import axios from "axios";
import React, { useEffect, useState } from "react";
import Helper from "../util/Helper";
import { useCookies } from "react-cookie";
import './ClickCatalouge.css';
import { Button, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

const CatalougeLeft = ({setGetCatalougeIvId, skeletonLoading, setGetCatalougeItemId, showcatalogueList, setShowProductDetailsWithVariant, setFilterValue, setFilter, filterValue }) => {
    
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const [navbarOpen, setNavbarOpen] = useState(false)
    const [getItemListCatalouge, setGetItemListCatalouge] = useState([])

    const [minIndex, setMinIndex] = useState(0)
    const [maxIndex, setMaxIndex] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)

    const [countPage, setCountPage] = useState(1)
    const [countPageAllStock, setCountPageAllStock] = useState(1)     
    const [active, setActive] = useState(null)
    // const [selectCsvFile, setSelectCsvFile] = useState(null)

    // const [anchorEl, setAnchorEl] = useState(null);
    // const [open, setOpen] = useState(false);
    // const [placement, setPlacement] = useState();
    // const [masterCategoryList, setMasterCategoryList] = useState([])
    // const [masterCategoryProductList, setMasterCategoryProductList] = useState([])
    // const [activeMasterCategory, setActiveMasterCategory] = useState(0)
    // const [selectedProductIdList, setSelectedProductIdList] = useState([])

    const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
    }     
    
    const handlePagination = (pageNumber) => {
        setMinIndex((pageNumber - 1) * 10)
        setMaxIndex(pageNumber * 10)
        setCurrentPage(pageNumber)
    }

    useEffect(() => {
        setGetItemListCatalouge(showcatalogueList)
    },[showcatalogueList])

    // var bodyformData = new FormData();
    // bodyformData.append('file', selectCsvFile );
    // bodyformData.append('fileName', selectCsvFile && selectCsvFile.name);

    // useEffect(() => {
    //     if(selectCsvFile && selectCsvFile != null) {
    //         const getItemListCatalougeUrl = {
    //             method: "POST",
    //             url: Helper.getWsUrl() + `/items/add_item_data_by_file/?web=1`,
    //             headers: { "content-type": "multipart/form-data", 'token': cookies.token },
    //             data:bodyformData
    //         };
    //         axios(getItemListCatalougeUrl)
    //         .then((response) => {       
    //             if (response.data.code === 201) {
    //                 alert(response.data.msg)
    //                 window.location.reload();                             
    //             } else if (response.data.code === 401) {
    //                 removeCookie("logged_in")
    //                 removeCookie("token")
    //                 setTimeout(() => {
    //                     navigate('/')
    //                     localStorage.setItem("isAuthenticated", false)
    //                     localStorage.removeItem("isAuthenticated")
    //                 }, 1000)
    //             } else if (response.data.code === 400) {
    //                 alert(response.data.msg)
    //             }
    //         })
    //         .catch((error) => {
    //             alert(error);
    //         })
    //     }
    // },[selectCsvFile])

    // const downloadCatelogueCsv = () => {
    //     const getItemListCatalougeUrl = {
    //         method: "GET",
    //         url: Helper.getWsUrl() + `/masters/get_csv_file_creation_instructions/?web=1`,
    //         headers: { "content-type": "multipart/form-data", 'token': cookies.token },
    //     };
    //     axios(getItemListCatalougeUrl)
    //     .then((response) => {       
    //         if (response.data.code === 200) {
    //             window.open(Helper.encypt_decrypt(response.data.data).url, '_blank', 'noopener,noreferrer')                  
    //         } else if (response.data.code === 401) {
    //             removeCookie("logged_in")
    //             removeCookie("token")
    //             setTimeout(() => {
    //                 navigate('/')
    //                 localStorage.setItem("isAuthenticated", false)
    //                 localStorage.removeItem("isAuthenticated")
    //             }, 1000)
    //         } else if (response.data.code === 400) {
    //             alert(response.data.msg)
    //         }
    //     })
    //     .catch((error) => {
    //         alert(error);
    //     })
    // }

    useEffect(() => {
        let countCatalogue = 0
        if(getItemListCatalouge && getItemListCatalouge.length > 0) {
            getItemListCatalouge.map((value,index) =>{
                countCatalogue += 1
            })
            setCountPageAllStock(countCatalogue)
        }
    },[getItemListCatalouge])

    useEffect(() => {
        setCountPage(Math.ceil(countPageAllStock / 10))
    })

    const showInStockCard = () => {
        return(
            <>
                {getItemListCatalouge && getItemListCatalouge.map((value,index) => {
                    if(value.feature_image === 1) {
                        var url = value.featured_img_url === "N/A" || value.featured_img_url === null ? require("../assets/images/enbill logo 1.png") : value.featured_img_url
                    }else{
                        var url = require("../assets/images/enbill_logo.png")
                    }
                        var inStock = {
                            writingMode:"vertical-rl",
                            color:"#fff", 
                            fontWeight:"500", 
                            marginLeft:"2px", 
                            width:"8.5%", 
                            overflow:"hidden", 
                            padding:"0", 
                            margin:"0", 
                            justifyContent:"center", 
                            alignItems:"center", 
                            borderRadius:"0 20px 20px 0",
                            background:"#309F73",
                            display:localStorage.getItem("inventory") != 1 ? "none" : "flex",
                            marginTop:"2%",
                            paddingTop:"12px",
                            marginBottom:"2%",
                        }
                        var outStock = {
                            writingMode:"vertical-rl",
                            color:"#fff", 
                            fontWeight:"500", 
                            marginLeft:"2px", 
                            width:"8.5%", 
                            overflow:"hidden", 
                            padding:"0", 
                            margin:"0", 
                            justifyContent:"center", 
                            alignItems:"center", 
                            borderRadius:"0 20px 20px 0",
                            display:localStorage.getItem("inventory") != 1 ? "none" : "flex",
                            background:"#DC463D",
                            marginTop:"2%",
                            paddingTop:"15px",
                            marginBottom:"2%",
                        }
                        return(
                            index >= minIndex && index < maxIndex && (
                                <div onClick={() => {setGetCatalougeItemId(value.item_id); setGetCatalougeIvId(value.iv_id)}} style={{display:"flex", cursor:"pointer", marginBottom:"20px"}}>                                                
                                    <div id='card'  style={{ borderRadius: "10px 10px 10px 10px", overflow: "hidden", padding: "16px 10px 16px 20px", display: 'flex', boxSizing: "content-box", justifyContent: "space-between", width: "90%", boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.1)" }} className={`cardborder  ${active == index && 'active'}`} key={index} onClick={() => {setShowProductDetailsWithVariant(true);setActive(index)}}  >
                                    
                                        <div id='card-left' style={{width:"30%", aspectRatio: 1/1, overflow:"hidden", marginRight:"10px", borderRadius:"10px", display:"flex", justifyContent:"center",alignItems:"center"}}>
                                            <img src={url} height={"100%"} width={"100%"} style={{margin:"0", borderRadius:"10px"}} class="img-fluid" 
                                                onError={(e) => {
                                                    if(e.target.onerror === null) {
                                                        (e.target.src = require("../assets/images/enbill logo 1.png"))
                                                    }}}/>   
                                        </div>
                                        <div id='card-right' style={{ display: "flex", justifyContent: "space-between", width: "70%" }}>
                                            <div>
                                                <p className="child" style={{ fontWeight: "600", textTransform:"capitalize" }}>{value.item_name} 
                                                    {(value.size != null && value.size != '') || (value.color != null && value.color != '') || (value.model != null && value.model != '') ? '(' : null} &nbsp;
                                                    <span className="child2" style={{ display: "inline-block", fontWeight: "500", fontSize: "15px" }}> {value.size}</span>
                                                    {(value.color != null && value.color != '') || (value.model != null && value.model != '') ? ',' : null} &nbsp;
                                                    <span className="child2" style={{ display: "inline-block", fontWeight: "500", fontSize: "15px" }}> {value.color}</span>
                                                    {(value.color != null && value.color != '') ? ',' : null} &nbsp;
                                                    <span className="child2" style={{ display: "inline-block", fontWeight: "500", fontSize: "15px" }}> {value.model}</span> 
                                                    {(value.size != null && value.size != '') || (value.color != null && value.color != '') || (value.model != null && value.model != '') ? ')' : null} 
                                                </p>
                                                <p className="child3" style={{ fontWeight: "600 ", marginBottom: "5px", fontSize: "18px"}}>₹ {value.mrp}</p>
                                                <p className="child4" style={{ fontWeight: "500", marginBottom: "5px", fontSize: "14px" }}>Sold by Pieces</p>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                {active === index ?  
                                                    <img src={require("../assets/images/white vector.png")} style={{ width: "85%", height: "20%" }} /> 
                                                :  
                                                    <img src={require("../assets/images/vector.png")} style={{ width: "85%", height: "20%" }} />
                                                }                                              
                                            </div>
                                        </div>
                                    </div>
                                    {value.in_stock === 1 ? 
                                        <div style={inStock}>
                                            <p>In Stock</p>
                                        </div> 
                                    :
                                        <div style={outStock}>
                                            <p>Out Of Stock</p>
                                        </div> 
                                    }                         
                                </div>
                            )
                        )
                })}
            </>
        )
    }

    // useEffect(() => {
    //     const requestCategoryOption = {
    //         method: "GET",
    //         url: Helper.getWsUrl() + `/items/get_merchant_categories_for_magicupload_new/?magic=1&web=1`,
    //         headers: { "Content-Type": "application/json", Token: cookies.token },
    //     };
    //     axios(requestCategoryOption)
    //         .then((response) => {       
    //             if (response.data.code === 200) {
    //                 setMasterCategoryList(Helper.encypt_decrypt(response.data.data))
    //                 getItemOnCategoryFunc(Helper.encypt_decrypt(response.data.data)[0].id)
    //             } else if (response.data.code === 401) {
    //                 removeCookie("logged_in")
    //                 removeCookie("token")
    //                 setTimeout(() => {
    //                     navigate('/')
    //                     localStorage.setItem("isAuthenticated", false)
    //                     localStorage.removeItem("isAuthenticated")
    //                 }, 1000)
    //             } else {
    //                 setMasterCategoryList([])
    //             }
    //         })
    //         .catch((error) => {
    //             alert(error);
    //         });
    // },[])

    // const getItemOnCategoryFunc = (id) => {
    //     const requestCategoryItemOption = {
    //         method: "GET",
    //         url: Helper.getWsUrl() + `/items/get_item_according_to_cat_new/${id}/?web=1`,
    //         headers: { "Content-Type": "application/json", Token: cookies.token },
    //     };
    //     axios(requestCategoryItemOption)
    //         .then((response) => {       
    //             if (response.data.code === 200) {
    //                 setMasterCategoryProductList(Helper.encypt_decrypt(response.data.data))
    //             } else if (response.data.code === 401) {
    //                 removeCookie("logged_in")
    //                 removeCookie("token")
    //                 setTimeout(() => {
    //                     navigate('/')
    //                     localStorage.setItem("isAuthenticated", false)
    //                     localStorage.removeItem("isAuthenticated")
    //                 }, 1000)
    //             } else {
    //                 setMasterCategoryProductList([])
    //             }
    //         })
    //         .catch((error) => {
    //             alert(error);
    //         });
    // }

    // const getSelectedItemOnCategoryFunc = () => {
    //     const requestSelectedCategoryItemOption = {
    //         method: "POST",
    //         url: Helper.getWsUrl() + `/items/post_item_according_to_cat_new/?web=1`,
    //         headers: { "Content-Type": "application/json", Token: cookies.token },
    //         data: {
    //             product_id: selectedProductIdList,
    //         }
    //     };
    //     axios(requestSelectedCategoryItemOption)
    //         .then((response) => {       
    //             if (response.data.code === 201) {
    //                 window.location.reload();
    //             } else if (response.data.code === 401) {
    //                 removeCookie("logged_in")
    //                 removeCookie("token")
    //                 setTimeout(() => {
    //                     navigate('/')
    //                     localStorage.setItem("isAuthenticated", false)
    //                     localStorage.removeItem("isAuthenticated")
    //                 }, 1000)
    //             } else {
    //                 setMasterCategoryProductList([])
    //             }
    //         })
    //         .catch((error) => {
    //             alert(error);
    //         });
    // }

    // const getProductIdListToPost = (id) => {
    //     if(!selectedProductIdList.find((element) => element.id === id)) {
    //         setSelectedProductIdList([...selectedProductIdList, {id: id}])
    //     } else {
    //         setSelectedProductIdList(selectedProductIdList.filter((element, ind) => 
    //             element.id != id
    //         ))
    //     }
    // }

    // const handleClick = (newPlacement) => (event) => {
    //     setAnchorEl(event.currentTarget);
    //     setOpen((prev) => placement !== newPlacement || !prev);
    //     setPlacement(newPlacement);
    // };

    const activeBtn = {
        background:"#008c53",
        border:"none",
        color:"#fff"
    }

    return (
        <div style={{width: "40%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff"}}>
            <div style={{position:"relative", width:"100%"}}>
                <h6 style={{ fontWeight: "600" }}>Catalogue</h6>
                <div style={{display:"flex", justifyContent:"space-between", position:"absolute", top:"0", right:"8%"}}>
                    {/* <Button style={{color:"#008c53", padding:"0px 3px", border:"1px solid #008c53", borderRadius:"10px", background:"none",fontSize:"12px"}} variant="contained" component="label">
                        Add items by csv
                        <input type="file" hidden accept="csv" onChange={(e) => {setSelectCsvFile(e.target.files[0])}}/>
                    </Button>
                    <Button onClick={() => {downloadCatelogueCsv()}} style={{color:"#008c53", padding:"0px 3px", border:"1px solid #008c53", borderRadius:"10px", background:"none",fontSize:"12px"}} variant="contained" component="label">
                        Download csv
                    </Button>
                    <Button onClick={handleClick('bottom')} style={{color:"#008c53", padding:"0px 3px", border:"1px solid #008c53", borderRadius:"10px", background:"none",fontSize:"12px"}} variant="contained" component="label">
                        Listed Product
                    </Button>
                    <Popper style={{width: 450, boxShadow:"0 0.5rem 1rem rgb(0 0 0 / 15%)"}} open={open} anchorEl={anchorEl} placement={placement} transition>
                        {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                            <Typography sx={{ p: 0, mt: 1 }}>
                                {masterCategoryList && masterCategoryList.length > 0 ? 
                                    <div style={{display:'flex'}}>
                                        <div id='masterCategoryScroll' style={{width:'40%', maxheight:'45vh', overflowY:'scroll'}}>
                                            {masterCategoryList && masterCategoryList.map((value, index) => {
                                                return (
                                                    <>
                                                        <div type='button' onClick={() => {setActiveMasterCategory(index); getItemOnCategoryFunc(value.id)}} style={{display:'flex', justifyContent:'start', alignItems:'center', padding:'5px 10px', background: activeMasterCategory === index ? 'rgb(238 238 238)' : '#fff'}}>
                                                            <img src={require('../assets/images/enbill logo 1.png')} style={{width:'45px', height:'45px'}} />
                                                            <p style={{margin:'0 0 0 10px', fontSize:'14px'}}>{value.name}</p>
                                                        </div>
                                                        <hr style={{margin:'0', border:'0.2px solid rgb(188 184 184)'}}/>
                                                    </>
                                                )
                                            })}
                                        </div>
                                        <div style={{background:'rgb(245, 245, 245)', padding:'10px', width:'60%', maxHeight:'45vh' }}>
                                            <h4 style={{fontSize:"14px", margin:"0 0 5px", fontWeight:'600', textAlign:"center"}}>Item List</h4>
                                            {masterCategoryProductList && masterCategoryProductList.length > 0 ? 
                                                <div id='masterCategoryScroll' style={{width:'100%', maxHeight:'35vh', overflowY:"scroll", padding:'5px', overflowY:'scroll' }}>
                                                    {masterCategoryProductList && masterCategoryProductList.map((value, index) => {
                                                        return (
                                                            <>
                                                                <div style={{display:"flex", alignItems:"center", background: !selectedProductIdList.find((element) => element.id === value.product_id) ? "#fff" : "rgb(238 238 238)"}} onClick={() => {getProductIdListToPost(value.product_id)}}>
                                                                    <img style={{width:"50px", marginRight:"5px"}} src={value.product_image === "N/A" ? require("../assets/images/enbill logo 1.png") : value.product_image} 
                                                                        onError={(e) =>
                                                                            (e.target.onerror = null)(
                                                                                (e.target.src = require("../assets/images/enbill logo 1.png"))
                                                                            )}/>
                                                                    <p type='button' style={{padding:'5px', margin:'0', fontSize:'12.5px', lineHeight:"15px", fontWeight:"500"}}>
                                                                        {value.name} <br/>
                                                                        <small style={{fontSize:"11.5px", fontWeight:"400"}}>
                                                                            ₹ {value.selling_price}
                                                                            {((value.size != null && value.size != "") || (value.model != null && value.model != "") || (value.color != null && value.color != "")) ? " ( " : null}
                                                                            {value.size != null && value.size != "" ? value.size : null}
                                                                            {((value.size != null && value.size != "") && (value.model != null && value.model != "")) ? ", " + value.model : value.model}
                                                                            {(((value.size != null && value.size != "") || (value.model != null && value.model != "")) && (value.color != null && value.color != "")) ? ", " + value.color : value.color}
                                                                            {((value.size != null && value.size != "") || (value.model != null && value.model != "") || (value.color != null && value.color != "")) ? " )" : null}
                                                                        </small>
                                                                    </p>
                                                                </div>
                                                                <hr style={{margin:'5px 0', border:'0.1px solid rgb(188 184 184)'}}/>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            :
                                                <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                    <p style={{fontSize:'14px', marginTop:'20px', fontWeight:'400', color:'#7a7a7a'}}>No Item Found</p>
                                                </div>
                                            }
                                            <button disabled={selectedProductIdList && selectedProductIdList.length > 0 ? "" : "disable"} style={{background:"#008c53", fontSize:"12px", fontWeight:'500', color:"#fff", border:"none", borderRadius:"15px", padding:"5px 13px", float:"right"}} onClick={() => {getSelectedItemOnCategoryFunc();}}>Add</button>
                                        </div>
                                    </div>
                                :
                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:'center', height:"20vh"}}>
                                        <img width={"20%"} src={require('../assets/images/No-Products_Found.png')} />
                                        <p style={{margin:"0", fontSize:"13px", fontWeight:"500", opacity:"0.8", textAlign:"center", color:"#008c53"}}>Sorry your selected shop category is not available. Wait for next update!</p>
                                    </div>
                                }
                            </Typography>
                            </Paper>
                        </Fade>
                        )}
                    </Popper> */}
                    <span>
                        <img src={require("../assets/images/Plus.png")} onClick={() => navigate("/Catalogue/add_new_item")}
                            height={"24px"} width={"24px"} style={{ cursor:"pointer"}}
                            className="img-fluid" />
                    </span>
                </div>
                <nav className='navBar' style={{height: !navbarOpen ? "0": "40px"}}>
                    <button onClick={handleToggle}>
                        { navbarOpen ? 
                            (<img onClick={() =>{setFilter(false); setCurrentPage(1); setFilterValue(null);}} src={require("../assets/images/cancel icon.png")} />) 
                            : 
                            (<img src={require("../assets/images/filter icon.png")} />)
                        }
                    </button>
                    <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
                        <li onClick={() => {setFilter(true); setFilterValue(1); setCurrentPage(1); handlePagination(1)}}><a style={filterValue === 1 ? activeBtn : null}>Stock</a></li>
                        <li onClick={() => {setFilter(true); setFilterValue(0); setCurrentPage(1); handlePagination(1)}}><a style={filterValue === 0 ? activeBtn : null}>Out of Stock</a></li>
                    </ul>
                </nav>
            </div>

            <div style={{overflowY:"scroll", height:"60vh"}}>
                {skeletonLoading ? 
                    <Skeleton count={4} borderRadius={"10px"} height={"15vh"} />
                :
                    <>
                        {getItemListCatalouge && getItemListCatalouge.length > 0 ? 
                            <>
                                {showInStockCard()}
                            </>
                        :
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"60vh"}}>
                                <p style={{color:"#7a7a7a", fontSize:"18px", fontWeight:"600"}}>no data found</p>
                            </div>
                        }
                    </>
                }
            </div>
            <div className="pageCount">
                <Pagination style={{display:"flex",justifyContent:"center",alignItems:"center"}}  count={countPage} defaultPage={1} page={currentPage} onChange={(event, pageNumber) => handlePagination(pageNumber)}/>
            </div>
        </div>
    )
}

export default CatalougeLeft;