import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const TemplateFunc = () => {

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()
    
    const [template, setTemplate] = useState(null);
    const [tempid,setTempId]=useState(null);
    const [tempupdateid,setTemupdateid]=useState(1)
    const [activeCard, setActiveCard] = useState(0)
    const [billTemplateId, setBillTemplateId] = useState(0)
    const [remark, setRemark] = useState(null)
    
    useEffect(() => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/contact/get_bill_templates/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                if (response.data.code === 200) {            
                  setTemplate(Helper.encypt_decrypt(response.data.data))              
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })
    },[]) 
    
    useEffect(() => {
        if(billTemplateId && billTemplateId === 5) {
            const requestOptions = {
                method: "GET",
                url: Helper.getWsUrl() + `/contact/get_template_remark_new/?web=1`,
                headers: { "Content-Type": "application/json", "token": cookies.token },
            };
            axios(requestOptions)
                .then((response) => {
                    if (response.data.code === 200) {           
                        setRemark(Helper.encypt_decrypt(response.data.data).remark)            
                    } else if (response.data.code === 401) {
                        removeCookie("logged_in")
                        removeCookie("token")
                        setTimeout(() => {
                            navigate('/')
                            localStorage.setItem("isAuthenticated", false)
                            localStorage.removeItem("isAuthenticated")
                        }, 1000)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },[billTemplateId]) 
    
    useEffect(() => {
        if(template && template != null) {
            setTempId(template[0].bill_image)
        }
    },[template])

    const UpdateTemplateBillApi = () => {
        if(tempupdateid != 5 ) {
            var data = {
                bill_template_id: tempupdateid
            }
        } else {
            var data = {
                bill_template_id: tempupdateid,
                remark: remark
            }
        }
        const requestOptions = {
            method: "PATCH",
            url: Helper.getWsUrl() + `/contact/patch_contact/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
            data: data
        };
        axios(requestOptions)
            .then((response) => {
                if (response.data.code === 201) {
                    alert("Bill Template Update successfully")                                
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })
        }

        const remarkStyle = {
            border:'1px solid #7a7a7a',
            borderRadius:"10px",
            marginLeft:'10px',
            padding:"3px 15px"
        }
      
    return (
        <div className='col-10' style={{backgroundColor:"rgb(245, 245, 245)",padding:"20px", position:"relative", display:"flex"}}>
            <div style={{background:"#fff", width:"45%", height:"78vh", borderRadius:"20px", padding:"20px", position:"relative", overflowY:"scroll"}}>
                <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                    <h3 style={{fontSize:"18px", fontWeight:"600", lineHeight:"29px"}}>Bill Templates</h3>
                </div>
                <div style={{display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px"}}>
                    {template && template.map((value, index) => {
                        return (
                            <div className={`cardborder  ${activeCard == index && 'card_active'}`} onClick={()=>{setTempId(value.bill_image); setTemupdateid(value.bill_template_id); setActiveCard(index); setBillTemplateId(value.bill_template_id)}} style={{ borderRadius: "5px", margin: "2%", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px", boxSizing: "border-box", marginBottom: "20px", width: "80%", padding: "16px" }}>
                                <img  src={value.bill_image} class="img-fluid"></img>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div style={{background:"#fff", width:"55%", height:"100%", borderRadius:"20px", padding:"8px", position:"relative", overflow:"hidden", marginLeft:"20px"}}>
                <div style={{ display: "flex", justifyContent: "center", alignItems:"center", flexDirection:"column"}}>
                    <img style={{height:"500px"}} src={tempid}  class="img-fluid" ></img>
                    {tempupdateid && tempupdateid != 5 ?
                        null
                    :
                        <p style={{margin:'0', fontWeight:"500"}}>
                            Remark : <input type='text' placeholder="No Remark" value={remark} style={remarkStyle} onChange={(e) => {setRemark(e.target.value)}} />
                        </p>
                    }
                    <button onClick={()=>{
                            if(tempupdateid != 5) {
                                UpdateTemplateBillApi()
                            } else {
                                if(remark && remark != null && remark != "") {
                                    UpdateTemplateBillApi()
                                } else {
                                    alert("Please add remark")
                                }
                            }
                            }} style={{ backgroundColor: "#008C53", borderRadius: "20px", border: "none",  padding:"8px 30px", fontSize: "12px", color: "white",  marginTop:"10px"}}>
                        Select
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TemplateFunc ;