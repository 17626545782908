import React, { useEffect } from 'react'
import { useState } from 'react';
import "./Galla.css"
import { GallaLeft } from './GallaLeft';
import GallaRight from './GallaRight';
import GallaFirstTimeUser from './GallaFirstTimeUser';
import Helper from "../util/Helper";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

const Galla = () => {

  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const [getPaymentId, setGetPaymentId] = useState(null)
  const [showPassBookDetail, setShowPassBookDetail] = useState(false)
  const [getDate, setGetDate] = useState(null)
  const [showCashBook, setShowCashBook] = useState(false)

  const[showPassbookList, setShowPassbookList] = useState(null)
  const[showCashbookList, setShowCashbookList] = useState([])
  const[gallaEmptystate, setGallaEmptyState] = useState(false)
  const[skeletonLoading, setSkeletonLoading] = useState(true)

  useEffect(() => {
    const getPassBookListUrl = {
        method: "GET",
        url: Helper.getWsUrl() + `/vouchers/get_merchant_passbook/?web=1`,
        headers: { "Content-Type": "application/json", 'token': cookies.token },
    };
    axios(getPassBookListUrl)
    .then((response) => {  
        // setCookie("token", response.headers.token);            
        if (response.data.code === 200) {
          setShowPassbookList(Helper.encypt_decrypt(response.data.data)) 
          setGallaEmptyState(false)
          setSkeletonLoading(false)            
        } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
        } else if(response.data.code === 204) {
          setGallaEmptyState(true)
          setSkeletonLoading(false)
        }
    })
    .catch((error) => {
        alert(error);
    })
},[])

  return (
    <div className='col-10' style={{ backgroundColor: "rgb(245, 245, 245)", padding: "20px", display: "flex", position:"relative"}}>
      {showCashBook ? 
        <div onClick={() => setShowCashBook(false)} style={{position:"absolute", cursor:"pointer", top:"0", left:"0", background:"#008c53", color:"#fff", fontWeight:"600", fontSize:"16px", zIndex:"1", padding:"10px 5px", borderRadius:"0 25px 25px 0", writingMode:"vertical-rl"}}>
          PassBook
        </div>
      :
        // <div onClick={() => setShowCashBook(true)} className=""  style={{position:"absolute", cursor:"pointer", top:"0", left:"0", background:"#008c53", color:"#fff", fontWeight:"600", fontSize:"16px", zIndex:"1", padding:"10px 5px", borderRadius:"0 25px 25px 0", writingMode:"vertical-rl"}}>
        //   CashBook
        // </div>
        <div onClick={() => setShowCashBook(true)} className="cashbookbutton"  >
          CashBook
        </div>
      }
      {gallaEmptystate ? 
        <GallaFirstTimeUser />
      :
        <>
          <GallaLeft skeletonLoading = {skeletonLoading} showPassbookList = {showPassbookList} showCashBook = {showCashBook} setGetDate = {setGetDate} setShowPassBookDetail = {setShowPassBookDetail} setGetPaymentId = {setGetPaymentId}/>
          <GallaRight setShowPassBookDetail = {setShowPassBookDetail} showCashBook = {showCashBook} getDate = {getDate} showPassBookDetail = {showPassBookDetail} getPaymentId = {getPaymentId}/>
        </>
      }
    </div>
  )
}

export default Galla