import CatalogueAddNew from "./CatalougeAddNew";
import CatalougeProductDetailsWithVariant from "./CatalougeProductDetailsWithVariant";
import CatalougeFirstTimeUser from "./CatalougeFirstTimeUser";
import CatalougeLeft from "./CatalougeLeft";
import React,{useEffect, useState} from "react";
import Helper from "../util/Helper";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import CatalogueListedProduct from "./CatalogueListedProduct";

const CatalougeIndex = ({globalItemId, globalIvId}) => {

  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const [showProductDetailsWithVariant,setShowProductDetailsWithVariant]=useState(false);
  const [getCatalougeItemId, setGetCatalougeItemId] = useState(null)
  const [getCatalougeIvId, setGetCatalougeIvId] = useState(null)
  const [refreshAfterEditItem, setRefreshAfterEditItem] = useState(null)
  const [showcatalogueList, setShowCatalogueList] = useState(null)
  const [catalogueEmptystate, setCatalogueEmptyState] = useState(false)
  const [filter, setFilter] = useState(false)
  const [filterValue, setFilterValue] = useState(null)
  const [skeletonLoading, setSkeletonLoading] = useState(true)
  const [showListedProduct, setShowListedProduct] = useState(false)

  useEffect(() => {
    if(globalItemId && globalItemId != null) {
      setShowProductDetailsWithVariant(true)
    }
  },[globalItemId])  

  useEffect(() => {
    const getItemListCatalougeUrl = {
        method: "GET",
        url: Helper.getWsUrl() + `${!filter ? `/items/get_itemsbymerchant/?web=1` : `/items/get_itemsbymerchant/?web=1&in_stock=${filterValue}`}`,
        headers: { "Content-Type": "application/json", 'token': cookies.token },
    };
    axios(getItemListCatalougeUrl)
    .then((response) => {           
        if (response.data.code === 200) {
          setShowCatalogueList(Helper.encypt_decrypt(response.data.data).Data)
          setCatalogueEmptyState(false)
          setSkeletonLoading(false)                       
        } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
        } else if(response.data.code === 204) {
          setShowCatalogueList([])
          if(!filter){
            setCatalogueEmptyState(true)
            setSkeletonLoading(false)
          }
        }  
    })
    .catch((error) => {
        alert(error);
    })
},[refreshAfterEditItem, filter, filterValue])
  
  return(
    <div className='col-10' style={{ backgroundColor: "rgb(245, 245, 245)", padding: "20px", display: "flex", position:"relative" }}>
      {showListedProduct ? 
        <CatalogueListedProduct />
      :
        <>
          {catalogueEmptystate ? 
            <CatalougeFirstTimeUser  setShowListedProduct = {setShowListedProduct} /> 
          : 
            <>
              <CatalougeLeft skeletonLoading = {skeletonLoading} filterValue = {filterValue} setFilterValue = {setFilterValue} setFilter = {setFilter} showcatalogueList = {showcatalogueList} setGetCatalougeIvId = {setGetCatalougeIvId} setGetCatalougeItemId = {setGetCatalougeItemId} setShowProductDetailsWithVariant={setShowProductDetailsWithVariant} ></CatalougeLeft>
              {showProductDetailsWithVariant ?       
                <CatalougeProductDetailsWithVariant globalIvId = {globalIvId} globalItemId = {globalItemId} setRefreshAfterEditItem = {setRefreshAfterEditItem} getCatalougeIvId = {getCatalougeIvId} getCatalougeItemId = {getCatalougeItemId} setShowProductDetailsWithVariant={setShowProductDetailsWithVariant}></CatalougeProductDetailsWithVariant>
              :
                <CatalogueAddNew setShowListedProduct = {setShowListedProduct} />                    
              }
            </>
          }
        </>
      }
    </div>
  )
}

export default CatalougeIndex;