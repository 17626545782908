import React from 'react'
// import PurchaseNav from './PurchaseNav'
import PurchaseCard from './PurchaseCard'

const PurchaseLeft = ({showPurchaseList, skeletonLoading, setGetVoucherId, setShowPurchaseAddItem, setshowPurchaseDetail, purchaseFilterValue, setPurchaseFilterValue, setPurchaseFilter}) => {

  

  return (
    <div style={{width: "40%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff"}}>
      <div style={{position:"relative", width:"100%"}}>
        <h6 style={{ fontWeight: "600" }}>View Purchase</h6>
        <span>
          <img src={require("../assets/images/Plus.png")} onClick={() => {setshowPurchaseDetail(false); setShowPurchaseAddItem(true)}}
              height={"24px"} width={"24px"} style={{position:"absolute", top:"0%", right:"10%", cursor:"pointer"}}
              className="img-fluid" />
        </span>
      </div>            
      <PurchaseCard purchaseFilterValue = {purchaseFilterValue} setPurchaseFilterValue = {setPurchaseFilterValue} setPurchaseFilter = {setPurchaseFilter} skeletonLoading = {skeletonLoading} showPurchaseList = {showPurchaseList} setGetVoucherId = {setGetVoucherId} setshowPurchaseDetail = {setshowPurchaseDetail} />
    </div> 
  )
}

export default PurchaseLeft