import React,{ useEffect, useState } from 'react'
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Pagination, TextField } from "@mui/material"; 
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const BillingLeft = ({setGetCardBillId, setShowBillDetail, showBillingList, skeletonLoading, billingFilterValue, setBillingFilterValue, setBillingFilter}) => {

  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const [getBillingList, setGetBillingList] = useState([])
  const [navbarOpen, setNavbarOpen] = useState(false)

  const [minIndex, setMinIndex] = useState(0)
  const [maxIndex, setMaxIndex] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [countPage, setCountPage] = useState(1)
  const [active, setActive] = useState(null)
  const [billCustom, setBillCustom] = useState(false)
  const [changeBillInp, setChangeBillInp] = useState(false)
  const [billStartDate, setBillStartDate] = useState(null)
  const [billEndDate, setBillEndDate] = useState(null)

  var dates=new Date();
  var tdate=dates.getDate();
  var year = dates.getFullYear();

  if(tdate < 10){
    tdate = "0"+ tdate;
  }
  var month=dates.getMonth()+1;
  if(month < 10){
    month = "0"+ month;
  }
  var maxDate = year + "-"+month+"-"+tdate;

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
  }

  const handlePagination = (pageNumber) => {
    setMinIndex((pageNumber - 1) * 10)
    setMaxIndex(pageNumber * 10)
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    setGetBillingList(showBillingList)
  },[showBillingList])

  useEffect(()=> {
    setCountPage(Math.ceil(getBillingList.length / 10))
  })

  const downloadBills = () => {
    const getPassBookListUrl = {
        method: "GET",
        url: Helper.getWsUrl() + `/contact/get_csv_file/?file_type=3&web=1`,
        headers: { "Content-Type": "application/json", 'token': cookies.token },
    };
    axios(getPassBookListUrl)
      .then((response) => {       
          if (response.data.code === 200) {    
            window.open(Helper.encypt_decrypt(response.data.data).link, '_blank', 'noopener,noreferrer')          
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          } 
      })
      .catch((error) => {
          alert(error);
      })
  }

  useEffect(() => {
    if(billStartDate && billStartDate != null && billEndDate && billEndDate != null) {
      const getBillListUrl = {
        method: "GET",
        url: Helper.getWsUrl() + `/vouchers/getBillsList/?start_date=${billStartDate}&end_date=${billEndDate}&web=1`,
        headers: { "Content-Type": "application/json", 'token': cookies.token },
      };
      axios(getBillListUrl)
      .then((response) => {          
          if (response.data.code === 200) {
            setGetBillingList(Helper.encypt_decrypt(response.data.data).billdetails)          
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          } else if(response.data.code === 204) {
            setGetBillingList([])
          }
      })
      .catch((error) => {
          alert(error);
      })
    }
  },[billStartDate, billEndDate])

  const activeBtn = {
    background:"#008c53",
    border:"none",
    color:"#fff"
  }

  return (
    <div className='billingLeft' style={{width: "40%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff"}} >
      <div style={{position:"relative", display:"flex", justifyContent:"space-between", alignItems:"center", width:"90%"}}>
        <h6 style={{ fontWeight: "600" }}>View Bills</h6>
        <img onClick={() => {downloadBills()}} style={{cursor:"pointer", height:"25px", display: getBillingList && getBillingList.length > 0 ? "block" : "none"}} src={require("../assets/images/download icon.png")}/>
      </div>
      <nav className="navBar" style={{height: !navbarOpen ? "0": !billCustom ? "40px" : "70px"}}>
        <button onClick={handleToggle}>
          {navbarOpen ? 
              (<img onClick={() => {setBillingFilter(false); setCurrentPage(1); setBillingFilterValue(null); setBillCustom(false); setChangeBillInp(false)}} src={require("../assets/images/cancel.png")}/>) 
          : 
              (<img src={require("../assets/images/Filter.png")}/>)}
        </button>
        <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`} style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto ",columnGap:"5px" }}>
            <li onClick={() => {setBillingFilter(true); setBillCustom(false); setBillingFilterValue(0); setCurrentPage(1); handlePagination(1); setChangeBillInp(false)}}><a style={billingFilterValue === 0 ? activeBtn : null}>Bills</a></li>
            <li onClick={() => {setBillingFilter(true); setBillCustom(false); setBillingFilterValue(1); setCurrentPage(1); handlePagination(1); setChangeBillInp(false)}}><a style={billingFilterValue === 1 ? activeBtn : null}>Modified</a></li>
            <li onClick={() => {setBillingFilter(true); setBillCustom(false); setBillingFilterValue(2); setCurrentPage(1); handlePagination(1); setChangeBillInp(false)}}><a style={billingFilterValue === 2 ? activeBtn : null}>Cancelled</a></li>
            <li onClick={() => {setBillingFilter(true); setBillCustom(true); setBillingFilterValue(null); setCurrentPage(1); handlePagination(1)}}><a style={!billCustom ? null : activeBtn}>Custom</a></li>
          </div>
          {billCustom ?  
            <div id='billCustomDate' style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
              {changeBillInp ? 
                <>
                  <li>
                    <TextField max={maxDate} onChange={e => setBillStartDate(e.target.value)} className="cal3" id="date" type="date" InputLabelProps={{ shrink: true,}} />
                  </li>
                  <p style={{margin:"0"}}>to</p>                      
                  <li>
                    <TextField max={maxDate} onChange={e => setBillEndDate(e.target.value)} className="cal3" id="date" type="date" InputLabelProps={{shrink: true }} />
                  </li>
                </>
              :
                <>
                  <input style={{cursor:"pointer", padding:"8px 14px"}} className='inputText' onClick={()=> setChangeBillInp(true)} placeholder='start date'></input>
                  <p style={{margin:"0px"}}>to</p> 
                  <input style={{cursor:"pointer", padding:"8px 14px"}} className='inputText' onClick={()=> setChangeBillInp(true)} placeholder='end date'></input>
                </>
              }
            </div>
          : 
              null
          }    
        </ul>
      </nav>
      {skeletonLoading ? 
        <div style={{height:"60vh", overflowY:"scroll"}}>
          <Skeleton count={6} borderRadius={"10px"} height={"10vh"} /> 
        </div>
      :
        <>
          {getBillingList && getBillingList.length > 0 ? 
            <div style={{overflowY:"scroll", height:"60vh"}}>
              {getBillingList && getBillingList.map((value, index) => {
                return(
                  index >= minIndex && index < maxIndex && (
                    <div key={index}  className={`${active == index && 'active'}`} onClick={() => {setGetCardBillId(value.bill_id); setShowBillDetail(true);setActive(index)}} style={{ position:"relative", display: "flex", width: "100%", justifyContent: "space-between", cursor: "pointer", borderRadius: "10px", boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.1)", padding: "10px", marginTop: "20px" }}>
                      <img style={{width:"20%", height:"90%", left:"40%", opacity:"0.5", top:"5%", position:"absolute", display: value.bill_adjusted === 2 ? "block" : "none"}} src={require('../assets/images/Cancelled_for_cards.png')} />
                      <img style={{width:"20%", height:"90%", left:"40%", opacity:"0.7", top:"5%", position:"absolute", display: value.bill_adjusted === 1 ? "block" : "none"}} src={require('../assets/images/Modified_for_cards.png')} />
                      <div style={{display:"flex", justifyContent:"space-between", width:"95%"}}>
                        <div style={{ textAlign: "left" }}>
                          <p className='child' style={{ fontSize: "18px", fontWeight: "600", margin: "0" }}>{value.bill_number}</p>
                          <small className='child' style={{ fontSize: "14px", fontWeight: "500",color: active != index ? "#7A7A7A" : "#fff" }}>{value.Customer_mobile}</small>
                        </div>
                        <div style={{ textAlign: "right", position: "relative" }}>
                          <p className='child' style={{ fontSize: "18px", fontWeight: "600", margin: "0" }}>₹ {value.total_amount}</p>
                          <small className='child' style={{ fontSize: "14px", fontWeight: "500",color: active != index ? "#7A7A7A" : "#fff" }}>{value.bill_date}</small>              
                        </div>
                      </div>
                      <div style={{width:"5%", display:"flex", justifyContent:"center", alignItems:"center", margin:"0", padding:"0"}}>
                      {active === index ?  <img src={require("../assets/images/white vector.png")} style={{ width: "8px", height: "20px" }} /> :  <img src={require("../assets/images/vector.png")} style={{ width: "8px", height: "20px" }} />}
                        {/* <img style={{width: "10px", height: "20px", marginLeft: "13px"}} src={require("../assets/images/vector.png")} /> */}
                      </div>
                    </div>
                  )
                )
              })}
            </div> 
          :
            <div style={{height:"60vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <p style={{color:"#7a7a7a", fontSize:"18px", fontWeight:"600"}}>no data found</p>
            </div>
          }
        </>
      } 
      <div className="pageCount" style={{marginTop:"15px"}}>
        <Pagination style={{display:"flex",justifyContent:"center",alignItems:"center"}} count={countPage} defaultPage={1} page={currentPage} onChange={(event, pageNumber) => handlePagination(pageNumber)}/>
      </div>  
    </div>
  )
}

export default BillingLeft