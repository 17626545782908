import React, { useEffect, useState } from 'react'
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import "./Galla.css"
import { useNavigate } from 'react-router-dom';

export const PassBookDetail = ({getPaymentId, setShowPassBookDetail}) => {

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()

    const [getPassBookDetail, setGetPassBookDetail] = useState(null) 
    const [modeCash, setModeCash] = useState("")
    // const [modeCard, setModeCard] = useState("")
    // const [modeOnline, setModeOnline] = useState("")
    // const [modeUdhaar, setModeUdhaar] = useState("")
    // const [modeCheque, setModeCheque] = useState("")

    useEffect(() => {
        if (getPaymentId && getPaymentId != null) {
            const getPassBookDetailUrl = {
            method: "GET",
            url: Helper.getWsUrl() + `/vouchers/getPaymentDetail/${getPaymentId}?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
            };
            axios(getPassBookDetailUrl)
            .then((response) => {  
                // setCookie("token", response.headers.token);            
                if (response.data.code === 200) {
                    setGetPassBookDetail(Helper.encypt_decrypt(response.data.data))                
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((error) => {
                alert(error);
            })
        }
    },[getPaymentId])

    useEffect(() => {
        if(getPassBookDetail && getPassBookDetail.payment_mode_id === 1){
            setModeCash("Cash")
        } else if(getPassBookDetail && getPassBookDetail.payment_mode_id === 3){
            setModeCash("Card")
        } else if(getPassBookDetail && getPassBookDetail.payment_mode_id === 5){
            setModeCash("Cheque")
        } else if(getPassBookDetail && getPassBookDetail.payment_mode_id === 4){
            setModeCash("Online")
        } else {
            setModeCash("Udhaar")
        }
    },[getPassBookDetail])


  return (
    <>
        <div className='PassBookCloseBtn'>
            <button onClick={() => setShowPassBookDetail(false)}>
                <img src={require('../assets/images/cancel.png')}/>
            </button>
        </div>

        <div className='PbDtlFrm' style={{width:"100%"}}>
            <div className='PbDUpperDiv'>
                <div className='PbDtlFrmUpprLeft'>
                    <div className='PbDtlFrmUpprLeftFirst'>
                        <p style={{margin:0}}>Reference No:</p>
                        <p>Bill no:</p>
                    </div>
                    <div className='PbDtlFrmUpprLeftScnd'>
                        <p style={{marginBottom:0}}>{getPassBookDetail && getPassBookDetail.payment_number}</p>
                        <p>{getPassBookDetail && getPassBookDetail.bill_number}</p>
                    </div>
                </div>
                <div className='PbDtlFrmUpprRight'>
                    <img src={require("../assets/images/enbill logo 1.png")}/>
                </div>
            </div>
            <div className='PbDLowerDiv'>
                <div className='PbDLowerLeft'>
                    <p>Form</p>
                    <p>Contact No.</p>
                    <p>Created on:</p>
                    <p>Payment mode</p>
                    <p>Amount</p>
                </div>
                <div className='PbDLowerRight'>
                    <p>{getPassBookDetail && getPassBookDetail.merchant_name}</p>
                    <p>{getPassBookDetail && getPassBookDetail.mobile_number}</p>
                    <p>{getPassBookDetail && getPassBookDetail.payment_date_time}</p>
                    {/* <p>{modeCash && modeCash} {modeCard && modeCard} {modeCheque && modeCheque} {modeUdhaar && modeUdhaar} {modeOnline && modeOnline}</p> */}
                    <p>{modeCash && modeCash}</p>
                    <p>₹ {getPassBookDetail && getPassBookDetail.amount}</p>
                </div>
            </div>
        </div>
    </>
  )
}
