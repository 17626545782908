import React from "react";

const ProformaNewUser = ({setPerformatemp, setPerformaEmptyState}) => {
    return (
        <div style={{backgroundColor:"white",display:"flex",justifyContent:"center",textAlign:"center",flexDirection:"column", width:"100%", borderRadius:"20px"}}>
            <div>
                <img src={require("../assets/images/proforma invoice.png")} style={{ height: "251px", width: "356px" }} />
            </div>
            <div style={{ marginTop: "15px" }}>
                <p style={{ color: "#8C8C8C", fontWeight: "600", fontSize: "18px" }}>Make Proforma invoices and share them with your grahaks. </p>
            </div>
            <div>
                <button onClick={() => {setPerformatemp(true); setPerformaEmptyState(false)}} style={{ backgroundColor: "#008C53", color: "white", borderRadius: "25px", border: "1px solid #008C53 ", padding: "5px 10px" }}>
                    <img src={require("../assets/images/icon.png")}  height={"12%"} width={"12%"} style={{ marginRight: "8px", marginBottom: "4px", marginLeft: "-5px" }} class="img-fluid" />
                    <span style={{ fontSize: "17px" }}>Add New</span>
                </button>
            </div>
        </div>
    )
}
export default ProformaNewUser;