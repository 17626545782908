import React from "react";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useState, useEffect, useCallback } from "react";
import './BillingPayment.css'
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';


const BillsPayment = ({bookingTotalAmtForBilling, bookingCustomerIdForBilling, bookingItemBillIdForBilling, bookingItemListForBilling, itemListTtlAmtWithDiscForAddBill, itemListTtlAmtForAddBill, overAllDiscount, grhkContactId, itemListToAddBill, billIdPitB, grahakDtlPitB, ttlAmtAllItemPitB, grahakDtlFrmGTB, grahakDetails, addedNewtemList, removeItemList, getTotalAmountForAllItem, showbillid, getCardBillId, accessReturnReplace}) => {

  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const [restModesDisable, setRestModesDisable] = useState(false)
  const [ttlAmtFromBoth, setTtlAmtFromBoth] = useState(0)
  const [grahakDetailFromBoth, setGrahakDetailFromBoth] = useState(null)
  const [billIdFromBoth, setBillIdFromBoth] = useState(null)
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [otpVarification, setOtpVarification] = useState(false)
  const [getOtp, setGetOtp] = useState(null)

  useEffect(() => {
    if(billIdPitB && billIdPitB != null) {
      setBillIdFromBoth(billIdPitB)
    }
    if(grahakDtlPitB && grahakDtlPitB != null) {
      setGrahakDetailFromBoth(grahakDtlPitB.contact_id)
    }
    if(grahakDetails && grahakDetails != null) {
      setGrahakDetailFromBoth(grahakDetails.contact_id)
    }
    if(grahakDtlFrmGTB && grahakDtlFrmGTB != null) {
      setGrahakDetailFromBoth(grahakDtlFrmGTB.contact_id)
    }
    if(grhkContactId && grhkContactId != null) {
      setGrahakDetailFromBoth(grhkContactId)
    }
    if(bookingCustomerIdForBilling && bookingCustomerIdForBilling != null) {
      setGrahakDetailFromBoth(bookingCustomerIdForBilling)
    }
    if(ttlAmtAllItemPitB && ttlAmtAllItemPitB != null) {
      setTtlAmtFromBoth(ttlAmtAllItemPitB)
    }
    if(getTotalAmountForAllItem && getTotalAmountForAllItem != null) {
      setTtlAmtFromBoth(getTotalAmountForAllItem)
    }
    if(bookingTotalAmtForBilling && bookingTotalAmtForBilling != null) {
      setTtlAmtFromBoth(bookingTotalAmtForBilling)
    }
  },[billIdPitB, grahakDtlPitB, ttlAmtAllItemPitB, grahakDetails, grahakDtlFrmGTB, getTotalAmountForAllItem, showbillid, bookingCustomerIdForBilling, bookingTotalAmtForBilling])

  const [isShown, setIsShown] = useState(false)
  const [isShown1, setIsShown1] = useState(false)
  const [isShown2, setIsShown2] = useState(false)
  const [isShown3, setIsShown3] = useState(false)
  const [isShown4, setIsShown4] = useState(false)

  const [cashPayment, setCashPayment] = useState(0)
  const [udhaarPayment, setUdhaarPayment] = useState(0)
  const [cardPayment, setCardPayment] = useState(0)
  const [onlinePayment, setOnlinePayment] = useState(0)
  const [chequePayment, setChequePayment] = useState(0)

  const [CashAmt, setCashAmt] = useState(0)
  const [CardAmt, setCardAmt] = useState(0)
  const [OnlineAmt, setOnlineAmt] = useState(0)
  const [ChequeAmt, setChequeAmt] = useState(0)
  const [udhaarAmt, setUdhaarAmt] = useState(0)
  const [varRestBalance, setVarRestBalance] = useState(0)
  const [refund, setrefund] = useState(0)
  const [refundAmt, SetRefundAmt] = useState(0)

  let cash = 0, card = 0, online = 0, cheque = 0
  var varTotalAmt = 0,  forUdhaarAmt = 0

  const cashPaymentMode = useCallback(event => {
    const { name, value } = event.target;
    // if(name === "cashAmt"){
    //   let floatValue = 0
    //   if(value.length == 0) {
    //     floatValue = parseFloat(value)
    //   } else {
    //     floatValue = value
    //   }
    //   console.log("type of ", typeof floatValue)
    //    setCashPayment({...cashPayment, [name] : floatValue})
    //   }else {}
      setCashPayment({ ...cashPayment, [name]: value });
  });

  const cardPaymentMode = useCallback(event => {
    const { name, value } = event.target;
    setCardPayment({ ...cardPayment, [name]: value });
  });

  const udhaarPaymentMode = useCallback(event => {
    const { name, value } = event.target;
    setUdhaarPayment({ ...udhaarPayment, [name]: value });
  });

  const onlinePaymentMode = useCallback(event => {
    const { name, value } = event.target;
    setOnlinePayment({ ...onlinePayment, [name]: value });
  });


  const chequePaymentMode = useCallback(event => {
    const { name, value } = event.target;
    setChequePayment({ ...chequePayment, [name]: value });
  });

  const [paymentArr, setPaymentArr] = useState([])

  const [cashObj, setCashObj] = useState({
    contact_id: null,
    payment_mode: 1,
    total_recieved: 0
  })

  useEffect(() => {
    setCashObj({
      ...cashObj,
      contact_id: grahakDetailFromBoth && grahakDetailFromBoth,
      total_recieved: parseFloat(cashPayment && cashPayment.cashAmt)
    })
    cash = cashPayment && cashPayment.cashAmt != null ? parseFloat(cashPayment && cashPayment.cashAmt) : 0
    setCashAmt({
      ...CashAmt,
      cash
    })
  }, [cashPayment])

  const [cardObj, setCardObj] = useState({
    approval_number: null,
    bank_name: null,
    contact_id: null,
    payment_mode: 3,
    total_recieved: 0
  })

  useEffect(() => {
    setCardObj({
      ...cardObj,
      contact_id: grahakDetailFromBoth && grahakDetailFromBoth,
      approval_number: cardPayment && cardPayment.approvalNo,
      bank_name: cardPayment && cardPayment.bankName,
      total_recieved: parseFloat(cardPayment && cardPayment.amount_paid)
    })
    card = cardPayment && cardPayment.amount_paid != null ? parseFloat(cardPayment && cardPayment.amount_paid) : 0
    setCardAmt({
      ...CardAmt,
      card
    })
  }, [cardPayment])

  const [onlineObj, setOnlineObj] = useState({
    contact_id: null,
    payment_mode: 4,
    total_recieved: 0
  })

  useEffect(() => {
    setOnlineObj({
      ...onlineObj,
      contact_id: grahakDetailFromBoth && grahakDetailFromBoth,
      total_recieved: parseFloat(onlinePayment && onlinePayment.amountpaid)
    })
    online = onlinePayment && onlinePayment.amountpaid != null ? parseFloat(onlinePayment && onlinePayment.amountpaid) : 0
    setOnlineAmt({
      ...OnlineAmt,
      online
    })
  }, [onlinePayment])

  const [checkObj, setCheckObj] = useState({
    contact_id: null,
    total_recieved: 0,
    payment_mode: 5,
    cheque_number: null,
    cheque_amount: null,
    due_date: null,
    bank_name: null,
    date_of_cheque: null
  })

  useEffect(() => {
    setCheckObj({
      ...checkObj,
      contact_id: grahakDetailFromBoth && grahakDetailFromBoth,
      total_recieved: parseFloat(chequePayment && chequePayment.chequeamt),
      cheque_number: chequePayment && chequePayment.chequeNo,
      cheque_amount: parseFloat(chequePayment && chequePayment.chequeamt),
      due_date: chequePayment && chequePayment.duedate,
      bank_name: chequePayment && chequePayment.banknam,
      date_of_cheque: chequePayment && chequePayment.dateofcheque
    })
    cheque = chequePayment && chequePayment.chequeamt != null ? parseFloat(chequePayment && chequePayment.chequeamt) : 0
    setChequeAmt({
      ...ChequeAmt,
      cheque
    })
  }, [chequePayment])

  const [udhaarObj, setUdhaarObj] = useState({
    contact_id: null,
    payment_mode: 2,
    total_recieved: 0,
    expected_date: null,
    shop_credit_address: null,
  })

  useEffect(() => {
    setUdhaarObj({
      ...udhaarObj,
      total_recieved: parseFloat(varRestBalance && varRestBalance),
      expected_date: udhaarPayment && udhaarPayment.expectedDate,
      shop_credit_address: udhaarPayment && udhaarPayment.grahakAdd,
      contact_id: grahakDetailFromBoth && grahakDetailFromBoth,
    })
  }, [udhaarPayment, varRestBalance])

  varTotalAmt += (parseFloat(CashAmt.cash) + parseFloat(CardAmt.card) + parseFloat(OnlineAmt.online) + parseFloat(ChequeAmt.cheque))
  
  useEffect(() => {
    var bal = isNaN(varTotalAmt)
    if(bal === false) {
      setrefund(parseFloat(varTotalAmt) - parseFloat(ttlAmtFromBoth))
      setVarRestBalance(parseFloat(ttlAmtFromBoth - varTotalAmt).toFixed(2))
    } else {
      setVarRestBalance(0)
    }
  },[varTotalAmt])

  useEffect(() => {
    setUdhaarAmt(varRestBalance)
  },[varRestBalance])

  useEffect(() => {    
    if(udhaarPayment && udhaarPayment.expectedDate != null && udhaarPayment.grahakAdd != null){
      setUdhaarAmt(0)
    }
  },[udhaarPayment])

  useEffect(() => {
    if(refund < 0) {
      SetRefundAmt(0)
    } else {
      SetRefundAmt(refund)
    }
  },[refund])

  const show = () => {
      setIsShown(!isShown)
  }
  const show1 = () => {
      setIsShown1(!isShown1)
  }
  const show2 = () => {
      setIsShown2(!isShown2)
  }
  const show3 = () => {
      setIsShown3(!isShown3)
  }
  const show4 = () => {
      setIsShown4(!isShown4)
  }

  useEffect(() => {
    if( ttlAmtFromBoth === 0 ) {
      setRestModesDisable(true)
    } else {
      setRestModesDisable(false)
    }
  },[ttlAmtFromBoth])

  const setValuesInArr = () => {    
    if (isShown) {
      if(cashPayment && cashPayment.cashAmt != null) {
        setPaymentArr(paymentArr => [
          ...paymentArr,
          cashObj
        ])
      } else {
        alert("Please fill field Cash Amount")
        setPaymentArr([])
      }
    }
    if (isShown1) {
      if(cardPayment && cardPayment.amount_paid != null && cardPayment.bankName != null && cardPayment.approvalNo != null) {
        setPaymentArr(paymentArr => [
          ...paymentArr,
          cardObj
        ])
      } else {
        alert("Please fill Card details")
        setPaymentArr([])
      }
    }
    if (isShown2) {
      if(chequePayment && chequePayment.chequeNo != null && chequePayment.chequeamt != null && chequePayment.dateofcheque != null && chequePayment.banknam != null) {
        setPaymentArr(paymentArr => [
          ...paymentArr,
          checkObj
        ])
      } else {
        alert("Please fill all Cheque details")
        setPaymentArr([])
      }
    }
    if (isShown4) {
      if(onlinePayment && onlinePayment.amountpaid != null) {
        setPaymentArr(paymentArr => [
          ...paymentArr,
          onlineObj
        ])
      } else {
        alert("Please fill Online Amount Paid")
        setPaymentArr([])
      }
    }
    if (isShown3) {
      if(udhaarPayment && udhaarPayment.expectedDate != null  && udhaarPayment.grahakAdd != null) {
        setPaymentArr(paymentArr => [
          ...paymentArr,
          udhaarObj
        ])
      } else {
        alert("Please fill Expected Date and Address")
        setPaymentArr([])
      }
    }
  }

  let bill_Id = billIdFromBoth

  useEffect(() => {
    if (paymentArr && paymentArr.length > 0) {
      setShowProgressBar(true)
      if(bill_Id && bill_Id != null) {
        MultiplePaymentsmode(bill_Id)
      } else {
        if(!accessReturnReplace) {
          addItemsInBill()
        } else {
          editBillDetails()
        }
      }
    }
  }, [paymentArr])

  const editBillDetails = () => {
    const requestOptions = {
        method: "PATCH",
        url: Helper.getWsUrl() + `/vouchers/edit_bill_detail/${getCardBillId}/${grhkContactId}/?web=1`,
        headers: { "Content-Type": "application/json", "token": cookies.token },
        data: {
            credit_note: 0,
            billdetails: {
                credit_note: 0,
                return_replace_billed_amount: itemListTtlAmtWithDiscForAddBill,
                extra_payble_amount: ttlAmtFromBoth && parseFloat(ttlAmtFromBoth).toFixed(2),
                bill_adjusted: 1
            },
            bill_Item_details: itemListToAddBill,
            remove_item: removeItemList,
            billed_item: addedNewtemList
        }
    };
    axios(requestOptions)
        .then((response) => {
            if (response.data.code === 201) {
              MultiplePaymentsmode(Helper.encypt_decrypt(response.data.data).bill_id_new)
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            }
        })
        .catch((err) => {
            console.log(err)
        })
  }

  const addItemsInBill = () => {
    if(bookingItemBillIdForBilling && bookingItemBillIdForBilling != null) {
      var requestData = {
        bill_Item_details: bookingItemListForBilling && bookingItemListForBilling,
        ib_id: bookingItemBillIdForBilling,
        billdetails:{
          bill_type:0,
          customer_id: bookingCustomerIdForBilling && bookingCustomerIdForBilling,
          is_shop_credit:0,
          overall_discount: 0.0,
          refund_amount:"0",
          round_off:0.0,
          status:0,
          sub_total: parseFloat(bookingTotalAmtForBilling && bookingTotalAmtForBilling).toFixed(2),
          taxable_amount:0.0,
          total_amount: parseFloat(bookingTotalAmtForBilling && bookingTotalAmtForBilling).toFixed(2),
          total_tax:0.0,
        }
      }
    } else {
      requestData = {
        bill_Item_details: itemListToAddBill && itemListToAddBill,
        billdetails:{
          bill_type:0,
          customer_id: grhkContactId && grhkContactId,
          is_shop_credit:0,
          overall_discount: overAllDiscount && overAllDiscount,
          refund_amount:"0",
          round_off:0.0,
          status:0,
          sub_total: parseFloat(itemListTtlAmtForAddBill && itemListTtlAmtForAddBill),
          taxable_amount:0.0,
          total_amount: parseFloat(itemListTtlAmtWithDiscForAddBill && itemListTtlAmtWithDiscForAddBill).toFixed(2),
          total_tax:0.0,
        }     
      }
    }
    const requestOptions5 = {
      method: "POST",
      url: Helper.getWsUrl() + "/vouchers/add_bill/?web=1",
      headers: { "Content-Type": "application/json", Token: cookies.token },
      data: requestData
    };
    axios(requestOptions5)
      .then((response) => {
        if (response.data.code === 201) {
          MultiplePaymentsmode(Helper.encypt_decrypt(response.data.data).bill_id)  
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const MultiplePaymentsmode = (idBill) => {
    const requestOptionspayment = {
      method: "POST",
      url: Helper.getWsUrl() + `/vouchers/multiple_bill_payments/${idBill}/?web=1`,
      headers: { "Content-Type": "application/json", "token": cookies.token },
      data: {
        payments: paymentArr
      }
    };
    axios(requestOptionspayment)
      .then((response) => {
        if (response.data.code === 201) {
          forEditBillFunc(idBill)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
      })
      .catch((error) => {
        alert(error);
      });
  }

  const forEditBillFunc = (bill_Id) => {
    const requestOptionspaymentEdit = {
      method: "PATCH",
      url: Helper.getWsUrl() + `/vouchers/edit_bill/${bill_Id}?isPerforma=1&web=1`,
      headers: { "Content-Type": "application/json", "token": cookies.token },
    };
    axios(requestOptionspaymentEdit)
      .then((response) => {
        if (response.data.code === 201) {
          generateBillAfterEditFunc(bill_Id)
          // changeStatusOfPackedItems()
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
      })
      .catch((error) => {
        alert(error);
      });
  }

  const generateBillAfterEditFunc = (bill_Id) => {
    const requestOptions6 = {
      method: "GET",
        url: Helper.getWsUrl() + `/reports/generate_invoice/${bill_Id}/?web=1`,
        headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOptions6)
        .then((response) => {
          if (response.data.code === 200) {
            changeStatusOfPackedItems()
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          } 
        })
        .catch((error) => {
          alert(error);
        });
  }

  const changeStatusOfPackedItems = () => {
    if(bookingItemBillIdForBilling && bookingItemBillIdForBilling != null) {
      const getStatusChangeUrl = {
        method : "PATCH",
        url: Helper.getWsUrl() + `/booking/bookingStatusChange/${bookingItemBillIdForBilling}?web=1`,
        headers: { "Content-Type": "application/json", Token: cookies.token },
        data:{
            status: 4,
            amount: ttlAmtFromBoth && ttlAmtFromBoth
        }
      }
      axios(getStatusChangeUrl)
        .then((response) => {
            if (response.data.code === 200 || response.data.code === 201) {
              window.location.reload();
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              }
        })
        .catch((error) => {
            alert(error);
        })
    } else {
      window.location.reload()
    }
  }

  const verifyOtpForBooking = () => {
    const getOtpVerifyUrl = {
      method : "POST",
      url: Helper.getWsUrl() + `/otp/match_otp_for_booking/${bookingItemBillIdForBilling}/?web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
      data:{
        otp: getOtp && getOtp
      }
    }
    axios(getOtpVerifyUrl)
      .then((response) => {
          if (response.data.code === 200) {
            setValuesInArr()
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          } else {
            alert(response.data.msg)
            window.location.reload()
          }
      })
      .catch((error) => {
          alert(error);
      })
  }

  var date=new Date();
  var tdate=date.getDate();
  var year = date.getFullYear();

  if(tdate < 10){
    tdate = "0"+ tdate;
  }

  var month=date.getMonth()+1;

  if(month < 10){
      month = "0"+ month;
  }

  var maxDate = year + "-"+month+"-"+tdate;

  const otpDivStyle = {
    justifyContent:"center",
    alignItems:"center"
  }
  const otpInputStyle = {
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    marginLeft:"10px",
    border:"1px solid #D9D9D9",
    backgroundColor:"#D9D9D9"
  }
  const otpInputFocusStyle = {
    border:"1px solid #d9d9d9"
  }
  const boolean = {
    type: "number"
  }

  return (
    <div className='col-10' style={{backgroundColor:"rgb(245, 245, 245)",padding:"20px", position:"relative"}}>
      <div onClick={() => {billIdPitB && billIdPitB != null ? navigate("/Proformainvoice") : window.location.reload()}} style={{cursor:"pointer", position:"absolute", top:"0", left:"0", background:"#008c53", color:"#fff", fontWeight:"600", fontSize:"16px", zIndex:"1", padding:"10px 5px", borderRadius:"0 25px 25px 0", writingMode:"vertical-rl"}}>
        {bookingItemBillIdForBilling && bookingItemBillIdForBilling != null ? "view orders" : billIdPitB && billIdPitB != null ? "View Performa Invoices" : "view bills"}
      </div>
      
      <div style={{background:"#fff", width:"100%", height:"100%", borderRadius:"20px", padding:"20px", position:"relative", overflow:"hidden"}}>

        <div style={{ width: "100%" }}>
          <p style={{ fontSize: "18px", fontWeight: "600", textAlign: "left" }}>Payment</p>
        </div>

        <div style={{ marginTop: "40px" }}>
          <p className="para_head">Select Payment Method</p>
        </div>

        <div className="paymentMainDiv" style={{ display: "flex", justifyContent: "space-between", marginTop: "40px" }}>
          <div className="card" >
            <div id="checkBox" style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "14px", marginBottom: "30px", backgroundColor: "#F9F9F9" }}>
              <div class="form-check">
                <input onClick={show} class="form-check-input" type="checkbox" />
                <label class="form-check-label" for="flexCheckDefault">Cash</label>
              </div>
            </div>
            {isShown ?
              <div style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "15px 10px" }}>
                <div style={{ position: "relative" }}>
                  <input autoComplete="off" type="number" name="cashAmt" onChange={cashPaymentMode} className="input_data" />
                  <span className='label_data'> Cash Amount* </span>
                </div>
                <div style={{ position: "relative", marginTop: "17px" }}>
                  <input disabled value={refundAmt} type="text" className="input_data" />
                  <span className='label_data'> Refund </span>
                </div>
              </div>
              :
              null
            }
          </div>
          <div className="card" >
            <div id="checkBox" style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "14px", marginBottom: "30px", backgroundColor: "#F9F9F9" }}>
              <div class="form-check">
                <input disabled={restModesDisable ? "disable" : ""} onClick={show1} class="form-check-input" type="checkbox" />
                <label class="form-check-label" for="flexCheckDefault">Card</label>
              </div>
            </div>
            {isShown1 ?
              <div style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "15px 10px" }}>
                <div style={{ position: "relative" }}>
                  <input autoComplete="off" type="text" name="bankName" onChange={cardPaymentMode} className="input_data" />
                  <span className='label_data'> Bank Name* </span>
                </div>
                <div style={{ position: "relative", marginTop: "17px" }}>
                  <input autoComplete="off" type="number" name="approvalNo" onChange={cardPaymentMode} className="input_data" />
                  <span className='label_data'> Approval No.* </span>
                </div>
                <div style={{ position: "relative", marginTop: "17px" }}>
                  <input autoComplete="off" type="number" name="amount_paid" onChange={cardPaymentMode} className="input_data" />
                  <span className='label_data'> Amount Paid* </span>
                </div>
              </div>
              :
              null
            }
          </div>
          <div className="card" >
            <div id="checkBox" style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "14px", marginBottom: "30px", backgroundColor: "#F9F9F9" }}>
              <div class="form-check">
                <input disabled={restModesDisable ? "disable" : ""} onClick={show2} class="form-check-input" type="checkbox" />
                <label class="form-check-label" for="flexCheckDefault">Cheque</label>
              </div>
            </div>
            {isShown2 ?
              <div style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "15px 10px" }}>
                <div style={{ position: "relative" }}>
                  <input autoComplete="off" type="number" name="chequeNo" onChange={chequePaymentMode} className="input_data" />
                  <span className='label_data'> Cheque No.* </span>
                </div>
                <div style={{ position: "relative", marginTop: "17px" }}>
                  <input autoComplete="off" type="number" name="chequeamt" onChange={chequePaymentMode} className="input_data" />
                  <span className='label_data'> Cheque Amt.* </span>
                </div>
                {/* <div style={{ position: "relative", marginTop: "17px" }}>
                  <input autoComplete="off" type="date" name="duedate" onKeyDown={(evt)=>{const cancelKeypress=RegExp(/^[0-9\b]+$/).test(evt.keyCode.toString());if(cancelKeypress){evt.preventDefault()}}} onChange={chequePaymentMode} className="input_data cal2"/>
                  <span className='label_data'> Due Date* </span>
                </div> */}
                <div style={{ position: "relative", marginTop: "17px" }}>
                  <input autoComplete="off" type="date" name="dateofcheque" onKeyDown={(evt)=>{const cancelKeypress=RegExp(/^[0-9\b]+$/).test(evt.keyCode.toString());if(cancelKeypress){evt.preventDefault()}}} onChange={chequePaymentMode} className="input_data cal2" />
                  <span className='label_data'> Date of Cheque* </span>
                </div>
                <div style={{ position: "relative", marginTop: "17px" }}>
                  <input autoComplete="off" type="text" name="banknam" onChange={chequePaymentMode} className="input_data" />
                  <span className='label_data'> Bank Name* </span>
                </div>
              </div>
              :
              null
            }
          </div>
          <div className="card" >
            <div id="checkBox" style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "14px", marginBottom: "30px", backgroundColor: "#F9F9F9" }}>
              <div class="form-check">
                <input disabled={restModesDisable ? "disable" : ""} onClick={show4} class="form-check-input" type="checkbox" />
                <label class="form-check-label" for="flexCheckDefault">Online</label>
              </div>
            </div>
            {isShown4 ?
              <div style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "15px 10px" }}>
                <div style={{ position: "relative" }}>
                  <input autoComplete="off" type="number" name="amountpaid" onChange={onlinePaymentMode} className="input_data" />
                  <span className='label_data'> Amount Paid </span>
                </div>
                {/* <div style={{ position: "relative", marginTop: "17px" }}>
                  <input autoComplete="off" type="text" className="input_data" />
                  <span className='label_data'> UPI ID </span>
                </div> */}
              </div>
              :
              null
            }
          </div>
          <div className="card" >
            <div id="checkBox" style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "14px", marginBottom: "30px", backgroundColor: "#F9F9F9" }}>
              <div class="form-check">
                <input disabled={localStorage.getItem("udhaar") != 1 || restModesDisable || accessReturnReplace ? "disable" : ""} onClick={show3} class="form-check-input" type="checkbox" />
                <label class="form-check-label" for="flexCheckDefault">Udhaar</label>
              </div>
            </div>
            {isShown3 ?
              <div style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "15px 10px" }}>
                <div style={{ position: "relative" }}>
                  <input autoComplete="off" type="text" name="grahakAdd" onChange={udhaarPaymentMode} className="input_data" />
                  <span className='label_data'> Grahak's Address* </span>
                </div>
                <div style={{ position: "relative", marginTop: "17px" }}>
                  <input autoComplete="off" type="date" min={maxDate} name="expectedDate"  onChange={udhaarPaymentMode} onKeyDown={(evt)=>{const cancelKeypress=RegExp(/^[0-9\b]+$/).test(evt.keyCode.toString());if(cancelKeypress){evt.preventDefault()}}} className="input_data cal2" />
                  <span className='label_data'> Expected Date*</span>
                </div>
              </div>
              :
              null
            }
          </div>
        </div>

        <div style={{marginTop:"30px"}}>
          <div style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
            <div style={{ textAlign: "right" }}>
              <p className='para_head'>Total Bill Amount</p>
              <p className='para_head'>Balance</p>
            </div>
            <div style={{ textAlign: "left", marginLeft: "20px" }}>
              <p className='para_head'>₹ {parseFloat(ttlAmtFromBoth).toFixed(2)}</p>
              <p className='para_head'>₹ {varRestBalance}</p>
            </div>
          </div>
          <div style={{ justifyContent:"end", marginTop: "40px", display:"flex" }}>

            <div style={{display:!showProgressBar ? "none" : "flex", marginRight:"20px"}} >
              <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
                <CircularProgress color="success" />
              </Stack>
              <p style={{color:"#008c53", fontWeight:"600", margin:"10px", padding:"0"}}>Processing...</p>
            </div>

            {!otpVarification ? 
              <button disabled={!showProgressBar ? "" : "disable"} style={{ textAlign: "center", fontSize: "20px", fontWeight: "600", border: "none", backgroundColor: "#008C53", color: "white", borderRadius: "20px", padding: "6px 25px" }}
                onClick={() => {
                  if(localStorage.getItem("udhaar") != 1) {
                    if(varRestBalance && varRestBalance > 0) {
                      alert("Please complete your payment")
                    } else {
                      if(bookingItemBillIdForBilling && bookingItemBillIdForBilling != null) {
                        setOtpVarification(true)
                      } else {
                        setValuesInArr();
                      }
                    }
                  } else {
                    if(isShown3 === false && udhaarAmt && udhaarAmt > 0) {
                      alert("Please complete your payment")
                    } else {
                      if(isShown3 && udhaarPayment && udhaarPayment.expectedDate === null) {
                        alert("For Udhaar, details are required")
                      } else {
                        if(udhaarAmt <= 0 || udhaarPayment && udhaarPayment.expectedDate != null && udhaarPayment.grahakAdd != null) {
                          if(bookingItemBillIdForBilling && bookingItemBillIdForBilling != null) {
                            setOtpVarification(true)
                          } else {
                            setValuesInArr();
                          }
                        } else {
                          alert("For Udhaar, All Udhaar details are required")
                        }
                      }
                    }
                  }
                }}
              >Done</button>
            :
              <div style={{display:"flex"}}>
                <OtpInput
                  shouldAutoFocus={true}
                  containerStyle = {otpDivStyle}
                  inputStyle = {otpInputStyle}
                  focusStyle = {otpInputFocusStyle}
                  value={getOtp && getOtp}                  
                  isInputNum = {boolean}
                  onChange={(e) => {setGetOtp(e)}}
                  numInputs={4}
                  />
                  <button onClick={() => {
                      if(getOtp && getOtp.length === 4) {
                        verifyOtpForBooking()
                      } else {
                        alert("Please enter otp")
                      }}} 
                      style={{border:"none", background:"#008c53", padding:"5px 20px", marginLeft:"25px", color:"#fff", fontWeight:"500", borderRadius:"20px"}}>Verify</button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default BillsPayment;