import React, { useEffect, useState } from 'react'
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import '../components/OrderNav.css'
import { Pagination } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { style } from '@mui/system';

const OrderCard = ({ setGetBookedByForDetail, setShowOrderdetail, setGetIbIdForDetail, showOrderList, skeletonLoading, orderFilterValue, setOrderFilterValue, setOrderFilter }) => {

    // const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    // const navigate = useNavigate()

    const [getOrderList, setGetOrderList] = useState([])
    // const [bookedData, setBookedData] = useState(true)
    // const [confirmedData, setConfirmedData] = useState(true)
    // const [deliveredData, setDeliveredData] = useState(true)
    // const [cancelledData, setCancelledData] = useState(true)
    // const [bookedBtn, setBookedBtn] = useState(true)
    // const [cancelBtn, setCancelBtn] = useState(true)
    // const [deliverBtn, setDeliverBtn] = useState(true)
    // const [confirmBtn, setConfirmBtn] = useState(true)

    const [navbarOpen, setNavbarOpen] = useState(false)

    const [minIndex, setMinIndex] = useState(0)
    const [maxIndex, setMaxIndex] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [countPage, setCountPage] = useState(1)

    const [countPageAllOrder, setCountPageAllOrder] = useState(1)
    // const [countPageBookedData, setCountPageBookedData] = useState(1)
    // const [countPageConfirmedData, setCountPageConfirmedData] = useState(1)  
    // const [countPageDeliveredData, setCountPageDeliveredData] = useState(1)     
    // const [countPageCancelledData, setCountPageCancelledData] = useState(1)
    const [active, setActive] = useState(null)
    // const[navHeight,setNavHeight]=useState(true)
    const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
    }

    const handlePagination = (pageNumber) => {
        setMinIndex((pageNumber - 1) * 10)
        setMaxIndex(pageNumber * 10)
        setCurrentPage(pageNumber)
    }

    useEffect(() => {
        setGetOrderList(showOrderList)
    }, [showOrderList])

    // useEffect(() => {
    //     const getOrderListUrl = {
    //         method: "GET",
    //         url: Helper.getWsUrl() + `/booking/get_merchant_booking/?web=1`,
    //         headers: { "Content-Type": "application/json", 'token': cookies.token },
    //     };
    //     axios(getOrderListUrl)
    //     .then((response) => {  
    //         // setCookie("token", response.headers.token);            
    //         if (response.data.code === 200) {
    //             Helper.consoleLogger(Helper.encypt_decrypt(response.data.data))
    //             Helper.consoleLogger(response.data.msg)
    //             setGetOrderList(Helper.encypt_decrypt(response.data.data))
    //         } else if (response.data.code === 401) {
    //             removeCookie("logged_in")
    //             removeCookie("token")
    //             setTimeout(() => {
    //                 navigate('/')
    //                 localStorage.setItem("isAuthenticated", false)
    //                 localStorage.removeItem("isAuthenticated")
    //             }, 1000)
    //         }
    //     })
    //     .catch((error) => {
    //         alert(error);
    //     })
    // },[])

    useEffect(() => {
        let countOrder = 0
        // let countBooked = 0
        // let countConfirmed = 0
        // let countDelivered = 0
        // let countCancelled = 0
        if (getOrderList && getOrderList.length > 0) {
            getOrderList.map((value, index) => {
                // if(value.status === 0) {
                //     countBooked += 1
                // } else if (value.s  ) {
                //     countConfirmed += 1
                // } else if (value.status === 4) {
                //     countDelivered += 1
                // } else if (value.status === 3) {
                //     countCancelled += 1
                // }
                countOrder += 1
            })
            setCountPageAllOrder(countOrder)
            // setCountPageBookedData(countBooked)
            // setCountPageConfirmedData(countConfirmed)
            // setCountPageDeliveredData(countDelivered)
            // setCountPageCancelledData(countCancelled)
        }
    }, [getOrderList])

    useEffect(() => {
        // if(cancelledData && deliveredData && confirmedData && bookedData) {
        setCountPage(Math.ceil(countPageAllOrder / 10))
        // } 
    })

    // const showBookedDataFunc = () => {
    //     setCountPage(Math.ceil(countPageBookedData / 10))
    //     setCancelledData(false)
    //     setDeliveredData(false)
    //     setConfirmedData(false)
    //     setBookedData(true)

    //     setBookedBtn(false)
    //     setCancelBtn(true)
    //     setDeliverBtn(true)
    //     setConfirmBtn(true)
    // }
    // const showConfirmedDataFunc = () => {
    //     setCountPage(Math.ceil(countPageConfirmedData / 10))
    //     setCancelledData(false)
    //     setDeliveredData(false)
    //     setConfirmedData(true)
    //     setBookedData(false)

    //     setBookedBtn(true)
    //     setCancelBtn(true)
    //     setDeliverBtn(true)
    //     setConfirmBtn(false)
    // }
    // const showDeliveredDataFunc = () => {
    //     setCountPage(Math.ceil(countPageDeliveredData / 10))
    //     setCancelledData(false)
    //     setDeliveredData(true)
    //     setConfirmedData(false)
    //     setBookedData(false)

    //     setBookedBtn(true)
    //     setCancelBtn(true)
    //     setDeliverBtn(false)
    //     setConfirmBtn(true)
    // }
    // const showCancelledDataFunc = () => {
    //     setCountPage(Math.ceil(countPageCancelledData / 10))
    //     setCancelledData(true)
    //     setDeliveredData(false)
    //     setConfirmedData(false)
    //     setBookedData(false)

    //     setBookedBtn(true)
    //     setCancelBtn(false)
    //     setDeliverBtn(true)
    //     setConfirmBtn(true)
    // }
    // const showAllDataFunc = () => {
    //     setCancelledData(true)
    //     setDeliveredData(true)
    //     setConfirmedData(true)
    //     setBookedData(true)

    //     setBookedBtn(true)
    //     setCancelBtn(true)
    //     setDeliverBtn(true)
    //     setConfirmBtn(true)
    // }

    const showBookedOrderList = () => {
        return (
            <>
                {getOrderList && getOrderList.map((value, index) => {
                    let booked_by = value.booked_by
                    // if(value.status === 0) {                          
                    return (
                        index >= minIndex && index < maxIndex && (
                            <div onClick={() => { setShowOrderdetail(true); setGetIbIdForDetail(value.ib_id); setGetBookedByForDetail(value.booked_by); setActive(index) }} style={{ display: "flex", marginBottom: "20px", cursor: "pointer" }}>
                                <div className={`cardborder  ${active == index && 'active'}`} id='card' key={index} style={{ borderRadius: "10px 10px 10px 10px", minHeight: "90px", overflow: "hidden", padding: "16px 10px 16px 20px", display: 'flex', boxSizing: "content-box", justifyContent: "space-between", width: "90%", boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.1)" }}>
                                    <div id='card-left'>
                                        <p className='child' style={{ marginBottom: "13px", fontWeight: "500", fontSize: "16px" }}>{value.customer_name}</p>
                                        <p className='orderchild' style={{ fontWeight: "500", marginBottom: "5px", fontSize: "14px", }}>{value.mobile_number}</p>
                                        <p className='orderchild' style={{ fontWeight: "500", marginBottom: "5px", fontSize: "14px", }}>{value.Customer_address}</p>
                                    </div>
                                    <div id='card-right' style={{ textAlign: "right", display: "flex", justifyContent: "space-between", width: "30%" }}>
                                        <div>
                                            {booked_by === 1 ?
                                                <p className='child'><span className='onclass' >On</span>line</p>
                                                :
                                                <p className='child'><span className='offclass' >Off</span>line</p>
                                            }
                                            <p className='orderchild' style={{ fontWeight: "500", marginBottom: "5px", fontSize: "14px" }}>{value.add_date_time}</p>
                                            {/*<p style={{fontweight:"300", marginBottom:"5px", fontSize:"14px", color:"#7a7a7a"}}>07:52 pm</p>*/}
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "20px" }}>
                                            {active === index ? <img src={require("../assets/images/white vector.png")} style={{}} /> : <img src={require("../assets/images/vector.png")} style={{}} />}
                                        </div>
                                    </div>
                                </div>
                                {value.status === 0 ?
                                    <div style={{ backgroundColor: "#EE7A37", width: "8%", display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: "10px", borderBottomRightRadius: "10px", marginTop: "2%", marginBottom: "2%" }}>
                                        <p style={{ writingMode: "vertical-rl", fontWeight: "600", color: "#fff" }}>Booked</p>
                                    </div>
                                    : null}
                                {value.status === 2 ?
                                    <div style={{ backgroundColor: "#008C53", width: "8%", display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: "10px", borderBottomRightRadius: "10px", marginTop: "2%", marginBottom: "2%" }}>
                                        <p style={{ writingMode: "vertical-rl", fontWeight: "600", color: "#fff" }}>Packed items</p>
                                    </div>
                                    : null}
                                {value.status === 1 ?
                                    <div style={{ backgroundColor: "#008C53", width: "8%", display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: "10px", borderBottomRightRadius: "10px", marginTop: "2%", marginBottom: "2%" }}>
                                        <p style={{ writingMode: "vertical-rl", fontWeight: "600", color: "#fff" }}>Confirmed</p>
                                    </div>
                                    : null}
                                {value.status === 4 ?
                                    <div style={{ backgroundColor: "#4CBD80", width: "8%", display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: "10px", borderBottomRightRadius: "10px", marginTop: "2%", marginBottom: "2%" }}>
                                        <p style={{ writingMode: "vertical-rl", fontWeight: "600", color: "#fff" }}>Delivered</p>
                                    </div>
                                    : null}
                                {value.status === 3 ?
                                    <div style={{ backgroundColor: "#BA1C09", width: "8%", display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: "10px", borderBottomRightRadius: "10px", marginTop: "2%", marginBottom: "2%" }}>
                                        <p style={{ writingMode: "vertical-rl", fontWeight: "600", color: "#fff" }}>Cancelled</p>
                                    </div>
                                    : null}
                            </div>
                        )
                    )
                    // }
                })}
            </>
        )
    }

    // const showConfirmedOrderList = () => {
    //     return (
    //         <>
    //             {getOrderList && getOrderList.map((value,index) => {
    //                 let booked_by = value.booked_by    
    //                 if(value.status === 1) {                          
    //                     return(
    //                         index >= minIndex && index < maxIndex && (
    //                             <div onClick={() => {setShowOrderdetail(true); setGetIbIdForDetail(value.ib_id); setGetBookedByForDetail(value.booked_by); setActive(index)}} style={{display:"flex",marginBottom:"20px",cursor:"pointer"}}>
    //                                 <div id='card'  className={`cardborder  ${active == index && 'active'}`} key={index}  style={{borderRadius:"10px 0 0 10px",  minHeight:"90px", overflow:"hidden", padding: "16px 10px 16px 20px", display: 'flex',boxSizing:"content-box", justifyContent:"space-between", width: "90%", boxShadow:"0px 2px 4px 0px rgba(0,0,0,0.1)"}}>
    //                                     <div id='card-left'>
    //                                         <p className='child' style={{marginBottom:"13px", fontWeight:"400", fontSize:"16px"}}>{value.customer_name}</p>
    //                                         <p className='orderchild' style={{fontweight:"300", marginBottom:"5px", fontSize:"14px", }}>{value.mobile_number}</p>
    //                                         <p className='orderchild' style={{fontweight:"300", marginBottom:"5px", fontSize:"14px", }}>{value.Customer_address}</p>
    //                                     </div>
    //                                     <div id='card-right' style={{textAlign:"right", display:"flex", justifyContent:"space-between", width:"30%"}}>
    //                                         <div>
    //                                             <p className='child'><span className='onoff'>{booked_by === 1 ? "On" : "Off"}</span>line</p>
    //                                             <p className='orderchild' style={{fontweight:"300", marginBottom:"5px", fontSize:"14px", }}>{value.add_date_time}</p>
    //                                             {/*<p style={{fontweight:"300", marginBottom:"5px", fontSize:"14px", color:"#7a7a7a"}}>07:52 pm</p>*/}
    //                                         </div>
    //                                         <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
    //                                             <img src={require("../assets/images/vector.png")} style={{ width:"85%", height:"20%"}}/>
    //                                         </div>                    
    //                                     </div>

    //                                 </div>
    //                                 <div style={{backgroundColor:"#008C53", width:"8%",display:"flex", justifyContent:"center", alignItems:"center", borderTopRightRadius:"10px", borderBottomRightRadius:"10px"}}>
    //                                     <p style={{writingMode:"vertical-rl", fontWeight:"600", color:"#fff"}}>Confirmed</p>
    //                                 </div>
    //                             </div>
    //                         )
    //                     )
    //                 }
    //             })}
    //         </>
    //     )
    // }

    // const showDeliveredOrderList = () => {
    //     return (
    //         <>
    //             {getOrderList && getOrderList.map((value,index) => {
    //                 let booked_by = value.booked_by    
    //                 if(value.status === 4) {                          
    //                     return(
    //                         index >= minIndex && index < maxIndex && (
    //                             <div onClick={() => {setShowOrderdetail(true); setGetIbIdForDetail(value.ib_id); setGetBookedByForDetail(value.booked_by); setActive(index)}} style={{display:"flex",marginBottom:"20px",cursor:"pointer"}}>
    //                                 <div id='card' className={`cardborder  ${active == index && 'active'}`} key={index} style={{borderRadius:"10px 0 0 10px",  minHeight:"90px", overflow:"hidden", padding: "16px 10px 16px 20px", display: 'flex',boxSizing:"content-box", justifyContent:"space-between", width: "90%", boxShadow:"0px 2px 4px 0px rgba(0,0,0,0.1)"}}>
    //                                     <div id='card-left'>
    //                                         <p className='child' style={{marginBottom:"13px", fontWeight:"400", fontSize:"16px"}}>{value.customer_name}</p>
    //                                         <p className='orderchild' style={{fontweight:"300", marginBottom:"5px", fontSize:"14px",}}>{value.mobile_number}</p>
    //                                         <p className='orderchild' style={{fontweight:"300", marginBottom:"5px", fontSize:"14px",}}>{value.Customer_address}</p>
    //                                     </div>
    //                                     <div id='card-right' style={{textAlign:"right", display:"flex", justifyContent:"space-between", width:"30%"}}>
    //                                         <div>
    //                                             <p className='child'><span className='onoff' >{booked_by === 1 ? "On" : "Off"}</span>line</p>
    //                                             <p className='orderchild' style={{fontweight:"300", marginBottom:"5px", fontSize:"14px",}}>{value.add_date_time}</p>
    //                                             {/*<p style={{fontweight:"300", marginBottom:"5px", fontSize:"14px", color:"#7a7a7a"}}>07:52 pm</p>*/}
    //                                         </div>
    //                                         <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
    //                                             <img src={require("../assets/images/vector.png")} style={{ width:"85%", height:"20%"}}/>
    //                                         </div>                    
    //                                     </div>

    //                                 </div>
    //                                 <div style={{backgroundColor:"#4CBD80", width:"8%",display:"flex", justifyContent:"center", alignItems:"center", borderTopRightRadius:"10px", borderBottomRightRadius:"10px"}}>
    //                                     <p style={{writingMode:"vertical-rl", fontWeight:"600", color:"#fff"}}>Delivered</p>
    //                                 </div>
    //                             </div>
    //                         )
    //                     )
    //                 }
    //             })}
    //         </>
    //     )
    // }

    // const showCancelledOrderList = () => {
    //     return (
    //         <>
    //             {getOrderList && getOrderList.map((value,index) => {
    //                 let booked_by = value.booked_by    
    //                 if(value.status === 3) {                          
    //                     return(
    //                         index >= minIndex && index < maxIndex && (
    //                             <div onClick={() => {setShowOrderdetail(true); setGetIbIdForDetail(value.ib_id); setGetBookedByForDetail(value.booked_by); setActive(index)}} style={{display:"flex",marginBottom:"20px",cursor:"pointer"}}>
    //                                 <div id='card' className={`cardborder  ${active == index && 'active'}`} key={index}  style={{borderRadius:"10px 0 0 10px",  minHeight:"90px", overflow:"hidden", padding: "16px 10px 16px 20px", display: 'flex',boxSizing:"content-box", justifyContent:"space-between", width: "90%", boxShadow:"0px 2px 4px 0px rgba(0,0,0,0.1)"}}>
    //                                     <div id='card-left'>
    //                                         <p className='child' style={{marginBottom:"13px", fontWeight:"400", fontSize:"16px"}}>{value.customer_name}</p>
    //                                         <p className='orderchild' style={{fontweight:"300", marginBottom:"5px", fontSize:"14px", }}>{value.mobile_number}</p>
    //                                         <p className='orderchild' style={{fontweight:"300", marginBottom:"5px", fontSize:"14px", }}>{value.Customer_address}</p>
    //                                     </div>
    //                                     <div id='card-right' style={{textAlign:"right", display:"flex", justifyContent:"space-between", width:"30%"}}>
    //                                         <div>
    //                                             <p className='child'><span className='onoff'>{booked_by === 1 ? "On" : "Off"}</span>line</p>
    //                                             <p className='orderchild' style={{fontweight:"300", marginBottom:"5px", fontSize:"14px"}}>{value.add_date_time}</p>
    //                                             {/*<p style={{fontweight:"300", marginBottom:"5px", fontSize:"14px", color:"#7a7a7a"}}>07:52 pm</p>*/}
    //                                         </div>
    //                                         <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
    //                                             <img src={require("../assets/images/vector.png")} style={{ width:"85%", height:"20%"}}/>
    //                                         </div>                    
    //                                     </div>

    //                                 </div>
    //                                 <div style={{backgroundColor:"#BA1C09", width:"8%",display:"flex", justifyContent:"center", alignItems:"center", borderTopRightRadius:"10px", borderBottomRightRadius:"10px"}}>
    //                                     <p style={{writingMode:"vertical-rl", fontWeight:"600", color:"#fff"}}>Cancelled</p>
    //                                 </div>
    //                             </div>
    //                         )
    //                     )
    //                 }
    //             })}
    //         </>
    //     )
    // }

    const activeBtn = {
        background: "#008c53",
        border: "none",
        color: "#fff"
    }

    return (
        <>
            <nav className="navBar" style={{height: !navbarOpen ? "0": "40px"}}>
                 <button onClick={handleToggle}>
                    {navbarOpen ?
                        (<img onClick={() => { setOrderFilter(false); setCurrentPage(1); setOrderFilterValue(null) }} src={require("../assets/images/cancel.png")} />)
                        :
                        (<img src={require("../assets/images/Filter.png")} />)
                    }
                </button>
                <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
                    <li onClick={() => { setOrderFilter(true); setOrderFilterValue('new'); setCurrentPage(1); handlePagination(1) }}><a style={orderFilterValue === "new" ? activeBtn : null}>Booked</a></li>
                    <li onClick={() => { setOrderFilter(true); setOrderFilterValue('confirmed'); setCurrentPage(1); handlePagination(1) }}><a style={orderFilterValue === "confirmed" ? activeBtn : null}>Confirmed</a></li>
                    <li onClick={() => { setOrderFilter(true); setOrderFilterValue('delivered'); setCurrentPage(1); handlePagination(1) }}><a style={orderFilterValue === "delivered" ? activeBtn : null}>Delivered</a></li>
                    <li onClick={() => { setOrderFilter(true); setOrderFilterValue('cancel'); setCurrentPage(1); handlePagination(1) }}><a style={orderFilterValue === "cancel" ? activeBtn : null}>Cancelled</a></li>
                    <li onClick={() => { setOrderFilter(true); setOrderFilterValue('item_packed'); setCurrentPage(1); handlePagination(1) }}><a style={orderFilterValue === "item_packed" ? activeBtn : null}>Packed Items</a></li>
                </ul>
            </nav>
            {skeletonLoading ?
                <div style={{ height: "60vh", overflowY: "scroll" }}>
                    <Skeleton count={4} borderRadius={"10px"} height={"15vh"} />
                </div>
                :
                <>
                    {getOrderList && getOrderList.length > 0 ?
                        <div style={{ height: !navbarOpen ? "60vh" : "56vh", overflowY: "scroll", marginTop: !navbarOpen ? "0px" : "30px" }}>
                            {showBookedOrderList()}
                        </div>
                        :
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60vh" }}>
                            <p style={{ color: "#7a7a7a", fontSize: "18px", fontWeight: "600" }}>no data found</p>
                        </div>
                    }
                </>
            }
            <div className="pageCount" style={{ marginTop: "15px" }}>
                <Pagination style={{ display: "flex", justifyContent: "center", alignItems: "center" }} count={countPage} defaultPage={1} page={currentPage} onChange={(event, pageNumber) => handlePagination(pageNumber)} />
            </div>
        </>
    )
}

export default OrderCard