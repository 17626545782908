import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";
import "./ClickUdhaar.css"
import { useNavigate } from "react-router-dom";

const UdhaarAllPaymentHistory=({setShowAllHistory,showallhistory})=>{

    const [allpayment, setAllPayment] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/vouchers/shop_credit_payment_history/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                // setCookie("token", response.headers.token);
                if (response.data.code === 200) {
                   setAllPayment(Helper.encypt_decrypt(response.data.data))
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const[color,setColor]=useState("");

    const getbtnclr=()=>{   
        if(allpayment[0].days===0){
            setColor("red")
        }else if(allpayment[0].days<0){
            setColor("yellow")

        }else{
            setColor("green")
        }
    }

    const btnvalue=()=>{
        if(allpayment[0].days===0){        
        }
    }
  
    return(
        <div style={{ maxHeight: "60vh", marginTop:"10px", width:"100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center", marginTop: "10px", width:"100%" }}>
                <p style={{fontWeight:"700",fontSize:"16px", margin:"0", padding:"0"}}>Payment History</p>
                <img onClick={()=>setShowAllHistory(false)} src={require("../assets/images/cancel icon.png")} class="img-fluid crossBtnAllHistory" />
            </div>
            <div className="fixTableHead" style={{marginTop:"20px", overflow:"scroll", width:"100%"}}>
                <table style={{ border: "none", width:"200%" }}>
                    <thead >
                        <tr>
                            <th scope="col" style={{ textAlign:"center", width:"15%", border: "none"}}>Shop Name</th>
                            <th scope="col" style={{ textAlign:"center", width:"10%", border: "none"}}>Contact Number</th>
                            <th scope="col" style={{ textAlign:"center", width:"10%", border: "none"}}>Bill No.</th>
                            <th scope="col" style={{ textAlign:"center", width:"10%", border: "none"}}>Total Amt.</th>
                            <th scope="col" style={{ textAlign:"center", width:"15%", border: "none"}}>Expected<br/> Date</th>
                            <th scope="col" style={{ textAlign:"center", width:"20%", border: "none"}}>Payment<br/>Date And Time</th>
                            <th scope="col" style={{ textAlign:"center", width:"10%", border: "none"}}>History</th>
                        </tr>
                    </thead> 
                    <tbody>
                        {allpayment.map((value,index)=>{
                            var od = "", bd = "", ad = ""
                            if(value.days === 0) {
                                od = "On Time"
                                var ondate = {
                                    display:"block",
                                    backgroundColor:"#008C53",
                                    padding: "8% 10%",
                                    color: "white",
                                    borderRadius:"10px"
                                }
                                var beforedate = {
                                    display:"none",
                                }
                                var afterdate ={
                                    display: "none",
                                }
                                
                            } else if (value.days < 0){
                                bd = "days early"
                                var ondate = {
                                    display:"none",
                                }
                                var beforedate = {
                                    display:"flex",
                                    backgroundColor:"#008C53",
                                    padding: "8% 10%",
                                    color: "white",
                                    borderRadius:"10px"
                                }
                                var afterdate ={
                                    display: "none",
                                }
                            }else{
                                ad = "days late"
                                var ondate = {                                    
                                    display:"none",
                                }
                                var beforedate = {
                                    display:"none",
                                }
                                var afterdate ={
                                    display: "flex",
                                    backgroundColor:"#BA1C09",
                                    padding: "8% 10%",
                                    color: "white",
                                    borderRadius:"10px"
                                }
                            }                            

                            return(
                                <tr style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                    <td style={{ textAlign:"center", width:"15%", border: "none"}}>{value.name}</td>
                                    <td style={{ textAlign:"center", width:"10%", border: "none"}}>{value.mobile_number}</td>
                                    <td style={{ textAlign:"center", width:"10%", border: "none"}}>{value.bill_number}</td>
                                    <td style={{ textAlign:"center", width:"10%", border: "none"}}>{value.total_amount}</td>
                                    <td style={{ textAlign:"center", width:"15%", border: "none"}}>{value.expected_date}</td>
                                    <td style={{ textAlign:"center", width:"20%", border: "none"}}>{value.bill_date}</td>
                                    <td style={{ textAlign:"center", width:"10%", border: "none"}}>
                                        <button disabled style={ondate}>{od}</button>
                                        <button disabled style={beforedate}>{(-1)*value.days}&nbsp;{bd}</button>
                                        <button disabled style={afterdate}>{value.days}&nbsp;{ad}</button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default UdhaarAllPaymentHistory