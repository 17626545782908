import './AndroidNotification.css';



const AndroidNotification = () => {
    
   
    return (
        
            <div style={{ backgroundColor: "white", paddingTop: "50px", paddingLeft: "37px", paddingRight: "32px", paddingBottom: "33px",margin:"0"}}>
                <div id='firstpara' style={{ display: "flex" }}>
                    <div style={{marginLeft:"-10%"}}>
                        <div> <img src={require("../assets/images/phone.png")} className="img-fluid" style={{ marginTop: "-38%" }}></img></div>
                    </div>
                    <div>
                        <p style={{ fontWeight: "600", fontSize: "20px",marginTop:"8%" }}>Download Our</p>
                        <p style={{ fontWeight: "600", fontSize: "20px",marginTop:"-10%" }}>Merchant app now</p>
                    </div>
                </div>

                <div style={{ marginTop: "-2%"}}>
                    <p id='para1' style={{ color: "#868D96", fontWeight: "600", fontSize: "16px",display:'flex',justifyContent:"center"}}>One stop solution</p>
                    <p id='para2' style={{ color: "#868D96", fontWeight: "600", fontSize: "16px" ,marginTop:"-2%",display:'flex',justifyContent:"center"}}>for all your business needs.</p>
                </div>

                <div style={{padding:"0 20px 0 13px"}}>
                    <div id='mainpara' style={{ border: "1px solid #CECECE", padding: "3%", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: "10px"}}>
                      <div className='main'>
                      <div  style={{display:"flex"}}>
                            <div  style={{padding:"1%"}}>
                                <img src={require("../assets/images/notilogo1.png")} className="img-fluid" style={{}}></img>
                            </div>
                            <div >
                                <p style={{fontWeight:"600",fontSize:"14px"}}>Connections</p>
                                <p style={{fontWeight:"500",fontSize:"12px"}}>Connect with your local grahak</p>
                            </div>
                        </div>
                        <div  style={{display:"flex"}}>
                            <div style={{padding:"1%"}}>
                                <img src={require("../assets/images/notilogo2.png")} className="img-fluid" style={{}}></img>
                            </div>
                            <div>
                                <p style={{fontWeight:"600",fontSize:"14px"}}>Digital Catalouge</p>
                                <p style={{fontWeight:"500",fontSize:"12px"}}>Create & Maintain your inventory.</p>
                            </div>
                        </div>
                        <div  style={{display:"flex"}}>
                            <div style={{padding:"1%"}}>
                                <img src={require("../assets/images/notilogo3.png")} className="img-fluid" style={{}}></img>
                            </div>
                            <div>
                                <p style={{fontWeight:"600",fontSize:"14px"}}>Promotion</p>
                                <p style={{fontWeight:"500",fontSize:"12px"}}>Promote your shop for free.</p>
                            </div>
                        </div>
                        <div  style={{display:"flex"}}>
                            <div style={{padding:"1%"}}>
                                <img src={require("../assets/images/notilogo4.png")} className="img-fluid" style={{}}></img>
                            </div>
                            <div>
                                <p style={{fontWeight:"600",fontSize:"14px"}}>Digital bills</p>
                                <p style={{fontWeight:"500",fontSize:"12px"}}>Create hassle free digital bills in just 3 tabs.</p>
                            </div>
                        </div>
                      </div>  
                    </div>
                </div>

                <div id='lastpara' style={{marginTop:"1%",display:"flex",justifyContent:"center"}}>
                  <img src={require("../assets/images/playstorelogo.png")} className="img-fluid" style={{}} onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.indotechs.enbillformerchant", "_blank")}></img>
                </div>

                <div style={{marginTop:"1%",display:"flex",justifyContent:"center"}}>
                    <button onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.indotechs.enbillformerchant", "_blank")} style={{color:"white",backgroundColor:"#198952" ,padding:"10px 50px",borderRadius:"15px", border:"none"}}>Explore the App now</button>
                </div>


            </div>
       
    )
}
export default AndroidNotification;