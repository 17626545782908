import TeamLeft from "./TeamLeft";
import TeamAddNew from "./TeamAddNew";
import TeamMemberDetails from "./TeamMemberDetails";
import TeamAddMember from "./TeamAddMember";
import TeamGetOTP from "./TeamGetOTP";
import { useEffect, useState } from "react";
import TeamFirstTimeUser from "./TeamFirstTimeUser";
import Helper from "../util/Helper";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

const Team = () => {

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const[memberdetails,setMemberdetails]=useState(false);
    const[otppage,setOtppage]=useState(false);
    const[addmember,setAddmember]=useState(false)
    const[send_contact_id,setSend_contact_id]=useState(null)

    const[showTeamList, setShowTeamList] = useState([])
    const[teamEmptystate, setTeamEmptyState] = useState(false)
    const[skeletonLoading, setSkeletonLoading] = useState(true)

    useEffect(()=>{
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/contact/get_team_members/?web=1`,
            headers: { "Content-Type": "application/json", token: cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                if (response.data.code === 200) {              
                    setShowTeamList(Helper.encypt_decrypt(response.data.data)); 
                    setTeamEmptyState(false)
                    setSkeletonLoading(false)                        
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else if(response.data.code === 204) {
                    setTeamEmptyState(true)
                    setSkeletonLoading(false)
                }  
            })
            .catch((err) => {
                console.log(err);
            });
    },[])

    return(
        <div className="col-10" style={{  backgroundColor: "rgb(245, 245, 245)", padding: "20px", display: "flex" }}>
            {teamEmptystate ? 
                <TeamFirstTimeUser setTeamEmptyState={setTeamEmptyState} setOtppage={setOtppage} />
            :
                <>
                    <TeamLeft setOtppage={setOtppage} skeletonLoading = {skeletonLoading} showTeamList = {showTeamList} memberdetails={memberdetails} setMemberdetails={setMemberdetails} setSend_contact_id={setSend_contact_id} ></TeamLeft>
                    {memberdetails?
                        <TeamMemberDetails addmember={addmember} setAddmember={setAddmember} memberdetails={memberdetails} setMemberdetails={setMemberdetails}  send_contact_id={send_contact_id}></TeamMemberDetails>
                    :
                        <>
                            {otppage ?
                                <TeamGetOTP  otppage={otppage} setOtppage={setOtppage}></TeamGetOTP>
                            :
                                <>
                                    {addmember ? 
                                        <TeamAddMember addmember={addmember} setAddmember={setAddmember}></TeamAddMember>
                                    :
                                        <TeamAddNew otppage={otppage} setOtppage={setOtppage}></TeamAddNew>
                                    }
                                </>
                            }            
                        </>
                    }
                </>
            }
        </div>
    )
}
export default Team;