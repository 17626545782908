import React, { useEffect, useState } from "react";
import Grahak from "./Grahak";

const NewGrahak = ({globalCustomerId, setGrahakDtlFrmGTB}) => {

  const[showAddGrahak, setShowAddGrahak] = useState(false)
  const [showUpdateGrahak, setShowUpdateGrahak] = useState(false)
  const [getGrahakId, setGetGrahakId] = useState(null)

  useEffect(() => {
    if(globalCustomerId && globalCustomerId != null) {
      setShowUpdateGrahak(true)
    }
  },[globalCustomerId])

  return (
        <Grahak setGrahakDtlFrmGTB = {setGrahakDtlFrmGTB} globalCustomerId = {globalCustomerId} getGrahakId = {getGrahakId} setGetGrahakId = {setGetGrahakId} showUpdateGrahak = {showUpdateGrahak} setShowUpdateGrahak={setShowUpdateGrahak} showAddGrahak = {showAddGrahak} setShowAddGrahak = {setShowAddGrahak}></Grahak>
  );
};

export default NewGrahak;
