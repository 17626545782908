import React,{ useEffect, useRef, useState } from 'react'
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Document, Page, pdfjs } from "react-pdf";
import './BillGenerate.css'
import { useNavigate } from 'react-router-dom';

import ReactToPrint, {useReactToPrint} from 'react-to-print';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ShowBill = ({setShowBillDetail, getCardBillId, setShowAddGrahak, globalBillId, setBillDetailForReturnReplace, setShowViewBills, setAccessReturnReplace}) => {

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate()
  
  const [billPdf, setBillPdf] = useState(null)
  const [billIdForApi, setBillIdForApi] = useState(null)
  const [itemList, setItemList] = useState([])
  const [removeItemList, setRemoveItemList] = useState([])
  const [totalAmt, setTotalAmt] = useState(null)
  const [getMerchantDetail, setGetMerchantDetail] = useState(null)
  const [imgUrl, setImgUrl] = useState(null)

  const [showReturnReplaceBtn, setShowReturnReplaceBtn] = useState(false)
  // const [showDownloadBtn, setShowDownloadBtn] = useState(false)
  const [cancelBillItemList, setCancelBillItemList] = useState([])

  const [paymentMode,setPaymentMode]=useState([]);
  const [paymentModeAmt,setPaymentModeAmt]=useState([]);
  const [cash,setCash]=useState(false);
  const [card,setCard]=useState(false);
  const [online,setOnline]=useState(false);
  const [udhaar,setUdhaar]=useState(false);
  const [cheque, setCheque] = useState(false);
  const [cashAmount,setCashAmount]=useState(null);
  const [cardAmount,setCardAmount]=useState(null);
  const [chequeAmount, setChequeAmount] = useState(null);
  const [onlineAmount,setOnlineAmount]=useState(null);

  var sum = 0 

  const [getBillPdf, setGetBillPdf] = useState(null)
  const componentRef = useRef();

  useEffect(() => {
    if(globalBillId && globalBillId != null) {
      setBillIdForApi(globalBillId)
    }
  },[globalBillId])

  useEffect(() => {
    if(getCardBillId && getCardBillId != null) {
      setBillIdForApi(getCardBillId)
    }
  },[getCardBillId])

  useEffect(() => {
    if(billIdForApi && billIdForApi != null) {
      billDetailFunc()
    }
  },[billIdForApi])

  const billDetailFunc = () => {
    const getBillListUrl = {
      method: "GET",
      url: Helper.getWsUrl() + `/vouchers/customer_bill_details/${billIdForApi}?web=1`,
      headers: { "Content-Type": "application/json", 'token': cookies.token },
    };
    axios(getBillListUrl)
    .then((response) => {           
        if (response.data.code === 200) {
          console.log("bill detail: ", Helper.encypt_decrypt(response.data.data))
          setShowReturnReplaceBtn(false);
          setGetBillPdf(Helper.encypt_decrypt(response.data.data).bill_pdf)
          setBillPdf(Helper.encypt_decrypt(response.data.data))  
          setItemList(Helper.encypt_decrypt(response.data.data).billItems)  
          setRemoveItemList(Helper.encypt_decrypt(response.data.data).remove_item)
          setPaymentMode(Helper.encypt_decrypt(response.data.data).paymentMode)
          setPaymentModeAmt(Helper.encypt_decrypt(response.data.data).pay_mode_amount)    
        }
        else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
    })
    .catch((error) => {
        alert(error);
    })
  }

  useEffect(() => {
    const requestOptions = {
        method: "GET",
        url: Helper.getWsUrl() + `/contact/get_contact/0/?app_type=0&web=1`,
        headers: { "Content-Type": "application/json", "token": cookies.token },
    };
    axios(requestOptions)
        .then((response) => {
            if (response.data.code === 200) {
                setGetMerchantDetail(Helper.encypt_decrypt(response.data.data))
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            }
        })
        .catch((err) => {
            console.log(err)
        })
  }, [])

  useEffect(() => {
    if (getMerchantDetail && getMerchantDetail.logo === 1) {
        setImgUrl(getMerchantDetail.m_logo_url)
    } else {
        let url = require("../assets/images/enbill_logo.png")
        setImgUrl(url)
    }
  }, [getMerchantDetail])

  useEffect(() => {
    if(billPdf && billPdf != null) {
      billPdf.billItems.map((value) => {
        if(!cancelBillItemList.find((element) => value.iv_id === element.iv_id)){
          setCancelBillItemList((prevState) => [
            ...prevState, {
              item_id: value.item_id,
              iv_id: value.iv_id,
              quantity: value.itemQty
            }
          ])
        }
      })
    }

    // if(billPdf && billPdf.bill_pdf === "--NA--") {
    //   const requestOptions6 = {
    //     method: "GET",
    //     url: Helper.getWsUrl() + `/reports/generate_invoice/${billIdForApi}/?web=1`,
    //     headers: { "Content-Type": "application/json", Token: cookies.token },
    //   };
    //   axios(requestOptions6)
    //     .then((response) => {
    //       if (response.data.code === 200) {
    //         billDetailFunc()
    //       } else if (response.data.code === 401) {
    //         removeCookie("logged_in")
    //         removeCookie("token")
    //         setTimeout(() => {
    //             navigate('/')
    //             localStorage.setItem("isAuthenticated", false)
    //             localStorage.removeItem("isAuthenticated")
    //         }, 1000)
    //       } 
    //     })
    //     .catch((error) => {
    //       alert(error);
    //     });
    // }
  },[billPdf])

  useEffect(() => {
    if(itemList && itemList.length > 0) {
      itemList && itemList.map((value) => {
        sum += parseFloat((value.rate) * (value.itemQty))
      })
      setTotalAmt(sum)
    }
  },[itemList])

  const downloadBillPdf = () => {
    window.open(billPdf.bill_pdf, '_blank', 'noopener,noreferrer')
  }

  const returnRepalceFunc = (c_id, b_id) => {
    const requestOptions = {
      method: "GET",
      url: Helper.getWsUrl() + `/vouchers/return_replace_new/${c_id}/${b_id}/?web=1`,
      headers: { "Content-Type": "application/json", "token": cookies.token },
    };
    axios(requestOptions)
      .then((response) => {
          if (response.data.code === 200) {
            setBillDetailForReturnReplace(billPdf)
            setAccessReturnReplace(true)
            setShowViewBills(true)
          } else if (response.data.code === 401) {
              removeCookie("logged_in")
              removeCookie("token")
              setTimeout(() => {
                  navigate('/')
                  localStorage.setItem("isAuthenticated", false)
                  localStorage.removeItem("isAuthenticated")
              }, 1000)
          } else {
            alert(response.data.msg)
          }
      })
      .catch((err) => {
          console.log(err)
      })
  }

  const returnRepalceForCancelFunc = (c_id, b_id) => {
    const requestOptions = {
      method: "GET",
      url: Helper.getWsUrl() + `/vouchers/return_replace_new/${c_id}/${b_id}/?web=1`,
      headers: { "Content-Type": "application/json", "token": cookies.token },
    };
    axios(requestOptions)
      .then((response) => {
          if (response.data.code === 200) {
            CancelBillFunc(c_id, b_id)
          } else if (response.data.code === 401) {
              removeCookie("logged_in")
              removeCookie("token")
              setTimeout(() => {
                  navigate('/')
                  localStorage.setItem("isAuthenticated", false)
                  localStorage.removeItem("isAuthenticated")
              }, 1000)
          } else {
            alert(response.data.msg)
          }
      })
      .catch((err) => {
          console.log(err)
      })
  }

  const CancelBillFunc = (c_id, bill_id) => {
    const requestOptions = {
      method: "PATCH",
      url: Helper.getWsUrl() + `/vouchers/cancel_bill_new/${c_id}/${bill_id}/?web=1`,
      headers: { "Content-Type": "application/json", "token": cookies.token },
      data:{
        bill_Item_details: cancelBillItemList,
        credit_note: billPdf.totalInvoice
      }
    };
    axios(requestOptions)
      .then((response) => {
          if (response.data.code === 201) {
            window.location.reload()
          } else if (response.data.code === 401) {
              removeCookie("logged_in")
              removeCookie("token")
              setTimeout(() => {
                  navigate('/')
                  localStorage.setItem("isAuthenticated", false)
                  localStorage.removeItem("isAuthenticated")
              }, 1000)
          }
      })
      .catch((err) => {
          console.log(err)
      })
  }

  useEffect(()=>{
    setCash(false)
    setUdhaar(false)
    setCard(false)
    setOnline(false)
    setCheque(false)
    paymentMode && paymentMode.map((value) => {
            if (value === 1) {
                    setCash(true)
            } else if (value === 2) {
                    setUdhaar(true)
            } else if (value === 3) {
                    setCard(true)
            } else if (value === 4) {
                    setOnline(true)
            } else if (value === 5) {
                    setCheque(true)
            }
    })
  },[paymentMode]) 

  useEffect(()=>{
    setCashAmount(null)
    setCardAmount(null)
    setChequeAmount(null)
    setOnlineAmount(null)
      paymentModeAmt && paymentModeAmt.map((value, index) => {
              if(paymentMode[index] === 1) {
                      setCashAmount(value)
              } else if(paymentMode[index] === 3) {
                      setCardAmount(value)
              } else if(paymentMode[index] === 4) {
                      setOnlineAmount(value)
              } else if(paymentMode[index] === 5) {
                      setChequeAmount(value)
              }
      })
  },[paymentModeAmt])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Bill Detail',
  })

  return (
    <div style={{width: "65%", position:"relative", height: "100%", overflow:"hidden", borderRadius: "20px", padding: "20px 20px 0", backgroundColor: "#fff", marginLeft: "20px", textAlign: "center", display: "flex", flexDirection: "column"}}>
        <div style={{display: "flex", width:"100%", justifyContent:"end"}}>
          {billPdf && billPdf.bill_pdf != "--NA--" ? 
            <>
              <img onClick={handlePrint} style={{cursor:"pointer", width:"22px"}} src={require("../assets/images/Print-Icon.png")}/>    
              <img onClick={downloadBillPdf} style={{cursor:"pointer", marginLeft:"20px"}} src={require("../assets/images/download icon.png")}/>
            </>
          : null }     
          <img onClick={() => {setShowBillDetail(false)}} style={{cursor:"pointer", marginLeft:"20px"}} src={require("../assets/images/cancel.png")}/>
        </div>

        <div ref={componentRef} style={{width:"100%", height: window.innerHeight, zIndex:"-1", position:"absolute", left:"0", top:"0", objectFit:"contain"}}>
          {getBillPdf &&
            <div>
              <div>
                <Document style={{width:"100%", height:"100%"}} file={getBillPdf && getBillPdf} ref={componentRef}>
                  <Page pageNumber={1} />
                </Document>
                {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js">
                  <Viewer fileUrl={getBillPdf && getBillPdf} />
                </Worker> */}
              </div>
            </div>
          } 
        </div>

        <div id='allTableItemList' style={{width:"100%", overflowY:"scroll",  height:"60vh", display:"flex", alignItems:"center", flexDirection:"column", marginTop:"5px"}}>
          <div style={{width:"85%", position:"relative", padding:"20px", borderRadius:"10px", border:"1px dashed #d9d9d9"}}>
            <img style={{width:"160px", height:"160px", right:"0", top:"50%", opacity:"0.4", position:"absolute", display: (billPdf && billPdf.bill_adjusted === 2) ? "block" : "none"}} src={require('../assets/images/Cancelled.png')} />
            <img style={{width:"160px", height:"160px", right:"0", top:"50%", opacity:"0.6", position:"absolute", display: (billPdf && billPdf.bill_adjusted === 1) ? "block" : "none"}} src={require('../assets/images/Modified.png')} />
            {billPdf && billPdf.bill_adjusted === 0 && billPdf.bill_count != 4 ?
              <div style={{display:"flex", flexDirection:"column", alignItems:"end", position:"relative"}}>
                <svg onClick={() => {setShowReturnReplaceBtn(!showReturnReplaceBtn)}} style={{cursor:"pointer"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                </svg>
                {showReturnReplaceBtn ? 
                  <div style={{position:"absolute", top:"18px", width:"150px", border:"none", borderRadius:"10px",padding:"7px 20px", background:"#e8e8e8", textAlign:"left"}}>
                    <button onClick={() => {returnRepalceFunc(billPdf.customer_id, billIdForApi)}} style={{fontSize:"16px", fontWeight:"500", border:"none", background:"none"}}>Return/Replace</button>
                    <hr style={{padding:"0", margin:"0"}}/>
                    <button data-bs-toggle="modal" data-bs-target="#exampleModal1" style={{fontSize:"16px", fontWeight:"500", border:"none", background:"none"}}>Cancel Bill</button>
                  </div>
                : null }
              </div>
            : null }

            <div style={{width:"100%", textAlign:"center"}}>
              <img src={imgUrl} style={{ borderRadius: "50%", width:"80px", height:"80px", marginBottom:"10px"}} />
              <p style={{fontWeight:"600", fontSize:"18px", margin:"0"}}>{billPdf && billPdf.shopName}</p>
              <p style={{fontWeight:"500", fontSize:"16px", margin:"0"}}>{billPdf && billPdf.shopMobNumber}</p>
              <p style={{fontWeight:"500", fontSize:"16px", margin:"0"}}>{billPdf && billPdf.address}</p>
            </div>

            <div className="col-12" style={{marginTop:"20px"}}>
              <p style={{fontWeight:"600"}}>Date & time : <span style={{fontWeight:"400"}}>{billPdf && billPdf.dateTime}</span></p>
            </div>

            <div style={{marginTop:"20px", marginBottom:"20px"}}>
              <table class="table table-striped" style={{border:"none"}}>
                  <thead>
                      <tr>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Sno.</th>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Item Name</th>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Qty.</th>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Price</th>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Amount</th>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Expiry date</th>
                      </tr>
                  </thead>
                  <tbody>
                      {itemList && itemList.map((value, index) => {
                      return(
                              <tr>
                                  <td style={{border:"none", fontWeight:"500"}}>{index + 1}.</td>
                                  <td style={{border:"none", fontWeight:"500"}}>{value.itemName}</td>
                                  <td style={{border:"none", fontWeight:"500"}}>{value.itemQty}</td>
                                  <td style={{border:"none", fontWeight:"500"}}>{value.rate}</td>
                                  <td style={{border:"none", fontWeight:"500"}}>{value.amount}</td>
                                  <td style={{border:"none", fontWeight:"500"}}>{value.exp_date != null ? value.exp_date : "--N/A--"}</td>
                              </tr>
                          )
                      })}
                      {removeItemList && removeItemList.map((value, ind) => {
                        let index = itemList.length+ind
                      return(
                              <tr>
                                  <td style={{border:"none", fontWeight:"500", color:"#BA1C09"}}>{index + 1}.</td>
                                  <td style={{border:"none", fontWeight:"500", color:"#BA1C09"}}>{value.itemName}</td>
                                  <td style={{border:"none", fontWeight:"500", color:"#BA1C09"}}>{value.itemQty}</td>
                                  <td style={{border:"none", fontWeight:"500", color:"#BA1C09"}}>{value.rate}</td>
                                  <td style={{border:"none", fontWeight:"500", color:"#BA1C09"}}>{parseFloat(value.amount)}</td>
                                  <td style={{border:"none", fontWeight:"500", color:"#BA1C09"}}>{value.exp_date != null ? value.exp_date : "--N/A--"}</td>
                              </tr>
                          )
                      })}
                  </tbody>
              </table> 
            </div>

            <div style={{width:"100%", display:"flex", justifyContent:"end"}}>
                <div style={{display:"flex"}}>
                    <div style={{display:"flex", flexDirection:"column"}}>
                      <div style={{fontWeight:"600"}}>
                        Total Amount :
                      </div>
                    </div>                            
                    <div style={{display:"flex", flexDirection:"column"}}>
                      <div className=" p-0 " style={{marginLeft:"10px"}}>
                        ₹ {billPdf && billPdf.totalInvoice}
                      </div>
                    </div>
                </div>
            </div>

            <div style={{ width:"100%", margin:"20px 0" }}>
              <div style={{width:"100%", textAlign:"center"}}>
                <p style={{fontWeight:"600"}}>Payment Mode</p>
              </div>
              <div style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                <div>
                  <input id="radio-2" type="checkbox" checked={cash ? "check" : ""} />
                  <label for="radio-2" style={{marginLeft:"8px", fontWeight:"500"}} >Cash</label>
                  <p>{cashAmount && cashAmount != null ? "₹" : null} {cashAmount && cashAmount}</p>
                </div>
                <div>
                  <input id="radio-2" type="checkbox" checked={card ? "check" : ""} />
                  <label for="radio-2" style={{marginLeft:"8px", fontWeight:"500"}}>Card</label>
                  <p>{cardAmount && cardAmount != null ? "₹" : null} {cardAmount && cardAmount}</p>
                </div>
                <div>
                  <input id="radio-3" type="checkbox" checked={online ? "check" : ""} />
                  <label for="radio-3" style={{marginLeft:"8px", fontWeight:"500"}}>Online</label>
                  <p>{onlineAmount && onlineAmount != null ? "₹" : null} {onlineAmount && onlineAmount}</p>
                </div>
                <div>
                  <input id="radio-4" type="checkbox" checked={udhaar ? "check" : ""} />
                  <label for="radio-4" style={{marginLeft:"8px", fontWeight:"500"}}>Udhaar</label>
                  <p>{billPdf && billPdf.udhar_amount != 0 ? "₹" : null} {billPdf && billPdf.udhar_amount != 0 ? billPdf.udhar_amount : null}</p>
                </div>
                <div>
                  <input id="radio-4" type="checkbox" checked={cheque ? "check" : ""} />
                  <label for="radio-4" style={{marginLeft:"8px", fontWeight:"500"}}>Cheque</label>
                  <p>{chequeAmount && chequeAmount != null ? "₹" : null} {chequeAmount && chequeAmount}</p>
                </div>
              </div>
            </div>

            <div style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
              <div style={{width:"50%", textAlign:"left"}}>
                <div style={{fontWeight:"550"}}>
                  Customer Name: &nbsp;
                  {billPdf && billPdf.customerName != null && billPdf.customerName != "" ?
                    <span style={{fontWeight:"400"}}>
                      {billPdf && billPdf.customerName}
                    </span>
                  :
                    <span style={{fontWeight:"400"}}>
                      --NA--
                    </span>
                  }
                </div>
                <div style={{fontWeight:"550"}}>
                  Customer Mobile : &nbsp;
                  {billPdf && billPdf.customerMobile != null ? 
                    <span style={{fontWeight:"400"}}>
                      {billPdf && billPdf.customerMobile}
                    </span>
                  :
                    <span style={{fontWeight:"400"}}>
                      --NA--
                    </span>
                  }
                </div>
                <div style={{fontWeight:"550"}}>
                  Bill No. : &nbsp;
                  <span style={{fontWeight:"400"}}>
                    {billPdf && billPdf.billNo}
                  </span>
                </div>
                <div style={{fontWeight:"550"}}>
                  Transaction Id : &nbsp;
                  <span style={{fontWeight:"400"}}>
                    {billPdf && billPdf.payment_number}
                  </span>
                </div>
              </div>

              <div style={{width:"50%", textAlign:"right"}}>
                <div style={{fontWeight:"550", display: (billPdf && billPdf.credit_note != null && billPdf.credit_note != 0) ? "block" : "none"}}>
                  Credit Note : &nbsp;
                  <span style={{fontWeight:"400"}}>
                    ₹ {billPdf && billPdf.credit_note}
                  </span>
                </div>
                <div style={{fontWeight:"550", display: (billPdf && billPdf.extra_payble_amount != null && billPdf.extra_payble_amount != 0) ? "block" : "none"}}>
                  Extra Paid Amount : &nbsp;
                  <span style={{fontWeight:"400"}}>
                    ₹ {billPdf && billPdf.extra_payble_amount}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{zIndex:"1", marginTop:"20px"}}>
          <button onClick={() =>{
                if(localStorage.getItem("fastBilling") != 1) {
                  setShowAddGrahak(true);
                  setShowBillDetail(false);
                } else {
                  setShowViewBills(true)
                }}}
                style={{ border: "none", backgroundColor: "#008c53", borderRadius: "20px", padding: "8px 10px", color: "#fff"}}>
            <img style={{ height: "75%", width: "16%", marginRight: "15px" }} src={require("../assets/images/icon.png")}/>
            Add New
          </button>
        </div>

        <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content" style={{textAlign:"center", borderRadius:"15px"}}>
              <div class="modal-body" style={{paddingBottom:"0"}}>
                <p style={{fontWeight:"500", fontsize:"18px"}}>Are you sure, you want to cancel bill ?</p>
              </div>
              <div class="modal-footer" style={{padding:"0 16px", border:"none", margin:"0"}}>
                <button type="button" style={{border:"none", background:"none", color:"#7a7a7a", fontWeight:"600", marginBottom:"20px"}} class="btn btn-secondary" data-bs-dismiss="modal">No</button>
                <button onClick={() => {returnRepalceForCancelFunc(billPdf.customer_id, billIdForApi)}} style={{border:"none", background:"none", color:"#008c53", fontWeight:"600", marginBottom:"20px"}} type="button" data-bs-dismiss="modal">Yes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ShowBill