import "./Viewbill.css";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const ViewBills = ({setShowBillGenerate, forEditItemList, removeItemList, getCardBillId, setRemoveItemList, addedNewtemList, setAddedNewItemList, billDetailForReturnReplace, accessReturnReplace, itemListToEditBill, setItemListToEditBill, setItemListTtlAmtWithDiscForAddBill, setItemListTtlAmtForAddBill, setOverAllDiscount, setGrhkContactId, setItemListToAddBill, setGetTotalAmountForAllItem, grahakDetails, grahakDtlFrmGTB}) => {

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate()

  const [show, setShow] = useState(false);
  const [showAddItemBtn, setShowAddItemBtn] = useState(false)

  const [billItemList, setBillItemList] = useState([])
  const [billItemListFastBilling, setBillItemListFastBilling] = useState([])
  const [filteredData, setFilteredData] = useState(null);
  const [getDataFrmPostNewItem, setGetDataFrmPostNewItem] = useState(null)
  const [getObjectData,setGetObjectData] = useState(null)
  const [getArrayData, setGetArrayData] = useState(null)
  const [getUnitData, setGetUnitData] = useState(null)  
  const [miuIdName, setMiuIdName] = useState(null);
  const [tableArr, setTableArr] = useState([])
  const [overAllDisc, setOverAllDisc] = useState(0)
  const [totalAmount, setTotalAmount] = useState(null)
  const [addPost,setAddPost]=useState(null)
  const [totalAmtWithOverallDisc, setTotalAmtWithOverallDisc] = useState(null)
  const [getScannedItemData, setGetScannedItemData] = useState([])
  const [grhkDtl, setGrhkDtl] = useState(null)
  const [getGSTData, setGetGSTData] = useState(null) 
  const [showDeleteBtn, setShowDeleteBtn] = useState(false)
  const [itemListForReturnReplace, setItemListForReturnReplace] = useState([])
  const [returnReplaceItemListArray, setReturnReplaceItemListArray] = useState([])

  const [grahakContactFastBilling, setGrahakContactFastBilling] = useState(null)
  const [grahakDetailFastBilling, setGrahakDetailFastBilling] = useState(null)
  const [showProgressBar, setShowProgressBar] = useState(false)
  
  const [itemget, setItemget] = useState(null);
  const [fieldValue, setFieldValue] = useState ({
    item_id: null,
    iv_id: null,
    itemName: null,
  })

  const [scannedData, setScannedData] = useState([])

  const [emptyObj, setEmptyObj] = useState({
    itemName: null,
    size: null,
    unit: null,
    mrp: null,
    rate: null,
    gst: null,
    discount: null,
    qty: null,
    amt: null,
    itemID:null,
    ivID:null,
    expiryDate:null
  })

  var tA = 0

  useEffect(() => {
    if(grahakDetails && grahakDetails != null) {
      setGrhkDtl(grahakDetails)
    }
  },[grahakDetails])

  useEffect(() => {
    if(billDetailForReturnReplace && billDetailForReturnReplace != null) {
      setItemListForReturnReplace(billDetailForReturnReplace.billItems)
      setGrhkDtl({
        contact_id: billDetailForReturnReplace.customer_id
      })
    }
  },[billDetailForReturnReplace])

  useEffect(() => {
    if(grahakDtlFrmGTB && grahakDtlFrmGTB != null) {
      setGrhkDtl(grahakDtlFrmGTB)
      setGrahakContactFastBilling(grahakDtlFrmGTB.mobile_number)
    }
  },[grahakDtlFrmGTB])

  const getItems = (event) => {
    setItemget(event.target.value);
  }; 

  const getAddBookPost = useCallback(event => {
    const { name, value } = event.target;
    setAddPost({ ...addPost, [name]: value });
  });

  useEffect (() => {
    if(itemget && itemget != null || itemget == ""){
      itemSearch(itemget)
      setShowAddItemBtn(true)
    }
  },[itemget])

  const itemSearch = (itemName) => {
    const requestOption = {
      method: "GET",
      url: Helper.getWsUrl() + `/ItemSearch/?name=${itemName != null && itemName != "" ? itemName : null}&web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOption)
      .then((response) => {      
        if (response.data.code === 200) {
            setFilteredData(Helper.encypt_decrypt(response.data.data));
            setShow(true)
        }
        else if(response.data.code === 204){
          setShow(false)
          setShowAddItemBtn(true)
        } 
        else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  } 

  const handleAutoFill = (ivId) => {
    document.getElementById("empty").reset()
    setShow(false)
    setShowAddItemBtn(false)

    let inputField = filteredData && filteredData.find(value => value.iv_id.includes(ivId));

    setFieldValue((prevState) => ({
      ...prevState,
      item_id: inputField.item_id,
      iv_id: inputField.iv_id ,
      itemName: inputField.itemName,
    }))
  } 

  const postNewItemDetail=()=>{
    const requestOptions4 = {
      method: "POST",
      url: Helper.getWsUrl() + "/items/post_bookeditem/?web=1",
      headers: { "Content-Type": "application/json", Token: cookies.token },
      data: {
        itemDetails:{        
          item_name:itemget,        
          size:addPost.size,
          miu_id:parseInt(addPost.miu_id), 
          mrp:parseFloat(addPost.mrp),
          gst_tax_per:parseFloat(addPost.gst_tax_per),
          rate:parseFloat(addPost.rate), 
          in_stock:1,
          is_active:1,
          discount_per:parseFloat(((parseFloat(addPost.mrp)-parseFloat(addPost.rate)) * 100) / parseFloat(addPost.mrp)).toFixed(2),
          min_qty: 1,
        }       
      },
    };
    axios(requestOptions4)
      .then((response) => {
        if (response.data.code === 201) {
          document.getElementById("emptyModal").reset()
          setAddPost({
            ...addPost,
            size: null,
            miu_id: null,
            mrp: null,
            rate: null,
            addQty: null,
            gst_tax_per: null,
          })
          setShow(false)
          setGetDataFrmPostNewItem(Helper.encypt_decrypt(response.data.data))
        } else if (response.data.code === 204) {
          alert(response.data.msg)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  useEffect(() => {
    if(fieldValue && fieldValue.item_id != null && fieldValue.iv_id != null) {
        if(!returnReplaceItemListArray.find((element) => fieldValue.iv_id === element.ivID)) {
          getItemDetails(fieldValue.item_id, fieldValue.iv_id)
        }
    }
  },[fieldValue])

  useEffect(() => {
    if(getDataFrmPostNewItem && getDataFrmPostNewItem.item_id != null && getDataFrmPostNewItem.iv_id != null) {
      getItemDetails(getDataFrmPostNewItem.item_id, getDataFrmPostNewItem.iv_id)
    }
  },[getDataFrmPostNewItem])

  const getItemDetails = (itemId,ivId) => {
    const requestOption = {
      method: "GET",
      url: Helper.getWsUrl() + `/items/get_items/${itemId}/${ivId}/?web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOption)
      .then((response) => { 
        if (response.data.code === 200) {
          setGetObjectData(Helper.encypt_decrypt(response.data.data).item_data)
          setGetArrayData(Helper.encypt_decrypt(response.data.data).item_verient[0])   
        }
        else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const edityQuantityInArrayFunc = (e, index, ivID) => {
    if(returnReplaceItemListArray.find((element) => ivID === element.ivID)) {
      const re = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/
      if(e.target.value === '' || re.test(e.target.value)) {
        let indexOfArray
        let editQtyVal
        itemListForReturnReplace.map((value) => {
          if(value.iv_id === ivID) {
            editQtyVal = value.itemQty
          }
        })
        let filterTableArray = [...tableArr]
        let indexOfEditQty = index
        let filtrTblArrElement = filterTableArray.filter((item, index) => {
          if(index == indexOfEditQty){
            indexOfArray = index
            return item
          }
        })
        if(editQtyVal < e.target.value) {
          filtrTblArrElement[0].qty = editQtyVal
        } else {
          filtrTblArrElement[0].qty = e.target.value
          if(!removeItemList.find((element) => element.iv_id === ivID)) {
            itemListForReturnReplace.map((value) => {
              if(value.iv_id === ivID) {
                setRemoveItemList((prevState) => [
                  ...prevState, {
                    item_id: value.item_id,
                    iv_id: value.iv_id,
                    quantity: editQtyVal - e.target.value
                  }
                ])
              }
            })
          } else {
            let filterTableArray = [...removeItemList] 
            let indexOfArray
            let filtrTblArrElement = filterTableArray.filter((item, index) => {
              if(item.iv_id === ivID) {
                indexOfArray = index
                return item
              }
            })
            filtrTblArrElement[0].quantity = editQtyVal - e.target.value
            filterTableArray[indexOfArray] = filtrTblArrElement[0]
            setRemoveItemList(filterTableArray)
          }
        }
        filtrTblArrElement[0].amt = filtrTblArrElement[0].rate * filtrTblArrElement[0].qty
        filterTableArray[indexOfArray] = filtrTblArrElement[0]
        setTableArr(filterTableArray)
      }
    } else {
      const re = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/
      if(e.target.value === '' || re.test(e.target.value)) {
        let filterTableArray = [...tableArr]
        let indexOfEditQty = index
        let editQtyVal = e.target.value
        let indexOfArray
        let filtrTblArrElement = null
        filtrTblArrElement = filterTableArray.filter((item, index) => {
          if(index == indexOfEditQty){
            indexOfArray = index
            return item
          }
        })
        filtrTblArrElement[0].qty = editQtyVal
        filtrTblArrElement[0].amt = filtrTblArrElement[0].rate * editQtyVal
        filterTableArray[indexOfArray] = filtrTblArrElement[0]
        setTableArr(filterTableArray)
      }
    }
  }

  useEffect(() => {
      getUnitValue()
  }, [])

  const getUnitValue = () => {
    const requestOption = {
      method: "GET",
      url: Helper.getWsUrl() + `/masters/get_itemunit_active/1/?web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOption)
      .then((response) => {    
        if (response.data.code === 200) {
          setGetUnitData(Helper.encypt_decrypt(response.data.data))
        }
        else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });

      const requestOptionForGst = {
        method: "GET",
        url: Helper.getWsUrl() + `/masters/get_merchant_gst_per_new/?web=1`,
        headers: { "Content-Type": "application/json", Token: cookies.token },
      };
      axios(requestOptionForGst)
        .then((response) => {  
          if (response.data.code === 200) {
            setGetGSTData(Helper.encypt_decrypt(response.data.data))
          }
          else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          }
        })
        .catch((error) => {
          alert(error);
        });
  }

  useEffect(() => {
    let filteredMiuId = null
    if(getUnitData && getObjectData && getObjectData.miu_id){
      filteredMiuId = getUnitData.filter((item) => {
        if(item.miu_id == getObjectData.miu_id) {
          return item          
        }
      })      
      setMiuIdName(filteredMiuId && filteredMiuId[0].name)
    }
  }, [getUnitData, getObjectData])

  // useEffect(() => {
  //   let Qty = getArrayData && getArrayData.min_qty
  //   if(getArrayData && getArrayData) {
  //     if(Qty === 0 || Qty === null) {
  //       Qty = 1
  //     }
  //     let amountget = Qty * getArrayData.selling_price
  //     let gstValue = getObjectData.gst_tax_per/100
  //     let amtWithGst = getArrayData.mrp + gstValue
  //     setAmount(amountget)
  //     setTaxebleAmt(amtWithGst)
  //   }
  // },[getArrayData,getObjectData])

  useEffect(() => {
    if(getScannedItemData && getScannedItemData.length > 0) {
      getScannedItemData.map((value, index) => {
        if(!returnReplaceItemListArray.find((element) => value.iv_id === element.ivID)) {
          setScannedData((scannedData) => [
            ...scannedData,{
              itemName: value.item_name,
              size: value.size,
              unit: value.unit,
              mrp: value.mrp,
              rate: value.selling_price,
              gst: value.gst_per_item,
              discount: value.discount,
              qty: value.quantity === 0 || value.quantity === null ? 1 : value.quantity,
              amt: value.quantity === 0 || value.quantity === null ? 1 * value.selling_price : value.quantity * value.selling_price,
              itemID:value.item_id,
              ivID:value.iv_id,
              expiryDate:value.exp_date
          }])
        }
      }) 
    }
  },[getScannedItemData])
  
  useEffect(() => {
    if(scannedData && scannedData.length > 0) {
      scannedData.map((value, index) => {
        if(tableArr.find((element) => value.ivID === element.ivID)) {
          let filterTableArray = [...tableArr]
          let indexOfArray
          let filtrTblArrElement = filterTableArray.filter((item, index) => {
                  if(item.ivID == value.ivID){
                      indexOfArray = index
                      return item
                  }
              })
              filtrTblArrElement[0].qty++
              filtrTblArrElement[0].amt = filtrTblArrElement[0].rate * filtrTblArrElement[0].qty
              filterTableArray[indexOfArray] = filtrTblArrElement[0]
              setTableArr(filterTableArray)
        } else {
          setTableArr(tableArr => [
            ...tableArr,scannedData[index]
          ])
        }
      })
    }
  },[scannedData])

  useEffect(() => {
    if(forEditItemList && forEditItemList === true) {
      setTableArr(itemListToEditBill)
    }
  },[forEditItemList])

  useEffect(() => {
    if(itemListForReturnReplace && itemListForReturnReplace.length > 0) {
      itemListForReturnReplace.map((value, index) => {
        setReturnReplaceItemListArray((returnReplaceItemListArray) => [
          ...returnReplaceItemListArray, {
              itemName: value.itemName,
              size: value.size[0],
              unit: value.unit,
              mrp: value.mrp,
              rate: value.rate,
              gst: value.gst,
              discount: parseFloat(value.itemDiscount).toFixed(2),
              qty: value.itemQty,
              amt: value.itemQty * value.rate,
              itemID: value.item_id,
              ivID: value.iv_id,
              expiryDate: value.exp_date
        }])
      })
    }
  },[itemListForReturnReplace])

  useEffect(() => {
    if(!forEditItemList && returnReplaceItemListArray && returnReplaceItemListArray.length > 0) {
      setTableArr(returnReplaceItemListArray)
    }
  },[returnReplaceItemListArray])
  
  useEffect(() => {
    if(getObjectData && (itemget || fieldValue.itemName || getObjectData.item_name) && getObjectData!= null && getArrayData && getArrayData != null)
    {
      setEmptyObj((emptyObj) => ({
        ...emptyObj,
        itemName: getObjectData.item_name,
        size: getArrayData.size,
        unit: miuIdName,
        mrp: getArrayData.mrp,
        rate: getArrayData.selling_price,
        gst: getObjectData.gst_tax_per,
        discount: getArrayData.discount_per,
        qty: `${getArrayData && getArrayData.min_qty != null && getArrayData.min_qty != 0 ? getArrayData.min_qty : "1"}`,
        amt: (getArrayData.min_qty != null && getArrayData.min_qty != 0) ? getArrayData.min_qty * getArrayData.selling_price : 1 * getArrayData.selling_price ,
        itemID:getArrayData.item_id,
        ivID:getArrayData.iv_id,
        expiryDate:getArrayData.exp_date
      }))
    }
  },[itemget, fieldValue.itemName, getArrayData && getArrayData.size, getObjectData && getObjectData.item_name])

  useEffect(() => {
    if(emptyObj.itemName) {
      if(tableArr.find((element) => emptyObj.ivID === element.ivID)) {
        let filterTableArray = [...tableArr]
        let indexOfArray
        let filtrTblArrElement = filterTableArray.filter((item, index) => {
                if(item.ivID == emptyObj.ivID){
                    indexOfArray = index
                    return item
                }
            })
            filtrTblArrElement[0].qty++
            filtrTblArrElement[0].amt = filtrTblArrElement[0].rate * filtrTblArrElement[0].qty
            filterTableArray[indexOfArray] = filtrTblArrElement[0]
            setTableArr(filterTableArray)
      } else {
        setTableArr(tableArr => [
          ...tableArr,emptyObj
        ])
      }
    }
  },[emptyObj])

  useEffect(() => {
    setEmptyObj((emptyObj) => ({
      ...emptyObj,
          itemName: null,
          size: null,
          unit: null,
          mrp: null,
          rate: null,
          gst: null,
          discount: null,
          qty: null,
          amt: null,
          itemID:null,
          ivID:null,
          expiryDate:null
    }))
    setScannedData([])
    setGetArrayData(null)
    setGetObjectData(null)
    setMiuIdName(null)                          
  },[tableArr])

  useEffect(() => {
    if(tableArr && tableArr.length >= 0) {
        {tableArr.map ((value, index) => {
          tA += value.amt
        })}
        setTotalAmount(tA)
    }
  },[tableArr])

  const getOverAllDiscFunc = (e) => {
        setOverAllDisc({
          ...overAllDisc,
          disc: e.target.value
        })
  }

  useEffect(() => {
    if(totalAmount && totalAmount!=null){
      setTotalAmtWithOverallDisc(parseFloat(totalAmount).toFixed(2))
    }
  },[totalAmount])
  
  useEffect(() => {
    if(overAllDisc && overAllDisc != null) {
      if(overAllDisc.disc > totalAmount) {
        setTotalAmtWithOverallDisc(totalAmount)
      } else {
        let amtAftrOvrallDisc = parseFloat(totalAmount - overAllDisc.disc)
        setTotalAmtWithOverallDisc(parseFloat(amtAftrOvrallDisc).toFixed(2))  
      }    
    }
  },[overAllDisc])

  const billItemListFunc = () => {
    removeListItemEdit()
    addedNewtemListfunc()
    if(tableArr && tableArr.length > 0) {
      setItemListToEditBill(tableArr)
      {tableArr && tableArr.map ((value, index) => {
        setBillItemList((billItemList) => [
          ...billItemList, {
            item_name: value.itemName,
            amount: parseFloat(value.rate * value.qty).toFixed(2),
            discount_per: parseFloat(value.discount).toFixed(2),
            gst_per: parseFloat(value.gst).toFixed(2),
            item_id: value.itemID,
            iv_id: value.ivID,
            quantity: parseFloat(value.qty),
            rate: parseFloat(value.rate).toFixed(2),
            taxable_amount: 0.0,
            unit_id: parseInt(value.unit),
            exp_date: value.expiryDate
          }
        ])
      })}
      setGetTotalAmountForAllItem(totalAmtWithOverallDisc)
    }else {
      alert("please add item")
    }
  }

  useEffect(() => {
    if(billItemList && billItemList.length > 0 ){
      if(overAllDisc && overAllDisc.disc != null) {
        var OvrAll_Disc=  parseFloat(overAllDisc.disc)
      }
      
      setItemListTtlAmtWithDiscForAddBill(totalAmtWithOverallDisc)
      setItemListTtlAmtForAddBill(totalAmount)
      setOverAllDiscount(OvrAll_Disc)
      setGrhkContactId(grhkDtl.contact_id)
      setItemListToAddBill(billItemList)
      setShowBillGenerate(true)
    } 
  },[billItemList])

  const scanBarcodeFunc = (customerId) => {
    const requestOption = {
      method: "GET",
      url: Helper.getWsUrl() + `/barcode_scanner/web_scan/${customerId}/?web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOption)
      .then((response) => {     
        if (response.data.code === 200) {
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const scanedItemFunc = (customerId) => {
    const requestOption = {
      method: "GET",
      url: Helper.getWsUrl() + `/barcode_scanner/get_scanned_items/${customerId}/?web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOption)
      .then((response) => {   
        if (response.data.code === 200) {
          setGetScannedItemData(Helper.encypt_decrypt(response.data.data))
        }
        else if (response.data.code === 204) {
          alert("Please scan some items first")          
        }
        else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const forDeleteRowFrmTbl = (ivId, index) => {
    setTableArr(tableArr.filter((element, ind) => 
      ind != index
    ))
    setShowDeleteBtn(false);
    if(itemListForReturnReplace.find((elemet) => ivId === elemet.iv_id)) {
      if(!removeItemList.find((element) => element.iv_id === ivId)) {
        itemListForReturnReplace.map((value) => {
          if(value.iv_id === ivId) {
            setRemoveItemList((prevState) => [
              ...prevState, {
                item_id: value.item_id,
                iv_id: value.iv_id,
                quantity: value.itemQty
              }
            ])
          }
        })
      } else {
        let editQtyVal
        itemListForReturnReplace.map((value) => {
          if(value.iv_id === ivId) {
            editQtyVal = value.itemQty
          }
        })
        let filterTableArray = [...removeItemList] 
          let indexOfArray
          let filtrTblArrElement = filterTableArray.filter((item, index) => {
            if(item.iv_id === ivId) {
              indexOfArray = index
              return item
            }
          })
          filtrTblArrElement[0].quantity = editQtyVal
          filterTableArray[indexOfArray] = filtrTblArrElement[0]
          setRemoveItemList(filterTableArray)
      }
    }
  }

  const removeListItemEdit = () => {
    if(removeItemList && removeItemList.length > 0) {
      if(removeItemList.find((element) => element.quantity === 0)) {
        setRemoveItemList(removeItemList.filter((value) => 
          value.quantity != 0
        ))
      }
      removeItemList.map((value) => {
        if(tableArr.find((element) => element.ivID === value.iv_id)) {
          itemListForReturnReplace.map((item) => {
            if(item.iv_id === value.iv_id) {
                setRemoveItemList(removeItemList.filter((val) => 
                  val.quantity != item.itemQty
                ))
            }
          })
        }
      })
    }
  }

  const addedNewtemListfunc = () => {
    tableArr.map((value) => {
      if(!itemListForReturnReplace.find((element) => element.iv_id === value.ivID)) {
        setAddedNewItemList((prevState) =>[
          ...prevState, {
            item_id: value.itemID,
            iv_id: value.ivID,
            quantity: value.qty,
            bill_id: getCardBillId
          }
        ])
      }
    })
  }

  const styles = {
    width: "100%",
    textAlign: "left",
    color: "#868D96",
    fontWeight: 400,
    fontSize: "14px"
  };
  
  const getContactsearch = (event) => {
    const re = /^[0-9\b]+$/;
    if(event.target.value === '' || re.test(event.target.value)) {
      setGrahakContactFastBilling (event.target.value);
    }
  };

  const getGrahakDetailFastBillingFunc = useCallback(event => {
    const { name, value } = event.target;
    setGrahakDetailFastBilling({ ...grahakDetailFastBilling, [name]: value });
  });

  const setTableForFastBillingFunc = () => {
    setShowProgressBar(true)
    {tableArr && tableArr.map ((value, index) => {
      if(!billItemListFastBilling.find((ele) => ele.iv_id === value.ivId)) {
        setBillItemListFastBilling((billItemListFastBilling) => [
          ...billItemListFastBilling, {
            item_name: value.itemName,
            amount: parseFloat(value.rate * value.qty).toFixed(2),
            discount_per: parseFloat(value.discount).toFixed(2),
            gst_per: parseFloat(value.gst).toFixed(2),
            item_id: value.itemID,
            iv_id: value.ivID,
            quantity: parseFloat(value.qty),
            rate: parseFloat(value.rate).toFixed(2),
            taxable_amount: 0.0,
            unit_id: parseInt(value.unit),
            exp_date: value.expiryDate
          }
        ])
      }
    })}
  }

  useEffect(() => {
    if(billItemListFastBilling && tableArr && billItemListFastBilling.length > 0 && (billItemListFastBilling.length === tableArr.length)) {
      if(grahakDtlFrmGTB && grahakDtlFrmGTB != null) {
        billProcess(grahakDtlFrmGTB.contact_id)
      } else {
        postCustomerData()
      }
    }
  },[billItemListFastBilling])

  const postCustomerData = () => {
    const requestOptions = {
      method: "POST",
      url: Helper.getWsUrl() + `/contact/post_customer/?web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
      data: {
        name:grahakDetailFastBilling && grahakDetailFastBilling.name,
        mobile_number:grahakContactFastBilling && grahakContactFastBilling,
        gst_number: grahakDetailFastBilling && grahakDetailFastBilling.gst,
        address: grahakDetailFastBilling && grahakDetailFastBilling.address,
        profile_type:3
      },
    };
    axios(requestOptions)
      .then((response) => {
        if (response.data.code === 201) {
          billProcess(Helper.encypt_decrypt(response.data.data)[0].customer_id)
        } else if (response.data.code === 499) {
          billProcess(Helper.encypt_decrypt(response.data.data)[0].customer_id)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
      })
      .catch((error) => {
        console.log("Something went wrong!!");
      });
  }

  const billProcess = (custId) => {
    const responseData = {
      method: "POST",
      url: Helper.getWsUrl() + "/vouchers/make_fast_billing_new/?web=1",
      headers: { "Content-Type": "application/json", Token: cookies.token },
      data: {
        bill_Item_details: billItemListFastBilling && billItemListFastBilling,
        billdetails:{
          bill_type:0,
          customer_id: custId,
          is_shop_credit:0,
          overall_discount: overAllDisc && overAllDisc.disc,
          refund_amount:"0",
          round_off:0.0,
          status:0,
          sub_total: parseFloat(totalAmount && totalAmount),
          taxable_amount:0.0,
          total_amount: parseFloat(totalAmtWithOverallDisc && totalAmtWithOverallDisc).toFixed(2),
          total_tax:0.0,
        },
        total_recieved:parseFloat(totalAmtWithOverallDisc && totalAmtWithOverallDisc).toFixed(2)
      }
    };
    axios(responseData)
      .then((response) => {
        if (response.data.code === 200) {
          window.location.reload()
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  // const billProcess = (custId) => {
  //   const requestOptions5 = {
  //     method: "POST",
  //     url: Helper.getWsUrl() + "/vouchers/add_bill/?web=1",
  //     headers: { "Content-Type": "application/json", Token: cookies.token },
  //     data: {
  //       bill_Item_details: billItemListFastBilling && billItemListFastBilling,
  //       billdetails:{
  //         bill_type:0,
  //         customer_id: custId,
  //         is_shop_credit:0,
  //         overall_discount: overAllDisc && overAllDisc.disc,
  //         refund_amount:"0",
  //         round_off:0.0,
  //         status:0,
  //         sub_total: parseFloat(totalAmount && totalAmount),
  //         taxable_amount:0.0,
  //         total_amount: parseFloat(totalAmtWithOverallDisc && totalAmtWithOverallDisc).toFixed(2),
  //         total_tax:0.0,
  //       }
  //     }
  //   };
  //   axios(requestOptions5)
  //     .then((response) => {
  //       if (response.data.code === 201) {
  //         MultiplePaymentsmode(Helper.encypt_decrypt(response.data.data).bill_id, custId)  
  //       } else if (response.data.code === 401) {
  //         removeCookie("logged_in")
  //         removeCookie("token")
  //         setTimeout(() => {
  //             navigate('/')
  //             localStorage.setItem("isAuthenticated", false)
  //             localStorage.removeItem("isAuthenticated")
  //         }, 1000)
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // }

  // const MultiplePaymentsmode = (idBill, custId) => {
  //   const requestOptionspayment = {
  //     method: "POST",
  //     url: Helper.getWsUrl() + `/vouchers/multiple_bill_payments/${idBill}/?web=1`,
  //     headers: { "Content-Type": "application/json", "token": cookies.token },
  //     data: {
  //       payments: [{
  //         contact_id: custId,
  //         payment_mode: 1,
  //         total_recieved: parseFloat(totalAmtWithOverallDisc && totalAmtWithOverallDisc)
  //       }]
  //     }
  //   };
  //   axios(requestOptionspayment)
  //     .then((response) => {
  //       if (response.data.code === 201) {
  //         forEditBillFunc(idBill)
  //       } else if (response.data.code === 401) {
  //         removeCookie("logged_in")
  //         removeCookie("token")
  //         setTimeout(() => {
  //             navigate('/')
  //             localStorage.setItem("isAuthenticated", false)
  //             localStorage.removeItem("isAuthenticated")
  //         }, 1000)
  //       } 
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // }

  // const forEditBillFunc = (bill_Id) => {
  //   const requestOptionspaymentEdit = {
  //     method: "PATCH",
  //     url: Helper.getWsUrl() + `/vouchers/edit_bill/${bill_Id}?isPerforma=1&web=1`,
  //     headers: { "Content-Type": "application/json", "token": cookies.token },
  //   };
  //   axios(requestOptionspaymentEdit)
  //     .then((response) => {
  //       if (response.data.code === 201) {
  //         generateBillAfterEditFunc(bill_Id)
  //       } else if (response.data.code === 401) {
  //         removeCookie("logged_in")
  //         removeCookie("token")
  //         setTimeout(() => {
  //             navigate('/')
  //             localStorage.setItem("isAuthenticated", false)
  //             localStorage.removeItem("isAuthenticated")
  //         }, 1000)
  //       } 
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // }

  // const generateBillAfterEditFunc = (bill_Id) => {
  //   const requestOptions6 = {
  //     method: "GET",
  //       url: Helper.getWsUrl() + `/reports/generate_invoice/${bill_Id}/?web=1`,
  //       headers: { "Content-Type": "application/json", Token: cookies.token },
  //   };
  //   axios(requestOptions6)
  //       .then((response) => {
  //         if (response.data.code === 200) {
  //           window.location.reload()
  //         } else if (response.data.code === 401) {
  //           removeCookie("logged_in")
  //           removeCookie("token")
  //           setTimeout(() => {
  //               navigate('/')
  //               localStorage.setItem("isAuthenticated", false)
  //               localStorage.removeItem("isAuthenticated")
  //           }, 1000)
  //         } 
  //       })
  //       .catch((error) => {
  //         alert(error);
  //       });
  // }
  
  return (
    <div className='col-10' style={{backgroundColor:"rgb(245, 245, 245)",padding:"20px", position:"relative"}}>
      <div onClick={() => {window.location.reload()}} style={{ cursor:"pointer", position:"absolute", top:"0", left:"0", background:"#008c53", color:"#fff", fontWeight:"600", fontSize:"16px", zIndex:"1", padding:"10px 5px", borderRadius:"0 25px 25px 0", writingMode:"vertical-rl"}}>
        view bills
      </div>

      <div style={{background:"#fff", width:"100%", height:"100%", borderRadius:"20px", padding:"20px", position:"relative", overflow:"hidden"}}>
        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
          {!accessReturnReplace ? 
            <h3 style={{fontSize:"18px", fontWeight:"600", lineHeight:"29px"}}>Add New Bill</h3>
          :
            <h3 style={{fontSize:"18px", fontWeight:"600", lineHeight:"29px"}}>Return/Replace</h3>
          }
          <p style={{color:"#BA1C09", display: !accessReturnReplace ? "none" : "block", fontSize:"16px", fontWeight:"500"}}>**You can't increase quantity of previous bill items**</p>
          <button disabled={!accessReturnReplace ? "" : removeItemList.length > 0 ? "" : "disable"} data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => {scanBarcodeFunc(grhkDtl && grhkDtl.contact_id)}} style={{border:"none", display: localStorage.getItem("fastBilling") != 0 && !accessReturnReplace ? "none" : "block", background:"#008c53", borderRadius:"10px", padding:"0 15px", color:"#fff", fontWeight:"600"}}>
            Scan Item
          </button>
        </div>
        <div style={{display:"flex", justifyContent:"space-around", marginTop:"15px"}}>
          <div class="user-input-wrp">
            <br />
            <input autoComplete="off" style={{border:"1px solid #008c53"}} type="text" disabled={localStorage.getItem("fastBilling") != 1 ? "disable" : !accessReturnReplace ? "" : "disable"} class="inputText" value={(grhkDtl && grhkDtl.display_name) || (grhkDtl && grhkDtl.name) || (billDetailForReturnReplace && billDetailForReturnReplace.customerName)} onChange={getGrahakDetailFastBillingFunc} name="name"/>
            <span style={{top: "12px", left:"17px", opacity:"1", background:"#fff", color:"#008c53"}} class="floating-label">Grahak name</span>
          </div>
          <div class="user-input-wrp" >
            <br />
            <input autoComplete="off" disabled={localStorage.getItem("fastBilling") != 1 ? "disable" : !accessReturnReplace ? "" : "disable"} type="text" style={{border:"1px solid #008c53"}} class="inputText" name="mobile_number"  onChange={(event) => {getContactsearch(event)}} maxLength={10} value={(grhkDtl && grhkDtl.mobile_number) || (billDetailForReturnReplace && billDetailForReturnReplace.customerMobile)} />
            <span style={{top: "12px", left:"17px", opacity:"1", background:"#fff", color:"#008c53"}} class="floating-label">Contact No.</span>
          </div>
          <div class="user-input-wrp">
            <br />
            <input autoComplete="off" style={{border:"1px solid #008c53"}} type="text" disabled={localStorage.getItem("fastBilling") != 1 ? "disable" : !accessReturnReplace ? "" : "disable"} class="inputText"  onChange={getGrahakDetailFastBillingFunc} name="gst" pattern="/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/" maxLength={15} value={(grhkDtl && grhkDtl.gst_number) || (billDetailForReturnReplace && billDetailForReturnReplace.customer_gst)}/>
            <span style={{top: "12px", left:"17px", opacity:"1", background:"#fff", color:"#008c53"}} class="floating-label">GST No.</span>
          </div>

          <div class="user-input-wrp" style={{ width: "30%" }}>
            <br />
            <input autoComplete="off" style={{border:"1px solid #008c53", width: "100%" }} type="text" disabled={localStorage.getItem("fastBilling") != 1 ? "disable" : !accessReturnReplace ? "" : "disable"} class="inputText" value={(grhkDtl && grhkDtl.address) || (billDetailForReturnReplace && billDetailForReturnReplace.customrt_address)}  onChange={getGrahakDetailFastBillingFunc} name="address" />
            <span style={{top: "12px", left:"17px", opacity:"1", background:"#fff", color:"#008c53"}} class="floating-label">Address</span>
          </div>
        </div>

        <div id="allTableItemList" style={{marginTop:"20px", maxHeight:"40vh", overflowY:"scroll"}}>
          <table class="table">
            <thead>
              <tr>
                <th style={{width:"5%"}}>S.No</th>
                <th style={{ width: "20%" }}>Item Name</th>
                <th>Size</th>
                <th>Unit</th>
                <th>MRP<br />(in ₹)</th>
                <th>Selling<br />Price/unit</th>
                <th>GST*<br />(in %)</th>
                <th>Disc<br />(in %)</th>
                <th>Qty<br />(in Units)</th>
                <th>Amount<br />(in ₹)</th>
              </tr>            
              <tr>
                <th style={{border:"none"}}></th>
                <th style={{ position: "relative"}}> 
                  <div style={{border: "none", width: "100%",borderRadius: "10px",padding: "0", display:"flex", justifyContent:"space-between"}}>
                    <form id="empty">
                      <input disabled={!accessReturnReplace ? "" : removeItemList.length > 0 ? "" : "disable"} autoComplete="off" type="text" placeholder="Add/Search Item" name="itemName" style={{borderRadius: "10px", border:"1px solid #7a7a7a", background:"none", padding:"3.5px 7px"}}
                          onChange={(event) => {
                          getItems(event);
                        }}/>
                    </form>
                    {showAddItemBtn ? 
                      <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{border: "none", background: "none", padding: "0", width:"max-content", marginLeft:"1px"}}>
                        <span style={{border: "none", borderRadius: "10px", padding: "6px 9px", fontSize: "14px", fontWeight: "500", color: "#fff", background:"#008C53"}}>
                          Add Item
                        </span>
                      </button>
                   : null }
                  </div>
                  {show ? (
                    <div style={{paddingTop: "10px", left:"0", minHeight:"0", maxHeight:"20vh", overflowY:"scroll", backgroundColor: "#fff",position: "absolute", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", width: "100%",borderRadius:"0 0 10px 10px"}}> 
                      {filteredData && filteredData.map((value, index) => {
                        return (
                          <li onClick={(e) => {handleAutoFill(value.iv_id)}} style={{listStyle: "none", border:"none", cursor:"pointer", padding:"0 15px"}}>
                            <p style={styles} key={value.item_id}>
                              {value.itemName} &nbsp;({value.size}, {value.color}, {value.model})
                            </p>
                          </li>
                        )
                      })}
                    </div> ) 
                    : 
                    null
                  }
                </th>              
                <th colSpan={8}></th>
              </tr> 
            </thead>
            <tbody style={{overflowY:"scroll", maxHeight:"20vh"}}>
              {
                tableArr && tableArr.map((item, index) => {
                  return(
                    <tr>
                      <th>
                        {index+1}
                        {showDeleteBtn ?
                          <img onClick={() => {forDeleteRowFrmTbl(item.ivID, index)}} src={require("../assets/images/delete icon.png")}  style={{width:"15px", marginLeft:"5px", cursor:"pointer"}} />
                        :
                          <img onClick={() => {setShowDeleteBtn(true)}} src={require("../assets/images/Vector1.png")}  style={{width:"10px", marginLeft:"10px", cursor:"pointer"}}/>
                        }  
                      </th>
                      <td>
                        <input disabled key={index} value={item.itemName} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.size} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.unit} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.mrp} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.rate} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.gst} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.discount} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input key={index} onChange={(e) => {edityQuantityInArrayFunc(e, index, item.ivID)}} value={item.qty} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={parseFloat(item.amt).toFixed(2)} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                    </tr>
                  )
                })
              }
              <tr>              
                <td colSpan={2} style={{borderRight:"none"}}>
                </td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}>Total</td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                <td colSpan={2} style={{ border: "none", width: "7%", position: "relative" }}>
                  <input style={{border: "1px solid #bababa",borderRadius: "5px",padding: "6px 9px",fontSize: "14px",fontWeight: "500", color: "#bababa", width: "100%",}}/>
                  <span style={{ position: "absolute", top: "30%", left: "15%" }}>
                    {parseFloat(totalAmount).toFixed(2)}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>          

        <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
          <div style={{ textAlign: "right", marginRight: "20px" }}>
            <p style={{ fontSize: "16px", fontWeight: "500", margin:"0" }}>
              Overall Disc (optional) &nbsp;&nbsp; -{" "}
            </p>
            {/*<p style={{ fontSize: "16px", fontWeight: "500" }}>
              round Off &nbsp; &nbsp; -{" "}
              </p>*/}
            <br></br>
            <p style={{ fontSize: "16px", fontWeight: "500", margin:"0" }}>
              Final Amount &nbsp; &nbsp; -{" "}
            </p>
          </div>

          <div style={{ textAlign: "left" }}>
            <div style={{display:"flex", position:"relative"}}>
              <p style={{position:"absolute", left:"-12px", top:"1px"}}>₹</p>
              <input onChange={(e) => {getOverAllDiscFunc(e)}} type="number" style={{ border: "none", borderBottom: "1px solid #bababa" }} placeholder="Disc" />
            </div> <br/>
            <input value={totalAmtWithOverallDisc} disabled type="text" style={{ marginTop:"4px", border: "none", borderBottom: "1px solid #bababa" }} placeholder="Final Amt" required/>
          </div>
        </div>       

        <div style={{ textAlign: "right", marginTop: "20px", marginRight: "10px" }}>
          {localStorage.getItem("fastBilling") != 0 && !accessReturnReplace ? 
            <div style={{ justifyContent:"end", marginTop: "10px", display:"flex" }}>
              <div style={{display:!showProgressBar ? "none" : "flex", marginRight:"20px"}} >
                <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
                  <CircularProgress color="success" />
                </Stack>
                <p style={{color:"#008c53", fontWeight:"600", margin:"10px", padding:"0"}}>Processing...</p>
              </div>
              <button disabled={!showProgressBar ? "" : "disable"} onClick={() => {
                    if(grahakContactFastBilling && grahakContactFastBilling != null) {
                      if(tableArr && tableArr.length > 0) {
                        if(totalAmtWithOverallDisc && totalAmtWithOverallDisc > 9999999){
                          alert("Amount should not exceed (₹ 99,99,999)")
                        } else {
                          setTableForFastBillingFunc()
                        }
                      } else {
                        alert("Please add at least one item for billing")
                      }
                    } else {
                      alert("Please enter grahak mobile number")
                    }
                  }}
                style={{backgroundColor: "#008C53", color: "#fff", borderRadius: "10px", padding: "3px 13px", border: "none", }}>
                Done
              </button>
            </div>
          : 
            <button disabled={!accessReturnReplace ? "" : removeItemList.length > 0 ? "" : "disable"} onClick={() => {
                                if(totalAmtWithOverallDisc && totalAmtWithOverallDisc > 9999999){
                                  alert("Amount should not exceed (₹ 99,99,999)")
                                } else {
                                  if(grhkDtl && grhkDtl.contact_id != null) {
                                    billItemListFunc();
                                  } else {
                                    alert("Please add grahak")
                                  }
                                } 
                              }} 
              style={{backgroundColor: "#008C53", color: "#fff", borderRadius: "10px", padding: "3px 13px", border: "none", }}>
              Genearate Bill
            </button>
          }
        </div>
      </div>

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content" style={{borderRadius:"1.3rem"}}>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add New Item</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>Item Name: {itemget}</p>
              <form id="emptyModal">
                <div style={{display:"flex", justifyContent:"space-between"}}>
                  <div class="user-input-wrp" style={{width:"30%"}}>
                    <br />
                    <input autoComplete="off" type="text" onChange={getAddBookPost} name="size" class="inputText" required/>
                    {/* <span class="floating-label">Size*</span> */}
                    <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Size*</p>
                  </div>
                  <div class="user-input-wrp" style={{width:"30%"}}>
                  <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Unit*</p>
                    <br />
                    <select name="miu_id" onChange={getAddBookPost} class="inputText inputData">
                      <option selected >Unit</option>
                      {getUnitData && getUnitData.map((value, index) => {
                        return(
                          <option value={value.miu_id}>{value.name}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div class="user-input-wrp" style={{width:"30%"}}>
                    <br />
                    <input autoComplete="off" type="number" name="mrp" onChange={getAddBookPost} class="inputText" required/>
                    {/* <span class="floating-label">MRP* (in ₹)</span> */}
                    <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>MRP* (in ₹)</p>
                  </div>
                </div>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                  <div class="user-input-wrp" style={{width:"30%"}}>
                    <br />
                    <input autoComplete="off" type="number" name="rate" onChange={getAddBookPost} class="inputText" required/>
                    {/* <span class="floating-label">SellingPrice*</span> */}
                    <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>SellingPrice*</p>
                  </div>
                  <div class="user-input-wrp" style={{width:"30%"}}>
                    <br />
                    <input autoComplete="off" type="text" name="addQty" onChange={getAddBookPost} class="inputText" required/>
                    {/* <span class="floating-label">Purchase*</span> */}
                    <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Model*</p>
                  </div>
                  <div class="user-input-wrp" style={{width:"30%"}}>
                  <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>GST*</p>
                    <br />
                    <select name="gst_tax_per" onChange={getAddBookPost} class="inputText inputData">
                      <option selected>GST</option>
                      {getGSTData && getGSTData.map((value) => {
                        return(
                          <option value={value.gst_per}>{value.gst_per}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>  
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" onClick={() => {
                if(addPost && addPost.size != null && addPost.size != "") {
                  if(addPost && addPost.miu_id != null) {
                    if(addPost && addPost.mrp != null && addPost.mrp != "") {
                      if(addPost && addPost.rate != null && addPost.rate != "") {
                        if(addPost && addPost.mrp >= addPost.rate) {
                          if(addPost && addPost.mrp <= 9999999) {
                            if(addPost && addPost.gst_tax_per != null) {
                              postNewItemDetail()
                            } else {
                              alert("Item GSt is required")
                            }
                          } else {
                            alert("Item MRP is not greater than ₹ 9999999")
                          }
                        } else {
                          alert("Item Selling Price is always less than or equal to MRP")
                        }
                      } else {
                        alert("Item Selling Price is required")
                      }
                    } else {
                      alert("Item MRP is required")
                    }

                  } else {
                    alert("Item Unit is required")
                  }
                } else {
                  alert("Item Size is required")
                }
              }} style={{border: "none", borderRadius: "5px", padding: "6px 9px", fontSize: "14px", fontWeight: "500", color: "#fff", background:"#008C53"}} data-bs-dismiss="modal" >Done</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content" style={{textAlign:"center", borderRadius:"15px"}}>
            <div class="modal-body">
              <p style={{fontWeight:"500", fontsize:"18px"}}>Done, please open scanner in phone</p>
            </div>
            <div class="modal-footer" style={{padding:"0 16px", border:"none", margin:"0"}}>
              <button type="button" style={{border:"none", background:"none", color:"#7a7a7a", fontWeight:"600", marginBottom:"20px"}} class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button onClick={() => {scanedItemFunc(grhkDtl && grhkDtl.contact_id)}} style={{border:"none", background:"none", color:"#008c53", fontWeight:"600", marginBottom:"20px"}} type="button" data-bs-dismiss="modal">Get Item</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default ViewBills;
