import React, {useEffect, useState} from 'react'
import PurchaseAddNew from './PurchaseAddNew'
import PurchaseAddItem from './PurchaseAddItem'
import PurchaseDetails from './PurchaseDetails'
import PurchaseLeft from './PurchaseLeft'
import PurchaseAddItemDetail from './PurchaseAddItemDetail'
import PurchasePayment from './PurchasePayment'
import PurchasePaymentDate from './PurchasePaymentDate'
import PurchaseFirstTimeUser from './PurchaseFirstTimeUser'
import Helper from "../util/Helper";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

const Purchase = ({globalVoucherId}) => {

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const [showPurchaseAddItem, setShowPurchaseAddItem] = useState(false)
    const [showPurchasePayment, setShowPurchasePayment] = useState(false)
    const [showPurchaseAddItemDetail, setShowPurchaseAddItemDetail] = useState(false)
    const [showUnpaidPage, setShowUnpaidPage] = useState(false)
    const [showPurchaseDetail, setshowPurchaseDetail] = useState(false) 
    const [getShopDetail, setGetShopDetail] = useState(null)
    const [getPurchaseItemsDetail, setGetPurchaseItemsDetail] = useState([])
    const [getTotalSellingAmt, setGetTotalSellingAmt] = useState(0)
    const [getitemdata,setgetitemdata] = useState (null)
    const [getVoucherId, setGetVoucherId] = useState(null)

    const[showPurchaseList, setShowPurchaseList] = useState([])
    const[purchaseEmptystate, setPurchaseEmptyState] = useState(false)
    const[skeletonLoading, setSkeletonLoading] = useState(true)

    const [purchaseFilter, setPurchaseFilter] = useState(false)
    const [purchaseFilterValue, setPurchaseFilterValue] = useState(null)

    useEffect(() => {
        if(globalVoucherId && globalVoucherId != null) {
            setshowPurchaseDetail(true)
        }
    },[globalVoucherId])

    useEffect(() => {
        const getPurchaseListUrl = {
            method: "GET",
            url: Helper.getWsUrl() + `${!purchaseFilter ? `/vouchers/get_purchase_web/?web=1` : `/vouchers/get_purchase_web/?web=1&key=${purchaseFilterValue}`}`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(getPurchaseListUrl)
        .then((response) => {       
            if (response.data.code === 200) {
                setShowPurchaseList(Helper.encypt_decrypt(response.data.data))
                setPurchaseEmptyState(false)
                setSkeletonLoading(false)
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            } else if(response.data.code === 204) {
                setShowPurchaseList([])
                if(!purchaseFilter) {
                    setPurchaseEmptyState(true)
                    setSkeletonLoading(false)
                }
            }  
        })
        .catch((error) => {
            alert(error);
        })
    },[purchaseFilter, purchaseFilterValue])
    
    return (
        <>
            {showPurchaseAddItemDetail ?
                <PurchaseAddItemDetail setShowPurchaseAddItemDetail = {setShowPurchaseAddItemDetail} setShowPurchasePayment = {setShowPurchasePayment} setGetTotalSellingAmt = {setGetTotalSellingAmt} setgetitemdata = {setgetitemdata} setGetPurchaseItemsDetail = {setGetPurchaseItemsDetail} getShopDetail = {getShopDetail} />
            :
                <>
                    {showPurchasePayment ? 
                        <>
                            {showUnpaidPage ? 
                                <PurchasePaymentDate getPurchaseItemsDetail = {getPurchaseItemsDetail} setShowPurchaseAddItem = {setShowPurchaseAddItem} setShowUnpaidPage = {setShowUnpaidPage} setShowPurchasePayment = {setShowPurchasePayment} getitemdata = {getitemdata} getTotalSellingAmt = {getTotalSellingAmt} getShopDetail = {getShopDetail}/> 
                            :
                                <PurchasePayment setShowPurchaseAddItem = {setShowPurchaseAddItem} setShowUnpaidPage = {setShowUnpaidPage} setShowPurchasePayment = {setShowPurchasePayment} getitemdata = {getitemdata} getTotalSellingAmt = {getTotalSellingAmt} getShopDetail = {getShopDetail} getPurchaseItemsDetail = {getPurchaseItemsDetail} />
                            }
                        </>
                    :
                        <div className='col-10' style={{ backgroundColor: "rgb(245, 245, 245)", padding: "20px", display: "flex", }}>
                            {purchaseEmptystate ? 
                                <PurchaseFirstTimeUser setShowPurchaseAddItem = {setShowPurchaseAddItem} setPurchaseEmptyState = {setPurchaseEmptyState} />
                            :
                                <>
                                    <PurchaseLeft purchaseFilterValue = {purchaseFilterValue} setPurchaseFilterValue = {setPurchaseFilterValue} setPurchaseFilter = {setPurchaseFilter} skeletonLoading = {skeletonLoading} showPurchaseList = {showPurchaseList} setGetVoucherId = {setGetVoucherId} setshowPurchaseDetail = {setshowPurchaseDetail} setShowPurchaseAddItem = {setShowPurchaseAddItem}/>
                                    {showPurchaseDetail ?
                                        <PurchaseDetails globalVoucherId = {globalVoucherId} getVoucherId = {getVoucherId} />
                                    :
                                        <>
                                            { showPurchaseAddItem ?
                                                <PurchaseAddItem setShowUnpaidPage = {setShowUnpaidPage} setGetShopDetail = {setGetShopDetail} setShowPurchaseAddItemDetail = {setShowPurchaseAddItemDetail} setShowPurchasePayment = {setShowPurchasePayment} /> 
                                            :
                                                <PurchaseAddNew setShowPurchaseAddItem = {setShowPurchaseAddItem}/>
                                            }
                                        </>
                                    }
                                </>    
                            }                                                             
                        </div>
                    }
                </>
            }
        </>                                                         
    )
}

export default Purchase