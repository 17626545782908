import React, { useEffect, useState } from 'react'
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Pagination } from "@mui/material"; 
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PassBookLeft = ({setGetPaymentId, setShowPassBookDetail, showPassbookList, skeletonLoading}) => {

    const [cookies, setCookie,removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()

    const [getPassBookList, setGetPassBookList] = useState([])  
    const [getPassBookTotalBills, setGetPassBookTotalBills] = useState(null)  

    const [minIndex, setMinIndex] = useState(0)
    const [maxIndex, setMaxIndex] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [countPage, setCountPage] = useState(1)
    const [active, setActive] = useState(null)
    // const [card_arrow_Imag,setcard_arrow_Imag]=useState(true)

    const handlePagination = (pageNumber) => {
        setMinIndex((pageNumber - 1) * 10)
        setMaxIndex(pageNumber * 10)
        setCurrentPage(pageNumber)
    }

    useEffect(() => {
        if(showPassbookList && showPassbookList != null){
            setGetPassBookTotalBills(showPassbookList)
            setGetPassBookList(showPassbookList.data1)
            console.log(showPassbookList.data1)
        }
    },[showPassbookList])
    
    // useEffect(() => {
    //     const getPassBookListUrl = {
    //         method: "GET",
    //         url: Helper.getWsUrl() + `/vouchers/get_merchant_passbook/?web=1`,
    //         headers: { "Content-Type": "application/json", 'token': cookies.token },
    //     };
    //     axios(getPassBookListUrl)
    //     .then((response) => {  
    //         // setCookie("token", response.headers.token);            
    //         if (response.data.code === 200) {
    //             setGetPassBookTotalBills(Helper.encypt_decrypt(response.data.data))
    //             setGetPassBookList(Helper.encypt_decrypt(response.data.data).data1)               
    //         } else if (response.data.code === 401) {
    //             removeCookie("logged_in")
    //             removeCookie("token")
    //             setTimeout(() => {
    //                 navigate('/')
    //                 localStorage.setItem("isAuthenticated", false)
    //                 localStorage.removeItem("isAuthenticated")
    //             }, 1000)
    //           }
    //     })
    //     .catch((error) => {
    //         alert(error);
    //     })
    // },[])

    useEffect(()=> {
        if(getPassBookList.length > 0) {
          setCountPage(Math.ceil(getPassBookList.length / 10))
        }
    },[getPassBookList])

    const downloadPassbook = () => {
        const getPassBookListUrl = {
            method: "GET",
            url: Helper.getWsUrl() + `/contact/get_csv_file/?file_type=4&web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(getPassBookListUrl)
        .then((response) => {         
            if (response.data.code === 200) { 
                console.log(Helper.encypt_decrypt(response.data.data))
               window.open(Helper.encypt_decrypt(response.data.data).link, '_blank', 'noopener,noreferrer')          
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              }
        })
        .catch((error) => {
            alert(error);
        })
    }

  return (
    <div className="leftBackground" style={{width: "40%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff"}}>
        <div style={{display:"flex", justifyContent:"space-between"}}>
            <p className='leftSideHeading'>Passbook</p>
            <img onClick={() => {downloadPassbook()}} style={{cursor:"pointer", height:"30px"}} src={require("../assets/images/download icon.png")}/>
        </div>
        {skeletonLoading ? 
            <div style={{height:"60vh", overflowY:"scroll"}}>
                <Skeleton count={4} borderRadius={"10px"} height={"15vh"} /> 
            </div>
        :
            <div style={{overflowY:"scroll", height:"60vh"}}>
                {getPassBookList && getPassBookList.map ((value,index) => {
                    let bgcolor = false;
                    if(value.type === "Dr"){
                        bgcolor = true
                    }
                    return (
                        index >= minIndex && index < maxIndex && (
                            <div className={`card  ${active == index && 'active'}`} key={index} onClick={() => {setShowPassBookDetail(true);setGetPaymentId(value.payment_id);setActive(index)}} >
                                <div className='cardUp'>
                                    <p className='child '>{value.mobile_number}
                                        <span className='m-1'>{value.customer_name}</span>                                   
                                    </p>
                                    <p className='child'>₹ {parseFloat(value.amount).toFixed(2)}</p>
                                </div>
                                <div className='cardDown'>
                                    <div className='cardDownLeft'>
                                        <p className='orderchild'>{value.bill_no}</p>
                                        <p className='orderchild '>{value.added_by}</p>
                                    </div>
                                    <div className='cardDownRight'>
                                        <p className='orderchild'>{value.payment_date}</p>
                                    </div>
                                </div>
                                <div className='cardImg'>
                                    {(active === index ) ?  
                                        <img src={require("../assets/images/white_left_marker.png")} />
                                    :
                                        <img src={require("../assets/images/vector.png")} />
                                    }
                                </div>
                                <div className='cardRgtCorner' style={{background: bgcolor ? "#ff0000" : "#008c53"}}>
                                    <p>{value.type}.</p>
                                </div>
                            </div> 
                        ) 
                    )
                })}  
            </div>
        }
        <div className="pageCount" style={{marginTop:"15px"}}>
            <Pagination count={countPage} defaultPage={1} page={currentPage} onChange={(event, pageNumber) => handlePagination(pageNumber)}/>
        </div>      
    </div>
  )
}

export default PassBookLeft