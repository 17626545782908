import React from "react";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useState, useEffect, useCallback } from "react";
import './ClickUdhaar.css';
import { useNavigate } from "react-router-dom";

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const UdhaarPayment = ({ setShowUdhaarBill, setPayment, billId, customerId, setShowAlert, setbillId, setExample }) => {

  const [isShown, setIsShown] = useState(false)
  const [isShown1, setIsShown1] = useState(false)
  const [isShown2, setIsShown2] = useState(false)
  const [isShown4, setIsShown4] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate()

  const customer_id = customerId;
  const [paymentMode, setPaymentMode] = useState(null)
  
  const [bill, setBill] = useState([]);
  const [cashPayment, setCashPayment] = useState(null)
  const [cardPayment, setCardPayment] = useState(null)
  const [onlinePayment, setOnlinePayment] = useState(null)
  const [sendmsg, setSendMsg] = useState(null)
  const [price, setPrice] = useState(0)
  const [paymentArr, setPaymentArr] = useState(null)

  const [showProgressBar, setShowProgressBar] = useState(false)

  const udhaarDetail = () => {
    const requestOptions = {
      method: "GET",
      url: Helper.getWsUrl() + `/vouchers/shop_credit/${customer_id}?web=1`,
      headers: { "Content-Type": "application/json", "token": cookies.token },
    };
    axios(requestOptions)
      .then((response) => {
        if (response.data.code === 200) {
          setBill(Helper.encypt_decrypt(response.data.data))
          setbillId(Helper.encypt_decrypt(response.data.data).sales)
          // console.log("data detail udhaar : ", Helper.encypt_decrypt(response.data.data))
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const balance = bill.Balance;
  
  useEffect((customerId) => {
    udhaarDetail();
  }, [customerId])

  const cashPaymentMode = useCallback(event => {
    const { name, value } = event.target;
    setCashPayment({ ...cashPayment, [name]: value });
    setPrice(event.target.value)
  });

  const cardPaymentMode = useCallback(event => {
    const { name, value } = event.target;
    setCardPayment({ ...cardPayment, [name]: value });
    if(event.target.name == "amount_paid") {
      setPrice(event.target.value)
    }
  });

  const onlinePaymentMode = useCallback(event => {
    const { name, value } = event.target;
    setOnlinePayment({ ...onlinePayment, [name]: value });
    setPrice(event.target.value)
  });

  const [cashObj, setCashObj] = useState({
    contact_id: customerId,
    payment_mode: 1,
    total_recieved: null
  })

  useEffect(() => {
    setCashObj({
      ...cashObj,
      total_recieved: parseFloat(cashPayment && cashPayment.cashAmt)
    })
  }, [cashPayment])

  const [cardObj, setCardObj] = useState({
    approval_number: null,
    bank_name: null,
    contact_id: customerId,
    payment_mode: 3,
    total_recieved: null
  })

  useEffect(() => {
    setCardObj({
      ...cardObj,
      approval_number: cardPayment && cardPayment.approvalNo,
      bank_name: cardPayment && cardPayment.bankName,
      total_recieved: parseFloat(cardPayment && cardPayment.amount_paid)
    })
  }, [cardPayment])

  const [onlineObj, setOnlineObj] = useState({
    contact_id: customerId,
    payment_mode: 4,
    total_recieved: null
  })

  useEffect(() => {
    setOnlineObj({
      ...onlineObj,
      total_recieved: parseFloat(onlinePayment && onlinePayment.amountpaid)
    })
  }, [onlinePayment])

  const show = () => {
    if (isShown === true) {
      setIsShown(false)
    } else {
      setIsShown(true)
      setIsShown1(false)
      setIsShown2(false)
      setIsShown4(false)
    }

  }
  const show1 = () => {
    setIsShown(false)
    setIsShown1(true)
    setIsShown2(false)
    setIsShown4(false)
  }
  const show2 = () => {
    setIsShown(false)
    setIsShown1(false)
    setIsShown2(true)
    setIsShown4(false)
  }

  const show4 = () => {
    setIsShown(false)
    setIsShown1(false)
    setIsShown2(false)
    setIsShown4(true)
  }

  // console.log("card bill Id : ", billId)
  // console.log("card payment  : ", paymentArr)

  const setValuesInArr = () => {
    if(isShown === true) {
      if(cashPayment && cashPayment.cashAmt != null){
        setPaymentArr(cashObj)
      } else {
        alert("please enter cash amount")
      }
    } else if(isShown1 === true) {
      if(cardPayment && cardPayment.amount_paid != null && cardPayment.approvalNo != null && cardPayment.bankName != null){
        setPaymentArr(cardObj)
      } else {
        alert("please enter card details")
      }
    } else if(isShown4 === true) {
      if(onlinePayment && onlinePayment.amountpaid != null){
        setPaymentArr(onlineObj)
      } else {
        alert("please enter online details")
      }
    }
  }

  useEffect(() => {
    if (paymentArr && paymentArr != null) {
      setSendMsg(paymentArr)
    }
  }, [paymentArr])

  useEffect(() => {
    if (paymentArr && paymentArr != null) {
      setPaymentMode(paymentArr.payment_mode)
    }
  }, [paymentArr])

  useEffect(() => {
    if (paymentMode && paymentMode != null) {
      setShowProgressBar(true)
      var recievedAmount = paymentArr.total_recieved
      const length = billId.length
      var i = 0
      msgApi(recievedAmount, paymentMode)
      for(i = 0; i < length; i++) {
        if(recievedAmount > 0) {
          if(recievedAmount > billId[i].amount) {
            forPaymentFunc(billId[i].bill_id, paymentMode, billId[i].amount)
            recievedAmount = recievedAmount - billId[i].amount
          } else if(recievedAmount <= billId[i].amount) {
            forPaymentFunc(billId[i].bill_id, paymentMode, recievedAmount)
            recievedAmount = recievedAmount - billId[i].amount
            setTimeout(() => {
              if(recievedAmount <= 0) {
                window.location.reload()
              }
            }, 1000);
            break;
          }
        } else {
          break;
        }
        // if(recievedAmount <= 0) {
        //   window.location.reload()
        // }
      }
    }
  },[paymentMode && billId])

  const forPaymentFunc = (billId, paymentMode, amount) => {    
    if(paymentMode === 1) {
      var dataValue = {
        contact_id : customerId,
        total_recieved : amount,
        refund_amouont : 0
      }
    } else if(paymentMode === 3) {
      var dataValue = {
        contact_id : customerId,
        total_recieved : amount,
        approval_number: cardPayment && cardPayment.approvalNo,
        bank_name: cardPayment && cardPayment.bankName,
      }
    } else {
      var dataValue = {
        contact_id : customerId,
        total_recieved : amount
      }
    }
    const requestOptionspayment = {
      method: "POST",
      url: Helper.getWsUrl() + `/vouchers/add_payment/${billId}/${paymentMode}?web=1`,
      headers: { "Content-Type": "application/json", "token": cookies.token },
      data: dataValue
    };
    axios(requestOptionspayment)
      .then((response) => {
        if (response.data.code === 201) {

        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  // var pmId = parseInt(sendmsg && sendmsg.payment_mode)
  // var am_t = parseFloat(sendmsg && sendmsg.total_recieved)

  const msgApi = (amt, mode) => {
    const requestOptions = {
      method: "POST",
      url: Helper.getWsUrl() + `/sms/send_shop_credit_payment_msg/${customerId}/?web=1`,
      headers: { "Content-Type": "application/json", "token": cookies.token },
      data: {
        pm_id: mode,
        amount: amt
      }
    };
    axios(requestOptions)
      .then((response) => {
        // setCookie("token", response.headers.token);
        if (response.data.code === 200) {
          // setPayment(false);
          // setShowAlert(true);
          // window.location.reload()
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // useEffect(() => {
  //   if (pmId && pmId != null && am_t && am_t != null) {
  //     msgApi();
  //   }
  // }, [pmId && am_t])

  return (
    <div className='col-10' style={{backgroundColor:"rgb(245, 245, 245)",padding:"20px", position:"relative"}}>
      <div style={{background:"#fff", width:"100%", height:"100%", borderRadius:"20px", padding:"20px", position:"relative", overflow:"hidden"}}>
        <div style={{ width: "100%" }}>
          <p style={{ fontSize: "18px", fontWeight: "600", textAlign: "left" }}>
            {/* <img src={require("../assets/images/back 1.png")} /> */}
            Payment
          </p>
        </div>
        <div style={{ marginTop: "40px" }}>
          <p className="para_head">Select Payment Method</p>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "25px" }}>
          <div className="card1" >
            <div id="checkBox" style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "14px", marginBottom: "30px", backgroundColor: "#F9F9F9" }}>
              <div class="form-check">
                <button onClick={show} style={{ backgroundColor: isShown ? "#008c53" : "#fff", border: "1px solid #7a7a7a", borderRadius: "4px", width: "18px", height: "15px" }} class="form-check-input" ><img style={{ marginBottom: "15px", marginLeft: "-3px", width: "10px" }} src={require("../assets/images/whitecheck.png")} /></button>
                <label class="form-check-label" for="flexCheckDefault">Cash</label>
              </div>
            </div>
            {isShown ?
              <div style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "15px 10px" }}>
                <div style={{ position: "relative" }}>
                  <input type="number" className="input_data" name="cashAmt" onChange={cashPaymentMode} max="5" />
                  <span className='label_data'> Cash Amount </span>
                </div>
                {/* <div style={{ position: "relative", marginTop: "17px" }}>
                  <input type="text" className="input_data" />
                  <span className='label_data'> Refund </span>
                </div> */}
              </div>
              :
              null
            }
          </div>
          <div className="card1" >
            <div id="checkBox" style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "14px ", marginBottom: "30px", backgroundColor: "#F9F9F9" }}>
              <div class="form-check">
                <button onClick={show1} style={{ backgroundColor: isShown1 ? "#008c53" : "#fff", border: "1px solid #7a7a7a", borderRadius: "4px", width: "18px", height: "15px" }} class="form-check-input" ><img style={{ marginBottom: "15px", marginLeft: "-3px", width: "10px" }} src={require("../assets/images/whitecheck.png")} /></button>
                <label class="form-check-label" for="flexCheckDefault">Card</label>
              </div>
            </div>
            {isShown1 ?
              <div style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "15px 10px" }}>
                <div style={{ position: "relative" }}>
                  <input type="text" className="input_data" name="bankName"  onChange={cardPaymentMode} />
                  <span className='label_data'> Bank Name </span>
                </div>
                <div style={{ position: "relative", marginTop: "17px" }}>
                  <input type="number" className="input_data" name="approvalNo"  onChange={cardPaymentMode}/>
                  <span className='label_data'> Approval No. </span>
                </div>
                <div style={{ position: "relative", marginTop: "17px" }}>
                  <input type="number" className="input_data" name="amount_paid" onChange={cardPaymentMode} />
                  <span className='label_data'> Amount Paid</span>
                </div>
              </div>
              :
              null
            }
          </div>
          <div className="card1" >
            <div id="checkBox" style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "14px", marginBottom: "30px", backgroundColor: "#F9F9F9" }}>
              <div class="form-check">
                <button onClick={show4} style={{ backgroundColor: isShown4 ? "#008c53" : "#fff", border: "1px solid #7a7a7a", borderRadius: "4px", width: "18px", height: "15px" }} class="form-check-input" ><img style={{ marginBottom: "15px", marginLeft: "-3px", width: "10px" }} src={require("../assets/images/whitecheck.png")} /></button>
                <label class="form-check-label" for="flexCheckDefault">Online</label>
              </div>
            </div>
            {isShown4 ?
              <div style={{ borderRadius: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "15px 10px" }}>
                <div style={{ position: "relative" }}>
                  <input type="number" className="input_data" name="amountpaid" onChange={onlinePaymentMode} />
                  <span className='label_data'> Amount Paid </span>
                </div>
                <div style={{ position: "relative", marginTop: "17px" }}>
                  <input type="text" className="input_data" />
                  <span className='label_data'> UPI ID </span>
                </div>
              </div>
              :
              null
            }
          </div>
        </div>

        <div style={{ marginTop:"20px" }}>
          <div style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
            <div style={{ textAlign: "right" }}>
              <p className='para_head'>Total Bill Amount</p>
              <p className='para_head'>Balance</p>
            </div>
            <div style={{ textAlign: "left", marginLeft: "20px" }}>
              <p className='para_head'>₹{parseFloat(bill.Balance).toFixed(2)}</p>
              <p className='para_head'>₹{parseFloat(balance - price).toFixed(2)}</p>
            </div>
          </div>
          <div style={{ justifyContent:"end", marginTop: "20px", display:"flex" }}>

            <div style={{display:!showProgressBar ? "none" : "flex", marginRight:"20px"}} >
              <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
                <CircularProgress color="success" />
              </Stack>
              <p style={{color:"#008c53", fontWeight:"600", margin:"10px", padding:"0"}}>Processing...</p>
            </div>

            <button disabled={!showProgressBar ? "" : "disable"} onClick={() => {
                  if (price > balance) {
                    alert("you cannot pay more than the balance amount")
                  } else if(isShown === false && isShown1 === false && isShown4 === false){ 
                    alert("please complete your payment")
                  } else {
                    setValuesInArr(); 
                  }
                }} style={{ backgroundColor: "#008C53", border:"none", color: "white", fontWeight:"600", borderRadius: "24px", padding: "8px 25px" }}>Done</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UdhaarPayment;