import React, { useEffect, useState } from 'react'
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Pagination } from "@mui/material"; 
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const CashBookLeft = ({setGetDate}) => {

  const navigate = useNavigate()

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [getCashBookList, setGetCashBookList] = useState([]) 

  const [minIndex, setMinIndex] = useState(0)
  const [maxIndex, setMaxIndex] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [countPage, setCountPage] = useState(1)
  const [active, setActive] = useState(null)

  const[skeletonLoading, setSkeletonLoading] = useState(true)

  const handlePagination = (pageNumber) => {
    setMinIndex((pageNumber - 1) * 10)
    setMaxIndex(pageNumber * 10)
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    const getCashBookListUrl = {
      method: "POST",
      url: Helper.getWsUrl() + `/reports/get_cashbook_list/?web=1`,
      headers: { "Content-Type": "application/json", 'token': cookies.token },
    };
    axios(getCashBookListUrl)
    .then((response) => {  
        // setCookie("token", response.headers.token);            
        if (response.data.code === 200) {
          setGetCashBookList(Helper.encypt_decrypt(response.data.data).list) 
          setSkeletonLoading(false)               
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
    })
    .catch((error) => {
        alert(error);
    })
  },[])

  useEffect(()=> {
    if(getCashBookList && getCashBookList.length > 0) {
      setCountPage(Math.ceil(getCashBookList.length / 10))
    }
  },[getCashBookList])

  const downloadCashbook = () => {
    const getPassBookListUrl = {
        method: "GET",
        url: Helper.getWsUrl() + `/contact/get_csv_file/?file_type=1&web=1`,
        headers: { "Content-Type": "application/json", 'token': cookies.token },
    };
    axios(getPassBookListUrl)
    .then((response) => {  
        // setCookie("token", response.headers.token);            
        if (response.data.code === 200) {
          //  console.log("response downloadd : ",response.data)     
           window.open(Helper.encypt_decrypt(response.data.data).link, '_blank', 'noopener,noreferrer')          
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
    })
    .catch((error) => {
        alert(error);
    })
}

  return (
    <div className="leftBackground" style={{width: "40%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff"}}>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <p className='leftSideHeading'>Cashbook</p>
          <img onClick={() => {downloadCashbook()}} style={{cursor:"pointer", height:"30px"}} src={require("../assets/images/download icon.png")}/>
        </div>
        {skeletonLoading ?
          <div style={{height:"60vh", overflowY:"scroll"}}>
              <Skeleton count={6} borderRadius={"10px"} height={"10vh"} /> 
          </div>
        :
          <>
            {getCashBookList && getCashBookList.length > 0 ?
              <div style={{overflowY:"scroll", height:"60vh"}}>
                {getCashBookList && getCashBookList.map((value,index) => {
                    return(
                      index >= minIndex && index < maxIndex && (
                        <div  className={`card  ${active == index && 'active'}`} key={index}  onClick={() => {setGetDate(value.db_date);setActive(index)}}>
                          <div className='cardUp'>
                              <p className="child">{value.date}</p>
                              <p className="child">₹ {parseFloat(value.balance).toFixed(2)}</p>
                          </div>
                          <div className='cardImg'>
                            {
                                  (active === index )
                                  ?  
                                  <img style={{top:"35%"}}  src={require("../assets/images/white_left_marker.png")} />
                              
                                  :
                                  <img style={{top:"35%"}} src={require("../assets/images/vector.png")} />
                              
                            }
                            

                            
                          </div>
                        </div>
                      )
                    )
                  })}      
              </div> 
            :
              <div style={{height:"60vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <p style={{color:"#7a7a7a", fontWeight:"600", fontSize:"18px"}}>no data found</p>
              </div>
            }
          </>
        }
        <div className="pageCount" style={{marginTop:"15px"}}>
          <Pagination count={countPage} defaultPage={1} page={currentPage} onChange={(event, pageNumber) => handlePagination(pageNumber)}/>
        </div> 
    </div>
  )
}
