import React,{ useEffect, useState } from 'react'
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Document, Page, pdfjs } from "react-pdf";
import './BillGenerate.css'
import { useNavigate } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ProformaInvoiceEdit = ({getPdf, showbillid, setBillIdPitB, setPerformabill, setPerformatemp, setProformaaddgrahak, setInvoiceedit, setPerformaviewbill}) => {

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate()

  const [billPdf, setBillPdf] = useState(null)
  const [itemList, setItemList] = useState([])

  const [getMerchantDetail, setGetMerchantDetail] = useState(null)
  const [imgUrl, setImgUrl] = useState(null)

  useEffect(() => {
    if(showbillid && showbillid != null) {
      const getBillListUrl = {
        method: "GET",
        url: Helper.getWsUrl() + `/vouchers/customer_bill_details/${showbillid}?web=1`,
        headers: { "Content-Type": "application/json", 'token': cookies.token },
      };
      axios(getBillListUrl)
      .then((response) => {        
          if (response.data.code === 200) {     
            setBillPdf(Helper.encypt_decrypt(response.data.data))
            setItemList(Helper.encypt_decrypt(response.data.data).billItems)
          }
          else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          }
      })
      .catch((error) => {
          alert(error);
      })
    }
  },[showbillid])

  useEffect(() => {
    const requestOptions = {
        method: "GET",
        url: Helper.getWsUrl() + `/contact/get_contact/0/?app_type=0&web=1`,
        headers: { "Content-Type": "application/json", "token": cookies.token },
    };
    axios(requestOptions)
        .then((response) => {
            if (response.data.code === 200) {
                setGetMerchantDetail(Helper.encypt_decrypt(response.data.data))
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            }
        })
        .catch((err) => {
            console.log(err)
        })
  }, [])

  useEffect(() => {
    if (getMerchantDetail && getMerchantDetail.logo === 1) {
        setImgUrl(getMerchantDetail.m_logo_url)
    } else {
        let url = require("../assets/images/enbill_logo.png")
        setImgUrl(url)
    }
  }, [getMerchantDetail])

  return (
    <div className='col-10 for_pdf' style={{backgroundColor:"rgb(245, 245, 245)", height:"100%", padding:"20px", position:"relative", overflow:"hidden", position:"relative"}}>
      <div onClick={() => {window.location.reload()}} style={{cursor:"pointer", position:"absolute", top:"0", left:"0", background:"#008c53", color:"#fff", fontWeight:"600", fontSize:"16px", zIndex:"1", padding:"10px 5px", borderRadius:"0 25px 25px 0", writingMode:"vertical-rl"}}>
        View Performa Invoices
      </div>

      <div style={{background:"#fff", width:"100%", height:"100%", borderRadius:"20px", padding:"10px 20px", position:"relative", overflow:"hidden", display:"flex", justifyContent:"center"}}>
        <div style={{width:"100%", display:"flex", alignItems:"center", flexDirection:"column"}}>
          <div style={{width:"70%", padding:"20px", borderRadius:"10px", border:"1px dashed #d9d9d9"}}>

            <div style={{width:"100%", textAlign:"center"}}>
              <img src={imgUrl} style={{ borderRadius: "50%", width:"80px", height:"80px", marginBottom:"10px"}} />
              <p style={{fontWeight:"600", fontSize:"18px", margin:"0"}}>{billPdf && billPdf.shopName}</p>
              <p style={{fontWeight:"500", fontSize:"16px", margin:"0"}}>{billPdf && billPdf.shopMobNumber}</p>
              <p style={{fontWeight:"500", fontSize:"16px", margin:"0"}}>{billPdf && billPdf.address}</p>
            </div>

            <div className="col-12" style={{marginTop:"15px"}}>
                <p style={{fontWeight:"600"}}>Date & time : <span style={{fontWeight:"400"}}>{billPdf && billPdf.dateTime}</span></p>
            </div>

            <div id='allTableItemList' style={{marginTop:"15px", marginBottom:"10px", maxHeight:"15vh", overflowY:"scroll"}}>
              <table class="table table-striped" style={{border:"none"}}>
                  <thead>
                      <tr>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Sno.</th>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Item Name</th>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Qty.</th>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Price</th>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Amount</th>
                      </tr>
                  </thead>
                  <tbody>
                      {itemList && itemList.map((value, index) => {
                      return(
                              <tr>
                                  <td style={{border:"none", fontWeight:"500"}}>{index + 1}.</td>
                                  <td style={{border:"none", fontWeight:"500"}}>{value.itemName}</td>
                                  <td style={{border:"none", fontWeight:"500"}}>{value.itemQty}</td>
                                  <td style={{border:"none", fontWeight:"500"}}>{value.rate}</td>
                                  <td style={{border:"none", fontWeight:"500"}}>{parseFloat(value.amount)}</td>
                              </tr>
                          )
                      })}
                  </tbody>
              </table> 
            </div>
            <div style={{width:"100%", display:"flex", justifyContent:"end"}}>
                <div style={{display:"flex"}}>
                    <div style={{display:"flex", flexDirection:"column"}}>
                      <div style={{fontWeight:"600"}}>
                        Total :
                      </div>
                    </div>                            
                    <div style={{display:"flex", flexDirection:"column"}}>
                      <div className=" p-0 " style={{marginLeft:"10px"}}>
                        ₹ {billPdf && billPdf.totalInvoice}
                      </div>
                    </div>
                </div>
            </div>
            
            <div style={{width:"100%", textAlign:"left"}}>
              <div style={{fontWeight:"550"}}>
                From : &nbsp;
                <span style={{fontWeight:"400"}}>
                  {billPdf && billPdf.shopName}
                </span>
              </div>
              <div style={{fontWeight:"550"}}>
                To : &nbsp;
                {billPdf && billPdf.customerName != null && billPdf.customerName != "" ?
                  <span style={{fontWeight:"400"}}>
                    {billPdf && billPdf.customerName}
                  </span>
                :
                  <span style={{fontWeight:"400"}}>
                    --NA--
                  </span>
                }
              </div>
              <div style={{fontWeight:"550"}}>
                Customer Mobile No. : &nbsp;
                {billPdf && billPdf.customerMobile != null ? 
                  <span style={{fontWeight:"400"}}>
                    {billPdf && billPdf.customerMobile}
                  </span>
                :
                  <span style={{fontWeight:"400"}}>
                    --NA--
                  </span>
                }
              </div>
              <div style={{fontWeight:"550"}}>
                Bill No. : &nbsp;
                <span style={{fontWeight:"400"}}>
                  {billPdf && billPdf.billNo}
                </span>
              </div>
            </div>
          </div>
        
          <div style={{width:"100%", justifyContent:"center", alignItems:"center", display:"flex", marginTop:"10px"}}>
            <button onClick={() => {window.location.reload()}} style={{backgroundColor:"#fff", border:"1px solid #008c53", color:"#008c53", fontWeight:"600", borderRadius:"20px", padding:"5px 20px", }}>
              Done
            </button>
            <button onClick={() => {setBillIdPitB(showbillid); navigate("/Billing")}} style={{backgroundColor:"#008C53", border:"none", color:"#fff", fontWeight:"600", borderRadius:"20px", padding:"5px 20px", marginLeft:"20px"}}>
              Make Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProformaInvoiceEdit;

