import React from "react";

const InventoryNewuser = () => {
    return (
        <div style={{ backgroundColor: "white", width: "100%", display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <div>
                    <img src={require("../assets/images/inventory 1.png")} style={{ height: "254px", width: "305px" }} />
                </div>
                <div style={{ marginTop: "15px" }}>
                    <p style={{ color: "#8C8C8C", fontWeight: "600", fontSize: "18px" }}>Add items to catalouge to organise your Inventory.</p>
                </div>
                
            </div>
        </div>
    )
}
export default InventoryNewuser;