import "./Addgrahak.css";
import { useState, useCallback, useEffect } from "react";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const AddGrahak = ({ handleClick, setShowAddGrahak, setCategoryId }) => {

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate()

  const [showcategory, setShowcategory] = useState(false);
  const [addCategory, setAddcategory] = useState(false);
  const[categoryValue,setCategoryValue]=useState(null);
  const[categoryInput,setCategoryInput]=useState(null);
  const [grahakDetails, setGrahakDetails] = useState(null)
  const [categoryList, setCategoryList] = useState([])
 
  const [inputs, inputsAll] = useState({
    name: null,
    mobileNumber: null,
    address: null,
    gst: null,
  });
  const [grahakContact, setGrahakContact] = useState({
    mobile_number: ""
  })

  const handleChange = useCallback(event => {
    const { name, value } = event.target;
    setGrahakDetails({
      ...grahakDetails,
      [name]: value,
    });
  })

  const handleChangeContact = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      setGrahakContact({
        ...grahakContact,
        mobile_number: event.target.value
      });
    }
  };

  useEffect(() => {
    inputsAll({
      ...inputs,
      name: grahakDetails && grahakDetails.name,
      mobileNumber: grahakContact && grahakContact.mobile_number,
      address: grahakDetails && grahakDetails.address,
      gst: grahakDetails && grahakDetails.gst,
    })
  }, [grahakDetails, grahakContact])

  const getCategorylistFunc = () => {
    const addCustomerRequest = {
      method: "GET",
      url: Helper.getWsUrl() + "/contact/get_customer_category/?web=1",
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(addCustomerRequest)
      .then((response) => {
        if (response.data.code === 200) {
          // console.log("response : ", response.data)
          setCategoryList(Helper.encypt_decrypt(response.data.data))
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
      })
      .catch((error) => {
        alert(error);
      });
  }

  const categoryPostFunc = () => {
    const addCustomerRequest = {
      method: "POST",
      url: Helper.getWsUrl() + "/contact/add_customer_category/?web=1",
      headers: { "Content-Type": "application/json", Token: cookies.token },
      data:{
        category_name: categoryInput,
      }
    };
    axios(addCustomerRequest)
      .then((response) => {
        if (response.data.code === 201) {
          // console.log("response : ", response.data)
          setCategoryId(Helper.encypt_decrypt(response.data.data).cc_id)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <>
      <div id="box" style={{ width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        {/* <img
          src={require("../assets/images/enbill_logo.png")}
          className=" img-fluid rounded-start mt-4 mx-auto d-block ms-0 "
          height={"20%"}
          width={"20%"}
          class="img-fluid"
        ></img> */}
        

        <form style={{ width: "90%", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div className="form user-input-wrp" style={{ position: "relative", width: "70%" }}>
            <input
              autocomplete="off"
              type="text"
              placeholder=""
              className="inputText"
              name="name"
              // value={inputs.name}
              onChange={handleChange}
              style={{
                fontSize: "18px",
                borderRadius: "10px",
                outline: "none",
                width: "100%",
                marginBottom: "20px"
              }}
            />
            <p style={{ position: "absolute", bottom: "60%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Grahak Name</p>
          </div>
          <div className="form user-input-wrp" style={{ position: "relative", width: "70%" }}>
            <input
              autocomplete="off"
              type="text"
              placeholder=""
              className="inputText"
              name="mobile_number"
              value={grahakContact && grahakContact.mobile_number}
              maxLength={10}
              onChange={(event) => { handleChangeContact(event) }}
              style={{
                fontSize: "18px",
                borderRadius: "10px",
                outline: "none",
                width: "100%",
                marginBottom: "20px"
              }} />
           <p style={{ position: "absolute", bottom: "60%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Grahak Mobile Number*</p> 
          </div>
          <div className="form user-input-wrp" style={{ position: "relative", width: "70%" }}>
            <input
              type="text"
              placeholder=""
              autoComplete="off"
              className="inputText"
              name="address"
              // value={inputs.address}
              onChange={handleChange}
              style={{
                fontSize: "18px",
                borderRadius: "10px",
                outline: "none",
                width: "100%",
                marginBottom: "20px"
              }}
            />
           <p style={{ position: "absolute", bottom: "60%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Grahak Address</p> 
          </div>
          <div className="form user-input-wrp" style={{ position: "relative", width: "70%" }}>
            <input
              type="text"
              autoComplete="off"
              placeholder=""
              name="gst"
              // value={inputs.gst}
              onChange={handleChange}
              className="inputText"
              style={{
                fontSize: "18px",
                borderRadius: "10px",
                outline: "none",
                width: "100%",
                marginBottom: "20px"
              }}
            />
           <p style={{ position: "absolute", bottom: "60%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>GST Number(optional)</p> 
          </div>
          <div style={{ display: "flex", width: "68%" }}>
            {categoryValue && categoryValue !=null ? 
              <button onClick={() => {setCategoryValue(null); setAddcategory(false); setCategoryInput(null)}} style={{ backgroundColor: "white", color: "#7a7a7a", border: "1px solid #B6BDC7", fontWeight: "500", fontSize: "12px", borderRadius: "20px", padding:"5px 8px", width: "max-content", margin: "10px", display:"flex" }}>
                {categoryValue}
                <img style={{marginLeft:"5px"}} src={require("../assets/images/Global icons.png")} class="img-fluid" />
              </button> 
            :
              <div onClick={() => {setShowcategory(!showcategory); getCategorylistFunc()}} style={{ display: "flex" }}>
                <div style={{ cursor: "pointer" }}>
                  <img src={require("../assets/images/addCategory.png")} class="img-fluid" />
                </div>
                <div>
                  <p style={{ color: "#2B2B2B", fontWeight: "500", fontSize: "14px", paddingTop: "4px", paddingLeft: "8px", cursor: "pointer" }}>Add Category</p>
                </div>
              </div>
            } 
          </div>
        </form>
        {showcategory ?
          <div style={{ marginTop: "5%", width: "70%", textAlign: "center" }}>
            {addCategory ?
              <div>
                <input onChange={(event)=>{setCategoryInput(event.target.value)}}
                  type="text" autoComplete="off" placeholder="Category" className="textbox" style={{ fontSize: "18px", borderRadius: "10px", outline: "none", width: "80%", marginBottom: "20px", border: "1px solid #B6BDC7" }} />
                <div>
                  <button onClick={()=>setAddcategory(false)} style={{ backgroundColor: "white", color: "#008C53", borderRadius: "24px", border: "none", fontWeight: "600", fontSize: "16px", border: "1px solid #008C53" ,padding:"5px 25px"}} >
                    Cancel
                  </button>
                  <button onClick={() => {setCategoryValue(categoryInput); setShowcategory(false); categoryPostFunc();}} style={{ backgroundColor: "#008C53", color: "white", borderRadius: "24px", border: "none", fontWeight: "600", fontSize: "16px", marginLeft: "20px" ,padding:"5px 25px"}} >
                    OK
                  </button>
                </div>
              </div> 
            : 
              <div>
                {categoryList && categoryList.map((value, index) => {
                  return(
                    <button onClick={() => {setCategoryValue(value.category_name); setCategoryId(value.cc_id); setShowcategory(false)}} style={{ backgroundColor: "white", color: "#7a7a7a", border: "1px solid #B6BDC7", fontWeight: "500", fontSize: "12px", borderRadius: "20px", width: "max-content", padding:"5px 8px", margin: "10px" }}>
                      {value.category_name}
                    </button>
                  )
                })}
                {categoryList && categoryList.length >= 10 ?
                  null
                :
                 <button onClick={() => {setAddcategory(true)}} style={{ backgroundColor: "white", color: "#008c53", border: "1px solid #008c53", fontWeight: "500", fontSize: "12px", borderRadius: "20px",padding:"5px 25px", margin: "10px" }}>Add More</button> 
                }
                 </div>
            }
          </div>
        : null}

        <div style={{ textAlign: "center", }}>
          <button
            className="mt-4"
            style={{
              backgroundColor: "#008C53",
              color: "white",
              padding: "5px 25px",
              borderRadius: "20px",
              // marginLeft: "30%",
              border: "none",
              fontWeight: "600",
              fontSize: "16px"
            }}
            onClick={() => { handleClick(inputs) }}
          >
            Save
          </button>

        </div>

      </div>
    </>

  );
}

export default AddGrahak;
