import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Login from "./pages/Login";
import Onboarding from "./pages/Onboarding";
import Onboarding1 from "./pages/Onboarding1";
import Onboarding2 from "./pages/Onboarding2";
import { NewDashboard } from "./pages/NewDashboard";

import CatalougeIndex from "./pages/CatalougeIndex";
import CatalougeViewBill from "./pages/CatalougeViewBill";

import Billing from "./pages/Billing";
import Order from "./pages/Order";
import Purchase from "./pages/Purchase";
import NewGrahak from "./pages/NewGrahak";
import Galla from "./pages/Galla";
import Udhaar from "./pages/Udhaar";
import Privacypolicy from "./pages/PrivacyPolicy";
import TermsUses from "./pages/TermsUses";
import Profile from "./pages/Profile";
import ManageQR from "./pages/ManageQR";
import Kyc from "./pages/KYC";
import Template from "./pages/Template";
import Team from './pages/Team';
import GetHelp from "./pages/GetHelp";
import AndroidNotification from "./pages/AndroidNotification";
import Proformainvoice from "./pages/ProformaInvoice";
// import ProformaNewUser from "./pages/ProformaNewUser";
import Inventory from "./pages/Inventory";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { useCookies } from "react-cookie";


function App() {

  const location = useLocation().pathname
  const [cookies, setCookie, removeCookie] = useCookies(["token"]); 
  const navigate = useNavigate()

  const [showMoileView, setShowmobileview] = useState(false);
  
  const [globalItemId, setGlobalItemId] = useState(null)
  const [globalIvId, setGlobalIvId] = useState(null)
  const [globalBillId, setGlobalBillId] = useState(null)
  const [globalVoucherId, setGlobalVoucherId] = useState(null)
  const [globalCustomerId, setGlobalCustomerId] = useState(null)
  const [globalIbId, setGlobalIbId] = useState(null)

  const [billIdPitB, setBillIdPitB] = useState(null)
  const [grahakDtlPitB, setGrahakDtlPitB] = useState(null)
  const [ttlAmtAllItemPitB, setTtlAmtAllItemPitB] = useState(null)
  const [grahakDtlFrmGTB, setGrahakDtlFrmGTB] = useState(null)

  useEffect(() => {
    if (isMobile) {
      setShowmobileview(true);
    } else {
      setShowmobileview(false);
    }
  }, []);
  
  let isAuthenticated = localStorage.getItem("isAuthenticated")
  if(!isAuthenticated) {
    isAuthenticated = "false"
  }

  const redirectLogout = (location) => {
    if(["/"].includes(location)) {
      removeCookie("logged_in")
      removeCookie("token")
      localStorage.setItem("isAuthenticated", false)
      localStorage.removeItem("isAuthenticated")
      navigate('/')
    }
  }
  
  return (
    <div className="App">
      {showMoileView ? (
        <AndroidNotification />
      ) : (
        <div className='container-fluid'>
          {isAuthenticated == "false" ? 
            null 
          : 
            <>
              {["/onboarding", "/onboarding1", "/onboarding2"].includes(location) ? 
                null
              : 
                <Header setGlobalIbId = {setGlobalIbId} setGlobalCustomerId = {setGlobalCustomerId} setGlobalVoucherId = {setGlobalVoucherId} setGlobalBillId = {setGlobalBillId} setGlobalIvId = {setGlobalIvId} setGlobalItemId = {setGlobalItemId}/>
              }
            </>
          } 
          <div className='row' style={{ height:"83vh", display:"flex"}}>
            {isAuthenticated == "false" ? 
              null 
            : 
              <>
                {["/onboarding", "/onboarding1", "/onboarding2"].includes(location) ? 
                  null
                : 
                  <Sidebar/>
                }
              </>
            }
            <Routes>
              {isAuthenticated == "false" ? (
                <>
                  <Route path="/" element={<Login />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </>
              ) : (
                <>
                  {isAuthenticated && redirectLogout(location)}
                  <Route path="/dashboard" element={<NewDashboard />} />
                  <Route path="/onboarding" element={<Onboarding />} />
                  <Route path="/onboarding2" element={<Onboarding2 />} />
                  <Route path="/onboarding1" element={<Onboarding1 />} />
                  <Route path="/Catalogue" element={<CatalougeIndex globalIvId = {globalIvId} globalItemId = {globalItemId}/>} />
                  <Route path="/Catalogue/add_new_item" element={<CatalougeViewBill />} />
                  <Route path="/Billing" element={<Billing grahakDtlFrmGTB = {grahakDtlFrmGTB} globalBillId = {globalBillId} ttlAmtAllItemPitB = {ttlAmtAllItemPitB} grahakDtlPitB = {grahakDtlPitB} billIdPitB = {billIdPitB}/>} />
                  <Route path="/Order" element={<Order globalIbId = {globalIbId}/>} />
                  <Route path="/Purchase" element={<Purchase globalVoucherId = {globalVoucherId}/>} />
                  <Route path="/Grahak" element={<NewGrahak globalCustomerId = {globalCustomerId} setGrahakDtlFrmGTB = {setGrahakDtlFrmGTB}/>} />
                  <Route path="/Galla" element={<Galla />} />
                  <Route path="/Udhaar" element={<Udhaar />} />
                  <Route path="/Privacypolicy" element={<Privacypolicy />} />
                  <Route path="/TermsUses" element={<TermsUses />} />
                  <Route path="/Profile" element={<Profile />} />
                  <Route path="/ManageQR" element={<ManageQR />} />
                  <Route path="/Kyc" element={<Kyc />} />
                  <Route path="/Template" element={<Template />} />
                  <Route path="/GetHelp" element={<GetHelp />} />
                  <Route path="/Proformainvoice" element={<Proformainvoice setTtlAmtAllItemPitB= {setTtlAmtAllItemPitB} setGrahakDtlPitB = {setGrahakDtlPitB} setBillIdPitB = {setBillIdPitB} />} />
                  <Route path="/Androidnotification" element={<AndroidNotification />} />
                  <Route path="/Inventory" element={<Inventory/>} />
                  <Route path="/Team" element={<Team/>}/>
                </>
              )}
            </Routes>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
