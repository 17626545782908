import React,{useState, useEffect, useCallback} from 'react'
import axios from "axios";
import Helper from "../util/Helper";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import './CatalougeViewBill.css'
import { Button, TextField } from '@mui/material';
import S3 from 'react-aws-s3';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

const CatalougeViewBill = () => {

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const [inputItemDetail, setInputItemDetail] = useState(null)
    const [getItemIdCatalouge, setGetItemIdCatalouge] = useState(null)
    const [inputItemVarient, setInputItemVarient] = useState([])
    const [showQtyColumn, setShowQtyColumn] = useState(false)
    const [showDescriptionColumn, setShowDescriptionColumn] = useState(false)
    const [itemVarFrmTblCata, setItemVarFrmTblCata] = useState(1)
    const [showAddItmVarBtn, setShowAddItmVarBtn] = useState(false)
    const [discForTbl, setDiscForTbl] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [forNavigate, setforNavigate] = useState(false)
    const [dsbleFrstTblRow, setDsbleFrstTblRow] = useState(false)
    const [discription, setDiscription] = useState(null)
    const [hsnNo, setHsnNo] = useState(null)
    const [productOrService, setProductOrService] = useState(false)
    const [b2bOrB2c, setB2bOrB2c] = useState(false)
    const [liveOrOffline, setLiveOrOffline] = useState(false)
    const [itemImg1, setItemImg1] = useState(null);
    const [itemImg2, setItemImg2] = useState(null);
    const [itemImg3, setItemImg3] = useState(null);
    const [itemImg4, setItemImg4] = useState(null);
    const [getUnitData, setGetUnitData] = useState(null) 
    const [getGSTData, setGetGSTData] = useState(null) 

    const [getSelectedUnit, setGetSelectedUnit] = useState(0)
    const [selectedUnit, setSelectedUnit] = useState(null)
    const [showAddMoreUnitBtn, setShowAddMoreUnitBtn] = useState(true)
    const [newUnit, setNewUnit] = useState(null)

    const [getSelectedGst, setGetSelectedGst] = useState(0)
    const [selectedGst, setSelectedGst] = useState(null)
    const [showAddMoreGstBtn, setShowAddMoreGstBtn] = useState(true)
    const [newGst, setNewGst] = useState(null)

    // const [masterCategoryList, setMasterCategoryList] = useState([])
    const [masterSubCategoryList, setMasterSubCategoryList] = useState([])
    // const [activeMasterCategory, setActiveMasterCategory] = useState(0)
    const [addNewSubCategory, setAddNewSubCategory] = useState(null)
    const [masterCategoryId, setMasterCategoryId] = useState(null)
    const [masterSubCategoryId, setMasterSubCategoryId] = useState(null)
    // const [selectedMasterCategory, setSelectedMasterCategory] = useState(null)
    const [selectedSubCategory, setSelectedSubCategory] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();

    const [itemVarValForApiCata, setItemVarValForApiCata]= useState({
        size: null,
        mrp: 0,
        pprice: 0,
        sprice: 0,
        disc: 0,
        color: null,
        model: null,
        refkey: null,
        refvalue: null,
        qty: 1,
        stock: 0
    })
  
    let productOrServiceValue = 0, b2bOrB2cvalue = 0, liveOrOfflineValue = 1, imgCount = 0

    let contact_id = localStorage.getItem("contact_id")

    var date=new Date();
    var tdate=date.getDate();
    var year = date.getFullYear();
    
    if(tdate < 10){
        tdate = "0"+ tdate;
    }
    var month=date.getMonth()+1;
    if(month < 10){
        month = "0"+ month;
    }
    var maxDate = year + "-"+month+"-"+tdate;

    const setProductOrServiceFunc = () => {
        if(productOrService === false) {
            productOrServiceValue = 1
            setProductOrService(true)
        } else {
            productOrServiceValue = 0
            setProductOrService(false)
        }
    }

    const setB2bOrB2cFunc = () => {
        if(b2bOrB2c === false) {
            b2bOrB2cvalue = 1
            setB2bOrB2c(true)
            setShowQtyColumn(true)
        } else {
            b2bOrB2cvalue = 0
            setB2bOrB2c(false)
            setShowQtyColumn(false)
        }
    }

    const setLiveOrOfflineFunc = () => {
        if(liveOrOffline === false) {
            liveOrOfflineValue = 0 
            setLiveOrOffline(true)
            setShowDescriptionColumn(true)
        } else {
            liveOrOfflineValue = 1
            setLiveOrOffline(false)
            setShowDescriptionColumn(false)
            setItemImg1(null)
            setItemImg2(null)
            setItemImg3(null)
            setItemImg4(null)
            setDiscription(null)
        }
    }

    const getItemDetailPost = useCallback(event => {
        const { name, value } = event.target;
        setInputItemDetail({ 
            ...inputItemDetail, 
            [name]: value 
        });
    });  

    const forHsnNoFunc = (e) => {
        const re = /^[0-9\b]+$/;
        if(e.target.value === '' || re.test(e.target.value)) {
            setHsnNo(e.target.value);
        } else {
            setHsnNo(null)
        }
    } 

    const getItemVarFrmTblCata = useCallback(event => {
        const { name, value } = event.target;
        setItemVarFrmTblCata({ 
            ...itemVarFrmTblCata, 
            [name]: value 
        });
    });

    useEffect(() => {
        let discFrmTbl = 0
        if(itemVarFrmTblCata && itemVarFrmTblCata.c_mrp != null && itemVarFrmTblCata.c_sellingprice != null) {
            discFrmTbl = parseFloat(((itemVarFrmTblCata.c_mrp - itemVarFrmTblCata.c_sellingprice) / itemVarFrmTblCata.c_mrp) * 100).toFixed(2)
            setDiscForTbl(discFrmTbl)
        }
        if(itemVarFrmTblCata && itemVarFrmTblCata != null) {
            setItemVarValForApiCata({
                ...itemVarValForApiCata,
                size: itemVarFrmTblCata && itemVarFrmTblCata.c_size,
                mrp: parseFloat(itemVarFrmTblCata && itemVarFrmTblCata.c_mrp).toFixed(2),
                pprice: itemVarFrmTblCata && itemVarFrmTblCata.c_purchaseprice != null ? parseFloat(itemVarFrmTblCata.c_purchaseprice).toFixed(2) : 0,
                sprice: itemVarFrmTblCata && itemVarFrmTblCata.c_sellingprice != null ? parseFloat(itemVarFrmTblCata.c_sellingprice).toFixed(2) : parseFloat(itemVarFrmTblCata.c_mrp).toFixed(2),
                disc: parseFloat(discFrmTbl).toFixed(2),
                color: itemVarFrmTblCata && itemVarFrmTblCata.c_color,
                model: itemVarFrmTblCata && itemVarFrmTblCata.c_model,
                refkey: itemVarFrmTblCata && itemVarFrmTblCata.c_addReference,
                refvalue: itemVarFrmTblCata && itemVarFrmTblCata.c_addReference,
                qty: itemVarFrmTblCata && itemVarFrmTblCata.c_qty != null ? parseFloat(itemVarFrmTblCata && itemVarFrmTblCata.c_qty) : 1,
                stock: itemVarFrmTblCata && itemVarFrmTblCata.c_stock != null ? parseInt(itemVarFrmTblCata.c_stock) : 0,
                expiry_date: itemVarFrmTblCata && itemVarFrmTblCata.c_expiryDate,
                sku_code: itemVarFrmTblCata && itemVarFrmTblCata.c_skuCode
            })
        }
    },[itemVarFrmTblCata])

    useEffect(() => {
        if(itemVarFrmTblCata && itemVarFrmTblCata.c_mrp != null) {
            setShowAddItmVarBtn(true)
        }
    },[itemVarFrmTblCata])

    useEffect(() => {
        let discFrmModel = 0
        if(inputItemDetail && inputItemDetail.itemMrp_catalouge != null && inputItemDetail.itemSelling_catalouge != null) {
            discFrmModel = parseFloat(((inputItemDetail.itemMrp_catalouge - inputItemDetail.itemSelling_catalouge) / inputItemDetail.itemMrp_catalouge) * 100).toFixed(2)
            setDiscForTbl(discFrmModel)
        }
        if(inputItemDetail && inputItemDetail != null) {
            setItemVarValForApiCata({
                ...itemVarValForApiCata,
                size: inputItemDetail && inputItemDetail.itemSize_catalouge,
                mrp: parseFloat(inputItemDetail && inputItemDetail.itemMrp_catalouge).toFixed(2),
                pprice: inputItemDetail && inputItemDetail.itemPurchase_catalouge != null ? parseFloat(inputItemDetail.itemPurchase_catalouge).toFixed(2) : 0,
                sprice: inputItemDetail && inputItemDetail.itemSelling_catalouge != null ? parseFloat(inputItemDetail.itemSelling_catalouge).toFixed(2) : parseFloat(inputItemDetail.itemMrp_catalouge).toFixed(2),
                disc: parseFloat(discFrmModel).toFixed(2),
                color: inputItemDetail && inputItemDetail.itemColor_catalouge,
                model: inputItemDetail && inputItemDetail.itemModel_catalouge,
                refkey: inputItemDetail && inputItemDetail.itemRefKey_catalouge,
                refvalue: inputItemDetail && inputItemDetail.itemRefValue_catalouge,
                qty: inputItemDetail && inputItemDetail.itemQty_catalouge != null ? parseFloat(inputItemDetail && inputItemDetail.itemQty_catalouge) : 1,
                stock: inputItemDetail && inputItemDetail.itemStock_catalouge != null ? parseInt(inputItemDetail.itemStock_catalouge) : 0,
                expiry_date: inputItemDetail && inputItemDetail.itemQty_expiryDate,
                sku_code: inputItemDetail && inputItemDetail.sku_code
            })
        }
    },[inputItemDetail])

    const forMainPageFunc = () => {
        if(inputItemDetail && inputItemDetail.itemName_Catalouge != null && itemVarFrmTblCata && itemVarFrmTblCata.c_mrp != null){
            if(parseFloat(itemVarFrmTblCata.c_sellingprice) > parseFloat(itemVarFrmTblCata.c_mrp)) {
                alert("Selling Price is not more than MRP")
            } else {
                if(liveOrOffline && liveOrOffline === true) {
                    if(itemImg1 != null || itemImg2 != null || itemImg3 != null || itemImg4 != null) {
                        if(masterSubCategoryId && masterSubCategoryId != null) {
                            postItemDetailFunc();
                        } else {
                            alert("Category is required")
                        }
                    } else {
                        alert("Please select atleast one image")
                    }
                } else {
                    postItemDetailFunc();
                }                   
            }
        } else {
            alert("Please Fill Item Details and Add Varient")
        }
    }

    const postItemDetailFunc = () => {
        const addItemCatalougeUrl = {
            method: "POST",
            url: Helper.getWsUrl() + `/items/post_items/?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
            data:{
                itemDetails:{
                    discount_per:0.0,
                    feature_image: (itemImg1 && itemImg1 != null) || (itemImg2 && itemImg2 != null) || (itemImg3 && itemImg3 != null) || (itemImg4 && itemImg4 != null) ? 1 : 0,
                    gst_tax_per: getSelectedGst && parseFloat(getSelectedGst).toFixed(2),
                    hsn_sac_code:hsnNo && hsnNo,
                    image_1: itemImg1 && itemImg1 != null ? 1 : 0,
                    image_2: itemImg2 && itemImg2 != null ? 1 : 0,
                    image_3: itemImg3 && itemImg3 != null ? 1 : 0,
                    image_4: itemImg4 && itemImg4 != null ? 1 : 0,
                    is_active:1,
                    is_approved:0,
                    item_name:inputItemDetail && inputItemDetail.itemName_Catalouge,
                    min_qty:0.0,
                    miu_id: getSelectedUnit && parseInt(getSelectedUnit).toFixed(2),
                    product_service: productOrServiceValue,
                    short_description: discription && discription,
                    master_category_id: masterCategoryId && masterCategoryId,
                    item_category_id: masterSubCategoryId && masterSubCategoryId,
                },
                product_pricing_details:[                       
                ] 
            }
        };
        axios(addItemCatalougeUrl)
        .then((response) => {          
            if (response.data.code === 201) {
                setGetItemIdCatalouge(Helper.encypt_decrypt(response.data.data).item_id)    
                if(itemImg1 && itemImg1 != null) {
                    imgCount = 0
                    AWSCredentials(itemImg1, imgCount,Helper.encypt_decrypt(response.data.data).item_id)
                }
                if(itemImg2 && itemImg2 != null) {
                    imgCount = 1
                    AWSCredentials(itemImg2, imgCount, Helper.encypt_decrypt(response.data.data).item_id)
                }  
                if(itemImg3 && itemImg3 != null) {
                    imgCount = 2
                    AWSCredentials(itemImg3, imgCount, Helper.encypt_decrypt(response.data.data).item_id)
                }  
                if(itemImg4 && itemImg4 != null) {
                    imgCount = 3
                    AWSCredentials(itemImg4, imgCount, Helper.encypt_decrypt(response.data.data).item_id)
                }                                      
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              } 
        })
        .catch((error) => {
            alert(error);
        })
    }

    useEffect(() => {
        unitGstfunc()
    },[])

    const unitGstfunc = () => {
        const requestOption = {
            method: "GET",
            url: Helper.getWsUrl() + `/masters/get_itemunit_active/1/?web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(requestOption)
            .then((response) => {       
              if (response.data.code === 200) {
                setGetUnitData(Helper.encypt_decrypt(response.data.data))
              } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              }
            })
            .catch((error) => {
              alert(error);
            });
  
        const requestOptionForGst = {
            method: "GET",
            url: Helper.getWsUrl() + `/masters/get_merchant_gst_per_new/?web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(requestOptionForGst)
            .then((response) => {       
            if (response.data.code === 200) {
                setGetGSTData(Helper.encypt_decrypt(response.data.data))
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            }
            })
            .catch((error) => {
            alert(error);
            });

        const requestCategoryOption = {
            method: "GET",
            url: Helper.getWsUrl() + `/items/get_merchant_category/?web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(requestCategoryOption)
            .then((response) => {       
                if (response.data.code === 200) {
                getSubCategory(Helper.encypt_decrypt(response.data.data)[0].id)
                setMasterCategoryId(Helper.encypt_decrypt(response.data.data)[0].id)
                } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
                }
            })
            .catch((error) => {
                alert(error);
            });
    }

    const checkItemVarientCatalouge = () => {
        setInputItemVarient(inputItemVarient =>[
            ...inputItemVarient,inputItemDetail
        ])
        postItemVarientFunc(getItemIdCatalouge) 
    }
    
    useEffect(() => {
        if(inputItemDetail && inputItemDetail.itemMrp_catalouge != null && inputItemDetail.itemSelling_catalouge != null){
            let cal_disc_catalouge = (100 * ((parseFloat(inputItemDetail.itemMrp_catalouge) - parseFloat(inputItemDetail.itemSelling_catalouge))/ parseFloat(inputItemDetail.itemMrp_catalouge)))
            setDiscForTbl(cal_disc_catalouge)
        }
    },[inputItemDetail])

    useEffect(() => {
        if(getItemIdCatalouge && getItemIdCatalouge != null) {
            postItemVarientFunc(getItemIdCatalouge)          
        } 
    },[getItemIdCatalouge])

    const postItemVarientFunc = (itemId) =>{
        const addItemVarientCatalougeUrl = {
            method: "POST",
            url: Helper.getWsUrl() + `/items/add_item_verient/${itemId}/?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
            data:{
                data:{
                    b2b: b2bOrB2cvalue,
                    color: itemVarValForApiCata.color,
                    discount_per: parseFloat(itemVarValForApiCata.disc).toFixed(2),
                    live: liveOrOffline && liveOrOffline === true ? 0 : 1,
                    min_qty: parseFloat(itemVarValForApiCata.qty),
                    model: itemVarValForApiCata.model,
                    mrp: parseFloat(itemVarValForApiCata.mrp),
                    purchase_price: parseFloat(itemVarValForApiCata.pprice),
                    ref_key: itemVarValForApiCata.refkey,
                    ref_value: itemVarValForApiCata.refvalue,
                    selling_price: parseFloat(itemVarValForApiCata.sprice),
                    size: itemVarValForApiCata.size,
                    stock: parseInt(itemVarValForApiCata.stock),
                    discription: discription && discription,
                    expiry_date: itemVarValForApiCata.expiry_date,
                    sku_code: itemVarValForApiCata.sku_code
                }
            }                
        };
        axios(addItemVarientCatalougeUrl)
        .then((response) => {          
            if (response.data.code === 201) {
                setShowModal(true)  
                setDsbleFrstTblRow(true) 
                document.getElementById("empty").reset() 
                setInputItemDetail({
                    ...inputItemDetail,
                    itemSize_catalouge: null,
                    itemMrp_catalouge: null,
                    itemPurchase_catalouge: null,
                    itemSelling_catalouge: null,
                    itemColor_catalouge: null,
                    itemModel_catalouge: null,
                    itemRefKey_catalouge: null,
                    itemRefValue_catalouge: null,
                    itemStock_catalouge: null,
                    itemQty_catalouge: null
                })
                if(forNavigate === true) {
                    navigate("/Catalogue")
                }  
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            } else {
                alert(response.data.msg)
            } 
        })
        .catch((error) => {
            alert(error);
        })
    }

    const AWSCredentials = (imgFile, imgCount, itemId) => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/settings/get_aws_credientials/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                if(response.data.code === 200) {
                    const imgFileKey = "_original_"+`${imgCount}`+".png"
                    const FOLDER_NAME =  `${contact_id}/itm/${itemId}`
                    const config = {
                        bucketName: Helper.encypt_decrypt(response.data.data).bucket_name,
                        region: "ap-south-1",
                        dirName: FOLDER_NAME,
                        accessKeyId: Helper.encypt_decrypt(response.data.data).aws_access_key,
                        secretAccessKey: Helper.encypt_decrypt(response.data.data).aws_secret_access_key,
                        s3Url: "https://enbfile.s3.ap-south-1.amazonaws.com",
                    }
                    const ReactS3Client = new S3(config)
                    ReactS3Client
                        .uploadFile(imgFile, imgFileKey)
                        .then(data => console.log("data : ",data))
                        .catch(err => console.error("error",err))
                }
            }
        );
    }

    const unitPostFunc = () => {
        const requestOptionForGst = {
            method: "POST",
            url: Helper.getWsUrl() + `/masters/post_merchant_unit_new/?web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
            data:{
                name: newUnit && newUnit
            }
          };
          axios(requestOptionForGst)
            .then((response) => {      
              if (response.data.code === 201) {
                setShowAddMoreUnitBtn(true)
                unitGstfunc()
              }
              else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              }
            })
            .catch((error) => {
              alert(error);
            });
    }

    const gstPostFunc = () => {
        const requestOptionForGst = {
            method: "POST",
            url: Helper.getWsUrl() + `/masters/post_merchant_gst_per_new/?web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
            data:{
                gst_per: newGst && newGst
            }
          };
          axios(requestOptionForGst)
            .then((response) => {      
              if (response.data.code === 201) {
                setShowAddMoreGstBtn(true)
                unitGstfunc()
              }
              else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              }
            })
            .catch((error) => {
              alert(error);
            });
    }

    const getSubCategory = (id) => {
        const requestOptionForGst = {
            method: "GET",
            url: Helper.getWsUrl() + `/items/get_item_category/${id}/?web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
          };
          axios(requestOptionForGst)
            .then((response) => {      
                if (response.data.code === 200) {
                    setMasterSubCategoryList(Helper.encypt_decrypt(response.data.data))
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else if (response.data.code === 204) {
                    setMasterSubCategoryList([])
                }
            })
            .catch((error) => {
              alert(error);
            });
    }

    const addSubCategory = () => {
        const requestOptionForGst = {
            method: "POST",
            url: Helper.getWsUrl() + `/items/post_item_category/${masterCategoryId}/?web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
            data: {
                name: addNewSubCategory && addNewSubCategory
            }
          };
          axios(requestOptionForGst)
            .then((response) => {      
                if (response.data.code === 201) {
                    getSubCategory(masterCategoryId)
                    document.getElementById("newSubCategoryForm").reset()
                    setAddNewSubCategory(null)
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else {
                    alert(response.data.msg)
                    setAddNewSubCategory(null)
                }
            })
            .catch((error) => {
              alert(error);
            });
    }

    const[check1,setCheck1]=useState(false);
    const[check2,setCheck2]=useState(false);
    const[check3,setCheck3]=useState(false);

    var clrA = "#008c53";
    var clrB= "#000000";
    var clrE = "#008c53";
    var clrF= "#000000";
    var clrI = "#008c53";
    var clrJ= "#000000";

    if(check1===true){
        var clrC = clrB;
        var clrD = clrA;
    }else{
        var clrC = clrA;
        var clrD = clrB;
    }
    if(check2===true){
        var clrG = clrF;
        var clrH = clrE;
    }else{
        var clrG = clrE;
        var clrH = clrF;
    }
    if(check3===true){
        var clrK = clrJ;
        var clrL = clrI;
    }else{
        var clrK = clrI;
        var clrL = clrJ;
    }

    const textAreaStyle = {
        border:"none",
        width:"100%",
        ':focus-visible': {
            outline:"none"
        }
    }

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    return (
        <div className='col-10' style={{backgroundColor:"rgb(245, 245, 245)",padding:"20px", position:"relative"}}>
            <div onClick={() => navigate("/Catalogue")} style={{position:"absolute", top:"0", left:"0", background:"#008c53", color:"#fff", fontWeight:"600", fontSize:"16px", zIndex:"1", padding:"10px 5px", borderRadius:"0 25px 25px 0", writingMode:"vertical-rl", cursor:"pointer"}}>
                view Catalogue
            </div>
          
            <div style={{background:"#fff", width:"100%", height:"100%", borderRadius:"20px", padding:"20px", position:"relative", overflow:"hidden"}}>
                <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                    <h3 style={{fontSize:"18px", fontWeight:"600", lineHeight:"29px"}}>Add Item Details</h3>
                </div>
                <div style={{display:"flex", justifyContent:"space-around",backgroundColor:"#F9F9F9",paddingTop:"20px"}}>
                    {/* <div class="form-check form-switch" style={{display:"flex", justifyContent:"center"}}>
                        <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", color:clrC}} class="form-check-label" for="flexSwitchCheckDefault">Product</p>
                        <input onClick={() => {setProductOrServiceFunc();setCheck1(!check1)}} style={{marginLeft:"10px"}} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", marginLeft:"10px",color:clrD}} class="form-check-label" for="flexSwitchCheckDefault">Service</p>
                    </div> */}
                    <div class="form-check form-switch" style={{display:"flex"}}>
                        <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", color:clrG}} class="form-check-label" for="flexSwitchCheckDefault">B2C</p>
                        <input onClick={() => {setB2bOrB2cFunc();setCheck2(!check2)}} style={{marginLeft:"10px"}}  class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", marginLeft:"10px",color:clrH}} class="form-check-label" for="flexSwitchCheckDefault">B2B</p>
                    </div>
                    <div class="form-check form-switch" style={{display:"flex"}}>
                        <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", color:clrK}} class="form-check-label" for="flexSwitchCheckDefault">Offline</p>
                        <input onClick={() => {setLiveOrOfflineFunc();setCheck3(!check3)}} style={{marginLeft:"10px"}} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", marginLeft:"10px",color:clrL}} class="form-check-label" for="flexSwitchCheckDefault">Live</p>
                    </div>
                </div>
                <div style={{display:"flex", justifyContent:"space-between", marginTop:"40px"}}>
                    <div class="user-input-wrpBill" style={{width:"25%"}}>                
                        <input autocomplete="off" type="text" name='itemName_Catalouge' onChange={getItemDetailPost} class="inputText" required />
                        <p style={{ position: "absolute", bottom: "35%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Item Name*</p>
                    </div>  
                    <div class="user-input-wrp"  style={{width:"15%"}}>
                        <div style={{position:"relative"}}> 
                            <p style={{ position: "absolute", bottom: "35%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Unit</p>
                            <button data-bs-toggle="modal" data-bs-target="#UnitModal" autocomplete="off" type="text" class="inputText" style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} >
                                {selectedUnit && selectedUnit != null ? selectedUnit : "Unit"}
                                <img src={require('../assets/images/dropdown_arrow.png')} style={{width:"18px"}} />
                            </button>
                        </div>                        
                    </div> 
                    <div class="user-input-wrp"  style={{width:"15%"}}>    
                        <div style={{position:"relative"}}> 
                            <p style={{ position: "absolute", bottom: "35%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>GST%</p>
                            <button data-bs-toggle="modal" data-bs-target="#GstModal" autocomplete="off" type="text" class="inputText" style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} >
                                {selectedGst && selectedGst != null || selectedGst === 0 ? selectedGst : "GST"}
                                <img src={require('../assets/images/dropdown_arrow.png')} style={{width:"18px"}} />
                            </button>
                        </div> 
                    </div>
                    <div class="user-input-wrp"  style={{width:"20%"}}>    
                        <div style={{position:"relative"}}> 
                            <p style={{ position: "absolute", bottom: "35%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Category</p>
                            <button onClick={handleClick('bottom-end')} autocomplete="off" type="text" class="inputText" style={{display:"flex", justifyContent:"space-between", alignItems:"center", whiteSpace:"nowrap", overflow:"hidden"}} >
                                {selectedSubCategory && selectedSubCategory != null ? selectedSubCategory : "Category"}
                                <img src={require('../assets/images/dropdown_arrow.png')} style={{width:"18px"}} />
                            </button>
                        </div> 
                    </div>
                    <Popper style={{width: 350}} open={open} anchorEl={anchorEl} placement={placement} transition>
                        {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                            <Typography sx={{ p: 0, mt: 1 }}>
                                    <div style={{background:'rgb(245, 245, 245)', borderRadius:"10px", padding:'5px', width:'100%', maxHeight: masterSubCategoryList && masterSubCategoryList.length > 0 ? '35vh' : "12vh"}}>
                                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:'5px'}}>
                                            <form id='newSubCategoryForm' style={{width:'60%'}}>
                                                <input placeholder='Add item Category' value={addNewSubCategory && addNewSubCategory} onChange={(e) => {setAddNewSubCategory(e.target.value)}} style={{width:'100%', borderRadius:'15px', border:'1px solid #7a7a7a', padding:'4px 15px', fontSize:'14px'}} />
                                            </form>
                                            <button disabled={addNewSubCategory && addNewSubCategory != null ? '' : 'disable'} onClick={addSubCategory} style={{border:'none', borderRadius:'15px', background:'#008c53', fontSize:'13px', fontWeight:'500', color:'#fff', padding:'5px 15px', marginLeft:'10px'}}>Add</button>
                                        </div>
                                        {masterSubCategoryList && masterSubCategoryList.length > 0 ? 
                                            <div id='allTableItemList' style={{width:'100%', maxHeight:'30vh', overflowY:'scroll' }}>
                                                {masterSubCategoryList && masterSubCategoryList.map((value, index) => {
                                                    return (
                                                        <>
                                                            <div type='button' onClick={() => {setMasterSubCategoryId(value.ic_id); setSelectedSubCategory(value.name); setOpen(false)}} style={{display:"flex", alignItems:"center"}}>
                                                                <img src={value.image_url != null && value.image_url != "N/A" && value.image_url != "" ? value.image_url : require("../assets/images/enbill logo 1.png")} 
                                                                        onError={(e) =>
                                                                            (e.target.onerror = null)(
                                                                                (e.target.src = require("../assets/images/enbill logo 1.png"))
                                                                            )} style={{width:"55px", height:"55px"}} />
                                                                <p style={{padding:'5px', margin:'0', fontSize:'14px', fontWeight:"500"}}>{value.name}</p>
                                                                <hr style={{margin:'0', border:'0.1px solid rgb(188 184 184)'}}/>
                                                            </div>
                                                            <hr style={{padding:"0", margin:"5px 0"}}/>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        :
                                            <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                <p style={{fontSize:'14px', marginTop:'20px', fontWeight:'400', color:'#7a7a7a'}}>No item category found</p>
                                            </div>
                                        }
                                    </div>
                            </Typography>
                            </Paper>
                        </Fade>
                        )}
                    </Popper>
                    <div class="user-input-wrpBill"  style={{width:"15%"}}>                
                        <input autocomplete="off" value={hsnNo && hsnNo} onChange={(e) => {forHsnNoFunc(e)}} type="text" class="inputText" maxLength={6} />
                        <p style={{ position: "absolute", bottom: "35%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>HSN/SAC</p>
                    </div> 
                </div>
                
                <div style={{width:"100%"}}>
                    <table class="table" style={{marginTop:"50px", width:"100%", maxHeight:"40vh"}}>
                        <thead>
                            <tr>
                                <th style={{width:"3%", textAlign:"center"}}>S.No</th>
                                <th style={{width:"10%", textAlign:"center"}}>Size</th>
                                <th style={{width:"10%", textAlign:"center"}}>MRP*<br/>(in ₹)</th>
                                <th style={{width:"10%", textAlign:"center"}}>Purchase<br/>Price/Unit</th>
                                <th style={{width:"10%", textAlign:"center"}}>Selling<br/>Price/unit</th>
                                <th style={{width:"10%", textAlign:"center"}}>Disc.<br/>(in %)</th>
                                <th style={{width:"10%", textAlign:"center"}}>Color</th>
                                <th style={{width:"10%", textAlign:"center"}}>Model</th>
                                <th style={{width:"10%", textAlign:"center"}}> Other Reference</th>
                                {showQtyColumn ? <th style={{width:"5%", textAlign:"center"}}>Qty</th> : null}
                                {localStorage.getItem("inventory") != 1 ? null : <th style={{width:"5%", textAlign:"center"}}>Stock</th>}
                                <th style={{width:"10%", textAlign:"center"}}>Expiry date</th>
                                <th style={{width:"10%", textAlign:"center"}}>SKU Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1.</td>
                                <td>
                                    <input autoComplete='off' disabled={dsbleFrstTblRow ? "disabled" : ""} type="text" onChange={getItemVarFrmTblCata} name="c_size" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                </td>
                                <td>
                                    <input autoComplete='off' disabled={dsbleFrstTblRow ? "disabled" : ""} type="number" onChange={getItemVarFrmTblCata} name="c_mrp" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                </td>
                                <td>
                                    <input autoComplete='off' disabled={dsbleFrstTblRow ? "disabled" : ""} type="number" onChange={getItemVarFrmTblCata} name="c_purchaseprice" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                </td>
                                <td>
                                    <input autoComplete='off' disabled={dsbleFrstTblRow ? "disabled" : ""} type="number" onChange={getItemVarFrmTblCata} name="c_sellingprice" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                </td>
                                <td>
                                    <input autoComplete='off' disabled value={discForTbl && discForTbl} type="text" onChange={getItemVarFrmTblCata} name="c_discount" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                </td>
                                <td>
                                    <input autoComplete='off' disabled={dsbleFrstTblRow ? "disabled" : ""} type="text" onChange={getItemVarFrmTblCata} name="c_color" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                </td>
                                <td>
                                    <input autoComplete='off' disabled={dsbleFrstTblRow ? "disabled" : ""} type="text" onChange={getItemVarFrmTblCata} name="c_model" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                </td>
                                <td>
                                    <input autoComplete='off' disabled={dsbleFrstTblRow ? "disabled" : ""} type="text" onChange={getItemVarFrmTblCata} name="c_addReference" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                </td>
                                {showQtyColumn ?
                                    <td>
                                        <input autoComplete='off' disabled={dsbleFrstTblRow ? "disabled" : ""} type="number" value={itemVarFrmTblCata && itemVarFrmTblCata.c_qty != null ? itemVarFrmTblCata.c_qty : 1} onChange={getItemVarFrmTblCata} name="c_qty" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                    </td>
                                : null }
                                {localStorage.getItem("inventory") != 1 ? null : 
                                    <td>
                                        <input autoComplete='off' disabled={dsbleFrstTblRow ? "disabled" : ""} type="number" onChange={getItemVarFrmTblCata} name="c_stock" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                    </td>
                                }
                                <td>
                                    <TextField autoComplete='off' inputProps={{ min: maxDate }} disabled={dsbleFrstTblRow ? "disabled" : ""} onChange={getItemVarFrmTblCata} name="c_expiryDate" className="cal3" id="date" type="date" InputLabelProps={{ shrink: true,}} />
                                </td>
                                <td>
                                    <input autoComplete='off' disabled={dsbleFrstTblRow ? "disabled" : ""} type="text" onChange={getItemVarFrmTblCata} name="c_skuCode" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                </td>
                            </tr>
                            { inputItemVarient.map((item, index) => {
                                return(
                                    <tr>
                                        <th>{index+2}.</th>
                                        <td>
                                            <input disabled key={index} value={item.itemSize_catalouge} type="text" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} class="inputText inputData" />
                                        </td>
                                        <td>
                                            <input disabled key={index} value={item.itemMrp_catalouge} type="text" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} class="inputText inputData" />
                                        </td>
                                        <td>
                                            <input disabled key={index} value={item.itemPurchase_catalouge} type="text" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} class="inputText inputData" />
                                        </td>
                                        <td>
                                            <input disabled key={index} value={item.itemSelling_catalouge} type="text" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} class="inputText inputData" />
                                        </td>   
                                        <td>
                                            <input disabled key={index} value={discForTbl && discForTbl} type="text" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} class="inputText inputData" />
                                        </td>                               
                                        <td>
                                            <input disabled key={index} value={item.itemColor_catalouge} type="text" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} class="inputText inputData" />
                                        </td>
                                        <td>
                                            <input disabled key={index} value={item.itemModel_catalouge} type="text" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} class="inputText inputData" />
                                        </td>
                                        <td>
                                            <input disabled key={index} value={item.itemRefValue_catalouge} type="text" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} class="inputText inputData" />
                                        </td>
                                        {showQtyColumn ? 
                                            <td>
                                                <input disabled key={index} value={item.itemQty_catalouge != null ? item.itemQty_catalouge : 1} type="text" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} class="inputText inputData" />
                                            </td>
                                        : null }
                                        {localStorage.getItem("inventory") != 1 ? null : 
                                            <td>
                                                <input disabled key={index} value={item.itemStock_catalouge} type="text" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} class="inputText inputData" />
                                            </td>
                                        }
                                        <td>
                                            <input disabled key={index} value={item.itemQty_expiryDate} type="text" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} class="inputText inputData" />
                                        </td>
                                        <td>
                                            <input disabled key={index} value={item.sku_code} type="text" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} class="inputText inputData" />
                                        </td>
                                    </tr>
                                )
                                })
                            }
                        </tbody>                
                    </table>
                    {showAddItmVarBtn ?
                        <button onClick={() => {
                            if(getItemIdCatalouge && getItemIdCatalouge != null){
                            } else {
                                forMainPageFunc()
                            }
                        }} type="button" data-bs-toggle={showModal ? "modal" : ""} data-bs-target="#exampleModal" style={{border: "none", background: "none", padding: "8px 10px",}}>
                            <span style={{border: "none", borderRadius: "8px", padding: "6px 9px", fontSize: "14px", fontWeight: "500", color: "#fff", background:"#008c53"}}>
                                Add Varient
                            </span>
                        </button>
                    : null }
                </div>
                {showDescriptionColumn ? 
                    <div style={{background:"rgb(234 234 234)", padding:"16px", width:"fit-content", borderRadius:"10px", marginTop:"20px"}}>
                        <div style={{width:"100%", display:"flex", justifyContent:"space-between", cursor:"pointer"}}>
                            <p style={{margin:"0"}}>Add Image & Description</p>
                        </div>
                        <div>
                            <Button style={{background:"#fff", width:"80px", height:"80px", borderRadius:"10px", padding:"0", marginRight:"10px", boxShadow:"none"}} variant="contained" component="label">
                                {itemImg1 && itemImg1 != null ? 
                                    <img src={URL.createObjectURL(itemImg1)} style={{width:"100%", height:"100%", borderRadius:"10px",}}/>
                                :
                                    <img src={require("../assets/images/Add Image Icon.png")} />
                                }
                                <input type="file" hidden accept="image/*" onChange={(e) => {setItemImg1(e.target.files[0])}} />
                            </Button>
                            <Button style={{background:"#fff", width:"80px", height:"80px", borderRadius:"10px", padding:"0", marginRight:"10px", boxShadow:"none"}} variant="contained" component="label">
                                {itemImg2 && itemImg2 != null ? 
                                    <img src={URL.createObjectURL(itemImg2)} style={{width:"100%", height:"100%", borderRadius:"10px",}}/>
                                :
                                    <img src={require("../assets/images/Add Image Icon.png")} />
                                }
                                <input disabled={itemImg1 && itemImg1 != null ? "" : "disable"} type="file" hidden accept="image/*" onChange={(e) => {setItemImg2(e.target.files[0])}} />
                            </Button>
                            <Button style={{background:"#fff", width:"80px", height:"80px", borderRadius:"10px", padding:"0", marginRight:"10px", boxShadow:"none"}} variant="contained" component="label">
                                {itemImg3 && itemImg3 != null ? 
                                    <img src={URL.createObjectURL(itemImg3)} style={{width:"100%", height:"100%", borderRadius:"10px",}}/>
                                :
                                    <img src={require("../assets/images/Add Image Icon.png")} />
                                }
                                <input disabled={itemImg2 && itemImg2 != null ? "" : "disable"} type="file" hidden accept="image/*" onChange={(e) => {setItemImg3(e.target.files[0])}} />
                            </Button>
                            <Button style={{background:"#fff", width:"80px", height:"80px", borderRadius:"10px", padding:"0", marginRight:"10px", boxShadow:"none"}} variant="contained" component="label">
                                {itemImg4 && itemImg4 != null ? 
                                    <img src={URL.createObjectURL(itemImg4)} style={{width:"100%", height:"100%", borderRadius:"10px",}}/>
                                :
                                    <img src={require("../assets/images/Add Image Icon.png")} />
                                }
                                <input disabled={itemImg3 && itemImg3 != null ? "" : "disable"} type="file" hidden accept="image/*" onChange={(e) => {setItemImg4(e.target.files[0])}} />
                            </Button>
                        </div>
                        <div style={{marginTop:"20px", borderRadius:"10px", background:"#fff", padding:"10px", width:"100%"}}>
                            <p style={{color:"#7a7a7a", margin:"0"}}>Add Description</p>
                            <textarea id='getHelpTextArea' style={textAreaStyle} onChange={(e) => {setDiscription(e.target.value)}} />
                        </div>
                    </div>
                : null }

                <button onClick={() => {
                    if(getItemIdCatalouge && getItemIdCatalouge != null) {
                        navigate("/Catalogue")
                    } else {
                        setforNavigate(true)
                        forMainPageFunc()
                    }}}  style={{textAlign:"center", marginTop:"10px", marginLeft:"89%", border:"none", borderRadius:"20px", background:"#008c53", color:"#fff", fontSize:"20px", fontWeight:"600", padding:"5px 30px"}}>
                    Done
                </button>                
            </div>

            <div class="modal fade" id="UnitModal" tabindex="-1" aria-labelledby="UnitModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{borderRadius:"1.3rem"}}>
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Unit</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div style={{display:"grid", gridTemplateColumns:"auto auto auto auto auto", gap:"10px", maxHeight:"30vh", overflowY:"scroll", padding:"10px"}}>
                                {getUnitData && getUnitData.map((value, index) => {
                                    return(
                                        <button data-bs-dismiss="modal" onClick={() => {setGetSelectedUnit(value.miu_id); setSelectedUnit(value.name)}} style={{border:"none", background:"#008c53", color:"#fff", borderRadius:"10px"}}>{value.name}</button>
                                    )
                                })}
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                {showAddMoreUnitBtn ? 
                                    <button onClick={() => setShowAddMoreUnitBtn(false)} style={{background:"none", width:"90px", fontWeight:"600", border:"1.5px solid #008c53", color:"#008c53", borderRadius:"10px"}}>Add More</button>
                                :
                                    <>
                                        <input onChange={(e) => {setNewUnit(e.target.value)}} autocomplete="off" type="text" class="inputText" />
                                        <button onClick={() => {unitPostFunc();}} style={{border:"none", fontWeight:"600", background:"#008c53", color:"#fff", borderRadius:"10px", marginLeft:"10px", padding:"10px 15px"}}>Save</button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="GstModal" tabindex="-1" aria-labelledby="GstModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{borderRadius:"1.3rem"}}>
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">GST</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div style={{display:"grid", gridTemplateColumns:"auto auto auto auto auto", gap:"10px", maxHeight:"30vh", overflowY:"scroll", padding:"10px"}}>
                                {getGSTData && getGSTData.map((value, index) => {
                                    return(
                                        <button data-bs-dismiss="modal" onClick={() => {setGetSelectedGst(value.gst_per); setSelectedGst(value.gst_per)}} style={{border:"none", background:"#008c53", color:"#fff", borderRadius:"10px"}}>{value.gst_per}</button>
                                    )
                                })}
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                {showAddMoreGstBtn ? 
                                    <button onClick={() => setShowAddMoreGstBtn(false)} style={{background:"none", width:"90px", fontWeight:"600", border:"1.5px solid #008c53", color:"#008c53", borderRadius:"10px"}}>Add More</button>
                                :
                                    <>
                                        <input onChange={(e) => {setNewGst(e.target.value)}} autocomplete="off" type="text" class="inputText" />
                                        <button onClick={() => {gstPostFunc();}} style={{border:"none", fontWeight:"600", background:"#008c53", color:"#fff", borderRadius:"10px", marginLeft:"10px", padding:"10px 15px"}}>Save</button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style={{borderRadius:"1.3rem"}}>
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Item Varient</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p>Item Name: {inputItemDetail && inputItemDetail.itemName_Catalouge}</p>
                            <form id='empty'>
                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                    <div class="user-input-wrp" style={{width:"25%"}}>
                                        <br />
                                        <input autocomplete="off" type="text" onChange={getItemDetailPost} name="itemSize_catalouge" class="inputText" required/>
                                        <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Size</p>
                                    </div>
                                    <div class="user-input-wrp" style={{width:"25%"}}>
                                        <br />
                                        <input autocomplete="off" type="number" name="itemMrp_catalouge" onChange={getItemDetailPost} class="inputText" required/>
                                        <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>MRP (in ₹)*</p>
                                    </div>                   
                                    <div class="user-input-wrp" style={{width:"25%"}}>
                                        <br />
                                        <input autocomplete="off" type="number" name="itemPurchase_catalouge" onChange={getItemDetailPost} class="inputText" required/>
                                        <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Purchase Price</p>
                                    </div>
                                </div>
                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                    <div class="user-input-wrp" style={{width:"25%"}}>
                                        <br />
                                        <input autocomplete="off" type="number" name="itemSelling_catalouge" onChange={getItemDetailPost} class="inputText" required/>
                                        <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Selling Price</p>
                                    </div>
                                    <div class="user-input-wrp" style={{width:"25%"}}>
                                        <br />
                                        <input autocomplete="off" type="text" name="itemColor_catalouge" onChange={getItemDetailPost} class="inputText" required/>
                                        <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Color</p>
                                    </div>
                                    <div class="user-input-wrp" style={{width:"25%"}}>
                                        <br />
                                        <input autocomplete="off" type="text" name="itemModel_catalouge" onChange={getItemDetailPost} class="inputText" required/>
                                        <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Model</p>
                                    </div>
                                </div> 
                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                    <div class="user-input-wrp" style={{width:"25%"}}>
                                        <br />
                                        <input autocomplete="off" type="text" name="itemRefKey_catalouge" onChange={getItemDetailPost} class="inputText" required/>
                                        <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Reference Key</p>
                                    </div>
                                    <div class="user-input-wrp" style={{width:"25%"}}>
                                        <br />
                                        <input autocomplete="off" type="text" name="itemRefValue_catalouge" onChange={getItemDetailPost} class="inputText" required/>
                                        <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Reference Value</p>
                                    </div>
                                    <div class="user-input-wrp" style={{width:"25%", display:localStorage.getItem("inventory") != 1 ? "none" : "block"}}>
                                        <br />
                                        <input autocomplete="off" type="number" name="itemStock_catalouge" onChange={getItemDetailPost} class="inputText" required/>
                                        <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Stock</p>
                                    </div>
                                </div> 
                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                    {showQtyColumn ? 
                                        <div class="user-input-wrp" style={{width:"25%"}}>
                                            <br />
                                            <input autocomplete="off" type="number" name="itemQty_catalouge" onChange={getItemDetailPost} class="inputText" required/>
                                            <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Qty</p>
                                        </div>
                                    : null }
                                    <div class="user-input-wrp" style={{width:"25%"}}>
                                        <br />
                                        <TextField autoComplete='off' inputProps={{ min: maxDate }} onChange={getItemDetailPost} name="itemQty_expiryDate" className="cal3" id="date" type="date" InputLabelProps={{ shrink: true,}} />
                                        <p style={{ position: "absolute", bottom: "30%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Expiry Date</p>
                                    </div>
                                    <div class="user-input-wrp" style={{width:"25%"}}>
                                        <br />
                                        <input autocomplete="off" type="number" name="sku_code" onChange={getItemDetailPost} class="inputText" required/>
                                        <p style={{ position: "absolute", bottom: "30%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>SKU Code</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" onClick={() => {
                                        if(inputItemDetail.itemMrp_catalouge != null && inputItemDetail.itemMrp_catalouge != ""){
                                            if(parseFloat(inputItemDetail.itemSelling_catalouge > parseFloat(inputItemDetail.itemMrp_catalouge))) {
                                                alert("Item Selling Price is not more than MRP")
                                            } else {
                                                checkItemVarientCatalouge()
                                            }                                                    
                                        } else {
                                            alert("Item MRP is required")
                                        }
                                    }} style={{border: "none", borderRadius: "5px", padding: "6px 20px", fontSize: "14px", fontWeight: "500", color: "#fff", background:"#008C53"}} data-bs-dismiss="modal">
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CatalougeViewBill
