import React, { useEffect, useState } from 'react'
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Pagination } from "@mui/material";
import "./PurchaseNav.css"
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PurchaseCard = ({skeletonLoading, showPurchaseList, setGetVoucherId, setshowPurchaseDetail, purchaseFilterValue, setPurchaseFilterValue, setPurchaseFilter}) => {    

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    // const navigate = useNavigate()

    const [getPurchaseList, setGetPurchaseList] = useState([])
    const [navbarOpen, setNavbarOpen] = useState(false)
    // const [paidData, setPaidData] = useState(true)
    // const [unpaidData, setUnpaidData] = useState(true)

    const [minIndex, setMinIndex] = useState(0)
    const [maxIndex, setMaxIndex] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)

    const [countPage, setCountPage] = useState(1)
    // const [countPagePaid, setCountPagePaid] = useState(1)
    // const [countPageUnpaid, setCountPageUnpaid] = useState(1)  
    const [countPageAllPurchase, setCountPageAllPurchase] = useState(1)     
    const [active, setActive] = useState(null)

    const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
    }

    const handlePagination = (pageNumber) => {
        setMinIndex((pageNumber - 1) * 10)
        setMaxIndex(pageNumber * 10)
        setCurrentPage(pageNumber)
    }

    useEffect(() => {
        setGetPurchaseList(showPurchaseList)
    }, [showPurchaseList])

    // useEffect(() => {
    //     const getPurchaseListUrl = {
    //         method: "GET",
    //         url: Helper.getWsUrl() + `/vouchers/get_purchase_web/?web=1`,
    //         headers: { "Content-Type": "application/json", 'token': cookies.token },
    //     };
    //     axios(getPurchaseListUrl)
    //     .then((response) => {  
    //         // setCookie("token", response.headers.token);            
    //         if (response.data.code === 200) {
    //             Helper.consoleLogger("get purchase list : ", Helper.encypt_decrypt(response.data.data))
    //             setGetPurchaseList(Helper.encypt_decrypt(response.data.data))
    //         }
    //         else if (response.data.code === 401) {
    //             removeCookie("logged_in")
    //             removeCookie("token")
    //             setTimeout(() => {
    //                 navigate('/')
    //                 localStorage.setItem("isAuthenticated", false)
    //                 localStorage.removeItem("isAuthenticated")
    //             }, 1000)
    //         }
    //     })
    //     .catch((error) => {
    //         alert(error);
    //     })
    // },[])

    useEffect(() => {
        let countPurchase = 0
        // let countPaid = 0
        // let countUnpaid = 0
        if(getPurchaseList && getPurchaseList.length > 0) {
            getPurchaseList.map((value,index) =>{
                // if(value.paid_status === 1) {
                //     countPaid += 1
                // } else if (value.paid_status === 0) {
                //     countUnpaid += 1
                // }
                countPurchase += 1
            })
            setCountPageAllPurchase(countPurchase)
            // setCountPagePaid(countPaid)
            // setCountPageUnpaid(countUnpaid)
        }
    },[getPurchaseList])

    useEffect(() => {
        // if(paidData && unpaidData) {
            setCountPage(Math.ceil(countPageAllPurchase / 10))
        // } 
    })

    // const showPaidDataFunc = () => {
    //     setCountPage(Math.ceil(countPagePaid / 10))
    //     setUnpaidData(false)
    //     setPaidData(true)
    // }
    // const showUnpaidDataFunc = () => {
    //     setCountPage(Math.ceil(countPageUnpaid / 10))
    //     setPaidData(false)
    //     setUnpaidData(true)
    // }

    // const showAllDataFunc = () => {
    //     setUnpaidData(true)
    //     setPaidData(true)
    // }

    const showPaidCard = () => {
        return (
            <>
                { getPurchaseList && getPurchaseList.map((value,index) => {
                    // if(value.paid_status === 1) {                                  
                        return(
                            index >= minIndex && index < maxIndex && (
                                <div onClick={() => {setGetVoucherId(value.voucher_id)}} style={{display:"flex", marginBottom:"10px"}}>
                                    <div id='card'  className={`cardborder  ${active == index && 'active'}`} key={index}  onClick={() => {setshowPurchaseDetail(true);setActive(index)}} style={{cursor:"pointer", borderRadius:"10px 10px 10px 10px", overflow:"hidden", padding: "16px 10px 16px 20px", display: 'flex',boxSizing:"content-box", justifyContent:"space-between", width: "90%", boxShadow:"0px 2px 4px 0px rgba(0,0,0,0.1)"}}>
                                        <div id='card-left'>
                                            <p className='child' style={{fontweight:"600", marginBottom:"5px", fontSize:"18px",fontWeight:"600" }}>{value.phone_number}</p>
                                            <p className='orderchild' style={{fontweight:"500", marginBottom:"5px", fontSize:"14px",fontWeight:"500"}}>{value.date}</p>
                                        </div>
                                        <div id='card-right' style={{textAlign:"right", display:"flex", justifyContent:"end", width:"40%"}}>
                                            <div>
                                                <p className='child' style={{fontweight:"600", marginBottom:"5px", fontSize:"18px",fontWeight:"500"}}>₹ {value.total_invoice}</p>
                                            </div>
                                            {active === index ?
                                                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <img src={require("../assets/images/white vector.png")} style={{ width:"85%", height:"20%"}}/>
                                                </div> 
                                            : 
                                                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <img src={require("../assets/images/vector.png")} style={{ width:"85%", height:"20%"}}/>
                                                </div>  
                                            }                                                               
                                        </div>                                        
                                    </div>
                                    {value.paid_status === 1 ? 
                                        <div style={{backgroundColor:"#309f73", width:"6%",display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"5px", borderTopRightRadius:"10px", borderBottomRightRadius:"10px",marginTop:"2%",marginBottom:"2%"}}>
                                            <p style={{writingMode:"vertical-rl",margin:"0",padding:"0", color:"#fff", fontSize:"14px", fontWeight:"700"}}>Paid</p>
                                        </div>
                                    :
                                        <div style={{backgroundColor:"#DC463D", width:"6%",display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"5px", borderTopRightRadius:"10px", borderBottomRightRadius:"10px",marginTop:"2%",marginBottom:"2%"}}>
                                            <p style={{writingMode:"vertical-rl", margin:"0",padding:"0", color:"#fff", fontSize:"14px", fontWeight:"700"}}>Unpaid</p>
                                        </div>
                                    }
                                </div>
                            )
                        )
                    // }
                })}
            </>
        )
    }

    // const showUnpaidCard = () => {
    //     return (
    //         <>
    //             { getPurchaseList && getPurchaseList.map((value,index) => {
    //                 if(value.paid_status === 0) {            
    //                     return(
    //                         index >= minIndex && index < maxIndex && (
    //                             <div onClick={() => {setShowUnpaidPurchaseDetail(false); setGetVoucherId(value.voucher_id);setActive(index)}} style={{display:"flex", marginBottom:"10px"}}>
    //                                 <div id='card' className={`cardborder  ${active == index && 'active'}`} key={index} onClick={() => setshowPurchaseDetail(true)} style={{cursor:"pointer", borderRadius:"10px 0 0 10px", overflow:"hidden", padding: "16px 10px 16px 20px", display: 'flex',boxSizing:"content-box", justifyContent:"space-between", width: "90%", boxShadow:"0px 2px 4px 0px rgba(0,0,0,0.1)"}}>
    //                                     <div id='card-left'>
    //                                         <p className='child' style={{fontweight:"600", marginBottom:"5px", fontSize:"18px"}}>{value.phone_number}</p>
    //                                         <p className='orderchild' style={{fontweight:"500", marginBottom:"5px", fontSize:"14px"}}>{value.date}</p>
    //                                     </div>
    //                                     <div id='card-right' style={{textAlign:"right", display:"flex", justifyContent:"end", width:"40%"}}>
    //                                         <div>
    //                                             <p className='child' style={{fontweight:"600", marginBottom:"5px", fontSize:"18px"}}>₹ {value.amount}</p>
    //                                         </div>
    //                                         <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
    //                                             <img src={require("../assets/images/vector.png")} style={{ width:"85%", height:"20%"}}/>
    //                                         </div>                    
    //                                     </div>
                                        
    //                                 </div>
    //                                 <div style={{backgroundColor:"#DC463D", width:"8%",display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"5px", borderTopRightRadius:"10px", borderBottomRightRadius:"10px"}}>
    //                                     <p style={{writingMode:"vertical-rl", color:"#fff", fontSize:"14px", fontWeight:"700"}}>Unpaid</p>
    //                                 </div>
    //                             </div>
    //                         )
    //                     )
    //                 }
    //             })}
    //         </>
    //     )        
    // }

    const activeBtn = {
        background:"#008c53",
        border:"none",
        color:"#fff"
    }

    return (
        <>
            <nav className="navBar" style={{height: !navbarOpen ? "0": "40px"}}>
                <button onClick={handleToggle}>
                    {navbarOpen ? 
                        (<img onClick={() => {setPurchaseFilter(false); setCurrentPage(1); setPurchaseFilterValue(null)}} src = {require("../assets/images/cancel.png")}/>) 
                    :
                        (<img src = {require("../assets/images/Filter.png")}/>)}
                </button>
                <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
                    <li style={{cursor:"pointer"}} onClick={() => {setPurchaseFilter(true); setCurrentPage(1); setPurchaseFilterValue('paid'); handlePagination(1)}}><a style={purchaseFilterValue === 'paid' ? activeBtn : null}>Paid</a></li>
                    <li style={{cursor:"pointer"}} onClick={() => {setPurchaseFilter(true); setCurrentPage(1); setPurchaseFilterValue('unpaid'); handlePagination(1)}}><a style={purchaseFilterValue === 'unpaid' ? activeBtn : null}>Unpaid</a></li>
                </ul>
            </nav>
            {skeletonLoading ? 
                <div style={{height:"55vh", overflowY:"scroll"}}>
                    <Skeleton count={6} borderRadius={"10px"} height={"10vh"} /> 
                </div>
            :
                <>
                    {getPurchaseList && getPurchaseList.length > 0 ?
                        <div style={{overflowY:"scroll", overflowX:"hidden", height:"59vh"}}>
                            {showPaidCard ()}      
                        </div>
                    :
                        <div style={{height:"55vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <p style={{color:"#7a7a7a", fontWeight:"600", fontSize:"18px"}}>no data found</p>
                        </div>
                    }  
                </>
            }
            <div className="pageCount" style={{marginTop:"15px"}}>
                <Pagination style={{display:"flex",justifyContent:"center",alignItems:"center"}} count={countPage} defaultPage={1} page={currentPage} onChange={(event, pageNumber) => handlePagination(pageNumber)}/>
            </div>       
        </>
    )
}

export default PurchaseCard