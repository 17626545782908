import "./sidebar.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {

  const navigate = useNavigate();
  const location = useLocation().pathname;
  const [sideActive, setSideActive] = useState(0);

  const sideRectangle = {
    border: "1px solid green",
    backgroundColor: "green",
    width: "6px",
    height: "28px",
    position: "absolute",
    top: "8%",
    left:"-15px",
    borderRadius:"0 5px 5px 0"
  }

  useEffect(() => {
    if(location == '/dashboard') {
      setSideActive(0)
    } else if(location == '/Catalogue' || location == '/Catalogue/add_new_item') {
      setSideActive(1)
    } else if(location == '/Billing') {
      setSideActive(2)
    } else if(location == '/Order') {
      setSideActive(3)
    } else if(location == '/Purchase') {
      setSideActive(4)
    } else if(location == '/Grahak') {
      setSideActive(5)
    } else if(location == '/Galla') {
      setSideActive(6)
    } else if(location == '/Udhaar') {
      setSideActive(7)
    } else if(location == '/Team') {
      setSideActive(8)
    } else if(location == '/Proformainvoice') {
      setSideActive(10)
    } else if(location == '/Inventory') {
      setSideActive(11)
    } else {
      setSideActive(0)
    }
  },[location])

  return (
    <div className="col-2" id="side" style={{ backgroundColor: "white" }}>

      <div onClick={() => { navigate("/dashboard"); }} className="item" id="first" style={{position:"relative", cursor:"pointer"}}>
        <div style={sideActive === 0 ? sideRectangle : null}></div>
        <span className="img">
          <img
            src={require("../assets/images/dashboard.png")}
            style={{ height: "35px", width: "35px" }}
          ></img>
        </span>
        
          <a className="sidebarlinks">
            Dashboard
          </a>
      </div>
      <div onClick={() =>{ navigate("/Catalogue");}} className="item" style={{position:"relative", cursor:"pointer"}}>
        <div style={sideActive === 1 ? sideRectangle : null}></div>
          <span className="img">
            <img
              src={require("../assets/images/catalauge.png")}
              style={{ height: "35px", width: "35px" }}
            ></img>
          </span>
            <a className="sidebarlinks">
              Catalogue
            </a>
      </div>
      <div onClick={() => {navigate("/Billing");}} className="item" style={{position:"relative", cursor:"pointer"}}>
        <div style={sideActive === 2 ? sideRectangle : null}></div>
          <span className="img">
            <img
              src={require("../assets/images/billing.png")}
              style={{ height: "35px", width: "35px" }}
            ></img>
          </span>
            <a className="sidebarlinks">
              Billing
            </a>
      </div>
      <div onClick={() => {navigate("/Order");}} className="item" style={{position:"relative", cursor:"pointer"}}>
        <div style={sideActive === 3 ? sideRectangle : null}></div>
        <span className="img">
          <img
            src={require("../assets/images/order.png")}
            style={{ height: "35px", width: "35px" }}
          ></img>
        </span>
          <a className="sidebarlinks">
            Order
          </a>
      </div>
      <div onClick={() => {navigate("/Purchase");}} className="item" style={{position:"relative", cursor:"pointer", opacity: localStorage.getItem("inventory") != 1 ? "0.4" : "1"}}>
        <div style={sideActive === 4 ? sideRectangle : null}></div>
        <span className="img">
          <img
            src={require("../assets/images/purchase.png")}
            style={{ height: "35px", width: "35px" }}
          ></img>
        </span>
          <a className="sidebarlinks">
            Purchase
          </a>
      </div>
      <div onClick={() => {navigate("/Grahak");}} className="item" style={{position:"relative", cursor:"pointer"}}>
        <div style={sideActive === 5 ? sideRectangle : null}></div>
        <span className="img">
          <img
            src={require("../assets/images/grahakh.png")}
            style={{ height: "35px", width: "35px" }}
          ></img>
        </span>
          <a className="sidebarlinks">
            Grahak
          </a>
      </div>
      <div onClick={() => {navigate("/Galla");}} className="item" style={{position:"relative", cursor:"pointer"}}>
        <div style={sideActive === 6 ? sideRectangle : null}></div>
        <span className="img">
          <img
            src={require("../assets/images/galla.png")}
            style={{ height: "35px", width: "35px" }}
          ></img>
        </span>
          <a className="sidebarlinks">
            Galla
          </a>
      </div>
      <div onClick={() => {navigate("/Udhaar");}} className="item" style={{position:"relative", cursor:'pointer', opacity: localStorage.getItem("udhaar") != 1 ? "0.4" : "1"}}>
        <div style={sideActive === 7 ? sideRectangle : null}></div>
        <span className="img">
          <img
            src={require("../assets/images/udhaar.png")}
            style={{ height: "35px", width: "35px" }}
          ></img>
        </span>
          <a className="sidebarlinks">
            Udhaar
          </a>
      </div>
      <div onClick={() => {navigate("/Team");}} className="item" style={{position:"relative", cursor:"pointer"}}>
        <div style={sideActive === 8 ? sideRectangle : null}></div>
        <span className="img">
          <img
            src={require("../assets/images/team.png")}
            style={{ height: "35px", width: "35px" }}
          ></img>
        </span>
          <a className="sidebarlinks">
            Team
          </a>
      </div>
      {/* <div className="item" style={{position:"relative"}}>
      <div style={sideActive === 9 ? sideRectangle : null}></div>
        <span className="img">
          <img
            src={require("../assets/images/finance.png")}
            style={{ height: "35px", width: "35px" }}
          ></img>
        </span>
        <span>
          <a
            style={{ cursor: "pointer" }}
            href=""
            data-bs-toggle="modal"
            data-bs-target="#exampleModalCS"
          >
            Finance
          </a>
        </span>
      </div> */}
      <div onClick={() => {navigate("/Proformainvoice");}} className="item" style={{position:"relative", cursor:"pointer", display:"flex"}}>
        <div style={sideActive === 10 ? sideRectangle : null}></div>
        <span className="img">
          <img
            src={require("../assets/images/proformainvoicesb.png")}
            style={{ height: "35px", width: "35px" }}
          ></img>
        </span>
        <div>
          <a className="sidebarlinks">
            Proforma Invoice
          </a>
        </div>
      </div>
      <div onClick={() => {navigate("/Inventory");}} className="item" style={{display: localStorage.getItem("inventory") != 1 ? "none" : "block", position:"relative", cursor:"pointer"}}>
        <div style={sideActive === 11 ? sideRectangle : null}></div>
        <span className="img">
          <img
            src={require("../assets/images/inventorysb.png")}
            style={{ height: "35px", width: "35px" }}
          ></img>
        </span>
          <a className="sidebarlinks">
            Inventory
          </a>
      </div>
    </div>
  );
};

export default Sidebar;
