import { React, useState, useEffect } from "react";
import axios from "axios";
import Helper from "../util/Helper";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip, AreaChart ,Area} from 'recharts';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";


export const Main = (props) => {

  const navigate = useNavigate()

  const [cookies, setCookie,removeCookie] = useCookies(["token"]);
  const [stats,setStats]=useState([]);

  useEffect(() => {
    const requestOptions = {
        method: "GET",
        url: Helper.getWsUrl() + `/contact/gmv_for_web/?web=1`,
        headers: { "Content-Type": "application/json", "token": cookies.token },
    };
    axios(requestOptions)
      .then((response) => {
          setCookie("token", response.headers.token);
          if (response.data.code === 200) {
            setStats(Helper.encypt_decrypt(response.data.data))
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          } 
      })
      .catch((err) => {
          console.log(err)
      })
  }, [])

  return (

    <div className="col-10" id="main" style={{ backgroundColor: "rgb(245, 245, 245)", padding: "20px", height:"100%" }}>
      <div className="row">
        <div className="col">
          <p className=" mt-3 ms-3" style={{fontWeight:"600",fontSize:"24px"}}>Overview</p>
        </div>
      </div>
      <div className="row" style={{ }}>
        <span className="  card"
          style={{
            backgroundColor: "white",
            marginLeft: "15px",
            width: "23%",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "row",
            marginLeft:"2%"
          }} id="firstcard">
          <div style={{ marginTop: "2%",marginBottom:"-7px", marginLeft: "5%" ,display:"flex",flexDirection:"column",justifyContent:"space-between" }}>
            <div>
              <img src={require("../assets/images/revenue.png")} style={{ marginTop: "10px" }} class="img-fluid" />
            </div>
            <p style={{ fontWeight: "500", fontSize: "14px", marginTop: "10px" ,marginBottom:"0px"}}>Total Revenue</p>
            <p style={{ color: "#008C53", fontWeight: "600", fontSize: "24px" }}>  ₹{parseFloat(props.gmv.total_bill_amount).toFixed(2)}</p>
          </div>
        </span>
        <span className="  card"
          style={{
            backgroundColor: "white",
            marginLeft: "15px",
            width: "23%",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "row",
            opacity: localStorage.getItem("udhaar") != 1 ? "0.4" : "1"
          }} id="firstcard">
          <div style={{ marginTop: "2%",marginBottom:"-7px", marginLeft: "5%" ,display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
            <div>
               <img src={require("../assets/images/2.png")} style={{ marginTop: "10px" }} class="img-fluid" />
            </div>
            <p style={{ fontWeight: "500", fontSize: "14px", marginTop: "10px",marginBottom:"0px" }}>Total Udhaar</p>
            <p style={{ color: "#BA1C09", fontWeight: "600", fontSize: "24px" }}>  ₹{parseFloat(props.gmv.amount_in_udhaar).toFixed(2)}</p>
          </div>
        </span>
        <span className="  card"
          style={{
            backgroundColor: "white",
            marginLeft: "15px",
            width: "23%",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "row",
          }} id="firstcard">
          <div style={{ marginTop: "2%",marginBottom:"-7px", marginLeft: "5%",display:"flex",flexDirection:"column",justifyContent:"space-between" }}>
            <div>
               <img src={require("../assets/images/3-1.png")} style={{ marginTop: "10px" }} class="img-fluid" />
            </div>
            <p style={{ fontWeight: "500", fontSize: "14px", marginTop: "10px",marginBottom:"0px" }}>Total Grahak</p>
            <p style={{ color: "#6381A8", fontWeight: "600", fontSize: "24px" }}>  {props.totalCustomer}</p>
          </div>
        </span>
        <span className="  card"
          style={{
            backgroundColor: "white",
            marginLeft: "15px",
            width: "23%",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "row",
          }} id="firstcard">
          <div style={{ marginTop: "2%",marginBottom:"-7px", marginLeft: "5%" ,display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
            <div>
                 <img src={require("../assets/images/bills.png")} style={{ marginTop: "10%" }} class="img-fluid" />
            </div>
            <p style={{ fontWeight: "500", fontSize: "14px", marginTop: "10%",marginBottom:"0px" }}>Total Bills</p>
            <p style={{ color: "#FFAB65", fontWeight: "600", fontSize: "24px" }}>  {props.gmv.total_bills}</p>
          </div>
        </span>
      </div>
      <div className="row" id="stats" style={{ marginTop:"5px"}}>
        <div className=" mt-3 ms-4"
          style={{
            borderRadius: "20px",
            backgroundColor: "white",
            marginLeft: "1.3%",
            width: "95%",
            background: "#FFF",
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.05)",
            padding: "20px",
            height:"47vh"
          }}>
          <p style={{ fontWeight: "600", fontSize: "16px", color: "#2B2B2B" }}>Statistics</p>
          <ResponsiveContainer  width="97%" aspect={4}>
            <AreaChart width={400} height={400}  margin={ { top: 27, right: 9, bottom: 5, left: 9}} data={stats}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#005D29" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#005D29" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#4CBD80" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#4CBD80" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid />
              <XAxis dataKey="date" interval={'preserveStartEnd'} />
              <YAxis />
              <Tooltip />
              <Legend iconType="rect"/>
                <Area type="monotone" dataKey="total_bill_amount" stroke="#6E3819" activeDot={{ r: 8 }} fillOpacity={1} fill="url(#colorUv)" />
                <Area type="monotone" dataKey="total_bill" stroke="#005D29" activeDot={{ r: 8 }} fillOpacity={1} fill="url(#colorUv)" />
                <Area type="monotone" dataKey="sold_item" stroke="#4CBD80" activeDot={{ r: 8 }} fillOpacity={1} fill="url(#colorUv)" />
                <Area type="monotone" dataKey="average_bill_value" stroke="#FA8039" activeDot={{ r: 8 }} fillOpacity={1} fill="url(#colorUv)" />
            </AreaChart>
          </ResponsiveContainer>


        </div>
      </div>
    </div>
  );
};

export default Main;
