import React, { useState, useEffect, useCallback } from "react";
import Helper from "../util/Helper";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const InventoryDetails = ({setInventorydetails, inventoryIvId, inventoryItemId}) => {

    const navigate = useNavigate()

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const [inventoryItemDtl, setInventoryItemDtl] = useState(null)
    const [inventoryItemList, setInventoryItemList] =useState([])

    useEffect(() => {
        if(inventoryItemId && inventoryItemId != null && inventoryIvId && inventoryIvId != null) {
            const getItemDetailInventoryUrl = {
                method: "GET",
                url: Helper.getWsUrl() + `/inventory/get_inventory_list/?iv_id=${inventoryIvId}&item_id=${inventoryItemId}&web=1`,
                headers: { "Content-Type": "application/json", 'token': cookies.token },
            };
            axios(getItemDetailInventoryUrl)
            .then((response) => {  
                // setCookie("token", response.headers.token);            
                if (response.data.code === 200) {
                    setInventoryItemDtl(Helper.encypt_decrypt(response.data.data).item_detail)    
                    setInventoryItemList(Helper.encypt_decrypt(response.data.data).inventory_list)              
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  } 
            })
            .catch((error) => {
                alert(error);
            })
        }
    },[inventoryItemId, inventoryIvId])

    const inventoryUpdateMrp = (event) => {
        let data = Helper.forDecimal(event.target.value)
            setInventoryItemDtl({
                ...inventoryItemDtl,
                item_mrp: data
            })  
        // { value: e.target.value.toString().split(".").map((el,i)=>i?el.split("").slice(0,2).join(""):el).join(".")}   
    }

    const inventoryUpdateQty = (event) => {
        setInventoryItemDtl({
            ...inventoryItemDtl,
            min_qty: event.target.value
        })        
    }

    const inventoryUpdateStock = (event) => {
        setInventoryItemDtl({
            ...inventoryItemDtl,
            stock: event.target.value
        })        
    }

    // console.log(inventoryItemDtl)
    
    const inventoryItemUpdate = () => {
        if(inventoryItemDtl && inventoryItemDtl.item_mrp != null && inventoryItemDtl.item_mrp != ""){
            if(inventoryItemDtl && inventoryItemDtl.min_qty != null && inventoryItemDtl.min_qty != "") {
                if(inventoryItemDtl && inventoryItemDtl.stock != null && inventoryItemDtl.stock != "" && /^\d*$/.test(inventoryItemDtl.stock)) {
                    itemUpdateInventoryApi()
                } else {
                    alert("Item Stock is required")
                }
            } else {
                alert("Item Quantity is required")
            }
        } else {
            alert("Item MRP is required")
        }
    }

    const itemUpdateInventoryApi = () => {
        const getItemUpdateInventoryUrl = {
            method: "PATCH",
            url: Helper.getWsUrl() + `/items/edit_item_verient/${inventoryIvId}/?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
            data:{
                data:{
                    min_qty: parseFloat(inventoryItemDtl && inventoryItemDtl.min_qty).toFixed(2),
                    mrp:parseFloat(inventoryItemDtl && inventoryItemDtl.item_mrp).toFixed(2),
                    stock:parseInt(inventoryItemDtl && inventoryItemDtl.stock)
                }              
            }
        };
        axios(getItemUpdateInventoryUrl)
        .then((response) => {  
            // setCookie("token", response.headers.token);            
            if (response.data.code === 201) {
                // console.log("data : ",response.data)   
                window.location.reload()     
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              }
        })
        .catch((error) => {
            alert(error);
        })
    }

    return (
        <>
            <div className="productDetailCatalouge" style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div id="upper" style={{width:"100%", padding:"0 10px"}}>
                    <div style={{width:"100%", display: "flex", justifyContent: "end"}}>
                        <span onClick={() => setInventorydetails(false)}>
                            <img style={{cursor:"pointer"}} src={require("../assets/images/cancel icon.png")} className="img-fluid "/>
                        </span>
                    </div>  
                </div>
                <div class="user-input-wrp" style={{ width: "100%", marginTop:"20px" }}>
                    <br />
                    <input disabled
                        type="text"
                        class="inputText"
                        value={inventoryItemDtl && inventoryItemDtl.item_name}
                        style={{ width: "100%" }}
                    />
                    <span class="floating-label" style={{top: "12px", left: "17px", fontSize: "16px", opacity: "1", background: "#fff", color: "#008c53", fontWeight: "500"}}>Item Name</span>
                </div>
                <div style={{ display: "flex", width:"100%" }}>
                    <div class="user-input-wrp" style={{ width: "100%", marginRight: "10px" }}>
                        <br />
                        <input
                            type="number"
                            class="inputText"
                            value={inventoryItemDtl && inventoryItemDtl.item_mrp}
                            name="inventory_mrp"
                            onChange={inventoryUpdateMrp}
                            style={{ width: "100%" }}
                        />
                        <span class="floating-label">MRP(₹)</span>
                    </div>
                    <div class="user-input-wrp" style={{ width: "100%"}}>
                        <br />
                        <input
                            type="number"
                            class="inputText"
                            value={inventoryItemDtl && inventoryItemDtl.min_qty}
                            name="inventory_qty"
                            onChange={inventoryUpdateQty}
                            style={{ width: "100%" }}
                        />
                        <span class="floating-label">Min.QTY</span>
                    </div>
                </div>
                <div class="user-input-wrp" style={{ width: "100%" }}>
                    <br />
                    <input
                        type="number"
                        class="inputText"
                        value={inventoryItemDtl && inventoryItemDtl.stock}
                        name="inventory_stock"
                        onChange={inventoryUpdateStock}
                        style={{ width: "100%" }}
                    />
                    <span class="floating-label">Stock</span>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", width:"100%" }}>
                    <button onClick={() => inventoryItemUpdate()} style={{ backgroundColor: "#008C53", color: "white", fontWeight:"600", borderRadius: "25px", border: "1px solid #008C53 ", padding: "5px 25px",fontSize:"20px" }} > 
                        Update 
                    </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems:"center", marginTop:"20px" }}>
                    <div style={{ borderTop: "1px solid #E0E0E0", width: "300px", display: "flex", justifyContent: "center" }}>
                        <p style={{ color: "#898A8D", fontWeight: "500", fontSize: "16px" }}>Stock Movement</p>
                    </div>
                </div>
                <div style={{marginTop:"15px", width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <table class="table" style={{color:"#BDBDBD", width:"100%"}}>
                        <thead style={{color:"black"}}>
                            <tr>
                                <th scope="col" style={{width:"20%", textAlign:"center",fontSize:"16px",fontWeight:"700"}} >Date</th>
                                <th scope="col" style={{color:"#008C53", width:"10%", textAlign:"center",fontSize:"16px",fontWeight:"700"}}>In</th>
                                <th scope="col" style={{color:"#FF1400", width:"10%", textAlign:"center",fontSize:"16px",fontWeight:"700"}}>Out</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            {inventoryItemList && inventoryItemList.map((value, index) => {
                                return(
                                    <tr>
                                        <td style={{textAlign:"center"}}>{value.date}</td>
                                        <td style={{color:"#008C53", textAlign:"center"}}>{value.in}</td>
                                        <td style={{color:"#FF1400", textAlign:"center"}}>{value.out}</td>
                                    </tr>
                                )
                            })}                            
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    )
}
export default InventoryDetails;