import InventoryLeft from "./InventoryLeft";
import InventoryAddNew from "./InventoryaddNew";
import InventoryDetails from "./InventoryDetails";
import InventoryNewuser from "./InventoryNewuser";
import { useEffect, useState } from "react";
import Helper from "../util/Helper";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

const Inventory = () => {

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const [inventorydetails,setInventorydetails] = useState(false)
    const [inventoryItemId, setInventoryItemId] = useState(null)
    const [inventoryIvId, setInventoryIvId] = useState(null)

    const [showInventoryList, setShowInventoryList] = useState([])
    const [inventoryEmptystate, setInventoryEmptyState] = useState(false)
    const [skeletonLoading, setSkeletonLoading] = useState(true)

    const [inventoryFilter, setInventoryFilter] = useState(false)
    const [inventoryFilterValue, setInventoryFilterValue] = useState(null)

    useEffect(() => {
        const getBillListUrl = {
          method: "GET",
          url: Helper.getWsUrl() + `${!inventoryFilter ? `/inventory/get_inventory_list/?web=1` : `/inventory/get_inventory_list/?web=1&status=${inventoryFilterValue}`}`,
          headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(getBillListUrl)
        .then((response) => {  
            // setCookie("token", response.headers.token);            
            if (response.data.code === 200) {
                setShowInventoryList(Helper.encypt_decrypt(response.data.data))   
                setInventoryEmptyState(false)
                setSkeletonLoading(false)             
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            } else if(response.data.code === 204) {
                setShowInventoryList([])
                if(!inventoryFilter) {
                    setInventoryEmptyState(true)
                    setSkeletonLoading(false)
                }
              }
        })
        .catch((error) => {
            alert(error);
        })
    },[inventoryFilter, inventoryFilterValue]) 

    return (
        <div className="col-10" style={{  backgroundColor: "rgb(245, 245, 245)", padding: "20px", display: "flex" }}>
            {inventoryEmptystate ? 
                <InventoryNewuser />
            :
                <>
                    <InventoryLeft setInventoryFilterValue = {setInventoryFilterValue} inventoryFilterValue = {inventoryFilterValue} setInventoryFilter = {setInventoryFilter} skeletonLoading = {skeletonLoading} showInventoryList = {showInventoryList} setInventoryItemId = {setInventoryItemId} setInventoryIvId = {setInventoryIvId} setInventorydetails={setInventorydetails}></InventoryLeft>
                    {inventorydetails ? 
                        <InventoryDetails inventoryIvId = {inventoryIvId} inventoryItemId = {inventoryItemId} setInventorydetails={setInventorydetails}></InventoryDetails>
                    :
                        <InventoryAddNew></InventoryAddNew>
                    }
                </>
            }
        </div>
    )
}

export default Inventory;