import React, { useState, useCallback, useEffect } from 'react'
import './PurchaseAddItem.css'

const PurchaseAddItem = ({setGetShopDetail, setShowUnpaidPage, setShowPurchasePayment, setShowPurchaseAddItemDetail}) => {

    const [shopDetail, setShopDetail] = useState(null)
    const [shopContact, setShopContact] = useState({
        mobile_number: ""
    })
    const [allShopDetail, setAllShopDetail] = useState({
        shopname: null,
        contactno: null,
        date: null,
        gst_no: null,
        bill_no: null
    })

    const getShopDetails = useCallback(event => {
        const { name, value } = event.target;
        setShopDetail({ ...shopDetail, [name]: value });          
    });

    const getShopContactNo = (event) => {
        const re = /^[0-9\b]+$/;
        if(event.target.value === '' || re.test(event.target.value)) {
            setShopContact ({
            ...shopContact,
            mobile_number: event.target.value     
            });
        }
    };

    useEffect(() => {
        setAllShopDetail({
            // ...allShopDetail,
            shopname: shopDetail && shopDetail.shopname,
            contactno: shopContact && shopContact.mobile_number,
            date: shopDetail && shopDetail.date,
            gst_no: shopDetail && shopDetail.gst_no,
            bill_no: shopDetail && shopDetail.bill_no
        })
    },[shopDetail, shopContact])

    const shopDetailFunc = () => {
        setGetShopDetail(allShopDetail)
    }

    const validation = () => {
        if(shopDetail && shopDetail.shopname != null && shopContact && shopContact.mobile_number != null && shopDetail && shopDetail.date != null){
            setShowPurchaseAddItemDetail(true)
        } else {
            alert("please fill the required field")
        }
    }

    const validationWhenSkip = () => {
        if(shopDetail && shopDetail.shopname != null && shopContact && shopContact.mobile_number != null && shopDetail && shopDetail.date != null){
            setShowPurchasePayment(true)
            setShowUnpaidPage(false)
        } else {
            alert("please fill the required field")
        }
    }
    var date=new Date();
    var tdate=date.getDate();
    var year = date.getFullYear();
  
  if(tdate < 10){
    tdate = "0"+ tdate;
}
    var month=date.getMonth()+1;
    if(month < 10){
        month = "0"+ month;
    }
    var minDate = year + "-"+month+"-"+tdate;

       
    return (
        <div id="box" style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>          
            <img src={require("../assets/images/AddPrchaseImg.png")} alt='' style={{ width:"123px", height:"143px"}}/> 

           <p style={{marginTop:"25px", fontSize:"18px", fontWeight:"600"}}>Shop Details</p>

           <div class="pai-user-input-wrp" style={{width:"80%"}}>                
                <input autoComplete='off' type="text" style={{width:"100%"}} class="inputText" onChange={getShopDetails} name="shopname"/>
                <span class="purchaseAddItm-floating-label">Shop Name*</span>
            </div>   
            <div style={{display:"flex", justifyContent:"space-between", width:"80%", marginTop:"16px"}}>
                <div class="pai-user-input-wrp" style={{ marginRight:"9px", width:"50%" }}>                
                    <input autoComplete="off" type="text" class="inputText" name="mobile_number" value={shopContact && shopContact.mobile_number} maxLength={10} onChange={(event) => {getShopContactNo(event)}}/>
                    <span class="purchaseAddItm-floating-label">Contact Number*</span>
                </div>  
                <div class="pai-user-input-wrp" style={{ marginLeft:"9px", width:"50%" }}>                
                    <input autoComplete='off' type="date" max={minDate} onKeyDown={(evt)=>{const cancelKeypress=RegExp(/^[0-9\b]+$/).test(evt.keyCode.toString());if(cancelKeypress){evt.preventDefault()}}} class="inputText calender" onChange={getShopDetails} name="date" />
                    <span class="purchaseAddItm-floating-label">Date*</span>
                </div>  
            </div>
            <div style={{display:"flex", justifyContent:"space-between", width:"80%", marginTop:"16px"}}>
                <div class="pai-user-input-wrp" style={{ marginRight:"9px", width:"50%" }}>                
                    <input autoComplete='off' maxLength={15} type="text" class="inputText" onChange={getShopDetails} name="gst_no" />
                    <span class="purchaseAddItm-floating-label">GST Number (Optional)</span>
                </div>  
                <div class="pai-user-input-wrp" style={{ marginLeft:"9px", width:"50%" }}>                
                    <input autoComplete='off' type="text" class="inputText" onChange={getShopDetails} name="bill_no" />
                    <span class="purchaseAddItm-floating-label">Bill Number (Optional)</span>
                </div>  
            </div>
            <div style={{marginTop:"50px"}}>
                <button onClick={() => {shopDetailFunc(); validationWhenSkip();}} style={{border:"1px solid #008c53", borderRadius:"20px", padding:"5px 20px", color:"#008c53", textAlign:"center", background:"none", fontSize:"20px", fontweight:"600"}}>
                    Skip
                </button>
                <button onClick={() => {validation(); shopDetailFunc()}} style={{border:"none", borderRadius:"20px", padding:"5px 20px", color:"#fff", textAlign:"center", background:"#008c53", fontSize:"20px", fontweight:"600", marginLeft:"25px"}}>
                    Add Item
                </button>
            </div>
        </div>  
    )
}

export default PurchaseAddItem