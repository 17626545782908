import UdhaarPayment from "./UdhaarPayment";
import UdhaarTotal from "./UdhaarTotal";
import UdhaarBill from "./UdhaarBill";
import UdhaarLeft from "./UdhaarLeft";
import React,{useEffect, useState} from "react";
import UdhaarFirstTimeUser from "./UdhaarFirstTimeUser";
import Helper from "../util/Helper";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

const Udhaar = () => {

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const[showUdhaarbill,setShowUdhaarBill]=useState(false);
    const[Payment,setPayment]=useState(false)
    const[userName,setUserName]=useState("");
    const[customerId,setCustomerId]=useState(null);
    const[billId,setbillId]=useState([]);
    const[showallhistory,setShowAllHistory]=useState(false)
    const[showalert,setShowAlert]=useState(false);
    const[example,setExample]=useState(false);
    const[showUdhaarList, setShowUdhaarList] = useState([])
    const[skeletonLoading, setSkeletonLoading] = useState(true)
    const[billingEmptystate, setBillingEmptyState] = useState(false)
    const[udhaarFilter, setUdhaarFilter] = useState(true)
    const[udhaarFilterValue, setUdhaarFilterValue] = useState('all')

    useEffect(() => {
        const requestOptions = {
            method: "POST",
            url: Helper.getWsUrl() + `${!udhaarFilter ? `/vouchers/shop_credit_list/?web=1` : `/vouchers/shop_credit_list/?key=${udhaarFilterValue}&web=1`}`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                if (response.data.code === 200) {
                    setShowUdhaarList(Helper.encypt_decrypt(response.data.data).credit_list)
                    setSkeletonLoading(false)
                    setBillingEmptyState(false)
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else if(response.data.code === 204) {
                    setShowUdhaarList([])
                    if(udhaarFilter) {
                        setSkeletonLoading(false)
                        setBillingEmptyState(true)
                    }
                }  
            })
            .catch((err) => {
                console.log(err)
            })
    },[udhaarFilter, udhaarFilterValue])

    return (
        <>
            {Payment ? 
                <UdhaarPayment setShowUdhaarBill={setShowUdhaarBill} setPayment={setPayment} customerId={customerId} billId={billId} setbillId={setbillId} setShowAlert={setShowAlert} setExample={setExample}/> 
            :
                <div className="col-10" style={{  backgroundColor: "rgb(245, 245, 245)", padding: "20px", display: "flex" }}>
                   {!billingEmptystate ? 
                        <>
                            <UdhaarLeft udhaarFilterValue = {udhaarFilterValue} setUdhaarFilterValue = {setUdhaarFilterValue} setUdhaarFilter = {setUdhaarFilter} skeletonLoading = {skeletonLoading} showUdhaarList = {showUdhaarList} setShowUdhaarBill={setShowUdhaarBill} setUserName={setUserName} setCustomerId={setCustomerId} ></UdhaarLeft>
                            {showUdhaarbill ?
                                <UdhaarBill setPayment={setPayment} userName={userName} customerId={customerId} setCustomerId={setCustomerId} setbillId={setbillId}></UdhaarBill>
                                :
                                <UdhaarTotal  setShowAllHistory={setShowAllHistory} showallhistory={showallhistory}/>
                            }
                        </>
                    :
                        <UdhaarFirstTimeUser />
                    }
                </div>
            }
        </>
    )
}

export default Udhaar;