import React, { useEffect, useState } from "react";
import BillingLeft from "./BillingLeft";
import NewBilling from "./NewBilling";
import ViewBills from "./ViewBills";
import BillGenarate from "./BillGenarate"
import BillsPayment from "./BillsPayment";
import ShowBill from "./ShowBill"
import BillingAddGrahak from "./BillingAddGrahak";
import BillingFirstTimeUser from "./BillingFirstTimeUser";
import Helper from "../util/Helper";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

const Billing = ({globalBillId, billIdPitB, grahakDtlPitB, ttlAmtAllItemPitB, grahakDtlFrmGTB, bookingTotalAmtForBilling, bookingCustomerIdForBilling, bookingItemBillIdForBilling, bookingItemListForBilling}) => {

  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  
  const [showViewBills, setShowViewBills] = useState(false)
  const [showBillGenerate, setShowBillGenerate] = useState(false)
  const [getPath, setGetPath] = useState(null)
  const [getPdf, setGetPdf] = useState(null)
  const [showPaymentPage, setShowPaymentPage] = useState(false)
  const [showbillid,setShowbillid] = useState(null);
  const [showBillDetail, setShowBillDetail] = useState(false)
  const [showAddGrahak, setShowAddGrahak] = useState(false) 
  const [getCardBillId, setGetCardBillId] = useState(null)
  const [getTotalAmountForAllItem, setGetTotalAmountForAllItem] = useState(null)
  const [grahakDetails, setGrahakDetails] = useState(null)

  const [showBillingList, setShowBillingList] = useState([])
  const [billingEmptystate, setBillingEmptyState] = useState(false)
  const [skeletonLoading, setSkeletonLoading] = useState(true)

  const [itemListToAddBill, setItemListToAddBill] = useState([])
  const [grhkContactId, setGrhkContactId] = useState(null)
  const [overAllDiscount, setOverAllDiscount] = useState(null)
  const [itemListTtlAmtForAddBill, setItemListTtlAmtForAddBill] = useState(null)
  const [itemListTtlAmtWithDiscForAddBill, setItemListTtlAmtWithDiscForAddBill] = useState(null)

  const [forEditItemList, setforEditItemList] = useState(false)
  const [itemListToEditBill, setItemListToEditBill] = useState([])

  const [billDetailForReturnReplace, setBillDetailForReturnReplace] = useState(null)
  const [accessReturnReplace, setAccessReturnReplace] = useState(false)
  const [removeItemList, setRemoveItemList] = useState([])
  const [addedNewtemList, setAddedNewItemList] = useState([])

  const [billingFilter, setBillingFilter] = useState(false)
  const [billingFilterValue, setBillingFilterValue] = useState(null)

  useEffect(() => {
    if(globalBillId && globalBillId != null) {
      setShowBillDetail(true)
    }
  },[globalBillId])

  useEffect(() => {
    if(bookingItemBillIdForBilling && bookingItemBillIdForBilling != null) {
      setShowPaymentPage(true)
    }
  },[bookingItemBillIdForBilling])

  useEffect(() => {
    if(billIdPitB && billIdPitB != null) {
      setShowPaymentPage(true)
    }
  },[billIdPitB])

  useEffect(() => {
    if(grahakDtlFrmGTB && grahakDtlFrmGTB != null) {
      setShowViewBills(true)
    }
  },[grahakDtlFrmGTB])

  useEffect(() => {
    const getBillListUrl = {
      method: "GET",
      url: Helper.getWsUrl() + `${!billingFilter ? `/vouchers/getBillsList/?web=1` : `/vouchers/getBillsList/?bill_adjusted=${billingFilterValue}&web=1`}`,
      headers: { "Content-Type": "application/json", 'token': cookies.token },
    };
    axios(getBillListUrl)
    .then((response) => {          
        if (response.data.code === 200) {
          setShowBillingList(Helper.encypt_decrypt(response.data.data).billdetails)
          setBillingEmptyState(false)
          setSkeletonLoading(false)            
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } else if(response.data.code === 204) {
          setShowBillingList([])
          if(!billingFilter) {
            setBillingEmptyState(true)
            setSkeletonLoading(false)
          }
        }
    })
    .catch((error) => {
        alert(error);
    })
  },[billingFilter, billingFilterValue])

  return (
    <>
      {showPaymentPage ? 
        <BillsPayment bookingTotalAmtForBilling = {bookingTotalAmtForBilling} bookingCustomerIdForBilling = {bookingCustomerIdForBilling} bookingItemBillIdForBilling = {bookingItemBillIdForBilling} bookingItemListForBilling = {bookingItemListForBilling} addedNewtemList = {addedNewtemList} removeItemList = {removeItemList} getCardBillId = {getCardBillId} accessReturnReplace = {accessReturnReplace} itemListTtlAmtWithDiscForAddBill = {itemListTtlAmtWithDiscForAddBill} itemListTtlAmtForAddBill = {itemListTtlAmtForAddBill} overAllDiscount = {overAllDiscount} grhkContactId = {grhkContactId} itemListToAddBill = {itemListToAddBill} grahakDtlFrmGTB = {grahakDtlFrmGTB} billIdPitB = {billIdPitB} grahakDtlPitB = {grahakDtlPitB} ttlAmtAllItemPitB = {ttlAmtAllItemPitB} setShowAddGrahak = {setShowAddGrahak} grahakDetails = {grahakDetails} setShowViewBills = {setShowViewBills} setShowBillGenerate = {setShowBillGenerate} setShowPaymentPage ={setShowPaymentPage} getTotalAmountForAllItem = {getTotalAmountForAllItem} showbillid = {showbillid} />
        :
        <>
          {showBillGenerate ? 
            <BillGenarate setGetTotalAmountForAllItem = {setGetTotalAmountForAllItem} addedNewtemList = {addedNewtemList} removeItemList = {removeItemList} getCardBillId = {getCardBillId} billDetailForReturnReplace = {billDetailForReturnReplace} accessReturnReplace = {accessReturnReplace} setforEditItemList = {setforEditItemList} setShowBillGenerate={setShowBillGenerate} itemListTtlAmtWithDiscForAddBill = {itemListTtlAmtWithDiscForAddBill} itemListTtlAmtForAddBill = {itemListTtlAmtForAddBill} overAllDiscount = {overAllDiscount} grhkContactId = {grhkContactId} itemListToAddBill = {itemListToAddBill} setShowPaymentPage = {setShowPaymentPage}/>
            : 
            <>
              {showViewBills ?                 
                <ViewBills addedNewtemList = {addedNewtemList} setAddedNewItemList = {setAddedNewItemList} setRemoveItemList = {setRemoveItemList} removeItemList = {removeItemList} billDetailForReturnReplace = {billDetailForReturnReplace} accessReturnReplace = {accessReturnReplace} itemListToEditBill = {itemListToEditBill} setItemListToEditBill = {setItemListToEditBill} forEditItemList = {forEditItemList} itemListToAddBill = {itemListToAddBill} setItemListTtlAmtWithDiscForAddBill = {setItemListTtlAmtWithDiscForAddBill} setItemListTtlAmtForAddBill = {setItemListTtlAmtForAddBill} setOverAllDiscount = {setOverAllDiscount} setGrhkContactId = {setGrhkContactId} setItemListToAddBill = {setItemListToAddBill} grahakDtlFrmGTB = {grahakDtlFrmGTB} grahakDetails = {grahakDetails} setGetTotalAmountForAllItem = {setGetTotalAmountForAllItem} setShowbillid = {setShowbillid} setGetPdf = {setGetPdf} setGetPath = {setGetPath} setShowBillGenerate = {setShowBillGenerate}/> 
              : 
                <div className="col-10" style={{  backgroundColor: "rgb(245, 245, 245)", padding: "20px", display: "flex" }}>
                  {billingEmptystate ? 
                    <BillingFirstTimeUser setShowViewBills = {setShowViewBills} setBillingEmptyState = {setBillingEmptyState} setShowAddGrahak = {setShowAddGrahak} />
                  :
                    <>
                      <BillingLeft billingFilterValue = {billingFilterValue} setBillingFilterValue = {setBillingFilterValue} setBillingFilter = {setBillingFilter} skeletonLoading = {skeletonLoading} showBillingList = {showBillingList} setShowBillDetail = {setShowBillDetail} setGetCardBillId = {setGetCardBillId}/>
                      {showBillDetail ? 
                        <ShowBill setShowViewBills = {setShowViewBills} setAccessReturnReplace = {setAccessReturnReplace} setBillDetailForReturnReplace = {setBillDetailForReturnReplace} globalBillId = {globalBillId} setShowAddGrahak = {setShowAddGrahak} getCardBillId = {getCardBillId} setShowBillDetail = {setShowBillDetail} /> 
                      :
                        <>
                          {showAddGrahak ? 
                            <BillingAddGrahak setGrahakDetails = {setGrahakDetails} setShowViewBills = {setShowViewBills}/>
                          :
                            <NewBilling setShowAddGrahak = {setShowAddGrahak} setShowViewBills = {setShowViewBills} /> 
                          }
                        </>
                      }
                    </>
                  }
                </div> 
              }
            </>
          }    
        </>
      }                        
    </>
  )
};

export default Billing;
