const InventoryAddNew=()=>{

    return(
        <div id="box" style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>          
            <img src={require("../assets/images/inventory 1.png")} class="img-fluid" />
            <div> 
                <p style={{ marginTop:"40px",color: "#8C8C8C", fontWeight: "600", fontSize: "18px" }}>Add items to catalouge to organise your Inventory. </p>
            </div>
        </div>
    )
}
export default InventoryAddNew;