
import './ClickUdhaar.css'
import TextField from '@material-ui/core/TextField';
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const UdhaarLeft = ({ setShowUdhaarBill, setCustomerId, showUdhaarList, skeletonLoading, setUdhaarFilterValue, setUdhaarFilter, udhaarFilterValue }) => {

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()
    const[heightnav,setheightnav]=useState(true)
    const [navbarOpen, setNavbarOpen] = useState(false)
    const [active, setActive] = useState(null)
    const [changeClass, setChangeClass] = useState(false)
    const [custom,setCustom]=useState(false);

    // const [btn2, setBtn2] = useState(true);
    // const [btn3, setBtn3] = useState(true);
    // const [btn4, setBtn4] = useState(false);

    const [minIndex, setMinIndex] = useState(0)
    const [maxIndex, setMaxIndex] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [countPage, setCountPage] = useState(1)

    // const [countPagePaid, setCountPagePaid] = useState(1)
    // const [countPagePending, setCountPagePending] = useState(1)  
    const [countPageAllUdhaar, setCountPageAllUdhaar] = useState(1)   
    // const [countPageAccDate, setCountPageAccDate] = useState(1)
    const [changeinp,setChangeInp]=useState(false);

    // const [allFilter, setAllFilter] = useState(true)
    // const [paidFilter, setPaidFilter] = useState(true)
    // const [pendingFilter, setPendingFilter] = useState(true)

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [users, setUsers] = useState([]);
    // const [date, setDate] = useState([]);
    
    const handlePagination = (pageNumber) => {
        setMinIndex((pageNumber - 1) * 10)
        setMaxIndex(pageNumber * 10)
        setCurrentPage(pageNumber)
    }

    // const showA = () => {
    //     setBtn2(true);
    //     setBtn3(true);
    //     setBtn4(false);

    //     setAllFilter(false)
    //     setPaidFilter(true)
    //     setPendingFilter(true)
    // }
    // const showB = () => {
    //     setCountPage(Math.ceil(countPagePaid / 10))
    //     setBtn2(true);
    //     setBtn3(false);
    //     setBtn4(false);

    //     setAllFilter(true)
    //     setPaidFilter(false)
    //     setPendingFilter(true)
    // }
    // const showC = () => {
    //     setCountPage(Math.ceil(countPagePending / 10))
    //     setBtn2(false);
    //     setBtn3(true);
    //     setBtn4(false);

    //     setAllFilter(true)
    //     setPaidFilter(true)
    //     setPendingFilter(false)
    // }
    // const showD = () => {
    //     setCountPage(Math.ceil(countPageAccDate / 10))
    //     setBtn2(false);
    //     setBtn3(false);
    //     setBtn4(true);
    // }

    // const forCustomFilterActive = () => {
    //     setAllFilter(true)
    //     setPaidFilter(true)
    //     setPendingFilter(true)
    // }
    
    var dates=new Date();
    var tdate=dates.getDate();
    var year = dates.getFullYear();
  
    if(tdate < 10){
        tdate = "0"+ tdate;
    }
    var month=dates.getMonth()+1;
    if(month < 10){
        month = "0"+ month;
    }
    var maxDate = year + "-"+month+"-"+tdate;

    const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
    }

    useEffect(() => {
        setUsers(showUdhaarList)
    },[showUdhaarList])

    // useEffect(() => {
    //     const requestOptions = {
    //         method: "POST",
    //         url: Helper.getWsUrl() + `/vouchers/shop_credit_list/?key=all&web=1`,
    //         headers: { "Content-Type": "application/json", "token": cookies.token },
    //     };
    //     axios(requestOptions)
    //         .then((response) => {
    //             if (response.data.code === 200) {
    //                 setUsers(Helper.encypt_decrypt(response.data.data).credit_list)
    //             } else if (response.data.code === 401) {
    //                 removeCookie("logged_in")
    //                 removeCookie("token")
    //                 setTimeout(() => {
    //                     navigate('/')
    //                     localStorage.setItem("isAuthenticated", false)
    //                     localStorage.removeItem("isAuthenticated")
    //                 }, 1000)
    //               }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // },[])

    useEffect(() => {
        let countUdhaar = 0
        // let countPaid = 0
        // let countPending = 0
        // let countDate = 0
        if(users.length > 0) {
            users.map((value,index) =>{
                // if(value.amount === 0) {
                //     countPaid += 1
                // } else if (value.amount !== 0) {
                //     countPending += 1
                // }
                countUdhaar += 1
            })
            setCountPageAllUdhaar(countUdhaar)
            // setCountPagePaid(countPaid)
            // setCountPagePending(countPending)
        }
        // if(date.length > 0) {
        //     date.map((value, index) => {
        //         countDate += 1
        //     })
        //     setCountPageAccDate(countDate)
        // }
    },[users])

    useEffect(() => {
        // if(btn2 && btn3) {
            setCountPage(Math.ceil(countPageAllUdhaar / 10))
        // } 
    })

    useEffect(() => {
        if(startDate && startDate != null && endDate && endDate != null) {
            const requestOptions = {
                method: "POST",
                url: Helper.getWsUrl() + `/vouchers/shop_credit_list/?key=all&web=1`,
                headers: { "Content-Type": "application/json", "token": cookies.token },
                data: {
                    end_date: endDate && endDate,
                    start_date: startDate && startDate
                }
            };
            axios(requestOptions)
                .then((response) => {
                    // setCookie("token", response.headers.token);
                    if (response.data.code === 200) {
                        setUsers(Helper.encypt_decrypt(response.data.data).credit_list)
                    } else if (response.data.code === 401) {
                        removeCookie("logged_in")
                        removeCookie("token")
                        setTimeout(() => {
                            navigate('/')
                            localStorage.setItem("isAuthenticated", false)
                            localStorage.removeItem("isAuthenticated")
                        }, 1000)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },[startDate, endDate])

    const Paid = () => {
        return (
            <>
                {users.map((value, index) => {
                    // if (value.amount === 0) {
                        return (
                            index >= minIndex && index < maxIndex && (
                                <div className={`container All  ${active == index && 'active'}`} key={index} onClick={() => { setShowUdhaarBill(true); setCustomerId(value.customer_id);setActive(index) }}
                                    style={{ cursor:"pointer", borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px", boxSizing: "border-box", marginBottom: "20px", width: "100%", padding: "16px 10px 4px 16px", display: "flex", }}>
                                    <div style={{ width: "40%" }}>
                                        <p className='child mb-1 text-truncate' style={{ fontWeight: "600", fontSize: "18px" }}>
                                            
                                            {
                                                (value.customer_name==="" || value.customer_name==null)
                                                ?
                                                "N/A"
                                                :
                                                value.customer_name
                                                
                                            }
                                           
                                            
                                            </p>
                                        <p style={{ fontWeight: "500", fontSize: "16px" }} className="child3 mb-2" >{value.customer_phone}</p>
                                    </div>
                                    <div style={{ marginLeft: "5%", width: "50%", textAlign: "center", display:"flex" }}>
                                        <div style={{ display: "flex", flexDirection:"column", width:"50%", justifyContent:"center" }}>
                                            <p className="child5 mb-1 text-left" style={{ fontSize: "16px", fontWeight: "500" }}>Paid</p>
                                            {udhaarFilterValue === 'paid' ? 
                                                <p className="child6 mb-2 text-left" style={{ fontSize: "16px", fontWeight: "500" }}>₹ {parseFloat(value.amount).toFixed(2)}</p>
                                            :
                                                <p className="child6 mb-2 text-left" style={{ fontSize: "16px", fontWeight: "500" }}>₹ {parseFloat(value.paid).toFixed(2)}</p>
                                            }
                                        </div>
                                        <div style={{ display: "flex", flexDirection:"column", width:"50%", justifyContent:"center" }}>
                                            <p style={{ fontWeight: "500", fontSize: "16px", marginLeft: "10%" }} className=" child2 mb-1" >Pending</p>
                                            {udhaarFilterValue === 'paid' ?
                                                <p style={{ fontWeight: "500", fontSize: "16px", marginLeft: "10%" }} className='  child4 mb-2'>₹ {parseFloat(value.paid).toFixed(2)}</p>
                                            :
                                                <p style={{ fontWeight: "500", fontSize: "16px", marginLeft: "10%" }} className='  child4 mb-2'>₹ {parseFloat(value.amount).toFixed(2)}</p>
                                            }
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "3%" }}>
                                       {
                                        (index===active)
                                        ?
                                       
                                        <img style={{ height: "20px", width: "8px",marginBottom:"17px" }} src={require("../assets/images/white_left_marker.png")} id={"vector"} className="img-fluid "></img>
                                        :
                                        <img style={{ height: "20px", width: "8px",marginBottom:"17px" }} src={require("../assets/images/vector.png")} id={"vector"} className="img-fluid "></img>
                                        
                                       } 
                                     
                                    </div>
                                </div>
                            )
                        )
                    // }
                })}
            </>
        )
    }

    // // function for after
    // const Pending = () => {
    //     return (
    //         <>
    //             {users.map((value, index) => {
    //                 if (value.amount !== 0) {
    //                     return (
    //                         index >= minIndex && index < maxIndex && (
    //                             <div  className={`container All  ${active == index && 'active'}`} key={index} onClick={() => { setShowUdhaarBill(true); setCustomerId(value.customer_id);setActive(index)}}
    //                                 style={{ cursor:"pointer", borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px", boxSizing: "border-box", marginBottom: "20px", width: "100%", padding: "16px 10px 16px 16px", display: "flex", }}>
    //                                 <div style={{ width: "40%" }}>
    //                                     <p className='child' style={{ fontWeight: "600", fontSize: "18px" }}>{value.customer_name}</p>
    //                                     <p style={{ fontWeight: "500", fontSize: "16px" }} className="child3" >{value.customer_phone}</p>
    //                                 </div>
    //                                 <div style={{ marginLeft: "5%", width: "50%", textAlign: "center", display:"flex" }}>
    //                                     <div style={{ display: "flex", flexDirection:"column", width:"50%", justifyContent:"center" }}>
    //                                         <p className="child5" style={{ fontSize: "14px", fontWeight: "500" }}>Paid</p>
    //                                         <p className="child6" style={{ fontSize: "16px", fontWeight: "500" }}>₹ {parseFloat(value.paid).toFixed(2)}</p>
    //                                     </div>
    //                                     <div style={{ display: "flex", flexDirection:"column", width:"50%", justifyContent:"center" }}>
    //                                         <p style={{ fontWeight: "500", fontSize: "14px", marginLeft: "10%" }} className=" child2" >Pending</p>
    //                                         <p style={{ fontWeight: "500", fontSize: "16px", marginLeft: "10%" }} className='  child4 '>₹ {parseFloat(value.amount).toFixed(2)}</p>
    //                                     </div>
    //                                 </div>
    //                                 <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "3%" }}>
    //                                     <img style={{ height: "20px", width: "8px" }} src={require("../assets/images/vector.png")} id={"vector"} className="img-fluid "></img>
    //                                 </div>
    //                             </div>
    //                         )
    //                     )
    //                 }
    //             })}
    //         </>
    //     )
    // }

    // // function for dates
    // const forDate = () => {
    //     return (
    //         <>
    //             {date.map((value, index) => {                   
    //                 return (
    //                     index >= minIndex && index < maxIndex && (
    //                         <div className={`container All  ${active == index && 'active'}`} key={index} onClick={() => { setShowUdhaarBill(true); setCustomerId(value.customer_id);setActive(index) }}
    //                             style={{ cursor:"pointer", borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px", boxSizing: "border-box", marginBottom: "20px", width: "100%", padding: "16px 10px 16px 16px", display: "flex", }}>
    //                             <div style={{ width: "40%" }}className='child'>
    //                                 <p  style={{ fontWeight: "600", fontSize: "18px" }}>{value.customer_name}</p>
    //                                 <p style={{ fontWeight: "500", fontSize: "16px" }} className="child3" >{value.customer_phone}</p>
    //                             </div>
    //                             <div style={{ marginLeft: "5%", width: "50%", textAlign: "center", display:"flex" }}>
    //                                 <div style={{ display: "flex", flexDirection:"column", width:"50%", justifyContent:"center" }}>
    //                                     <p className="child5" style={{ fontSize: "14px", fontWeight: "500" }}>Paid</p>
    //                                     <p className="child6" style={{ fontSize: "16px", fontWeight: "500" }}>₹ {parseFloat(value.paid).toFixed(2)}</p>
    //                                 </div>
    //                                 <div style={{ display: "flex", flexDirection:"column", width:"50%", justifyContent:"center" }}>
    //                                     <p style={{ fontWeight: "500", fontSize: "14px", marginLeft: "10%" }} className=" child2" >Pending</p>
    //                                     <p style={{ fontWeight: "500", fontSize: "16px", marginLeft: "10%" }} className='  child4 '>₹ {parseFloat(value.amount).toFixed(2)}</p>
    //                                 </div>
    //                             </div>
    //                             <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "3%" }}>
    //                                 <img style={{ height: "20px", width: "8px" }} src={require("../assets/images/vector.png")} id={"vector"} className="img-fluid "></img>
    //                             </div>
    //                         </div>
    //                     )
    //                 )                     
    //             })}
    //         </>
    //     )
    // }

    // console.log("udhaar : ", users)

    const activeBtn = {
        background:"#008c53",
        border:"none",
        color:"#fff"
    }
    
    return (
        <div style={{width: "40%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff"}}>
            <div style={{position:"relative", width:"100%"}}>
                <h6 className='uddhh'>Udhaar</h6>
                {/* <nav className="navBar nvb" id={changeClass ? "forNavHight" : ""}> */}
                <nav className=
                {
                    (heightnav)?"navBar nvb1":"navBar nvb2"
                }
             
                
                id={changeClass ? "forNavHight" : ""}>
                    <button id="navbtn" onClick={handleToggle}>
                        {navbarOpen ? 
                            (<img onClick={() => {setChangeClass(false);setheightnav(true) ;setUdhaarFilter(true); setUdhaarFilterValue('all'); setCurrentPage(1); setCustom(false); setChangeInp(false)}} src={require("../assets/images/cancel icon.png")} />) 
                        : 
                            (<img onClick={() => {setChangeClass(true);setheightnav(false)}} src={require("../assets/images/filter icon.png")} />)}
                    </button>
                    <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`} style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto ", margin: "1%" }}>
                            <li style={{ marginRight: "10%" }}><a style={udhaarFilterValue === "all" && !custom  ? activeBtn : null} onClick={()=>{setUdhaarFilter(true); setUdhaarFilterValue('all'); setCurrentPage(1); handlePagination(1); setCustom(false); setChangeInp(false)}} className="hett">All</a></li>
                            <li style={{ marginRight: "10%" }}><a style={udhaarFilterValue === "paid" && !custom  ? activeBtn : null} onClick={()=>{setUdhaarFilter(true); setUdhaarFilterValue('paid'); setCurrentPage(1); handlePagination(1); setCustom(false); setChangeInp(false)}} className="hett">Paid</a></li>
                            <li style={{ marginRight: "10%" }}><a style={udhaarFilterValue === null && !custom  ? activeBtn : null} onClick={()=>{setUdhaarFilter(false); setUdhaarFilterValue(null); setCurrentPage(1); handlePagination(1); setCustom(false); setChangeInp(false)}} className="hett">Pending</a></li>
                            <li style={{ marginRight: "10%" }}><a style={!custom ? null : activeBtn} onClick={()=>{setCustom(true); setUsers([]); setCurrentPage(1); handlePagination(1)}} className="hett">Custom</a></li>
                        </div>
                        {custom ?  
                            <div style={{display:"flex",width:"100%"}}>
                            { changeinp ? 
                                    <>
                                        <li style={{ marginRight: "10%" }}>
                                            <TextField max={maxDate} onChange={e => setStartDate(e.target.value)} className="cal3" id="date" type="date" InputLabelProps={{ shrink: true,}} />
                                        </li>
                                        <div style={{paddingRight:"10px"}}>
                                            <p style={{marginRight:"40px"}}>to</p>
                                        </div>                            
                                        <li style={{ marginRight: "10%" }}>
                                            <TextField max={maxDate} onChange={e => setEndDate(e.target.value)} className="cal3" id="date" type="date" InputLabelProps={{shrink: true }} />
                                        </li>
                                    </>
                                :
                                    <>
                                        <input style={{cursor:"pointer"}} className='inputText' onClick={()=>setChangeInp(true)} placeholder='start date'></input>
                                        <p style={{margin:"10px"}}>to</p> 
                                        <input style={{cursor:"pointer"}} className='inputText' onClick={()=>setChangeInp(true)} placeholder='end date'></input>
                                    </>
                                }
                            </div>
                        : 
                            null
                        }                    
                    </ul>
                </nav>
            </div>
            {skeletonLoading ? 
                <div style={{height:"60vh", overflowY:"scroll"}}>
                    <Skeleton count={5} borderRadius={"10px"} height={"13vh"} /> 
                </div>
            :
                <div style={{overflowY:"scroll", height:"60vh"}}>
                    {users && users.length > 0 ? 
                        <>
                            {Paid()}
                        </>
                    :
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"60vh"}}>
                            <p style={{color:"#7a7a7a", fontSize:"18px", fontWeight:"600"}}>no data found</p>
                        </div>
                    }
                </div>
            }
            <div className="pageCount">
                <Pagination count={countPage} defaultPage={1} page={currentPage} onChange={(event, pageNumber) => handlePagination(pageNumber)}/>
            </div>
        </div>
    )
}

export default UdhaarLeft