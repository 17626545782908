import "./Kyc.css"
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import S3 from 'react-aws-s3';
import OtpInput from "react-otp-input";

window.Buffer = window.Buffer || require("buffer").Buffer;

const Kyc = () => {

    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const [allKycData, setAllKycData] = useState(null)
    const [kycgetcontact,setKycgetcontact]=useState(null);
    const [adhaarNumber,setAdhaarNumber]=useState(null)
    const [pancardNumber,setPancardNumber]=useState(null)

    const [enableGstField, setEnableGstField] = useState(true)
    const [enableAadharField,setEnableAadharField]=useState(true)
    const [enablePanCardField,setEnablePanCardField]=useState(true)

    const [imgUrlPancardF, setImgUrlPancardF] = useState(null)
    const [pancardFront, setPancardFront] = useState(null)
    const [imgUrlAdhaarF, setImgUrlAdhaarF] = useState(null)
    const [imgUrlAdhaarB, setImgUrlAdhaarB] = useState(null)
    const [adhaarFront, setAdhaarFront] = useState(null)
    const [adhaarBack, setAdhaarBack] = useState(null)
    
    const [gstVerify,setGstVerify]=useState(true)
    const [panVerify,setPanVerify]=useState(true)
    const [adhaarVerify,setAadharVerify]=useState(true)

    const [adhaarOtpField, setAdhaarOtpField] = useState(false)
    const [adhaarOtp, setAdhaarOtp] = useState(null)
    const [adhaarRefId, setAdhaarRefId] = useState(null)

    useEffect(() => {
        getKYCData()
    },[])

    const getKYCData = () => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/contact/get_contact/0/?app_type=0&web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                if (response.data.code === 200) {
                    console.log("datataaaa : ", Helper.encypt_decrypt(response.data.data))
                    setAllKycData(Helper.encypt_decrypt(response.data.data))
                    setKycgetcontact(Helper.encypt_decrypt(response.data.data).gst_number)          
                    setAdhaarNumber(Helper.encypt_decrypt(response.data.data).adhaar_number) 
                    setPancardNumber(Helper.encypt_decrypt(response.data.data).pancard_number)

                    if(Helper.encypt_decrypt(response.data.data).gst_number != null && Helper.encypt_decrypt(response.data.data).gst_number != "") {
                        setEnableGstField(false)
                        setGstVerify(false)
                    }
                    if(Helper.encypt_decrypt(response.data.data).adhaar_number != null && Helper.encypt_decrypt(response.data.data).adhaar_number != "") {
                        setEnableAadharField(false)
                        setAadharVerify(false)
                    }
                    if(Helper.encypt_decrypt(response.data.data).pancard_number != null && Helper.encypt_decrypt(response.data.data).pancard_number != "") {
                        setEnablePanCardField(false)
                        setPanVerify(false)
                    }
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })  
    }

    useEffect(() => {
        if(allKycData && allKycData.a_fr != null) {
            setImgUrlAdhaarF(allKycData.a_fr)
        } else {
            let url = require("../assets/images/Component 466.png")
            setImgUrlAdhaarF(url)
        }
        if(allKycData && allKycData.a_ba != null) {
            setImgUrlAdhaarB(allKycData.a_ba)
        } else {
            let url = require("../assets/images/Component 466.png")
            setImgUrlAdhaarB(url)
        }
        if(allKycData && allKycData.p_fr != null) {
            setImgUrlPancardF(allKycData.p_fr)
        } else {
            let url = require("../assets/images/Component 466.png")
            setImgUrlPancardF(url)
        }
    },[allKycData])

    useEffect(() => {
        if(adhaarFront && adhaarFront != null) {
            if(adhaarNumber && adhaarNumber != null) {
                setImgUrlAdhaarF(URL.createObjectURL(adhaarFront))
                verifyAdhaarImg(adhaarFront)
            } else {
                alert("Please enter adhaar number first")
            }
        }  
    },[adhaarFront])

    useEffect(() => {
        if(adhaarBack && adhaarBack != null) {
            if(adhaarNumber && adhaarNumber != null) {
                setImgUrlAdhaarB(URL.createObjectURL(adhaarBack))
                verifyAdhaarImg(adhaarBack)
            } else {
                alert("Please enter adhaar number first")
            }
        }
    },[adhaarBack])

    useEffect(() => {
        if(pancardFront && pancardFront != null) {
            if(pancardNumber && pancardNumber != null) {
                setImgUrlPancardF(URL.createObjectURL(pancardFront))
                verifyPancardImg(pancardFront)
            } else {
                alert("Please enter PAN number first")
                }
        }
    },[pancardFront])   
      
    const verifyAdhaarImg = (adhaarImg) => {
        var bodyformData = new FormData();
        bodyformData.append('file', adhaarImg );
        bodyformData.append('adhaar_number', adhaarNumber);
        const getItemListCatalougeUrl = {
            method: "POST",
            url: Helper.getWsUrl() + `/items/detect_safe_search_documents/adhaar?web=1`,
            headers: { "content-type": "multipart/form-data", 'token': cookies.token },
            data:bodyformData
        };
        axios(getItemListCatalougeUrl)
        .then((response) => {         
            if (response.data.code === 200) {
                alert(response.data.msg)  
                if(adhaarFront && adhaarFront != null) {
                    let forAdhaarUrlFr = 'a_fr.png'
                    AWSCredentials(adhaarFront, forAdhaarUrlFr)
                }   
                if(adhaarBack && adhaarBack != null) {
                    let forAdhaarUrlB = 'a_ba.png'
                    AWSCredentials(adhaarBack, forAdhaarUrlB)
                }                       
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            } else if (response.data.code === 406) {
                alert(response.data.msg)
            } else if(response.data.code === 400) {
                alert(response.data.msg)
            }
        })
        .catch((error) => {
            alert(error);
        })
    }

    var bodyformData1 = new FormData();
    bodyformData1.append('file', pancardFront );
    bodyformData1.append('pan_number', pancardNumber);
    
    const verifyPancardImg = () => {
        const getItemListCatalougeUrl = {
            method: "POST",
            url: Helper.getWsUrl() + `/items/detect_safe_search_documents/pan?web=1`,
            headers: { "content-type": "multipart/form-data", 'token': cookies.token },
            data:bodyformData1
        };
        axios(getItemListCatalougeUrl)
            .then((response) => {          
                if (response.data.code === 200) {
                    alert(response.data.msg)
                    let urlPanFr = 'p_fr.png'
                    AWSCredentials(pancardFront, urlPanFr)                      
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else if (response.data.code === 406) {
                    alert(response.data.msg)
                } else if(response.data.code === 400) {
                    alert(response.data.msg)
                }
            })
            .catch((error) => {
                alert(error);
            })
    }

    var contactIdkyc = localStorage.getItem("p_id")
    
    const AWSCredentials = (editMerchantImg, imgKey) => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/settings/get_aws_credientials/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                if(response.data.code === 200) {
                
                    const imgFileKey = imgKey
                    const FOLDER_NAME =  contactIdkyc+"/doc"
                    const config = {
                        bucketName: Helper.encypt_decrypt(response.data.data).bucket_name,
                        region: "ap-south-1",
                        dirName: FOLDER_NAME,
                        accessKeyId: Helper.encypt_decrypt(response.data.data).aws_access_key,
                        secretAccessKey: Helper.encypt_decrypt(response.data.data).aws_secret_access_key,
                        s3Url: 'https://enbfile.s3.ap-south-1.amazonaws.com',
                    }
                    const ReactS3Client = new S3(config)
                    ReactS3Client
                        .uploadFile(editMerchantImg, imgFileKey)
                        .then(data => console.log("data : ",data))
                        .catch(err => console.error("error",err))
                }
            }
        );
    }

    const GSTVerificationApi=()=>{
        const Reuest = {
            url:Helper.getWsUrl() + `/contact/gst_varification/?gstNo=${kycgetcontact}&web=1`,
            method: "GET",
            headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(Reuest)
            .then((response) => {            
            if (response.data.code === 200) {                            
                alert("Validate Successfully");
                // setEnableGstField(false);
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              } else {
                alert("Not Validate");
            }
            })
            .catch((error) => {
            alert(error);
            });
    }

    const AdharVerificationApi = () => {
        const Reuest = {
            url: Helper.getWsUrl() + "/kyc/send_otp_to_aadhar/?web=1",
            method: "POST",
            headers: { "Content-Type": "application/json", Token: cookies.token },
            data: { aadhar_number: adhaarNumber && adhaarNumber },          
        };
        axios(Reuest)
        .then((response) => {        
            if (response.data.code === 200) {  
                alert(Helper.encypt_decrypt(response.data.data).message)
                setAdhaarRefId(Helper.encypt_decrypt(response.data.data).ref_id)
                setAdhaarOtpField(true)
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            } else {
                alert(response.data.msg);
            }
        })
        .catch((error) => {
            alert(error);
        });
    };

    const verfiyAdhaarNumberOtp = () => {
        const Reuest = {
          url: Helper.getWsUrl() + "/kyc/match_otp_to_aadhar/?web=1",
          method: "POST",
          headers: { "Content-Type": "application/json", Token: cookies.token },
          data: { 
            ref_id: adhaarRefId && adhaarRefId,
            otp: adhaarOtp && adhaarOtp 
          },
        };
        axios(Reuest)
          .then((response) => {
            if (response.data.code === 200) {
              alert(Helper.encypt_decrypt(response.data.data).message);
              setAdhaarOtpField(false)
            } else if (response.data.code === 401) {
              removeCookie("logged_in")
              removeCookie("token")
              setTimeout(() => {
                  navigate('/')
                  localStorage.setItem("isAuthenticated", false)
                  localStorage.removeItem("isAuthenticated")
              }, 1000)
            } else {
              alert(response.data.msg);
            }
          })
          .catch((error) => {
            alert(error);
          });
    }

    const PanVerificationAPI=()=>{
        const Reuest = {
            url: Helper.getWsUrl() + "/kyc/verify_pancard/?web=1",
            method: "POST",
            headers: { "Content-Type": "application/json", Token: cookies.token },
            data: {
                pan_number:pancardNumber && pancardNumber 
            },          
        };
        axios(Reuest)
        .then((response) => {          
            if (response.data.code === 200) {                
                alert(Helper.encypt_decrypt(response.data.data).message);
                // setEnablePanCardField(false)
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              } else {
                alert(response.data.data.message);
            }
        })
        .catch((error) => {
            alert(error);
        });
    }
      
    const UpdatedDataKycPatchApi=()=>{
        const requestOptions = {
            method: "PATCH",
            url: Helper.getWsUrl() + `/contact/patch_contact/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
            data:{
                gst_number: kycgetcontact && kycgetcontact,
                aadhar_number: adhaarNumber && adhaarNumber,
                pancard_number: pancardNumber && pancardNumber,                
            }
        };        
        axios(requestOptions)
            .then((response) => { 
                if (response.data.code === 201) {
                    alert("Kyc Update Successfully"); 
                    getKYCData()                  
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })  
    }

    const otpDivStyle = {
        justifyContent:"center",
        alignItems:"center",
    }
    const otpInputStyle = {
        height: "40px",
        width: "40px",
        borderRadius: "10px",
        marginLeft:"10px",
        border:"1px solid #D9D9D9",
        backgroundColor:"#D9D9D9"
    }
    const otpInputFocusStyle = {
        border:"1px solid #d9d9d9"
    }
    const boolean = {
        type: "number"
    }

    return (
        <div className='col-10' id='main-div' style={{backgroundColor: "#f9f9f9" , padding: "20px", display:"flex",height:"100%"}}>
            <div className='kycMainDiv'>
                <h1>KYC Details</h1>
                <div className='inputDetailKyc'>
                    <div className='dataFieldKyc' style={{display:"flex", alignItems:"center"}}>                                
                        <p style=
                        {{color:"#oo8c53", 
                            width:"30%",
                         fontWeight:"500",
                         fontSize:"18px",
                         marginBottom:"6px",                 
                         }}>GST Number :</p>
                        <input placeholder="Please Enter GST Number" value={kycgetcontact && kycgetcontact} disabled={!enableGstField ? "disable" : ""} maxLength="15" onChange={(e) => {setKycgetcontact(e.target.value)}} className='inputTextKyc' style={{width:"60%", marginLeft:"50px"}} type="text" />
                        {/* {gstVerify ? 
                            <button onClick={GSTVerificationApi} style={{display: (kycgetcontact && kycgetcontact.length === 15) ? "flex" : "none", marginLeft:"20px", border:"none", background:"#008c53", borderRadius:"10px", padding:"10px 20px", color:"#fff", fontWeight:"500"}}>Verify</button>
                        : null } */}
                    </div>
                </div> 

                <div className='inputDetailKyc'>
                    <div className='dataFieldKyc' style={{display:"flex", alignItems:"center"}}>                                
                        <p style={{color:"#oo8c53", width:"30%", fontWeight:"500",
                            fontSize:"18px",
                         marginBottom:"6px"
                         
                         }}>Adhaar Number :</p>
                        <input placeholder="Please Enter Adhaar Number" value={adhaarNumber && adhaarNumber} disabled={!enableAadharField ? "disable" : ""} maxLength="12" onChange={(e) => {setAdhaarNumber(e.target.value)}} className='inputTextKyc' style={{width:"60%", marginLeft:"50px"}} type="text" />
                        {/* {adhaarVerify ?
                            <button onClick={AdharVerificationApi} style={{display:!adhaarOtpField ? (adhaarNumber && adhaarNumber.length === 12) ? "flex" : "none" : "none", marginLeft:"20px", border:"none", background:"#008c53", borderRadius:"10px", padding:"10px 20px", color:"#fff", fontWeight:"500"}}>Verify</button>
                        : null} */}
                    </div>
                    <div style={{display: !adhaarOtpField ? "none" : "flex", marginTop:"10px", justifyContent:"end"}}>
                        <OtpInput
                            shouldAutoFocus={true}
                            containerStyle = {otpDivStyle}
                            inputStyle = {otpInputStyle}
                            focusStyle = {otpInputFocusStyle}
                            value={adhaarOtp}
                            isInputNum = {boolean}
                            onChange={(otp) => {setAdhaarOtp(otp)}}
                            numInputs={6}/>
                        <button disabled={(adhaarOtp && adhaarOtp.length) === 6 ? '' : 'disable'} onClick={() => {verfiyAdhaarNumberOtp()}} style={{marginLeft:"30px", color:"#fff", background:"#008c53", border:"none", fontWeight:"600", borderRadius:"10px", padding:"0 15px"}}>Done</button>
                    </div>
                    <div className='uploadDataKyc '>
                        <h2>Upload  Document </h2>
                        <div className='imgDivKyc'>
                            <Button style={{background:"none", boxShadow:"none"}} variant="contained" component="label">
                                <img src={imgUrlAdhaarF} style={{maxWidth:"120px", maxHeight:"70px"}} />
                                <input type="file" hidden accept="image/*" onChange={(e) => {setAdhaarFront(e.target.files[0])}} />
                            </Button>
                            <Button style={{background:"none", boxShadow:"none"}} variant="contained" component="label">
                                <img src={imgUrlAdhaarB} style={{maxWidth:"120px", maxHeight:"70px"}} />
                                <input type="file" hidden accept="image/*" onChange={(e) => {setAdhaarBack(e.target.files[0])}} />
                            </Button>
                        </div>
                    </div>
                </div>                
               
                <div className='inputDetailKyc'>
                    <div className='dataFieldKyc' style={{display:"flex", alignItems:"center"}}>                                
                        <p style={{color:"#oo8c53", width:"30%", fontWeight:"500",
                            fontSize:"18px",
                         marginBottom:"-1px"}}>PAN Card Number:</p>
                        <input placeholder="Please Enter PAN Card Number" value={pancardNumber && pancardNumber} disabled={!enablePanCardField ? "disable" : ""} onChange={(e) => {setPancardNumber(e.target.value)}} className='inputTextKyc' maxLength="10" style={{width:"60%", marginLeft:"50px"}} type="text" />
                        {/* {panVerify ? 
                            <button onClick={PanVerificationAPI} style={{display: (pancardNumber && pancardNumber.length === 10) ? "flex" : "none", marginLeft:"20px", border:"none", background:"#008c53", borderRadius:"10px", padding:"10px 20px", color:"#fff", fontWeight:"500"}}>Verify</button>
                        : null } */}
                    </div>
                    <div className='uploadDataKyc'>
                        <h2>Upload  Document </h2>
                        <div className='imgDivKyc'>
                            <Button style={{background:"none", boxShadow:"none"}} variant="contained" component="label">
                                <img src={imgUrlPancardF} style={{maxWidth:"120px", maxHeight:"70px"}} />
                                <input type="file" hidden accept="image/*" onChange={(e) => {setPancardFront(e.target.files[0])}} />
                            </Button>
                        </div>
                    </div>
                </div>

                <button onClick={() => {
                    if(kycgetcontact && kycgetcontact === null && adhaarNumber && adhaarNumber === null && pancardNumber && pancardNumber === null){
                        alert("Please verify one of the document")
                    } else {
                        UpdatedDataKycPatchApi()
                    }
                }
                } className='updateBtnKyc' style={{marginTop:"0px",marginBottom:"50px"}} >Update</button>                     
            </div>
        </div>
    )
}

export default Kyc