import Helper from "../util/Helper";
import axios from "axios";
import './Team.css'
import { useCookies } from "react-cookie";
import { Pagination } from "@mui/material"; 
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ProformaLeft = ({setProformaaddgrahak, skeletonLoading, setPerformabill, setGetCardBillId, setPerformatemp, showPerformaList}) => {

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()

    const [showgetBillList,setShowgetBillList]=useState(null)    
    const [minIndex, setMinIndex] = useState(0)
    const [maxIndex, setMaxIndex] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [countPage, setCountPage] = useState(1)
    const [active, setActive] = useState(null)
  
    const handlePagination = (pageNumber) => {
      setMinIndex((pageNumber - 1) * 10)
      setMaxIndex(pageNumber * 10)
      setCurrentPage(pageNumber)
    }

    useEffect(() => {
        setShowgetBillList(showPerformaList)
    },[showPerformaList])
    
    // useEffect(() => {
    //     const requestOptions = {
    //         method: "GET",
    //         url: Helper.getWsUrl() + `/vouchers/getBillsList/?performa=1&web=1`,
    //         headers: { "Content-Type": "application/json", token: cookies.token },
    //       };
    //       axios(requestOptions)
    //         .then((response) => {
    //             // setCookie("token", response.headers.token);
    //             if (response.data.code === 200) {                
    //                 setShowgetBillList(Helper.encypt_decrypt(response.data.data).billdetails);
    //             } else if (response.data.code === 401) {
    //                 removeCookie("logged_in")
    //                 removeCookie("token")
    //                 setTimeout(() => {
    //                     navigate('/')
    //                     localStorage.setItem("isAuthenticated", false)
    //                     localStorage.removeItem("isAuthenticated")
    //                 }, 1000)
    //               }
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
        
    // },[])
    
    useEffect(()=> {
        if(showgetBillList && showgetBillList.length > 0) {
          setCountPage(Math.ceil(showgetBillList.length / 10))
        }
      },[showgetBillList])

    return (
        <div className='billingLeft' style={{width: "40%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff"}} >
            <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <h6 style={{ fontWeight: "600",fontSize:"18px" }}>Performa Invoice</h6>
                <span>
                    <img src={require("../assets/images/Plus.png")} onClick={() => {setPerformatemp(true)}}
                        height={"24px"} width={"24px"} style={{cursor:"pointer"}} className="img-fluid" />
                </span>
            </div>
            {skeletonLoading ? 
                <div style={{height:"60vh", overflowY:"scroll", marginTop:"20px"}}>
                    <Skeleton count={4} borderRadius={"10px"} height={"17vh"} /> 
                </div>
            :
                <>
                    {showgetBillList && showgetBillList.length > 0 ?
                        <div style={{overflowY:"scroll", height:"60vh", marginTop:"20px"}}>
                            {showgetBillList && showgetBillList.map((value, index)=>{
                                return(
                                    index >= minIndex && index < maxIndex && (
                                        <div onClick={() => {setGetCardBillId(value.bill_id);}} style={{display:"flex", cursor:"pointer", marginBottom:"20px"}}>                                                
                                            <div id='card'  style={{ borderRadius: "5px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px", marginBottom: "20px", width: "100%", padding: "16px", display: "flex", }} className={`cardborder  ${active == index && 'active'}`} key={index} onClick={() => {setPerformabill(true); setActive(index); setProformaaddgrahak(false)}}  >
                                                <div style={{width: "70%"}} className="Proforma">
                                                    <p className='child mb-0' style={{ fontWeight: "500", fontSize: "16px" }}>{value.bill_number}</p>
                                                    <p className='child mb-0' style={{ fontWeight: "500", fontSize: "16px" }}>{value.Customer_mobile}</p>
                                                </div>
                                                <div style={{marginLeft: "5%", width: "45%", textAlign: "right"}} className="Proforma">
                                                    <p className='child mb-0' style={{ fontWeight: "500", fontSize: "16px" }}>₹ {value.total_amount}</p>
                                                    <p className='child mb-0 ml-n5' style={{ fontWeight: "500", fontSize: "16px",whiteSpace:"nowrap" }}>{value.bill_date}</p>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10%", width: "5%" }}>
                                                    {(index==active) ?
                                                        <img style={{ height: "20px", width: "8px" }} alt="" src={require("../assets/images/white_left_marker.png")} id={"vector"} className="img-fluid "></img>
                                                    :
                                                        <img style={{ height: "20px", width: "8px" }} alt="" src={require("../assets/images/vector.png")} id={"vector"} className="img-fluid "></img>
                                                    }                                                   
                                                </div>                             
                                            </div>
                                        </div>
                                    )
                                )
                            })}
                        </div>
                    :
                        <div style={{height:"60vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <p style={{color:"#7a7a7a", fontSize:"18px", fontWeight:"600"}}>no data found</p>
                        </div>
                    }
                </>
            } 
            <div className="pageCount" style={{marginTop:"15px"}}>
                <Pagination count={countPage} defaultPage={1} page={currentPage} onChange={(event, pageNumber) => handlePagination(pageNumber)}/>
            </div>                  
        </div>
    )

}
export default ProformaLeft;