import React, { useState } from "react";

const NewBilling = ({setShowAddGrahak, setShowViewBills}) => {

  return (
      <div style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
        <img src={require("../assets/images/billing 1.png")} class="img-fluid" />
        <button onClick={() => {
              if(localStorage.getItem("fastBilling") != 1) {
                setShowAddGrahak(true)
              } else {
                setShowViewBills(true)
              }}} 
             style={{backgroundColor: "#008C53",  color: "white",  borderRadius: "20px", padding: "7px 0px", marginTop:"40px", border: "1px solid #008C53"}}>
          <img height={"13%"} width={"13%"} class="img-fluid" src={require("../assets/images/icon.png")}/>
          <span style={{fontSize: "17px", marginLeft:"10px", fontWeight:"600"}}>Add New</span>
        </button>
      </div>
  );
};

export default NewBilling;

