import { React, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Helper from "../util/Helper";
import { useCookies } from "react-cookie";
import Button from '@mui/material/Button';
import S3 from 'react-aws-s3';
import OtpInput from "react-otp-input";

window.Buffer = window.Buffer || require("buffer").Buffer;


function Onboarding1() {

  const data = useLocation();
  const navigate = useNavigate()

  const copydata = { ...data.state };
  const [cookies, setCookie, removeCookie] = useCookies(["token"], ["logged_in"]);

  const [gstNumber,setGstNumber]=useState(null);
  const [adhaarNumber,setAdhaarNumber]=useState(null)
  const [pancardNumber,setPancardNumber]=useState(null)

  const [aadharverify, setAadharverify] = useState(false);
  const [panverify, setPanverify] = useState(false);
  const [gstverify, setGstverify] = useState(false);

  const [adhaarVerifyWithImg, setAdhaarVerifyWithImg] = useState(false)
  const [panVerifyWithImg, setPanVerifyWithImg] = useState(false)

  const [aadharFrontverify, setAadharFrontverify] = useState(false);
  const [aadharBackverify, setAadharBackverify] = useState(false);
  const [panFrontverify, setPanFrontverify] = useState(false);

  const [imgUrlAdhaarF, setImgUrlAdhaarF] = useState(null)
  const [imgUrlAdhaarB, setImgUrlAdhaarB] = useState(null)
  const [imgUrlPancardF, setImgUrlPancardF] = useState(null)
  const [adhaarFront, setAdhaarFront] = useState(null)
  const [adhaarBack, setAdhaarBack] = useState(null)
  const [pancardFront, setPancardFront] = useState(null)

  const [adhaarOtpField, setAdhaarOtpField] = useState(false)
  const [adhaarOtp, setAdhaarOtp] = useState(null)
  const [adhaarRefId, setAdhaarRefId] = useState(null)

  var payload = {
    gst_number: gstNumber,
    adhaar_number: adhaarNumber,
    pancard_number: pancardNumber,
  };

  useEffect(() => {
    // if(aadharverify === true && aadharFrontverify === true && aadharBackverify === true) {
    if(aadharFrontverify === true && aadharBackverify === true) {
      setAdhaarVerifyWithImg(true)
    }
  },[aadharverify, aadharFrontverify, aadharBackverify])

  useEffect(() => {
    if(panFrontverify === true) {
      // if(panverify === true && panFrontverify === true) {
      setPanVerifyWithImg(true)
    }
  },[panverify, panFrontverify])

  useEffect(() => {
    let url = require("../assets/images/Component 466.png")
    setImgUrlAdhaarF(url)
    setImgUrlAdhaarB(url)
    setImgUrlPancardF(url)
  },[])

  useEffect(() => {
    if(adhaarFront && adhaarFront != null) {
      if(adhaarNumber && adhaarNumber != null) {
        setImgUrlAdhaarF(URL.createObjectURL(adhaarFront))
        verifyAdhaarImg(adhaarFront)
      } else {
        alert("Please enter adhaar number first")
      }
    }  
  },[adhaarFront])

  useEffect(() => {
    if(adhaarBack && adhaarBack != null) {
      if(adhaarNumber && adhaarNumber != null) {
        setImgUrlAdhaarB(URL.createObjectURL(adhaarBack))
        verifyAdhaarImg(adhaarBack)
      } else {
        alert("Please enter adhaar number first")
      }
    }
  },[adhaarBack])

  useEffect(() => {
    if(pancardFront && pancardFront != null) {
      if(pancardNumber && pancardNumber != null) {
        setImgUrlPancardF(URL.createObjectURL(pancardFront))
        verifyPancardImg(pancardFront)
      } else {
          alert("Please enter PAN number first")
        }
    }
  },[pancardFront])

  const verifyAdhaarImg = (adhaarImg) => {
    var bodyformData = new FormData();
    bodyformData.append('file', adhaarImg );
    bodyformData.append('adhaar_number', adhaarNumber);
        const getItemListCatalougeUrl = {
            method: "POST",
            url: Helper.getWsUrl() + `/items/detect_safe_search_documents/adhaar?web=1`,
            headers: { "content-type": "multipart/form-data", 'token': cookies.token },
            data:bodyformData
        };
        axios(getItemListCatalougeUrl)
        .then((response) => {          
            if (response.data.code === 200) {
                alert(response.data.msg)  
                if(adhaarFront && adhaarFront != null) {
                  setAadharFrontverify(true)
                  let forAdhaarUrlFr = 'a_fr.png'
                  AWSCredentials(adhaarFront, forAdhaarUrlFr)
                }   
                if(adhaarBack && adhaarBack != null) {
                  setAadharBackverify(true)
                  let forAdhaarUrlB = 'a_ba.png'
                  AWSCredentials(adhaarBack, forAdhaarUrlB)
                }                       
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            } else if (response.data.code === 406) {
                alert(response.data.msg)
            } else if(response.data.code === 400) {
              alert(response.data.msg)
            }
        })
        .catch((error) => {
            alert(error);
        })
  }

  var bodyformData1 = new FormData();
  bodyformData1.append('file', pancardFront );
  bodyformData1.append('pan_number', pancardNumber);

  const verifyPancardImg = () => {
        const getItemListCatalougeUrl = {
            method: "POST",
            url: Helper.getWsUrl() + `/items/detect_safe_search_documents/pan?web=1`,
            headers: { "content-type": "multipart/form-data", 'token': cookies.token },
            data:bodyformData1
        };
        axios(getItemListCatalougeUrl)
        .then((response) => {           
            if (response.data.code === 200) {
                alert(response.data.msg)
                let urlPanFr = 'p_fr.png'
                AWSCredentials(pancardFront, urlPanFr)
                setPanFrontverify(true)                           
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            } else if (response.data.code === 406) {
                alert(response.data.msg)
            } else if(response.data.code === 400) {
              alert(response.data.msg)
            }
        })
        .catch((error) => {
            alert(error);
        })
  }

  var contactIdkyc = localStorage.getItem("p_id")
    
  const AWSCredentials = (editMerchantImg, imgKey) => {
    const requestOptions = {
        method: "GET",
        url: Helper.getWsUrl() + `/settings/get_aws_credientials/?web=1`,
        headers: { "Content-Type": "application/json", "token": cookies.token },
    };
    axios(requestOptions)
      .then((response) => {
          if(response.data.code === 200) {
            
              const imgFileKey = imgKey
              const FOLDER_NAME =  contactIdkyc+"/doc"
              const config = {
                  bucketName: Helper.encypt_decrypt(response.data.data).bucket_name,
                  region: "ap-south-1",
                  dirName: FOLDER_NAME,
                  accessKeyId: Helper.encypt_decrypt(response.data.data).aws_access_key,
                  secretAccessKey: Helper.encypt_decrypt(response.data.data).aws_secret_access_key,
                  s3Url: 'https://enbfile.s3.ap-south-1.amazonaws.com',
              }
              const ReactS3Client = new S3(config)
              ReactS3Client
                  .uploadFile(editMerchantImg, imgFileKey)
                  .then(data => console.log("data : ",data))
                  .catch(err => console.error("error",err))
          }
      }
    );
  }

  const submitbtn = () => {
    if (adhaarVerifyWithImg===false && panVerifyWithImg===false && gstNumber===null) {
      // if (adhaarVerifyWithImg===false && panVerifyWithImg===false && gstverify===false) {
      alert("Please verify atleast one document fully")
    } else {
      if (adhaarVerifyWithImg === false) {
        setAdhaarNumber(null);
      }
      if (panVerifyWithImg === false) {
        setPancardNumber(null);
      }
      if (gstverify === false) {
        setGstNumber(null);
      }

      const requestOptions = {
        method: "PATCH",
        url: Helper.getWsUrl() + `/contact/patch_contact/?web=1`,
        data: payload,
        headers: { "Content-Type": "application/json", Token: cookies.token },
      };
      axios(requestOptions)
        .then((response) => {
          if (response.data.code === 201) {
            let path = `/dashboard`;
            setCookie("logged_in", "yes");
            navigate(path, { state: { Token: cookies.token } });
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const verfiyAdhaarNumber = () => {
    const Reuest = {
      url: Helper.getWsUrl() + "/kyc/send_otp_to_aadhar/?web=1",
      method: "POST",
      headers: { "Content-Type": "application/json", Token: cookies.token },
      data: { aadhar_number: adhaarNumber && adhaarNumber },
    };
    axios(Reuest)
      .then((response) => {
        if (response.data.code === 200) {
          alert(response.data.msg);
          setAdhaarRefId(Helper.encypt_decrypt(response.data.data).ref_id)
          setAdhaarOtpField(true)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } else {
          alert(response.data.msg);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const verfiyAdhaarNumberOtp = () => {
    const Reuest = {
      url: Helper.getWsUrl() + "/kyc/match_otp_to_aadhar/?web=1",
      method: "POST",
      headers: { "Content-Type": "application/json", Token: cookies.token },
      data: { 
        ref_id: adhaarRefId && adhaarRefId,
        otp: adhaarOtp && adhaarOtp 
      },
    };
    axios(Reuest)
      .then((response) => {
        if (response.data.code === 200) {
          alert("Adhaar Verified");
          setAadharverify(true);
          setAdhaarOtpField(false)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } else {
          alert(response.data.msg);
          setAadharverify(false);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const verfiyGSTNumber = () => {
    const Reuest = {
      url:
        Helper.getWsUrl() +
        `/contact/gst_varification/?gstNo=${gstNumber}&web=1`,
      method: "GET",
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(Reuest)
      .then((response) => {
        if (response.data.code === 200) {
          alert(response.data.msg);
          setGstverify(true);
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } else {
          alert(response.data.msg);
          setGstverify(false);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const verfiyPancardNumber = () => {
    const Reuest = {
      url: Helper.getWsUrl() + "/kyc/verify_pancard/?web=1",
      method: "POST",
      headers: { "Content-Type": "application/json", Token: cookies.token },
      data: {
        pan_number:pancardNumber && pancardNumber 
      },
    };
    axios(Reuest)
      .then((response) => {
        if (response.data.code === 200) {
          // console.log('data :', Helper.encypt_decrypt(response.data.data))
          alert("Pancard Verified");
          setPanverify(true);
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } else {
          alert(response.data.msg);
          setPanverify(false);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const otpDivStyle = {
    justifyContent:"center",
    alignItems:"center",
  }
  const otpInputStyle = {
    height: "40px",
    width: "40px",
    borderRadius: "10px",
    marginLeft:"10px",
    border:"1px solid #D9D9D9",
    backgroundColor:"#D9D9D9"
  }
  const otpInputFocusStyle = {
    border:"1px solid #d9d9d9"
  }
  const boolean = {
    type: "number"
  }

  return (
        <div className="row" style={{height:"100vh"}}>
          <div className="col-3">
            <img
              src={require("../assets/images/side_bar.png")}
              className="img-fluid"
              style={{ height: "100%" }}
              alt=""
            />
          </div>
          <div className="col-9 mb-5 text-center">
            <div className="row mt-5">
              <div className="col-1">
                <button
                  type="button"
                  className="btn btn-sm btn-success rounded-3"
                  style={{ width: "2rem", height: "2rem" }}
                >
                  3
                </button>
              </div>
              <div className="col-5 text-start">
                <h3>Varification</h3>
              </div>
            </div>
            <hr style={{ height: "2px", width: 900, borderWidth: 0 }}></hr>
            <div className="row">
              <div className="col text-start">
                <p className="text-gray-700">KYC Details</p>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <h3>GST</h3>
              </div>
              <div className="col-1"></div>

              <div className="col-5">
                <div style={{display:"flex"}}>
                  <input
                    type="text"
                    autoComplete="off"
                    disabled = {!gstverify ? "" : "disable"}
                    name="gst_number"
                    className="form-control form-control-lg rounded-3"
                    placeholder="Enter GST Number"
                    style={{textTransform: "uppercase"}}
                    // value={kyc.gst_number}
                    onChange={(e) => {setGstNumber(e.target.value)}}
                    id="gst"
                    maxlength={15}
                    // onClick={GstVerification}
                    pattern="/^[a-zA-Z]+$/;"
                    required
                  />
                  {/* <button onClick={() => {verfiyGSTNumber()}} style={{display: !gstverify ? (gstNumber && gstNumber.length === 15) ? "block" : "none" : "none", marginLeft:"10px", color:"#fff", background:"#008c53", border:"none", fontWeight:"600", borderRadius:"10px", padding:"0 15px"}}>Verify</button> */}
                </div>
              </div>
              <div className="col-2"></div>
            </div>            

            <div className="row mt-5 mb-3">
              <div className="col-4">
                <h3>AADHAAR</h3>
              </div>
              <div className="col-1"></div>

              <div className="col-5" >
                <div style={{display:"flex"}}>
                  <input
                    type="text"
                    autoComplete="off"
                    disabled={!aadharverify ? "" : "disable"}
                    name="adhaar_number"
                    className="form-control form-control-lg rounded-3"
                    placeholder="Enter Number"
                    style={{textTransform: "uppercase"}}
                    // value={kyc.adhaar_number}
                    onChange={(e) => {setAdhaarNumber(e.target.value)}}
                    id="adhaar_number"
                    maxlength={12}
                    // onClick={AdharVerification}
                    pattern="/^[a-zA-Z]+$/;"
                    required
                  />
                  {/* <button onClick={() => {verfiyAdhaarNumber()}} style={{display: !aadharverify ? !adhaarOtpField ? (adhaarNumber && adhaarNumber.length === 12) ? "block" : "none" : "none" : "none", marginLeft:"10px", color:"#fff", background:"#008c53", border:"none", fontWeight:"600", borderRadius:"10px", padding:"0 15px"}}>Verify</button> */}
                </div>
                <div style={{display: !adhaarOtpField ? "none" : "flex", marginTop:"10px"}}>
                  <OtpInput
                  shouldAutoFocus={true}
                  containerStyle = {otpDivStyle}
                  inputStyle = {otpInputStyle}
                  focusStyle = {otpInputFocusStyle}
                  value={adhaarOtp}
                  isInputNum = {boolean}
                  onChange={(otp) => {setAdhaarOtp(otp)}}
                  numInputs={6}/>
                  <button disabled={(adhaarOtp && adhaarOtp.length) === 6 ? '' : 'disable'} onClick={() => {verfiyAdhaarNumberOtp()}} style={{marginLeft:"30px", color:"#fff", background:"#008c53", border:"none", fontWeight:"600", borderRadius:"10px", padding:"0 15px"}}>Done</button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4  text-start">Upload Adhaar Document</div>
              <div className="col-1 "></div>
              <div className="col-7 text-start">
                <div class="verification-main-input-div">
                  <div id="row-of-product-photos" class="row-of-id-photos">
                    <div class="two1" id="addImgLabel1">
                      <label for="addImg1" class="input-label inputLabelCss">
                        <div class="photosvg">
                          <Button style={{background:"none", boxShadow:"none"}} variant="contained" component="label">
                              <img src={imgUrlAdhaarF} style={{maxWidth:"120px", maxHeight:"70px"}} />
                              <input type="file" hidden accept="image/*" onChange={(e) => {setAdhaarFront(e.target.files[0])}} />
                          </Button>
                          <Button style={{background:"none", boxShadow:"none"}} variant="contained" component="label">
                              <img src={imgUrlAdhaarB} style={{maxWidth:"120px", maxHeight:"70px"}} />
                              <input type="file" hidden accept="image/*" onChange={(e) => {setAdhaarBack(e.target.files[0])}} />
                          </Button>
                        </div>
                      </label>
                      <input
                        id="addImg1"
                        name="addImg1"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        style={{ display: "none" }}
                        multiple=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5 mb-3">
              <div className="col-4">
                <h3>PANCARD</h3>
              </div>
              <div className="col-1"></div>

              <div className="col-5">
                <div style={{display:"flex"}}>
                  <input
                    type="text"
                    autoComplete="off"
                    name="pancard_number"
                    disabled={!panverify ? "" : "disable"}
                    className="form-control form-control-lg rounded-3"
                    placeholder="Enter Number"
                    style={{textTransform: "uppercase"}}
                    // value={kyc.pancard_number}
                    onChange={(e) => {setPancardNumber(e.target.value)}}
                    id="pancard"
                    maxlength={10}
                    // onClick={PanVerification}
                    pattern="/^[a-zA-Z]+$/;"
                    required
                  />
                  {/* <button onClick={() => {verfiyPancardNumber()}} style={{display: !panverify ? (pancardNumber && pancardNumber.length === 10) ? "block" : "none" : "none", marginLeft:"10px", color:"#fff", background:"#008c53", border:"none", fontWeight:"600", borderRadius:"10px", padding:"0 15px"}}>Verify</button> */}
                </div>
              </div>
              <div className="col-2"></div>
            </div>

            <div className="row mt-3 mb-5">
              <div className="col-4 text-start">Upload PAN Document</div>
              <div className="col-1"></div>
              <div className="col-7 text-start">
                <div class="verification-main-input-div">
                  <div id="row-of-product-photos" class="row-of-id-photos">
                    <div class="two1" id="addImgLabel1">
                      <label for="addImg1" class="input-label inputLabelCss">
                        <div class="photosvg">
                          <Button style={{background:"none", boxShadow:"none"}} variant="contained" component="label">
                              <img src={imgUrlPancardF} style={{maxWidth:"120px", maxHeight:"70px"}} />
                              <input type="file" hidden accept="image/*" onChange={(e) => {setPancardFront(e.target.files[0])}} />
                          </Button>
                        </div>
                      </label>
                      <input
                        id="addImg1"
                        name="addImg1"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        style={{ display: "none" }}
                        multiple=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div style={{display:"flex", justifyContent:"space-between"}}>
                <button
                  type="button"
                  class="btn btn-outline-success rounded-pill me-4"
                  onClick={() => {
                    let path = `/dashboard`;
                    setCookie("logged_in", "yes");
                    navigate(path, { state: { Token: cookies.token } });
                  }}
                  style={{
                    color: "rgb(0, 140, 83, 1)",
                    background: "none",
                    width: "175px",
                  }}
                >
                  Skip
                </button>

                <button
                  type="button"
                  class="btn btn-outline-secondary rounded-pill"
                  style={{ color: "#fff", background:"#008c53", border:"none", width: "175px" }}
                  onClick={submitbtn}
                >
                  Continue
                </button>
              </div>
            {/* </div> */}
          </div>
        </div>
    //   </div>
    // </div>
  );
}

export default Onboarding1;
