import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Helper from "../util/Helper";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const CatalougeFirstTimeUser = ({setShowListedProduct}) =>{

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const [selectCsvFile, setSelectCsvFile] = useState(null)
    const [masterCategoryList, setMasterCategoryList] = useState([])
    const [showProgressToUploadCSV, setShowProgressToUploadCSV] = useState(false)

    var bodyformData = new FormData();
    bodyformData.append('file', selectCsvFile );
    bodyformData.append('fileName', selectCsvFile && selectCsvFile.name);

    useEffect(() => {
        if(selectCsvFile && selectCsvFile != null) {
            setShowProgressToUploadCSV(true)
            const getItemListCatalougeUrl = {
                method: "POST",
                url: Helper.getWsUrl() + `/items/add_item_data_by_file/?web=1`,
                headers: { "content-type": "multipart/form-data", 'token': cookies.token },
                data:bodyformData
            };
            axios(getItemListCatalougeUrl)
            .then((response) => {          
                if (response.data.code === 201) {
                    window.location.reload();                             
                    setShowProgressToUploadCSV(false)
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else if (response.data.code === 400) {
                    alert(response.data.msg)
                }
            })
            .catch((error) => {
                alert(error);
            })
        }

        const requestCategoryOption = {
            method: "GET",
            url: Helper.getWsUrl() + `/items/get_merchant_categories_for_magicupload_new/?magic=1&web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(requestCategoryOption)
            .then((response) => {       
                if (response.data.code === 200) {
                    // console.log("axsdsdsds",Helper.encypt_decrypt(response.data.data))
                    setMasterCategoryList(Helper.encypt_decrypt(response.data.data))
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                }
            })
            .catch((error) => {
                alert(error);
            });
    },[selectCsvFile])

    const downloadCatelogueCsv = () => {
        const getItemListCatalougeUrl = {
            method: "GET",
            url: Helper.getWsUrl() + `/masters/get_csv_file_creation_instructions/?web=1`,
            headers: { "content-type": "multipart/form-data", 'token': cookies.token },
        };
        axios(getItemListCatalougeUrl)
        .then((response) => {       
            if (response.data.code === 200) {
                window.open(Helper.encypt_decrypt(response.data.data).url, '_blank', 'noopener,noreferrer')                  
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            } else if (response.data.code === 400) {
                alert(response.data.msg)
            }
        })
        .catch((error) => {
            alert(error);
        })
    }

    return(
        <div style={{backgroundColor:"#fff",display:"flex",justifyContent:"center",textAlign:"center",flexDirection:"column", width:"100%", borderRadius:"20px"}}>
            <div>
                <img src={require("../assets/images/catalougeAdd.png")} style={{marginTop:"10%"}} class="img-fluid" />
            </div>
            <div>
                <p style={{color:"#8C8C8C",fontWeight:"600"}}>You’re just a step away from creating an online dukaan.</p>
            </div>
            <div style={{width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                <button onClick={() => {navigate("/Catalogue/add_new_item")}} style={{ backgroundColor: "#008C53",  color: "white", borderRadius: "25px",  padding: "5px 15px", border: "1px solid #008C53 "}} >
                    <img src={require("../assets/images/icon.png")} height={"10%"}  width={"10%"} style={{ position: "relative", top: "-2%", left: "-20%" }} class="img-fluid" />
                    <span style={{ fontSize: "17px" }}>Add New</span>
                </button>
                <div style={{marginTop:"15px", display:"flex", alignItems:"center"}}>
                    <Button disabled={!showProgressToUploadCSV ? "" : "disable"} style={{color:"#008c53", textTransform:"capitalize", padding:"5px 10px", border:"none", borderRadius:"10px", background:"none",fontSize:"12px"}} variant="contained" component="label">
                        <UploadFileIcon sx={{marginRight:"5px"}} /> 
                        Upload CSV File
                        <input type="file" hidden accept="csv" onChange={(e) => {setSelectCsvFile(e.target.files[0])}}/>
                    </Button>

                    {!showProgressToUploadCSV ? 
                        null
                    :
                        <Stack sx={{ color: 'grey.300' }} style={{ width: '25px', height: '25px', marginLeft:"5px" }} spacing={2} direction="row">
                            <CircularProgress color="success" style={{ width: '25px', height: '25px' }} />
                        </Stack>
                    }

                    <Button onClick={() => {downloadCatelogueCsv()}} style={{color:"#008c53", marginLeft:"20px", textTransform:"capitalize", padding:"5px 10px", border:"none", borderRadius:"10px", background:"none",fontSize:"12px"}} variant="contained" component="label">
                        <FileDownloadOutlinedIcon sx={{marginRight:"5px"}} />
                        Download CSV format
                    </Button>
                </div>

                {masterCategoryList && masterCategoryList.length > 0 && masterCategoryList[0].name === "Daily Needs" ? 
                    <Button onClick={() => {setShowListedProduct(true)}}  style={{color:"#008c53", padding:"5px 10px", marginTop:"15px", border:"none", borderRadius:"10px", background:"none",fontSize:"12px"}} variant="contained" component="label">
                        <img src={require('../assets/images/Product-Listing.png')} style={{width:"23px", marginRight:"5px"}} />
                        Listed Product
                    </Button>
                : null }
            </div>        
        </div>
    )
}

export default CatalougeFirstTimeUser;