import ProformaLeft from "./ProformaLeft";
import ProformaAddNew from "./ProformaAddNew";
import ProformaBill from "./ProformaBill";
import ProformaViewBills from "./ProformaViewBills";
import ProformaTemplate from "./ProformaTemplate";
import ProformaInvoiceEdit from "./ProformaInvoiceEdit";
import ProformaAddGrahak from "./ProformaAddGrahak";
import { useState ,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import ProformaNewUser from "./ProformaNewUser";
import Helper from "../util/Helper";
import { useCookies } from "react-cookie";
import axios from "axios";

const Proformainvoice = ({setTtlAmtAllItemPitB, setGrahakDtlPitB, setBillIdPitB}) => {

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const [performabill, setPerformabill] = useState(false);
    const [performatemp, setPerformatemp] = useState(false);
    const [performaviewbill, setPerformaviewbill] = useState(false);
    const [invoiceedit,setInvoiceedit]=useState(false);
    const [getCardBillId, setGetCardBillId]=useState(null)
    const [getPath, setGetPath]=useState(null)
    const [getPdf, setGetPdf]=useState(null);
    const [proformaaddgrahak,setProformaaddgrahak]=useState(false)
    const [showbillid,setShowbillid] = useState(null);
    const [grahakDetails, setGrahakDetails] = useState(null)
    const [getTotalAmountForAllItem, setGetTotalAmountForAllItem] = useState(null)
    const [tempId, setTempId] = useState(null)
    const [totalAmtFrmPerformaBillDtl, setTotalAmtFrmPerformaBillDtl] = useState(null)
    const [custIdForEdit, setCustIdForEdit] = useState(null)
    const [itemForEdit, setItemForEdit] = useState([])
    const [pidForEdit, setPidForEdit] = useState(null)

    const[showPerformaList, setShowPerformaList] = useState([])
    const[performaEmptystate, setPerformaEmptyState] = useState(false)
    const[skeletonLoading, setSkeletonLoading] = useState(true)

    useEffect(() => {
        if(grahakDetails && grahakDetails != null) {
            setGrahakDtlPitB(grahakDetails)
        }
        if(getTotalAmountForAllItem && getTotalAmountForAllItem != null) {
            setTtlAmtAllItemPitB(getTotalAmountForAllItem)
        }
        if(totalAmtFrmPerformaBillDtl && totalAmtFrmPerformaBillDtl != null) {
            setTtlAmtAllItemPitB(totalAmtFrmPerformaBillDtl)
            navigate('/Billing')
        }
    },[totalAmtFrmPerformaBillDtl, grahakDetails, getTotalAmountForAllItem])
    
    useEffect(() => {
        if(grahakDetails && grahakDetails !==null){
            setProformaaddgrahak(grahakDetails)
        }     
    }, [grahakDetails && grahakDetails])

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/vouchers/getBillsList/?performa=1&web=1`,
            headers: { "Content-Type": "application/json", token: cookies.token },
          };
          axios(requestOptions)
            .then((response) => {
                // setCookie("token", response.headers.token);
                if (response.data.code === 200) {                
                    console.log("list ",Helper.encypt_decrypt(response.data.data).billdetails);
                    setShowPerformaList(Helper.encypt_decrypt(response.data.data).billdetails);
                    setPerformaEmptyState(false)
                    setSkeletonLoading(false)
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else if(response.data.code === 204) {
                    setPerformaEmptyState(true)
                    setSkeletonLoading(false)
                } 
            })
            .catch((err) => {
              console.log(err);
            });
        
    },[])
    
    return (   
        <>     
        {invoiceedit ? 
            <ProformaInvoiceEdit setBillIdPitB = {setBillIdPitB} showbillid = {showbillid} setPerformabill = {setPerformabill} setProformaaddgrahak = {setProformaaddgrahak} setPerformatemp = {setPerformatemp} setPerformaviewbill = {setPerformaviewbill} setInvoiceedit = {setInvoiceedit} getPdf={getPdf}/>
        :
            <> 
                {performaviewbill ? 
                    <ProformaViewBills getCardBillId = {getCardBillId} pidForEdit = {pidForEdit} itemForEdit = {itemForEdit} custIdForEdit = {custIdForEdit} tempId = {tempId} setShowbillid = {setShowbillid} setGetTotalAmountForAllItem = {setGetTotalAmountForAllItem} grahakDetails = {grahakDetails} setInvoiceedit={setInvoiceedit} setGetPath={setGetPath} setGetPdf={setGetPdf} />
                :
                    <>
                        {performatemp ?
                            <ProformaTemplate setTempId = {setTempId} setProformaaddgrahak = {setProformaaddgrahak} setPerformatemp={setPerformatemp} ></ProformaTemplate> 
                        :
                            <div className="col-10" style={{  backgroundColor: "rgb(245, 245, 245)", padding: "20px", display: "flex" }}>
                                {performaEmptystate ? 
                                    <ProformaNewUser setPerformaEmptyState={setPerformaEmptyState} setPerformatemp={setPerformatemp} />
                                :
                                    <>
                                        <ProformaLeft skeletonLoading = {skeletonLoading} showPerformaList = {showPerformaList} setProformaaddgrahak = {setProformaaddgrahak} setPerformatemp={setPerformatemp} setGetCardBillId = {setGetCardBillId} setPerformabill={setPerformabill}></ProformaLeft>
                                        {proformaaddgrahak ? 
                                            <ProformaAddGrahak setGrahakDetails = {setGrahakDetails} setPerformaviewbill={setPerformaviewbill} ></ProformaAddGrahak>
                                        :
                                            <>
                                                {performabill ?
                                                    <ProformaBill setPidForEdit = {setPidForEdit} setItemForEdit = {setItemForEdit} setCustIdForEdit = {setCustIdForEdit} setTotalAmtFrmPerformaBillDtl = {setTotalAmtFrmPerformaBillDtl} setBillIdPitB = {setBillIdPitB} setPerformatemp ={setPerformatemp} getCardBillId = {getCardBillId} getPath = {getPath} getPdf = {getPdf} performaviewbill={performaviewbill} setPerformaviewbill={setPerformaviewbill} setPerformabill={setPerformabill} performabill={performabill}></ProformaBill>
                                                :
                                                    <ProformaAddNew setPerformatemp={setPerformatemp} ></ProformaAddNew>
                                                }
                                            </>
                                        }
                                    </>
                                }                      
                            </div>                 
                        }
                    </>
                }
            </>
        }
        </>
    )
}
   export default Proformainvoice;