import "./Addgrahak.css";
import React, { useEffect, useState } from "react";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

function UpdateGrahak({ setGrahakDtlFrmGTB, getGrahakId, globalCustomerId, categoryValForDtl }) {

  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [getGrahakDetails, setGetGrahakDetails] = useState(null);
  const [grahakIdForApi, setGrahakIdForApi] = useState(null);
  const [gstEnable, setGstEnable] = useState(false);
  const [grahakimg, setGrahakimg] = useState("");
  const [grahakurl, setGrahakurl] = useState("");
  const [showcategory, setShowcategory] = useState(false);
  const [addCategory, setAddcategory] = useState(false);
  const[categoryValue,setCategoryValue]=useState(null);
  const[categoryInput,setCategoryInput]=useState(null);
  const [categoryId, setCategoryId] = useState(null)
  const [categoryList, setCategoryList] = useState([])

  useEffect(() => {
    if (getGrahakId && getGrahakId != null) {
      setGrahakIdForApi(getGrahakId);
    }
  }, [getGrahakId]);

  useEffect(() => {
    if (globalCustomerId && globalCustomerId != null) {
      setGrahakIdForApi(globalCustomerId);
    }
  }, [globalCustomerId]);

  const updateGrahakName = (e) => {
    setGetGrahakDetails({
      ...getGrahakDetails,
      name: e.target.value,
    });
  };

  const updateGrahakGst = (e) => {
    setGetGrahakDetails({
      ...getGrahakDetails,
      gst_number: e.target.value,
    });
  };

  const updateGrahakAddress = (e) => {
    setGetGrahakDetails({
      ...getGrahakDetails,
      address: e.target.value,
    });
  };

  useEffect(() => {
    if(categoryValForDtl && categoryValForDtl != null && categoryValForDtl != "--NA--") {
      setCategoryValue(categoryValForDtl)
    }
  },[categoryValForDtl])

  useEffect(() => {
    if (grahakIdForApi && grahakIdForApi != null) {
      const getGrahakListDetailUrl = {
        method: "GET",
        url: Helper.getWsUrl() + `/contact/get_contact/${grahakIdForApi}/?web=1`,
        headers: { "Content-Type": "application/json", Token: cookies.token },
      };
      axios(getGrahakListDetailUrl)
        .then((response) => {
          // setCookie("token", response.headers.token);
          if (response.data.code === 200) {
            setGetGrahakDetails(Helper.encypt_decrypt(response.data.data));
            setGrahakimg(Helper.encypt_decrypt(response.data.data).c_logo);
            setGrahakurl(Helper.encypt_decrypt(response.data.data).c_logo_url);
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, [grahakIdForApi]);

  useEffect(() => {
    if (getGrahakDetails && getGrahakDetails.gst_number === null) {
      setGstEnable(true);
    } else if(getGrahakDetails && getGrahakDetails.gst_number.length === 15) {
      setGstEnable(false)
    }
  }, [getGrahakDetails]);

  const getCategorylistFunc = () => {
    const addCustomerRequest = {
      method: "GET",
      url: Helper.getWsUrl() + "/contact/get_customer_category/?web=1",
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(addCustomerRequest)
      .then((response) => {
        if (response.data.code === 200) {
          // console.log("response : ", response.data)
          setCategoryList(Helper.encypt_decrypt(response.data.data))
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
      })
      .catch((error) => {
        alert(error);
      });
  }

  const updateGrahakDetailFunc = () => {
    const updateGrahakUrl = {
      method: "PATCH",
      url: Helper.getWsUrl() + `/contact/update_customer/${grahakIdForApi}/?web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
      data: {
        address: getGrahakDetails.address,
        gst: getGrahakDetails.gst_number != null && getGrahakDetails.gst_number != "" ? 1 : 0,
        gst_number: getGrahakDetails.gst_number != null && getGrahakDetails.gst_number != "" ? getGrahakDetails.gst_number : null ,
        gst_type: getGrahakDetails.gst_number != null && getGrahakDetails.gst_number != "" ? 1 : 0,
        logo: 0,
        name: getGrahakDetails.name,
        customer_category:categoryId,
      },
    };
    axios(updateGrahakUrl)
      .then((response) => {
        // setCookie("token", response.headers.token);
        if (response.data.code === 200) {
          window.location.reload();
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const deleteGrahakDetailFunc = () => {
    const deleteGrahakUrl = {
      method: "DELETE",
      url: Helper.getWsUrl() + `/contact/remove_customer/${grahakIdForApi}/?web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(deleteGrahakUrl)
      .then((response) => {
        // setCookie("token", response.headers.token);
        if (response.data.code === 200) {
          window.location.reload();
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
  if (grahakimg === 1) {
    var url = { grahakurl };
  } else {
    var url = require("../assets/images/enbill_logo.png");
  }

  const setGrahakDetailFunc = () => {
    setGrahakDtlFrmGTB(getGrahakDetails)
  }

  const categoryPostFunc = () => {
    const addCustomerRequest = {
      method: "POST",
      url: Helper.getWsUrl() + "/contact/add_customer_category/?web=1",
      headers: { "Content-Type": "application/json", Token: cookies.token },
      data:{
        category_name: categoryInput,
      }
    };
    axios(addCustomerRequest)
      .then((response) => {
        if (response.data.code === 201) {
          // console.log("response : ", response.data)
          setCategoryId(Helper.encypt_decrypt(response.data.data).cc_id)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <div
      id="box"
      style={{
        width: "60%",
        height: "100%",
        borderRadius: "20px",
        padding: "20px",
        backgroundColor: "#fff",
        marginLeft: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <img
        onClick={() => deleteGrahakDetailFunc()}
        src={require("../assets/images/delete icon.png")}
        height={"4%"}
        width={"4%"}
        style={{
          position: "absolute",
          right: "5%",
          top: "4%",
          cursor: "pointer",
        }}
        class="img-fluid"
      ></img>

      <form
        style={{
          width: "90%",
         
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="form user-input-wrp" style={{ position: "relative", width: "70%" }}>
          <input
            value={getGrahakDetails && getGrahakDetails.name != null ? getGrahakDetails.name : ""}
            onChange={(e) => updateGrahakName(e)}
            autocomplete="off"
            type="text"
            placeholder=""
            className="inputText"
            style={{
              fontSize: "18px",
              borderRadius: "10px",
              outline: "none",
              width: "100%",
              marginBottom: "20px",
            }}
          />
         <p style={{ position: "absolute", bottom: "60%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Grahak Name*</p> 
        </div>
        <div className="form user-input-wrp" style={{ position: "relative", width: "70%" }}>
          <input
            disabled
            value={getGrahakDetails && getGrahakDetails.mobile_number}
            type="text"
            autocomplete="off"
            placeholder=""
            className="inputText"
            style={{
              fontSize: "18px",
              borderRadius: "10px",
              outline: "none",
              width: "100%",
              marginBottom: "20px",
            }}
          />
         <p style={{ position: "absolute", bottom: "60%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Contact Number</p> 
        </div>
        <div className="form user-input-wrp" style={{ position: "relative", width: "70%" }}>
          <input
            value={getGrahakDetails && getGrahakDetails.address != null ? getGrahakDetails.address : ""}
            onChange={(e) => updateGrahakAddress(e)}
            type="text"
            autocomplete="off"
            placeholder=""
            className="inputText"
            style={{
              fontSize: "18px",
              borderRadius: "10px",
              outline: "none",
              width: "100%",
              marginBottom: "20px",
            }}
          />
        <p style={{ position: "absolute", bottom: "60%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Grahak Address</p> 
        </div>
        <div className="form user-input-wrp" style={{ position: "relative", width: "70%" }}>
          <input
            value={getGrahakDetails && getGrahakDetails.gst_number != null ? getGrahakDetails.gst_number : ""}
            onChange={(e) => updateGrahakGst(e)}
            type="text"
            maxLength={15}
            disabled={gstEnable ? "" : "disable"}
            autocomplete="off"
            placeholder=""
            className="inputText"
            style={{
              fontSize: "18px",
              borderRadius: "10px",
              outline: "none",
              width: "100%",
              marginBottom: "20px",
            }}
          />
         <p style={{ position: "absolute", bottom: "60%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>GST Number(optional)</p> 
        </div>
        <div style={{ display: "flex", width: "68%" }}>
            {categoryValue && categoryValue !=null ? 
              <button onClick={() => {setCategoryValue(null); setAddcategory(false); setCategoryInput(null)}} style={{ backgroundColor: "white", color: "#7a7a7a", border: "1px solid #B6BDC7", fontWeight: "500", fontSize: "12px", borderRadius: "20px", padding:"5px 8px", width: "max-content", margin: "10px", display:"flex" }}>
                {categoryValue}
                <img style={{marginLeft:"5px"}} src={require("../assets/images/Global icons.png")} class="img-fluid" />
              </button> 
            :
              <div onClick={() => {setShowcategory(!showcategory); getCategorylistFunc()}} style={{ display: "flex" }}>
                <div style={{ cursor: "pointer" }}>
                  <img src={require("../assets/images/addCategory.png")} class="img-fluid" />
                </div>
                <div>
                  <p style={{ color: "#2B2B2B", fontWeight: "500", fontSize: "14px", paddingTop: "4px", paddingLeft: "8px", cursor: "pointer" }}>Add Category</p>
                </div>
              </div>
            } 
          </div>
      </form>
      {showcategory ?
          <div style={{ marginTop: "5%", width: "70%", textAlign: "center" }}>
            {addCategory ?
              <div>
                <input  onChange={(event)=>{setCategoryInput(event.target.value)}}
                  type="text" autoComplete="off" placeholder="Category" className="textbox" style={{ fontSize: "18px", borderRadius: "10px", outline: "none", width: "80%", marginBottom: "20px", border: "1px solid #B6BDC7" }} />
                <div>
                  <button onClick={()=>setAddcategory(false)} style={{ backgroundColor: "white", color: "#008C53", borderRadius: "24px", border: "none", fontWeight: "600", fontSize: "16px", border: "1px solid #008C53", height: "40px", width: "100px" }} >
                    Cancel
                  </button>
                  <button onClick={() => {setCategoryValue(categoryInput); setShowcategory(false); categoryPostFunc();}} style={{ backgroundColor: "#008C53", color: "white", borderRadius: "24px", border: "none", fontWeight: "600", fontSize: "16px", height: "40px", width: "100px", marginLeft: "20px" }} >
                    OK
                  </button>
                </div>
              </div> 
            : 
              <div>
                {categoryList && categoryList.map((value, index) => {
                  return(
                    <button onClick={() => {setCategoryValue(value.category_name); setCategoryId(value.cc_id); setShowcategory(false)}} style={{ backgroundColor: "white", color: "#7a7a7a", border: "1px solid #B6BDC7", fontWeight: "500", fontSize: "12px", borderRadius: "20px", width: "max-content", padding:"5px 8px", margin: "10px" }}>
                      {value.category_name}
                    </button>
                  )
                })}
                {categoryList && categoryList.length >= 10 ?
                  null
                :
                 <button onClick={() => {setAddcategory(true)}} style={{ backgroundColor: "white", color: "#008c53", border: "1px solid #008c53", fontWeight: "500", fontSize: "12px", borderRadius: "20px", width: "80px", height: "35px", margin: "10px" }}>Add More</button> 
                }
                 </div>
            }
          </div>
        : null}
      <span>
        <button
          onClick={() => updateGrahakDetailFunc()}
          className="mt-5"
          style={{
            backgroundColor: "#008C53",
            color: "white",
            padding:"5px 20px",
            borderRadius: "20px",
            position: "absolute",
            left: "32%",
            border: "none",
          }}
        >
          Update
        </button>
        <button onClick={() => {setGrahakDetailFunc(); navigate("/Billing");}}
          className="mt-5"
          style={{
            backgroundColor: "#008C53",
            color: "white",
            padding:"5px 20px",
            borderRadius: "20px",
            position: "absolute",
            left: "52%",
            border: "none",
          }}
        >
          Make Bill
        </button>
      </span>
    </div>
  );
}

export default UpdateGrahak;
