import OtpInput from "react-otp-input";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const TeamGetOTP = ({setOtppage,otppage,setAddmember}) => {

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate()

  const [datawrite,setDatawrite]=useState(null)
  const[datawriteTeamMemberName,setDatawriteTeamMemberName]=useState(null);
  const[teamotp,setTeamotp]=useState(0)
  const[otp,setOtp]=useState(false);
  const[teamRoleList, setTeamRoleList] = useState([])
  const[teamMemberRole, setTeamMemberRole] = useState(null)

  const otpDivStyle = {
    justifyContent:"center",
    alignItems:"center"
  }
  const otpInputStyle = {
    height: "40px",
    width: "40px",
    borderRadius: "15px",
    marginLeft:"10px",
    border:"1px solid #D9D9D9",
    backgroundColor:"#D9D9D9"
  }
  const otpInputFocusStyle = {
    border:"1px solid #d9d9d9"
  }
  const boolean = {
    type: "number"
  }

  const handleChange1=(event)=>{
    setDatawriteTeamMemberName(event.target.value)
  }

  useEffect(() => {
    const requestForRole = {
      method: "GET",
      url: Helper.getWsUrl() + `/contact/get_roles_list_new/?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
    };
    axios(requestForRole)
      .then((response) => {
        if (response.data.code === 200) {
          setTeamRoleList(Helper.encypt_decrypt(response.data.data).team_roles)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },[])

  const Add_Team_member_Api=()=>{
    const requestAddmember= {
      method: "POST",
      url: Helper.getWsUrl() + `/otp/send_otp_to_team/?role_id=1&web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
      data: {
        name:datawriteTeamMemberName && datawriteTeamMemberName,
        mobile_number:datawrite && datawrite,
        app_type:1,
        version:1.27,
      },
    };
    axios(requestAddmember)
      .then((response) => {
        if (response.data.code === 200) {
          setOtp(true);
         } else if(response.data.code === 206) {
          alert(response.data.msg)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });      
  }

  const MatchOtp_Team=()=>{
    const requestAddmember= {
        method: "POST",
        url: Helper.getWsUrl() + `/otp/match_otp_team/?web=1`,
        headers: { "Content-Type": "application/json", token: cookies.token },
        data: {        
          mobile_number:datawrite && datawrite,
          version:1.16,
          otp:teamotp.toString(),
        },
      };
    axios(requestAddmember)
      .then((response) => {
        if (response.data.code === 200) {
          window.location.reload()
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <div className='rightside' style={{ backgroundColor: "#fff", borderRadius: "20px", padding: "20px", marginLeft: "20px", width: "62%" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={require("../assets/images/team 1.png")} class="img-fluid" style={{ height: "120px", width: "100px" }}></img>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ fontWeight: "600", fontSize: "18px", marginTop: "2%" }}>Member details</p>
      </div>
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "15%" }}>
        <div class="user-input-wrp" style={{ width: "80%" }}>
          <input autoComplete="off" name="name" value={datawriteTeamMemberName} onChange={handleChange1} class="inputText" required style={{ height: "45px" }} />
          <p style={{ position: "absolute", bottom: "32%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Member Name</p>
        </div>
        <div class="user-input-wrp" style={{ width: "80%", marginTop:"20px" }}>
          <input type="text" name="mobile_number" value={datawrite}  onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if(e.target.value === '' || re.test(e.target.value)) {
                setDatawrite(e.target.value);
              }
            }} autoComplete="off" class="inputText" maxLength="10" style={{ height: "45px" }} />
          <p style={{ position: "absolute", bottom: "32%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Contact Number*</p>
        </div>
        {/* <div class="user-input-wrp" style={{ width: "80%", marginTop:'20px' }}>
          <select class="inputText" name="role" style={{padding:"7.5px 16px"}} onChange={(e) => {setTeamMemberRole(e.target.value)}}>
            <option disabled selected>Select Role</option>
            {teamRoleList && teamRoleList.map((value) => {
              return <option value={value.role_id}>{value.role}</option>;
            })}
          </select>
          <p style={{ position: "absolute", bottom: "32%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Role*</p>
        </div> */}
      </div>
      {otp ? 
        <div>
          <div style={{display:"flex",justifyContent:"center",marginTop:"3%"}}>
            <OtpInput 
                  containerStyle = {otpDivStyle}
                  inputStyle = {otpInputStyle}
                  focusStyle = {otpInputFocusStyle}
                  value={teamotp}
                  isInputNum = {boolean}
                  onChange={(teamotp) => {setTeamotp(teamotp);console.log(teamotp)}}
                  numInputs={4}     

                  />                
          </div>
          <div style={{display:"flex",justifyContent:"center",flexDirection:"row",marginTop:"1%"}}>
              <p style={{color:"#545454"}}>Did’nt receive OTP?<span onClick={()=>{Add_Team_member_Api()}} style={{color:'#008C53', cursor:"pointer"}}> Resend</span> </p>
          </div>
          <div style={{ display: "flex", justifyContent: "center", paddingTop: "5%" }}>
          <button onClick={()=>setOtppage(false)} style={{ textAlign: "center", fontSize: "16px", border: "none", backgroundColor: "white", color: "#008C53", borderRadius: "20px", padding: "6px 25px", border: "1px solid #008C53" }}>
              Cancel
          </button>
          {
            (teamotp.length==4)
            ?
            <button onClick={()=>MatchOtp_Team()} style={{ marginLeft: "3%", textAlign: "center", fontSize: "16px", border: "none", backgroundColor: "#008C53", color: "white", borderRadius: "20px", padding: "6px 25px" }}>
            Add Member
            </button>
        :
            <button onClick={()=>MatchOtp_Team()} style={{ marginLeft: "3%", textAlign: "center", fontSize: "16px", border: "none", backgroundColor: "#B6BDC7", color: "white", borderRadius: "20px", padding: "6px 25px" }}>
            Add Member
            </button>
            
          }
        
      </div>
      </div>:   <div style={{ display: "flex", justifyContent: "center", paddingTop: "5%" }}>
          <button onClick={()=>setOtppage(false)} style={{ textAlign: "center", fontSize: "16px", border: "none", backgroundColor: "white", color: "#008C53", borderRadius: "20px", padding: "6px 25px", border: "1px solid #008C53" }}>
              Cancel
          </button>
          <button onClick={()=>{
              if(datawrite && datawrite != null) {
                // if(teamMemberRole && teamMemberRole != null) {
                  Add_Team_member_Api()
                // } else {
                //   alert("Please select role for team")
                // }
              } else {
                alert("Please enter correct number")
              }
              }} style={{ marginLeft: "3%", textAlign: "center", fontSize: "16px", border: "none", backgroundColor: "#008C53", color: "white", borderRadius: "20px", padding: "6px 25px" }}>
              Get OTP
          </button>
      </div>}
    </div>
  )
}

export default TeamGetOTP;