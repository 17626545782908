import React,{useState, useEffect, useCallback} from 'react'
import axios from 'axios';
import { useCookies } from "react-cookie";
import Helper from "../util/Helper";
import './PurchaseAddItemDetail.css'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate } from 'react-router-dom';

const PurchaseAddItemDetail = ({setgetitemdata, setShowPurchaseAddItemDetail, setShowPurchasePayment,  setGetTotalSellingAmt, setGetPurchaseItemsDetail, setShowPurchasePaymentAItemDet, getShopDetail}) => {

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()

    const [getUnitData, setGetUnitData] = useState(null)
    const [getGstData, setGetGstData] = useState(null)

    const [itemDetail, setItemDetail] = useState(null)
    const [itemVarFrmTbl, setItemVarFrmTbl] = useState(null)
    const [itemVarFrmModal, setItemVarFrmModal] = useState(null)

    const [hideTblRow, setHideTblRow] = useState(false)
    const [purchaseTableArr, setPurchaseTableArr] = useState([])
    const [showAddItmVarBtn, setShowAddItmVarBtn] = useState(false)

    const [getPurchaseDisc, setGetPurchaseDisc] = useState(null)
    const [getDiscFrmTbl, setGetDiscFrmTbl] = useState(0)
    const [allVarientAmt, setAllVarientAmt] = useState(0)

    const [purchaseQtyCol, setPurchaseQtyCol] = useState(false)
    const [calStock, setCalStock] = useState(0)

    const [productOrService, setProductOrService] = useState(false)
    const [b2bOrB2c, setB2bOrB2c] = useState(false)
    const [liveOrOffline, setLiveOrOffline] = useState(false)

    let productOrServiceValue = 0, b2bOrB2cvalue = 0, liveOrOfflineValue = 0

    const [dataForArrFrmTbl, setDataForArrFrmTbl] = useState({
        iv_size:null,
        iv_mrp:null,
        iv_purc:null,
        iv_sell:null,
        iv_disc:null,
        iv_color:null,
        iv_model:null,
        iv_refKey:null,
        iv_refVal:null,
        iv_stock:null,
        iv_minQty: null
    })

    const [dataForArr, setDataForArr] = useState({
        iv_size:null,
        iv_mrp:null,
        iv_purc:null,
        iv_sell:null,
        iv_disc:null,
        iv_color:null,
        iv_model:null,
        iv_refKey:null,
        iv_refVal:null,
        iv_stock:null,
        iv_minQty: null
    })

    const getItemDetails = useCallback(event => {
        const { name, value } = event.target;
        setItemDetail({
            ...itemDetail, 
            [name]: value
        });        
        setgetitemdata(itemDetail)
    });    

    const getItemVarFrmTbl = useCallback(event => {
        const { name, value } = event.target;
        setItemVarFrmTbl({
            ...itemVarFrmTbl, 
            [name]: value
        }); 
    });

    const getItemVarFrmModal = useCallback(event => {
        const { name, value } = event.target;
        setItemVarFrmModal({
            ...itemVarFrmModal,
            [name]: value
        })
    })

    const setProductOrServicePurchaseFunc = () => {
        if(productOrService === false) {
            productOrServiceValue = 1
            setProductOrService(true)
        } else {
            productOrServiceValue = 0
            setProductOrService(false)
        }
    }

    const setB2bOrB2cPurchaseFunc = () => {
        if(b2bOrB2c === false) {
            b2bOrB2cvalue = 1
            setB2bOrB2c(true)
            setPurchaseQtyCol(true)
        } else {
            b2bOrB2cvalue = 0
            setB2bOrB2c(false)
            setPurchaseQtyCol(false)
        }
    }

    const setLiveOrOfflinePurchaseFunc = () => {
        if(liveOrOffline === false) {
            liveOrOfflineValue = 1 
            setLiveOrOffline(true)
        } else {
            liveOrOfflineValue = 0
            setLiveOrOffline(false)
        }
    }

    useEffect(() => {
        let discForTbl = 0
        if(itemVarFrmTbl && itemVarFrmTbl.p_mrp != null && itemVarFrmTbl.p_sellingprice != null) {
            discForTbl = parseFloat(((itemVarFrmTbl.p_mrp - itemVarFrmTbl.p_sellingprice)/itemVarFrmTbl.p_mrp) * 100).toFixed(2)
            setGetDiscFrmTbl(discForTbl)
        }
    },[itemVarFrmTbl && itemVarFrmTbl.p_mrp && itemVarFrmTbl.p_sellingprice])

    useEffect(() => {
        let cal_disc = 0
        if(itemVarFrmModal && itemVarFrmModal.p_mrp != null && itemVarFrmModal.p_sellingprice != null) {
            cal_disc = parseFloat(((itemVarFrmModal.p_mrp - itemVarFrmModal.p_sellingprice)/itemVarFrmModal.p_mrp) * 100).toFixed(2)
            setGetPurchaseDisc(cal_disc)
        }
    },[itemVarFrmModal && itemVarFrmModal.p_mrp && itemVarFrmModal.p_sellingprice])

    const itemDetailFilledFunc = () => {
        if(itemDetail && itemDetail.itemname != null && itemDetail.itemunit != null && itemDetail.itemgst != null){
            addFrstTbRow()
        } else {
            alert("Please add item detail")
        }
    }

    const addFrstTbRow = () => {
        if(purchaseTableArr && purchaseTableArr.length > 0) {
            // setShowPurchasePayment(true)
            // setShowPurchaseAddItemDetail(false)
            forAnotherVarient() 
        } else if(itemVarFrmTbl && itemVarFrmTbl.p_size != null && itemVarFrmTbl.p_mrp != null && itemVarFrmTbl.p_purchaseprice != null && itemVarFrmTbl.p_sellingprice != null && itemVarFrmTbl.p_stock != null) {
            if(parseFloat(itemVarFrmTbl.p_sellingprice) > parseFloat(itemVarFrmTbl.p_mrp)) {
                alert("Item Selling Price is not more than MRP")
            } else {
                setDataForArrFrmTbl({
                    iv_size:itemVarFrmTbl && itemVarFrmTbl.p_size,
                    iv_mrp:itemVarFrmTbl && itemVarFrmTbl.p_mrp,
                    iv_purc:itemVarFrmTbl && itemVarFrmTbl.p_purchaseprice,
                    iv_sell:itemVarFrmTbl && itemVarFrmTbl.p_sellingprice,
                    iv_disc:getDiscFrmTbl && getDiscFrmTbl,
                    iv_color:itemVarFrmTbl && itemVarFrmTbl.p_color,
                    iv_model:itemVarFrmTbl && itemVarFrmTbl.p_model,
                    iv_refKey:itemVarFrmTbl && itemVarFrmTbl.p_addReference,
                    iv_refVal:itemVarFrmTbl && itemVarFrmTbl.p_addReference,
                    iv_stock:itemVarFrmTbl && itemVarFrmTbl.p_stock,
                    iv_minQty: itemVarFrmTbl.p_Qty != null ? parseFloat(itemVarFrmTbl.p_Qty) : 1
                })
                forAnotherVarient()
                
            }           
        } else {
            alert("Please fill item varient")
        }
    }

    useEffect(() => {
        if(purchaseTableArr && purchaseTableArr.length === 0) {
            if(dataForArrFrmTbl && dataForArrFrmTbl.iv_size != null) {
                setPurchaseTableArr(purchaseTableArr => [
                    ...purchaseTableArr, dataForArrFrmTbl
                ])
            setHideTblRow(true)
        }
        }
    },[dataForArrFrmTbl])

    const validItemVarientDataFunc = () => {
        if(itemVarFrmModal && itemVarFrmModal.p_size != null && itemVarFrmModal.p_size != "") {
            if(itemVarFrmModal && itemVarFrmModal.p_mrp != null && itemVarFrmModal.p_mrp != "") {
                if(itemVarFrmModal && itemVarFrmModal.p_purchaseprice != null && itemVarFrmModal.p_purchaseprice != "") {
                    if(itemVarFrmModal && itemVarFrmModal.p_sellingprice != null && itemVarFrmModal.p_sellingprice != "") {
                        if(itemVarFrmModal && itemVarFrmModal.p_stock != null && itemVarFrmModal.p_stock != "" && /^\d*$/.test(itemVarFrmModal.p_stock)) {
                            if(parseFloat(itemVarFrmModal.p_sellingprice) > parseFloat(itemVarFrmModal.p_mrp)) {
                                alert("Item Selling Price is not more than MRP")
                            } else {
                                addDataPTblArr()
                            }
                        } else {
                            alert ("Item Stock is required")
                        }
                    } else {
                        alert ("Item Selling Price is required")
                    }
                } else {
                    alert ("Item Purchase Price is required")
                }
            } else {
                alert ("Item MRP is required")
            }
        } else {
            alert ("Item Size is required")
        }
    }

    const addDataPTblArr = () => {
        setDataForArr((dataForArr) => ({
            ...dataForArr,
            iv_size:itemVarFrmModal.p_size,
            iv_mrp:parseFloat(itemVarFrmModal.p_mrp),
            iv_purc:parseFloat(itemVarFrmModal.p_purchaseprice),
            iv_sell:parseFloat(itemVarFrmModal.p_sellingprice),
            iv_disc:parseFloat(getPurchaseDisc && getPurchaseDisc),
            iv_color:itemVarFrmModal.p_color,
            iv_model:itemVarFrmModal.p_model,
            iv_refVal:itemVarFrmModal.p_addReference,
            iv_stock:parseInt(itemVarFrmModal.p_stock),
            iv_minQty:itemVarFrmModal.p_qty != null ? parseFloat(itemVarFrmModal.p_qty) : 1
        }))
        setShowAddItmVarBtn(false)
    }

    useEffect(() => {
        if(dataForArr && dataForArr.iv_size != null) {
            setPurchaseTableArr(purchaseTableArr => [
                ...purchaseTableArr, dataForArr
            ])
            setDataForArr((dataForArr) => ({
                ...dataForArr,
                iv_size:null,
                iv_mrp:null,
                iv_purc:null,
                iv_sell:null,
                iv_disc:null,
                iv_color:null,
                iv_model:null,
                iv_refVal:null,
                iv_stock:null
            }))
        }
    },[dataForArr])

    useEffect(() => {
        document.getElementById("emptyItemVarient").reset();
        // setDataForArr((dataForArr) => ({
        //     ...dataForArr,
        //     iv_size:null,
        //     iv_mrp:null,
        //     iv_purc:null,
        //     iv_sell:null,
        //     iv_disc:null,
        //     iv_color:null,
        //     iv_model:null,
        //     iv_refVal:null,
        //     iv_stock:null
        // }))
    },[purchaseTableArr])

    // console.log("dataForArr : ", dataForArr)
    // console.log("itemDetail : ", itemDetail)

    const forAnotherVarient = () => {
        confirmAlert({
            message: ' Do you want to add another item varient?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {setShowAddItmVarBtn(true)}
              },
              {
                label: 'No',
                onClick: () => {setShowPurchasePayment(true); setShowPurchaseAddItemDetail(false)}
              }
            ]
          });
    }

    useEffect(() => {
        let countStock = 0
        let sum = 0
        if(itemVarFrmTbl && itemVarFrmTbl.p_stock != null) {
            countStock = parseInt(itemVarFrmTbl.p_stock)
            setCalStock(countStock)
        }
        if(purchaseTableArr && purchaseTableArr != null) {
            {purchaseTableArr && purchaseTableArr.map ((value) => {                
                countStock += parseInt(value.iv_stock)
                setCalStock(countStock)
            })}
        }
        // if(itemVarFrmTbl && itemVarFrmTbl.p_sellingprice != null && itemVarFrmTbl.p_stock != null) {
        //     sum = parseFloat((itemVarFrmTbl.p_sellingprice) * (itemVarFrmTbl.p_stock))
        //     setAllVarientAmt(sum)
        // } 
        if(purchaseTableArr && purchaseTableArr.length > 0) {
            {purchaseTableArr && purchaseTableArr.map ((value) => {                
                sum += parseFloat(value.iv_sell * value.iv_stock)
                setAllVarientAmt(sum)
            })}
        }         
    },[purchaseTableArr, itemVarFrmTbl && itemVarFrmTbl.p_sellingprice && itemVarFrmTbl.p_stock])

    useEffect(() => {
        if(allVarientAmt && allVarientAmt) {
            setGetTotalSellingAmt(allVarientAmt)
        }
    },[allVarientAmt])

    useEffect(() => {
        if(purchaseTableArr && purchaseTableArr.length > 0 ) {
            setGetPurchaseItemsDetail(purchaseTableArr)
        } 
    },[purchaseTableArr])

    // console.log(" array data : ",purchaseTableArr)
    
    useEffect(() => {
        const getUnitListUrl = {
            method: "GET",
            url: Helper.getWsUrl() + `/masters/get_merchant_unit_new/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(getUnitListUrl)
        .then((response) => {    
            // setCookie("token", response.headers.token);            
            if (response.data.code === 200) {
                setGetUnitData(Helper.encypt_decrypt(response.data.data))
            }
            else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
                }
        })
        .catch((error) => {
            alert(error);
        })    
    },[])

    useEffect(() => {
        const getGstListUrl = {
            method: "GET",
            url: Helper.getWsUrl() + `/masters/get_merchant_gst_per_new/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(getGstListUrl)
        .then((response) => {    
            // setCookie("token", response.headers.token);            
            if (response.data.code === 200) {
                setGetGstData(Helper.encypt_decrypt(response.data.data))
            }
            else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              }
        })
        .catch((error) => {
            alert(error);
        })    
    },[])

    const[check1,setCheck1]=useState(false);
    const[check2,setCheck2]=useState(false);
    const[check3,setCheck3]=useState(false);

    var clrA = "#008c53";
    var clrB= "#000000";
    var clrE = "#008c53";
    var clrF= "#000000";
    var clrI = "#008c53";
    var clrJ= "#000000";

    if(check1===true){
        var clrC = clrB;
        var clrD = clrA;
    }else{
        var clrC = clrA;
        var clrD = clrB;
    }
    if(check2===true){
        var clrG = clrF;
        var clrH = clrE;
    }else{
        var clrG = clrE;
        var clrH = clrF;
    }
    if(check3===true){
        var clrK = clrJ;
        var clrL = clrI;
    }else{
        var clrK = clrI;
        var clrL = clrJ;
    }

    return (
        <div className='col-10' style={{backgroundColor:"rgb(245, 245, 245)",padding:"20px", position:"relative"}}>
            <div onClick={() => {window.location.reload()}} style={{cursor:"pointer", position:"absolute", top:"0", left:"0", background:"#008c53", color:"#fff", fontWeight:"600", fontSize:"16px", zIndex:"1", padding:"10px 5px", borderRadius:"0 25px 25px 0", writingMode:"vertical-rl"}}>
                view Purchase
            </div>

            <div style={{background:"#fff", width:"100%", height:"100%", borderRadius:"20px", padding:"20px", position:"relative", overflow:"hidden"}}>
                <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                    <h3 style={{fontSize:"18px", fontWeight:"600", lineHeight:"29px"}}>Add Item Details</h3>
                    <div style={{textAlign:"right"}}>
                        <p style={{fontSize:"18px",fontWeight:"600", lineHeight:"22px", padding:"0", margin:"0"}}>{getShopDetail && getShopDetail.shopname}</p>
                        <p style={{fontSize:"18px",fontWeight:"600", lineHeight:"22px"}}>{getShopDetail && getShopDetail.contactno}</p>
                    </div>
                </div>

                <div style={{display:"flex", justifyContent:"space-around",backgroundColor:"#F9F9F9",paddingTop:"20px"}}>
                    <div class="form-check form-switch" style={{display:"flex", justifyContent:"center"}}>
                        <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", color:clrC}} class="form-check-label" for="flexSwitchCheckDefault">Product</p>
                        <input onClick={() => {setProductOrServicePurchaseFunc();setCheck1(!check1)}} style={{marginLeft:"10px"}} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", marginLeft:"10px", color:clrD}} class="form-check-label" for="flexSwitchCheckDefault">Service</p>
                    </div>
                    <div class="form-check form-switch" style={{display:"flex"}}>
                        <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", color:clrG}} class="form-check-label" for="flexSwitchCheckDefault">B2C</p>
                        <input onClick={() => {setB2bOrB2cPurchaseFunc();setCheck2(!check2)}} style={{marginLeft:"10px"}} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", marginLeft:"10px", color:clrH}} class="form-check-label" for="flexSwitchCheckDefault">B2B</p>
                    </div>
                    <div class="form-check form-switch" style={{display:"flex"}}>
                        <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", color:clrK}} class="form-check-label" for="flexSwitchCheckDefault">Offline</p>
                        <input onClick={() => {setLiveOrOfflinePurchaseFunc();setCheck3(!check3)}} style={{marginLeft:"10px"}} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", marginLeft:"10px", color:clrL}} class="form-check-label" for="flexSwitchCheckDefault">Live</p>
                    </div>
                </div>

                <form id='emptyItemDetail' style={{display:"flex", justifyContent:"space-around", marginTop:"40px"}}>
                    <div class="user-input-wrp" style={{width:"30%"}}>                
                        <input autoComplete='off' name='itemname' onChange={getItemDetails} type="text" class="inputText" required />
                        <p style={{ position: "absolute", bottom: "35%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Item Name*</p>  
                    </div>  
                    <div class="user-input-wrp">             
                        <div style={{position:"relative"}}>
                            <p style={{ position: "absolute", bottom: "35%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Unit</p>
                            <select name='itemunit' onChange={getItemDetails} class="inputText">
                                <option className="optionData" selected disabled>Unit*</option>
                                {getUnitData && getUnitData.map((item,index) => {
                                    return(
                                        <option key={item.miu_id} className="optionData" value={item.miu_id}>{item.name}</option>
                                    );
                                }) }
                            </select>
                        </div>
                    </div> 
                    <div class="user-input-wrp"> 
                        <div style={{position:"relative"}}> 
                            <p style={{ position: "absolute", bottom: "35%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Gst</p>  
                            <select name='itemgst' onChange={getItemDetails} class="inputText">
                                <option className="optionData"selected disabled>GST*</option>
                                { getGstData && getGstData.map((item,index) => {
                                    return(
                                        <option key={index} className="optionData" value={item.gst_per}>{item.gst_per}</option>
                                    );
                                }) }
                            </select>
                        </div>
                    </div> 
                    <div class="user-input-wrp">                
                        <input autoComplete='off' name='itemhsn' maxLength={6} onChange={getItemDetails} type="text" class="inputText" required />
                        <p style={{ position: "absolute", bottom: "35%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>HSN/SAC</p>  
                    </div> 
                </form>
                <div className='overflow-auto'>
                    <table class="table" style={{marginTop:"50px", maxHeight:"40vh"}}>
                        <thead>
                            <tr>
                                <th style={{width:"3%", textAlign:"center"}}>S.No</th>
                                <th style={{width:"10%", textAlign:"center"}}>Size*</th>
                                <th style={{width:"10%", textAlign:"center"}}>MRP*<br/>(in ₹)</th>
                                <th style={{width:"10%", textAlign:"center"}}>Purchase*<br/>Price/Unit</th>
                                <th style={{width:"10%", textAlign:"center"}}>Selling*<br/>Price/unit</th>
                                <th style={{width:"5%", textAlign:"center"}}>Disc.<br/>(in %)</th>
                                <th style={{width:"10%", textAlign:"center"}}>Color</th>
                                <th style={{width:"10%", textAlign:"center"}}>Model</th>
                                <th style={{width:"10%", textAlign:"center"}}> Other Reference</th>
                                {purchaseQtyCol ? <th style={{width:"10%", textAlign:"center"}}>Min.Qty*</th> : null}
                                <th style={{width:"10%", textAlign:"center"}}>Stock*</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hideTblRow ? null :
                                <tr>
                                        <td>1.</td>
                                        <td>
                                            <input autoComplete='off' type="text" onChange={getItemVarFrmTbl} value={itemVarFrmTbl && itemVarFrmTbl.p_size} name="p_size" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                        </td>
                                        <td>
                                            <input autoComplete='off' type="number" onChange={getItemVarFrmTbl} value={itemVarFrmTbl && itemVarFrmTbl.p_mrp} name="p_mrp" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                        </td>
                                        <td>
                                            <input autoComplete='off' type="number" onChange={getItemVarFrmTbl} value={itemVarFrmTbl && itemVarFrmTbl.p_purchaseprice} name="p_purchaseprice" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                        </td>
                                        <td>
                                            <input autoComplete='off' type="number" onChange={getItemVarFrmTbl} value={itemVarFrmTbl && itemVarFrmTbl.p_sellingprice} name="p_sellingprice" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                        </td>
                                        <td>
                                            <input autoComplete='off' disabled type="text" onChange={getItemVarFrmTbl} name="p_discount" value={getDiscFrmTbl && getDiscFrmTbl} class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                        </td>
                                        <td>
                                            <input autoComplete='off' type="text" onChange={getItemVarFrmTbl} value={itemVarFrmTbl && itemVarFrmTbl.p_color} name="p_color" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                        </td>
                                        <td>
                                            <input autoComplete='off' type="text" onChange={getItemVarFrmTbl} value={itemVarFrmTbl && itemVarFrmTbl.p_model} name="p_model" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                        </td>
                                        <td>
                                            <input autoComplete='off' type="text" onChange={getItemVarFrmTbl} value={itemVarFrmTbl && itemVarFrmTbl.p_addReference} name="p_addReference" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                        </td>
                                        {purchaseQtyCol ? 
                                            <td>
                                                <input autoComplete='off' type="number" onChange={getItemVarFrmTbl} value={itemVarFrmTbl && itemVarFrmTbl.p_Qty} name="p_Qty" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                            </td>
                                        : null }
                                        <td>
                                            <input autoComplete='off' type="number" onChange={getItemVarFrmTbl} value={itemVarFrmTbl && itemVarFrmTbl.p_stock} name="p_stock" class="inputText" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} />
                                        </td>
                                </tr>
                            }
                            { purchaseTableArr && purchaseTableArr.map((values, index) => {         
                                return ( 
                                    <tr>
                                        <th>{index+1}.</th>
                                        <td><input disabled key={index} value={values.iv_size} type="text" class="inputText inputData" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} /></td>
                                        <td><input disabled key={index} value={values.iv_mrp} type="text" class="inputText inputData" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} /></td>
                                        <td><input disabled key={index} value={values.iv_purc} type="text" class="inputText inputData" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} /></td>
                                        <td><input disabled key={index} value={values.iv_sell} type="text" class="inputText inputData" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} /></td>
                                        <td><input disabled key={index} value={values.iv_disc} type="text" class="inputText inputData" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} /></td>
                                        <td><input disabled key={index} value={values.iv_color} type="text" class="inputText inputData" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} /></td>
                                        <td><input disabled key={index} value={values.iv_model} type="text" class="inputText inputData" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} /></td>
                                        <td><input disabled key={index} value={values.iv_refVal} type="text" class="inputText inputData" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} /></td>
                                        {purchaseQtyCol ? 
                                            <td><input disabled key={index} value={values.iv_qty} type="text" class="inputText inputData" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} /></td>
                                        : null }
                                        <td><input disabled key={index} value={values.iv_stock} type="text" class="inputText inputData" style={{width:"100%", padding:"3px 5px", border:"1px solid #7d7d7d", borderRadius:"7px"}} /></td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td style={{border:"none"}} colSpan={9}></td>   
                                {purchaseQtyCol ? <td style={{border:"none"}}></td> : null }                           
                                <td style={{border:"none", width:"7%"}}>
                                    <input disabled value={calStock && calStock} style={{border: "1px solid #bababa", borderRadius: "5px", padding: "6px 9px", fontSize: "14px", fontWeight:"500", color: "#7d7d7d", width:"100%"}}/>
                                </td>
                            </tr>
                        </tbody>              
                    </table>
                    {showAddItmVarBtn ? 
                        <button type="button" style={{ border: "none", background: "none", padding:"8px 10px" }}>
                            <span type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal" style={{border: "none", background:"#008c53", borderRadius: "5px", padding: "6px 9px", fontSize: "14px", fontWeight:"500", color: "#fff"}}>
                                Add Varient
                            </span>
                        </button>
                    : null }
                </div>
                <div style={{display:"flex", marginTop:"10%", float:"right"}}>
                    {/* <button onClick={() => {itemDetailFilledFunc()}} style={{textAlign:"center", border:"none", borderRadius:"20px", background:"#008c53", color:"#fff", fontSize:"18px", fontWeight:"500", padding:"5px 30px"}}>
                        Save
                    </button> */}
                    <button onClick={() => {itemDetailFilledFunc()}} style={{ marginLeft:"20px", textAlign:"center", border:"none", borderRadius:"20px", background:"#008c53", color:"#fff", fontSize:"18px", fontWeight:"500", padding:"5px 30px"}}>
                        Save
                    </button>
                </div>

                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content" style={{borderRadius:"1.3rem"}}>
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add New Item Varient</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p>Item Name: <span style={{fontWeight:"700"}}>{itemDetail && itemDetail.itemname}</span></p>
                                <form id='emptyItemVarient'>
                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                        <div class="user-input-wrp" style={{width:"30%"}}>
                                            <br />
                                            <input autoComplete='off' type="text" onChange={getItemVarFrmModal} name="p_size" class="inputText" required/>
                                            <span class="floating-label">Size*</span>
                                        </div>
                                        <div class="user-input-wrp" style={{width:"30%"}}>
                                            <br />
                                            <input autoComplete='off' type="number" name="p_mrp" onChange={getItemVarFrmModal} class="inputText" required/>
                                            <span class="floating-label">MRP* (in ₹)</span>
                                        </div>
                                        <div class="user-input-wrp" style={{width:"30%"}}>
                                            <br />
                                            <input autoComplete='off' type="number" onChange={getItemVarFrmModal} name="p_purchaseprice" class="inputText" required/>
                                            <span class="floating-label">PurchasePrice*</span>
                                        </div>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                        <div class="user-input-wrp" style={{width:"30%"}}>
                                            <br />
                                            <input autoComplete='off' type="number" name="p_sellingprice" onChange={getItemVarFrmModal} class="inputText" required/>
                                            <span class="floating-label">Selling Price*</span>
                                        </div>
                                        <div class="user-input-wrp" style={{width:"30%"}}>
                                            <br />
                                            <input autoComplete='off' type="text" name="p_color" onChange={getItemVarFrmModal} class="inputText" required/>
                                            <span class="floating-label">Color</span>
                                        </div>
                                        <div class="user-input-wrp" style={{width:"30%"}}>
                                            <br />
                                            <input autoComplete='off' type="text" name="p_model" onChange={getItemVarFrmModal} class="inputText" required/>
                                            <span class="floating-label">Model</span>
                                        </div>                                
                                    </div>  
                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                        <div class="user-input-wrp" style={{width:"30%"}}>
                                            <br />
                                            <input autoComplete='off' type="text" name="p_addReference" onChange={getItemVarFrmModal} class="inputText" required/>
                                            <span class="floating-label">Reference Key</span>
                                        </div>
                                        <div class="user-input-wrp" style={{width:"30%"}}>
                                            <br />
                                            <input autoComplete='off' type="text" name="p_addReference" onChange={getItemVarFrmModal} class="inputText" required/>
                                            <span class="floating-label">Reference Value</span>
                                        </div>
                                        <div class="user-input-wrp" style={{width:"30%"}}>
                                            <br />
                                            <input autoComplete='off' type="number" name="p_stock" onChange={getItemVarFrmModal} class="inputText" required/>
                                            <span class="floating-label">Stock*</span>
                                        </div>
                                    </div>
                                    {purchaseQtyCol ? 
                                        <div class="user-input-wrp" style={{width:"30%"}}>
                                            <br />
                                            <input autoComplete='off' type="number" name="p_qty" onChange={getItemVarFrmModal} class="inputText" required/>
                                            <span class="floating-label">Min Qty.*</span>
                                        </div> 
                                    : null }
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" onClick={() => validItemVarientDataFunc()}  style={{border: "none", borderRadius: "5px", padding: "6px 9px", fontSize: "14px", fontWeight: "500", color: "#fff", background:"#008C53"}} data-bs-dismiss="modal" >
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default PurchaseAddItemDetail