import React from 'react'
import OrderNav from '../components/OrderNav'
import OrderCard from './OrderCard'

const OrderLeft = ({setGetBookedByForDetail, setShowOrderdetail, setGetIbIdForDetail, showOrderList, skeletonLoading, orderFilterValue, setOrderFilterValue, setOrderFilter}) => {
  return (
    <div style={{width: "40%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff"}}>
      <div style={{position:"relative", width:"100%"}}>                   
        <h6 style={{fontWeight:"600"}}>Orders</h6>                  
        <OrderCard orderFilterValue = {orderFilterValue} setOrderFilterValue = {setOrderFilterValue} setOrderFilter = {setOrderFilter} skeletonLoading = {skeletonLoading} showOrderList = {showOrderList} setGetBookedByForDetail = {setGetBookedByForDetail} setGetIbIdForDetail = {setGetIbIdForDetail} setShowOrderdetail = {setShowOrderdetail}/> 
      </div>                 
    </div>
  )
}

export default OrderLeft