// import React,{ useEffect, useState } from 'react'
// import Helper from "../util/Helper";
// import axios from "axios";
// import { useCookies } from "react-cookie";
// import { Document, Page, pdfjs } from "react-pdf";
// import { useNavigate } from 'react-router-dom';

// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const ShowBill = ({getCardBillId, setPidForEdit, setItemForEdit, setCustIdForEdit, setBillIdPitB, setPerformabill, setPerformaviewbill, setTotalAmtFrmPerformaBillDtl}) => {

//   const navigate = useNavigate();
//   const [cookies, setCookie , removeCookie] = useCookies(["token"]);

//   const [numberPage, setNumberPage] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);

//   function onDocumentLoadSuccess({ numPages }) {
//     setNumberPage(numPages);
//     setPageNumber(1);
//   }

//   function changePage(offset) {
//     setPageNumber((prevPageNumber) => prevPageNumber + offset);
//   }

//   const [billPdf, setBillPdf] = useState(null)
//   const [totalInvoiceBillDetail, setTotalInvoiceBillDetail] = useState(null)
//   const [contactId, setContactId] = useState(null)
//   const [itemList, setitemList] = useState([])

//   useEffect(() => {
//     const getBillListUrl = {
//       method: "GET",
//       url: Helper.getWsUrl() + `/vouchers/customer_bill_details/${getCardBillId}?web=1`,
//       headers: { "Content-Type": "application/json", 'token': cookies.token },
//     };
//     axios(getBillListUrl)
//     .then((response) => {  
//         // setCookie("token", response.headers.token);            
//         if (response.data.code === 200) {     
//           setBillPdf(Helper.encypt_decrypt(response.data.data).bill_pdf)   
//           setTotalInvoiceBillDetail(Helper.encypt_decrypt(response.data.data).totalInvoice)  
//           setContactId(Helper.encypt_decrypt(response.data.data).contact_id)  
//           setitemList(Helper.encypt_decrypt(response.data.data).billItems)
//         } else if (response.data.code === 401) {
//           removeCookie("logged_in")
//           removeCookie("token")
//           setTimeout(() => {
//               navigate('/')
//               localStorage.setItem("isAuthenticated", false)
//               localStorage.removeItem("isAuthenticated")
//           }, 1000)
//         }
//     })
//     .catch((error) => {
//         alert(error);
//     })
//   },[getCardBillId])

//   const downloadBillPdfFunc = () => {
//     window.open(billPdf, '_blank', 'noopener,noreferrer')
//   }

//   return (
//     <div style={{width: "60%", overflow:"hidden", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", textAlign: "center", display: "flex", flexDirection: "column"}}>
//         <div style={{display: "flex", overflow:"hidden", width:"100%", justifyContent:"end"}}>
//           <img onClick={() => {downloadBillPdfFunc()}} style={{cursor:"pointer"}} src={require("../assets/images/download icon.png")}/>
//           <img onClick={() => {setPerformabill(false)}} style={{cursor:"pointer", marginLeft:"10px"}} src={require("../assets/images/cancel.png")}/>
//         </div>
//         <div style={{width:"100%", overflow:"auto", marginTop:"20px", height:"60vh", display:"flex", justifyContent:"center"}}>
//           <Document file={billPdf && billPdf} options={{ workerSrc: "/pdf.worker.js" }} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error()}>
//             <Page pageNumber={pageNumber} />
//           </Document>
//         </div>
        // <div style={{display:"flex", justifyContent:"center", marginTop:"20px", width:"100%", padding:"0 50px", zIndex:"1"}}>
        //   <button onClick={() => {setCustIdForEdit(contactId);setPidForEdit(getCardBillId); setItemForEdit(itemList); setPerformaviewbill(true);}} style={{border:"none", background:"#008c53", borderRadius:"20px", color:"#fff", padding:"5px 45px"}}>Edit</button>
        //   <button onClick={() => {setBillIdPitB(getCardBillId); setTotalAmtFrmPerformaBillDtl(totalInvoiceBillDetail);}} style={{border:"none", background:"#008c53", borderRadius:"20px", color:"#fff", padding:"5px 25px", marginLeft:"30px"}}>Make Bill</button>
        // </div>       
//       </div>
//   )
// }

// export default ShowBill


import React,{ useEffect, useState } from 'react'
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Document, Page, pdfjs } from "react-pdf";
import './BillGenerate.css'
import { useNavigate } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ShowBill = ({getCardBillId, setPidForEdit, setItemForEdit, setCustIdForEdit, setBillIdPitB, setPerformabill, setPerformaviewbill, setTotalAmtFrmPerformaBillDtl}) => {

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate()

  const [billPdf, setBillPdf] = useState(null)
  const [itemList, setItemList] = useState([])
  const [totalInvoiceBillDetail, setTotalInvoiceBillDetail] = useState(null)
  const [contactId, setContactId] = useState(null)

  const [getMerchantDetail, setGetMerchantDetail] = useState(null)
  const [imgUrl, setImgUrl] = useState(null)

  var sum = 0

  useEffect(() => {
    if(getCardBillId && getCardBillId != null) {
      const getBillListUrl = {
        method: "GET",
        url: Helper.getWsUrl() + `/vouchers/customer_bill_details/${getCardBillId}?web=1`,
        headers: { "Content-Type": "application/json", 'token': cookies.token },
      };
      axios(getBillListUrl)
      .then((response) => {        
          if (response.data.code === 200) {     
            setBillPdf(Helper.encypt_decrypt(response.data.data))
            setTotalInvoiceBillDetail(Helper.encypt_decrypt(response.data.data).totalInvoice) 
            setItemList(Helper.encypt_decrypt(response.data.data).billItems)  
            setContactId(Helper.encypt_decrypt(response.data.data).contact_id) 
            console.log("bill detail : ",Helper.encypt_decrypt(response.data.data))
          }
          else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          }
      })
      .catch((error) => {
          alert(error);
      })
    }
  },[getCardBillId])

  useEffect(() => {
    const requestOptions = {
        method: "GET",
        url: Helper.getWsUrl() + `/contact/get_contact/0/?app_type=0&web=1`,
        headers: { "Content-Type": "application/json", "token": cookies.token },
    };
    axios(requestOptions)
        .then((response) => {
            if (response.data.code === 200) {
                setGetMerchantDetail(Helper.encypt_decrypt(response.data.data))
                console.log("contact detail :",Helper.encypt_decrypt(response.data.data))
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            }
        })
        .catch((err) => {
            console.log(err)
        })
  }, [])

  useEffect(() => {
    if (getMerchantDetail && getMerchantDetail.logo === 1) {
        setImgUrl(getMerchantDetail.m_logo_url)
    } else {
        let url = require("../assets/images/enbill_logo.png")
        setImgUrl(url)
    }
  }, [getMerchantDetail])

  const downloadBillPdfFunc = () => {
    window.open(billPdf.bill_pdf, '_blank', 'noopener,noreferrer')
  }

  return (
    <div style={{width: "65%", height: "100%", overflow:"hidden", borderRadius: "20px", padding: "20px 20px 0", backgroundColor: "#fff", marginLeft: "20px", textAlign: "center", display: "flex", flexDirection: "column"}}>
        <div style={{display: "flex", overflow:"hidden", width:"100%", justifyContent:"end"}}>
          <img onClick={downloadBillPdfFunc} style={{cursor:"pointer"}} src={require("../assets/images/download icon.png")}/>
          <img onClick={() => {setPerformabill(false)}} style={{cursor:"pointer", marginLeft:"10px"}} src={require("../assets/images/cancel.png")}/>
        </div>

        <div id='allTableItemList' style={{width:"100%", overflowY:"scroll",  height:"65vh", display:"flex", alignItems:"center", flexDirection:"column"}}>
          <div style={{width:"85%", padding:"20px", borderRadius:"10px", border:"1px dashed #d9d9d9"}}>

            <div style={{width:"100%", textAlign:"center"}}>
                <img src={imgUrl} style={{ borderRadius: "50%", width:"80px", height:"80px", marginBottom:"10px"}} />
                <p style={{fontWeight:"600", fontSize:"18px", margin:"0"}}>{billPdf && billPdf.shopName}</p>
                <p style={{fontWeight:"500", fontSize:"16px", margin:"0"}}>{billPdf && billPdf.shopMobNumber}</p>
                <p style={{fontWeight:"500", fontSize:"16px", margin:"0"}}>{billPdf && billPdf.address}</p>
            </div>

            <div className="col-12" style={{marginTop:"20px"}}>
                <p style={{fontWeight:"600"}}>Date & time : <span style={{fontWeight:"400"}}>{billPdf && billPdf.dateTime}</span></p>
            </div>

            <div style={{marginTop:"20px", marginBottom:"20px"}}>
              <table class="table table-striped" style={{border:"none"}}>
                  <thead>
                      <tr>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Sno.</th>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Item Name</th>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Qty.</th>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Price</th>
                          <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Amount</th>
                      </tr>
                  </thead>
                  <tbody>
                      {itemList && itemList.map((value, index) => {
                      return(
                              <tr>
                                  <td style={{border:"none", fontWeight:"500"}}>{index + 1}.</td>
                                  <td style={{border:"none", fontWeight:"500"}}>{value.itemName}</td>
                                  <td style={{border:"none", fontWeight:"500"}}>{value.itemQty}</td>
                                  <td style={{border:"none", fontWeight:"500"}}>{value.rate}</td>
                                  <td style={{border:"none", fontWeight:"500"}}>{parseFloat(value.amount)}</td>
                              </tr>
                          )
                      })}
                  </tbody>
              </table> 
            </div>
            <div style={{width:"100%", display:"flex", justifyContent:"end"}}>
                <div style={{display:"flex"}}>
                    <div style={{display:"flex", flexDirection:"column"}}>
                      <div style={{fontWeight:"600"}}>
                        Total :
                      </div>
                    </div>                            
                    <div style={{display:"flex", flexDirection:"column"}}>
                      <div className=" p-0 " style={{marginLeft:"10px"}}>
                        ₹ {billPdf && billPdf.totalInvoice}
                      </div>
                    </div>
                </div>
            </div>
            
            <div style={{width:"100%", textAlign:"left"}}>
              <div style={{fontWeight:"550"}}>
                From : &nbsp;
                <span style={{fontWeight:"400"}}>
                  {billPdf && billPdf.shopName}
                </span>
              </div>
              <div style={{fontWeight:"550"}}>
                To : &nbsp;
                {billPdf && billPdf.customerName != null && billPdf.customerName != "" ?
                  <span style={{fontWeight:"400"}}>
                    {billPdf && billPdf.customerName}
                  </span>
                :
                  <span style={{fontWeight:"400"}}>
                    --NA--
                  </span>
                }
              </div>
              <div style={{fontWeight:"550"}}>
                Customer Mobile No. : &nbsp;
                {billPdf && billPdf.customerMobile != null ? 
                  <span style={{fontWeight:"400"}}>
                    {billPdf && billPdf.customerMobile}
                  </span>
                :
                  <span style={{fontWeight:"400"}}>
                    --NA--
                  </span>
                }
              </div>
              <div style={{fontWeight:"550"}}>
                Bill No. : &nbsp;
                <span style={{fontWeight:"400"}}>
                  {billPdf && billPdf.billNo}
                </span>
              </div>
            </div>
          </div>
        </div>
        
        <div style={{display:"flex", justifyContent:"center", marginTop:"20px", width:"100%", padding:"0 50px", zIndex:"1"}}>
          <button onClick={() => {setCustIdForEdit(contactId);setPidForEdit(getCardBillId); setItemForEdit(itemList); setPerformaviewbill(true);}} style={{border:"none", background:"#008c53", borderRadius:"20px", color:"#fff", padding:"5px 45px"}}>Edit</button>
          <button onClick={() => {setBillIdPitB(getCardBillId); setTotalAmtFrmPerformaBillDtl(totalInvoiceBillDetail);}} style={{border:"none", background:"#008c53", borderRadius:"20px", color:"#fff", padding:"5px 25px", marginLeft:"30px"}}>Make Bill</button>
        </div>
      </div>
  )
}

export default ShowBill