import './Team.css'
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TeamLeft=({setOtppage,setMemberdetails,setSend_contact_id, showTeamList, skeletonLoading})=>{

    const [active, setActive] = useState(null)

    return(
        <div className='billingLeft' style={{width: "40%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff"}} >
            <div style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                <h6 className='tmmber'>View Team Members</h6>
                <span>
                    <img src={require("../assets/images/Plus.png")} onClick={() =>{setOtppage(true);setMemberdetails(false)}}
                    height={"24px"} width={"24px"} style={{cursor:"pointer"}} className="img-fluid" />
                </span>
            </div>
            {skeletonLoading ? 
                <div style={{height:"60vh", overflowY:"scroll"}}>
                    <Skeleton count={4} borderRadius={"10px"} height={"15vh"} /> 
                </div>
            :
                <>
                    {showTeamList && showTeamList.length > 0 ? 
                        <div style={{overflowY:"scroll", height:"60vh"}}>
                            {showTeamList && showTeamList.map((value,index)=>{
                                return(
                                    <div key={index} className={`${active == index && 'active'}`} onClick={() => {setSend_contact_id(value.contact_id); setMemberdetails(true);setActive(index)}} style={{ display: "flex", width: "100%", justifyContent: "space-between", cursor: "pointer", borderRadius: "10px", boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.1)", padding: "10px", marginTop: "20px" }}>
                                        <div className='TeamWhite' style={{width: "70%"}}>
                                            <p className='childname mb-0' >{value.name}</p>
                                            {(index===active) ?   
                                                <p className='childname1 mb-0' style={{fontWeight:"500",fontSize:"14px",color:"white"}}>{value.mobile_number}</p>
                                            :
                                                <p className='childname1 mb-0' style={{fontWeight:"500",fontSize:"14px"}}>{value.mobile_number}</p>
                                            }
                                            <p className='childname mb-0' style={{fontWeight:"500", fontSize:"14px"}}>Booking active : {value.live_booking}</p>
                                        </div>
                                        <div style={{ width: "45%", textAlign: "right",fontWeight:"500",fontSize:"14px"}}>
                                            <p  className='TeamWhite mb-0'>Bills : {value.bills}  </p>
                                            <p className='TeamUdhaar mb-0'>Udhaar : {value.credit_amonut}  </p>
                                            <p className='Teamrevenue mb-0'>Revenue : {value.amont} </p>
                                        </div>                        
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10%", width: "5%" }}>
                                            {(index===active)?
                                                <img style={{ height: "20px", width: "8px" }} src={require("../assets/images/white_left_marker.png")} id={"vector"} className="img-fluid "></img>
                                            :
                                                <img style={{ height: "20px", width: "8px" }} src={require("../assets/images/vector.png")} id={"vector"} className="img-fluid "></img>
                                            }                                           
                                        </div>
                                    </div>
                                ) 
                            })}
                        </div>
                    :
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"60vh"}}>
                            <p style={{color:"#7a7a7a", fontWeight:"600", fontSize:"18px"}}>no data found</p>
                        </div>
                    }
                </>
            }
        </div>
    )
}
export default TeamLeft;