const TeamAddMember = ({setAddmember,addmember}) => {
    return (
        <div style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <div style={{ display: "flex", flexDirection:"column", justifyContent: "space-between", width:"80%", overflow:"hidden", padding:"0 20px"}} >
                <img src={require("../assets/images/team 1.png")} class="img-fluid" style={{height:"120px",width:"100px"}}></img>
                <div style={{display:"flex",justifyContent:"center"}}>
                    <p style={{fontWeight:"600", fontSize:"18px",marginTop:"2%"}}>Member details</p>
                </div>
                <div style={{display:"flex",justifyContent:"center",flexDirection:"column",marginLeft:"15%"}}>
                    <div class="user-input-wrp" style={{ width: "80%" }}>
                        <br />
                        <input name="rate" class="inputText" required style={{height:"45px"}}/>
                        <span class="floating-label">Member Name*</span>
                    </div>
                    <div class="user-input-wrp" style={{ width: "80%" }}>
                        <br />
                        <input name="rate" maxLength={10} class="inputText" required style={{height:"45px"}}/>
                        <span class="floating-label">contact Number*</span>
                    </div>
                </div>
                <div style={{display:"flex",justifyContent:"center",paddingTop:"5%"}}>
                    <button onClick={()=>setAddmember(false)} style={{ textAlign: "center", fontSize: "16px", border: "none", backgroundColor: "white", color: "#008C53", borderRadius: "20px", padding: "6px 25px",border:"1px solid #008C53" }}>
                        Cancel
                    </button>
                    <button onClick={()=>setAddmember(false)} style={{marginLeft:"3%", textAlign: "center", fontSize: "16px", border: "none", backgroundColor: "#008C53", color: "white", borderRadius: "20px", padding: "6px 25px" }}>
                        Update
                    </button>
                </div>
            </div>
        </div>
    )
}
export default TeamAddMember;