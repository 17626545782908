import React,{ useEffect, useState } from "react";
import Helper from "../util/Helper";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { Button, Pagination } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const CatalogueAddNew =({setShowListedProduct})=>{

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [masterCategoryList, setMasterCategoryList] = useState([])
    const [openCSVOption, setOpenCSVOption] = useState(false)
    const [selectCsvFile, setSelectCsvFile] = useState(null)
    const [showProgressToUploadCSV, setShowProgressToUploadCSV] = useState(false)

    var bodyformData = new FormData();
    bodyformData.append('file', selectCsvFile );
    bodyformData.append('fileName', selectCsvFile && selectCsvFile.name);

    useEffect(() => {
        if(selectCsvFile && selectCsvFile != null) {
            setShowProgressToUploadCSV(true)
            const getItemListCatalougeUrl = {
                method: "POST",
                url: Helper.getWsUrl() + `/items/add_item_data_by_file/?web=1`,
                headers: { "content-type": "multipart/form-data", 'token': cookies.token },
                data:bodyformData
            };
            axios(getItemListCatalougeUrl)
                .then((response) => {       
                    if (response.data.code === 201) {
                        alert(response.data.msg)
                        window.location.reload();                             
                        setShowProgressToUploadCSV(false)
                    } else if (response.data.code === 401) {
                        removeCookie("logged_in")
                        removeCookie("token")
                        setTimeout(() => {
                            navigate('/')
                            localStorage.setItem("isAuthenticated", false)
                            localStorage.removeItem("isAuthenticated")
                        }, 1000)
                    } else if (response.data.code === 400) {
                        alert(response.data.msg)
                    }
                })
                .catch((error) => {
                    alert(error);
                })
        }

        const requestCategoryOption = {
            method: "GET",
            url: Helper.getWsUrl() + `/items/get_merchant_categories_for_magicupload_new/?magic=1&web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(requestCategoryOption)
            .then((response) => {       
                if (response.data.code === 200) {
                    // console.log("axsdsdsds",Helper.encypt_decrypt(response.data.data))
                    setMasterCategoryList(Helper.encypt_decrypt(response.data.data))
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                }
            })
            .catch((error) => {
                alert(error);
            });
    },[selectCsvFile])

    const downloadCatelogueCsv = () => {
        const getItemListCatalougeUrl = {
            method: "GET",
            url: Helper.getWsUrl() + `/masters/get_csv_file_creation_instructions/?web=1`,
            headers: { "content-type": "multipart/form-data", 'token': cookies.token },
        };
        axios(getItemListCatalougeUrl)
        .then((response) => {       
            if (response.data.code === 200) {
                window.open(Helper.encypt_decrypt(response.data.data).url, '_blank', 'noopener,noreferrer')                  
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            } else if (response.data.code === 400) {
                alert(response.data.msg)
            }
        })
        .catch((error) => {
            alert(error);
        })
    }

    const handleClick = (newPlacement) => (event) => {
        setOpenCSVOption(false)
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    return(       
        <div id="box" style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position:"relative"}}>
            <AddCircleIcon onClick={handleClick('bottom-end')} sx={{color:"#008c53", position:"absolute", right:"25px", top:"25px",fontSize:"28px", cursor:"pointer"}} />
            <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper sx={{width: "auto", background:"rgb(244 244 244)", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", position:"relative"}}>
                        <Typography sx={{ p: 1 }}>
                            {!openCSVOption ? 
                                <Button onClick={() => {setOpenCSVOption(true)}} style={{color:"#008c53", padding:"4px 5px", border:"none", borderRadius:"10px", background:"none",fontSize:"12px"}} variant="contained" component="label">
                                    <img src={require('../assets/images/CSV_Icon.png')} style={{width:"23px", marginRight:"5px"}} />
                                    CSV
                                </Button>
                            :
                                
                                <Button onClick={() => {downloadCatelogueCsv()}} style={{color:"#008c53", textTransform:"capitalize", padding:"5px 10px", border:"none", borderRadius:"10px", background:"none",fontSize:"12px"}} variant="contained" component="label">
                                    <FileDownloadOutlinedIcon sx={{marginRight:"5px"}} />
                                    Download format
                                </Button>
                            }

                            <div style={{margin: masterCategoryList && masterCategoryList.length > 0 && masterCategoryList[0].name === "Daily Needs" ? "10px 0" : "0", padding:"0"}}></div>

                            {!openCSVOption ?
                                <>
                                    {masterCategoryList && masterCategoryList.length > 0 && masterCategoryList[0].name === "Daily Needs" ? 
                                        <Button onClick={() => {setOpen(false); setShowListedProduct(true)}}  style={{color:"#008c53", padding:"5px 10px", border:"none", borderRadius:"10px", background:"none",fontSize:"12px"}} variant="contained" component="label">
                                            <img src={require('../assets/images/Product-Listing.png')} style={{width:"23px", marginRight:"5px"}} />
                                            Listed Product
                                        </Button>
                                    : null }
                                </>
                            :
                                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                    <Button disabled={!showProgressToUploadCSV ? "" : "disable"} style={{color:"#008c53", textTransform:"capitalize", padding:"5px 10px", border:"none", borderRadius:"10px", background:"none",fontSize:"12px"}} variant="contained" component="label">
                                        <UploadFileIcon sx={{marginRight:"5px"}} /> 
                                        Upload File
                                        <input type="file" hidden accept="csv" onChange={(e) => {setSelectCsvFile(e.target.files[0])}}/>
                                    </Button>
                                    {!showProgressToUploadCSV ? 
                                        null
                                    :
                                        <Stack sx={{ color: 'grey.300' }} style={{ width: '25px', height: '25px' }} spacing={2} direction="row">
                                            <CircularProgress color="success" style={{ width: '25px', height: '25px' }} />
                                        </Stack>
                                    }
                                </div>
                            }

                        </Typography>
                    </Paper>
                </Fade>
                )}
            </Popper>
            <img src={require("../assets/images/catalougeAdd.png")} class="img-fluid" />
            <div>           
                <button onClick={()=> navigate("/Catalogue/add_new_item")}
                        style={{backgroundColor: "#008C53", color: "white",  borderRadius: "20px", padding: "7px 0px", marginTop:"40px", border: "1px solid #008C53"}} >
                    <img src={require("../assets/images/icon.png")}
                            height={"13%"}
                            width={"13%"}
                            class="img-fluid">
                    </img>
                <span style={{ fontSize: "17px", marginLeft:"10px", fontWeight:"600" }}>Add New</span>
                </button>
            </div>
        </div>   
    )
}

export default CatalogueAddNew;