import Helper from "../util/Helper";
import axios from "axios";
import "./Team.css";
import { useCookies } from "react-cookie";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJs, ArcElement,Tooltip,Legend } from "chart.js";

ChartJs.register(ArcElement, Tooltip, Legend)

const TeamMemberDetails = ({ setMemberdetails, send_contact_id }) => {

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate();

  const [showdataarray, setShowdataarray] = useState(null);
  const [showitemdetails, setShowitemdetails] = useState([]);
  const [getBookingData, setGetBookingData] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      url: Helper.getWsUrl() + `/contact/get_team_member_detail/${send_contact_id}?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
    };
    axios(requestOptions)
      .then((response) => {
        if (response.data.code === 200) {
          setShowdataarray(Helper.encypt_decrypt(response.data.data)[0].header);
          setShowitemdetails(Helper.encypt_decrypt(response.data.data)[0].items_detail);
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          removeCookie("token");
          setTimeout(() => {
            navigate("/");
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("isAuthenticated");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    const requestOptions1 = {
      method: "GET",
      url: Helper.getWsUrl() + `/booking/get_team_member_assin_booking_new/${send_contact_id}/?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
    };
    axios(requestOptions1)
      .then((response) => {
        if (response.data.code === 200) {
          console.log("detail :   ",Helper.encypt_decrypt(response.data.data).booking_list[0]);
          setGetBookingData(Helper.encypt_decrypt(response.data.data).booking_list[0]);
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          removeCookie("token");
          setTimeout(() => {
            navigate("/");
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("isAuthenticated");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [send_contact_id]);

  const Delete_Team_Member = () => {
    const requestoptions = {
      method: "DELETE",
      url: Helper.getWsUrl() + `/contact/remove_team_member/${send_contact_id}?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
    };
    axios(requestoptions)
      .then((response) => {
        if (response.data.code === 200) {
          window.location.reload();
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          removeCookie("token");
          setTimeout(() => {
            navigate("/");
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("isAuthenticated");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const data = {
    labels:[`no_of_booking (${getBookingData && getBookingData.new_booking})`,`packed_booking (${getBookingData && getBookingData.packed_booking})`,`delivered_booking (${getBookingData && getBookingData.delivered_booking})`,`cancel_booking (${getBookingData && getBookingData.cancel_booking})`],
    datasets:[{
      label:"poll",
      data:[
        getBookingData && getBookingData.new_booking,
        getBookingData && getBookingData.packed_booking,
        getBookingData && getBookingData.delivered_booking,
        getBookingData && getBookingData.cancel_booking
      ],
      backgroundColor: ["#ea7d3d", "#008c53", "#4cbd80", "#ba1c09"],
    }]
  }

  return (
    <div className="rightside" style={{ backgroundColor: "#fff", borderRadius: "20px", padding: "20px", marginLeft: "20px", width: "62%", display: "flex", flexDirection: "column", alignItems: "center", }} >
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }} >
        <h4 style={{ fontWeight: "600", fontSize: "18px", lineHeight: "20px" }}>
          Member Details
        </h4>
        <div>
          <img onClick={() => { setMemberdetails(false); Delete_Team_Member();}} src={require("../assets/images/delete icon.png")} class="img-fluid" />
          <img onClick={() => setMemberdetails(false)} src={require("../assets/images/cancel icon.png")} class="img-fluid" style={{ paddingLeft: "15px" }} />
        </div>
      </div>

      <div style={{ width: "100%", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", borderRadius: "20px", padding: "12px 16px", marginTop: "10px", }}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "60%" }}>
            <p style={{ fontweight: "600" }} className="memdetails mb-0">
              {showdataarray && showdataarray.name}
            </p>
            <p className="mbernumber">
              {showdataarray && showdataarray.mobile_number}
            </p>
          </div>
          <div style={{ fontweight: "500", fontSize: "14px", width: "15%" }}>
            <p className="billset mb-0">Bills</p>
            <p style={{ color: "#BA1C09", fontWeight: "500", fontSize: "14px" }} className="mb-0">
              Udhaar
            </p>
            <p style={{ color: "#008C53", fontWeight: "500", fontSize: "14px" }} className="mb-0">
              Revenue
            </p>
          </div>
          <div style={{ fontWeight: "600", fontSize: "14px", width: "25%" }}>
            <p className="bnumber2 mb-0">
              {" "}
              : {showdataarray && showdataarray.total_bills}
            </p>
            <p style={{ color: "#BA1C09", fontWeight: "600", fontSize: "14px" }} className="mb-0" >
              : ₹ {showdataarray && showdataarray.shop_credit}
            </p>
            <p style={{ color: "#008C53", fontWeight: "600", fontSize: "14px" }} className="mb-0">
              : ₹{" "}
              {showdataarray && showdataarray.total_revannue != null? 
                showdataarray && showdataarray.total_revannue
              : 
                0
              }
            </p>
          </div>
        </div>
      </div>

      <div style={{ borderRadius: "20px", width: "100%", overflow: "hidden", border: "1px solid #d9d9d9", marginTop: "10px", padding: "20px 36px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", height: "80%", }}>
        <div style={{width:"100%", display:"flex"}}>
          <h4 style={{ fontSize: "18px", fontWeight: "600", margin: "0" }}>
            Booking Status :
          </h4>
          <div style={{width:"15vw", marginLeft:"20px"}}>
            <Doughnut data={data} />
          </div>
        </div>

        {showitemdetails && showitemdetails.length > 0 ? (
          <>
            <h4 style={{ fontSize: "18px", margin: "0", fontWeight: "600" }}>
              Item Sold :
            </h4>
            <div id="allTableItemList" style={{ width: "100%", maxHeight: "25vh", overflowY: "scroll"}}>
              <table class="table table-striped" style={{ border: "none" }}>
                <thead>
                  <tr style={{ border: "none" }}>
                    <th scope="col" style={{ border: "none" }}>
                      S.no
                    </th>
                    <th scope="col" style={{ border: "none", width: "60%" }}>
                      Item description
                    </th>
                    <th scope="col" style={{ border: "none", width: "60%" }}>
                      Qty
                    </th>
                    <th scope="col" style={{ border: "none" }}>
                      Unit
                    </th>
                  </tr>
                </thead>
                <tbody style={{ border: "none" }}>
                  {showitemdetails &&
                    showitemdetails.map((value, index) => {
                      return (
                        <>
                          <tr style={{ border: "none" }}>
                            <td style={{ border: "none", fontWeight: "500", fontSize: "14px", }}>
                              {" "}
                              {index + 1}
                            </td>
                            <td style={{ border: "none", fontWeight: "500", fontSize: "14px", }} >
                              {value.item_name}
                            </td>
                            <td style={{ border: "none", fontWeight: "500", fontSize: "14px", }}>
                              {value.quantity}
                            </td>
                            <td style={{ border: "none", fontWeight: "500", fontSize: "14px", }}>
                              {value.unit}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TeamMemberDetails;
