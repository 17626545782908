import './BillGenerate.css'
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import Helper from "../util/Helper";

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const BillGenarate = ({setShowBillGenerate, addedNewtemList, itemListTtlAmtWithDiscForAddBill, setforEditItemList, itemListTtlAmtForAddBill, overAllDiscount, grhkContactId, accessReturnReplace, getCardBillId, billDetailForReturnReplace, itemListToAddBill, setShowPaymentPage, removeItemList, setGetTotalAmountForAllItem, setItemListTtlAmtWithDiscForAddBill }) => {
  
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const [getMerchantDetail, setGetMerchantDetail] = useState(null)
  const [imgUrl, setImgUrl] = useState(null)

  const [showProgressBar, setShowProgressBar] = useState(false)

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  const time = current.toLocaleTimeString("en-US");

  useEffect(() => {
    const requestOptions = {
        method: "GET",
        url: Helper.getWsUrl() + `/contact/get_contact/0/?app_type=0&web=1`,
        headers: { "Content-Type": "application/json", "token": cookies.token },
    };
    axios(requestOptions)
        .then((response) => {
            if (response.data.code === 200) {
                setGetMerchantDetail(Helper.encypt_decrypt(response.data.data))
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            }
        })
        .catch((err) => {
            console.log(err)
        })
  }, [])

  const editBillDetails = () => {
    const requestOptions = {
        method: "PATCH",
        url: Helper.getWsUrl() + `/vouchers/edit_bill_detail/${getCardBillId}/${grhkContactId}/?web=1`,
        headers: { "Content-Type": "application/json", "token": cookies.token },
        data: {
            credit_note: billDetailForReturnReplace.totalInvoice - itemListTtlAmtWithDiscForAddBill > 0 ? parseFloat(billDetailForReturnReplace.totalInvoice - itemListTtlAmtWithDiscForAddBill).toFixed(2) : 0,
            billdetails: {
                credit_note: billDetailForReturnReplace.totalInvoice - itemListTtlAmtWithDiscForAddBill > 0 ? parseFloat(billDetailForReturnReplace.totalInvoice - itemListTtlAmtWithDiscForAddBill).toFixed(2) : 0,
                return_replace_billed_amount: itemListTtlAmtWithDiscForAddBill,
                extra_payble_amount: itemListTtlAmtWithDiscForAddBill - billDetailForReturnReplace.totalInvoice > 0 ? itemListTtlAmtWithDiscForAddBill - billDetailForReturnReplace.totalInvoice : 0,
                bill_adjusted: 1
            },
            bill_Item_details: itemListToAddBill,
            remove_item: removeItemList,
            billed_item: addedNewtemList
        }
    };
    axios(requestOptions)
        .then((response) => {
            if (response.data.code === 201) {
               window.location.reload()
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            }
        })
        .catch((err) => {
            console.log(err)
        })
  }

  useEffect(() => {
    if (getMerchantDetail && getMerchantDetail.logo === 1) {
        setImgUrl(getMerchantDetail.m_logo_url)
    } else {
        let url = require("../assets/images/enbill_logo.png")
        setImgUrl(url)
    }
  }, [getMerchantDetail])

  return (
    <div className='col-10 for_pdf' style={{backgroundColor:"rgb(245, 245, 245)",padding:"20px", position:"relative", overflow:"hidden"}}>
        <div onClick={() => {window.location.reload()}} style={{cursor:"pointer", position:"absolute", top:"0", left:"0", background:"#008c53", color:"#fff", fontWeight:"600", fontSize:"16px", zIndex:"1", padding:"10px 5px", borderRadius:"0 25px 25px 0", writingMode:"vertical-rl"}}>
            view bills
        </div>

        <div style={{background:"#fff", width:"100%", height:"100%", borderRadius:"20px", padding:"10px 20px", position:"relative", overflow:"hidden", display:"flex", justifyContent:"center"}}>
            <div style={{width:"100%", display:"flex", alignItems:"center", flexDirection:"column"}}>
                <div style={{width:"70%", padding:"20px", borderRadius:"10px", border:"1px dashed #d9d9d9"}}>
                    <div style={{width:"100%", textAlign:"center"}}>
                        <img src={imgUrl} style={{ borderRadius: "50%", width:"80px", height:"80px", marginBottom:"10px"}} />
                        <p style={{fontWeight:"600", fontSize:"18px", margin:"0"}}>{getMerchantDetail && getMerchantDetail.name}</p>
                        <p style={{fontWeight:"500", fontSize:"16px", margin:"0"}}>{getMerchantDetail && getMerchantDetail.mobile_number}</p>
                        <p style={{fontWeight:"500", fontSize:"16px", margin:"0"}}>{getMerchantDetail && getMerchantDetail.address}</p>
                    </div>
                    <div className="col-12" style={{marginTop:"20px"}}>
                        <p style={{fontWeight:"600"}}>Date & time : <span style={{fontWeight:"400"}}>&nbsp;{date}&nbsp; &nbsp;{time}</span></p>
                    </div>
                    <div id='allTableItemList' style={{marginTop:"15px", marginBottom:"10px", overflowY:"scroll", maxHeight:"22vh"}}>
                        <table class="table table-striped" style={{border:"none"}}>
                            <thead>
                                <tr>
                                    <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Sno.</th>
                                    <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Item Name</th>
                                    <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Qty.</th>
                                    <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Price</th>
                                    <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Amount</th>
                                    <th scope="col" style={{fontSize:"16px", fontWeight:"600", border:"none"}}>Expiry Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {itemListToAddBill && itemListToAddBill.map((value, index) => {
                                return(
                                        <tr>
                                            <td style={{border:"none", fontWeight:"500"}}>{index + 1}.</td>
                                            <td style={{border:"none", fontWeight:"500"}}>{value.item_name}</td>
                                            <td style={{border:"none", fontWeight:"500"}}>{value.quantity}</td>
                                            <td style={{border:"none", fontWeight:"500"}}>{value.rate}</td>
                                            <td style={{border:"none", fontWeight:"500"}}>{parseFloat((value.rate) * (value.quantity)).toFixed(2)}</td>
                                            <td style={{border:"none", fontWeight:"500"}}>{value.exp_date != null ? value.exp_date : "--N/A--"}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table> 
                    </div>
                    <div style={{width:"100%", textAlign:"left"}}>
                        <div style={{display:"flex"}}>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <div style={{fontWeight:"600"}}>
                                    Total Amount :
                                </div>
                                <div style={{fontWeight:"600"}}>
                                    Overall Discount :
                                </div>
                                {!accessReturnReplace ? 
                                    <div style={{fontWeight:"600"}}>
                                        Total payble Amount :
                                    </div>
                                : null }
                                {!accessReturnReplace ? 
                                    null
                                :
                                    <>
                                        <div style={{fontWeight:"600"}}>
                                            Previous Bill Amount :
                                        </div>
                                        <div style={{fontWeight:"600"}}>
                                            Credit Note :
                                        </div>
                                        <div style={{fontWeight:"600"}}>
                                            Extra Payble Amount :
                                        </div>
                                    </>
                                }
                            </div>                            
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <div className=" p-0 " style={{marginLeft:"10px"}}>
                                    {!accessReturnReplace ? 
                                        <>
                                            ₹ {parseFloat(itemListTtlAmtForAddBill && itemListTtlAmtForAddBill).toFixed(2)}
                                        </>
                                    :
                                        <>
                                            ₹ {itemListTtlAmtWithDiscForAddBill && itemListTtlAmtWithDiscForAddBill}
                                        </>
                                    }
                                </div>
                                <div className=" p-0 " style={{marginLeft:"10px"}}>
                                    ₹ {overAllDiscount && overAllDiscount != NaN ? overAllDiscount : 0}
                                </div>
                                {!accessReturnReplace ? 
                                    <div className="  p-0 " style={{marginLeft:"10px"}}>
                                        ₹ {itemListTtlAmtWithDiscForAddBill && itemListTtlAmtWithDiscForAddBill}
                                    </div>
                                : null }
                                {!accessReturnReplace ? 
                                    null
                                :
                                    <>
                                        <div className=" p-0 " style={{marginLeft:"10px"}}>
                                            ₹ {billDetailForReturnReplace && billDetailForReturnReplace.totalInvoice}
                                        </div>
                                        <div className=" p-0 " style={{marginLeft:"10px"}}>
                                            ₹ {itemListTtlAmtWithDiscForAddBill && (billDetailForReturnReplace.totalInvoice - itemListTtlAmtWithDiscForAddBill) > 0  ? parseFloat(billDetailForReturnReplace.totalInvoice - itemListTtlAmtWithDiscForAddBill).toFixed(2) : 0}
                                        </div>
                                        <div className=" p-0 " style={{marginLeft:"10px"}}>
                                            ₹ {itemListTtlAmtWithDiscForAddBill && (itemListTtlAmtWithDiscForAddBill - billDetailForReturnReplace.totalInvoice) > 0 ? parseFloat(itemListTtlAmtWithDiscForAddBill - billDetailForReturnReplace.totalInvoice).toFixed(2) : 0}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width:"100%", justifyContent:"center", alignItems:"center", display:"flex", marginTop:"10px"}}>
                
                    <div style={{display:!showProgressBar ? "none" : "flex", marginRight:"20px"}} >
                        <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
                            <CircularProgress color="success" />
                        </Stack>
                        <p style={{color:"#008c53", fontWeight:"600", margin:"10px", padding:"0"}}>Processing...</p>
                    </div>

                    {!accessReturnReplace ?
                        <>
                            <button onClick={() => { setShowPaymentPage(true);}}  style={{border:"none", background:"#008c53", borderRadius:"20px", padding:"5px 20px", fontWeight:"600", color:"#fff"}}>Payment</button>
                            <button onClick={() => {setShowBillGenerate(false); setforEditItemList(true)}}  style={{marginLeft:"10px",border:"1px solid #008c53", background:"none", borderRadius:"20px", padding:"5px 20px", fontWeight:"600", color:"#008c53"}}>Edit</button>
                        </>
                    :
                            <>
                                <button disabled={!showProgressBar ? "" : "disable"} data-bs-toggle="modal" data-bs-target="#exampleModal1" style={{border:"none", background:"#008c53", borderRadius:"20px", padding:"5px 20px", fontWeight:"600", color:"#fff"}}>Payment</button>
                                <button disabled={!showProgressBar ? "" : "disable"} onClick={() => {setShowBillGenerate(false); setforEditItemList(true)}}  style={{marginLeft:"10px",border:"1px solid #008c53", background:"none", borderRadius:"20px", padding:"5px 20px", fontWeight:"600", color:"#008c53"}}>Edit</button>
                            </>
                    }
                </div>
            </div>
        </div>

        <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content" style={{textAlign:"center", borderRadius:"15px"}}>
                    <div class="modal-body" style={{paddingBottom:"0"}}>
                        {!accessReturnReplace ? null :
                            <>
                                <p style={{fontWeight:"500", fontsize:"18px", display:billDetailForReturnReplace.totalInvoice - itemListTtlAmtWithDiscForAddBill > 0 ? "block" : "none"}}>You have to return  ₹{parseFloat(billDetailForReturnReplace.totalInvoice - itemListTtlAmtWithDiscForAddBill).toFixed(2)} credit note in cash to Grahak!</p>
                                <p style={{fontWeight:"500", fontsize:"18px", display:billDetailForReturnReplace.totalInvoice - itemListTtlAmtWithDiscForAddBill === 0 ? "block" : "none"}}>Your new bill amount is adjusted with the previous bill amount</p>
                                <p style={{fontWeight:"500", fontsize:"18px", display:itemListTtlAmtWithDiscForAddBill - billDetailForReturnReplace.totalInvoice > 0 ? "block" : "none"}}>Collect extra amount  ₹{parseFloat(itemListTtlAmtWithDiscForAddBill - billDetailForReturnReplace.totalInvoice).toFixed(2)} from Grahak</p>
                            </>
                        }
                            </div>
                    <div class="modal-footer" style={{padding:"0 16px", border:"none", margin:"0"}}>
                        <button style={{border:"none", background:"none", color:"#7a7a7a", fontWeight:"600", marginBottom:"20px"}} type="button" data-bs-dismiss="modal">Cancel</button>
                        <button onClick={() => {
                                if(itemListTtlAmtWithDiscForAddBill - billDetailForReturnReplace.totalInvoice > 0) {
                                    setGetTotalAmountForAllItem(parseFloat(itemListTtlAmtWithDiscForAddBill - billDetailForReturnReplace.totalInvoice).toFixed(2))
                                    setShowPaymentPage(true)
                                } else {
                                    setShowProgressBar(true)
                                    editBillDetails()
                                }
                            }} type="button" style={{border:"none", background:"none", color:"#008c53", fontWeight:"600", marginBottom:"20px"}} class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default BillGenarate;

