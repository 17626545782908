import axios from "axios";
import Helper from "../util/Helper";
import React, { useEffect } from "react";
import Raect, { useState } from "react";
import { useCookies } from "react-cookie";
import UdhaarAllPaymentHistory from "./UdhaarAllPaymentHistory";
import { useNavigate } from "react-router-dom";

const UdhaarTotal = (setShowBill) => {

const[showallhistory,setShowAllHistory]=useState(false)
const [cookies, setCookie, removeCookie] = useCookies(["token"]);
const navigate = useNavigate()

const [users, setUsers] = useState([]);

    const getusers = () => {
        const requestOptions = {
            method: "POST",
            url: Helper.getWsUrl() + `/vouchers/shop_credit_list/?key=all&web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                // setCookie("token", response.headers.token);
                if (response.data.code === 200) {
                    setUsers(Helper.encypt_decrypt(response.data.data))           
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getusers();
    }, [])

    return (
        <div style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column",}}>
            <div style={{ backgroundColor: "#008C53", width:"100%", color: "white", display: "flex", textAlign: "center", padding: "10px 20px", borderRadius: "10px", justifyContent: "space-between" }}>
                <div style={{width:"100%", textAlign:"center"}}>
                    <p style={{ marginTop: "2%", fontWeight: "600" }}>Total Udhaar</p>
                    <p style={{ marginTop: "-1%", fontWeight: "600" }}>₹ {parseFloat(users.total_credit).toFixed(2)}</p>
                </div>
                <div style={{display:"flex", alignItems:"center"}}>
                    <img className="allPyamentHisBtn" onClick={()=>setShowAllHistory(true)} src={require("../assets/images/TimeCircle.png")} />
                </div>
            </div>
            {showallhistory?
                <UdhaarAllPaymentHistory showallhistory={showallhistory} setShowAllHistory={setShowAllHistory}></UdhaarAllPaymentHistory>
            :
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width:"100%", marginTop:"20px"}}>
                    <img src={require("../assets/images/UdhaarIcon.png")} class="img-fluid" style={{width:"50%"}} />
                    <p style={{ color: "#7A7A7A", fontWeight: "600", marginTop:"20px" }}>See your pending udhaar here</p>
                </div>          
            }
        </div>
    )
}

export default UdhaarTotal;