// import "./Viewbill.css";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const ProformaViewBills = ({setInvoiceedit, getCardBillId, itemForEdit, tempId, setGetPdf, setShowbillid,setGetTotalAmountForAllItem, grahakDetails, custIdForEdit, pidForEdit}) => {

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate()

  const [show, setShow] = useState(false);
  const [showAddItemBtn, setShowAddItemBtn] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)

  const [billItemList, setBillItemList] = useState([])
  const [filteredData, setFilteredData] = useState(null);
  const [getDataFrmPostNewItem, setGetDataFrmPostNewItem] = useState(null)
  const [getObjectData,setGetObjectData] = useState(null)
  const [getArrayData, setGetArrayData] = useState(null)
  const [getUnitData, setGetUnitData] = useState(null)  
  const [miuIdName, setMiuIdName] = useState(null);
  // const [amount, setAmount] = useState(null); 
  // const [taxableAmt, setTaxebleAmt] = useState(null)  
  const [tableArr, setTableArr] = useState([])
  const [overAllDisc, setOverAllDisc] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [addPost,setAddPost]=useState(null)
  const [totalAmtWithOverallDisc, setTotalAmtWithOverallDisc] = useState(null)

  const [customerDetail, setCustomerDetail] = useState(null)
  const [editItemList, setEditItemList] = useState([])
  const [showDeleteBtn, setShowDeleteBtn] = useState(false)
  const [getGSTData, setGetGSTData] = useState(null) 
  
  const [itemget, setItemget] = useState({
    itemDetails: {
     size: null,
     discount_per: null,
     gst_tax_per: null,
     in_stock: null,
     is_active: 1,
     itemName: null,
     min_qty: null,
     miu_id: null,
     mrp: null,
     rate: null,
    },
    product_pricing_details: "",
  }); 
  const [fieldValue, setFieldValue] = useState ({
    item_id: null,
    iv_id: null,
    itemName: null,
  })
  const [emptyObj, setEmptyObj] = useState({
    itemName: null,
    size: null,
    unit: null,
    mrp: null,
    rate: null,
    gst: null,
    discount: null,
    qty: null,
    amt: null,
    itemID:null,
    ivID:null
  })

  var tA = 0

  useEffect(() => {
    if(grahakDetails && grahakDetails != null) {
      setCustomerDetail(grahakDetails)
    }

    if(custIdForEdit && custIdForEdit != null) {
      const reqForGrahakDetail = {
        method: "GET",
        url: Helper.getWsUrl() + `/contact/get_contact/${custIdForEdit}?web=1`,
        headers: { "Content-Type": "application/json", Token: cookies.token },
      };
      axios(reqForGrahakDetail)
        .then((response) => {
          // setCookie("token", response.headers.token);
          if (response.data.code === 200) {
            setCustomerDetail(Helper.encypt_decrypt(response.data.data));
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          }
        })
        .catch((error) => {
          console.log("Something went wrong!!");
        });
    }
  },[grahakDetails, custIdForEdit, itemForEdit])

  useEffect(() => {
    if(pidForEdit && pidForEdit != null) {
      const getBillListUrl = {
        method: "GET",
        url: Helper.getWsUrl() + `/vouchers/customer_bill_details/${pidForEdit}?web=1`,
        headers: { "Content-Type": "application/json", 'token': cookies.token },
      };
      axios(getBillListUrl)
      .then((response) => {        
          if (response.data.code === 200) {
            setEditItemList(Helper.encypt_decrypt(response.data.data).billItems)
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          }
      })
      .catch((error) => {
          alert(error);
      })
    }
  },[pidForEdit])

  useEffect(() => {
    if(editItemList && editItemList.length > 0) {
      editItemList.map((value) => {
        if(!tableArr.find((element) => value.item_id === element.itemID)) {
          setTableArr((tableArr) => [
            ...tableArr,{
            itemName: value.itemName,
            size: value.size,
            unit: value.unit,
            mrp: value.mrp,
            rate: value.rate,
            gst: value.gst,
            discount: parseFloat(((value.mrp - value.rate) * 100)/value.mrp).toFixed(2),
            qty: value.itemQty != null && value.itemQty != 0 ? value.itemQty : 1,
            amt: value.amount,
            itemID:value.item_id,
            ivID:value.iv_id,
          }
          ])
        }
      })
    }
  },[editItemList])

  const getItems = (event) => {
    setItemget((prevState) => ({
      ...prevState,
      itemDetails: {
        ...prevState.itemDetails,
      itemName: event.target.value,
      }
    }));
  };

  const getAddBookPost = useCallback(event => {
    const { name, value } = event.target;
    setAddPost({ ...addPost, [name]: value });
  });

  useEffect (() => {
    if(itemget.itemDetails.itemName != null || itemget.itemDetails.itemName == ""){
      itemSearch(itemget.itemDetails.itemName)
      setShowAddItemBtn(true)
    }
  },[itemget.itemDetails.itemName])

  const itemSearch = (itemName) => {
    const requestOption = {
      method: "GET",
      url: Helper.getWsUrl() + `/ItemSearch/?name=${itemName != null && itemName != "" ? itemName : null}&web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOption)
      .then((response) => {       
        if (response.data.code === 200) {
            setFilteredData(Helper.encypt_decrypt(response.data.data));
            setShow(true)
        }
        else if(response.data.code === 204){
          setShow(false)
          setShowAddItemBtn(true)
        } 
        else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  } 

  const handleAutoFill = (name) => {
    document.getElementById("empty").reset()
    setShow(false)
    let text = name
    
    let inputField = filteredData.filter ((val) => {
      return val.iv_id.includes(text)
    })

    setFieldValue((prevState) => ({
      ...prevState,
      item_id: inputField[0].item_id,
      iv_id: inputField[0].iv_id ,
      itemName: inputField[0].itemName,
    }))
  } 

  const postNewItemDetail=()=>{
    const requestOptions4 = {
      method: "POST",
      url: Helper.getWsUrl() + "/items/post_bookeditem/?web=1",
      headers: { "Content-Type": "application/json", Token: cookies.token },
      data: {
        itemDetails:{        
          item_name:itemget.itemDetails.itemName,        
          size:addPost.size,
          miu_id:parseInt(addPost.miu_id), 
          mrp:parseFloat(addPost.mrp),
          gst_tax_per:parseFloat(addPost.gst_tax_per),
          rate:parseFloat(addPost.rate), 
          in_stock:1,
          is_active:1,
          discount_per:parseFloat(((parseFloat(addPost.mrp)-parseFloat(addPost.rate)) * 100) / parseFloat(addPost.mrp)).toFixed(2),
          min_qty: addPost && (addPost.addQty != null && addPost.addQty != 0) ? parseInt(addPost.addQty) : 1
        }       
      },
    };
    axios(requestOptions4)
      .then((response) => {
        if (response.data.code === 201) {
          setGetDataFrmPostNewItem(Helper.encypt_decrypt(response.data.data))
          setShow(false)
          document.getElementById("emptyModal").reset()
          setAddPost({
            ...addPost,
            size: null,
            miu_id: null,
            mrp: null,
            rate: null,
            addQty: null,
            gst_tax_per: null,
          })
        } else if (response.data.code === 204) {
          alert(response.data.msg)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  useEffect(() => {
    if(fieldValue && fieldValue.item_id != null && fieldValue.iv_id != null) {
      getItemDetails(fieldValue.item_id, fieldValue.iv_id)
    }
  },[fieldValue])

  useEffect(() => {
    if(getDataFrmPostNewItem && getDataFrmPostNewItem.item_id != null && getDataFrmPostNewItem.iv_id != null) {
      getItemDetails(getDataFrmPostNewItem.item_id, getDataFrmPostNewItem.iv_id)
    }
  },[getDataFrmPostNewItem])

  const getItemDetails = (itemId,ivId) => {
    const requestOption = {
      method: "GET",
      url: Helper.getWsUrl() + `/items/get_items/${itemId}/${ivId}/?web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOption)
      .then((response) => {    
        if (response.data.code === 200) {
            setGetObjectData(Helper.encypt_decrypt(response.data.data).item_data)
            setGetArrayData(Helper.encypt_decrypt(response.data.data).item_verient[0])
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  } 

  useEffect(() => {
    const requestOption = {
      method: "GET",
      url: Helper.getWsUrl() + `/masters/get_itemunit_active/1/?web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOption)
      .then((response) => {       
        if (response.data.code === 200) {
          setGetUnitData(Helper.encypt_decrypt(response.data.data))
        }
        else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });

      const requestOptionForGst = {
        method: "GET",
        url: Helper.getWsUrl() + `/masters/get_merchant_gst_per_new/?web=1`,
        headers: { "Content-Type": "application/json", Token: cookies.token },
      };
      axios(requestOptionForGst)
        .then((response) => {    
          if (response.data.code === 200) {
            setGetGSTData(Helper.encypt_decrypt(response.data.data))
          }
          else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          }
        })
        .catch((error) => {
          alert(error);
        });
  },[])

  useEffect(() => {
    let filteredMiuId = null
    if(getUnitData && getObjectData && getObjectData.miu_id){
      filteredMiuId = getUnitData.filter((item) => {
        if(item.miu_id == getObjectData.miu_id) {
          return item          
        }
      })      
      setMiuIdName(filteredMiuId && filteredMiuId[0].name)
    }
  }, [getUnitData, getObjectData, editItemList])

  // useEffect(() => { 
  //   if(getArrayData && getArrayData) {
  //     let amountget = (getArrayData.min_qty != null && getArrayData.min_qty != 0 ? getArrayData.min_qty : 1) * getArrayData.selling_price
  //     let gstValue = getObjectData.gst_tax_per/100
  //     let amtWithGst = getArrayData.mrp + gstValue

  //     setAmount(amountget)
  //     setTaxebleAmt(amtWithGst)
  //   }
  // },[getArrayData,getObjectData])

  useEffect(() => {
    if((itemget.itemDetails.itemName || fieldValue.itemName || getObjectData && getObjectData.item_name) && getObjectData && getObjectData!= null && getArrayData && getArrayData != null)
      {
        setEmptyObj((emptyObj) => ({
          ...emptyObj,
          itemName: getObjectData.item_name,
          size: getArrayData.size,
          unit: miuIdName,
          mrp: getArrayData.mrp,
          rate: getArrayData.selling_price,
          gst: getObjectData.gst_tax_per,
          discount: parseFloat(((getArrayData.mrp - getArrayData.selling_price) * 100)/getArrayData.mrp).toFixed(2),
          qty: getArrayData.min_qty != null && getArrayData.min_qty != 0 ? getArrayData.min_qty : 1,
          amt: (getArrayData.min_qty != null && getArrayData.min_qty != 0) ? getArrayData.min_qty * getArrayData.selling_price : 1 * getArrayData.selling_price,
          itemID:getArrayData.item_id,
          ivID:getArrayData.iv_id,
        }))
      }
  },[itemget.itemDetails.itemName,fieldValue.itemName,getObjectData && getObjectData,getArrayData && getArrayData.size])

  useEffect(() => {
    if(emptyObj.itemName) {
      if(tableArr.find((element) => emptyObj.ivID === element.ivID)) {
        let filterTableArray = [...tableArr]
        let indexOfArray
        let filtrTblArrElement = filterTableArray.filter((item, index) => {
                if(item.ivID == emptyObj.ivID){
                    indexOfArray = index
                    return item
                }
            })
            filtrTblArrElement[0].qty++
            filterTableArray[indexOfArray] = filtrTblArrElement[0]
            setTableArr(filterTableArray)
      } else {
        setTableArr(tableArr => [
          ...tableArr,emptyObj
        ])
      }
    }
  },[emptyObj])

  useEffect(() => {
    setEmptyObj((emptyObj) => ({
      ...emptyObj,
          itemName: null,
          size: null,
          unit: null,
          mrp: null,
          rate: null,
          gst: null,
          discount: null,
          qty: null,
          amt: null,
          itemID:null,
          ivID:null,
    }))
    setGetArrayData(null)
    setGetObjectData(null)
    setMiuIdName(null)                          
  },[tableArr])

  useEffect(() => {
    if(tableArr && tableArr.length > 0) {
      {tableArr && tableArr.map ((value, index) => {
        tA += value.amt || value.amount
      })}
      setTotalAmount(tA)
    }
  },[tableArr])

  const getOverAllDiscFunc = (e) => {
    setOverAllDisc({
      ...overAllDisc,
      disc: e.target.value
    })
  }

  useEffect(() => {
    if(totalAmount && totalAmount!=null){
      setTotalAmtWithOverallDisc(parseFloat(totalAmount).toFixed(2))
    }
  },[totalAmount])
  
  useEffect(() => {
    if(overAllDisc && overAllDisc != null) {
      if(overAllDisc.disc > totalAmount) {
        setTotalAmtWithOverallDisc(totalAmount)
      } else {
        let amtAftrOvrallDisc = parseFloat(totalAmount - overAllDisc.disc)
        setTotalAmtWithOverallDisc(parseFloat(amtAftrOvrallDisc).toFixed(2))  
      }    
    }
  },[overAllDisc])

  const billItemListFunc = () => {
    if(tableArr && tableArr.length > 0) {
      {tableArr && tableArr.map ((value, index) => {
        setBillItemList((billItemList) => [
          ...billItemList, {
            amount: parseFloat(value.rate * value.qty),
            discount_per: parseFloat(value.discount).toFixed(2),
            gst_per: parseFloat(value.gst),
            item_id: value.itemID,
            iv_id: value.ivID,
            quantity: parseFloat(value.qty),
            rate: parseFloat(value.rate),
            taxable_amount: 0.0,
            unit_id: parseInt(value.unit)
          }
        ])
      })}
      setDisableBtn(true)
      setGetTotalAmountForAllItem(totalAmtWithOverallDisc)
    }else {
      alert("please add item")
    }
  }

  const edityQuantityInArrayFunc = (e, index) => {
    const re = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/
    if(e.target.value === '' || re.test(e.target.value)) {
      let filterTableArray = [...tableArr]
      let indexOfEditQty = index
      let editQtyVal = e.target.value
      let indexOfArray
      let filtrTblArrElement = null
      filtrTblArrElement = filterTableArray.filter((item, index) => {
        if(index == indexOfEditQty){
          indexOfArray = index
          return item
        }
      })
      filtrTblArrElement[0].qty = editQtyVal
      filtrTblArrElement[0].itemQty = editQtyVal
      filtrTblArrElement[0].amt = filtrTblArrElement[0].rate * editQtyVal
      filtrTblArrElement[0].amount = filtrTblArrElement[0].rate * editQtyVal
      filterTableArray[indexOfArray] = filtrTblArrElement[0]
      setTableArr(filterTableArray)
    }
  }

  useEffect(() => {
    if(billItemList && billItemList.length > 0 && pidForEdit && pidForEdit != null){
      if(overAllDisc && overAllDisc.disc != null) {
        var OvrAll_Disc=  parseFloat(overAllDisc.disc)
      }
      const requestOptions5 = {
        method: "PATCH",
        url: Helper.getWsUrl() + `/vouchers/update_performa_invoice/${pidForEdit}/`,
        headers: { "Content-Type": "application/json", Token: cookies.token },
        data: {
          bill_Item_details: billItemList,
            billdetails:{
              bill_type:0,
              customer_id: customerDetail && customerDetail.contact_id,
              is_shop_credit:0,
              overall_discount: OvrAll_Disc,
              refund_amount:"0",
              round_off:0.0,
              status:0,
              sub_total: parseFloat(totalAmount),
              taxable_amount:0.0,
              total_amount: parseFloat(totalAmtWithOverallDisc),
              total_tax:0.0
            }     
        },
      };
      axios(requestOptions5)
        .then((response) => {
          if (response.data.code === 201) { 
            setInvoiceedit(true) 
            setShowbillid(pidForEdit)  
            setGetPdf(response.data.data.pr_link)
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          }
        })
        .catch((error) => {
          alert(error);
        });
    } 
  },[billItemList])

  useEffect(() => {
    if(billItemList && billItemList.length > 0 && tempId && tempId != null){
      if(overAllDisc && overAllDisc.disc != null) {
        var OvrAll_Disc=  parseFloat(overAllDisc.disc)
      }
        const requestOptions5 = {
          method: "POST",
          url: Helper.getWsUrl() + `/vouchers/add_bill/?isPerforma=1&tempId=${tempId}&web=1`,
          headers: { "Content-Type": "application/json", Token: cookies.token },
          data: {
            bill_Item_details: billItemList,
            billdetails:{
              bill_type:0,
              customer_id: customerDetail && customerDetail.contact_id,
              is_shop_credit:0,
              overall_discount: OvrAll_Disc,
              refund_amount:"0",
              round_off:0.0,
              status:0,
              sub_total: parseFloat(totalAmount),
              taxable_amount:0.0,
              total_amount: parseFloat(totalAmtWithOverallDisc),
              total_tax:0.0
            }     
          },
          };
          axios(requestOptions5)
          .then((response) => {
            if (response.data.code === 201) {
              customerBillDtl(Helper.encypt_decrypt(response.data.data).bill_id) 
              setShowbillid(Helper.encypt_decrypt(response.data.data).bill_id)  
              setGetPdf(Helper.encypt_decrypt(response.data.data).pr_link)  
            } else if (response.data.code === 401) {
              removeCookie("logged_in")
              removeCookie("token")
              setTimeout(() => {
                  navigate('/')
                  localStorage.setItem("isAuthenticated", false)
                  localStorage.removeItem("isAuthenticated")
              }, 1000)
            }
          })
          .catch((error) => {
            alert(error);
          });
    } 
  },[billItemList])

  const customerBillDtl = (billId) => {
    const requestOptions6 = {
      method: "GET",
        url: Helper.getWsUrl() + `/vouchers/customer_bill_details/${billId}?web=1`,
        headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOptions6)
        .then((response) => {
          if (response.data.code === 200) {
            setInvoiceedit(true)  
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          }
        })
        .catch((error) => {
          alert(error);
        });
    }

    const forDeleteRowFrmTbl = (itemId, index) => {
      setTableArr(tableArr.filter((element, ind) => 
        ind != index
      ))
      setShowDeleteBtn(false);
    }

  const styles = {
    width: "100%",
    textAlign: "left",
    color: "#868D96",
    fontWeight: 400,
    fontSize: "14px"
  };
  
  
  return (
    <div className='col-10' style={{backgroundColor:"rgb(245, 245, 245)",padding:"20px", position:"relative"}}>
      <div onClick={() => {window.location.reload()}} style={{cursor:"pointer", position:"absolute", top:"0", left:"0", background:"#008c53", color:"#fff", fontWeight:"600", fontSize:"16px", zIndex:"1", padding:"10px 5px", borderRadius:"0 25px 25px 0", writingMode:"vertical-rl"}}>
        View Performa Invoices
      </div>

      <div style={{background:"#fff", width:"100%", height:"100%", borderRadius:"20px", padding:"20px", position:"relative", overflow:"hidden"}}>
        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
          <h3 style={{fontSize:"18px", fontWeight:"600", lineHeight:"29px"}}>Add Performa Invoice</h3>
        </div>
        <div style={{display:"flex", justifyContent:"space-around", marginTop:"10px"}}>
          <div class="user-input-wrp">
            <br />
            <input autoComplete="off" style={{border:"1px solid #008c53"}} type="text" disabled class="inputText" value={customerDetail && customerDetail.name} required name="name"/>
            <span style={{top: "12px", left:"17px", opacity:"1", background:"#fff", color:"#008c53"}} class="floating-label">Grahak name</span>
          </div>
          <div class="user-input-wrp" >
            <br />
            <input autoComplete="off" disabled type="number" style={{border:"1px solid #008c53"}} class="inputText" name="mobile_number" required maxLength={10} value={customerDetail && customerDetail.mobile_number} />
            <span style={{top: "12px", left:"17px", opacity:"1", background:"#fff", color:"#008c53"}} class="floating-label">Contact No.</span>
          </div>
          <div class="user-input-wrp">
            <br />
            <input autoComplete="off" style={{border:"1px solid #008c53"}} type="text" disabled class="inputText" required name="gst" pattern="/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/" maxLength={15} value={customerDetail && customerDetail.gst_number}/>
            <span style={{top: "12px", left:"17px", opacity:"1", background:"#fff", color:"#008c53"}} class="floating-label">GST No.</span>
          </div>

          <div class="user-input-wrp" style={{ width: "30%" }}>
            <br />
            <input autoComplete="off" style={{border:"1px solid #008c53", width: "100%" }} type="text" disabled class="inputText" value={customerDetail && customerDetail.address} required name="address" />
            <span style={{top: "12px", left:"17px", opacity:"1", background:"#fff", color:"#008c53"}} class="floating-label">Address</span>
          </div>
        </div>

        <div id="allTableItemList" style={{marginTop:"40px", maxHeight:"40vh", overflowY:"scroll"}}>
          <table class="table">
            <thead>
              <tr>
                <th style={{width:"5%"}}>S.No</th>
                <th style={{ width: "20%" }}>Item Name</th>
                <th>Size</th>
                <th>Unit</th>
                <th>MRP<br />(in ₹)</th>
                <th>Selling<br />Price/unit</th>
                <th>GST*<br />(in %)</th>
                <th>Disc<br />(in %)</th>
                <th>Qty<br />(in Units)</th>
                <th>Amount<br />(in ₹)</th>
              </tr>            
              <tr>
                <th style={{border:"none"}}></th>
                <th style={{ position: "relative"}}> 
                  <div style={{border: "none", width: "100%",borderRadius: "10px",padding: "0", display:"flex", justifyContent:"space-between"}}>
                    <form id="empty">
                      <input autoComplete="off" type="text" placeholder="Add/Search Item" name="itemName" style={{borderRadius: "10px", border:"1px solid #7a7a7a", background:"none", padding:"3.5px 7px"}}
                          onChange={(event) => {
                          getItems(event);
                        }}/>
                    </form>
                    {showAddItemBtn ? 
                      <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{border: "none", background: "none", padding: "0", width:"max-content", marginLeft:"3px"}}>
                        <span style={{border: "none", borderRadius: "10px", padding: "6px 9px", fontSize: "14px", fontWeight: "500", color: "#fff", background:"#008C53"}}>
                          Add Item
                        </span>
                      </button>
                   : null }
                  </div>
                  {show ? (
                    <div style={{paddingTop: "10px", left:"0", minHeight:"0", maxHeight:"20vh", overflowY:"scroll", backgroundColor: "#fff",position: "absolute", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", width: "100%",borderRadius:"0 0 10px 10px"}}> 
                      {filteredData && filteredData.map((value, index) => {
                        return (
                          <li onClick={(e) => {handleAutoFill(value.iv_id)}} style={{listStyle: "none", border:"none", cursor:"pointer", padding:"0 15px"}}>
                            <p style={styles} key={value.item_id}>
                              {value.itemName} &nbsp;({value.size}, {value.color}, {value.model})
                            </p>
                          </li>
                        )
                      })}
                    </div> ) 
                    : 
                    null
                  }
                </th>              
                <th colSpan={8}></th>
              </tr> 
            </thead>
            <tbody style={{overflowY:"scroll", maxHeight:"20vh"}}>
              {
                tableArr && tableArr.map((item, index) => {
                  return(
                    <tr>
                      <th>
                        {index+1}
                        {showDeleteBtn ?
                          <img onClick={() => {forDeleteRowFrmTbl(item.item_id, index)}} src={require("../assets/images/delete icon.png")}  style={{width:"15px", marginLeft:"5px", cursor:"pointer"}} />
                        :
                          <img onClick={() => {setShowDeleteBtn(true)}} src={require("../assets/images/Vector1.png")}  style={{width:"10px", marginLeft:"10px", cursor:"pointer"}}/>
                        }  
                      </th>
                      <td>
                        <input disabled key={index} value={item.itemName} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.size} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.unit || item.unit_name} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.mrp} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.rate} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.gst} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.discount || item.itemDiscount} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input onChange={(e) => {edityQuantityInArrayFunc(e, index)}} key={index} value={item.qty || item.itemQty} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={parseFloat(item.amt).toFixed(2) || parseFloat(item.amount).toFixed(2)} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                    </tr>
                  )
                })
              }
              <tr>              
                <td colSpan={2} style={{borderRight:"none"}}>
                </td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}>Total</td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none", width: "7%", position: "relative" }}>
                  <input style={{border: "1px solid #bababa",borderRadius: "5px",padding: "6px 9px",fontSize: "14px",fontWeight: "500", color: "#bababa", width: "100%",}}/>
                  <span style={{ position: "absolute", top: "30%", left: "15%" }}>
                    {parseFloat(totalAmount).toFixed(2)}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>          

        <div style={{ display: "flex", justifyContent: "end", marginTop: "30px" }}>
          <div style={{ textAlign: "right", marginRight: "20px" }}>
            <p style={{ fontSize: "16px", fontWeight: "500", margin:"0" }}>
              Overall Disc (optional) &nbsp;&nbsp; -{" "}
            </p>
            {/*<p style={{ fontSize: "16px", fontWeight: "500" }}>
              round Off &nbsp; &nbsp; -{" "}
              </p>*/}
            <br></br>
            <p style={{ fontSize: "16px", fontWeight: "500", margin:"0" }}>
              Final Amount &nbsp; &nbsp; -{" "}
            </p>
          </div>

          <div style={{ textAlign: "left" }}>
            <input onChange={(e) => {
                const re = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/
                if(e.target.value === '' || re.test(e.target.value)) {
                  getOverAllDiscFunc(e)
                }
              }} value={overAllDisc.disc} type="text" style={{ border: "none", borderBottom: "1px solid #bababa" }} placeholder="Disc" />
            <br />
            {/*<input type="text" style={{ border: "none", borderBottom: "1px solid #bababa" }} placeholder="Round off" required/>*/}
            <br />
            <input value={totalAmtWithOverallDisc} disabled type="text" style={{ border: "none", borderBottom: "1px solid #bababa" }} placeholder="Final Amt" required/>
          </div>
        </div>

        <div style={{ textAlign: "right", marginTop: "30px", marginRight: "10px" }}>
          <button disabled={disableBtn ? "disable" : ""} onClick={() => {
            if(totalAmtWithOverallDisc && totalAmtWithOverallDisc > 9999999){
              alert("Amount should not exceed 99,99,999")
            }else{if(customerDetail && customerDetail.contact_id != null) {
              billItemListFunc();
            }else{
              alert("Please add grahak")
            } }
            }} 
            style={{backgroundColor: "#008C53", color: "#fff", borderRadius: "10px", padding: "3px 13px", border: "none", }}>
            Genearate Bill
          </button>
        </div>
      </div>

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content" style={{borderRadius:"1.3rem"}}>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add New Item</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>Item Name: {itemget.itemDetails.itemName}</p>
              <form id="emptyModal">
                <div style={{display:"flex", justifyContent:"space-between"}}>
                  <div class="user-input-wrp" style={{width:"30%"}}>
                    <br />
                    <input autoComplete="off" type="text" onChange={getAddBookPost} name="size" class="inputText" required/>
                    {/* <span class="floating-label">Size*</span> */}
                    <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Size*</p>
                  </div>
                  <div class="user-input-wrp" style={{width:"30%"}}>
                  <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Unit*</p>
                    <br />
                    <select name="miu_id" onChange={getAddBookPost} class="inputText inputData">
                      <option selected >Unit</option>
                      {getUnitData && getUnitData.map((value, index) => {
                        return(
                          <option value={value.miu_id}>{value.name}</option>
                        )
                      })}
                      {/* <option value="3">Add More</option> */}
                      {/* <option value="2">Pieces</option> 
                      <option value="4">Gram</option>
                      <option value="5">Kilogram</option>
                      <option value="6">Liter</option>
                      <option value="7">Meter</option> */}
                    </select>
                  </div>
                  <div class="user-input-wrp" style={{width:"30%"}}>
                    <br />
                    <input autoComplete="off" type="number" name="mrp" onChange={getAddBookPost} class="inputText" required/>
                    {/* <span class="floating-label">MRP* (in ₹)</span> */}
                    <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>MRP* (in ₹)</p>
                  </div>
                </div>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                  <div class="user-input-wrp" style={{width:"30%"}}>
                    <br />
                    <input autoComplete="off" type="number" name="rate" onChange={getAddBookPost} class="inputText" required/>
                    {/* <span class="floating-label">SellingPrice*</span> */}
                    <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>SellingPrice*</p>
                  </div>
                  <div class="user-input-wrp" style={{width:"30%"}}>
                    <br />
                    <input autoComplete="off" type="text" name="addQty" onChange={getAddBookPost} class="inputText" required/>
                    {/* <span class="floating-label">Purchase*</span> */}
                    <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Model*</p>
                  </div>
                  <div class="user-input-wrp" style={{width:"30%"}}>
                  <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>GST*</p>
                    <br />
                    <select name="gst_tax_per" onChange={getAddBookPost} class="inputText inputData">
                      <option selected>GST</option>
                      {getGSTData && getGSTData.map((value) => {
                        return(
                          <option value={value.gst_per}>{value.gst_per}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>  
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" onClick={() => {
                if(addPost && addPost.size != null && addPost.size != "") {
                  if(addPost && addPost.miu_id != null) {
                    if(addPost && addPost.mrp != null && addPost.mrp != "") {
                      if(addPost && addPost.rate != null && addPost.rate != "") {
                        if(addPost && addPost.mrp >= addPost.rate) {
                          if(addPost && addPost.mrp <= 9999999) {
                            if(addPost && addPost.gst_tax_per != null) {
                              postNewItemDetail()
                            } else {
                              alert("Item GSt is required")
                            }
                          } else {
                            alert("Item MRP is not greater than ₹ 9999999")
                          }
                        } else {
                          alert("Item Selling Price is always less than or equal to MRP")
                        }
                      } else {
                        alert("Item Selling Price is required")
                      }
                    } else {
                      alert("Item MRP is required")
                    }

                  } else {
                    alert("Item Unit is required")
                  }
                } else {
                  alert("Item Size is required")
                }
              }} style={{border: "none", borderRadius: "5px", padding: "6px 9px", fontSize: "14px", fontWeight: "500", color: "#fff", background:"#008C53"}} data-bs-dismiss="modal" >Done</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default ProformaViewBills;
