import { React, useState, useEffect } from "react";
import Main from "../components/Main";
import axios from "axios";
import Helper from "../util/Helper";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { messaging } from '../firebase';
import { getToken } from "firebase/messaging";
// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

// import { initializeApp } from "https://www.gstatic.com/firebasejs/9.0.2/firebase-app.js";
// // import { getAnalytics } from "https://www.gstatic.com/firebasejs/9.0.2/firebase-analytics.js";
// import { getMessaging, getToken, onMessage } from "https://www.gstatic.com/firebasejs/9.0.2/firebase-messaging.js";

export const NewDashboard = () => {

  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  
  const [totalcust, setTotalcust] = useState(0);
  const [welcomeMerchant, setWelcomeMerchant] = useState(null);
  const [amount, setAmount] = useState(0);
  const [gcmToken, setGcmToken] = useState(null)
  const [gmv, setGmv] = useState({
    status: "failure",
    code: 204,
    msg: "data not found",
    data: { total_bills_amount: 0.0, amount_in_udhaar: 0.0 },
  });

  const requestOptions = {
    method: "GET",
    url: Helper.getWsUrl() + "/contact/gmv/?web=1",
    headers: { "Content-Type": "application/json", "token": cookies.token },
  };

  const requestOption = {
    method: "GET",
    url: Helper.getWsUrl() + "/contact/total_data/?web=1",
    headers: { "Content-Type": "application/json", "token": cookies.token },
  };
  const getContactDetailsRequest = {
    method: "GET",
    url: Helper.getWsUrl() + "/contact/get_contact/0/?app_type=0&web=1",
    headers: { "Content-Type": "application/json", "token": cookies.token },
  };

  useEffect(() => {
    axios(requestOptions)
      .then((response) => {
        if (response.data.code === 200) {
          setGmv(Helper.encypt_decrypt(response.data.data));
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });

    axios(requestOption)
      .then((response) => {
        if (response.data.code === 200) {
          setTotalcust(Helper.encypt_decrypt(response.data.data).Total_customer);
          setAmount(Helper.encypt_decrypt(response.data.data).amount);
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });

    axios(getContactDetailsRequest)
      .then((response) => {
        if (response.data.code === 200) {
          localStorage.setItem("udhaar",Helper.encypt_decrypt(response.data.data).shop_credit)
          localStorage.setItem("inventory",Helper.encypt_decrypt(response.data.data).inventory)
          localStorage.setItem("fastBilling",Helper.encypt_decrypt(response.data.data).fast_billing)
          setWelcomeMerchant(Helper.encypt_decrypt(response.data.data).name);
          localStorage.setItem("contact_id",Helper.encypt_decrypt(response.data.data).p_id)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });

      // if(cookies.gcm_id != undefined) {
      //   updateGCMId(cookies.gcm_id)
      // } else {
      //   addGCMId()
      // }
  }, [gcmToken]);

  useEffect(() => {
    requestPermission()
  },[])

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if(permission === 'granted') {
      const token = await getToken(messaging, {vapidKey: 'BMxJu7HzNYlKaw82xGIiJ27bTZ7De_ukXXKDw7jSh4B-laZ90-AbPvCLdewoWWije9KxDnYcmfYn5qWz0b3614g'})
      setGcmToken(token)
    } else if(permission === 'denied') {
      console.log("You denied for the notification")
    }
  }

  // const addGCMId = () => {
  //   const reqestGCMToken = {
  //     method: "POST",
  //     url: Helper.getWsUrl() + "/gcm/add_gcm_data/?web=1",
  //     headers: { "Content-Type": "application/json",},
  //     data: {
  //       gcm_token: gcmToken && gcmToken,
  //       device_info: {
  //         IP: "192.168.1.116",
  //       },
  //       profile_type: 0,
  //     },
  //   };
  //   axios(reqestGCMToken)
  //     .then((response) => {
  //       if (response.data.code === 200) {
  //         setCookie("gcm_id", Helper.encypt_decrypt(response.data.data)[0].gcm_id);
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // }

  // const updateGCMId = (gcmId) => {
  //   const reqestGCMToken = {
  //     method: "PATCH",
  //     url: Helper.getWsUrl() + `/gcm/update_gcm/${gcmId}/?web=1`,
  //     headers: { "Content-Type": "application/json", "token": cookies.token },
  //   };
  //   axios(reqestGCMToken)
  //     .then((response) => {
  //       if (response.data.code === 201) {
  //         console.log("gcm update")
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // }

  useEffect(() => {
    if(welcomeMerchant && welcomeMerchant != null){
      localStorage.setItem("shopName", welcomeMerchant)
    }
  },[welcomeMerchant])

  return (
    <Main totalCustomer={totalcust} gmv={gmv} amount={amount} />
  );
};
