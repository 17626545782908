import "./Gethelp.css";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState, useCallback } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

const GetHelp = () => {

  const navigate = useNavigate()

  const [con, setCon] = useState(false);
  const [getsupportticket, setGetsupportticket] = useState([]);
  const [postdescdata, setPostdescdata] = useState(null);
  const [getyoutube, setGetyoutube] = useState("");
  const [faqgethelp, setFaqgethelp] = useState("");
  const [forVideoUrl, setForVideoUrl] = useState(null);
  const [queryid, setQueryId] = useState("");
  const [querydata, setQueryData] = useState([]);
  const [queryreply,setQueryReply]=useState([]);
  const [querydate,setQueryDate]=useState("");

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const getpostdataFunc = useCallback((event) => {
    const { name, value } = event.target;
    setPostdescdata({ ...postdescdata, [name]: value });
  });

  useEffect(() => {
    if (postdescdata && postdescdata != null) {
      setCon(true);
    }
  }, [postdescdata]);
 
  const style = {
    border: "none",
    ":focus": {
      outline: "none",
    },
  };

  const getSupportTicketHelpApi = () => {
    const requestOptions = {
      method: "GET",
      url: Helper.getWsUrl() + `/support/ticket_list/?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
    };
    axios(requestOptions)
      .then((response) => {
        if (response.data.code === 200) {
          setGetsupportticket(Helper.encypt_decrypt(response.data.data));
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSupportTicketHelpApi();
  }, []);

  const postSupportHelpApi = () => {
    const requestgethelp = {
      method: "POST",
      url: Helper.getWsUrl() + `/support/AddQuery/?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
      data: {
        description: postdescdata && postdescdata.discription,
        tittle: postdescdata && postdescdata.title,
        user_type: 0,
      },
    };
    axios(requestgethelp)
      .then((response) => {
        if (response.data.code === 201) {
          window.location.reload()
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getyoutubevideo = () => {
    const requestOptions = {
      method: "GET",
      url: Helper.getWsUrl() + `/promotions/get_youtubevideo/?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
    };
    axios(requestOptions)
      .then((response) => {
        if (response.data.code === 200) {
          setGetyoutube(Helper.encypt_decrypt(response.data.data));
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getyoutubevideo();
  }, []);

  const getsettingFaq = () => {
    const requestOptions = {
      method: "GET",
      url: Helper.getWsUrl() + `/settings/get_faq/`,
      headers: { "Content-Type": "application/json", token: cookies.token },
    };
    axios(requestOptions)
      .then((response) => {
        if (response.data.code === 200) {
          setFaqgethelp(response.data.data);
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(()=>{
    getsettingFaq()
  },[])

  const getreply = (e) => {
    setQueryReply(e.target.value);  
  };

  const QueryList=(id)=>{
    const Queries = {
      method: "GET",
      url: Helper.getWsUrl() + `/support/ticket_details/${id}?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
    };
    axios(Queries)
      .then((response) => {
        if (response.data.code === 200) {
          setQueryData(Helper.encypt_decrypt(response.data.data).all_reply)
          setQueryDate(Helper.encypt_decrypt(response.data.data).ticket_details)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
          navigate('/')
          localStorage.setItem("isAuthenticated", false)
          localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }    
   
  const postQueryReply = () => {
    const postQuery = {
      method: "POST",
      url: Helper.getWsUrl() + `/support/AddReply/${queryid}?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
      data: {
              reply:queryreply
            },
    };
    axios(postQuery)
      .then((response) => {
      if (response.data.code === 200) {
        window.location.reload()
      } else if (response.data.code === 401) {
        removeCookie("logged_in")
        removeCookie("token")
        setTimeout(() => {
        navigate('/')
        localStorage.setItem("isAuthenticated", false)
        localStorage.removeItem("isAuthenticated")
        }, 1000)
      }
    })
    .catch((error) => {
      alert(error);
    });
  };

  return (
    <>
      <div className="col-10" id="main "  style={{ backgroundColor: "#F9F9F9", padding: "1%" }} >
        <div id="allTableItemList" style={{ backgroundColor: "white", overflowY: "auto", height: "78vh", }} >
          <div style={{display: "flex", justifyContent: "space-between", padding: "1%", }} >
            <p style={{ fontWeight: "600", fontSize: "20px" }}>
              Get Help & Support
            </p>
            <button data-bs-toggle="modal" data-bs-target="#helpModal" style={{ backgroundColor: "#008C53", borderRadius: "20px", border: "none", padding: "10px 30px", fontSize: "15px",color: "white",}} >
              Raise a Query
            </button>
          </div>

          <div class="modal fade" id="helpModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <input name="title" onChange={getpostdataFunc} className="inp"placeholder="Add Title..." />
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style={{overflow:"hidden"}}>
                  <textarea id="getHelpTextArea" placeholder="Write Here.." onChange={getpostdataFunc} name="discription" style={{width:"100%", resize:"none", border:"none"}}/>
                </div>
                {con ? (
                    <div style={{width:"100%", overflow:"hidden", textAlign:"right", padding:"0 20px 20px 0"}}>
                      <button onClick={postSupportHelpApi} style={{color: "#fff", background:"#008c53", border:"none", borderRadius:"25px", padding:"5px 25px"}} data-bs-dismiss="modal">
                        Done
                      </button>
                    </div>
                  ) 
                : null }
              </div>
            </div>
          </div>

          <div style={{ padding: "1%" }}>
            <p style={{ fontWeight: "500", fontSize: "20px" }}>
              Recent Tickets
            </p>
            {getsupportticket && getsupportticket.map((value) => {
              return (
                <div onClick={() => {setQueryId(value.id); QueryList(value.id);}} data-bs-toggle="modal" data-bs-target="#replyModal"
                    style={{ borderRadius: "5px", margin: "1%", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px", boxSizing: "border-box", marginBottom: "2px", width: "90%", padding: "10px",  }}>
                  <span style={{ color: "green", fontSize: "35px" }}>
                    .
                  </span>
                  <span style={{ fontWeight: "600", fontSize: "14px",padding: "5px",  }}>
                    {value.title}
                  </span>
                  <p style={{fontWeight: "400", fontSize: "14px", padding: "1%", }} >
                    {value.description}
                  </p>
                </div>
              );
            })}
          </div>

          <div class="modal fade" id="replyModal" tabindex="-1" role="dialog" aria-labelledby="replyModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <div style={{width:"100%", textAlign:"right"}}>
                    <p style={{width:"100%", fontWeight:"500"}}>{querydate && querydate.add_date_time}</p>
                  </div>
                  <div>
                    <p style={{margin:"0", padding:"0px", color:"#008c53", fontWeight:"600", fontSize:"18px"}}>{querydate && querydate.title}</p>
                    <p style={{margin:"0", padding:"0px", fontSize:"16px", fontWeight:"400"}}>{querydate && querydate.description}</p>
                  </div>
                  <hr></hr>
                  {querydata && querydata.map((value)=>{
                    return (
                      <>
                        <div style={{display:"flex"}}>
                          <div style={{width:"70%"}}>
                            <p style={{margin:"0", padding:"0px", fontSize:"16px", fontWeight:"500"}}>{value.reply_by}</p>
                            <p style={{margin:"0", padding:"0px", fontSize:"14px", fontWeight:"400"}}>{value.reply}</p>
                          </div>
                          <div style={{textAlign:"right", width:"30%"}}>
                            <p style={{color:"#008c53", fontSize:"14px"}}>{value.add_date_time}</p>
                          </div>
                        </div>
                        <hr></hr>
                      </>
                    );
                  })}
                  <div style={{marginTop:"50px"}}>
                    <input style={{ width: "80%", borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgba(0,140,83)" }} placeholder="Type here..." onChange={getreply} />
                    <button onClick={() => {postQueryReply()}} style={{ backgroundColor: "rgba(0,140,83)", color: "white", border: "none", padding: "5px 10px", marginLeft: "10px" }}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div style={{ padding: "1%" }}>
              <p style={{ fontWeight: "500", fontSize: "20px" }}>
                Knowledge base{" "}
              </p>
              <div style={{ display: "flex", justifyContent: "space-around",padding: "1%", }}  >
                {getyoutube && getyoutube.map((value) => {
                  return (
                    <>
                      <div style={{ margin: "1%" }}>
                        <div onClick={() => {setForVideoUrl(value.y_id); }} >
                          <img alt="" data-bs-toggle="modal" data-bs-target="#exampleModal" data-backdrop="static" data-keyboard="false"
                              style={{ border: "1px solid #008C53",height: "30%", width: "90%", }}
                              src={ value.thumbnail} >
                          </img>
                        </div>
                        <p style={{ fontWeight: "400", fontSize: "18px" }} >
                          {value.title}
                        </p>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>

          <div style={{ padding: "1%" }}>
            <p style={{ fontWeight: "500", fontSize: "20px" }}>
              Frequently Asked Questions (FAQ’S)
            </p>
          </div>
          <div style={{ padding: "1%" }}>              
            <div class="accordian_myclass accordion accordion-flush" id="accordionFlushExample">
              {faqgethelp && faqgethelp.map((value, index)=>{
                return(
                  <div class="accordion-item">
                    <h2 class="accordion-header" id={`flush-heading${index}`}>
                      <button style={{color:"#008c53", background:"none", border:"none"}} class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls="flush-collapseOne">
                        {value.question}
                      </button>
                    </h2>
                    <div id={`flush-collapse${index}`} class="accordion-collapse collapse" aria-labelledby={`flush-heading${index}`} data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">{value.answer}</div>
                    </div>
                  </div>
                )
              })}
            </div>          
          </div>    
        </div>
      </div>

      <div class="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false" >
        <div class="modal-dialog">
            <ReactPlayer url={`https://www.youtube.com/watch?v=${forVideoUrl}`} playing/>          
        </div>
      </div>
    </>
  );
};
export default GetHelp;
