import React,{useEffect, useState} from 'react'
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';

const PurchaseDetails = ({getVoucherId, globalVoucherId}) => {

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()

    const [getListDetail, setGetListDetail] = useState(null)
    const [getItemListAmt, setGetItemListAmt] = useState (0)
    // const [getItemVarientDtl, setGetItemVarientDtl] = useState ([])
    const [voucheridForApi, setVoucherIdForApi] = useState(null)
    const [tblItemDetails, setTblItemDetails] = useState(null)
    const [paymentModeForTbl, setPaymentModeForTbl] = useState([])

    useEffect(() => {
        if(getVoucherId && getVoucherId != null) {
            setVoucherIdForApi(getVoucherId)
        }
    },[getVoucherId])

    useEffect(() => {
        if(globalVoucherId && globalVoucherId != null) {
            setVoucherIdForApi(globalVoucherId)
        }
    },[globalVoucherId])

    useEffect(() =>{
        if(voucheridForApi && voucheridForApi != null) {
            const getListDetailUrl = {
                method : "GET",
                url: Helper.getWsUrl() + `/vouchers/get_purchase_detail/${voucheridForApi}/?web=1`,
                headers: { "Content-Type": "application/json", Token: cookies.token },
            }
            axios(getListDetailUrl)
            .then((response) => {
                // setCookie("token", response.headers.token); 
                if (response.data.code === 200) {
                    setGetListDetail(Helper.encypt_decrypt(response.data.data))
                    setTblItemDetails(Helper.encypt_decrypt(response.data.data).item_list)
                    setPaymentModeForTbl(Helper.encypt_decrypt(response.data.data).payment_modes)
                    // setGetItemVarientDtl(Helper.encypt_decrypt(response.data.data).item_list[0].varient_detail)
                }
                else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                }
            })
            .catch((error) => {
                // alert(error);
            })
        }
    },[voucheridForApi])

    useEffect(() => {
        if(tblItemDetails && tblItemDetails.length > 0) {
            setGetItemListAmt(0)
            let sum = 0
            tblItemDetails.map((value, index) => {
                sum += parseFloat(value.varient_detail[0].selling_price * value.varient_detail[0].stock)
                setGetItemListAmt({
                    ...getItemListAmt,
                    sum
                })
            })
        }
    },[tblItemDetails])

    // console.log("getItemListAmt ", tblItemDetails)


    const myStyle ={
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "22px",
       }

    return (
        <div id="box" style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>          
            <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <h4 style={{fontWeight: "600", fontSize: "18px", lineHeight: "29px"}}>Purchase Details</h4>
                {getListDetail && getListDetail.status === 1 ?
                    <button style={{border:"none", background:"#309f73", padding:"5px 30px",color:"#fff", fontSize:"14px", fontWeight:"700", borderRadius:"10px"}}>Paid</button>
                :
                    <button style={{border:"none", background:"#DC463D", padding:"5px 30px",color:"#fff", fontSize:"14px", fontWeight:"700", borderRadius:"10px"}}>Unpaid</button>
                } 
            </div>
            <div style={{width:"100%", boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.1)", borderRadius:"20px", padding:"16px", marginTop:"20px", display:"flex", justifyContent:"space-between"}}>
                <div style={{textAlign:"left"}}>
                    <p style={{fontSize:"16px", fontWeight:"500", lineHeight:"26px", margin:"0"}}>{getListDetail && getListDetail.contact_name} &nbsp; {getListDetail && getListDetail.mobile_number != null ? `(${getListDetail && getListDetail.mobile_number})` : null}</p>
                    <p style={{fontSize:"16px", fontWeight:"500", lineHeight:"26px", margin:"0"}}>{getListDetail && getListDetail.gst_number != null ? `GST Number : ${getListDetail && getListDetail.gst_number}` : null}</p>
                    <p style={{fontSize:"16px", fontWeight:"500", lineHeight:"26px", margin:"0"}}>{getListDetail && getListDetail.bill_number != null ? `Bill Number : ${getListDetail && getListDetail.bill_number}` : null}</p>
                </div>
                <div style={{textAlign:"left"}}>
                    {getListDetail && getListDetail.status === 1 ?
                        <>
                            <p style={{fontSize:"16px", fontWeight:"500", lineHeight:"26px", margin:"0"}}>Purchase Date : {getListDetail && getListDetail.date}</p>
                            <p style={{fontSize:"16px", fontWeight:"500", lineHeight:"26px", margin:"0"}}>Payment Mode : {paymentModeForTbl && paymentModeForTbl.map((value, index) => {
                                if(value.mode === 1) {
                                    return("Cash ")
                                }
                                if(value.mode === 3) {
                                    return("Card ")
                                }
                                if(value.mode === 4) {
                                    return("Online ")
                                } 
                                if(value.mode === 5) {
                                    return("Cheque ")
                                }
                            })}</p>
                        </>
                    :
                        <p style={{fontSize:"16px", fontWeight:"500", lineHeight:"26px", margin:"0"}}>Expected Date : {getListDetail && getListDetail.expected_date}</p>
                    }
                </div>
            </div>
            <div style={{borderRadius: "20px", width:"100%", overflow:"hidden", border:"1px dashed #d9d9d9", marginTop:"20px", padding:"54px 36px 45px"}}>
                <table class="table table-striped" style={{border:"none"}}>
                    <thead>
                        <tr>
                            <th scope="col" style={{fontSize:"16px", fontWeight:"500", border:"none"}}>Sno.</th>
                            <th scope="col" style={{fontSize:"16px", fontWeight:"500", border:"none"}}>Item Name</th>
                            <th scope="col" style={{fontSize:"16px", fontWeight:"500", border:"none"}}>Stock</th>
                            <th scope="col" style={{fontSize:"16px", fontWeight:"500", border:"none"}}>Price</th>
                            <th scope="col" style={{fontSize:"16px", fontWeight:"500", border:"none"}}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tblItemDetails && tblItemDetails.map((value, index) => {
                        return(
                                <tr>
                                    <td style={{border:"none"}}>{index + 1}</td>
                                    <td style={{border:"none"}}>{value.item_detail.item_name}</td>
                                    <td style={{border:"none"}}>{value.varient_detail[0].stock}</td>
                                    <td style={{border:"none"}}>{value.varient_detail[0].selling_price}</td>
                                    <td style={{border:"none"}}>{parseFloat((value.varient_detail[0].selling_price) * (value.varient_detail[0].stock))}</td>
                                </tr>
                            )
                        })}
                                <tr>
                                    <td style={{border:"none"}}></td>
                                    <td style={{border:"none"}}>Total</td>
                                    <td style={{border:"none"}}></td>
                                    <td style={{border:"none"}}></td>
                                    <td style={{border:"none"}}><span>{getItemListAmt && getItemListAmt.sum}</span></td>
                                </tr>
                    </tbody>
                </table>
                <div style={{float:"right", marginTop:"30px"}}>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div style={{textAlign:"right"}}>
                            <p style={{fontSize:"14px", fontWeight:"500"}}>Total Amount</p>
                            {/*<p style={{fontSize:"14px", fontWeight:"500"}}>Tax</p>*/}
                        </div>
                        <div style={{textAlign:"left", marginLeft:"30px"}}>
                            <p style={{fontSize:"14px", fontWeight:"500"}}>{getItemListAmt && getItemListAmt.sum}</p>
                            {/*<p style={{fontSize:"14px", fontWeight:"500"}}>{parseFloat(0)}</p>*/}
                        </div>
                    </div>
                    <hr style={{padding:"0", marginTop:"-10px"}}></hr> 
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div style={{textAlign:"right"}}>
                            <p style={{fontSize:"14px", fontWeight:"500"}}>Grand Total</p>
                        </div>
                        <div style={{textAlign:"left", marginLeft:"30px"}}>
                            <p style={{fontSize:"14px", fontWeight:"500"}}>{getItemListAmt && getItemListAmt.sum}</p>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default PurchaseDetails