import React, { useEffect, useState } from 'react'
import AddOrder from './AddOrder'
import OrderAddNew from './OrderAddNew'
import OrderDetail from './OrderDetail'
import OrderLeft from './OrderLeft'
import OrderAddGrahak from './OrderAddGrahak'
import OrderFirstTimeUser from './OrderFirstTimeUser'
import Helper from "../util/Helper";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import Billing from './Billing'

const Order = ({globalIbId}) => {

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const [showOrderDetail, setShowOrderdetail] = useState(false)
    const [showAddOrderPage, setShowAddOrderPage] = useState(false)
    const [getIbIdForDetail, setGetIbIdForDetail] = useState(null)
    const [showOrderAddGrahak, setShowOrderAddGrahak] = useState(false)
    const [grahakDetails, setGrahakDetails] = useState(null)
    const [getBookedByForDetail, setGetBookedByForDetail] = useState(null)
    const [showOrderList, setShowOrderList] = useState([])
    const [orderEmptystate, setOrderEmptyState] = useState(false)
    const [skeletonLoading, setSkeletonLoading] = useState(true)
    const [orderFilter, setOrderFilter] = useState(false)
    const [orderFilterValue, setOrderFilterValue] = useState(null)
    const [bookingToBilling, setBookingToBilling] = useState(false)
    const [bookingItemListForBilling, setBookingItemListForBilling] = useState([])
    const [bookingItemBillIdForBilling, setBookingItemBillIdForBilling] = useState(null)
    const [bookingCustomerIdForBilling, setBookingCustomerIdForBilling] = useState(null)
    const [bookingTotalAmtForBilling, setBookingTotalAmtForBilling] = useState(null)

    useEffect(() => {
        if(globalIbId && globalIbId != null) {
            setShowOrderdetail(true)
        }
    },[globalIbId])

    useEffect(() => {
        const getOrderListUrl = {
            method: "GET",
            url: Helper.getWsUrl() + `${!orderFilter ? `/booking/get_merchant_booking/?web=1` : `/booking/get_merchant_booking/?web=1&status=${orderFilterValue}`}`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(getOrderListUrl)
        .then((response) => {           
            if (response.data.code === 200) {
                setShowOrderList(Helper.encypt_decrypt(response.data.data))
                setOrderEmptyState(false)
                setSkeletonLoading(false)
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            } else if(response.data.code === 204) {
                setShowOrderList([])
                if(!orderFilter) {
                    setOrderEmptyState(true)
                    setSkeletonLoading(false)
                }
            }  
        })
        .catch((error) => {
            alert(error);
        })
    },[orderFilter, orderFilterValue])

    return (
        <>     
            {bookingToBilling ?
                <Billing bookingTotalAmtForBilling = {bookingTotalAmtForBilling} bookingCustomerIdForBilling = {bookingCustomerIdForBilling} bookingItemBillIdForBilling = {bookingItemBillIdForBilling} bookingItemListForBilling = {bookingItemListForBilling}/>
            :
                <>
                    {showAddOrderPage ? 
                        <OrderAddNew setShowOrderAddGrahak = {setShowOrderAddGrahak} grahakDetails = {grahakDetails} setShowAddOrderPage = {setShowAddOrderPage}/>
                    :
                        <div className='col-10' style={{ backgroundColor: "rgb(245, 245, 245)", padding: "20px", display: "flex", }}>
                            {orderEmptystate ? 
                                <OrderFirstTimeUser setOrderEmptyState={setOrderEmptyState} setShowOrderAddGrahak={setShowOrderAddGrahak} />
                            :
                                <>
                                    <OrderLeft orderFilterValue = {orderFilterValue} setOrderFilterValue = {setOrderFilterValue} setOrderFilter = {setOrderFilter} skeletonLoading = {skeletonLoading} showOrderList = {showOrderList} setGetBookedByForDetail = {setGetBookedByForDetail} setGetIbIdForDetail = {setGetIbIdForDetail} setShowOrderdetail = {setShowOrderdetail}/> 
                                    {showOrderDetail ? 
                                        <OrderDetail setBookingToBilling = {setBookingToBilling} bookingItemListForBilling = {bookingItemListForBilling} setBookingTotalAmtForBilling = {setBookingTotalAmtForBilling} setBookingCustomerIdForBilling = {setBookingCustomerIdForBilling} setBookingItemBillIdForBilling = {setBookingItemBillIdForBilling} setBookingItemListForBilling = {setBookingItemListForBilling} getBookedByForDetail = {getBookedByForDetail} globalIbId = {globalIbId} getIbIdForDetail = {getIbIdForDetail} setShowOrderdetail = {setShowOrderdetail}/>
                                    :
                                        <>
                                            {showOrderAddGrahak ? 
                                                <OrderAddGrahak setShowAddOrderPage = {setShowAddOrderPage} setGrahakDetails = {setGrahakDetails}/>
                                            :
                                                <AddOrder  setShowOrderAddGrahak = {setShowOrderAddGrahak}/> 
                                            }
                                        </>
                                    }
                                </>
                            }
                        </div>
                    }  
                </>
            }                    
        </>      
    )
}

export default Order

