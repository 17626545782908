import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const Privacypolicy = () => {
    
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()

    const [encode, setEncode] = useState("")
    const Privacy = () => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/settings/get_merchant_privacy_policy/`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                // setCookie("token", response.headers.token);
                if (response.data.code === 200) {
                    setEncode(Buffer.toString(response.data.data.merchant_privacy_policy))
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })
    }



    useEffect(() => {
        Privacy();
    }, [])


    return (
        <>
            <div className='col-10' id='main-div' style={{ backgroundColor: "#f9f9f9", overflow: "hidden", padding: "20px", display: "flex", height: "90%", overflow: "auto" }}>
                <div style={{ backgroundColor: "white", width: "100%" }}>
                   
                    <div style={{backgroundColor:"white",padding:"5%"}}>
                    <p style={{ fontWeight: "900" }}> Privacy Policy</p>
                        <p style={{ }}>
                            This policy is owned and operated by Indotechs Software Private Limited through mobile application and website ENBILL.<br></br>
                            <br></br>
                           <p style={{fontWeight:"900"}}>A. GENERAL</p>
                            In the course of using this website / Mobile Application in the name of ENBILL or availing the products and services, the company may become privy to the personal information of its users, including information that is of a confidential nature. At Indotechs Software Pvt Ltd, we understand the importance of protecting your privacy. Our Privacy Policy covers our treatment of personal information collected when anyone is on our website www.indotechs.in and our mobile Application - ENBILL or uses the products and services we provide; it neither addresses nor applies to individuals or third-party practices over which we have no control. This includes, but is not limited to, cookies or embeds which may be used by our advertisers.<br></br>
                            Indotechs only collects information relevant and necessary for providing the services it offers; as such, our stated goal is to customize your experience and let you get the most out of what we offer. To that end:<br></br>
                            1. For your security and privacy, your personal information is protected with a password created by you and shall not be made public without your consent.<br></br>
                            2. You will always have access to your personal information and you may edit or delete it at any time.<br></br>
                            3. Company - name and Mobile Application is sensitive to any unsupervised use of the website or Mobile Application by children under the age of 18. If you are under the age of 18 years, you may use our website/ Mobile Application only with the involvement of a parent or guardian.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}> B. INFORMATION COLLECTED</p>
                            Indotechs / EnBill collects information related to you when you directly provide it to us or through third-party sources. During the use of website and mobile application, we may collect and process information from the users including:<br></br>
                            1. Information that the users provide to Indotechs/Enbill by filling in forms on the website/mobile application. This includes contact information such as name, email address, mailing address, phone number, financial information, bank account number, password and preferences information such as favourites lists and transaction history;<br></br>
                            2. Information that the users provide when the users write directly to Indotechs /Enbill (including by way of email, WhatsApp or messages);<br></br>
                            3. Information that the users provide to Indotechs/Enbill by completing surveys;<br></br>
                            4. Indotechs Software Pvt Ltd may also use certain kinds of technology such as cookies and web beacons to collect information. Among other things, the use of cookies and web beacons enables Company to improve its services and products by identifying the areas and features which are most popular and by counting the number of computers, mobile phones or other devices accessing Indotechs’ website or Mobile Application in the name of ENBILL, personalizing and improving the experience, recording preferences, and allowing the User to visit www.indotechs.in without re-entering his/her member ID and/or password each time.<br></br>
                            5. Company may also collect the following information:<br></br>
                            a. the internet protocol (IP) address used to connect your computer to the internet;<br></br>
                            b. login, e-mail address, and password;<br></br>
                            c. Geo-tracking information;<br></br>
                            d. device metrics such as when a device is in use, application usage, connectivity data, and any errors or event failures;<br></br>
                            e. Service metrics (e.g., the occurrences of technical errors, your interactions with service features and content, your settings preferences and backup information, location of your device running an application, information about uploaded images and files such as the file name, dates, times and location of your images);<br></br>
                            f. version and time zone settings;<br></br>
                            g. purchase and content use history, which we sometimes aggregate with similar information from other customers to create features like trending Arti logs;<br></br>
                            h. the full Uniform Resource Locator (URL) clickstream to, though, and from our websites, including date and time; products and content you viewed or searched for; page response times, download errors, length of visits to certain pages, and page interaction information (such as scrolling, clicks, and mouse-overs).<br></br>
                            i. phone numbers used to call our customer service number;<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>C. DISCLOSURE OF PERSONAL INFORMATION</p>
                            Indotechs/ EnBill pledges that it shall not sell or rent users’ personal details to anyone. We will protect every bit of the users’ business or personal information and maintain the confidentiality of the same. With this seal of trust, Indotechs/ EnBill makes its services available to the users for assessment and analysis that include credit and behavior scoring, market and product analysis.<br></br>
                            <br></br>
                            1. Indotechs/ EnBill guarantees that it is going to keep all information confidential except in the following cases:<br></br>
                            a. Indotechs/ EnBill may disclose users’ information to governmental and other statutory bodies who have appropriate authorization to access the same for any specific legal purposes.<br></br>
                            b. Indotechs/ EnBill may disclose users’ information if it is under a duty to do so in order to comply with any legal obligation, or in order to enforce or apply the Terms of Use (displayed on the website), or to protect the rights, property or safety of Indotechs, its users or others. This includes exchanging information with other companies / agencies that work for fraud prevention and credit reference.<br></br>
                            c. Indotechs/ EnBill may disclose users’ information to its agents under a strict code of confidentiality.<br></br>
                            d. Indotechs/ EnBill may disclose users’ information to such third parties to whom it transfers its rights and duties under the customer agreement entered into with the users. In such an event, the said third parties’ use of the information will be subject to such confidentiality obligations as contained in this Policy.<br></br>
                            e. In the event that Indotechs sells or buys any business or assets, it may disclose the users’ information to the prospective seller or buyer of such business or assets. user, email and visitor information are generally one of the transferred business assets in these types of transactions. Indotechs/ EnBill may also transfer or assign such information in the course of corporate divestitures, mergers or dissolution.<br></br>
                            f. Indotechs may disclose users’ information to any member of its related or group companies including its subsidiaries, its ultimate holding company and its subsidiaries, as the case may be.<br></br>
                            <br></br>
                            2. Indotechs/ EnBill never will sell or rent personal information of its clients to anyone, at any time, for any reason. Indotechs/ EnBill may use the user’s personal information in the following ways, viz:<br></br>
                            a. Monitor, improve and administer the website/ Mobile Application and improve the quality of services;<br></br>
                            b. Analyze how the website/ Mobile Application is used, diagnose service or technical problems, maintain security;<br></br>
                            c. Remember information to help the user effectively access the website / Mobile Application;<br></br>
                            d. Monitor aggregate metrics such as total number of views, visitors, traffic and demographic patterns;<br></br>
                            e. To confirm the user’s identity in order to determine its eligibility to use the website/ Mobile Application and avail of the services;<br></br>
                            f. To notify the user about changes to the website / Mobile Application;<br></br>
                            g. To enable Indotechs/ EnBill to comply with its legal and regulatory obligations;<br></br>
                            h. To help the user apply for certain products and services.<br></br>
                            i. For the purpose of sending administrative notices, service-related alerts and other similar communication with a view to optimizing the efficiency of the website/ Mobile Application.<br></br>
                            j. Doing market research, troubleshooting, protection against error, project planning, fraud and other criminal activity.<br></br>
                            k. To reinforce Indotechs/ EnBill Terms of Use.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}> D. YOUR ACCEPTANCE OF THIS POLICY</p>
                            All data and information provided by Subscriber through its use of the Services is subject to the Privacy Policy. By using the Services, Subscriber accepts and agrees to be bound and abide by the Privacy Policy. All data and information provided by Customer through its use of the Service is subject to Provider’s Privacy Policy, as amended from time-to-time, which can be viewed by clicking the “Privacy” hypertext link located within the Service. By using the Service, Customer accepts and agrees to be bound and abide by such privacy policy. By using the Service, you agree that you have read, understood, and agree to the data collection, use, and disclosure provisions set forth in the Privacy Policy.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>E. YOUR RIGHTS,</p>
                            The users shall have the right to make amendments to their data, to delete data, to review the information that the company hold and other rights.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}> F. COLLECTION OF INFORMATION BY THIRD PARTIES,</p>
                            Company does not host some of the content displayed on our Services. Users have the ability to post content that is actually hosted by a third party , but is embedded in our pages (an Embed). When you interact with an Embed, it can send information about your interaction to the hosting third party just as if you were visiting the third party’s site directly. Company does not control what information third parties collect through Embeds or what they do with the information. This Privacy Policy does not apply to information collected through Embeds. The privacy policy belonging to the third party hosting the Embed applies to any information the Embed collects, and we recommend you review that policy before interacting with the Embed.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>G.CONTACT US:</p>
                            Any questions or concerns should be addressed to info@indotechs.in<br></br>
                            OR<br></br>
                            K-37A,Basement, Kailash Colony,<br></br>
                            Near Kailash Colony Metro Station<br></br>
                            New Delhi-110048<br></br>
                        </p>

                    </div>

                </div>


            </div>
        </>
    )
}

export default Privacypolicy