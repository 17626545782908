import React from 'react'

const PurchaseAddNew = ({setShowPurchaseAddItem}) => {
    
    return (
        <div id="box" style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>          
            <img src={require("../assets/images/AddPrchaseImg.png")} alt='' class="img-fluid" />
            <div>           
                <button onClick={()=> setShowPurchaseAddItem(true)}
                        style={{backgroundColor: "#008C53",  color: "white",  borderRadius: "20px", padding: "7px 0px", marginTop:"40px", border: "1px solid #008C53"}} >
                    <img src={require("../assets/images/icon.png")}
                            height={"13%"}
                            width={"13%"}
                            class="img-fluid">
                    </img>
                <span style={{ fontSize: "17px", marginLeft:"10px", fontWeight:"600" }}>Add New</span>
                </button>
            </div> 
        </div>  
    )
}

export default PurchaseAddNew
