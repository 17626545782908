import './ClickUdhaar.css'
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useCookies } from "react-cookie";
import Helper from "../util/Helper";
import { useNavigate } from 'react-router-dom';

const UdhaarPaymentHistory = ({ setShowBill, customerId}) => {

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()

    const [history, setHistory] = useState([]);
    const customer_id = customerId;
   
    const paymentHistory = () => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/vouchers/shop_credit_items_of_cus_new/${customer_id}?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                // setCookie("token", response.headers.token);
                if (response.data.code === 200) {
                    setHistory(Helper.encypt_decrypt(response.data.data))                   
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect((customerId) => {
        paymentHistory();
    }, [customerId])


    return (
        <div>
            <div style={{ display: "flex", justifyContent: "end", marginTop: "2%" }}>
                <img style={{cursor:"pointer"}} onClick={() => setShowBill(false)} src={require("../assets/images/cancel icon.png")} class="img-fluid modalCross"></img>
            </div>
            {history && history.length > 0  ? 
                <div className="fixTableHead" style={{ backgroundColor: "white", display: "flex", flexDirection: "row", textAlign: "center", borderRadius: "10px", marginTop: "1%", justifyContent: "center", padding: "1%", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px", marginTop: "2%" }}>
                    <table class="table" style={{ border: "none" }}>
                        <thead >
                            <tr>
                                <th scope="col" style={{ width:"25%", textAlign:"center", border: "none", position: "sticky", top: "0" }}>Transaction Number</th>
                                <th scope="col" style={{ width:"15%", textAlign:"center", border: "none", position: "sticky", top: "0" }}>Total amt</th>
                                <th scope="col" style={{ width:"30%", textAlign:"center", border: "none", position: "sticky", top: "0" }}>Date & time</th>
                                <th scope="col" style={{ width:"15%", textAlign:"center", border: "none", position: "sticky", top: "0" }}>Mode of Payment</th>
                            </tr>
                        </thead> 
                        <tbody>
                            {history.map((value) => {
                                return (
                                    <tr >
                                        <td style={{ textAlign:"center", border: "none" }}>{value.payment_number}</td>
                                        <td style={{ textAlign:"center", border: "none" }}>{value.amount}</td>
                                        <td style={{ textAlign:"center", border: "none" }}>{value.payment_date}</td>
                                        <td style={{ textAlign:"center", border: "none" }}><button disabled style={{ backgroundColor: "#008C53", color: "#fff", border:"none", borderRadius:"10px", fontSize: "85%", padding:"10px 20px"}}>{value.payment_mode}</button></td>
                                    </tr>
                                )
                            })} 
                        </tbody>
                    </table>
                </div>
            : 
                <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"25%"}}>
                    <p style={{fontSize:"20px", }}>No History Found</p>
                </div>
            }
        </div>
    )
}

export default UdhaarPaymentHistory;
