import React from "react";

const BillingFirstTimeUser = ({setShowAddGrahak, setBillingEmptyState, setShowViewBills}) =>{

    return(
        <div style={{backgroundColor:"white", width:"100%", borderRadius:"20px", display:"flex",justifyContent:"center",textAlign:"center",flexDirection:"column"}}>
            <div>
                <img width={"300px"} src={require("../assets/images/billing 1.png")} style={{marginTop:"10%"}} class="img-fluid" />
            </div>
            <div>
                <p style={{color:"#8C8C8C",fontWeight:"600"}}>You’re just a step away from creating an online dukaan.</p>
            </div>
            <div>
                <button onClick={() => {
                        if(localStorage.getItem("fastBilling") != 1) {
                            setShowAddGrahak(true);
                            setBillingEmptyState(false);
                        } else {
                            setShowViewBills(true);
                            setBillingEmptyState(false);
                        }}} style={{ backgroundColor: "#008C53",  color: "white", borderRadius: "25px",  padding: "5px 15px", border: "1px solid #008C53 ",  marginTop:"5%", marginBottom:"7%" }} >
                    <img src={require("../assets/images/icon.png")} height={"13%"}  width={"13%"} style={{ position: "relative", top: "-2%", left: "-20%" }} class="img-fluid" />
                    <span style={{ fontSize: "17px" }}>Add New</span>
                </button>
            </div>        
        </div>
    )
}

export default BillingFirstTimeUser;