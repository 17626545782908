import { useState, useEffect } from "react";
import "./Grahak.css";
import AddGrahak from "./AddGrahak";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import Addnew from "./AddNew";
import UpdateGrahak from "./UpdateGrahak";
import { Pagination } from "@mui/material"; 
import { useNavigate } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import GrahakFirstTimeUser from "./GrahakFirstTimeUser";
// import {NotificationContainer, NotificationManager} from 'react-notifications';
// import 'react-notifications/lib/notifications.css';

function Grahak({setGrahakDtlFrmGTB, setShowAddGrahak, globalCustomerId, getGrahakId, setGetGrahakId, showUpdateGrahak, setShowUpdateGrahak, showAddGrahak}) {
  
  const [custlist, setCustlist] = useState([]);
  const navigate = useNavigate()
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const handleClick = (inputs) => {
      addCustomer(inputs);
  };
  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
}   
  const [minIndex, setMinIndex] = useState(0)
  const [maxIndex, setMaxIndex] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [countPage, setCountPage] = useState(1)
  const [active, setActive] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const [categoryList, setCategoryList] = useState([])
  const [grahakFilterValue, setGrahakFilterValue] = useState(null)
  const [categoryValForDtl, setCategoryValForDtl] = useState(null)
  const [activelist,setActiveList]=useState(null);
  const [grahakEmptystate, setGrahakEmptyState] = useState(false)
  const [skeletonLoading, setSkeletonLoading] = useState(true)
  const [grahakFilter, setGrahakFilter] = useState(false)

  const handlePagination = (pageNumber) => {
    setMinIndex((pageNumber - 1) * 10)
    setMaxIndex(pageNumber * 10)
    setCurrentPage(pageNumber)
  }

  const addCustomer = (inputs) => {
    if(inputs.name != null && inputs.mobileNumber != null) {
      const addCustomerRequest = {
        method: "POST",
        url: Helper.getWsUrl() + "/contact/post_customer/?web=1",
        headers: { "Content-Type": "application/json", Token: cookies.token },
        data: {
          address: inputs.address,
          gst: inputs.gst != null ? 1 : 0,
          gst_number: inputs.gst,
          gst_type: inputs.gst != null ? 1 : 0,
          logo: 0,
          mobile_number: inputs.mobileNumber,
          name: inputs.name,
          profile_type: 3,
          customer_category_id:categoryId,
        },
      };
      axios(addCustomerRequest)
        .then((response) => {
          if (response.data.code === 201) {
            // NotificationManager.success("Grahak added successfully")
            inputs.name = null;
            inputs.mobileNumber = null;
            inputs.address = null;
            inputs.gst = null;
            window.location.reload();
            setShowAddGrahak(false)
          } else if(response.data.code === 499) {
            alert("Customer already added");
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          } 
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      alert("Please fill all required fields")
    }
  };

  const getCustomer = () => {
    const getCustomerRequest = {
      method: "POST",
      url: Helper.getWsUrl() + `${!grahakFilter ? `/contact/get_customer/?web=1` : `/contact/get_customer/?web=1&customer_category=${grahakFilterValue}`}`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(getCustomerRequest)
    .then((response) => {
        if (response.data.code === 200) {
          setCustlist([]);
          var list = Helper.encypt_decrypt(response.data.data);
          list.map((e) =>
            setCustlist((oldArray) => [
              ...oldArray,
              {
                customer_id: e.customer_id,
                name: e.display_name,
                mobileNumber: e.mobile_number,
                address: e.address,
                gst: e.gst,
                customer_category: e.customer_category
              },
            ])
          );
          setGrahakEmptyState(false)
          setSkeletonLoading(false)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } else if(response.data.code === 204) {
          setCustlist([])
          if(!grahakFilter) {
            setGrahakEmptyState(true)
            setSkeletonLoading(false)
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffect(() => {
    getCustomer()
  }, [grahakFilter, grahakFilterValue]);

  useEffect(() => {
    const addCustomerRequest = {
      method: "GET",
      url: Helper.getWsUrl() + "/contact/get_customer_category/?web=1",
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(addCustomerRequest)
      .then((response) => {
        if (response.data.code === 200) {
          setCategoryList(Helper.encypt_decrypt(response.data.data))
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } 
      })
      .catch((error) => {
        alert(error);
      });
  },[])

  useEffect(()=> {
        setCountPage(Math.ceil(custlist.length / 10))
  },[custlist])

  const grahakCardFunc = () => {
    return(
      <>
        {custlist && custlist.map((e, index) => {
            return (
              index >= minIndex && index < maxIndex && (
                  <div  className={`cardborder  ${active == index && 'active'}`} key={index} onClick={() => {setGetGrahakId(e.customer_id); setCategoryValForDtl(e.customer_category); setShowUpdateGrahak(true);setActive(index)}} style={{padding:"10px", cursor:"pointer", marginBottom:"20px", width: "100%", borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px", boxSizing: "border-box", position: "relative",display:"flex", justifyContent:"space-between"  }} >
                    <div style={{width:"90%"}}>
                      <h6 className="child" style={{ fontSize:"18px", fontWeight: "600" }} >
                        {e.name}
                      </h6>
                      <p className="orderchild" style={{ fontSize: "14px", fontWeight: "500" }} >
                        {e.mobileNumber}
                      </p>
                      <p className="orderchild" style={{ fontSize: "14px", fontWeight: "500" }} >
                        {e.address}
                      </p>
                      <p className="child" style={{ fontSize: "14px", fontWeight: "500" }} >
                        {e.customer_category}
                      </p>
                    </div>
                    <div style={{width:"7%", display:"flex", justfyContent:"center", alignItems:"center", padding:"8px"}}>
                      {/* <img style={{width:"10px", height:"20px"}} src={require("../assets/images/vector.png")}/> */}
                      {active === index ?  <img src={require("../assets/images/white vector.png")} style={{ width: "10px", height: "20px" }} /> :  <img src={require("../assets/images/vector.png")} style={{ width: "10px", height: "20px" }} />}
                    </div>
                  </div>
              )
            )
        })}
      </>
    )
  }

  const FilterColor = {
    background: "rgb(0, 140, 83)",
    color: "#fff",
    borderRadius: "20px",
    border: "none",
    width:"max-content",
    padding:"5px 8px  "
  }
  const unFilteredList = {
    background: "none",
    borderRadius: "20px",
    border: "1px solid #7a7a7a",
    width:"max-content",
    padding:"5px 8px  "
  }

  return (
    <div className='col-10' style={{ backgroundColor: "rgb(245, 245, 245)", padding: "20px", display: "flex", height:"100%" }}>
      {grahakEmptystate ? 
        <GrahakFirstTimeUser setShowAddGrahak = {setShowAddGrahak} setGrahakEmptyState = {setGrahakEmptyState} /> 
      : 
        <>
          <div style={{width: "40%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff"}}>
            <div style={{position:"relative", width:"100%"}}>
              <h6 style={{ fontWeight: "600" }}>View Grahaks</h6>
              {categoryList && categoryList.length > 0 ?
                <nav className="navBar" style={{height: !navbarOpen ? "0": "20px"}}>
                  <button onClick={handleToggle}>
                    { navbarOpen ? 
                      (<img onClick={() => {setGrahakFilter(false); setActiveList(null); setCurrentPage(1); setGrahakFilterValue(null)}} src={require("../assets/images/cancel icon.png")} />) 
                      : 
                      (<img src={require("../assets/images/filter icon.png")} />)
                    }
                  </button>
                  <ul id="allTableItemList" style={{overflowX:"scroll", display:"flex", padding:"10px 0"}} className={`menuNav ${navbarOpen ? "showMenu" : ""}`}>
                    {categoryList && categoryList.map((value, index) => {
                      return(
                        <li key={index}  onClick={() => {
                                setGrahakFilterValue(value.cc_id); 
                                setActiveList(index); 
                                handlePagination(1);
                                setCurrentPage(1);
                                setGrahakFilter(true);
                              }}>
                          <a style={activelist === index ? FilterColor : unFilteredList}>{value.category_name}</a>
                        </li>)
                    })}
                  </ul>
                </nav>
              : null}
              <span>
                <img src={require("../assets/images/Plus.png")} onClick={() => {setShowAddGrahak(true); setShowUpdateGrahak(false)}} height={"24px"} width={"24px"} style={{position:"absolute", top:"0%", right: categoryList && categoryList.length > 0 ? "10%" : "0", cursor:"pointer"}} className="img-fluid" />
              </span>
            </div>
            {skeletonLoading ? 
              <div style={{height:"60vh", overflowY:"scroll"}}>
                <Skeleton count={4} borderRadius={"10px"} height={"16vh"} /> 
              </div>
            :
              <>
                {custlist && custlist.length > 0 ? 
                  <div style={{overflowY:"scroll", height:"60vh", marginTop: !navbarOpen ? "15px" : "30px"}}>
                    {grahakCardFunc()}
                  </div>
                : 
                  <div style={{height:"50vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <p style={{color:"#7a7a7a", fontSize:"18px", fontWeight:"600"}}>no data found</p>
                  </div>
                }
              </>
            }
            <div className="pageCount" style={{marginTop:"15px"}}>
              <Pagination style={{display:"flex",justifyContent:"center",alignItems:"center"}} count={countPage} defaultPage={1} page={currentPage} onChange={(event, pageNumber) => handlePagination(pageNumber)}/>
            </div>          
          </div>
          {showUpdateGrahak ? 
            <UpdateGrahak categoryValForDtl = {categoryValForDtl} setGrahakDtlFrmGTB = {setGrahakDtlFrmGTB} globalCustomerId = {globalCustomerId} getGrahakId = {getGrahakId}/>
          :
            <>
              {showAddGrahak ? 
                <AddGrahak setCategoryId = {setCategoryId} setShowAddGrahak = {setShowAddGrahak} handleClick={handleClick} />
              :
                <Addnew setShowAddGrahak = {setShowAddGrahak}/>
              }
            </>
          }
        </>
      }
      {/* <NotificationContainer /> */}
    </div>
  );
}

export default Grahak;
