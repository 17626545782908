import React, { useEffect, useState } from 'react'
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import moment from 'moment'
import "./Galla.css"
import { useNavigate } from 'react-router-dom';

const CashBookDetail = ({getDate}) => {

  const navigate = useNavigate()

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [getCashBookIncome, setGetCashBookIncome] = useState([])
  const [getCashBookPurchase, setGetCashBookPurchase] = useState([])
  const [getCashBookDetail, setGetCashBookDetail] = useState(0)
  const [getDateFrDtl, setGetDateFrDtl] = useState(null)
  const [showTodayHeading, setShowTodayHeading] = useState(true)

  const current = new Date();
  const date = moment(new Date()).format("YYYY-MM-DD");

  useEffect(() => {
    if(getDate && getDate != null) {
      setGetDateFrDtl(getDate)
      setShowTodayHeading(false)
    }else {
      setGetDateFrDtl(date)   
    }
  },[getDate])  

  useEffect(() => {
    if(getDateFrDtl && getDateFrDtl != null) {
      const getCashBookDetailUrl = {
        method: "POST",
        url: Helper.getWsUrl() + `/report/cashbook_details/?web=1`,
        headers: { "Content-Type": "application/json", 'token': cookies.token },
        data:{ 
          date:getDateFrDtl
        }
      };
      axios(getCashBookDetailUrl)
      .then((response) => {  
          // setCookie("token", response.headers.token);            
          if (response.data.code === 200) {
            // console.log("cashbook detail : ",Helper.encypt_decrypt(response.data.data))
            setGetCashBookDetail(Helper.encypt_decrypt(response.data.data))
            setGetCashBookIncome(Helper.encypt_decrypt(response.data.data).Income)
            setGetCashBookPurchase(Helper.encypt_decrypt(response.data.data).Purchase)              
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          } 
      })
      .catch((error) => {
          alert(error);  
      })
    }
  },[getDateFrDtl])
  // console.log("todays date : ",getDate)

  return (
    <div className='cashBookDtl' style={{width:"100%"}}>
      <div className='cashBookDtlUppr'>
        {showTodayHeading ? 
          <p style={{margin:"0"}}>Today's Cashbook</p>
        : null }
        <p>{getDateFrDtl && getDateFrDtl}</p>
      </div>
      <div className='cashBookDtlMidl'>
        <div className='cashBkDtlMidlFirst'>
          <img src={require('../assets/images/csahBkDtlImg-1.png')} />
          <p style={{margin:0}}>Income</p>
          <p>₹ {parseFloat(getCashBookDetail && getCashBookDetail.Total_Income).toFixed(2)}</p>
        </div>
        <div className='cashBkDtlMidlScnd'>
          <img src={require('../assets/images/csahBkDtlImg-2.png')} />
          <p style={{margin:0}}>Purchase</p>
          <p>₹ {parseFloat(getCashBookDetail && getCashBookDetail.Total_Purchase).toFixed(2)}</p>
        </div>
        <div className='cashBkDtlMidlThird'>
          <img src={require('../assets/images/csahBkDtlImg-3.png')} />
          <p style={{margin:0}}>Balance</p>
          <p>₹ {parseFloat(getCashBookDetail && getCashBookDetail.Balance).toFixed(2)}</p>
        </div>
      </div>
      <div className='cashBookDtlLower'>
        <div>
          <h1 className='incdd '>Income</h1>
          <div className='cashBookDtlLowrIncmCrdBox'>
            {getCashBookIncome && getCashBookIncome.map((value, index) => {
              return(
                <div className='card'>
                  <div className='cardDown'>
                      <div className='cardDownLeft'>
                          <p>{value.billNo}</p>
                          <p style={{color:"#7a7a7a"}}>{value.receivedBy}</p>
                      </div>
                      <div className='cardDownRight'>
                          <p>₹ {value.receivedAmt}</p>
                      </div>
                  </div>
                  <div className='cardRgtCorner'>
                      <p>Cr.</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div>
          <h1 className='incdd '>Purchase</h1>
          <div className='cashBookDtlLowrIncmCrdBox'>
            {getCashBookPurchase && getCashBookPurchase.map((value,index) => {
              return(
                <div className='card'>
                  <div className='cardDown'>
                    <div className='cardDownLeft'>
                        <p>{value.Voucher_number}</p>
                        <p style={{color:"#7a7a7a"}}>{value.Added_by}</p>
                    </div>
                    <div className='cardDownRight'>
                        <p>₹ {value.totalPurchaseAmt}</p>
                    </div>
                  </div>
                  <div className='cardRgtCorner' style={{background:"#ff0000"}}>
                    <p>Dr.</p>
                  </div>
                </div>
              )
            })}           
          </div>
        </div>
      </div>
    </div>
  )
}

export default CashBookDetail