import "./header.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const Header = ({setGlobalItemId, setGlobalIvId, setGlobalBillId, setGlobalVoucherId, setGlobalCustomerId, setGlobalIbId}) => {

  const navigate = useNavigate()
  const [cookies, setCookie,removeCookie] = useCookies(["token"]);
  
  const [settings,setsettings]=useState('Settings')
  const [searchData, setSearchData] = useState(null)
  const [globalSearchList, setGlobalSearchList] = useState([])
  const [showGlobalSearchList, setShowGlobalSearchList] = useState(false)
  const [noData,setNoData]=useState(false);

  const searchDataFunc = (event) => {
    setSearchData(event.target.value)
  }

  useEffect(() => {
    if(searchData != null || searchData === "") {
      globalSearchFunc(searchData)
    }
  },[searchData])

  const globalSearchFunc = (searchValue) => {
    const requestOption = {
      method: "GET",
      url: Helper.getWsUrl() + `/booking/global_web_search/?value1=${searchValue}&web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOption)
      .then((response) => {
        if (response.data.code === 200) {
          setGlobalSearchList(Helper.encypt_decrypt(response.data.data)) 
          setShowGlobalSearchList(true) 
          setNoData(false) 
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
            navigate('/')
            localStorage.setItem("isAuthenticated", false)
            localStorage.removeItem("isAuthenticated")
          }, 1000)
        } else {
          setGlobalSearchList([])
          if(searchData && searchData != "") {
            setShowGlobalSearchList(true)
            setNoData(true)  
          } else {
            setShowGlobalSearchList(false)
          } 
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const showListDetail = (module, itemId, ivId, billId, voucherId, customerId, ibId) => {
    setGlobalItemId(itemId)
    setGlobalIvId(ivId)
    setGlobalBillId(billId)
    setGlobalVoucherId(voucherId)
    setGlobalCustomerId(customerId)
    setGlobalIbId(ibId)
    navigate(`/${module}`)
    setShowGlobalSearchList(false)
  }

  let shopName = localStorage.getItem("shopName")

  const styles = {
    width: "100%",
    textAlign: "left",
    color: "#000",
    fontWeight: 400,
    fontSize: "14px",
  };

  const styleModule = {
    width: "20%",
    textAlign: "left",
    color: "#7a7a7a",
    fontWeight: 400,
    fontSize: "14px",
    textAlign:"right"
  }

  if(window.location.pathname == "/GetHelp"){
    var logo_url = require("../assets/images/green info.png")
  }else{
    var logo_url = require("../assets/images/HELP ICON.png")
  }

  const[changelogo,setChangelogo]=useState(false);

  if(changelogo===true){
    var notilogo = require("../assets/images/green notification.png")
  }else{
    var notilogo = require("../assets/images/notification1.png")
  }

  return (
    <div className="row" style={{ height: "106px"}}>
      <div className="col-2 text-white text-center" id="header" style={{padding:"0 10px", backgroundColor: "#008C53", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
        <img src={require("../assets/images/enbill_logo.png")} className=" img-fluid rounded-start mx-auto d-block"
            height={"80px"}
            width={"80px"}
            class="img-fluid"/>
        <p style={{padding:"0", margin:"0", textTransform:"capitalize", fontWeight:"600", fontSize:"18px"}} className="">{shopName}</p>
      </div>

      <div className="col-7" id="header" style={{backgroundColor: "#fff", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"start", padding:"0 30px"}}>
        {/* <h2>{props.welcome}</h2> */}
        <div style={{position:"relative", width:"100%", display:"flex", justifyContent:"center", alignItems:"center", borderRadius: "20px", border: "none", backgroundColor: "rgb(245 245 245)"}}>
          <input className="globalSearch" autoComplete="off" onChange={(e) => {searchDataFunc(e)}} type="search" color="#4D4F5C" placeholder="Search transactions, invoices or help" aria-label="Search" aria-describedby="search-addon"
            style={{padding: "5px 20px", width:"100%", borderRadius: "20px", border: "none", backgroundColor: "rgb(245 245 245)",position:"relative"}}/>
          {/* <sapn style={{border:"none", background:"none",position:"absolute",right:"10px"}}>
            <img src={require("../assets/images/icon_search.png")} style={{width:"20px", height:"20px"}} class="img-fluid"/>
          </sapn> */}
          {searchData != null ? 
            <ClearIcon onClick={() => {setSearchData(null); setShowGlobalSearchList(false)}} sx={{color:'#7a7a7a', position:"absolute",right:"10px"}} />
          :
            <SearchIcon sx={{color:'#7a7a7a', position:"absolute",right:"10px"}} />
          }
          {showGlobalSearchList ? (
            <div style={{paddingTop: "10px", zIndex:"99999999", boxShadow:"", top:"35px", left:"0", minHeight:"0", maxHeight:"40vh", overflowY:"scroll", backgroundColor: "#fff",position: "absolute", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", width: "95%",borderRadius:"0 0 10px 10px"}}> 
              {noData?
                <div style={{marginLeft:"20px",marginTop:"20px"}}> 
                  <p>No data found</p>
                </div> 
              :
                <>
                  {globalSearchList && globalSearchList.map((value, index) => {
                    let m_no = value.mobile_number
                    return (
                      <>
                        <li onClick={() => {showListDetail(value.module, value.item_id, value.iv_id, value.bill_id, value.voucher_id, value.customer_id, value.ib_id)}} style={{listStyle: "none", display:"flex", alignItems:"center", justifyContent:"center", border:"none", cursor:"pointer", padding:"0 15px"}}>
                          <p style={styles} key={index}>
                            {value.customer_name} &nbsp;
                            {value.shop_name} &nbsp;
                            {value.name} &nbsp;
                            {m_no != null ? <>({m_no})</> : null} &nbsp;&nbsp;
                            {value.bill_no} &nbsp;&nbsp;
                            {value.voucher_number} &nbsp;&nbsp;
                            {value.item_name} &nbsp;&nbsp;
                            {value.gst_no}
                          </p>
                          <p style={styleModule} key={index}>
                            {value.module}
                          </p>
                        </li>
                      </>
                    )
                  })}
  
                </>
              }
            </div> ) 
          : 
            null
          }
        </div>
      </div>

      <div className="col-3" id="header" style={{backgroundColor: "#fff", display: "flex", justifyContent: "center",marginTop:"1%" }}>
        <div style={{ display: "flex",justifyContent:"center", width: "100%" }}>
          <img src={logo_url} onClick={() => { navigate('/GetHelp') }} style={{cursor:"pointer", width: "25px", height: "25px" }} />         
          <div class="dropdown" style={{marginLeft:"10px", padding:"0"}}>
              <ul style={{cursor:"pointer",color:"#4D4F5C",fontSize:"17px",fontWeight:"500"}} class="dropdown-toggle" data-bs-toggle="dropdown">{settings}</ul>
              <div class="dropdown-menu">
                  <li class="dropdown-item" onClick={() => {navigate('/Profile');setsettings("Profile")}} style={{cursor:"pointer",padding:"5%"}}>  <img src={require("../assets/images/PROFILE ICON.png")} style={{cursor:"pointer", width: "25px", height: "25px" }} /> Profile</li>
                  <li class="dropdown-item" onClick={() => {navigate('/Kyc');setsettings("Kyc")}} style={{cursor:"pointer",padding:"5%"}}> <img src={require("../assets/images/KYC ICON.png")} style={{cursor:"pointer", width: "25px", height: "25px" }} /> KYC</li>
                  <li class="dropdown-item" onClick={() => {navigate('/ManageQR');setsettings("ManageQR")}} style={{cursor:"pointer",padding:"5%"}}> <img src={require("../assets/images/MANAGE QR ICON.png")} style={{cursor:"pointer", width: "25px", height: "25px" }} /> Manage QR</li>
                  <li class="dropdown-item" onClick={() => {navigate('/Template');setsettings("Template")}} style={{cursor:"pointer",padding:"5%"}}> <img src={require("../assets/images/BILL TEMPLATE ICON.png")} style={{cursor:"pointer", width: "25px", height: "25px" }} /> Template</li>
                  <li class="dropdown-item" onClick={() => {navigate('/GetHelp');setsettings("GetHelp")}} style={{cursor:"pointer",padding:"5%"}}> <img src={require("../assets/images/GET HELP ICON.png")} style={{cursor:"pointer", width: "25px", height: "25px" }} /> Get Help</li>
                  <li class="dropdown-item" onClick={() => {navigate('/PrivacyPolicy');setsettings("Privacypolicy")}} style={{cursor:"pointer",padding:"5%"}}> <img src={require("../assets/images/PRIVACY POLICY ICON.png")} style={{cursor:"pointer", width: "25px", height: "25px" }} /> Privacy Policy</li>
                  <li class="dropdown-item" onClick={() => {navigate('/TermsUses');setsettings("TermsUses")}} style={{cursor:"pointer",padding:"5%"}}> <img src={require("../assets/images/T & C ICON.png")} style={{cursor:"pointer", width: "25px", height: "25px" }} /> Terms of Use</li>
              </div>
          </div>
        </div>
      </div>
    </div>
   
  );
};

export default Header;
