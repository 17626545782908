import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Helper from "../util/Helper";
import Checkbox from '@mui/material/Checkbox';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const CatalogueListedProduct = () => {

    const ref = useRef([]);
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const [itemCategoryList, setItemCategoryList] = useState([])
    const [activeItemCategory, setActiveItemCategory] = useState(0)
    const [selectedProductList, setSelectedProductList] = useState([])
    const [itemCategoryProductList, setItemCategoryProductList] = useState([])
    const [showSelectedItemsForSellingPrice, setShowSelectedItemsForSellingPrice] = useState(false)

    useEffect(() => {
        const requestCategoryOption = {
            method: "GET",
            url: Helper.getWsUrl() + `/items/get_item_category_product_dataset_new/?web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(requestCategoryOption)
            .then((response) => {       
                if (response.data.code === 200) {
                    setItemCategoryList(Helper.encypt_decrypt(response.data.data))
                    getItemOnCategoryFunc(Helper.encypt_decrypt(response.data.data)[0].ic_id)
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else {
                    setItemCategoryList([])
                }
            })
            .catch((error) => {
                alert(error);
            });
    },[])

    const getItemOnCategoryFunc = (id) => {
        setShowSelectedItemsForSellingPrice(false)
        const requestCategoryItemOption = {
            method: "GET",
            url: Helper.getWsUrl() + `/items/get_item_according_to_cat_new/1/?ic_id=${id}&web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(requestCategoryItemOption)
            .then((response) => {       
                if (response.data.code === 200) {
                    setSelectedProductList([])
                    setItemCategoryProductList(Helper.encypt_decrypt(response.data.data))
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else {
                    setItemCategoryProductList([])
                    setSelectedProductList([])
                }
            })
            .catch((error) => {
                alert(error);
            });
    }

    const getProductListToPost = (id, mrp, name) => {
        if(!selectedProductList.find((element) => element.product_id === id)) {
            setSelectedProductList([
                ...selectedProductList, {
                    product_id: id,
                    selling_price: mrp,
                    mrp: mrp,
                    name: name
                }
            ])
        } else {
            setSelectedProductList(selectedProductList.filter((element) => 
                element.product_id != id
            ))
        }
    }

    const getProductListAfterPreview = (e, index) => {
        let filterTableArray = [...selectedProductList]
        let indexOfEditSellingPrice = index
        let indexOfFilterTableArray
        let filterTableElement = filterTableArray.filter((item, index) => {
            if(index === indexOfEditSellingPrice) {
                indexOfFilterTableArray = index
                return item
            }
        })

        const regex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/
        if(e.target.value === '' || regex.test(e.target.value)) {
            if(parseFloat(e.target.value) > parseFloat(filterTableElement[0].mrp)) {
                filterTableElement[0].selling_price = filterTableElement[0].mrp
                filterTableArray[indexOfFilterTableArray] = filterTableElement[0]
                setSelectedProductList(filterTableArray)
            } else {
                filterTableElement[0].selling_price = e.target.value
                filterTableArray[indexOfFilterTableArray] = filterTableElement[0]
                setSelectedProductList(filterTableArray)
            }
        }
    }

    const postSelectedProductListToCategoryFunc = () => {
        const requestSelectedCategoryItemOption = {
            method: "POST",
            url: Helper.getWsUrl() + `/items/post_item_according_to_cat_new/?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
            data: {
                product_id: selectedProductList
            }
        };
        axios(requestSelectedCategoryItemOption)
            .then((response) => {       
                if (response.data.code === 201) {
                    window.location.reload()
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                }
            })
            .catch((error) => {
                alert(error);
            });
    }

    return (
        <div style={{display:"flex", width:"100%"}}>
            <div onClick={() => {window.location.reload()}} style={{position:"absolute", top:"0", left:"0", background:"#008c53", color:"#fff", fontWeight:"600", fontSize:"16px", zIndex:"1", padding:"10px 5px", borderRadius:"0 25px 25px 0", writingMode:"vertical-rl", cursor:"pointer"}}>
                view Catalogue
            </div>

            <div style={{width: "40%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff"}}>
                <h6 style={{ fontWeight: "600" }}>Item Category</h6>
                <div style={{width:'100%', height:"68vh", overflowY:'scroll', marginTop:"15px"}}>
                    {itemCategoryList && itemCategoryList.map((value, index) => {
                        return (
                            <div type='button' onClick={() => {getItemOnCategoryFunc(value.ic_id); setActiveItemCategory(index)}} style={{display:'flex', justifyContent:'space-between', alignItems:'center', padding: "15px", borderRadius:"10px", background: activeItemCategory === index ? "#008c53" : '#fff', boxShadow: "rgb(0 0 0 / 10%) 0px 2px 4px 0px", width: "100%", marginBottom:"10px"}}>
                                <img src={value.image_url} style={{width:'20%', aspectRatio:'1/1'}} />
                                <p style={{margin:'0 0 0 20px', fontSize:'16px', fontWeight:"500", width:"80%", color: activeItemCategory === index ? "#fff" : "#212529"}}>{value.name}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            
            <div style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", position:"relative", justifyContent:"center", alignItems:"center"}}>
                {!showSelectedItemsForSellingPrice ?
                    <>
                        {itemCategoryProductList && itemCategoryProductList.length > 0 ? 
                            <div style={{width:"100%", height:"68vh"}}>
                                <h6 style={{ fontWeight: "600" }}>Item List</h6>                        
                                <div id='masterCategoryScroll' style={{width:'100%', height:"62vh", overflowY:"scroll", marginTop:'15px'}}>
                                    {itemCategoryProductList && itemCategoryProductList.map((value, index) => {
                                        return (
                                            <div style={{display:"flex", alignItems:"center", background:"#fff", borderRadius:"10px", width:"100%", padding:"10px 15px"}} >
                                                <Checkbox onChange={(e) => {getProductListToPost(value.product_id, value.mrp, value.product_name)}} checked={selectedProductList.find((element) => element.product_id === value.product_id) ? "checked" : ""} sx={{color:"#008c53"}} color="success" size="small" />
                                                <img style={{width:"12%", aspectRatio:"1/1", margin:"0 10px 0 25px"}} src={value.product_image === "N/A" ? require("../assets/images/enbill logo 1.png") : value.image_url} 
                                                    onError={(e) =>
                                                        (e.target.onerror = null)(
                                                            (e.target.src = require("../assets/images/enbill logo 1.png"))
                                                        )}/>
                                                <div>
                                                    <p style={{margin:'0', fontSize:'15px', lineHeight:"16px", fontWeight:"500"}}>
                                                        {value.product_name}
                                                    </p>
                                                    <p style={{fontSize:"13px", fontWeight:"400"}}>
                                                        ₹ {value.mrp}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div style={{width:"100%", display: selectedProductList && selectedProductList.length > 0 ? "flex" : "none", alignItems:"center", justifyContent:"flex-end", marginTop:"18px"}}>
                                    <button onClick={() => {setShowSelectedItemsForSellingPrice(true)}} style={{border:"none", borderRadius:"15px", background:"#008c53", padding:"5px 10px", color:"#fff"}}>Add Items</button>
                                </div>
                            </div>
                        :
                            <div style={{display:"flex", width:"80%", flexDirection:"column", justifyContent:"center", alignItems:'center', height:"70vh"}}>
                                <img width={"25%"} src={require('../assets/images/No-Products_Found.png')} />
                                <p style={{margin:"0", fontSize:"16px", fontWeight:"500", opacity:"0.8", textAlign:"center", color:"#008c53"}}>Sorry your selected Item category product list is not available. Wait for next update!</p>
                            </div>
                        }
                    </>
                :
                    <div style={{width:"100%", height:"68vh"}}>
                        <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", position:"relative"}}>
                            <KeyboardBackspaceIcon sx={{color:"#008c53", cursor:"pointer", position:"absolute", left:"0"}} onClick={() => {setShowSelectedItemsForSellingPrice(false)}} />
                            <small style={{fontWeight:"500"}}>Do you want to change selling price ?</small>
                        </div>
                        <div id='masterCategoryScroll' style={{width:"100%", height:"62vh", padding:"10px", overflowY:"scroll"}}>
                            {selectedProductList && selectedProductList.map((value, index) => {
                                return (
                                    <div style={{width:"100%", padding:"10px 20px", marginBottom:"10px",boxShadow: "rgb(0 0 0 / 10%) 0px 2px 4px 0px", borderRadius:"10px"}}>
                                        <p style={{margin:"0", fontSize:"16px", fontWeight:"500"}}>{value.name}</p>
                                        <div style={{display:"flex", justifyContent:"space-between"}}>
                                            <p style={{margin:"0", fontSize:"16px", position:"relative", fontWeight:"500"}}>
                                                MRP : <br/>
                                                <input disabled value={value.mrp} style={{padding:"2px 15px 2px 25px", fontSize:"15px", border:"1px solid #008c53", borderRadius:"10px", width:"50%"}} />
                                                <small style={{position:"absolute", left:"12px", fontSize:"15px", bottom:"3px"}}>₹</small>
                                            </p>
                                            <p style={{margin:"0", fontSize:"16px", position:"relative", fontWeight:"500"}}>
                                                Selling Price : <br/>
                                                <input onChange={(e) => {getProductListAfterPreview(e, index)}} value={value.selling_price} style={{padding:"2px 15px 2px 25px", fontSize:"15px", border:"1px solid #008c53", borderRadius:"10px", width:"50%"}} />
                                                <small style={{position:"absolute", left:"12px", fontSize:"15px", bottom:"3px"}}>₹</small>
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{width:"100%", display: "flex", alignItems:"center", justifyContent:"flex-end", marginTop:"18px"}}>
                            <button onClick={() => {postSelectedProductListToCategoryFunc()}} style={{border:"none", borderRadius:"15px", background:"#008c53", padding:"5px 25px", color:"#fff"}}>Done</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default CatalogueListedProduct;