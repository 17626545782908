import React from "react";

const UdhaarFirstTimeUser = () =>{

    return(
        <div style={{backgroundColor:"white",display:"flex",justifyContent:"center",textAlign:"center",flexDirection:"column", width:"100%", borderRadius:"20px"}}>
            <div>
                <img width={"250px"} src={require("../assets/images/UdhaarIcon.png")} style={{marginTop:"10%"}} class="img-fluid" />
            </div>
            <div>
                <p style={{color:"#8C8C8C",fontWeight:"600"}}>See your pending udhaar here.</p>
            </div>
        </div>
    )
}

export default UdhaarFirstTimeUser;