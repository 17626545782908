import "./Profile.css"
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import S3 from 'react-aws-s3';
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Geocode from "react-geocode";
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

Geocode.setLanguage("en");
Geocode.setRegion("in");
Geocode.enableDebug();
Geocode.setApiKey("AIzaSyDY0fD0X7L5jbJDn8M5L9IrA3XsAKHs_tQ");
Geocode.setLocationType("ROOFTOP");

window.Buffer = window.Buffer || require("buffer").Buffer;

const Profile = () => {    
    
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(["token"]); 
    
    const [showLogOutBtn, setShowLogOutBtn] = useState(false)
    const [grahakProfile, setGrahakProfile] = useState([]);
    const [catagoryList, setCatagoryList] = useState([]);
    const [showpopup, setShowPopUp] = useState(false);
    const [addressList, setAddressList] = useState(null)
    const [imgUrl, setImgUrl] = useState(null)
    const [editMerchantImg, setEditMerchantImg] = useState(null)
    const [entityName, setEntityName] = useState(null);
    const [deliveryRange, setDeliveryRange] = useState(0)
    const [getPincodeData, setGetPincodeData] = useState(null)
    const [getMinBookingAmt, setGetMinBookingAmt] = useState(100)

    const nameOnChangeFun = (event) => {
        setGrahakProfile({
            ...grahakProfile,
            name: event.target.value
        });
    };

    // useEffect(() => {
    //     if(grahakProfile && grahakProfile.pincode_text != null) {
    //         // search_pincode(getPincode)
    //         Geocode.fromAddress(getPincode).then(({ results }) => {
    //             setGrahakProfile({...grahakProfile, lng: results[0].geometry.location.lng})
    //             setGrahakProfile({...grahakProfile, lat: results[0].geometry.location.lat})
    //         });
    //     }
    // },[grahakProfile])

    // useEffect(() => {
    //     if(grahakProfile && grahakProfile.pincode_text != null) {
    //         setPincodeFunc()
    //     }
    // },[grahakProfile])

    const setPincodeFunc = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            search_pincode(getPincodeData);
            Geocode.fromAddress(getPincodeData).then(({ results }) => {
                setGrahakProfile({...grahakProfile, lng: results[0].geometry.location.lng})
                setGrahakProfile({...grahakProfile, lat: results[0].geometry.location.lat})
            });
        }
    }
    
    const search_pincode = (pincode) => {
        // if(pincode.length === 6) {
          const requestOptions = {
            // url: `https://api.postalpincode.in/pincode/${pincode}`,
            url: `https://maps.googleapis.com/maps/api/geocode/json?address=${pincode}&key=AIzaSyDY0fD0X7L5jbJDn8M5L9IrA3XsAKHs_tQ`,
            method: "GET",
          };
          axios(requestOptions)
            .then((response) => {
                setAddressList(response.data.results[0].formatted_address)
                const addressComponents = response.data.results[0].address_components;
                const pincodeComponent = addressComponents.find(component => component.types.includes('postal_code'));
                setGetPincodeData(pincodeComponent ? pincodeComponent.long_name : '');
            })
            .catch((error) => {
            });
        // }
    };
    
    useEffect(() => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/contact/get_contact/0/?app_type=0&web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                if (response.data.code === 200) {
                    // console.log("profile detail :",Helper.encypt_decrypt(response.data.data))
                    setGrahakProfile(Helper.encypt_decrypt(response.data.data))
                    localStorage.setItem("udhaar",Helper.encypt_decrypt(response.data.data).shop_credit)
                    localStorage.setItem("fastBilling",Helper.encypt_decrypt(response.data.data).fast_billing)
                    localStorage.setItem("inventory",Helper.encypt_decrypt(response.data.data).inventory)
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
   
    useEffect(() => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/masters/get_category_active/1/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                if (response.data.code === 200) {
                    setCatagoryList(Helper.encypt_decrypt(response.data.data))
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })
            
        const requestSelectedCategory = {
            method: "GET",
            url: Helper.getWsUrl() + `/contact/get_contact_category_assoc/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestSelectedCategory)
            .then((response) => {
                if (response.data.code === 200) {
                    setEntityName(Helper.encypt_decrypt(response.data.data)[0].master_category_id)                 
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    var add = null;

    useEffect(() => {
        if (grahakProfile.logo === 1) {
            setImgUrl(grahakProfile.m_logo_url)
        } else {
            let url = require("../assets/images/enbill_logo.png")
            setImgUrl(url)
        }
    },[grahakProfile])

    useEffect(() => {
        if(grahakProfile && grahakProfile.km != null) {
            setDeliveryRange(grahakProfile.km)
        }
    },[grahakProfile.km])

    useEffect(() => {
        if(grahakProfile && grahakProfile.min_booking_amount != null) {
            setGetMinBookingAmt(grahakProfile.min_booking_amount)
        }
    },[grahakProfile.min_booking_amount])

    useEffect(() => {
        if(grahakProfile && grahakProfile.pincode_text != null) {
            setGetPincodeData(grahakProfile.pincode_text)
        }
        if(grahakProfile && grahakProfile.address != null) {
            setAddressList(grahakProfile.address)
        }
    },[grahakProfile.pincode_text, grahakProfile.address])

    useEffect(()=>{
        if(editMerchantImg && editMerchantImg != null) {
            setImgUrl(URL.createObjectURL(editMerchantImg))
        }
    },[editMerchantImg])

    const ProfilePatch = () => {
        if(editMerchantImg && editMerchantImg != null) {
            AWSCredentials(editMerchantImg)
        }
        if(entityName && entityName.length != null) {
            CategoryPatch()
        }

        const data = {
            name: grahakProfile && grahakProfile.name,
            km: grahakProfile && grahakProfile.km,
            address: addressList && addressList,
            shop_credit: grahakProfile && grahakProfile.shop_credit,
            inventory: grahakProfile && grahakProfile.inventory,
            logo: grahakProfile && grahakProfile.logo,
            landmark: grahakProfile && grahakProfile.landmark,
            pincode_text: getPincodeData && getPincodeData,
            lat: grahakProfile && grahakProfile.lat,
            lng: grahakProfile && grahakProfile.lng,
            fast_billing: grahakProfile && grahakProfile.fast_billing,
            min_booking_amount: grahakProfile.min_booking_amount
        }
        const requestOptions = {
            method: "PATCH",
            url: Helper.getWsUrl() + `/contact/patch_contact/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
            data: data
        };
        axios(requestOptions)
            .then((response) => {
                if (response.data.code === 201) {   
                    localStorage.setItem("udhaar",grahakProfile && grahakProfile.shop_credit)
                    localStorage.setItem("inventory",grahakProfile && grahakProfile.inventory)          
                    localStorage.setItem("fastBilling",grahakProfile && grahakProfile.fast_billing) 
                    window.location.reload()         
                    // setShowPopUp(true)
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  } else {
                    setShowPopUp(false)
                }
            })
            .catch((err) => {
                console.log("error")
            })
    }

    const AWSCredentials = (editMerchantImg) => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/settings/get_aws_credientials/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                if(response.data.code === 200) {
                    const imgFileKey = '_original.png'
                    const FOLDER_NAME =  grahakProfile.p_id+"/pp"
                    const config = {
                        bucketName: Helper.encypt_decrypt(response.data.data).bucket_name,
                        region: "ap-south-1",
                        dirName: FOLDER_NAME,
                        accessKeyId: Helper.encypt_decrypt(response.data.data).aws_access_key,
                        secretAccessKey: Helper.encypt_decrypt(response.data.data).aws_secret_access_key,
                        s3Url: Helper.encypt_decrypt(response.data.data).base_url,
                    }
                    const ReactS3Client = new S3(config)
                    ReactS3Client
                        .uploadFile(editMerchantImg, imgFileKey)
                        .then(data => console.log("data : ",data))
                        .catch(err => console.error("error",err))
                }
            }
        );
    }
 
    const CategoryPatch = () => {
        const addCategoryURL = {
            url: Helper.getWsUrl() + "/contact/patch_contact_category_assoc/?web=1",
            method: "PATCH",
            headers: { "Content-Type": "application/json", "token": cookies.token },
            data: {
              ids: [
                parseInt(entityName)
              ]
            }
          };
          axios(addCategoryURL)
            .then((response) => {
              if (response.data.code === 200) {
                // console.log(Helper.encypt_decrypt(response.data.data));
              } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              }
            })
            .catch((error) => {
              alert(error);
            });
    }

    const logoutFunc = () => {
        removeCookie("logged_in")
        removeCookie("token")
        setTimeout(() => {
            navigate('/')
            localStorage.setItem("isAuthenticated", false)
            localStorage.removeItem("isAuthenticated")
            // setIsAuthenticated(false)
        }, 1000)
    }
   
    setTimeout(function(){
        setShowPopUp(false);
    },5000)

    const handleChange = (event) => {
        setEntityName(event.target.value)
    };

    return (
        <div className='col-10' id='main-div' style={{ backgroundColor: "#f9f9f9", padding: "20px", display: "flex" }}>
            <div className='profileMainDiv'>
                <div className='firstDiv' style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <h1 className="profilecss">Profile Details</h1>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"end", position:"relative"}}>
                        <svg onClick={() => {setShowLogOutBtn(!showLogOutBtn)}} style={{cursor:"pointer"}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                        </svg>
                        {showLogOutBtn ? 
                            <button onClick={() => {logoutFunc()}} style={{position:"absolute", top:"25px", width:"150px", border:"none", borderRadius:"10px",padding:"7px 20px", background:"rgb(245, 245, 245)", fontSize:"18px", fontWeight:"600"}}>Log Out</button>
                        : null }
                    </div>
                </div>
                <div className='secDiv'>
                    <div style={{ width: "50%" }}>
                        <div class="pro-user-input-wrp" style={{ width: "100%" }}>
                            <input disabled type="text" style={{ width: "100%" }} class="inputText" value={grahakProfile && grahakProfile.mobile_number} />
                            <p style={{ position: "absolute", bottom: "40%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Mobile Number</p>
                        </div>

                        <div class="pro-user-input-wrp" style={{ width: "100%" }}>
                            <input type="text" style={{ width: "100%" }} class="inputText" value={grahakProfile && grahakProfile.name} onChange={nameOnChangeFun} />
                            <span class="pro-floating-label">Shop Name</span>
                        </div>

                        <div style={{ position: "relative" }}>
                            <p style={{ position: "absolute", bottom: "34%", left: "3%", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Category</p>
                            <select
                                class="form-select form-select-lg mb-3"
                                aria-label=".form-select-lg example"
                                style={{ marginTop: "20px", fontSize:'16px' }}
                                onChange={handleChange}
                                name="category_name"
                                value={entityName}
                            >
                                <option disabled style={{ display: "none" }} selected>Select Category</option>
                                {catagoryList && catagoryList.map((value) => {
                                    return <option selected={(entityName && entityName === value.id) ? "selected" : ""} value={value.id}>{value.name}</option>;
                                })}
                            </select>
                        </div>

                        <div className='switchDiv'>
                            <p>Udhaar</p>
                            <div class="form-check form-switch">
                                <input class="form-check-input hetswitch" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                                    onClick={() => {
                                        if(grahakProfile && grahakProfile.shop_credit === 1) {
                                        setGrahakProfile({
                                                        ...grahakProfile,
                                                        shop_credit: 0
                                                    })
                                        } else {
                                            setGrahakProfile({
                                                ...grahakProfile,
                                                shop_credit: 1
                                            })
                                        }
                                    }}
                                checked={grahakProfile && grahakProfile.shop_credit === 1 ? true : false}
                                />
                            </div>
                            <p>Inventory</p>
                            <div class="form-check form-switch">
                                <input class="form-check-input hetswitch" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                                onClick={() => {
                                    if(grahakProfile && grahakProfile.inventory === 1) {
                                    setGrahakProfile({
                                                    ...grahakProfile,
                                                    inventory: 0
                                                })
                                    } else {
                                        setGrahakProfile({
                                            ...grahakProfile,
                                            inventory: 1
                                        })
                                    }
                                }}
                                checked={grahakProfile && grahakProfile.inventory === 1 ? true : false}
                                />
                            </div>
                            <p>Fast Billing</p>
                            <div class="form-check form-switch">
                                <input class="form-check-input hetswitch" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                                onClick={() => {
                                    if(grahakProfile && grahakProfile.fast_billing === 1) {
                                    setGrahakProfile({
                                                    ...grahakProfile,
                                                    fast_billing: 0
                                                })
                                    } else {
                                        setGrahakProfile({
                                            ...grahakProfile,
                                            fast_billing: 1
                                        })
                                    }
                                }}
                                checked={grahakProfile && grahakProfile.fast_billing === 1 ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='secDivImgBlockProfile'>
                        <Button style={{background:"none", boxShadow:"none"}} variant="contained" component="label">
                            <img src={imgUrl} style={{borderRadius:"50%"}} />
                            <input type="file" hidden accept="image/*" onChange={(e) => {
                                setEditMerchantImg(e.target.files[0]);
                                setGrahakProfile({
                                    ...grahakProfile,
                                    logo: 1
                                })
                                }} />
                        </Button>
                        <p>Business logo</p>
                    </div>
                </div>
                <div>
                    <p style={{margin:"0", fontWeight:"600", fontSize:"20px", color:"#7a7a7a"}}>Select the preferred option for your Dukaan</p>
                    <div style={{display:"flex", width:"30%", justifyContent:"space-between"}}>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <p className="text-gray-700" style={{margin:"0", fontWeight:"500", fontSize:"18px", color:"#7a7a7a"}}>Delivery</p>
                            <Checkbox size="small" {...label} checked={grahakProfile && grahakProfile.km ? "checked" : ""} onClick={() => {setGrahakProfile({...grahakProfile, km: 15})}} color="success" />
                        </div>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <p className="text-gray-700" style={{margin:"0", fontWeight:"500", fontSize:"18px", color:"#7a7a7a"}}>Pick Up</p>
                            <Checkbox size="small" {...label} checked={grahakProfile && grahakProfile.km ? "" : "checked"} onClick={() => {setGrahakProfile({...grahakProfile, km: 0})}} color="success" />
                        </div>
                    </div>
                    <p style={{fontSize:"16px", fontWeight:"400", margin:"0", display: deliveryRange && deliveryRange != 0 ? "" : "none"}}>Select range for delivery (in kms)</p>
                    <div style={{display: deliveryRange && deliveryRange != 0 ? "flex" : "none", }}>
                        <Box width={300} >
                            <Slider onChange={(e) => {setGrahakProfile({...grahakProfile, km: e.target.value})}} sx={{color:"#008c53"}} max={15} min={0} value={deliveryRange && deliveryRange} aria-label="Default" valueLabelDisplay="auto" />
                        </Box>
                        <p style={{margin:"0 0 0 15px"}}>({grahakProfile && grahakProfile.km} Km)</p>
                    </div>
                </div>
                <div style={{display: deliveryRange && deliveryRange != 0 ? "flex" : "none", flexDirection:"column"}}>
                    <p style={{margin:"0", fontWeight:"600", fontSize:"20px", color:"#7a7a7a"}}>Select the minimum amount required to receive a booking (in ₹)</p>
                    <div style={{display: "flex"}}>
                        <Box width={300} >
                            <Slider onChange={(e) => {setGrahakProfile({...grahakProfile, min_booking_amount: e.target.value})}} sx={{color:"#008c53"}} max={1000} min={100} value={getMinBookingAmt} aria-label="Default" valueLabelDisplay="auto" />
                        </Box>
                        <p style={{margin:"0 0 0 15px"}}>( ₹ {grahakProfile && grahakProfile.min_booking_amount} )</p>
                    </div>
                </div>
                <hr />
                <div className='lastDivProfile'>
                    <div style={{ width: "50%" }}>
                        <h2>Location Details</h2>
                        <div class="pro-user-input-wrp" style={{ width: "100%" }}>
                            <input  type="text" style={{ width: "100%" }} class="inputText" autoComplete="off"
                            value={getPincodeData && getPincodeData}
                            onKeyDown={setPincodeFunc}
                            onChange={(e) => {setGetPincodeData(e.target.value)}} />
                            <span class="pro-floating-label">Pincode</span>
                        </div>
                        <div class="pro-user-input-wrp" style={{ width: "100%" }}>
                            <input type="text" style={{ width: "100%" }} class="inputText" autoComplete="off"
                            value={grahakProfile && grahakProfile.landmark}
                            onChange={(e) => {setGrahakProfile({...grahakProfile, landmark: e.target.value})}} />
                            <span class="pro-floating-label"  >Landmark (Optional)</span>
                        </div>
                        <div class="pro-user-input-wrp" style={{ width: "100%" }}>
                             {/* <select value={grahakProfile && grahakProfile.address} name="address" onChange={(e) => {setGrahakProfile({...grahakProfile, address: e.target.value})}} style={{width:"100%", fontSize:"1.25rem", padding: "0.5rem 1rem", borderRadius:"0.3rem", color:"#212529", border:"1px solid #ced4da", background:"#fff"}}>
                                <option selected disabled>select Address</option>
                                {addressList && addressList.map((value, index) => {
                                    return (
                                    <option style={{color:"#7a7a7a", fontSize:"16px"}} key={index} value={value.Name + "," + value.District + "," + value.State + "," + value.Pincode}>{value.Name}, {value.District}, {value.State}, {value.Pincode}</option>
                                    )
                                    })}
                            </select> */}
                            <input type="text" style={{ width: "100%" }} class="inputText" autoComplete="off"
                            value={addressList && addressList} name="address" onChange={(e) => {setAddressList(e.target.value)}} />
                            <span class="pro-floating-label" style={{top:'-10px', background: '#fff', color:"#008c53"}}>Address</span>
                        </div>
                    </div>
                    <div className='mapDivProfile'>
                        <button onClick={() => { ProfilePatch()}} data-bs-toggle={showpopup ? "modal" : ""} data-bs-target="#popupModal">Update</button>
                    </div>                    
                </div>                
            </div>
            {showpopup?
                <div style={{position:"absolute",backgroundColor:"#008C53",textAlign:"center",width:"80%",marginBottom:"0%"}}>
                    <p style={{color:"white"}}>Profile Updated Successfully</p>
                </div>
            :null}
        </div>
    )
}

export default Profile