import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Helper from "../util/Helper";
import {useCookies} from 'react-cookie'

const Onboarding2 = () => {
    
    let navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [accountDetail, setAccountDetail] = useState(null)
    const [upi, setUpi] = useState(null)
    const [upiVerify, setUpiVerify] = useState(false)

    const setAccountDetailFunc = (event) => {
        let value = event.target.value;
        setAccountDetail({
          ...accountDetail,
          [event.target.name]: value,      
        });
    }

  const bankDetailApiFunc = () => {
    if((upi && upi != null) || (accountDetail && accountDetail.account_holder_name != null && accountDetail.account_number != null && accountDetail.bank_name != null && accountDetail.ifcs_code != null)) {
      // if(upi && upi != null && !upiVerify) {
      //   alert("please verify UPI Id")
      // } else {
        const requestOptions = {
          method: "PATCH",
          url: Helper.getWsUrl() + `/contact/patch_contact/?web=1`,
          headers: { "Content-Type": "application/json", Token: cookies.token },
          data: {
              account_holder_name: accountDetail &&  accountDetail.account_holder_name,
              bank_name: accountDetail && accountDetail.bank_name,
              account_number:accountDetail && accountDetail.account_number,
              ifcs_code:accountDetail && accountDetail.ifcs_code,
              upi_id:upi && upi,
          },
        };
        axios(requestOptions)
          .then((response) => {
              if (response.data.code === 201) {
                alert(response.data.msg)
                routeChange()
              } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              }else if(response.data.code===400){
                alert(response.data.msg)
              }
          })
          .catch((error) => {
              alert(error);
          });
      // }
    } else {
        alert("Please fill Account Details or UPI Id")
    }
  }    

  const verfiyUPIId = () => {
    const Request = {
      url: Helper.getWsUrl() + "/kyc/upi_varify/?web=1",
      method: "POST",
      headers: { "Content-Type": "application/json", Token: cookies.token },
      data: {
        upi_id: upi && upi
      },
    };
    axios(Request)
      .then((response) => {
        if (response.data.code === 200) {
          alert(Helper.encypt_decrypt(response.data.data).message)
          setUpiVerify(true)
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        } else {
          alert(response.data.msg);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const routeChange = () => {
    let path = `/onboarding1`;
    navigate(path, { state: { Token: cookies.token } });
  };

  return(
    <div style={{height:"100vh", display:"flex"}}>
      <div style={{width:"20%"}}>
        <img src={require("../assets/images/side_bar.png")} className="img-fluid" style={{ width:"100%", height:"100%" }} alt=""/>
      </div>

      <div style={{margin:"20px", width:"80%"}}>
        <div style={{display:"flex", justifyContent:"space-between", width:"35%"}}>
          <button className="btn btn-sm btn-success rounded-3" style={{ width: "2rem", height: "2rem" }}>
            2
          </button>
          <h3>Bank Details</h3>
        </div>
        <hr style={{ height: "2px", width: "100%", borderWidth: 0 }}></hr>
        <div >
          <p className="text-gray-700">Account Details</p>
        </div>
        <div>
          <div className="col-6">
            <div className="col-12 mt-5 mb-4">
              <input 
                autoComplete="off"
                type="text"
                name="account_holder_name"
                value={accountDetail && accountDetail.account_holder_name}
                placeholder="Account Name"
                id="customer_name"
                onChange={setAccountDetailFunc}
                className = "form-control form-control-lg rounded-3" />
            </div>
            <div className="col-12 mb-3">
              <input
                type="text"
                name="bank_name"
                className="form-control form-control-lg rounded-3"
                placeholder="Bank Name"
                onChange={setAccountDetailFunc}
                id="customer_name"
                maxlength="50"
                pattern="/^[a-zA-Z]+$/;"
                required
              />
            </div>
            <div className="col-12 mb-3">
              <input
                type="text"
                name="account_number"
                className="form-control form-control-lg rounded-3"
                placeholder="Account Number"
                onChange={setAccountDetailFunc}
                id="customer_name"
                maxlength="50"
                pattern="/^[a-zA-Z]+$/;"
                required
              />
            </div>
            <div className="col-12 mb-3">
              <input
                type="text"
                name="ifcs_code"
                className="form-control form-control-lg rounded-3"
                placeholder="IFSC Code"
                onChange={setAccountDetailFunc}
                id="customer_name"
                maxlength="50"
                pattern="/^[a-zA-Z]+$/;"
                required
              />
            </div>

            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>OR</div>
            
            <div style={{marginTop:"20px"}}>UPI Details</div>
            <div style={{marginTop:"40px", display:"flex"}}>
              <input
                type="text"
                name="upi_id"
                className="form-control form-control-lg rounded-3"
                placeholder="UPI ID"
                onChange={(e) => {setUpi(e.target.value)}}
                id="customer_name"
                maxlength="50"
                pattern="/^[a-zA-Z]+$/;"
                required
                />
              {/* <button onClick={() => {verfiyUPIId()}} style={{display: (upi && upi != null) ? "block" : "none", marginLeft:"10px", color:"#fff", background:"#008c53", border:"none", fontWeight:"600", borderRadius:"10px", padding:"0 15px"}}>Verify</button> */}
            </div>
          </div>

          <div style={{display:"flex", justifyContent:"space-between", marginTop:"50px"}}>
              <button
                  type="button"
                  class="btn btn-outline-success rounded-pill"
                  style={{
                    background: "none",
                    color: "rgb(0, 140, 83, 1)",
                    width: "175px",
                  }}
                  onClick={routeChange} >
                  Skip
              </button>
              <button
                type="button"
                class="btn btn-outline-success rounded-pill"
                style={{
                  backgroundColor: "rgb(0, 140, 83, 1)",
                  color: "white",
                  width: "175px",
                  marginLeft:"10px"
                }}
                onClick={()=> {bankDetailApiFunc()}} >
                Continue
              </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Onboarding2;