import './CatalougeProductWithVariant.css'
import React, { useState, useEffect, useCallback } from "react";
import Helper from "../util/Helper";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import S3 from 'react-aws-s3';

const CatalougeProductDetailsWithVariant = ({globalItemId, globalIvId, getCatalougeIvId, getCatalougeItemId, setRefreshAfterEditItem, setShowProductDetailsWithVariant}) => {
    
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()

    const [getCatalougeItemData, setGetCatalougeItemData] = useState(null)
    const [getCatalougeItemVarient, setGetCatalougeItemVarient] = useState([])
    const [getBarcodeUrl, setGetBarcodeUrl] = useState(null)
    const [changeBtn, setChangeBtn] = useState(false)
    const [getEditItemApiRes, setGetEditItemApiRes] = useState(null)
    const [itemIdForApi, setItemIdForApi] = useState(null)
    const [ivIdForApi, setIvIdForApi] = useState(null)
    const [getUnitDataCata, setGetUnitDataCata] = useState(null)
    const [gstData, setGstData] = useState(null)
    const [discOnUpdate, setDiscOnUpdate] = useState(0)

    // const [masterCategoryList, setMasterCategoryList] = useState([])
    const [masterSubCategoryList, setMasterSubCategoryList] = useState([])
    // const [activeMasterCategory, setActiveMasterCategory] = useState(0)
    const [addNewSubCategory, setAddNewSubCategory] = useState(null)
    const [masterCategoryId, setMasterCategoryId] = useState(null)
    const [masterSubCategoryId, setMasterSubCategoryId] = useState(null)
    // const [selectedMasterCategory, setSelectedMasterCategory] = useState(null)
    const [selectedSubCategory, setSelectedSubCategory] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();

    const [itemImg1, setItemImg1] = useState(null);
    const [itemImg2, setItemImg2] = useState(null);
    const [itemImg3, setItemImg3] = useState(null);
    const [itemImg4, setItemImg4] = useState(null);

    let img_1 = null
    let img_2 = null
    let img_3 = null
    let img_4 = null
    let imgCount = 0

    let contact_id = localStorage.getItem("contact_id")

    // const [isShown, setIsShown] = useState(false);
    const [barcode, setBarcode] = useState(null);

    var date=new Date();
    var tdate=date.getDate();
    var year = date.getFullYear();
    
    if(tdate < 10){
        tdate = "0"+ tdate;
    }
    var month=date.getMonth()+1;
    if(month < 10){
        month = "0"+ month;
    }
    var maxDate = year + "-"+month+"-"+tdate;

    useEffect(() => {
        if(globalItemId && globalItemId != null) {
            setItemIdForApi(globalItemId)
        }
    },[globalItemId])

    useEffect(() => {
        if(getCatalougeItemId && getCatalougeItemId != null) {
            setItemIdForApi(getCatalougeItemId)
        }
    },[getCatalougeItemId])

    useEffect(() => {
        if(globalIvId && globalIvId != null) {
            setIvIdForApi(globalIvId)
        }
    },[globalIvId])

    useEffect(() => {
        if(getCatalougeIvId && getCatalougeIvId != null) {
            setIvIdForApi(getCatalougeIvId)
        }
    },[getCatalougeIvId])

    const validation = (e) => {
        const re = /^[0-9\b]+$/;
        if(e.target.value === '' || re.test(e.target.value)) {
            setBarcode(e.target.value);
        }
    }
    
    const getEditItemNameFunc = (event) => {
        setGetCatalougeItemData({ 
            ...getCatalougeItemData, 
            item_name: event.target.value 
        });
    }; 

    const getEditUnitFunc = (event) => {
        setGetCatalougeItemData({ 
            ...getCatalougeItemData, 
            miu_id: event.target.value 
        });
    }; 

    const getEditSizeFunc = (event) => {
        setGetCatalougeItemVarient({ 
            ...getCatalougeItemVarient, 
            size: event.target.value 
        });
    };   

    const getEditHsnFunc = (event) => {
        setGetCatalougeItemData({
            ...getCatalougeItemData,
            hsn_sac_code: event.target.value
        })
    }

    const getEditMrpFunc = (event) => {
        setGetCatalougeItemVarient({
            ...getCatalougeItemVarient,
            mrp: event.target.value
        })
    }

    const getEditGstFunc = (event) => {
        setGetCatalougeItemData({
            ...getCatalougeItemData,
            gst_tax_per: event.target.value
        })
    }

    useEffect(() => {
        if(itemImg1 && itemImg1 != null) {
            setGetCatalougeItemData({
                ...getCatalougeItemData,
                image_1: 1,
                feature_image: 1
            })
        }
        if(itemImg2 && itemImg2 != null) {
            setGetCatalougeItemData({
                ...getCatalougeItemData,
                image_2: 1,
                feature_image: 1
            })
        }
        if(itemImg3 && itemImg3 != null) {
            setGetCatalougeItemData({
                ...getCatalougeItemData,
                image_3: 1,
                feature_image: 1
            })
        }
        if(itemImg4 && itemImg4 != null) {
            setGetCatalougeItemData({
                ...getCatalougeItemData,
                image_4: 1,
                feature_image: 1
            })
        }
    },[itemImg1, itemImg2, itemImg3, itemImg4])

    const getEditQtyFunc = (event) => {
        setGetCatalougeItemVarient({
            ...getCatalougeItemVarient,
            min_qty: parseFloat(event.target.value)
        })
    }

    const getEditPurchaseFunc = (event) => {
        setGetCatalougeItemVarient({
            ...getCatalougeItemVarient,
            purchase_price: event.target.value
        })
    }

    const getEditSellingFunc = (event) => {
        setGetCatalougeItemVarient({
            ...getCatalougeItemVarient,
            selling_price: event.target.value
        })
    }

    const getEditSkuCodeFunc = (event) => {
        setGetCatalougeItemVarient({
            ...getCatalougeItemVarient,
            sku_code: event.target.value
        })
    }

    const getEditDiscFunc = (event) => {
        setGetCatalougeItemVarient({
            ...getCatalougeItemVarient,
            disc: event.target.value
        })
    }

    const getEditExpiryDateFunc = (event) => {
        setGetCatalougeItemVarient({
            ...getCatalougeItemVarient,
            exp_date: event.target.value
        })
    }

    useEffect(() => {
        let discont = 0
        if(getCatalougeItemVarient && getCatalougeItemVarient.mrp != null && getCatalougeItemVarient.selling_price != null) {
            discont = (((getCatalougeItemVarient.mrp - getCatalougeItemVarient.selling_price) / getCatalougeItemVarient.mrp) * 100)
            setDiscOnUpdate(discont)
        }
    },[getCatalougeItemVarient])

    const getEditColorFunc = (event) => {
        setGetCatalougeItemVarient({
            ...getCatalougeItemVarient,
            color: event.target.value
        })
    }

    const getEditModelFunc = (event) => {
        setGetCatalougeItemVarient({
            ...getCatalougeItemVarient,
            model: event.target.value
        })
    }

    const getEditRefFunc = (event) => {
        setGetCatalougeItemVarient({
            ...getCatalougeItemVarient,
            ref_value: event.target.value
        })
    }

    const getEditStockFunc = (event) => {
        setGetCatalougeItemVarient({
            ...getCatalougeItemVarient,
            stock: event.target.value
        })
    }

    const forDetails = (itemIdForApi, ivIdForApi) => {
        if(itemIdForApi && itemIdForApi != null && ivIdForApi && ivIdForApi != null) {
            const getItemDetailCatalougeUrl = {
                method: "GET",
                url: Helper.getWsUrl() + `/items/get_items/${itemIdForApi}/${ivIdForApi}/?web=1`,
                headers: { "Content-Type": "application/json", 'token': cookies.token },
            };
            axios(getItemDetailCatalougeUrl)
            .then((response) => {         
                if (response.data.code === 200) {
                    console.log("item detail : ", Helper.encypt_decrypt(response.data.data))
                    setChangeBtn(false)
                    setMasterSubCategoryId(Helper.encypt_decrypt(response.data.data).item_data.ic_id)
                    setMasterCategoryId(Helper.encypt_decrypt(response.data.data).item_data.mc_id)
                    setSelectedSubCategory(Helper.encypt_decrypt(response.data.data).item_data.ic_id_name)
                    setGetCatalougeItemData(Helper.encypt_decrypt(response.data.data).item_data)
                    setGetCatalougeItemVarient(Helper.encypt_decrypt(response.data.data).item_verient[0])                    
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  } 
            })
            .catch((error) => {
                alert(error);
            })
        }
    }

    useEffect(() => {
        forDetails(itemIdForApi, ivIdForApi)
    },[itemIdForApi, ivIdForApi])

    const generateBarcodeFunc = () => {
        if(getCatalougeIvId && getCatalougeIvId != null && barcode && barcode != null) {
            const getBarcodeCatalougeUrl = {
                method: "POST",
                url: Helper.getWsUrl() + `/inventory/get_single_barcode/?web=1`,
                headers: { "Content-Type": "application/json", 'token': cookies.token },
                data:{
                    barcode_type: 0,
                    item_id: getCatalougeIvId,
                    quantity: parseInt(barcode)
                }
            };
            axios(getBarcodeCatalougeUrl)
            .then((response) => {           
                if (response.data.code === 200) {
                    setGetBarcodeUrl(Helper.encypt_decrypt(response.data.data)[0].pdf_link)                
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  } 
            })
            .catch((error) => {
                alert(error);
            })
        }
    }

    const downloadBarcodeFunc = () => {
        window.open(getBarcodeUrl, '_blank', 'noopener,noreferrer')
        setGetBarcodeUrl(null)
    }

    const editItemValueFunc = () => {
        const editItemCatalougeUrl = {
            method: "PATCH",
            url: Helper.getWsUrl() + `/items/edit_items/${itemIdForApi}/?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
            data:{
                itemDetails:{
                    discount_per:0.0,
                    // feature_image:getCatalougeItemData && getCatalougeItemData.feature_image === 1 && getCatalougeItemVarient && getCatalougeItemVarient.live != 1 ? 1 : 0,
                    gst_tax_per: parseFloat(getCatalougeItemData && getCatalougeItemData.gst_tax_per),
                    image_1:getCatalougeItemData && getCatalougeItemData.image_1 === 1 && getCatalougeItemVarient && getCatalougeItemVarient.live != 1 ? 1 : 0,
                    image_2:getCatalougeItemData && getCatalougeItemData.image_2 === 1 && getCatalougeItemVarient && getCatalougeItemVarient.live != 1 ? 1 : 0,
                    image_3:getCatalougeItemData && getCatalougeItemData.image_3 === 1 && getCatalougeItemVarient && getCatalougeItemVarient.live != 1 ? 1 : 0,
                    image_4:getCatalougeItemData && getCatalougeItemData.image_4 === 1 && getCatalougeItemVarient && getCatalougeItemVarient.live != 1 ? 1 : 0,
                    item_name: getCatalougeItemData && getCatalougeItemData.item_name,
                    min_qty:0.0,
                    miu_id: parseInt(getCatalougeItemData && getCatalougeItemData.miu_id),
                    product_service: getCatalougeItemData && getCatalougeItemData.product_service,
                    short_description: getCatalougeItemVarient && getCatalougeItemVarient.disc,
                    master_category_id: masterCategoryId && masterCategoryId,
                    item_category_id: masterSubCategoryId && masterSubCategoryId,
                },                 
                product_pricing_details:[                    
                ]            
            }
        };
        axios(editItemCatalougeUrl)
            .then((response) => {           
                if (response.data.code === 201) {
                    setOpen(false)
                    setGetEditItemApiRes(response.data)
                    if(itemImg1 && itemImg1 != null) {
                        imgCount = 0
                        AWSCredentials(itemImg1, imgCount, itemIdForApi, getCatalougeItemData.image_1_url)
                    }
                    if(itemImg2 && itemImg2 != null) {
                        imgCount = 1
                        AWSCredentials(itemImg2, imgCount, itemIdForApi, getCatalougeItemData.image_2_url)
                    }  
                    if(itemImg3 && itemImg3 != null) {
                        imgCount = 2
                        AWSCredentials(itemImg3, imgCount, itemIdForApi, getCatalougeItemData.image_3_url)
                    }  
                    if(itemImg4 && itemImg4 != null) {
                        imgCount = 3
                        AWSCredentials(itemImg4, imgCount, itemIdForApi, getCatalougeItemData.image_4_url)
                    }            
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } 
            })
            .catch((error) => {
                alert(error);
            })
    }

    const AWSCredentials = (imgFile, imgCount, itemId, oldImgUrl) => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/settings/get_aws_credientials/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                if(response.data.code === 200) {
                    const imgFileKey = "_original_"+`${imgCount}`+".png"
                    const FOLDER_NAME =  `${contact_id}/itm/${itemId}`
                    const config = {
                        bucketName: Helper.encypt_decrypt(response.data.data).bucket_name,
                        region: "ap-south-1",
                        dirName: FOLDER_NAME,
                        accessKeyId: Helper.encypt_decrypt(response.data.data).aws_access_key,
                        secretAccessKey: Helper.encypt_decrypt(response.data.data).aws_secret_access_key,
                        s3Url: "https://enbfile.s3.ap-south-1.amazonaws.com",
                    }
                    const ReactS3Client = new S3(config)
                    ReactS3Client
                        .uploadFile(imgFile, imgFileKey)
                        .then(data => {
                            // console.log("data : ",data);
                            updateImageUrl(ivIdForApi, oldImgUrl, data.location)
                        })
                        .catch(err => console.error("error",err))
                }
            }
        );
    }

    const updateImageUrl = (ivId, oldUrl, newUrl) => {
        const request = {
            method: "PATCH",
            url: Helper.getWsUrl() + `/items/update_image_new/${ivId}/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
            data: {
                image_url: newUrl,
                remove_img: oldUrl,
            }
        };
        axios(request)
        .then((response) => {       
            if (response.data.code === 201) {
                forDetails(itemIdForApi, ivIdForApi)
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              } 
        })
        .catch((error) => {
            alert(error);
        })
    }

    useEffect(() => {
        if(getEditItemApiRes && getEditItemApiRes != null) {
            const editVarientCatalougeUrl = {
                method: "PATCH",
                url: Helper.getWsUrl() + `/items/edit_item_verient/${ivIdForApi}/?web=1`,
                headers: { "Content-Type": "application/json", 'token': cookies.token },
                data:{
                    data:{
                        b2b: getCatalougeItemVarient && getCatalougeItemVarient.b2b,
                        color: getCatalougeItemVarient && getCatalougeItemVarient.color !=null && getCatalougeItemVarient.color !="" ? getCatalougeItemVarient.color : null,
                        discount_per: parseFloat(discOnUpdate && discOnUpdate),
                        live: getCatalougeItemVarient && getCatalougeItemVarient.live,
                        min_qty: parseInt(getCatalougeItemVarient && getCatalougeItemVarient.min_qty),
                        model: getCatalougeItemVarient && getCatalougeItemVarient.model !=null && getCatalougeItemVarient.model !="" ? getCatalougeItemVarient.model : null,
                        mrp: parseFloat(getCatalougeItemVarient && getCatalougeItemVarient.mrp),
                        purchase_price: parseFloat(getCatalougeItemVarient && getCatalougeItemVarient.purchase_price),
                        ref_key: getCatalougeItemVarient && getCatalougeItemVarient.ref_key !=null && getCatalougeItemVarient.ref_key != "" ? getCatalougeItemVarient.ref_key : null,
                        ref_value: getCatalougeItemVarient && getCatalougeItemVarient.ref_value !=null && getCatalougeItemVarient.ref_value !="" ? getCatalougeItemVarient.ref_value : null,
                        selling_price: parseFloat(getCatalougeItemVarient && getCatalougeItemVarient.selling_price),
                        size: getCatalougeItemVarient && getCatalougeItemVarient.size,
                        stock: getCatalougeItemVarient && getCatalougeItemVarient.stock != null ? parseInt(getCatalougeItemVarient.stock) : 0,
                        discription: getCatalougeItemVarient && getCatalougeItemVarient.disc,
                        expiry_date: getCatalougeItemVarient && getCatalougeItemVarient.exp_date != null ? moment(getCatalougeItemVarient.exp_date).format('yyyy-MM-DD') : null,
                        sku_code: getCatalougeItemVarient && getCatalougeItemVarient.sku_code,
                    }            
                }
            };
            axios(editVarientCatalougeUrl)
            .then((response) => {       
                if (response.data.code === 201) {
                    setGetCatalougeItemData(null)
                    setGetCatalougeItemVarient([])
                    setRefreshAfterEditItem(response.data)
                    setChangeBtn(false);
                    forDetails(itemIdForApi, ivIdForApi)
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  } 
            })
            .catch((error) => {
                alert(error);
            })
        }        
    },[getEditItemApiRes])

    const funcForDltItem = (itemId, ivId) => {
        const deleteItemUrl = {
            method: "PATCH",
            url: Helper.getWsUrl() + `/items/deactivate_item_v11/?key=single&web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
            data:{
                    item_id: itemId,
                    iv_id: ivId
            }
        };
        axios(deleteItemUrl)
        .then((response) => {         
            if (response.data.code === 200) {
                window.location.reload()
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              } 
        })
        .catch((error) => {
            alert(error);
        })
    }

    useEffect(() => {
        const requestOption = {
          method: "GET",
          url: Helper.getWsUrl() + `/masters/get_itemunit_active/1/?web=1`,
          headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(requestOption)
          .then((response) => {      
            if (response.data.code === 200) {
              setGetUnitDataCata(Helper.encypt_decrypt(response.data.data))
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              } 
          })
          .catch((error) => {
            alert(error);
          });
    },[getCatalougeItemData])

    useEffect(() => {
        const requestOption = {
            method: "GET",
            url: Helper.getWsUrl() + `/masters/get_merchant_gst_per_new/?web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(requestOption)
            .then((response) => {     
                if (response.data.code === 200) {
                    setGstData(Helper.encypt_decrypt(response.data.data))
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  } 
            })
            .catch((error) => {
                alert(error);
            });

        const requestCategoryOption = {
            method: "GET",
            url: Helper.getWsUrl() + `/items/get_merchant_category/?web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(requestCategoryOption)
            .then((response) => {       
                if (response.data.code === 200) {
                    setMasterCategoryId(Helper.encypt_decrypt(response.data.data)[0].id)
                    getSubCategory(Helper.encypt_decrypt(response.data.data)[0].id)
                } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
                }
            })
            .catch((error) => {
                alert(error);
            });
    },[])

    const addSubCategory = () => {
        const requestOptionForGst = {
            method: "POST",
            url: Helper.getWsUrl() + `/items/post_item_category/${masterCategoryId}/?web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
            data: {
                name: addNewSubCategory && addNewSubCategory
            }
        };
        axios(requestOptionForGst)
            .then((response) => {      
                if (response.data.code === 201) {
                    getSubCategory(masterCategoryId)
                    document.getElementById("newSubCategoryForm").reset()
                    setAddNewSubCategory(null)
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else {
                    alert(response.data.msg)
                    setAddNewSubCategory(null)
                }
            })
            .catch((error) => {
              alert(error);
            });
    }

    const getSubCategory = (id) => {
        const requestOptionForGst = {
            method: "GET",
            url: Helper.getWsUrl() + `/items/get_item_category/${id}/?web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(requestOptionForGst)
            .then((response) => {      
                if (response.data.code === 200) {
                    setMasterSubCategoryList(Helper.encypt_decrypt(response.data.data))
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else if (response.data.code === 204) {
                    setMasterSubCategoryList([])
                }
            })
            .catch((error) => {
              alert(error);
            });
    }

    useEffect(() => {
        if(getCatalougeItemData && getCatalougeItemData != null) {
            if(getCatalougeItemData && getCatalougeItemData.image_1_url != "--NA--" && getCatalougeItemData.image_1_url != "" && getCatalougeItemData.image_1_url != null) {
                setGetCatalougeItemData({
                    ...getCatalougeItemData,
                    image_1: 1,
                })
            }
        }
    },[getCatalougeItemData && getCatalougeItemData.image_1_url])

    if(getCatalougeItemData && getCatalougeItemData.image_1 === 1 && getCatalougeItemVarient && getCatalougeItemVarient.live === 0) {
        img_1 = getCatalougeItemData && getCatalougeItemData.image_1_url
        var firstImg = {
            width:"20%",
            aspectRatio:"1/1",
            border:"none", 
            borderRadius:"10px",
            background:"rgb(237 237 237)",
            padding:"0"
        }
    } else {
        if(getCatalougeItemVarient && getCatalougeItemVarient.live === 0 && changeBtn) {
            var firstImg = {
                width:"20%",
                aspectRatio:"1/1",
                border:"none", 
                borderRadius:"10px",
                background:"rgb(237 237 237)",
                padding:"0"
            }
        } else {
            var firstImg = {
                display:"none"
            }
        }
    }

    if(getCatalougeItemData && getCatalougeItemData.image_2 === 1 && getCatalougeItemVarient && getCatalougeItemVarient.live === 0) {
        img_2 = getCatalougeItemData && getCatalougeItemData.image_2_url
        var secImg = {
            width:"20%",
            aspectRatio:"1/1",
            border:"none", 
            borderRadius:"10px",
            background:"rgb(237 237 237)",
            padding:"0"
        }
    }else {
        if(getCatalougeItemVarient && getCatalougeItemVarient.live === 0 && changeBtn) {
            var secImg = {
                width:"20%",
                aspectRatio:"1/1",
                border:"none", 
                borderRadius:"10px",
                background:"rgb(237 237 237)",
                padding:"0"
            }
        } else {
            var secImg = {
                display:"none"
            }
        }
    }

    if(getCatalougeItemData && getCatalougeItemData.image_3 === 1 && getCatalougeItemVarient && getCatalougeItemVarient.live === 0) {
        img_3 = getCatalougeItemData && getCatalougeItemData.image_3_url
        var thirdImg = {
            width:"20%",
            aspectRatio:"1/1",
            border:"none", 
            borderRadius:"10px",
            background:"rgb(237 237 237)",
            padding:"0"
        }
    }else {
        if(getCatalougeItemVarient && getCatalougeItemVarient.live === 0 && changeBtn) {
            var thirdImg = {
                width:"20%",
                aspectRatio:"1/1",
                border:"none", 
                borderRadius:"10px",
                background:"rgb(237 237 237)",
                padding:"0"
            }
        } else {
            var thirdImg = {
                display:"none"
            }
        }
    }

    if(getCatalougeItemData && getCatalougeItemData.image_4 === 1 && getCatalougeItemVarient && getCatalougeItemVarient.live === 0) {
        img_4 = getCatalougeItemData && getCatalougeItemData.image_4_url
        var fourthImg = {
            width:"20%",
            aspectRatio:"1/1",
            border:"none", 
            borderRadius:"10px",
            background:"rgb(237 237 237)",
            padding:"0"
        }
    }else {
        if(getCatalougeItemVarient && getCatalougeItemVarient.live === 0 && changeBtn) {
            var fourthImg = {
                width:"20%",
                aspectRatio:"1/1",
                border:"none", 
                borderRadius:"10px",
                background:"rgb(237 237 237)",
                padding:"0"
            }
        } else {
            var fourthImg = {
                display:"none"
            }
        }
    }

    const[check1,setCheck1]=useState(false);
    const[check2,setCheck2]=useState(false);
    const[check3,setCheck3]=useState(false);

    var clrA = "#008c53";
    var clrB= "#000000";
    var clrE = "#008c53";
    var clrF= "#000000";
    var clrI = "#008c53";
    var clrJ= "#000000";

    if(check1===true){
        var clrC = clrB;
        var clrD = clrA;
    }else{
        var clrC = clrA;
        var clrD = clrB;
    }
    if(check2===true){
        var clrG = clrF;
        var clrH = clrE;
    }else{
        var clrG = clrE;
        var clrH = clrF;
    }
    if(check3===true){
        var clrK = clrJ;
        var clrL = clrI;
    }else{
        var clrK = clrI;
        var clrL = clrJ;
    }

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    return (
        <>
            <div className="productDetailCatalouge" style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px 20px 5px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <div id="upper" style={{width:"100%", padding:"0 10px"}}>
                    <div style={{width:"100%", display: "flex", justifyContent: "space-between"}}>
                        <p style={{ fontWeight: "600" }}>Product Details</p>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"baseline"}}>
                            <button data-bs-toggle="modal" data-bs-target="#deleteItem" style={{border:"none", background:"none", marginRight:"10px"}}>
                                <img src={require("../assets/images/delete icon.png")} />
                            </button>
                            <span onClick={() => setShowProductDetailsWithVariant(false)}>
                                <img style={{cursor:"pointer"}} src={require("../assets/images/cancel icon.png")} className="img-fluid "/>
                            </span>
                        </div>
                    </div>
                </div>

                <div style={{ margin:"0 0 10px", maxHeight:"65vh", overflow:"auto" }}>
                    <div id="middle" className="" style={{ height: "100%" }}>
                        {/* <div id="img-container" style={{display:"flex", justifyContent:"space-between", width:"100%",padding:"0 40px"}}>
                            <img src={img_1} style={firstImg} class="img-fluid"
                                onError={(e) =>
                                    (e.target.onerror = null)(
                                        (e.target.src = require("../assets/images/enbill logo 1.png"))
                                    )}/>
                            <img src={img_2} style={secImg} class="img-fluid"
                                onError={(e) =>
                                    (e.target.onerror = null)(
                                        (e.target.src = require("../assets/images/enbill logo 1.png"))
                                    )}/>
                            <img src={img_3} style={thirdImg} class="img-fluid"
                                onError={(e) =>
                                    (e.target.onerror = null)(
                                        (e.target.src = require("../assets/images/enbill logo 1.png"))
                                    )}/>
                            <img src={img_4} style={fourthImg} class="img-fluid"
                                onError={(e) =>
                                    (e.target.onerror = null)(
                                        (e.target.src = require("../assets/images/enbill logo 1.png"))
                                    )}/>
                        </div> */}
                        <div id="img-container" style={{display:"flex", justifyContent:"flex-start", columnGap:"7%", width:"100%",padding:"0 40px"}}>
                            {/* <div style={{position:"relative", width:"20%"}}> */}
                                <Button style={firstImg} variant="contained" component="label">
                                    {itemImg1 && itemImg1 != null ? 
                                        <img src={URL.createObjectURL(itemImg1)} style={{width:"100%", height:"100%", borderRadius:"10px",}}/>
                                    :
                                        <>
                                            {img_1 && img_1 != null ? 
                                                <img src={img_1} style={{width:"100%", height:"100%", borderRadius:"10px",}} class="img-fluid"
                                                    onError={(e) => {
                                                        if(e.target.onerror === null) {
                                                            (e.target.src = require("../assets/images/enbill logo 1.png"))
                                                        }}}/>
                                            :
                                                <img src={require("../assets/images/Add Image Icon.png")} />
                                            }
                                        </>
                                    }
                                    <input disabled={changeBtn ? "" : "disabled"} type="file" hidden accept="image/*" onChange={(e) => {setItemImg1(e.target.files[0])}} />
                                </Button>
                                {/* <button onClick={() => {updateImageUrl(ivIdForApi, getCatalougeItemData.image_1_url, null)}} style={{position:"absolute", width:"100%", bottom:"0", left:"0", color:"#fff", background:"#008c53", border:"none", borderRadius:"55px 55px 15px 15px", fontSize:"12px", fontWeight:"500", display: changeBtn ? (getCatalougeItemData && getCatalougeItemData.image_1_url != null && getCatalougeItemData.image_1_url != "--NA--") ? "block" : "none" : "none"}}>Remove</button>
                            </div>
                            <div style={{position:"relative", width:"20%"}}> */}
                                <Button style={secImg} variant="contained" component="label">
                                    {itemImg2 && itemImg2 != null ? 
                                        <img src={URL.createObjectURL(itemImg2)} style={{width:"100%", height:"100%", borderRadius:"10px",}}/>
                                    :
                                        <>
                                            {img_2 && img_2 != null ? 
                                                <img src={img_2} style={{width:"100%", height:"100%", borderRadius:"10px",}} class="img-fluid"
                                                    onError={(e) => {
                                                        if(e.target.onerror === null) {
                                                            (e.target.src = require("../assets/images/enbill logo 1.png"))
                                                        }}}/>
                                            :
                                                <img src={require("../assets/images/Add Image Icon.png")} />
                                            }
                                        </>
                                    }
                                    <input disabled={changeBtn ? "" : "disabled"} type="file" hidden accept="image/*" onChange={(e) => {setItemImg2(e.target.files[0])}} />
                                </Button>
                                {/* <button onClick={() => {updateImageUrl(ivIdForApi, getCatalougeItemData.image_2_url, null)}} style={{position:"absolute", width:"100%", bottom:"0", left:"0", color:"#fff", background:"#008c53", border:"none", borderRadius:"55px 55px 15px 15px", fontSize:"12px", fontWeight:"500", display: changeBtn ? (getCatalougeItemData && getCatalougeItemData.image_1_url != null && getCatalougeItemData.image_2_url != "--NA--") ? "block" : "none" : "none"}}>Remove</button>
                            </div>
                            <div style={{position:"relative", width:"20%"}}> */}
                                <Button style={thirdImg} variant="contained" component="label">
                                    {itemImg3 && itemImg3 != null ? 
                                        <img src={URL.createObjectURL(itemImg3)} style={{width:"100%", height:"100%", borderRadius:"10px",}}/>
                                    :
                                        <>
                                            {img_3 && img_3 != null ? 
                                                <img src={img_3} style={{width:"100%", height:"100%", borderRadius:"10px",}} class="img-fluid"
                                                    onError={(e) => {
                                                        if(e.target.onerror === null) {
                                                            (e.target.src = require("../assets/images/enbill logo 1.png"))
                                                        }}}/>
                                            :
                                                <img src={require("../assets/images/Add Image Icon.png")} />
                                            }
                                        </>
                                    }
                                    <input disabled={changeBtn ? "" : "disabled"} type="file" hidden accept="image/*" onChange={(e) => {setItemImg3(e.target.files[0])}} />
                                </Button>
                                {/* <button onClick={() => {updateImageUrl(ivIdForApi, getCatalougeItemData.image_3_url, null)}} style={{position:"absolute", width:"100%", bottom:"0", left:"0", color:"#fff", background:"#008c53", border:"none", borderRadius:"55px 55px 15px 15px", fontSize:"12px", fontWeight:"500", display: changeBtn ? (getCatalougeItemData && getCatalougeItemData.image_1_url != null && getCatalougeItemData.image_3_url != "--NA--") ? "block" : "none" : "none"}}>Remove</button>
                            </div>
                            <div style={{position:"relative", width:"20%"}}> */}
                                <Button style={fourthImg} variant="contained" component="label">
                                    {itemImg4 && itemImg4 != null ? 
                                        <img src={URL.createObjectURL(itemImg4)} style={{width:"100%", height:"100%", borderRadius:"10px",}}/>
                                    :
                                        <>
                                            {img_4 && img_4 != null ? 
                                                <img src={img_4} style={{width:"100%", height:"100%", borderRadius:"10px",}} class="img-fluid"
                                                    onError={(e) => {
                                                        if(e.target.onerror === null) {
                                                            (e.target.src = require("../assets/images/enbill logo 1.png"))
                                                        }}}/>
                                            :
                                                <img src={require("../assets/images/Add Image Icon.png")} />
                                            }
                                        </>
                                    }
                                    <input disabled={changeBtn ? "" : "disabled"} type="file" hidden accept="image/*" onChange={(e) => {setItemImg4(e.target.files[0])}} />
                                </Button>
                                {/* <button onClick={() => {updateImageUrl(ivIdForApi, getCatalougeItemData.image_4_url, null)}} style={{position:"absolute", width:"100%", bottom:"0", left:"0", color:"#fff", background:"#008c53", border:"none", borderRadius:"55px 55px 15px 15px", fontSize:"12px", fontWeight:"500", display: changeBtn ? (getCatalougeItemData && getCatalougeItemData.image_1_url != null && getCatalougeItemData.image_4_url != "--NA--") ? "block" : "none" : "none"}}>Remove</button>
                            </div> */}
                        </div>
                        {changeBtn ? 
                            <div style={{display:"flex", justifyContent:"space-around",backgroundColor:"#fff",paddingTop:"10px"}}>
                                {/* <div class="form-check form-switch" style={{display:"flex", justifyContent:"center"}}>
                                    <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", color:clrC}} class="form-check-label" for="flexSwitchCheckDefault">Product</p>
                                    <input onClick={() => {
                                                setCheck1(!check1)
                                                if(getCatalougeItemData && getCatalougeItemData.product_service === 1) {
                                                    setGetCatalougeItemData({
                                                        ...getCatalougeItemData,
                                                        product_service: 0
                                                    })
                                                } else {
                                                    setGetCatalougeItemData({
                                                        ...getCatalougeItemData,
                                                        product_service: 1
                                                    })
                                                }
                                            }}  style={{marginLeft:"10px"}} checked={getCatalougeItemData && getCatalougeItemData.product_service === 1 ? "" : "checked"} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                    <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", marginLeft:"10px",color:clrD}} class="form-check-label" for="flexSwitchCheckDefault">Service</p>
                                </div> */}
                                <div class="form-check form-switch" style={{display:"flex"}}>
                                    <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", color:clrG}} class="form-check-label" for="flexSwitchCheckDefault">B2C</p>
                                    <input onClick={() => {
                                                setCheck2(!check2)
                                                if(getCatalougeItemVarient && getCatalougeItemVarient.b2b === 1) {
                                                    setGetCatalougeItemVarient({
                                                        ...getCatalougeItemVarient,
                                                        b2b: 0
                                                    })
                                                } else {
                                                    setGetCatalougeItemVarient({
                                                        ...getCatalougeItemVarient,
                                                        b2b: 1
                                                    })
                                                }
                                            }} style={{marginLeft:"10px"}} checked={getCatalougeItemVarient && getCatalougeItemVarient.b2b === 1 ? "checked" : ""} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                    <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", marginLeft:"10px",color:clrH}} class="form-check-label" for="flexSwitchCheckDefault">B2B</p>
                                </div>
                                <div class="form-check form-switch" style={{display:"flex"}}>
                                    <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", color:clrK}} class="form-check-label" for="flexSwitchCheckDefault">Offline</p>
                                    <input onClick={() => {
                                                setCheck3(!check3)
                                                if(getCatalougeItemVarient && getCatalougeItemVarient.live === 1) {
                                                    setGetCatalougeItemVarient({
                                                        ...getCatalougeItemVarient,
                                                        live: 0
                                                    })
                                                } else {
                                                    setGetCatalougeItemVarient({
                                                        ...getCatalougeItemVarient,
                                                        live: 1
                                                    })
                                                }
                                            }} style={{marginLeft:"10px"}} checked={getCatalougeItemVarient && getCatalougeItemVarient.live === 1 ? "" : "checked"} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                    <p style={{fontSize:"16px", fontWeight:"600", lineHeight:"26px", marginLeft:"10px",color:clrL}} class="form-check-label" for="flexSwitchCheckDefault">Live</p>
                                </div>
                            </div>
                        : 
                            <div style={{ display: "flex", margin:"0", padding:"0" }}>
                                {/* { getCatalougeItemData && getCatalougeItemData.product_service === 1 ? 
                                    <p style={{ fontSize: "40px", marginTop:"0", marginBottom:"0", padding:"0" }}>.<span style={{ fontSize: "15px", marginLeft: "10px", fontWeight: "500" }}>Product</span></p>
                                :
                                    <p style={{ fontSize: "40px", marginTop:"0", marginBottom:"0", padding:"0" }}>.<span style={{ fontSize: "15px", marginLeft: "10px", fontWeight: "500" }}>Service</span></p>
                                } */}
                                { getCatalougeItemVarient && getCatalougeItemVarient.b2b === 1 ? 
                                    <p style={{ fontSize: "40px", marginTop:"0", marginBottom:"0", padding:"0", marginLeft: "20px" }}>.<span style={{ fontSize: "15px", marginLeft: "10px", fontWeight: "500" }}>B2B</span></p>
                                :
                                    <p style={{ fontSize: "40px", marginTop:"0", marginBottom:"0", padding:"0", marginLeft: "20px" }}>.<span style={{ fontSize: "15px", marginLeft: "10px", fontWeight: "500" }}>B2C</span></p>
                                }
                                { getCatalougeItemVarient && getCatalougeItemVarient.live === 1 ? 
                                    <p style={{ fontSize: "40px", marginTop:"0", marginBottom:"0", padding:"0", marginLeft: "20px" }}>.<span style={{ fontSize: "15px", marginLeft: "10px", fontWeight: "500" }}>Offline</span></p>
                                :
                                    <p style={{ fontSize: "40px", marginTop:"0", marginBottom:"0", padding:"0", marginLeft: "20px" }}>.<span style={{ fontSize: "15px", marginLeft: "10px", fontWeight: "500" }}>Live</span></p>
                                }
                            </div>
                        }
                        <div id="inputs" >
                            <div style={{ display: "flex", justifyContent:"space-between", marginRight:"10px", alignItems:"end" }}>
                                <div class="cpd-user-input-wrp" style={{ width: "58%" }}>
                                    <br />
                                    <input autocomplete="off" 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="text"
                                        class="cpd-inputText"
                                        onChange={getEditItemNameFunc}
                                        value={getCatalougeItemData && getCatalougeItemData.item_name}
                                        style={{ width: "100%", padding:"10px 20px"  }}
                                    />
                                    <span class="cpd-floating-label">Item Name</span>
                                </div>
                                <div class="cpd-user-input-wrp" style={{ width: "40%" }}>
                                    <br/>
                                    <span class="cpd-floating-label">Category</span>
                                    <button disabled={changeBtn ? "" : "disabled"} onClick={handleClick('bottom-end')} autocomplete="off" type="text" 
                                            style={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"100%", outline:"none", borderRadius:"10px", backgroundColor:"#f9f9f9", fontSize:"16px", 
                                                    fontWeight:"500", border:"2px solid #008c53", padding:"9.5px 10px", whiteSpace:"nowrap", overflow:"hidden"}} >
                                        {selectedSubCategory && selectedSubCategory != null ? selectedSubCategory : "Category"}
                                        <img src={require('../assets/images/dropdown_arrow.png')} style={{width:"18px"}} />
                                    </button>
                                </div>
                                <Popper style={{width: 350}} open={open} anchorEl={anchorEl} placement={placement} transition>
                                    {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                        <Typography sx={{ p: 0, mt: 1 }}>
                                                <div style={{background:'rgb(245, 245, 245)', borderRadius:"10px", padding:'5px', width:'100%', maxHeight: masterSubCategoryList && masterSubCategoryList.length > 0 ? '35vh' : "12vh"}}>
                                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:'5px'}}>
                                                        <form id='newSubCategoryForm' style={{width:'60%'}}>
                                                            <input placeholder='Add Sub Category' value={addNewSubCategory && addNewSubCategory} onChange={(e) => {setAddNewSubCategory(e.target.value)}} style={{width:'100%', borderRadius:'15px', border:'1px solid #7a7a7a', padding:'4px 15px', fontSize:'14px'}} />
                                                        </form>
                                                        <button disabled={addNewSubCategory && addNewSubCategory != null ? '' : 'disable'} onClick={addSubCategory} style={{border:'none', borderRadius:'15px', background:'#008c53', fontSize:'13px', fontWeight:'500', color:'#fff', padding:'5px 15px', marginLeft:'10px'}}>Add</button>
                                                    </div>
                                                    {masterSubCategoryList && masterSubCategoryList.length > 0 ? 
                                                        <div id='allTableItemList' style={{width:'100%', maxHeight:'30vh', overflowY:'scroll' }}>
                                                            {masterSubCategoryList && masterSubCategoryList.map((value, index) => {
                                                                return (
                                                                    <>
                                                                        <div type='button' onClick={() => {setMasterSubCategoryId(value.ic_id); setSelectedSubCategory(value.name); setOpen(false); setMasterCategoryId(value.mc_id)}} style={{display:"flex", alignItems:"center"}}>
                                                                            <img src={value.image_url != null && value.image_url != "N/A" && value.image_url != "" ? value.image_url : require("../assets/images/enbill logo 1.png")} 
                                                                                    onError={(e) =>
                                                                                        (e.target.onerror = null)(
                                                                                            (e.target.src = require("../assets/images/enbill logo 1.png"))
                                                                                        )} style={{width:"55px", height:"55px"}} />
                                                                            <p style={{padding:'5px', margin:'0', fontSize:'14px', fontWeight:"500"}}>{value.name}</p>
                                                                            <hr style={{margin:'0', border:'0.1px solid rgb(188 184 184)'}}/>
                                                                        </div>
                                                                        <hr style={{padding:"0", margin:"5px 0"}}/>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    :
                                                        <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                            <p style={{fontSize:'14px', marginTop:'20px', fontWeight:'400', color:'#7a7a7a'}}>No sub category found</p>
                                                        </div>
                                                    }
                                                </div>
                                        </Typography>
                                        </Paper>
                                    </Fade>
                                    )}
                                </Popper>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div class="cpd-user-input-wrp" style={{ width: "100%", marginRight: "10px" }}>
                                    <br />
                                    <select 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="text"
                                        class="cpd-inputText"
                                        onChange={getEditUnitFunc}
                                        style={{ width: "100%", padding:"12px 20px" }}>
                                        {getUnitDataCata && getUnitDataCata.map((value, index) => {
                                            return(
                                                <option selected={getCatalougeItemData && getCatalougeItemData.miu_id === value.miu_id ? "selected" : ""} value={value.miu_id}>{value.name}</option>
                                            )
                                        })}
                                    </select>
                                    <span class="cpd-floating-label">Unit</span>
                                </div>
                                <div class="cpd-user-input-wrp" style={{ width: "100%", marginRight: "10px" }}>
                                    <br />
                                    <input autocomplete="off" 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="text"
                                        class="cpd-inputText"
                                        value={getCatalougeItemVarient && getCatalougeItemVarient.size}
                                        onChange={getEditSizeFunc}
                                        style={{ width: "100%", padding:"10px 20px" }}
                                    />
                                    <span class="cpd-floating-label">size</span>
                                </div>
                                <div class="cpd-user-input-wrp" style={{ width: "100%", marginRight: "10px" }}>
                                    <br />
                                    <input autocomplete="off" 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="number"
                                        class="cpd-inputText"
                                        name='hsn_sac_code'
                                        value={getCatalougeItemData && getCatalougeItemData.hsn_sac_code}
                                        onChange={getEditHsnFunc}
                                        style={{ width: "100%",padding:"10px 20px" }}
                                    />
                                    <span class="cpd-floating-label">HSN/SAC</span>
                                </div>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div class="cpd-user-input-wrp" style={{ width: "100%", marginRight: "10px" }}>
                                    <br />
                                    <input autocomplete="off" 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="number"
                                        class="cpd-inputText"
                                        value={getCatalougeItemVarient && getCatalougeItemVarient.mrp}
                                        onChange={getEditMrpFunc}
                                        style={{ width: "100%",padding:"10px 20px" }}
                                    />
                                    <span class="cpd-floating-label">MRP(₹)</span>
                                </div>
                                <div class="cpd-user-input-wrp" style={{ width: "100%", marginRight: "10px" }}>
                                    <br />
                                    <select 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="number"
                                        class="cpd-inputText"
                                        value={getCatalougeItemData && getCatalougeItemData.gst_tax_per}
                                        onChange={getEditGstFunc}
                                        style={{ width: "100%",padding:"12px 20px" }}>
                                        {gstData && gstData.map((value, index) => {
                                            return(
                                                <option>{value.gst_per}</option>
                                            )
                                        })}
                                    </select>                                        
                                    <span class="cpd-floating-label">GST</span>
                                </div>
                                {getCatalougeItemVarient && getCatalougeItemVarient.b2b === 0 ?
                                    null
                                :
                                    <div class="cpd-user-input-wrp" style={{ width: "100%", marginRight: "10px" }}>
                                        <br />
                                        <input autocomplete="off" 
                                            disabled={changeBtn ? "" : "disabled"}
                                            type="number"
                                            class="cpd-inputText"
                                            value={getCatalougeItemVarient && getCatalougeItemVarient.min_qty}
                                            onChange={getEditQtyFunc}
                                            style={{ width: "100%",padding:"10px 20px" }}
                                        />
                                        <span class="cpd-floating-label">Min QTY</span>
                                    </div>
                                }
                            </div>
                            <div style={{ display: "flex" }}>
                                <div class="cpd-user-input-wrp" style={{ width: "100%", marginRight: "10px" }}>
                                    <br />
                                    <input autocomplete="off" 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="number"
                                        class="cpd-inputText"
                                        value={getCatalougeItemVarient && getCatalougeItemVarient.purchase_price}
                                        onChange={getEditPurchaseFunc}
                                        style={{ width: "100%",padding:"10px 20px" }}
                                    />
                                    <span class="cpd-floating-label">Purchase Price(₹)</span>
                                </div>
                                <div class="cpd-user-input-wrp" style={{ width: "100%", marginRight: "10px" }}>
                                    <br />
                                    <input autocomplete="off" 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="number"
                                        class="cpd-inputText"
                                        value={getCatalougeItemVarient && getCatalougeItemVarient.selling_price}
                                        onChange={getEditSellingFunc}
                                        style={{ width: "100%",padding:"10px 20px" }}
                                    />
                                    <span class="cpd-floating-label">Selling Price(₹)</span>
                                </div>
                                <div class="cpd-user-input-wrp" style={{ width: "100%", marginRight: "10px" }}>
                                    <br />
                                    <input autocomplete="off" 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="text"
                                        class="cpd-inputText"
                                        value={getCatalougeItemVarient && getCatalougeItemVarient.sku_code}
                                        onChange={getEditSkuCodeFunc}
                                        style={{ width: "100%",padding:"10px 20px" }}
                                    />
                                    <span class="cpd-floating-label">SKU Code</span>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop:"20px", padding:"0 10px 0px 0px"}}>
                                <div style={{display:"flex", width:"49%"}}>
                                    <TextField label="Expiry Date" style={{border:"none",width:'100%'}} disabled={changeBtn ? "" : "disabled"} autoComplete='off' inputProps={{ min: maxDate }} value={getCatalougeItemVarient && moment(getCatalougeItemVarient.exp_date).format('yyyy-MM-DD')} onChange={getEditExpiryDateFunc} className="cal3" id="date" type="date" InputLabelProps={{ shrink: true,}} />
                                </div>
                                <div class="cpd-user-input-wrp" style={{ width: "25%", overflow:"hidden", textAlign:"center", borderRadius:"10px", backgroundColor: "rgba(0,140,83,0.5)", padding:"5px 0 "}}>
                                    <p style={{margin:"0"}}>
                                        {discOnUpdate && parseFloat(discOnUpdate).toFixed(2)} % Disc
                                    </p>
                                </div>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div class="cpd-user-input-wrp" style={{ width: "100%", marginRight: "10px" }}>
                                    <br />
                                    <input autocomplete="off" 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="text"
                                        class="cpd-inputText"
                                        value={getCatalougeItemVarient && getCatalougeItemVarient.color != null?  getCatalougeItemVarient && getCatalougeItemVarient.color : ""}
                                        onChange={getEditColorFunc}
                                        style={{ width: "100%",padding:"10px 20px" }}
                                    />
                                    <span class="cpd-floating-label">Color</span>
                                </div>
                                <div class="cpd-user-input-wrp" style={{ width: "100%", marginRight: "10px" }}>
                                    <br />
                                    <input autocomplete="off" 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="text"
                                        class="cpd-inputText"
                                        value={getCatalougeItemVarient && getCatalougeItemVarient.model != null?  getCatalougeItemVarient && getCatalougeItemVarient.model : ""}
                                        onChange={getEditModelFunc}
                                        style={{ width: "100%",padding:"10px 20px" }}
                                    />
                                    <span class="cpd-floating-label">Model</span>
                                </div>
                                <div class="cpd-user-input-wrp" style={{ width: "100%", marginRight: "10px" }}>
                                    <br />
                                    <input autocomplete="off" 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="text"
                                        class="cpd-inputText"
                                        value={getCatalougeItemVarient && getCatalougeItemVarient.ref_value != null?  getCatalougeItemVarient && getCatalougeItemVarient.ref_value : ""}
                                        onChange={getEditRefFunc}
                                        style={{ width: "100%",padding:"10px 20px" }}
                                    />
                                    <span class="cpd-floating-label">Other Refrence</span>
                                </div>
                            </div>
                            <div style={{ marginRight:"10px", display: localStorage.getItem("inventory") != 1 ? "none" : "block"}}>
                                <div style={{ width: "100%", padding:"10px", textAlign:"center", borderRadius:"10px", marginTop:"20px", background:"rgba(0,140,83,0.5)"}}>
                                    Stock - 
                                    <input autocomplete="off" 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="number"
                                        class="cpd-inputText"
                                        value={getCatalougeItemVarient && getCatalougeItemVarient.stock}
                                        onChange={getEditStockFunc}
                                        style={{ width: "10%",padding:"0 5px", background:"none", border:"none" }} />
                                </div>
                            </div>
                        </div>

                        { getCatalougeItemVarient && getCatalougeItemVarient.live === 0 ? 
                            <div id="desc" >
                                <div class="cpd-user-input-wrp" style={{ width: "100%" }}>
                                    <br />
                                    <textarea autocomplete="off" 
                                        disabled={changeBtn ? "" : "disabled"}
                                        type="text"
                                        class="cpd-inputText"
                                        value={getCatalougeItemVarient && getCatalougeItemVarient.disc}
                                        onChange={getEditDiscFunc}
                                        style={{ paddingBottom: "20%", width: "100%" }}
                                    />
                                    <span style={{top:"7%"}} class="cpd-floating-label">Description</span>
                                </div>
                            </div>
                        : null }
                    </div>
                </div>
                <div id="lower" style={{display: "flex", justifyContent: "center" }}>
                    {changeBtn ? 
                        <button onClick={() => {
                                if(getCatalougeItemVarient && getCatalougeItemVarient.mrp != null && getCatalougeItemVarient.mrp != "") {
                                    if (getCatalougeItemVarient && getCatalougeItemVarient.selling_price != null && getCatalougeItemVarient.selling_price != "") {
                                        if (getCatalougeItemVarient && getCatalougeItemVarient.mrp >= getCatalougeItemVarient.selling_price) {
                                            if(getCatalougeItemVarient && getCatalougeItemVarient.live != 1) {
                                                if((itemImg1 && itemImg1 != null) || (itemImg2 && itemImg2 != null) || (itemImg3 && itemImg3 != null) || (itemImg4 && itemImg4 != null) || (img_1 != null) || (img_2 != null) || (img_3 != null) || (img_4 != null)) {
                                                    // if(getCatalougeItemVarient && getCatalougeItemVarient.disc != null) {
                                                        editItemValueFunc();
                                                    // } else {
                                                    //     alert("Description is required on live item")
                                                    // }
                                                } else {
                                                    alert("Add atleast one image on live item")
                                                }
                                            } else {
                                                editItemValueFunc();
                                            }
                                        } else {
                                            alert("Item Selling Price is not more than MRP")
                                        }
                                    } else {
                                        alert("Item Selling Price is required")
                                    }
                                } else {
                                    alert("Item MRP is required")
                                }
                                }} style={{ backgroundColor: "#008c53", color: "#fff", borderRadius: "20px", padding: "5px 25px",fontWeight:"600", border:"none" }}>
                            Update
                        </button>                    
                    :
                        <button onClick={() => {setChangeBtn(true)} } style={{ backgroundColor: "white", color: "#008C53", borderRadius: "20px", padding: "5px 20px",fontWeight:"600", border:"1px solid #008c53" }}>
                            Edit
                        </button>
                    }
                    <button data-bs-toggle="modal" data-bs-target="#exampleModal"
                            style={{marginLeft:"10px", backgroundColor: "#008C53", color: "white", borderRadius: "20px", padding: "5px 25px",border:"none",fontWeight:"600" }}>
                        Generate Barcode
                    </button>                    
                </div>
            </div>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="bar-user-input-wrp" style={{ width: "100%" }}>
                                <br />
                                <input type="text" maxLength={2} class="inputText" required name="gst" style={{ width: "100%" }} onChange={(e) => {validation(e)}} value={barcode} autoComplete="off" />
                                <span class="floating-label">Enter Barcode Quantity</span>                                                               
                                <img src ={require("../assets/images/barcode 1.png")} style={{ position: "absolute", right: "2%", top: "50%" }} class="img-fluid" />
                            </div>
                        </div>
                        <div class="modal-footer" style={{ border: "none" }}>
                            <button style={{background:"none", border:"none", fontWeight:"700", color:"#7a7a7a"}} data-bs-dismiss="modal">cancel</button>
                            <a style={{background:"none", border:"none", fontWeight:"700", color:"#008c53", cursor:"pointer"}} data-bs-dismiss="modal" 
                                    onClick={() => { generateBarcodeFunc();}} >
                                proceed
                            </a>
                            {getBarcodeUrl && downloadBarcodeFunc()}
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="deleteItem" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div style={{marginTop:"5px"}} >
                            <p style={{marginLeft:"18px"}} >Do you want to delete item?</p>
                            <div style={{display:"flex",justifyContent:"end"}} >
                                <p data-bs-dismiss="modal" style={{color:"#008C53", fontWeight:"600", cursor:"pointer"}}>No</p>
                                <p onClick={() => {funcForDltItem(getCatalougeItemVarient.item_id, getCatalougeItemVarient.iv_id)}} style={{marginLeft:"20px", fontWeight:"600", color:"#008C53",cursor:"pointer"}} >Yes</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CatalougeProductDetailsWithVariant;