import "./Viewbill.css";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const OrderAddGrahak = ({setShowAddOrderPage, setGrahakDetails}) => {

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()

    const [filteredData1,setFilteredData1]=useState(null)
    const [show1, setShow1] = useState(false);
    const [custGrahakName,setCustGrahakName]=useState(null)
    const [getDataFrmPostCust, setGetDataFrmPostCust] = useState(null)
    const [custId, setCustId] = useState(null)
    const [searchedContactId, setSearchedContactId] = useState(null)

    const [contactsearch,setContactsearch]=useState({
        mobile_number:"",
      })

    const getContactpost = useCallback(event => {
        const { name, value } = event.target;
        setCustGrahakName({ ...custGrahakName, [name]: value });
    });

    useEffect(() => {
        if(searchedContactId && searchedContactId != null){
          setCustId(searchedContactId)
        } else if(getDataFrmPostCust && getDataFrmPostCust != null) {
          setCustId(getDataFrmPostCust)
        }
    },[searchedContactId, getDataFrmPostCust])

    const validMobileNo = () => {
        if (contactsearch && contactsearch.mobile_number.match(/^[0-9]\d{9}$/)) {
          postCustomerData() 
        } else {
          alert("Please enter correct number")
        }
      }

    const getContactsearch = (event) => {
      const re = /^[0-9\b]+$/;
      if(event.target.value === '' || re.test(event.target.value)) {
        setContactsearch ({
          ...contactsearch,
          mobile_number: event.target.value     
        });
      }
    };

    useEffect (() => {
        if(contactsearch. mobile_number){
        ContactSearchFunc(contactsearch.mobile_number)
        }
    },[contactsearch.mobile_number])

    const  ContactSearchFunc=(mobile_number)=>{
        const requestOption = {
        method: "GET",
        url: Helper.getWsUrl() + `/contact/search_customer/?phone=${mobile_number}&web=1`,
        headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(requestOption)
        .then((response) => {  
            // setCookie("token", response.headers.token);
            if (response.data.code === 200) {
              Helper.consoleLogger("grahak search for add order : ", Helper.encypt_decrypt(response.data.data))
              setFilteredData1(Helper.encypt_decrypt(response.data.data));
              setShow1(true)
            } else if (response.data.code === 401) {
              removeCookie("logged_in")
              removeCookie("token")
              setTimeout(() => {
                  navigate('/')
                  localStorage.setItem("isAuthenticated", false)
                  localStorage.removeItem("isAuthenticated")
              }, 1000)
            }
        })
        .catch((error) => {
            alert(error);
        });
    } 

    const postCustomerData = () => {
        const requestOptions = {
          method: "POST",
          url: Helper.getWsUrl() + "/contact/post_customer/?web=1",
          headers: { "Content-Type": "application/json", Token: cookies.token },
          data: {
            name:custGrahakName && custGrahakName.name,
            mobile_number:contactsearch.mobile_number,
            gst_number: custGrahakName && custGrahakName.gst,
            address: custGrahakName && custGrahakName.address,
            profile_type:3
          },
        };
        axios(requestOptions)
          .then((response) => {
            // setCookie("token", response.headers.token);
            if (response.data.code === 201) {
              // Helper.consoleLogger("customer post order : ", Helper.encypt_decrypt(response.data.data))
              setGetDataFrmPostCust(Helper.encypt_decrypt(response.data.data)[0].customer_id);
            } else if (response.data.code === 499) {
              alert("Customer already added");
            } else if (response.data.code === 401) {
              removeCookie("logged_in")
              removeCookie("token")
              setTimeout(() => {
                  navigate('/')
                  localStorage.setItem("isAuthenticated", false)
                  localStorage.removeItem("isAuthenticated")
              }, 1000)
            }
          })
          .catch((error) => {
            console.log("Something went wrong!!");
          });
    }

    useEffect(() => {
        if(custId && custId != null) {
            customerDetailFunc(custId)
        }
    },[custId])

    const customerDetailFunc = (contactId) => {
        const reqForGrahakDetail = {
          method: "GET",
          url: Helper.getWsUrl() + `/contact/get_contact/${contactId}?web=1`,
          headers: { "Content-Type": "application/json", Token: cookies.token },
        };
        axios(reqForGrahakDetail)
          .then((response) => {
            // setCookie("token", response.headers.token);
            if (response.data.code === 200) {
              setGrahakDetails(Helper.encypt_decrypt(response.data.data));
              setShowAddOrderPage(true)
            } else if (response.data.code === 401) {
              removeCookie("logged_in")
              removeCookie("token")
              setTimeout(() => {
                  navigate('/')
                  localStorage.setItem("isAuthenticated", false)
                  localStorage.removeItem("isAuthenticated")
              }, 1000)
            }
          })
          .catch((error) => {
            console.log("Something went wrong!!");
          });
    }

    const styles = {
        width: "100%",
        textAlign: "left",
        color: "#868D96",
        fontWeight: 400,
        fontSize: "14px"
    };
      

    return (
      <div id="box" style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
         
        <div style={{ display: "flex", flexDirection:"column", justifyContent: "space-between", width:"70%", overflow:"hidden", padding:"0 20px"}} >
          <img style={{height:"32%",width:"20%",alignSelf:"center"}} src={require("../assets/images/orderRightImg.png")} class="img-fluid" />
            <div style={{position:"relative"}}>
              <div style={{width:"100%"}} class="user-input-wrp" >  <br />
                  <input autoComplete="off" style={{position:"relative", width:"100%"}} type="text" value={contactsearch && contactsearch.mobile_number} class="inputText" name="mobile_number" maxlength="10" onChange={(event) => {getContactsearch(event)}}/>
                  {/* <span class="floating-label">Contact No.</span> */}
                  <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Contact No.*</p>
              </div>
                {show1 ? (
                    <div style={{paddingTop: "0px", zIndex:"9999", minHeight:"0", maxHeight:"20vh", overflowY:"scroll", backgroundColor: "#fff",position: "absolute", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", width: "100%",borderRadius:"0 0 10px 10px"}} > 
                    {filteredData1 && filteredData1.map((value, index) => {
                        return (
                        <li onClick={(e) => { setSearchedContactId(value.contact_id); setShow1(false) }} style={{listStyle: "none", border:"none", cursor:"pointer", padding:"0 15px"}} >
                            <p style={styles}  key={value.contact_id} > 
                                {value.mobile_number} <br/>
                                {value.slug_name}
                            </p>
                        </li>
                        );
                    })}
                    </div>
                ) : null }
            </div>
            <div style={{width:"100%"}} class="user-input-wrp"> <br />
                <input autoComplete="off" style={{ width: "100%" }}  type="text" class="inputText" required onChange={getContactpost} name="name"/>
                {/* <span class="floating-label">Grahak name</span> */}
                <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Grahak Name</p>
            </div>
            <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
               <div style={{width:"50%"}} class="user-input-wrp"> <br />
                <input autoComplete="off" type="text" class="inputText" style={{ width: "100%" }} onChange={getContactpost} required name="gst" pattern="/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/" maxLength={15} />
                {/* <span class="floating-label">GST No.</span> */}
                <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>GST No.</p>
            </div>
            <div style={{width:"50%",marginLeft:"10px"}} class="user-input-wrp"> <br />
                <input autoComplete="off" type="text" class="inputText" style={{ width: "100%" }} required name="address" onChange={getContactpost}/>
                {/* <span class="floating-label">Address</span> */}
                <p style={{ position: "absolute", bottom: "27%", left: "20px", backgroundColor: "white", color: "#008C53", fontWeight: "500" }}>Address</p>
            </div>
            </div>
           
        </div>
        <div style={{textAlign:"center",}}>
          <button onClick={() => {validMobileNo()}} style={{ backgroundColor: "#008C53", color: "white", padding:"10px 35px", borderRadius: "20px", border:"none", fontWeight:"600", fontSize:"16px", marginTop:"50px"}}>
            Add
          </button>
        </div>
      </div>
    );
}

export default OrderAddGrahak;
