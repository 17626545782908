import React, { useEffect, useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import Helper from "../util/Helper";
import { useNavigate } from "react-router-dom";

const ProformaTemplate = ({setPerformatemp, setProformaaddgrahak, setTempId}) => {

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate()

  const [showPerformaTemplate, setShowPerformaTemplate] = useState(null);
  const [tempPerformaId, setTempPerformaId] = useState(null);

  const [activeCard, setActiveCard] = useState(0)

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      url: Helper.getWsUrl() + `/contact/get_bill_templates/?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
    };
    axios(requestOptions)
      .then((response) => {
        if (response.data.code === 200) {
          setShowPerformaTemplate(Helper.encypt_decrypt(response.data.data));
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },[])

  useEffect(() => {
    if(showPerformaTemplate && showPerformaTemplate != null) {
      setTempPerformaId(showPerformaTemplate[0].bill_image)
      setTempId(showPerformaTemplate[0].bill_template_id)
    }
  },[showPerformaTemplate])

  return (
    <div className='col-10' style={{backgroundColor:"rgb(245, 245, 245)",padding:"20px", position:"relative", display:"flex"}}>
        <div style={{background:"#fff", width:"45%", height:"100%", borderRadius:"20px", padding:"20px", position:"relative", overflow:"hidden"}}>
          <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
            <h3 style={{fontSize:"18px", fontWeight:"600", lineHeight:"29px"}}>Performa Invoice Templates</h3>
          </div>
          <div style={{display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px", maxHeight:"70vh", overflowY:"scroll"}}>
            {showPerformaTemplate && showPerformaTemplate.map((value, index) => {
                return (
                  <div className={`cardborder  ${activeCard == index && 'card_active'}`} onClick={() => {
                      setTempPerformaId(value.bill_image);
                      setTempId(value.bill_template_id);
                      setActiveCard(index)
                    }} style={{borderRadius: "5px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px", boxSizing: "border-box", marginBottom: "10px", width: "80%", padding: "5px"}}>
                    <img src={value.bill_image} class="img-fluid" />
                  </div>
                );
              })}
          </div>
        </div>

        <div style={{background:"#fff", width:"55%", height:"100%", borderRadius:"20px", padding:"20px", position:"relative", overflow:"hidden", marginLeft:"20px"}}>
          <div style={{ display: "flex", justifyContent: "center", alignItems:"center", flexDirection:"column"}}>
            <img style={{ height: "520px" }} src={tempPerformaId} class="img-fluid" />
            <button onClick={() => {
                  setPerformatemp(false);
                  setProformaaddgrahak(true);
                }}style={{backgroundColor: "#008C53", fontWeight:"600", fontSize:"17px", color: "#fff", borderRadius: "25px", border: "1px solid #008C53 ", padding: "5px 20px", marginTop:"10px"}}>
              Continue
            </button>
          </div>
        </div>
     
    </div>
  );
};
export default ProformaTemplate;
