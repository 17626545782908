import React, {useState, useEffect,useCallback} from 'react'
import Helper from "../util/Helper";
import axios from "axios";
import {  useCookies } from "react-cookie";
import './PurchasePayment.css'
import { useNavigate } from 'react-router-dom';

const PurchasePayment = ({setShowUnpaidPage, getShopDetail, getTotalSellingAmt, getPurchaseItemsDetail,getitemdata, setShowPurchasePayment, setShowPurchaseAddItem}) => {

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate() 

    const [dataFrmAddPurchase, setDataFrmAddPurchase] = useState(null)
    const [restModesDisable, setRestModesDisable] = useState(false)

    const [isShown, setIsShown] = useState(false)
    const [isShown1, setIsShown1] = useState(false)
    const [isShown2, setIsShown2] = useState(false)
    const [isShown3, setIsShown3] = useState(false)
    const [isShown4, setIsShown4] = useState(false)

    const [purchasePaymentArr, setPurchasePaymentArr] = useState([])
    const [dataVoucher1, setDataVoucher1] = useState({
            gst_tax_per:"",
            image_1:0,
            image_2:0,
            image_3:0,
            image_4:0,
            item_name:"",
            miu_id:"",
            product_service:1
    })
    const [dataVoucher2, setDataVoucher2] = useState([])
    
    const [purchaseCashPayment,setPurchaseCashPayment]=useState(null)
    const [purchaseCardPayment,setPurchaseCardPayment]=useState(null)
    const [purchaseOnlinePayment,setPurchaseOnlinePayment]=useState(null)
    const [purchaseChequePayment,setPurchaseChequePayment]=useState(null)

    const [CashAmt,setCashAmt]=useState(0)
    const [CardAmt,setCardAmt]=useState(0)
    const [OnlineAmt,setOnlineAmt]=useState(0)
    const [ChequeAmt,setChequeAmt]=useState(0)

    const [refundAmt, SetRefundAmt] = useState(0)
    const [varRestBalance, setVarRestBalance] = useState(0)
    const [refund, setrefund] = useState(0)

    let cash = 0, card =0, online = 0, cheque = 0
    var varTotalAmt = 0 

    const purchaseCashPaymentMode = useCallback(event => {
        const { name, value } = event.target;
        setPurchaseCashPayment({ ...purchaseCashPayment, [name]: value });        
    });

    const purchaseCardPaymentMode = useCallback(event => {
        const { name, value } = event.target;
        setPurchaseCardPayment({ ...purchaseCardPayment, [name]: value });
    });
      
    const purchaseOnlinePaymentMode = useCallback(event => {
        const { name, value } = event.target;
        setPurchaseOnlinePayment({ ...purchaseOnlinePayment, [name]: value });
    });    
    
    const purchaseChequePaymentMode = useCallback(event => {
        const { name, value } = event.target;
        setPurchaseChequePayment({ ...purchaseChequePayment, [name]: value });
    });

    const [purchaseCashObj, setPurchaseCashObj] =useState({
        contact_id:null,
        payment_mode:1,
        total_recieved: 0
    })
    
    useEffect(() => {
        setPurchaseCashObj({
          ...purchaseCashObj,
          total_recieved: parseFloat(purchaseCashPayment && purchaseCashPayment.cashAmt)
        })      
        cash = purchaseCashPayment && purchaseCashPayment.cashAmt != null ? parseFloat(purchaseCashPayment && purchaseCashPayment.cashAmt) : 0
        setCashAmt({
            ...CashAmt,
            cash 
        })
      },[purchaseCashPayment])

    const [purchaseCardObj, setPurchaseCardObj] =useState({
        approval_number:null,
        bank_name: null,
        contact_id:null,
        payment_mode:3,
        total_recieved:0
    }) 

    useEffect(() => {
        setPurchaseCardObj({
            ...purchaseCardObj,
            approval_number:purchaseCardPayment && purchaseCardPayment.approvalNo,
            bank_name: purchaseCardPayment && purchaseCardPayment.bankName,
            total_recieved:parseFloat(purchaseCardPayment && purchaseCardPayment.amount_paid) 
        })
        card = purchaseCardPayment && purchaseCardPayment.amount_paid != null ? parseFloat(purchaseCardPayment && purchaseCardPayment.amount_paid) : 0
        setCardAmt({
            ...CardAmt,
            card 
        })
    },[purchaseCardPayment])

    const [purchaseOnlineObj, setPurchaseOnlineObj] =useState({
        contact_id:null,
        payment_mode:4,
        total_recieved:0
    }) 

    useEffect(() => {
        setPurchaseOnlineObj({
            ...purchaseOnlineObj,
            total_recieved:parseFloat(purchaseOnlinePayment && purchaseOnlinePayment.amountpaid)
        })
        online = purchaseOnlinePayment && purchaseOnlinePayment.amountpaid != null ? parseFloat(purchaseOnlinePayment && purchaseOnlinePayment.amountpaid) : 0
        setOnlineAmt({
            ...OnlineAmt,
            online
        })
    },[purchaseOnlinePayment])

    const [purchaseCheckObj, setPurchaseCheckObj] =useState({
        contact_id:null,
        total_recieved:0,
        payment_mode:5,
        cheque_number :null,
        cheque_amount: null,
        due_date : null,
        bank_name:null,
        date_of_cheque: null
    }) 

    useEffect(() => {
        setPurchaseCheckObj({
            ...purchaseCheckObj,
            total_recieved:parseFloat(purchaseChequePayment && purchaseChequePayment.chequeamt),
            cheque_number :purchaseChequePayment && purchaseChequePayment.chequeNo,
            cheque_amount: parseFloat(purchaseChequePayment && purchaseChequePayment.chequeamt),
            due_date : purchaseChequePayment && purchaseChequePayment.duedate,
            bank_name:purchaseChequePayment && purchaseChequePayment.banknam,
            date_of_cheque: purchaseChequePayment && purchaseChequePayment.dateofcheque
        })
        cheque = purchaseChequePayment && purchaseChequePayment.chequeamt != null ? parseFloat(purchaseChequePayment && purchaseChequePayment.chequeamt) : 0
        setChequeAmt({
            ...ChequeAmt,
            cheque
        })
    },[purchaseChequePayment])

    varTotalAmt += (parseFloat(parseFloat(CashAmt.cash)+parseFloat(CardAmt.card)+parseFloat(OnlineAmt.online)+parseFloat(ChequeAmt.cheque))) 

    // varRestBalance = parseFloat(getTotalSellingAmt) - parseFloat(varTotalAmt)

    useEffect(() => {
        var bal = isNaN(varTotalAmt)
        if(bal === false) {
          setrefund(parseFloat(varTotalAmt) - parseFloat(getTotalSellingAmt))
          if(varTotalAmt > getTotalSellingAmt) {
            setVarRestBalance(getTotalSellingAmt)
          } else {
            setVarRestBalance(parseFloat(parseFloat(getTotalSellingAmt) - parseFloat(varTotalAmt)))
          }
        } else {
          setVarRestBalance(0)
        }
      },[varTotalAmt])

    // refund = parseFloat(varTotalAmt) - parseFloat(getTotalSellingAmt)

    useEffect(() => {
        if(refund < 0) {
            SetRefundAmt(0)
        } else {
            SetRefundAmt(refund)
        }
    },[refund])

    const show = () => {
        setIsShown(!isShown)
    }
    const show1 = () => {
        setIsShown1(!isShown1)   
    }
    const show2 = () => {
        setIsShown2(!isShown2)        
    }
    const show4 = () => {
        setIsShown4(!isShown4)          
    }

    useEffect(() => {
        if( getTotalSellingAmt === 0 ) {
          setRestModesDisable(true)
        }
      },[getTotalSellingAmt])

    const setValuesInArr = () =>{
        callGetPurchaseItem()
        if(isShown){
            if(purchaseCashPayment && purchaseCashPayment.cashAmt != null) {
                setPurchasePaymentArr(purchasePaymentArr => [
                    ...purchasePaymentArr,
                    purchaseCashObj
                ])
            } else {
                alert("Please fill cash amount")
            }
        } 
        if(isShown1){
            if(purchaseCardPayment && purchaseCardPayment.bankName != null && purchaseCardPayment.approvalNo != null && purchaseCardPayment.amount_paid != null) {
                setPurchasePaymentArr(purchasePaymentArr =>[
                    ...purchasePaymentArr,
                    purchaseCardObj
                ])
            } else {
                alert("Please fill card details")
            }
        } 
         if(isShown2){
            if(purchaseChequePayment && purchaseChequePayment.chequeNo != null && purchaseChequePayment.chequeamt != null && purchaseChequePayment.dateofcheque != null && purchaseChequePayment.banknam != null) {
                setPurchasePaymentArr(purchasePaymentArr =>[
                    ...purchasePaymentArr,
                    purchaseCheckObj
                ])
            } else {
                alert("Please fill cheque detail")
            }
        } 
        if(isShown4){
            if(purchaseOnlinePayment && purchaseOnlinePayment.amountpaid != null) {
                setPurchasePaymentArr(purchasePaymentArr =>[
                    ...purchasePaymentArr,
                    purchaseOnlineObj
                ])
            } else {
                alert("Please fill online detail")
            }
        }
    }

    const MultiplePurchasePaymentsMode = () => {
        var t_invoice = parseFloat(getTotalSellingAmt).toFixed(2)
        const requestPurchasePayment = {
            method: "POST",
            url: Helper.getWsUrl() + `/vouchers/add_purchage/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token},
            data:{
                contact_name:getShopDetail.shopname,
                date:getShopDetail.date,
                gst_number:getShopDetail.gst_no,
                paid_status:1,             
                payments:purchasePaymentArr,
                phone_number: getShopDetail.contactno,
                refference_number:getShopDetail.bill_no,
                total_invoice:parseFloat(varTotalAmt),
                type:1
            }
        }
        axios(requestPurchasePayment)
        .then((response) => {
            // setCookie("token", response.headers.token);
            if (response.data.code === 201) {                
                setDataFrmAddPurchase(Helper.encypt_decrypt(response.data.data))
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            }
        })
        .catch((error) => {
            alert(error);
        });
    }

    let V_id = dataFrmAddPurchase && dataFrmAddPurchase.voucher_id;

    useEffect(() => {
        if(V_id != null){
            addPurchaseItems(V_id)
        }
    },[V_id])
    
    useEffect(() => {
        setDataVoucher1((dataVoucher1) => ({
            ...dataVoucher1,
            gst_tax_per: getitemdata && getitemdata.itemgst,
            item_name: getitemdata && getitemdata.itemname,
            miu_id: getitemdata && getitemdata.itemunit
        }))
    },[getitemdata])

    const callGetPurchaseItem = () => {
        getPurchaseItemsDetail && getPurchaseItemsDetail.map((value, index) => {    
            setDataVoucher2((dataVoucher2) => [
                ...dataVoucher2, {
                    b2b:0,
                    color:value.iv_color,
                    discount_per:value.iv_disc,
                    live:1,
                    min_qty:parseFloat(value.iv_minQty),
                    model: value.iv_model,
                    mrp:parseFloat(value.iv_mrp),
                    purchase_price: parseFloat(value.iv_purc),
                    ref_key:"",
                    ref_value: value.p_addReference,
                    selling_price: parseFloat(value.iv_sell),
                    size:value.iv_size,
                    stock: parseInt(value.iv_stock)
                }
            ])
        })
    }

    useEffect(() => {
        if(purchasePaymentArr.length > 0) {
            MultiplePurchasePaymentsMode(); 
        }
    },[purchasePaymentArr])

    const addPurchaseItems = (voucherId) => {
        const requestAddPurchaseItems = {
            method: "POST",
            url: Helper.getWsUrl() + `/items/add_voucher_items/${voucherId}/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token},
            data:{data:[
                            {
                                item_detail:{
                                    gst_tax_per:parseFloat(dataVoucher1 && dataVoucher1.gst_tax_per),
                                    image_1:0,
                                    image_2:0,
                                    image_3:0,
                                    image_4:0,
                                    item_name:dataVoucher1 && dataVoucher1.item_name,
                                    miu_id:parseInt(dataVoucher1 && dataVoucher1.miu_id),
                                    product_service:1
                                },
                                varient_detail:dataVoucher2
                            }
                        ]
                }
        }
        axios(requestAddPurchaseItems)
        .then((response) => {
            // setCookie("token", response.headers.token);
            if (response.data.code === 200) {   
                // setShowPurchasePayment(false)
                // setShowPurchaseAddItem(false)
                window.location.reload()
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              }
        })
        .catch((error) => {
            alert(error);
        });
    }
    var date=new Date();
    var tdate=date.getDate();
    var year = date.getFullYear();
  
  if(tdate < 10){
    tdate = "0"+ tdate;
}
    var month=date.getMonth()+1;
    if(month < 10){
        month = "0"+ month;
    }
    var minDate = year + "-"+month+"-"+tdate;

       
  
    return (
        <div className='col-10' style={{backgroundColor:"rgb(245, 245, 245)",padding:"20px", position:"relative"}}>
            <div style={{background:"#fff", width:"100%", height:"100%", borderRadius:"20px", padding:"20px", position:"relative", overflow:"hidden"}}>
                <div style={{ width: "100%" }}>
                <p style={{ fontSize: "18px", fontWeight: "600", textAlign: "left" }}>Payment</p>
                </div>

                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <p className="para_head">Select Payment Status</p>
                    <div style={{marginLeft:"8%"}} class="user-input-wrp">             
                        <select onChange={() => {setShowUnpaidPage(true)}} class="inputText">
                            <option className="optionData" selected value="Paid">Paid</option>
                            <option className="optionData" value="Unpaid">Unpaid</option>
                        </select>
                    </div> 
                </div>

                <div style={{marginTop:"40px"}}>
                    <p className="para_head">Select Payment Method</p>
                </div>

                <div className="paymentMainDiv" style={{ display: "flex", justifyContent: "space-between", marginTop: "40px" }}>
                    <div className="purchasePaymentCard" >
                        <div id="checkBox" style={{borderRadius:"10px", boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.1)", padding:"14px", marginBottom:"30px",backgroundColor:"#F9F9F9"}}>
                            <div class="form-check">
                                <input onClick={show} class="form-check-input" type="checkbox" value=""/>
                                <label class="form-check-label" for="flexCheckDefault">Cash</label>
                            </div>
                        </div>
                        { isShown ? 
                            <div style={{borderRadius:"10px", boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.1)", padding:"15px 10px"}}>
                                <div style={{position:"relative"}}>
                                    <input  name="cashAmt" onChange={purchaseCashPaymentMode} type="number" className="input_data"/>
                                    <span className='label_data'> Cash Amount </span>
                                </div>
                                <div style={{position:"relative", marginTop:"17px"}}>
                                    <input disabled value={refundAmt} type="text"  className="input_data"/>
                                    <span className='label_data'> Refund </span>
                                </div>
                            </div> 
                        :
                            null
                        } 
                    </div>
                    <div className="purchasePaymentCard" >
                        <div id="checkBox" style={{borderRadius:"10px", boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.1)", padding:"14px", marginBottom:"30px",backgroundColor:"#F9F9F9"}}>
                            <div class="form-check">
                                <input disabled={restModesDisable ? "disable" : ""} onClick={show1} class="form-check-input" type="checkbox" value="" />
                                <label class="form-check-label" for="flexCheckDefault">Card</label>
                            </div>
                        </div>
                        { isShown1 ? 
                            <div style={{borderRadius:"10px", boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.1)", padding:"15px 10px"}}>
                                <div style={{position:"relative"}}>
                                    <input name="bankName"onChange={purchaseCardPaymentMode} type="text" className="input_data"/>
                                    <span className='label_data'> Bank Name </span>
                                </div>
                                <div style={{position:"relative", marginTop:"17px"}}>
                                    <input name="approvalNo" onChange={purchaseCardPaymentMode} type="number" className="input_data"/>
                                    <span className='label_data'> Approval No. </span>
                                </div>
                                <div style={{position:"relative", marginTop:"17px"}}>
                                    <input name="amount_paid" onChange={purchaseCardPaymentMode} type="number" className="input_data"/>
                                    <span className='label_data'> Amount Paid </span>
                                </div>
                            </div> 
                        :
                            null
                        } 
                    </div>
                    <div className="purchasePaymentCard" >
                        <div id="checkBox" style={{borderRadius:"10px", boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.1)", padding:"14px", marginBottom:"30px",backgroundColor:"#F9F9F9"}}>
                            <div class="form-check">
                                <input disabled={restModesDisable ? "disable" : ""} onClick={show2} class="form-check-input" type="checkbox" value="" />
                                <label class="form-check-label" for="flexCheckDefault">Cheque</label>
                            </div>
                        </div>
                        { isShown2 ? 
                            <div style={{borderRadius:"10px", boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.1)", padding:"15px 10px"}}>
                                <div style={{position:"relative"}}>
                                    <input name="chequeNo" onChange={purchaseChequePaymentMode} type="number" className="input_data"/>
                                    <span className='label_data'> Cheque No. </span>
                                </div>
                                <div style={{position:"relative", marginTop:"17px"}}>
                                    <input name="chequeamt" onChange={purchaseChequePaymentMode} type="number" className="input_data"/>
                                    <span className='label_data'> Cheque Amt. </span>
                                </div>
                                {/* <div style={{position:"relative", marginTop:"17px"}}>
                                    <input name="duedate" min={minDate} onChange={purchaseChequePaymentMode} type="date" className="input_data cal2"/>
                                    <span className='label_data'> Due Date </span>
                                </div> */}
                                <div style={{position:"relative", marginTop:"17px"}}>
                                    <input name="dateofcheque" min={minDate}  onChange={purchaseChequePaymentMode} type="date" className="input_data cal2"/>
                                    <span className='label_data'> Date of Cheque </span>
                                </div>
                                <div style={{position:"relative", marginTop:"17px"}}>
                                    <input name="banknam" onChange={purchaseChequePaymentMode} type="text" className="input_data"/>
                                    <span className='label_data'> Bank Name </span>
                                </div>
                            </div> 
                        :
                            null
                        } 
                    </div>
                    <div className="purchasePaymentCard" >
                        <div id="checkBox" style={{borderRadius:"10px", boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.1)", padding:"14px", marginBottom:"30px",backgroundColor:"#F9F9F9"}}>
                            <div class="form-check">
                                <input disabled={restModesDisable ? "disable" : ""} onClick={show4} class="form-check-input" type="checkbox" value="" />
                                <label class="form-check-label" for="flexCheckDefault">Online</label>
                            </div>
                        </div>
                        { isShown4 ? 
                            <div style={{borderRadius:"10px", boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.1)", padding:"15px 10px"}}>
                                <div style={{position:"relative"}}>
                                    <input name="amountpaid" onChange={purchaseOnlinePaymentMode} type="number" className="input_data"/>
                                    <span className='label_data'> Amount Paid </span>
                                </div>
                                <div style={{position:"relative", marginTop:"17px"}}>
                                    <input  type="text" className="input_data"/>
                                    <span className='label_data'> UPI ID </span>
                                </div>
                            </div> 
                        :
                            null
                        } 
                    </div>
                </div>  

                <div style={{position:"absolute", bottom:"1%", right:"3%"}}>
                    <div style={{display:"flex",alignItems:"end", justifyContent:"end"}}>
                        <div style={{textAlign:"right"}}>
                            <p className='para_head'>Total Bill Amount</p>
                            <p className='para_head'>Balance</p>
                        </div>
                        <div style={{textAlign:"left", marginLeft:"20px"}}>
                            <p className='para_head'>₹ {getTotalSellingAmt}</p>
                            <p className='para_head'>₹ {varRestBalance}</p>
                        </div>
                    </div>
                    <div style={{textAlign:"right", marginTop:"30px"}}>
                        <button onClick={() => {
                                if(varRestBalance > 0) {
                                    alert("Please complete your payment")
                                // } else if(varRestBalance < 0) {
                                //     alert("You can not pay more than amount")
                                } else {                            
                                    setValuesInArr()
                                }
                            }} style={{textAlign:"center", fontSize:"20px", fontWeight:"600", border:"none" ,backgroundColor:"#008C53", color:"white" , borderRadius:"20px",padding:"6px 25px"}}>
                            Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PurchasePayment