import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Helper from "../util/Helper";
import { useCookies } from 'react-cookie'
import Button from '@mui/material/Button';
import S3 from 'react-aws-s3';
import Geocode from "react-geocode";
import { useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

Geocode.setLanguage("en");
Geocode.setRegion("in");
Geocode.enableDebug();
Geocode.setApiKey("AIzaSyDY0fD0X7L5jbJDn8M5L9IrA3XsAKHs_tQ");
Geocode.setLocationType("ROOFTOP");

window.Buffer = window.Buffer || require("buffer").Buffer;

const Onboarding = () => {

  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const [merchantImg, setMerchantImg] = useState(null);
  const [isOn, setIsON] = useState(true)
  const [invenOn, setInventOn] = useState(true)
  const [value1, setValue] = useState([]);
  const [entity, setEntity] = useState([]);
  const [businessDetail, setBusinessDetail] = useState(null)
  const [pincode, setPincode] = useState(null)
  const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null)
  const [entityName, setEntityName] = useState([]);
  const [deliveryRange, setDeliveryRange] = useState(0)
  const [minBookingAmt, setMinBookingAmt] = useState(100)
  const [getPincode, setGetPincode] = useState(null)
  const theme = useTheme();
  let p_id = localStorage.getItem("contact_id")

  const setBusinessDetailFunc = (e) => {
    let value = e.target.value
    setBusinessDetail({
      ...businessDetail,
      [e.target.name]: value,
    })
  }

  const handleToggle = () => {
    setIsON(!isOn)
    if (isOn === true) {
      businessDetail.shop_credit = 1
    } else {
      businessDetail.shop_credit = 0
    }
  }

  const handleToggle1 = () => {
    setInventOn(!invenOn)
    if (invenOn === true) {
      businessDetail.inventory = 1
    }
    else {
      businessDetail.inventory = 0
    }
  }

  useEffect(() => {
    const Reuest = {
      url: Helper.getWsUrl() + "/masters/get_category_active/1/?web=1",
      method: "GET",
      headers: { "Content-Type": "application/json", "token": cookies.token },
    };
    axios(Reuest)
      .then((response) => {
        if (response.data.code === 200) {
          setEntity(Helper.encypt_decrypt(response.data.data));
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  }, []);

  const assocCategory = () => {
    const addCategoryURL = {
      url: Helper.getWsUrl() + "/contact/patch_contact_category_assoc/?web=1",
      method: "PATCH",
      headers: { "Content-Type": "application/json", "token": cookies.token },
      data: {
        ids: entityName && entityName
      }
    };
    axios(addCategoryURL)
      .then((response) => {
        if (response.data.code === 200) {
          // console.log(Helper.encypt_decrypt(response.data.data));
        } else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const apicallFunc = () => {
    if (businessDetail && businessDetail.name != null && businessDetail.name != "") {
      if(merchantImg && merchantImg != null) {
        AWSCredentials(merchantImg)
      }
      assocCategory()
      const requestOptions = {
        method: "PATCH",
        url: Helper.getWsUrl() + `/contact/patch_contact/?web=1`,
        data: {
          name: businessDetail.name,
          address: businessDetail.address,
          shop_credit: businessDetail.shop_credit,
          inventory: businessDetail.inventory,
          landmark:  businessDetail.address2 ,
          detail_onboard: 1,
          logo: merchantImg && merchantImg != null ? 1 : 0,
          pincode_text: pincode,
          lat: lat,
          lng: lng,
          km: deliveryRange,
          min_booking_amount: minBookingAmt
        },
        headers: { "Content-Type": "application/json", Token: cookies.token },
      };
      axios(requestOptions)
        .then((response) => {
          if (response.data.code === 201) {
            localStorage.setItem("udhaar",businessDetail.shop_credit)
            localStorage.setItem("inventory", businessDetail.inventory)
            routeChange()
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            setTimeout(() => {
                navigate('/')
                localStorage.setItem("isAuthenticated", false)
                localStorage.removeItem("isAuthenticated")
            }, 1000)
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      alert("Shop Name compulsory")
    }
  }

  useEffect(() => {
    // const length = pincode && pincode.length;
    // if(pincode && pincode != null && length === 6) {
    if(getPincode && getPincode != null) {
      // search_pincode(getPincode)
      Geocode.fromAddress(pincode).then(({ results }) => {
        setLng(results[0].geometry.location.lng)
        setLat(results[0].geometry.location.lat)
      });
    }
  },[getPincode])

  const setPincodeFunc = (event) => {
    if (event.key === 'Enter') {
        // setGetPincode(pincode)
        search_pincode(getPincode)
    }
  }

  const search_pincode = (e) => {
    // if(e.length === 6) {
    //   setPincode(e)
      const requestOptions = {
        // url: `https://api.postalpincode.in/pincode/${e}`,
        url: `https://maps.googleapis.com/maps/api/geocode/json?address=${e}&key=AIzaSyDY0fD0X7L5jbJDn8M5L9IrA3XsAKHs_tQ`,
        method: "GET",
      };
      axios(requestOptions)
        .then((response) => {
          setValue(response.data.results[0].formatted_address);
          setPincode(response.data.results[0].address_components.find(component => component.types.includes('postal_code')).long_name);
        })
        .catch((error) => {
        });
    // }
  };

  const AWSCredentials = (merchant_img) => {
    const requestOptions = {
        method: "GET",
        url: Helper.getWsUrl() + `/settings/get_aws_credientials/?web=1`,
        headers: { "Content-Type": "application/json", "token": cookies.token },
    };
    axios(requestOptions)
        .then((response) => {
            if(response.data.code === 200) {
                const imgFileKey = '_original.png'
                const FOLDER_NAME =  p_id+"/pp"
                const config = {
                    bucketName: Helper.encypt_decrypt(response.data.data).bucket_name,
                    region: "ap-south-1",
                    dirName: FOLDER_NAME,
                    accessKeyId: Helper.encypt_decrypt(response.data.data).aws_access_key,
                    secretAccessKey: Helper.encypt_decrypt(response.data.data).aws_secret_access_key,
                    s3Url: Helper.encypt_decrypt(response.data.data).base_url,
                }
                const ReactS3Client = new S3(config)
                ReactS3Client
                    .uploadFile(merchant_img, imgFileKey)
                    .then(data => console.log("data : ",data))
                    .catch(err => console.error("error",err))
            }
        }
    );
  }

  const routeChange = () => {
    let path = `/onboarding2`;
    navigate(path, { state: { Token: cookies.token } });
  };

  const ITEM_HEIGHT = 55;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  function getStyles(name, entityName, theme) {
    return {
      fontWeight:
        entityName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  }

  const handleChange = (event) => {
    setEntityName([event.target.value])
  };

  return (
    <div style={{ height: "100vh", display: "flex" }}>
      <div style={{ width: "20%" }}>
        <img src={require("../assets/images/side_bar.png")} className="img-fluid" style={{ width: "100%", height: "100%" }} alt="" />
      </div>
      <div style={{ margin: "20px", width: "80%" }}>
        <div style={{ display: "flex", justifyContent: "space-between", width: "35%" }}>
          <button type="button" className="btn btn-sm btn-success rounded-3" style={{ width: "2rem", height: "2rem" }}>
            1
          </button>
          <h3>Business Details</h3>
        </div>
        <hr style={{ height: "2px", width: "100%", borderWidth: 0 }}></hr>
        <div>
          <p className="text-gray-700">Shop Details</p>
        </div>
        <div style={{ display: "flex" }}>
          <div className="col-6">
            <div className="col-12 mb-4">
              <input
                autoComplete="off"
                type="text"
                name="name"
                className="form-control form-control-lg rounded-3"
                placeholder="Enter Shop Name"
                onChange={setBusinessDetailFunc}
                id="customer_name"
                value={businessDetail && businessDetail.name} />
            </div>
            <div id="selectCategoryOnboarding" className="col-12 mb-3">
              <FormControl sx={{ m: 0, width: '100%', borderRadius:'4px' }}>
                <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Category"
                  // multiple
                  value={entityName}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                >
                  {entity.map((name) => (
                    <MenuItem                      
                      value={name.id}
                      style={getStyles(name, entityName, theme)}
                    >
                      {name.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-6" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection:"column" }}>
            <Button style={{background:"#fff", marginTop:"10px", width:"100px", height:"100px", borderRadius:"50%", padding:"0", marginRight:"10px", boxShadow:"none"}} variant="contained" component="label">
              {merchantImg && merchantImg != null ? 
                  <img src={URL.createObjectURL(merchantImg)} style={{width:"100%", height:"100%", borderRadius:"50%",}}/>
              :
                  <img src={require("../assets/images/enbill_logo.png")} style={{width:"100%", height:"100%", borderRadius:"50%",}}/>
              }
              <input type="file" hidden accept="image/*" onChange={(e) => {setMerchantImg(e.target.files[0])}} />
            </Button>
            <p style={{color:"#8e8e8e",fontSize:"20px",fontWeight:"500", margin:"0"}}>Shop Logo</p>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-2 text-start">
            <p className="text-gray-700">Udhaar</p>
          </div>
          <div className="col-1 text-center">
            <div class="form-check form-switch col-lg-1">
              <input
                onClick={handleToggle}
                class="form-check-input"
                type="checkbox"
                role="switch"
              />
            </div>
          </div>
          <div className="col-2 text-start">
            <p className="text-gray-700">Inventory</p>
          </div>
          <div className="col-1 text-center">
            <div class="form-check form-switch col-lg-1">
              <input
                onClick={handleToggle1}
                class="form-check-input"
                type="checkbox"
                role="switch" />
            </div>
          </div>
        </div>

        <div>
          <p style={{margin:"0"}}>Select the preferred option for your Dukaan</p>
          <div style={{display:"flex", width:"30%", justifyContent:"space-between"}}>
            <div style={{display:"flex", alignItems:"center"}}>
              <p className="text-gray-700" style={{margin:"0"}}>Delivery</p>
              <Checkbox {...label} checked={deliveryRange && deliveryRange != 0 ? "checked" : ""} onClick={() => {setDeliveryRange(15)}} color="success" />
            </div>
            <div style={{display:"flex", alignItems:"center"}}>
              <p className="text-gray-700" style={{margin:"0"}}>Pick Up</p>
              <Checkbox {...label} checked={deliveryRange && deliveryRange != 0 ? "" : "checked"} onClick={() => {setDeliveryRange(0)}} color="success" />
            </div>
          </div>
          <p style={{fontSize:"16px", fontWeight:"400", margin:"0", display: deliveryRange && deliveryRange != 0 ? "" : "none"}}>Select range for delivery (in kms)</p>
          <Box width={300} style={{display: deliveryRange && deliveryRange != 0 ? "" : "none"}}>
            <Slider onChange={(e) => {setDeliveryRange(e.target.value)}} sx={{color:"#008c53"}} max={15} min={0} value={deliveryRange && deliveryRange} aria-label="Default" valueLabelDisplay="auto" />
          </Box>
        </div>

        <div style={{display: deliveryRange && deliveryRange != 0 ? "" : "none"}}>
          <p style={{fontSize:"16px", fontWeight:"400", margin:"0"}}>Select the minimum amount required to receive a booking (in ₹)</p>
          <div style={{display:"flex"}}>
            <Box width={300} style={{}}>
              <Slider onChange={(e) => {setMinBookingAmt(e.target.value)}} sx={{color:"#008c53"}} max={1000} min={100} value={minBookingAmt} aria-label="Default" valueLabelDisplay="auto" />
            </Box>
            <p style={{margin:"0 0 0 10px"}}>( ₹ {minBookingAmt} )</p>
          </div>
        </div>

        <hr style={{ height: "2px", width: "100%", borderWidth: 0 }}></hr>

        <div className="row">
          <div className="col text-start">
            <p className="text-gray-700">Location Details</p>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-12 mt-2 mb-3">
                <input
                  type="text"
                  name="location"
                  className="form-control form-control-lg rounded-3"
                  placeholder="Enter PIN code or area"
                  // maxLength={6}
                  autoComplete="off"
                  onKeyDown={setPincodeFunc}
                  onChange={(e) => {setGetPincode(e.target.value)}}
                  required
                />
              </div>
              <div className="col-12 mt-2 mb-3">
                <input
                  type="text"
                  name="address2"
                  className="form-control form-control-lg rounded-3"
                  placeholder="Appartment/Building"
                  maxLength={100}
                  autoComplete="off"
                  onChange={setBusinessDetailFunc}
                  required
                />
              </div>
              <div className="col-12 mb-4">
                {/* <select name="address" onChange={setBusinessDetailFunc} style={{width:"100%", fontSize:"1.25rem", padding: "0.5rem 1rem", borderRadius:"0.3rem", color:"#212529", border:"1px solid #ced4da", background:"#fff"}}>
                  <option selected disabled>select Address</option>
                  {value1 && value1.map((value, index) => {
                    return (
                      <option style={{color:"#7a7a7a", fontSize:"16px"}} key={index} value={value.Name + "," + value.District + "," + value.State + "," + value.Pincode}>{value.Name}, {value.District}, {value.State}, {value.Pincode}</option>
                    )
                    })}
                </select> */}
                <input
                  type="text"
                  name="address"
                  className="form-control form-control-lg rounded-3"
                  placeholder="Address"
                  value={value1}
                  autoComplete="off"
                  onChange={setBusinessDetailFunc}
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "100%", marginTop: "0px" }}>
          <div style={{ float: "right" }}>
            <button
              type="button"
              class="btn btn-outline-success rounded-pill"
              onClick={(e) => {
                e.preventDefault();
                apicallFunc()
              }}
              style={{
                backgroundColor: "rgb(0, 140, 83, 1)",
                color: "white",
                width: "175px",
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Onboarding;
