import React from 'react'
import { CashBookLeft } from './CashBookLeft'
import "./Galla.css"
import PassBookLeft from './PassBookLeft'

export const GallaLeft = ({setGetPaymentId, setShowPassBookDetail, setGetDate, showCashBook, showPassbookList, skeletonLoading}) => {
  return (
    <>
      {showCashBook ? 
        <CashBookLeft setGetDate = {setGetDate}/> 
      :
        <PassBookLeft skeletonLoading = {skeletonLoading} showPassbookList = {showPassbookList} setShowPassBookDetail = {setShowPassBookDetail} setGetPaymentId = {setGetPaymentId}/>
      }
    </>
  )
}
