import { useEffect, useState } from "react";
import './ClickUdhaar.css'
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const InventoryLeft = ({setInventorydetails, skeletonLoading, setInventoryItemId, setInventoryIvId, showInventoryList, setInventoryFilter, inventoryFilterValue, setInventoryFilterValue}) => {

    const navigate = useNavigate()

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const [getInventoryList, setGetInventoryList] = useState([])
    const [navbarOpen, setNavbarOpen] = useState(false)

    // const [inventoryInStock, setInventoryInStock] = useState(true);
    // const [inventoryLowStock, setInventoryLowStock] = useState(true);
    // const [inventoryOutStock, setInventoryOutStock] = useState(true);

    // const [inStockBtn, setInStockBtn] = useState(true)
    // const [lowStockBtn, setLowStockBtn] = useState(true)
    // const [outStockBtn, setOutStockBtn] = useState(true)
    const [minIndex, setMinIndex] = useState(0)
    const [maxIndex, setMaxIndex] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)

    const [countPage, setCountPage] = useState(1)
    // const [countPageInStock, setCountPageInStock] = useState(1)
    // const [countPageLowStock, setCountPageLowStock] = useState(1)  
    // const [countPageOutStock, setCountPageOutStock] = useState(1)  
    const [countPageAllStock, setCountPageAllStock] = useState(1)     
    const [active, setActive] = useState(null)

    const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
    }  

    const handlePagination = (pageNumber) => {
        setMinIndex((pageNumber - 1) * 10)
        setMaxIndex(pageNumber * 10)
        setCurrentPage(pageNumber)
    }

    useEffect(() => {
        setGetInventoryList(showInventoryList)
    },[showInventoryList])

    // useEffect(() => {
    //     const getBillListUrl = {
    //       method: "GET",
    //       url: Helper.getWsUrl() + `/inventory/get_inventory_list/?web=1`,
    //       headers: { "Content-Type": "application/json", 'token': cookies.token },
    //     };
    //     axios(getBillListUrl)
    //     .then((response) => {  
    //         // setCookie("token", response.headers.token);            
    //         if (response.data.code === 200) {
    //             setGetInventoryList(Helper.encypt_decrypt(response.data.data))                
    //         } else if (response.data.code === 401) {
    //             removeCookie("logged_in")
    //             removeCookie("token")
    //             setTimeout(() => {
    //                 navigate('/')
    //                 localStorage.setItem("isAuthenticated", false)
    //                 localStorage.removeItem("isAuthenticated")
    //             }, 1000)
    //           }
    //     })
    //     .catch((error) => {
    //         alert(error);
    //     })
    // },[]) 

    useEffect(() => {
        let countInventory = 0
        // let countInStock = 0
        // let countLowStock = 0
        // let countOutOfStock = 0
        if(getInventoryList && getInventoryList.length > 0) {
            getInventoryList.map((value,index) =>{
                // if(value.in_stock === 1 && value.warning === 0) {
                //     countInStock += 1
                // } 
                // if (value.in_stock === 0 && value.warning === 1) {
                //     countOutOfStock += 1
                // }
                // if (value.in_stock === 1 && value.warning === 1) {
                //     countLowStock += 1
                // }
                countInventory += 1
            })
            setCountPageAllStock(countInventory)
            // setCountPageInStock(countInStock)
            // setCountPageLowStock(countLowStock)
            // setCountPageOutStock(countOutOfStock)
        }
    },[getInventoryList])

    useEffect(() => {
        // if(inventoryInStock && inventoryLowStock && inventoryOutStock) {
            setCountPage(Math.ceil(countPageAllStock / 10))
        // } 
    })

    // const showInventoryStockDataFunc = () => {        
    //     setCountPage(Math.ceil(countPageInStock / 10))
    //     setInventoryOutStock(false)
    //     setInventoryInStock(true)
    //     setInventoryLowStock(false)

    //     setInStockBtn(false)
    //     setLowStockBtn(true)
    //     setOutStockBtn(true)
    // }
    // const showInventoryOutStockDataFunc = () => {
    //     setCountPage(Math.ceil(countPageOutStock / 10))
    //     setInventoryInStock(false)
    //     setInventoryOutStock(true)
    //     setInventoryLowStock(false)

    //     setInStockBtn(true)
    //     setLowStockBtn(true)
    //     setOutStockBtn(false)
    // }
    // const showInventoryLowStockDataFunc = () => {
    //     setCountPage(Math.ceil(countPageLowStock / 10))
    //     setInventoryInStock(false)
    //     setInventoryOutStock(false)
    //     setInventoryLowStock(true)

    //     setInStockBtn(true)
    //     setLowStockBtn(false)
    //     setOutStockBtn(true)
    // }

    // const showAllInventoryStockDataFunc = () => {        
    //     setInventoryOutStock(true)
    //     setInventoryInStock(true)
    //     setInventoryLowStock(true)

    //     setInStockBtn(true)
    //     setLowStockBtn(true)
    //     setOutStockBtn(true)
    // } 

    const inventoryInStockCard = () => {
        return (
            <>
                {getInventoryList && getInventoryList.map((value, index) => {
                    // if(value.in_stock === 1 && value.warning === 0) {
                        return(
                            index >= minIndex && index < maxIndex && (
                                <div className={`cardborder  ${active == index && 'active'}`} onClick={() => {setInventorydetails(true); setInventoryIvId(value.iv_id); setInventoryItemId(value.item_id);setActive(index)}} style={{display:"flex",marginBottom:"20px",cursor:"pointer", boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.1)",  borderRadius: "10px", overflow: "hidden", boxSizing: "content-box"}}>
                                    <div id="card" style={{display:"flex", flexDirection:"column", padding: "16px 10px 16px 20px", width:"100%", }}>
                                        <div style={{ display: "flex" }}>
                                            <p className="child mb-0" style={{ fontWeight: "600", fontSize: "16px" }}>{value.item_name}</p>
                                            <p  className="child mb-0" style={{ fontWeight: "500", fontSize: "14px", marginLeft: "3px", marginTop: "2px" }}>({value.size},&nbsp;{value.color},&nbsp;{value.model})</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", textAlign:"center" }}>
                                            <div>
                                                <p  className="child mb-0" style={{ fontWeight: "500", fontSize: "16px" }}>MRP</p>
                                                <p  className="child mb-0" style={{ fontWeight: "500", fontSize: "16px" }}>₹ {value.mrp}</p>
                                            </div>
                                            {value.in_stock === 1 && value.warning === 0 ? 
                                                <div className="instock" style={{color:"#008c53"}}>
                                                    <p style={{fontWeight: "700", fontSize: "16px"}} className="child mb-0" >In Stock</p>
                                                    <p style={{ fontWeight: "700", fontSize: "16px"}} className="child mb-0">({value.stock_value})</p>
                                                </div>
                                            : null }
                                            {value.in_stock === 1 && value.warning === 1 ? 
                                                <div className="lowstock" style={{color:"#EE7A37"}}>
                                                    <p style={{fontWeight: "700", fontSize: "16px"}} className="child mb-0">Low Stock</p>
                                                    <p style={{fontWeight: "700", fontSize: "16px"}} className="child mb-0">({value.stock_value})</p>
                                                </div>
                                            : null }
                                            {value.in_stock === 0 && value.warning === 1 ? 
                                                <div className="outofstock" style={{color:"#FF1400"}}>
                                                    <p style={{fontWeight: "700", fontSize: "16px"}} className="child mb-0">Out Of Stock</p>
                                                    <p style={{fontWeight: "700", fontSize: "16px"}} className="child mb-0">({value.stock_value})</p>
                                                </div>
                                            : null }
                                            <div>
                                                <p  className="child mb-0" style={{ fontWeight: "400", fontSize: "14px" }}>Min Qty</p>
                                                <p  className="child mb-0" style={{ fontWeight: "400", fontSize: "14px" }}>{value.min_qty}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems:"center", width: "6%" }}>
                                        {
                                            (index==active)
                                            ?
                                            <img style={{ height: "20px", width: "8px" }} src={require("../assets/images/white_left_marker.png")} id={"vector"} className="img-fluid "></img>
                                            :
                                            <img style={{ height: "20px", width: "8px" }} src={require("../assets/images/vector.png")} id={"vector"} className="img-fluid "></img>

                                        }                                     
                                    </div>
                                </div>
                            )
                        )
                    // }
                })}
            </>
        )
    }

    // const inventoryLowStockCard = () => {
    //     return (
    //         <>
    //             {getInventoryList && getInventoryList.map((value, index) => {
    //                 if(value.in_stock === 1 && value.warning === 1) {
    //                     return(
    //                         index >= minIndex && index < maxIndex && (
    //                             <div className={`cardborder  ${active == index && 'active'}`} onClick={() => {setInventorydetails(true); setInventoryIvId(value.iv_id); setInventoryItemId(value.item_id);setActive(index)}} style={{display:"flex",marginBottom:"20px",cursor:"pointer", boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.1)",  borderRadius: "10px", overflow: "hidden", boxSizing: "content-box"}}>
    //                                 <div id="card" style={{display:"flex", flexDirection:"column", padding: "16px 10px 16px 20px", width:"100%", }}>
    //                                     <div style={{ display: "flex" }} 
    //                                     //className="item"
    //                                     >
    //                                         <p className="child" style={{ fontWeight: "500", fontSize: "16px" }}>{value.item_name}</p>
    //                                         <p className="child" style={{ fontWeight: "400", fontSize: "14px", marginLeft: "10px", marginTop: "3px" }}>({value.size}&nbsp;{value.color}&nbsp;{value.model})</p>
    //                                     </div>
    //                                     <div style={{ display: "flex", justifyContent: "space-between", textAlign:"center" }}>
    //                                         <div 
    //                                         //className="item"
    //                                         >
    //                                             <p className="child">MRP</p>
    //                                             <p className="child">₹ {value.mrp}</p>
    //                                         </div>
    //                                         <div className="lowstock">
    //                                             <p className="child">Low Stock</p>
    //                                             <p className="child">({value.stock_value})</p>
    //                                         </div>
    //                                         <div 
    //                                         //className="item"
    //                                         >
    //                                             <p className="child">Min Qty</p>
    //                                             <p className="child">{value.min_qty}</p>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                                 <div style={{ display: "flex", justifyContent: "center", alignItems:"center", width: "3%" }}>
    //                                     <img style={{ height: "20px", width: "8px" }} src={require("../assets/images/vector.png")} id={"vector"} className="img-fluid "></img>
    //                                 </div>
    //                             </div>
    //                         )
    //                     )
    //                 }
    //             })}
    //         </>
    //     )
    // }
    // const inventoryOutOfStockCard = () => {
    //     return (
    //         <>
    //             {getInventoryList && getInventoryList.map((value, index) => {
    //                 if(value.in_stock === 0 && value.warning === 1) {
    //                     return(
    //                         index >= minIndex && index < maxIndex && (
    //                             <div className={`cardborder  ${active == index && 'active'}`} onClick={() => {setInventorydetails(true); setInventoryIvId(value.iv_id); setInventoryItemId(value.item_id);setActive(index)}}style={{display:"flex",marginBottom:"20px",cursor:"pointer", boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.1)",  borderRadius: "10px", overflow: "hidden", boxSizing: "content-box"}}>
    //                                 <div id="card" style={{display:"flex", flexDirection:"column", padding: "16px 10px 16px 20px", width:"100%", }}>
    //                                     <div style={{ display: "flex" }} 
    //                                     //className="item"
    //                                     >
    //                                         <p className="child" style={{ fontWeight: "500", fontSize: "16px" }}>{value.item_name}</p>
    //                                         <p className="child" style={{ fontWeight: "400", fontSize: "14px", marginLeft: "10px", marginTop: "3px" }}>({value.size}&nbsp;{value.color}&nbsp;{value.model})</p>
    //                                     </div>
    //                                     <div style={{ display: "flex", justifyContent: "space-between", textAlign:"center" }}>
    //                                         <div 
    //                                         //className="item"
    //                                         >
    //                                             <p className="child">MRP</p>
    //                                             <p className="child">₹ {value.mrp}</p>
    //                                         </div>
    //                                         <div className="outofstock">
    //                                             <p className="child">Out Of Stock</p>
    //                                             <p className="child">({value.stock_value})</p>
    //                                         </div>
    //                                         <div 
    //                                         //className="item"
    //                                         >
    //                                             <p className="child">Min Qty</p>
    //                                             <p className="child">{value.min_qty}</p>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                                 <div style={{ display: "flex", justifyContent: "center", alignItems:"center", width: "3%" }}>
    //                                     <img style={{ height: "20px", width: "8px" }} src={require("../assets/images/vector.png")} id={"vector"} className="img-fluid "></img>
    //                                 </div>
    //                             </div>
    //                         )
    //                     )
    //                 }
    //             })}
    //         </>
    //     )
    // }

    const activeBtn = {
        background:"#008c53",
        border:"none",
        color:"#fff"
    }

    return (
        <div style={{width: "40%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff"}}>
            <div style={{position:"relative", width:"100%"}}>
                <h6 style={{ fontWeight: "600",fontSize:'18px' }}>View Inventory</h6>
                <nav className="navBar" style={{height: !navbarOpen ? "0": "40px"}}>
                    <button onClick={handleToggle}>
                        { navbarOpen ? 
                            (<img onClick={() => {setInventoryFilter(false); setCurrentPage(1); setInventoryFilterValue(null)}} src={require("../assets/images/cancel icon.png")} />) 
                            : 
                            (<img src={require("../assets/images/filter icon.png")}/>)
                        }
                    </button>
                    <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
                        <li onClick={() => {setInventoryFilter(true); setInventoryFilterValue(1); setCurrentPage(1); handlePagination(1)}}><a style={inventoryFilterValue === 1 ? activeBtn : null}>In Stock</a></li>
                        <li onClick={() => {setInventoryFilter(true); setInventoryFilterValue(2); setCurrentPage(1); handlePagination(1)}}><a style={inventoryFilterValue === 2 ? activeBtn : null}>Low Stock</a></li>
                        <li onClick={() => {setInventoryFilter(true); setInventoryFilterValue(0); setCurrentPage(1); handlePagination(1)}}><a style={inventoryFilterValue === 0 ? activeBtn : null}>Out of Stock</a></li>
                    </ul>
                </nav>
            </div>
            {skeletonLoading ? 
                <div style={{height:"60vh", overflowY:"scroll"}}>
                    <Skeleton count={4} borderRadius={"10px"} height={"18vh"} /> 
                </div>
            :
                <>
                    {getInventoryList && getInventoryList.length > 0 ?
                        <div style={{overflowY:"scroll", height:"60vh"}}>
                            {inventoryInStockCard()}
                        </div>
                    :
                        <div style={{height:"60vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <p style={{color:"#7a7a7a", fontSize:"18px", fontWeight:"600"}}>no data found</p>
                        </div>
                    }
                </>
            }
            <div className="pageCount">
                <Pagination count={countPage} defaultPage={1} page={currentPage} onChange={(event, pageNumber) => handlePagination(pageNumber)}/>
            </div>
        </div>
    )
}

export default InventoryLeft;
