import React,{useState, useEffect} from 'react'
import Helper from "../util/Helper";
import axios from "axios";
import {  useCookies } from "react-cookie";
import './PurchasePayment.css'
import { useNavigate } from 'react-router-dom';

const PurchasePaymentDate = ({ setShowPurchaseAddItem, setShowUnpaidPage, setShowPurchasePayment, getShopDetail, getTotalSellingAmt, getPurchaseItemsDetail,getitemdata}) => {

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()

    const [dataFrmAddPurchase, setDataFrmAddPurchase] = useState(null)
    const [getExpectedDate, setGetExpectedDate] = useState(null)

    const [dataVoucher1, setDataVoucher1] = useState({
            gst_tax_per:"",
            image_1:0,
            image_2:0,
            image_3:0,
            image_4:0,
            item_name:"",
            miu_id:"",
            product_service:1
    })
    const [dataVoucher2, setDataVoucher2] = useState([])
    const [totalBalance, setTotalBalance] = useState(0)

    useEffect(() => {
        if(getTotalSellingAmt && getTotalSellingAmt != null) {
            setTotalBalance(getTotalSellingAmt)
        }
    },[getTotalSellingAmt])

    const expectedDateFunc = (e) => {
        setGetExpectedDate(e.target.value)
    }

    // console.log("getExpectedDate : ",getExpectedDate)

    useEffect(() => {
            if(dataVoucher2 && dataVoucher2.length > 0) { 
                if(getExpectedDate && getExpectedDate != null) {
                    MultiplePurchasePaymentsMode()
                } else {
                    setDataVoucher2([])
                    alert("Please select expected date")
                }
            } 
    },[dataVoucher2,getExpectedDate])


    // console.log("datavoucher 2 :",dataVoucher2)
    // console.log("getPurchaseItemsDetail 2 :",getPurchaseItemsDetail)
    // console.log("get Total Sellin gAmt : ", getTotalSellingAmt)
    // console.log("totalBalance ", totalBalance)

    const MultiplePurchasePaymentsMode = () => {
        // if(getExpectedDate && getExpectedDate != null){
            var t_invoice = parseFloat(getTotalSellingAmt).toFixed(2)
            const requestPurchasePayment = {
                method: "POST",
                url: Helper.getWsUrl() + `/vouchers/add_purchage/?web=1`,
                headers: { "Content-Type": "application/json", "token": cookies.token},
                data:{
                    contact_name:getShopDetail && getShopDetail.shopname,
                    date:getShopDetail && getShopDetail.date,
                    expected_date:getExpectedDate && getExpectedDate,
                    gst_number:getShopDetail && getShopDetail.gst_no,
                    paid_status:0,             
                    payments:[],
                    phone_number: getShopDetail && getShopDetail.contactno,
                    refference_number:getShopDetail && getShopDetail.bill_no,
                    total_invoice:parseFloat(t_invoice),
                    type:1
                }
            }
            axios(requestPurchasePayment)
            .then((response) => {
                // setCookie("token", response.headers.token);
                if (response.data.code === 201) {                
                    setDataFrmAddPurchase(Helper.encypt_decrypt(response.data.data))
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                }
            })
            .catch((error) => {
                alert(error);
            });
        // } 
    }

    let V_id = dataFrmAddPurchase && dataFrmAddPurchase.voucher_id; 

    useEffect(() => {
        if(V_id != null){
            addPurchaseItems(V_id)
        }
    },[V_id])

    useEffect(() => {
        setDataVoucher1((dataVoucher1) => ({
            ...dataVoucher1,
            gst_tax_per: getitemdata && getitemdata.itemgst,
            item_name: getitemdata && getitemdata.itemname,
            miu_id: getitemdata && getitemdata.itemunit
        }))
    },[getitemdata])

    const callGetPurchaseItem = () => {
        // if(getPurchaseItemsDetail === null) {
        //     dataVoucher2.length ++
        // } else {
            getPurchaseItemsDetail && getPurchaseItemsDetail.map((value, index) => {    
                setDataVoucher2((dataVoucher2) => [
                    ...dataVoucher2, {
                        b2b:0,
                        color:value.iv_color,
                        discount_per:value.iv_disc,
                        live:1,
                        min_qty:10,
                        model: value.iv_model,
                        mrp:parseFloat(value.iv_mrp),
                        purchase_price: parseFloat(value.iv_purc),
                        ref_key:"",
                        ref_value: value.p_addReference,
                        selling_price: parseFloat(value.iv_sell),
                        size:value.iv_size,
                        stock: parseInt(value.iv_stock)
                    }
                ])
            })
        // }
    }

    const addPurchaseItems = (voucherId) => {
        const requestAddPurchaseItems = {
            method: "POST",
            url: Helper.getWsUrl() + `/items/add_voucher_items/${voucherId}/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token},
            data:{   data:[{
                    item_detail:{
                        gst_tax_per:parseFloat(dataVoucher1 && dataVoucher1.gst_tax_per),
                        image_1:0,
                        image_2:0,
                        image_3:0,
                        image_4:0,
                        item_name:dataVoucher1 && dataVoucher1.item_name,
                        miu_id:parseInt(dataVoucher1 && dataVoucher1.miu_id),
                        product_service:1
                    },
                    varient_detail:dataVoucher2
                }]}
        }
        axios(requestAddPurchaseItems)
        .then((response) => {
            // setCookie("token", response.headers.token);
            if (response.data.code === 200) {   
                // setShowPurchasePayment(false)
                // setShowPurchaseAddItem(false)
                window.location.reload()
                // console.log("item details : ",response.data)
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            }
        })
        .catch((error) => {
            alert(error);
        });
    }
    var date=new Date();
    var tdate=date.getDate();
    var year = date.getFullYear();
  
  if(tdate < 10){
    tdate = "0"+ tdate;
  }
    var month=date.getMonth()+1;
    if(month < 10){
        month = "0"+ month;
    }
    var maxDate = year + "-"+month+"-"+tdate;

  return (
    <div className='col-10' style={{backgroundColor:"rgb(245, 245, 245)",padding:"20px", position:"relative"}}>
        <div style={{background:"#fff", width:"100%", height:"100%", borderRadius:"20px", padding:"20px", position:"relative", overflow:"hidden"}}>
            <div style={{ width: "100%" }}>
            <p style={{ fontSize: "18px", fontWeight: "600", textAlign: "left" }}>Payment</p>
            </div>

            <div style={{display:"flex",justifyContent:"center", marginTop:"50px", alignItems:"center"}}>
                <div>
                    <p className="para_head" style={{textAlign:"right", marginTop:"20px"}}>Select Payment Status</p>
                    <p className="para_head" style={{textAlign:"right", marginTop:"50px"}}>Expected Date of Payment</p>
                </div>
                
                <div style={{marginLeft:"8%"}}>
                    <div class="user-input-wrp" style={{width:"100%"}}>             
                        <select onChange={() => {setShowUnpaidPage(false)}} class="inputText">                        
                            <option className="optionData" selected value="Unpaid">Unpaid</option>
                            <option className="optionData" value="Paid">Paid</option>
                        </select>
                    </div>  
                    <div class="user-input-wrp" style={{width:"100%", marginTop:"30px"}}>                

                        <input style={{marginRight:"30px"}} autoComplete="off" type="date" min={maxDate} name="expectedDate" onChange={(e) => expectedDateFunc(e)}  onKeyDown={(evt)=>{const cancelKeypress=RegExp(/^[0-9\b]+$/).test(evt.keyCode.toString());if(cancelKeypress){evt.preventDefault()}}} className="input_data cal2" />
                    </div>  
                </div>            
            </div>

            <div style={{position:"absolute", bottom:"5%", right:"3%"}}>
                <div style={{display:"flex",alignItems:"end", justifyContent:"end"}}>
                    <div style={{textAlign:"right"}}>
                        <p className='para_head'>Total Bill Amount</p>
                        <p className='para_head'>Balance</p>
                    </div>
                    <div style={{textAlign:"left", marginLeft:"20px"}}>
                        <p className='para_head'>₹ {getTotalSellingAmt}</p>
                        <p className='para_head'>₹ {totalBalance}</p>
                    </div>
                </div>
                <div style={{textAlign:"right", marginTop:"30px"}}>
                    <button onClick={() => {
                        if(getPurchaseItemsDetail.length === 0) {
                            if(getExpectedDate && getExpectedDate != null) {
                                MultiplePurchasePaymentsMode()
                            } else {
                                alert("Please select expected date")
                            }
                        } else {
                            callGetPurchaseItem()
                        }
                    }}  style={{textAlign:"center", fontSize:"20px", fontWeight:"600", border:"none" ,backgroundColor:"#008C53", color:"white" , borderRadius:"20px",padding:"6px 25px"}}>Done</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PurchasePaymentDate