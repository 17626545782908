import Helper from "../util/Helper";
import "./Kyc.css"
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ManageQR = () => {

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const[username,setUsername]=useState(null);
    const[visible,setVisible]=useState(false);
    const [qrCodeImg, setQrCodeImg] = useState(null)
    const[inputboxlength,setinputboxlength]=useState(null)

    const dataupiidget=(event)=>{  
        setUsername({
            ...username,
            upi_id:event.target.value
        })
    };

    useEffect(() => {
        qrCodeApi()
    },[])
        
   const qrCodeApi = () => { 
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/contact/get_qrcode/0/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                if (response.data.code === 201) {
                    setUsername(Helper.encypt_decrypt(response.data.data))  
                    setQrCodeImg(Helper.encypt_decrypt(response.data.data).data)
                    if(Helper.encypt_decrypt(response.data.data) != null && Helper.encypt_decrypt(response.data.data) != "") {
                        setVisible(true)    
                    } else {
                        setVisible(false)
                    }  
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  } else {
                    setVisible(false)
                }
            })
            .catch((err) => {
                console.log(err)
            })  
    }

    const verfiyUPIId = () => {
        const Request = {
          url: Helper.getWsUrl() + "/kyc/upi_varify/?web=1",
          method: "POST",
          headers: { "Content-Type": "application/json", Token: cookies.token },
          data: {
            upi_id: username.upi_id && username.upi_id
          },
        };
        axios(Request)
          .then((response) => {
            if (response.data.code === 200) {
                alert(Helper.encypt_decrypt(response.data.data).message)
                getQR()
            } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
            } else {
              alert(response.data.msg);
            }
          })
          .catch((error) => {
            alert(error);
          });
    }

    const getQR = () => {
        const requestOptions = {
            method: "PATCH",
            url: Helper.getWsUrl() + `/contact/patch_contact/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
            data:{
                upi_id: username.upi_id
            }
        };
        axios(requestOptions)
            .then((response) => {  
                if (response.data.code === 201) {
                    setVisible(true)
                    qrCodeApi()
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  } else {
                    alert(response.data.msg)
                    setVisible(false)
                }
            })
            .catch((err) => {
                console.log(err)
            })  
    }
    
    return (
        <div className='col-10' id='main ' style={{ backgroundColor: "#F9F9F9" }}>
            <div className="row" style={{ padding: "1%" }}>
                <div className="col" style={{ backgroundColor: "white" }}>
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div style={{ display: "flex", justifyContent: "end" }}>
                                    <img data-bs-dismiss="modal" style={{}} src={require("../assets/images/cancel icon.png")} />
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <img style={{}} src={require("../assets/images/enbilllogo.png")} />

                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <img style={{}} src={require("../assets/images/User_Name.png")} />
                                </div>

                                <div class="modal-body" style={{display:"flex",justifyContent:"center"}}>
                                    <img style={{}} src={qrCodeImg && qrCodeImg.qr_code_image} />
                                    
                                </div>
                                <div style={{display:"flex",justifyContent:"center"}}>
                                    <p style={{color:"#ED7525",fontWeight:"600"}}>{username && username.upi_id}</p>
                                </div>
                                <div class="modal-footer" style={{ border: "none" ,display:"flex",justifyContent:"center",flexDirection:"column"}}>
                                    <img style={{}} src={require("../assets/images/Frame 2.png")} />
                                    <img style={{}} src={require("../assets/images/Frame 1.png")} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ padding: "1%" }}>
                        <p style={{ fontWeight: "600", fontSize: "20px" }}>ManageQR</p>
                    </div>
                    <div style={{ paddingLeft: "1%" }}>
                        <p style={{ color: "#7A7A7A", fontWeight: "600", size: "18px" }}>UPI details</p>
                    </div>
                    <div style={{ display: "flex", paddingLeft: "1%" }}>
                        <div class="user-input-wrp">
                            <br />
                            <input
                                type="text"
                                autoComplete="off"
                                class="inputText"
                                required
                                style={{ width: "200%" }}
                                name="name" 
                                value={username && username.upi_id}
                                onChange={(event)=>{setinputboxlength(event.target.value.length);dataupiidget(event)}}
                                />
                            <span class="floating-label" style={{fontWeight:"600"}}>UPI ID</span>
                        </div>
                        <div style={{ marginLeft: "23%", marginTop: "2%", }} >
                            <button 
                            onClick={() => getQR()} 
                            // onClick={() => verfiyUPIId()} 
                            className=
                            {(inputboxlength >0)
                                ?
                                    "grey"
                                :
                                "grey2"                             
                            }
                            >Verify</button>
                            {visible ?
                                <button  data-bs-toggle="modal" data-bs-target="#exampleModal" style={{marginLeft:"10px", backgroundColor: "white", borderRadius: "20px", border: "none", padding: "8px 22px", fontSize: "20px", color: "#008C53", border: "1px solid #008C53",fontWeight:
                             "600"}}>QR Code</button>
                            : null }
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "end", marginTop: "5%", marginBottom: "25%" }}>
                        <button onClick={() => {navigate("/dashboard")}} style={{ backgroundColor: "#008C53", borderRadius: "20px", border: "none", padding: "8px 22px", fontSize: "20px",fontWeight:"600", color: "white" }}>Update</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageQR