import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";


const TermsUses = () => {


    return (
        <>
            {/* <div className='container-fluid'>
            <Header/>
            <div className='row' style={{height:"83vh"}}>
                <Sidebar/> */}
            <div className='col-10' id='main-div' style={{ backgroundColor: "#f9f9f9", overflow: "hidden", padding: "20px", display: "flex", height: "90%", overflow: "auto" }}>
                <div style={{ backgroundColor: "white", width: "100%", }}>
                    <div>
                     
                    </div>
                    <div style={{ backgroundColor: "white", width: "100%",  padding: "5%" }}>
                    <p style={{ fontWeight: "900" }}> Terms of Use</p>
                        <p >
                            This policy is owned and operated by Indotechs Software Private Limited through mobile application and website ENBILL.<br></br><br></br>

                           <p style={{fontWeight:"900"}}>1. Company and its Role:</p> 
                            Indotechs Software Pvt Ltd is a company which is engaged in the following roles:<br></br>
                            (a)To carry on the business of providing, importing, exporting, selling, purchasing, trading, production, distribution, development, customization or otherwise deal in mobile application, programs, software packages, internet packages, web applications, products, portals, services, applications, web designs & other related product/services.<br></br>
                            (b)To carry on in India and/or elsewhere the business of manufacturing, designing, marketing, servicing, consulting, repair, alter, assemble, purchase, sale, export, import, transfer, exchange or otherwise deal in all types of electrical and electronic devices, computers/parts or accessories/ other related products.<br></br>
                            (c) To carry on the business of establishing and/or promoting support/consulting services whether IT-enabled or otherwise including but not limited to maintenance of supporting centers, solutions, R&D, IT consulting, placement services, web services, technology process outsourcing, and act as a consultant for the abovementioned services and to carry on the business of data digitalization.<br></br>
                            (d) To operate technology data processing centre or providing management information analysis, development accounting and business information, analysis, and providing data to corporate, institutions, individuals in India and abroad to carry on the business of gathering, compiling, distributing, selling, and providing access to information regarding financial operations, financial status, creditworthiness, and rating consumer responses and management of businesses of all kinds and descriptions and to provided other services through either computer-aided or telephone or through any other mode in India or anywhere in the world ant to carry on the business of providing Infrastructure management services and Application service provider (ASP) services or commercial usage to corporate, institutions, individuals or legal entity whether in India or abroad.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}> 2. Types of users and role defined:</p>
                            These Terms constitute a binding and enforceable legal contract between the Company and a User (as defined below) or any end-user of the Services (collectively, “you”). You represent and warrant that you <br></br>(a) have full legal capacity and authority to agree and bind yourself to these Terms, <br></br>(b) are 18 (eighteen) years of age or older, and <br></br>(c) is an Indian resident. If you represent an entity, organization, or any other legal person, you confirm and represent that you have the necessary power and authority to bind such entity, organization, or legal person to these Terms.<br></br>
                            These Terms also include our privacy policy, available at (“Privacy Policy”) and any internal guidelines, supplementary terms, policies, or disclaimers made available or issued by us from time to time. By continuing to access or use the Platform, or any Service on the Platform, you signify your acceptance of the Terms. Further, you understand that the Platform is intended for the use of Indian residents only, and by your continuing access and/use of the Platform shall be construed as a deemed declaration that you are an Indian resident.<br></br>
                            The Company reserves the right to make changes to these Terms by posting the new/updated version and your continued use and/or non-deletion of the Platform shall indicate your agreement to such changes. Accordingly, we encourage you to kindly continue to review the Terms whenever accessing or using the Platform so as to be abreast with the changes that we may be carrying out to these Terms.<br></br>
                            By using the Services, you agree that you have read, understood, and are bound by these Terms, and that you comply with the requirements listed herein. If you do not agree to all of these Terms or comply with the requirements herein, please do not access the Platform or use the Services.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>  3. Legally bound by accepting the terms & conditions:</p>
                            (a)This Terms of Use is an electronic record in terms of Information Technology Act, 2000 and rules thereunder as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.<br></br>
                            (b)This Terms of Use is published in accordance with the provisions of Rule 3 of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of the Website and/or App.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>4. Usage definitions free and paid models:</p>
                            (a) “Customer” means any person who contracts with you for purchase of goods and/or services and makes a payment or remittance thereunder.<br></br>
                            (b) “Customer Payment Amount” means the amount paid by the Customer to you, using the Service, for purchase of any goods and/or services.<br></br>
                            (c) "Merchant Services" the services or products provided by you to your Customer/s, the remittance/payment for which is to be made through the Customer's valid accounts / Card/s or bank account, using Indotechs Mobile Application Services.<br></br>
                            (d) “Processing Mechanism” means the payment mechanism through the internet or such other mode of payment and delivery as may be notified by Indotechs from time to time, including but not limited to Immediate Payment Service (IMPS), National Electronic Funds Transfer (NEFT), Real Time Gross Settlement (RTGS) and/or UPI (defined hereinafter).<br></br>
                            (e) “Transaction” shall mean an electronic financial transaction undertaken using the Indotechs Mobile Application Services.<br></br>
                            (f) "UPI" means the Unified Payment Interface, which is a payment system that powers multiple bank accounts into a single payment network of participating banks permitting merging several banking features, seamless fund routing & merchant payments into one hood<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>5. Payment Terms and Refund Policy:</p> 
                            (a) By accepting these Terms and Conditions, you expressly authorize Indotechs to hold, receive, disburse and settle funds on your behalf. Such authorization shall permit Indotechs Mobile Application to receive Customer Payment Amounts pursuant to a Transaction into the Indotech’s account (Delivery Versus Payment) to further process each Transaction. Indotechs shall process and transfer such payments received by it to the bank account designated by you for this purpose at the time of registration and/or any such account as instructed by you (“Merchant Account”). The authorization provided herein under shall remain in full force and effect until specifically terminated.<br></br>
                            (b) By accepting these Terms and Conditions, you also expressly authorize Indotechs to hold, receive and settle funds on your behalf to such other value-added services as may be opted & instructed by you on the permitted use of the application.<br></br>
                            (c) Indotechs shall initiate a transfer of the relevant Customer Payment Amount received by it to the respective Merchant Account within T (such) banking days as per annexure A (‘T’ being the date of a successful Transaction and banking days being days on which private banks are open for business in New Delhi) from the date of receipt of the relevant Customer Payment Amount, after deduction of Indotechs Fees and charges as agreed under these Terms and Conditions. For clarity, you acknowledge that Indotech shall be liable to settle Customer Payment Amounts only upon actual receipt thereof.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>6. Right to Amendment:</p>
                            (a) The Company reserves the right to modify the Platform and/or alter these Terms and/or Policies at any time and retains the right to deny access at any time including the termination of membership and deletion of the account, to anyone whom the Company believes has violated the provisions of the User Agreement.<br></br>
                            (b) You are advised that any amendment to the User Agreement incorporated herein by reference will only be notified on the Platform on publicly accessible links and You agree by accessing, browsing or using the Platform that such publication shall immediately constitute sufficient notice for all purposes against You. The revised version/ terms shall be effective from the time that the Company publishes the same on the Platform.<br></br>
                            (c) The Company shall not be liable to give any prior notice to the Users for any amendments to the User Agreement, which in the opinion of the Company, has the effect of diminishing the User’s existing rights or imposing additional obligations. You understand and hereby accept that the decision, whether any proposed amendments to the User Agreement have the effect of diminishing the User’s existing rights or imposing additional obligations on the User, shall be decided by the Company in its sole discretion.<br></br>
                            <br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>7. Eligibility to use as per Indian contract act 1872:</p> 
                            (a) In order to use the Platform, You need to be 18 (eighteen) years of age or older. The Platform may only be used or accessed by such persons who can enter into and perform legally binding contracts under Indian Contract Act, 1872.<br></br>
                            (b)The Company shall not be liable in case of any false information is provided by the User including User’s age and the User and/or his natural or appointed guardian alone shall be liable for the consequences as per the applicable Law of the country to which the User belongs.<br></br>
                            (c)The Company disclaims all liability arising out of such unauthorized use of the Platform and any third-party liability arising out of Your use of the Platform if You are a minor.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>8. Services offered:</p>
                            (a) providing, importing, exporting, selling, purchasing, trading, production, distribution, development, customization or otherwise deal in mobile application, programs, software packages, internet packages, web applications, products, portals, services, applications, web designs & other related product/services<br></br>
                            (b)manufacturing, designing, marketing, servicing, consulting, repair, altering, assembly, purchase, sale, export, import, transfer, exchange or otherwise deal in all types of electrical and electronic devices, computers/parts or accessories/ other related products.<br></br>
                            (c) establishing and/or promoting support/consulting services whether IT-enabled or otherwise including but not limited to maintenance of supporting centers, solutions, R&D, IT consulting, placement services, web services, technology process outsourcing, and acting as a consultant for the above-mentioned services and to carry on the business of data digitalization.<br></br>
                            (d) providing management information analysis, development accounting and business information, analysis, and providing data to corporate, institutions, individuals in India and abroad to carry on the business of gathering, compiling, distributing, selling, and providing access to information regarding financial operations, financial status, creditworthiness, and rating consumer responses and management of the business of all kinds and descriptions<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>  9. User Roles/Obligations and Definitions:</p>
                            As a condition precedent for the use of Company/Mobile Application at all times, the User warrants that the said use shall not be for any purpose that is unlawful, illegal, immoral, or otherwise prohibited by Applicable Laws or these Terms of Use. The User shall not use in any manner that could damage, disable, hinder, inhibit, or impair the Company website/Mobile Application or interfere with any other party's use and enjoyment of the website. The User shall not obtain or attempt to obtain any materials or information by any means not intended to be made available or not provided through the website.<br></br>
                            Company/ Mobile Application may contain data sync service, application download services, chat support services, positioning services through GPS or triangulation technology, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable the user to communicate with Company/ Mobile Application, other Users of Company/ Mobile Application, or the public at large (collectively referred to as "Communication Services"). The User agrees to use the Communication Services only to post, send and receive messages and materials that are appropriate, allowed, and permissible under the present Terms of Use and do not violate or offend any Applicable Laws and these Terms of Use or Privacy Policy.<br></br>
                            The User warrants and undertakes to immediately report any misuse, misappropriation, unauthorized access, unauthorized usage, theft, or any such incidents that the User is aware of in relation to Company-name/ Mobile Application and failure to do so shall render the User solely responsible and liable for any consequences arising out of or in relation to the same.<br></br>
                            The User agrees that he/ she shall access and use Company-name/ Mobile Application strictly in accordance with these Terms of Use and in line with Applicable Laws at all times.<br></br>
                            The User further agrees that he/she shall host, display, upload, modify, publish, transmit, update or share any information that:<br></br>
                            (a) Belongs to another person/User and to which the User does not have any right to;<br></br>
                            (b) Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating to or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever;<br></br>
                            (c) Harms minors in any way<br></br>
                            (d) Infringes any patent, trademark, copyright, or other proprietary rights;<br></br>
                            (e) Violates any law for the time being in force;<br></br>
                            (f) Deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive in nature;<br></br>
                            (g) Impersonate another person;<br></br>
                            (h) Contains software viruses or any other computer code, files, or programs designed to interrupt, destroy or limit the functionality of any computer resource;<br></br>
                            (i) Threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offense or prevents investigation of any offense or is insulting to any other nation;<br></br>
                            (j) Is opposed to the law of land of the nation where the User resides.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>10. Merchant Obligations:</p>
                            Your use of the Website and the mobile application is at your own risk. Everything on the Website and the mobile application including the access to a listing of and information about any Suppliers are provided to you on an "as is where is" basis and without warranty of any kind, either expressed or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. The entire risk as to the results and performance obtained from using the Content is assumed by you.<br></br>
                            The Suppliers and other suppliers providing services listed on the Website or the mobile application are third parties obtaining services from Indotechs as independent contractors and not agents or employees of Indotechs. Indotechs is not liable for the acts, errors, omissions, representations, warranties, breaches, or negligence of any such third parties or for any personal injuries, death, property damage, damage, or other damages or expenses resulting therefrom.<br></br>
                            The Website and the mobile application and the Content contained therein could include technical or other inaccuracies or typographical errors. You agree and acknowledge that much of the Content on the Website and mobile application is provided and/or uploaded by third parties. Indotechs makes no representations or warranties as to the completeness or accuracy of Content. Indotechs makes no commitment to update or correct the Content that appears on the Website and/or the mobile application.<br></br>
                            Indotechs makes no representations about the suitability of the information, software, products, and services contained on the Website and mobile application for any purpose, and the inclusion or offering for sale of any products or services on the Website and mobile application does not constitute any endorsement or recommendation of such products or services by Indotechs. All such information, software, products, and services are provided on an "as is where is" basis without warranty of any kind. Indotechs disclaims all warranties, conditions and liability with regard to this information (including, without limitation, photographs, a list of amenities and descriptions of Suppliers), software, products, and services including all implied warranties and conditions of merchantability, fitness for a particular purpose, title, and non-infringement.<br></br>
                            Information is supplied upon the condition that the persons receiving the same will make their own determination as to its suitability for their purposes prior to use or in connection with the making of any decision.<br></br>
                            Without prejudice to the generality of the above, Indotechs does not confirm or guarantee any accuracy of the ratings provided to the Suppliers listed on the Website and the mobile application, and the same is provided solely for the convenience of the Users.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>11. Intellectual Property Rights (IPR)</p>
                            (a) All rights, title, and interest in and to the Platform and Services, including all intellectual property rights arising out of the Platform and Services, are owned by or otherwise lawfully licensed by the Company. Subject to compliance with these Terms, the Company grants the User a non-exclusive, non-transferable, non-sublicensable, royalty-free, revocable, and limited license to use the Platform and Services in accordance with these Terms and its written instructions issued from time to time.<br></br>
                            (b) The User should assume that everything the User sees or reads on the Platform is protected under the Indian Copyright Act, 1957 and other intellectual property laws of India and may not be used except with the prior written permission of the Company.<br></br>
                            (c) The Company may freely use, copy, disclose, publish, display, distribute without any payment of royalty, acknowledgment, prior consent, or any other form of restriction arising out of the User’s intellectual property rights.<br></br>
                            (d) Except as stated in these Terms, nothing in these Terms should be construed as conferring any right in or license to the Company’s or any third party’s intellectual rights.<br></br>
                            (e) The contents of this Platform, including but not limited to the text and images herein and their arrangements, unless otherwise noted, are copyright-protected in the whole and every part of this Platform and the same belongs to the Company and may not be used, sold, licensed, copied or reproduced in whole or in part in any manner or form or in or on any media to any person without the prior written consent of the Company.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>12. IPR in User Content:</p>
                            Indotechs shall exclusively retain all worldwide rights, title, and interest in and to the Indotechs Services (including without limitation ownership of all intellectual property rights therein and to the Processing Mechanism enabled by Indotechs and software related thereto). You understand and agree that the customizations, if any, carried out for and on behalf of you shall be intellectual property rights of Indotechs and that you can only use such additional modifications/ amendments during the term of the engagement.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>13. Third Party Consent:</p>
                            (a) You agree that the Company and any third-party service providers it engages in, may, in accordance with its Privacy Policy, collect and use your information and technical data, and related information.<br></br>
                            (b) The Company may use information and data pertaining to your use of the Services for analytics, trends’ identification, and purposes of statistics to further enhance the effectiveness and efficiency of the Platform.<br></br>
                            (c) Subject to applicable laws, the Company may be directed by law enforcement agencies or the government and related bodies to disclose data in relation to Users in connection with criminal proceedings. You understand and agree that in such instances, the Company shall have the right to share such data with relevant agencies or bodies.<br></br>
                            <br></br>
                             <p style={{fontWeight:"900"}}>14. Indemnification:</p>
                            You shall indemnify, defend at the Company’s option, and hold the Company, its parent companies, subsidiaries, affiliates, and their officers, associates successors, assigns, licensors, employees, directors, agents, and representatives, harmless from and against any claim, demand, lawsuits, judicial proceeding, losses, liabilities, damages, and costs (including, without limitation, from all damages, liabilities, settlements, costs and attorneys’ fees) due to or arising out of your access to the Services, use of the Services, violation of these Terms or any infringement by any third party who may use your account with the Company, of these Terms.<br></br>
                            <br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}> 15. Disclaimers, Warranties, and Limitation of Liability:</p>
                            Company’s website/Mobile application cannot exclude or limit our liability to the user where it would be illegal to do so; this includes any liability for the gross negligence, fraud, or intentional misconduct of Company or the other companies being Parties in providing the Services. In countries where the following types of exclusions are not allowed, Company-name is responsible to the User only for losses and damages that are a reasonably foreseeable result of our failure to use reasonable care and skill or the breach of our contract with you. This paragraph does not affect consumer rights that cannot be waived or limited by any contract or agreement.<br></br>
                            In countries where exclusions or limitations of liability are permitted, the User agrees that Company/Mobile Application will not be liable for:<br></br>
                            (a) any loss of use; interruption of business; or any direct; indirect; special; incidental; exemplary or punitive or consequential damages of any kind (including lost profits) regardless of the form of action whether in the contract; tort (including negligence); strict product liability or otherwise; whether arising out of the use or inability to use www.company-name.com(including but not limited to loss of data or data being rendered inaccurate), even if Company-name has been advised of the possibility of such damages.<br></br>
                            (b) other than for the types of liability Company-name cannot limit by law (as described in this section), Company-name limit the total liability of the service for any claim arising out of or relating to these Terms or our Services, regardless of the form of the action, to the amount paid by the user to use our Services or to Rs. 3500 whichever is higher.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>16. Compliance with Laws:</p>
                            (a) These Terms and Conditions shall be governed by and construed in accordance with the laws of India.<br></br>
                            (b) Any difference, dispute, conflict or controversy, including any question as to its existence, validity or termination, arising out of or in connection with these Terms and Conditions (“Dispute”) shall, if not amicably settled within 30 (thirty) days after the receipt by one Party of a written notice from the other Party of the existence of such Dispute, be referred to and resolved in accordance with the Arbitration and Conciliation Act, 1996 by a sole arbitrator duly appointed by Indotechs. The seat and venue of arbitration shall be New Delhi and the arbitration shall be conducted in English. The award of the arbitrator shall be final and binding upon the Parties. Each Party shall bear its own cost of arbitration.<br></br>
                            (c) The Courts at New Delhi shall have sole and exclusive jurisdiction over any matters related to these Terms and Conditions.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>17. Report Abuse:</p>
                            The user can report any behavior that they consider to be a violation of the Terms of services and policy. Violations may include spam, malware, violence, hate speech, terrorist content, impersonation, illegal activities, etc.<br></br>
                            Reports do not guarantee removal of content/file, content that may seem inappropriate is not always a violation of Terms of services and policy.<br></br>
                            Actions we may take on abusive material<br></br>
                            (a) Remove the file from the account.<br></br>
                            (b) Restrict sharing of a file.<br></br>
                            (c) Limiting who can view the file.<br></br>
                            (d) Disable access to one or more products.<br></br>
                            (e) Delete the Account.<br></br>
                            (f) Report illegal materials to appropriate law enforcement authorities.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>18. Notices:</p>
                            All notices, requests, demands, and determinations for the Company under these Terms (other than routine operational communications) shall be sent to (mention web address) The User agrees that with respect any written notice which Company is required or permitted to give to the User in connection with these Terms of Use, such notice shall be deemed to have been eﬀectively given immediately upon the electronic transmission of such notice to the email address of the User set forth in their account information.<br></br>
                            Except as otherwise expressly provided herein, all notices and communications to be addressed to Company/Mobile Application with regard to these Terms of Use or the accompanying Privacy Policy shall be in writing and are to be addressed to the following address<br></br>
                            <p style={{fontWeight:"900"}}>19. Disputes and Governing Law:</p>
                            These Terms shall be governed by and construed and enforced in accordance with the laws of India. Subject to other provisions in this Clause, courts in Bangalore shall have exclusive jurisdiction over all issues arising out of these Terms or the use of the Services.<br></br>
                            Any controversies, conflicts, disputes, or differences arising out of these Terms shall be resolved by arbitration in Delhi in accordance with the Arbitration and Conciliation Act, 1996 for the time being in force, which is deemed to be incorporated by reference in this Clause. The tribunal shall consist of 1 (one) arbitrator appointed by the Company. The language of the arbitration shall be English.<br></br>
                            The parties to the arbitration shall keep the arbitration confidential and not disclose to any person, other than on a need-to basis or to legal advisors unless required to do so by law. The decision of the arbitrator shall be final and binding on all the Parties hereto.<br></br>
                            Each party to the arbitration shall bear its own costs with respect to any dispute.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>20. Copyright Policy:</p>
                            The User should assume that everything the User sees or reads on the Platform is protected under the Indian Copyright Act, 1957 and other intellectual property laws of India and may not be used except with the prior written permission of the Company<br></br>
                            The contents of this Platform, including but not limited to the text and images herein and their arrangements, unless otherwise noted, are copyright-protected in the whole and every part of this Platform and the same belongs to the Company and may not be used, sold, licensed, copied or reproduced in whole or in part in any manner or form or in or on any media to any person without the prior written consent of the Company.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>21. Claims regarding Content other than copyright infringement:</p>
                            (a) Any claim shall be reported at info@indotechs.in.<br></br>
                            (b) Takedown of the content shall be done by Indotechs after receiving any feedback from both or more parties.<br></br>
                            (c) Indotechs / EnBill is indemnified from any legal proceedings from all parties.<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>22. Notice:</p>
                            All notices, requests, demands, and determinations for the Company under these Terms (other than routine operational communications) shall be sent to (mention web address).<br></br>
                            <br></br>
                            <p style={{fontWeight:"900"}}>23. Counter-Notice:</p>
                            In case Company-namer emoves any content, personal information or profile of any User deactivates, disables or terminates any Accused User’s account due to suspicion of copyright infringement on the basis of information received from User as above, the Accused User may dispute his/her responsibility for or otherwise the alleged infringement itself by sending a written communication by email or mail to the designated agent of Company-names below.<br></br>
                            [info@indotechs.in]<br></br>
                            That written communication must include the following:<br></br>
                            (a) accused User’s physical or electronic signature;<br></br>
                            (b) identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled;<br></br>
                            (c) a statement under penalty of perjury that the Accused User has good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled; and<br></br>
                            (d) accused User’s name, address, and telephone number, and a statement that the User consents to his/her submission to the jurisdiction of the competent Court as provided for hereunder and that the User will accept service of process from the person who provided notification of copyright infringement or an agent of such person.<br></br>
                            The User must send all notices under the above copyright infringement policies by email or mail to the following designated email address/agent of Company-name for receipt of notifications of claimed infringement.<br></br>

                        </p>

                    </div>

                </div>


            </div>
            {/* </div>
        </div>       */}
        </>
    )
}

export default TermsUses