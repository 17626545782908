import CryptoJS from "crypto-js";
import { AES } from 'crypto-js';
import { mode } from 'crypto-js/aes'

class Helper {

  static environment = 2;
  static server_base_url = null;
  static console_logger = null;
  static numberValue = null;
  /* 
    environment = 0 (Debugging)
    environment = 1 (Development)
    environment = 2 (Production) 
  */

  static getWsUrl() {
    if (Helper.environment === 0) {
      Helper.server_base_url = "http://159.89.166.21:8000/v14";
      Helper.console_logger = 0
    }
     else if (Helper.environment === 1) {
      Helper.server_base_url = "http://192.168.1.34:8001/v14";
      // Helper.server_base_url = "http://192.168.1.27:8000/v14";
      // Helper.server_base_url = "http://192.168.1.28:8000/v14";
      Helper.console_logger = 1
    } else if (Helper.environment === 2) {
      Helper.server_base_url = "https://enbill.site/v14";
    } else {
      Helper.server_base_url = "https://enbill.site/v14";
    }
    return Helper.server_base_url
  }

  static consoleLogger = (data) => {
    if (Helper.console_logger === 0) {
      console.log(data);
    } else if (Helper.console_logger === 1) {
      console.log(data);
    } else {
      console.log();
    }
  };

  static forDecimal = (data) => {
    Helper.numberValue = data.toString().split(".").map((el,i)=>i?el.split("").slice(0,2).join(""):el).join(".")
    return Helper.numberValue
  }

  static encypt_decrypt = (encryptedData) =>{
    var key ='f^Wd2qv8z+gQa%$['//key used in Python
    key = CryptoJS.enc.Utf8.parse(key); 
    var decrypted = CryptoJS.AES.decrypt(encryptedData, key,{mode:CryptoJS.mode.ECB});
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  }

  static encryptData = (data) => {
    var key = CryptoJS.enc.Utf8.parse('d2qv8z+gQa%$[toiiQGk0^33DaAYiUK')//key used in Python
    var keyString = CryptoJS.enc.Utf8.stringify(key);
    console.log("key : ", key, keyString)
    const dataString = JSON.stringify(data);
    console.log("dataString : ", dataString)
    const encryptedData = AES.encrypt(dataString, keyString, {mode:CryptoJS.mode.ECB}).toString();
    console.log("encryptedData : ", encryptedData)
    const dataBase64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptedData))
    console.log("dataBase64 : ", dataBase64)
    return dataBase64;
  }

  // static decryptData = (encryptedData) => {
  //   var key ='f^Wd2qv8z+gQa%$['//key used in Python
  //   const decryptedBytes = AES.decrypt(encryptedData, key, {
  //     mode:CryptoJS.mode.ECB,
  //   });
  //   const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  //   return decryptedData;
  // }
}

export default Helper;
