import React from "react";
import"../components/header.css"
import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Helper from "../util/Helper";
import OtpInput from "react-otp-input";
import { messaging } from '../firebase';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const Login = ({setIsAuthenticated}) => {

  let navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(
    ["token"],
    ["logged_in"],
    ["gcm_id"]
  );

  const increment = useRef(null);
  const [timer, setTimer] = useState(30);
  const [routeChange, setRouteChange] = useState(null)
  const [hide, setHide] = useState(0);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [otp, setOtp] = useState(null)
  const [showResendBtn, setShowResendBtn] = useState(false)
  const [gcmToken, setGcmToken] = useState(null)

  const handleStart = () => {
    increment.current = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(increment.current);
    setShowResendBtn(true)
  };

  const handleReset = () => {
    clearInterval(increment.current);
    setTimer(30);
  };

  useEffect(() => {
    if(routeChange && routeChange != null) {
      if (routeChange.msg !== "not validate") {      
        let path = "/onboarding";
        let path1 = "/dashboard";
        if (routeChange.detail_onboard === 1) {
          // if (routeChange.data.detail_onboard === 1) {
          setCookie("logged_in", "yes");
          // setIsAuthenticated(true)
          localStorage.setItem("isAuthenticated", true)
          navigate(path1,{
            state: cookies.token,
          })
        } else {
          // setIsAuthenticated(true)
          localStorage.setItem("isAuthenticated", true)
          navigate(path, {
            state: cookies.token,
          })
        }    
      }
    }
  },[routeChange])


  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    if (getSeconds <= 0) {
      setShowResendBtn(true)
      handlePause();
      return "00 : 00";
    } else {
      return `00 : ${getSeconds}`;
    }
  };
  
  const sendOtp = (mobileNumber) => {
    // if (hide === 0) {
    var data1 = { mobile_number: mobileNumber, app_type: 0, version: 1.01 };
    const requestOptions = {
      method: "POST",
      url: Helper.getWsUrl() + "/otp/send_otp/?web=1",
      headers: { "Content-Type": "application/json",},
      data: data1,
    };
    axios(requestOptions)
      .then((response) => {
        if(response.data.code === 200) {
          // setOtpToken(response.data.data.token);
          setShowResendBtn(false)
        } else {
          setHide(0);
          setShowResendBtn(false)
          alert(response.data.msg)
        } 
      })
  } 

  const matchOtp = (mobileNumber) => {
    var data2 = {
      mobile_number: mobileNumber,
      app_type: 0,
      version: 1.01,
      otp: otp.toString(),
    };
    const requestOptions1 = {
      method: "POST",
      url: Helper.getWsUrl() + "/otp/match_otp/?web=1",
      headers: { "Content-Type": "application/json",},
      data: data2,
    };
    axios(requestOptions1)
      .then((response) => {
        if (response.data.code === 200) {
          // getGCMId()
          setCookie("token", response.headers.token);
          let res_data = Helper.encypt_decrypt(response.data.data);
          localStorage.setItem("contact_id",res_data.p_id)
          localStorage.setItem("p_id", res_data.contact_id)
          const profile_type = res_data.profile_type  
          if (profile_type == 0){
            setRouteChange(Helper.encypt_decrypt(response.data.data),"profile_type");
          } else {
            alert("you can't login on merchant website because you are a team member");
          }  
        } else {
          alert(response.data.msg);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  useEffect(() => {
    requestPermission()
  },[])

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if(permission === 'granted') {
      const token = await getToken(messaging, {vapidKey: 'BMxJu7HzNYlKaw82xGIiJ27bTZ7De_ukXXKDw7jSh4B-laZ90-AbPvCLdewoWWije9KxDnYcmfYn5qWz0b3614g'})
      setGcmToken(token)
    } else if(permission === 'denied') {
      console.log("You denied for the notification")
    }
  }
  
  // const getGCMId = () => {
  //   const reqestGCMToken = {
  //     method: "POST",
  //     url: Helper.getWsUrl() + "/gcm/add_gcm_data/?web=1",
  //     headers: { "Content-Type": "application/json",},
  //     data: {
  //       gcm_token: gcmToken && gcmToken,
  //       device_info: {
  //         IP: "192.168.1.116",
  //       },
  //       profile_type: 0,
  //     },
  //   };
  //   axios(reqestGCMToken)
  //     .then((response) => {
  //       if (response.data.code === 200) {
  //         setCookie("gcm_id", Helper.encypt_decrypt(response.data.data)[0].gcm_id);
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // }

  const otpDivStyle = {
    justifyContent:"center",
    alignItems:"center"
  }
  const otpInputStyle = {
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    marginLeft:"10px",
    border:"1px solid #D9D9D9",
    backgroundColor:"#D9D9D9"
  }
  const otpInputFocusStyle = {
    border:"1px solid #d9d9d9"
  }
  const boolean = {
    type: "number"
  }

  return (
    <div style={{width:"100%", height:"100vh", overflow:"hidden", display:"flex", justifyContent:"center", alignItems:"center", padding:"20px 40px", background:"#008c53"}}>
      <div className="left_card " style={{height:"100%", width:"50%", background:"#fff", borderRadius:"10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
        <div style={{width:"70%", height:"70%",}}>
          <img src={require("../assets/images/merchant web login screen 1.png")} alt="Enbill Logo" style={{height:"100%",width:"100%"}}/>
        </div>
        <p style={{fontWeight:"600",fontSize:"24px",marginTop:"20px"}} className="text-center">
          Local Dukaan, Local Grahak
        </p>      
          <div  style={{height:"82%",backgroundColor:"rgba(149, 165, 166,0.3)",width:"2px",position:"absolute",left:"50%",}}></div>
      </div>
      
      
      <div className="right_card" style={{height:"100%", width:"50%", padding:"40px", background:"#fff", borderRadius:"10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}} >
        <div style={{width:"120px", height:"120px"}}>
          <img src={require("../assets/images/enbill logo 1.png")} alt="EnBill Logo" style={{width:"100%", height:"100%"}}/>
        </div>
        {hide === 0 ? (
          <h5 style={{fontSize:"22px", textAlign: "center", color: "#008C53", marginTop:"20px",fontWeight:"600"}}>
            Enter your mobile number
          </h5>
        ) : (
          <h5 style={{fontSize:"20px", textAlign: "center", color: "#008C53", marginTop:"30px",fontWeight:"600" }}>
            Enter One Time Password Received on <br/>
            <span style={{fontSize:"20px", marginTop:"10px"}}>+91 {mobileNumber}</span>
          </h5>
        )}
        <form style={{textAlign:"center"}}>
          <div style={{position:"relative"}}>
            <input autoFocus autoComplete="off" style={{display: hide === 0 ? "block" : "none", padding:"5px 5px 5px 40px", width: "100%", borderRadius: "20px", marginTop: "30px",}}
                className="form-control input-sm"
                id="inputsm"
                type="text"
                placeholder="Enter your number"
                maxLength={10}
                value={mobileNumber}
                onChange={(e) => {
                  setMobileNumber( e.target.value.replace(/\D/g, ''))
                  }} />
                {hide === 0 ? 
                  <span style={{
                    position:"absolute", top:"19%", left:"10px"}}>+91</span>
                : null }
            </div>
            {hide === 0 ? 
              <button style={{ marginTop: "25px",fontSize:"18px", borderRadius:"20px",  padding:"7px 20px", fontWeight:"600", background:"#008c53" }}
              type="submit"
              className="btn btn-success"
              onClick={() => {
                  setOtp(null)
                    if (mobileNumber !== "undefined") {
                      var pattern = new RegExp(/^[0-9\b]+$/);
                      if (!pattern.test(mobileNumber)) {
                        alert("Please enter only number.");
                      } else if (mobileNumber.length !== 10) {
                        alert("Please enter valid phone number.");
                      } else {
                        handleReset();
                        handleStart();
                        sendOtp(mobileNumber);
                        hide === 0 ? setHide(1) : setHide(0);
                      }
                    }
                  }}>
                GET OTP
              </button>
          : null
          }
        </form>
        <form style={{textAlign:"center"}}>
          <div id="otp" style={{display: hide === 1 ? "block" : "none", marginTop:"30px"}} >
            <OtpInput
              shouldAutoFocus={true}
              containerStyle = {otpDivStyle}
              inputStyle = {otpInputStyle}
              focusStyle = {otpInputFocusStyle}
              value={otp}
              
              isInputNum = {boolean}
              onChange={(otp) => setOtp(otp)}
              numInputs={4}
              />
          </div>
          {hide === 0 ? (
            null
          ) : (
            <button style={{ fontSize:"20px",marginTop: "30px", borderRadius:"20px",  padding:"7px 20px", fontWeight:"600", background:"#008c53" }}
                type="submit"
                className="btn btn-success"
                onClick={() => { 
                  if (mobileNumber !== "undefined") {
                    var pattern = new RegExp(/^[0-9\b]+$/);
                    if (!pattern.test(mobileNumber)) {
                      alert("Please enter only number.");
                    } else if (
                      mobileNumber.length !== 10
                    ) {
                      alert("Please enter valid phone number.");
                    } else {
                      handleReset();
                      handleStart();
                      matchOtp(mobileNumber);
                      hide === 0 ? setHide(1) : setHide(0);
                    }
                  }
                }}>
              Continue
            </button>
          )}
        </form>
        <h6 style={{fontSize:"16px", color:"#008C53", textAlign:"center", fontWeight:"600", marginTop:"30px", display: hide === 0 ? "block" : "none"}}>
          You will receive One Time Password <br/>to this number
        </h6>
        <h6 style={{fontSize:"16px", textAlign:"center", display: hide === 0 ? "block" : "none" , fontWeight:"600", color:"#868D96", marginTop:"30px"}}>
          By continuing you agree to our <br/> terms of Use and Privacy Policy
        </h6>
        {showResendBtn === false ? 
          <>
            <h6 style={{fontSize:"18px",color:"#4CBD80", marginTop:"30px", display: hide === 1 ? "block" : "none", textAlign: "center"}}>
              {formatTime()}
            </h6>
          </>         
        : null }

        {showResendBtn ? 
          <button onClick={() => {
              setShowResendBtn(false); 
              handleReset();
              handleStart();
              sendOtp(mobileNumber);
            }} style={{fontSize: "16px", marginTop:"20px", border:"none", background:"none", fontWeight:"500", textAlign: "center", padding: "0", display: hide === 1 ? "block" : "none",}}>
            Resend OTP
          </button>
        : null }
      </div>
    </div>
  );
};

export default Login;
