import axios from "axios";
import Helper from "../util/Helper";
import Card from '@mui/material/Card';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import Popper from '@mui/material/Popper';
import Select from '@mui/material/Select';
import { useCookies } from "react-cookie";
import MenuItem from '@mui/material/MenuItem';
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@mui/material/FormControl';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import React, { useEffect, useState, useRef } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OrderDetail = ({ getBookedByForDetail, setBookingToBilling, setShowOrderdetail, getIbIdForDetail, globalIbId, setBookingItemListForBilling, bookingItemListForBilling, setBookingItemBillIdForBilling, setBookingCustomerIdForBilling, setBookingTotalAmtForBilling }) => {

    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const [getOrderDetail, setGetOrderDetail] = useState(null)
    const [itemBookingList, setItemBookingList] = useState([])
    const [getStatus, setGetStatus] = useState(null)
    const [ibIdForApi, setIbIdForApi] = useState(null)
    const [expectedDate, setExpectedDate] = useState(null)
    const [teamMember, setTeamMember] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [openTemp, setOpenTemp] = useState(false);
    const [showChatBtn, setShowChatBtn] = useState(false)
    const [teamList, setTeamList] = useState([])
    const [deliveryBoyList, setDeliveryBoyList] = useState([])
    const [teamRoleId, setTeamRoleId] = useState(null)
    const [teamName, setTeamName] = useState(null)
    const [getBookingChatList, setGetBookingChatList] = useState([])
    const [bookingChatTempList, setBookingChatTempList] = useState([])
    const [selectedChatTempId, setSelectedChatTempId] = useState(null)
    const [selectedChatTempMsg, setSelectedChatTempMsg] = useState(null)
    const [chattingEmptyState, setChattingEmptyState] = useState(false)
    const [bookingChatCount, setBookingChatCount] = useState(0)

    var date = new Date();
    var tdate = date.getDate();
    var year = date.getFullYear();

    if (tdate < 10) {
        tdate = "0" + tdate;
    }
    var month = date.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    var maxDate = year + "-" + month + "-" + tdate;

    useEffect(() => {
        if (getIbIdForDetail && getIbIdForDetail != null) {
            setIbIdForApi(getIbIdForDetail)
        }
    }, [getIbIdForDetail])

    useEffect(() => {
        if (globalIbId && globalIbId != null) {
            setIbIdForApi(globalIbId)
        }
    }, [globalIbId])

    useEffect(() => {
        if (getBookedByForDetail && getBookedByForDetail === 1) {
            if (ibIdForApi && ibIdForApi != null) {
                const getListDetailUrl = {
                    method: "GET",
                    url: Helper.getWsUrl() + `/booking/get_Customer_booking_details/${ibIdForApi}?web=1`,
                    headers: { "Content-Type": "application/json", Token: cookies.token },
                }
                axios(getListDetailUrl)
                    .then((response) => {
                        if (response.data.code === 200) {
                            // console.log("detail online", Helper.encypt_decrypt(response.data.data))
                            setGetOrderDetail(Helper.encypt_decrypt(response.data.data)[0])
                            setItemBookingList(Helper.encypt_decrypt(response.data.data)[0].Items)
                        } else if (response.data.code === 401) {
                            removeCookie("logged_in")
                            removeCookie("token")
                            setTimeout(() => {
                                navigate('/')
                                localStorage.setItem("isAuthenticated", false)
                                localStorage.removeItem("isAuthenticated")
                            }, 1000)
                        }
                    })
                    .catch((error) => {
                        alert(error);
                    })
            }
        } else {
            if (ibIdForApi && ibIdForApi != null) {
                const getListDetailUrl = {
                    method: "GET",
                    url: Helper.getWsUrl() + `/booking/get_booking_details/${ibIdForApi}?web=1`,
                    headers: { "Content-Type": "application/json", Token: cookies.token },
                }
                axios(getListDetailUrl)
                    .then((response) => {
                        if (response.data.code === 200) {
                            // console.log("detail offline", Helper.encypt_decrypt(response.data.data))
                            setGetOrderDetail(Helper.encypt_decrypt(response.data.data)[0].BOOKING[0])
                            setItemBookingList(Helper.encypt_decrypt(response.data.data)[0].BOOKING[0].Item_booking_list)
                        } else if (response.data.code === 401) {
                            removeCookie("logged_in")
                            removeCookie("token")
                            setTimeout(() => {
                                navigate('/')
                                localStorage.setItem("isAuthenticated", false)
                                localStorage.removeItem("isAuthenticated")
                            }, 1000)
                        }
                    })
                    .catch((error) => {
                        alert(error);
                    })
            }
        }
    }, [ibIdForApi])

    const setDataForBookingToBilling = () => {
        itemBookingList.map((value) => {
            if (!bookingItemListForBilling.find((element) => value.iv_id === element.iv_id)) {
                setBookingItemListForBilling((prevState) => [
                    ...prevState, {
                        item_name: value.item_name,
                        amount: parseFloat(value.final_amount).toFixed(2),
                        discount_per: parseFloat(value.discount).toFixed(2),
                        gst_per: parseFloat(value.gst_tax_per),
                        item_id: value.item_id,
                        iv_id: value.iv_id,
                        quantity: parseFloat(value.quantity),
                        rate: parseFloat(value.selling_price),
                        taxable_amount: 0.0,
                        unit_id: parseInt(value.unit_id),
                        exp_date: value.expire_date
                    }
                ])
            }
        })
        sendOtpForBookingToBilling()
        setBookingItemBillIdForBilling(ibIdForApi)
        setBookingCustomerIdForBilling(getOrderDetail.customer_id)
        setBookingTotalAmtForBilling(getOrderDetail.total)
    }

    const sendOtpForBookingToBilling = () => {
        const getOtpVerifyUrl = {
            method : "POST",
            url: Helper.getWsUrl() + `/otp/send_otp_while_booking/${ibIdForApi}/?web=1`,
            headers: { "Content-Type": "application/json", Token: cookies.token },
          }
          axios(getOtpVerifyUrl)
            .then((response) => {
                if (response.data.code === 201) {
                    setBookingToBilling(true)                  
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else {
                    alert(response.data.msg)
                }
            })
            .catch((error) => {
                alert(error);
            })
    }

    useEffect(() => {
        if (ibIdForApi && ibIdForApi != null && getStatus && getStatus != null) {
            const getStatusChangeUrl = {
                method: "PATCH",
                url: Helper.getWsUrl() + `/booking/bookingStatusChange/${ibIdForApi}?web=1`,
                headers: { "Content-Type": "application/json", Token: cookies.token },
                data: {
                    status: parseInt(getStatus),
                    date: expectedDate && expectedDate,
                    amount : parseFloat(getOrderDetail.total).toFixed(2) || parseFloat(getIbIdForDetail.total_amount).toFixed(2)
                }
            }
            axios(getStatusChangeUrl)
                .then((response) => {
                    if (response.data.code === 200 || response.data.code === 201) {
                        window.location.reload();
                    } else if (response.data.code === 401) {
                        removeCookie("logged_in")
                        removeCookie("token")
                        setTimeout(() => {
                            navigate('/')
                            localStorage.setItem("isAuthenticated", false)
                            localStorage.removeItem("isAuthenticated")
                        }, 1000)
                    }
                })
                .catch((error) => {
                    alert(error);
                })
        }
    }, [ibIdForApi, getStatus])

    useEffect(()=>{
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/contact/get_assine_member_list_new/?key=team_member&web=1`,
            headers: { "Content-Type": "application/json", token: cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                if (response.data.code === 200) {              
                    // console.log(Helper.encypt_decrypt(response.data.data));
                    setTeamList(Helper.encypt_decrypt(response.data.data));     
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else {
                    setTeamList([])
                }
            })
            .catch((err) => {
                console.log(err);
            });

            // const requestOptions1 = {
            //     method: "GET",
            //     url: Helper.getWsUrl() + `/contact/get_assine_member_list_new/?key=delivery_boy&web=1`,
            //     headers: { "Content-Type": "application/json", token: cookies.token },
            // };
            // axios(requestOptions1)
            //     .then((response) => {
            //         if (response.data.code === 200) {              
            //             console.log(Helper.encypt_decrypt(response.data.data));
            //             setDeliveryBoyList(Helper.encypt_decrypt(response.data.data));     
            //         } else if (response.data.code === 401) {
            //             removeCookie("logged_in")
            //             removeCookie("token")
            //             setTimeout(() => {
            //                 navigate('/')
            //                 localStorage.setItem("isAuthenticated", false)
            //                 localStorage.removeItem("isAuthenticated")
            //             }, 1000)
            //         } else {
            //             setDeliveryBoyList([])
            //         }
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //     });
    },[])

    const assignTeamFunc = () => {
        const requestOptions = {
            method: "POST",
            url: Helper.getWsUrl() + `/booking/assign_booking_to_team/?web=1`,
            headers: { "Content-Type": "application/json", token: cookies.token },
            data: {
                booking_id: ibIdForApi,
                team_member_id: teamMember,
                role_id: teamRoleId,
                current_booking_status_id: getOrderDetail.online_status,
                next_booking_status_id: (getOrderDetail.online_status+1),
                name: teamName,
            }
        };
        axios(requestOptions)
            .then((response) => {
                if (response.data.code === 201) {
                    window.location.reload()
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: "#008c53",
        "&:hover": {
          backgroundColor: "#027e4b"
        }
    }));

    const myStyle = {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "22px",
        border: "none"
    }

    const handleClick = (newPlacement) => (event) => {
        getBookingChat()
        handleClose()
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    useEffect(() => {
        setOpen(false)
        setOpenTemp(false);
        setShowChatBtn(false)
        if(ibIdForApi && ibIdForApi != null) {
            getBookingChatCount()
            getCustomerBookingTemp()
        }
    },[ibIdForApi])

    const getBookingChatCount = () => {
        const request = {
            method: "GET",
            url: Helper.getWsUrl(cookies.skip) + `/booking/get_booking_chat_count_new/${ibIdForApi}/0/?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(request)
            .then((response) => {        
                if (response.data.code === 200) {
                    setShowChatBtn(true)
                    setBookingChatCount(Helper.encypt_decrypt(response.data.data).msg_count)
                    // console.log("chat data ", Helper.encypt_decrypt(response.data.data))
                } else if(response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                    navigate("/");
                    localStorage.setItem("isAuthenticated", false);
                    localStorage.removeItem("isAuthenticated");
                    }, 1000);
                } else {
                    setShowChatBtn(true)
                    setBookingChatCount(0)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getBookingChat = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl(cookies.skip) + `/booking/get_booking_chat_new/${ibIdForApi}/0/?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(patchItem)
            .then((response) => {        
                if (response.data.code === 200) {
                    setChattingEmptyState(false)
                    getBookingChatCount()
                    setGetBookingChatList(Helper.encypt_decrypt(response.data.data))
                    // console.log("chat data ", Helper.encypt_decrypt(response.data.data))
                } else if(response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                    navigate("/");
                    localStorage.setItem("isAuthenticated", false);
                    localStorage.removeItem("isAuthenticated");
                    }, 1000);
                } else {
                    setChattingEmptyState(true)
                    setGetBookingChatList([])
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    
    const getCustomerBookingTemp = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl(cookies.skip) + `/booking/get_booking_temp_new/?profile_type=0&web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(patchItem)
            .then((response) => {        
                if (response.data.code === 200) {
                    setBookingChatTempList(Helper.encypt_decrypt(response.data.data).temp)
                    // console.log("sdjfbfh ",Helper.encypt_decrypt(response.data.data).temp)
                } else if(response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                    navigate("/");
                    localStorage.setItem("isAuthenticated", false);
                    localStorage.removeItem("isAuthenticated");
                    }, 1000);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const postCustomerBookingChat = () => {
        if(selectedChatTempMsg && selectedChatTempMsg != null) {
            const request = {
                method: "POST",
                url: Helper.getWsUrl(cookies.skip) + `/booking/add_booking_chat_new/${ibIdForApi}/?web=1`,
                headers: { "Content-Type": "application/json", 'token': cookies.token },
                data: {
                    temp_id: selectedChatTempId && selectedChatTempId,
                    added_by: 0
                }
            }
            axios(request)
                .then((response) => {        
                    if (response.data.code === 201) {
                        setSelectedChatTempMsg(null)
                        getBookingChat()
                    } else if(response.data.code === 401) {
                        removeCookie("logged_in");
                        setTimeout(() => {
                        navigate("/");
                        localStorage.setItem("isAuthenticated", false);
                        localStorage.removeItem("isAuthenticated");
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    const handleClickOpen = () => {
        setOpenTemp(true);
    };
    const handleClose = () => {
        setOpenTemp(false);
    };

    const ScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }));
        return <div ref={elementRef} />;
    };

    return (
        <div id="box" style={{ width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <h4 style={{ fontWeight: "600", fontSize: "18px", lineHeight: "29px" }}>Order Detail</h4>
                <div style={{ margin: "0", width: "28%", display:"flex", alignItems:"center", justifyContent:"space-between" }}>
                    {getOrderDetail && getOrderDetail.status === 0 || getOrderDetail && getOrderDetail.online_status === 0 ?
                        <p style={{ backgroundColor: "#ee7a37", float: "right", color: "#fff", padding: "8px 25px", fontWeight: "500", fontSize: "14px", borderRadius: "10px", margin:"0"}}>Booked</p>
                        :
                        <>
                            {getOrderDetail && getOrderDetail.status === 1 || getOrderDetail && getOrderDetail.online_status === 1 ?
                                <p style={{ backgroundColor: "#008c53", float: "right", color: "#fff", padding: "8px 25px", fontWeight: "500", fontSize: "14px", borderRadius: "10px", margin:"0"}}>Confirmed</p>
                                :
                                <>
                                    {getOrderDetail && getOrderDetail.status === 4 || getOrderDetail && getOrderDetail.online_status === 4 ?
                                        <p style={{ backgroundColor: "#4CBD80", float: "right", color: "#fff", padding: "8px 25px", fontWeight: "500", fontSize: "14px", borderRadius: "10px", margin:"0" }}>Delivered</p>
                                        :
                                        <>
                                            {getOrderDetail && getOrderDetail.online_status === 2 ?
                                                <p style={{ backgroundColor: "#008c53", float: "right", color: "#fff", padding: "8px 25px", fontWeight: "500", fontSize: "14px", borderRadius: "10px" , margin:"0"}}>Packed Items</p>
                                                :
                                                <p style={{ backgroundColor: "#ba1c09", float: "right", color: "#fff", padding: "8px 25px", fontWeight: "500", fontSize: "14px", borderRadius: "10px" , margin:"0"}}>Cancelled</p>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                    <img onClick={() => setShowOrderdetail(false)} style={{ width: "30px", height: "30px", cursor: "pointer" }} src={require("../assets/images/cancel.png")} />
                </div>
            </div>

            <div id='selectAssignTeam' style={{width:'100%', marginTop:"10px", display: (teamList && teamList.length > 0) && (getOrderDetail && (getOrderDetail.online_status === 1 || getOrderDetail.online_status === 2)) && (getBookedByForDetail && getBookedByForDetail === 1 && getOrderDetail && getOrderDetail.team_member_id === null) && (getOrderDetail && getOrderDetail.pickup_drop != 1) ? "flex" : "none", justifyContent:'start', alignItems:"center"}}>
                <p style={{margin:"0", fontSize:"15px", fontWeight:"500", marginRight:'10px'}}>Assign to team member</p>
                <FormControl sx={{ m: 0, width: 200 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Team</InputLabel>
                    <Select sx={{textTransform:"capitalize"}} labelId="demo-simple-select-autowidth-label" id="demo-simple-select-autowidth" value={teamMember} onChange={(e) => {setTeamMember(e.target.value)}} autoWidth label="Team">
                       {teamList && teamList.map((value, index) => {
                            return (
                                <MenuItem sx={{textTransform:"capitalize"}} onClick={() => {setTeamRoleId(value.role_id); setTeamName(value.team_member_name)}} value={value.team_member_id}>{value.team_member_name} ({value.team_member_mobile})</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <ColorButton disabled={teamMember && teamMember != null ? "" : "disable"} onClick={assignTeamFunc} style={{marginLeft:"15px", borderRadius:"10px", padding:"3px 12px"}} variant="contained">Assign</ColorButton>
            </div>

            {/* <div id='selectAssignTeam' style={{width:'100%', marginTop:"10px", display: (deliveryBoyList && deliveryBoyList.length > 0) && (getOrderDetail && getOrderDetail.online_status === 2) && (getBookedByForDetail && getBookedByForDetail === 1 && getOrderDetail && getOrderDetail.team_member_id === null) ? "flex" : "none", justifyContent:'start', alignItems:"center"}}>
                <p style={{margin:"0", fontSize:"15px", fontWeight:"500", marginRight:'10px'}}>Assign to delivery boy</p>
                <FormControl sx={{ m: 0, width: 200 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Delivery Boy</InputLabel>
                    <Select sx={{textTransform:"capitalize"}} labelId="demo-simple-select-autowidth-label" id="demo-simple-select-autowidth" value={teamMember} onChange={(e) => {setTeamMember(e.target.value)}} autoWidth label="Team">
                       {deliveryBoyList && deliveryBoyList.map((value, index) => {
                            return (
                                <MenuItem sx={{textTransform:"capitalize"}} onClick={() => {setTeamRoleId(value.role_id); setTeamName(value.team_member_name)}} value={value.team_member_id}>{value.team_member_name} ({value.team_member_mobile})</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <ColorButton onClick={assignTeamFunc} style={{marginLeft:"15px", borderRadius:"10px", padding:"3px 12px"}} variant="contained">Assign</ColorButton>
            </div> */}

            <div style={{ width: "100%", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", borderRadius: "20px", padding: "8px 20px", marginTop:"15px", display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: "left", width: "50%" }}>
                    <p style={{ fontSize: "18px", fontWeight: "600", lineHeight: "28px", margin: "0", display:"flex", flexWrap:"wrap" }}>
                        Date & Time : &nbsp;
                        <span style={{ fontWeight: "500", fontSize: "14px" }}>{getOrderDetail && getOrderDetail.add_date_time || getOrderDetail && getOrderDetail.booking_date}</span>
                    </p>
                    <p style={{ fontSize: "18px", fontWeight: "600", lineHeight: "28px", margin: "0", display: getOrderDetail && getOrderDetail.expected_date != null ? "flex" : "none", flexWrap:"wrap" }}>
                        Delivery Date : &nbsp;
                        <span style={{ fontWeight: "500", fontSize: "14px" }}>{getOrderDetail && getOrderDetail.expected_date}</span>
                    </p>
                    <p style={{ fontSize: "18px", fontWeight: "600", lineHeight: "28px", margin: "0", display:getBookedByForDetail && getBookedByForDetail === 1 && getOrderDetail && getOrderDetail.team_member_id != null ? 'flex' : 'none', flexWrap:"wrap" }}>
                        Booking Assigned : &nbsp;
                        <span style={{ fontWeight: "500", fontSize: "14px", textTransform:"capitalize" }}>{getOrderDetail && getOrderDetail.team_member_name} ({getOrderDetail && getOrderDetail.team_member_number})</span>
                    </p>
                    <p style={{fontSize:"14px", fontWeight:"500", color:"#ee7a37", margin:"0", display:getBookedByForDetail && getBookedByForDetail === 1 ? "block" : "none"}}>( {getOrderDetail && getOrderDetail.pickup_drop != 1 ? "for delivery" : "for pickUp"} )</p>
                </div>
                <div style={{ textAlign: "left", width: "50%" }}>
                    <p style={{ fontSize: "18px", fontWeight: "600", lineHeight: "25px", margin: "0", display: getOrderDetail && getOrderDetail.customer_name != null ? "flex":"none", flexWrap:"wrap" }}>Name : &nbsp; <span style={{ fontWeight: "500", fontSize: "14px" }}>{getOrderDetail && getOrderDetail.customer_name}</span></p>
                    <p style={{ fontSize: "18px", fontWeight: "600", lineHeight: "25px", margin: "0", display:"flex", flexWrap:"wrap"}}>Mobile No. : &nbsp; <span style={{ fontWeight: "500", fontSize: "14px" }}>{getOrderDetail && getOrderDetail.customer_number}</span></p>
                    <p style={{ fontSize: "18px", fontWeight: "600", lineHeight: "25px", display:"flex", flexWrap:"wrap" }}>Address : &nbsp; <span style={{ fontWeight: "500", fontSize: "14px" }}>{getOrderDetail && getOrderDetail.Customer_address || getOrderDetail && getOrderDetail.customer_adress}</span></p>
                </div>
            </div>
            <div id='allTableItemList' style={{ borderRadius: "20px", width: "100%", overflowX: "hidden", webkitBoxShadow: "0 0 5px grey", boxShadow: "0 0 5px grey", border: "1px dashed #e5e5e5", marginTop: "20px", padding: "20px 36px", height:"32vh", overflowY:"scroll"}}>
                <table class="table table-striped" style={{ border: "none" }}>
                    <thead>
                        <tr>
                            <th scope="col" style={{ fontSize: "16px", fontWeight: "500", borderLeft: "none", borderRight: "none", borderTop: "none" }}>Sno.</th>
                            <th scope="col" style={{ fontSize: "16px", fontWeight: "500", borderLeft: "none", borderRight: "none", borderTop: "none" }}>Item Description</th>
                            <th scope="col" style={{ fontSize: "16px", fontWeight: "500", borderLeft: "none", borderRight: "none", borderTop: "none" }}>Qty</th>
                            <th scope="col" style={{ fontSize: "16px", fontWeight: "500", borderLeft: "none", borderRight: "none", borderTop: "none" }}>Price</th>
                            <th scope="col" style={{ fontSize: "16px", fontWeight: "500", borderLeft: "none", borderRight: "none", borderTop: "none" }}>Amount</th>
                        </tr>
                    </thead>
                    <tbody style={{ maxHeight: "10vh", overflowY: "scroll", border: "none" }}>
                        {itemBookingList && itemBookingList.map((value, index) => {
                            return (
                                <tr>
                                    <td style={myStyle}>{index + 1}</td>
                                    <td style={myStyle}>{value.item_name}</td>
                                    <td style={myStyle}>{value.quantity}</td>
                                    <td style={myStyle}>{value.selling_price != null ? parseFloat(value.selling_price).toFixed(2) : "0.00"}</td>
                                    <td style={myStyle}>{value.final_amount != null ? parseFloat(value.final_amount).toFixed(2) : "0.00"}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div style={{ display: "flex", justifyContent: "space-between", margin: "30px 0 0", width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px", width: "80%" }}>
                        <p style={{ fontSize: "14px", fontWeight: "600" }}>Grand Total(in words) : <span style={{ fontSize: "14px", fontWeight: "400" }}>{getOrderDetail && getOrderDetail.words}</span></p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "50%" }}>
                        <div style={{ textAlign: "left" }}>
                            <p style={{ fontSize: "14px", fontWeight: "600" }}>Total Amount : &nbsp;
                                {getBookedByForDetail && getBookedByForDetail != 0 ?
                                    <span style={{ fontSize: "14px", fontWeight: "500" }}>₹ {getOrderDetail && parseFloat(getOrderDetail.total).toFixed(2)}</span>
                                    :
                                    <span style={{ fontSize: "14px", fontWeight: "500" }}>₹ {getOrderDetail && parseFloat(getOrderDetail.total_amount).toFixed(2)}</span>
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content" style={{ textAlign: "center", borderRadius: "15px" }}>
                        <div class="modal-body">
                            <p style={{marginLeft:"0px", fontWeight: "500"}} >Do you want to confirm booking?</p>
                            <p style={{ fontWeight: "500", fontsize: "18px", margin:"0" }}>Please select the delievery date</p>
                            <li style={{ listStyle: "none" }}>
                                <TextField inputProps={{ min: maxDate }} onChange={e => setExpectedDate(e.target.value)} className="cal3" id="date" type="date" InputLabelProps={{ shrink: true, }} />
                            </li>
                        </div>
                        <div class="modal-footer" style={{ padding: "0", border: "none", margin: "0" }}>
                            <button onClick={() => {setGetStatus(1);}} disabled={expectedDate && expectedDate != null ? "" : "disable"}
                                style={{ border: "none", borderRadius: "10px", padding: "5px 15px", backgroundColor: "#008c53", color: "#fff", fontSize: "14px", fontWeight: "500", marginRight: "10%" }}>Done</button>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ textAlign: "center", marginTop: "20px", display: "flex" }} >
                {getOrderDetail && getOrderDetail.status === 0 || getOrderDetail && getOrderDetail.online_status === 0 ?
                    <button data-bs-toggle="modal" data-bs-target="#exampleModal1" style={{ border: "none", borderRadius: "10px", padding: "5px 15px", backgroundColor: "#008c53", color: "#fff", fontSize: "14px", fontWeight: "500" }}>Confirm</button>
                    :
                    <>
                        {getOrderDetail && getOrderDetail.status === 1 ?
                            <button data-bs-toggle="modal" data-bs-target="#deliverModal"
                                style={{ border: "none", borderRadius: "10px", padding: "5px 25px", backgroundColor: "#4CBD80", color: "#fff", fontSize: "14px", fontWeight: "500" }}>Deliver</button>
                            : null
                        }
                        {getOrderDetail && getOrderDetail.online_status === 1 ?
                            <button data-bs-toggle="modal" data-bs-target="#packModal"
                                style={{ border: "none", borderRadius: "10px", padding: "5px 25px", backgroundColor: "#4CBD80", color: "#fff", fontSize: "14px", fontWeight: "500" }}>Pack Items</button>
                            : null
                        }
                        {getOrderDetail && getOrderDetail.online_status === 2 ?
                            <button data-bs-toggle="modal" data-bs-target="#paymentModal"
                            // onClick={() => setDataForBookingToBilling()} 
                            style={{ border: "none", borderRadius: "10px", padding: "5px 25px", backgroundColor: "#4CBD80", color: "#fff", fontSize: "14px", fontWeight: "500" }}>Make Payment</button>
                            : null
                        }
                    </>
                }
                {(getOrderDetail && getOrderDetail.status === 0 || getOrderDetail && getOrderDetail.online_status === 0 || getOrderDetail && getOrderDetail.status === 1 || getOrderDetail && getOrderDetail.online_status === 1 || getOrderDetail && getOrderDetail.online_status === 2) ?
                    <button  data-bs-toggle="modal" data-bs-target="#cancelModal" 
                    // onClick={() => { setGetStatus(3); }}
                     style={{ border: "none", borderRadius: "10px", padding: "5px 25px", backgroundColor: "#ba1c09", color: "#fff", fontSize: "14px", fontWeight: "500", marginLeft: "20px" }}>Cancel</button>
                    : null}
            </div>

            {getOrderDetail && (getOrderDetail.status === 3 || getOrderDetail.online_status === 3 || getOrderDetail.status === 4 || getOrderDetail.online_status === 4) ?
                null 
            : 
                <div onClick={handleClick('top-end')} className='orderChattingIcon' style={{display:!showChatBtn ? "none" : "block", width:"50px", aspectRatio:"1/1", borderRadius:"50%", position:"fixed", bottom:"9vh", right:"8vh", backgroundColor:"#fff", padding:"10px", cursor:"pointer", boxShadow: "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px"}}>
                    <img src={require("../assets/images/Merchant-Chat-Icon.png")} 
                        style={{width:"100%", height:"100%"}} />
                    <div style={{background:"rgb(186, 28, 9)", borderRadius:"50%", width:"15px", height:"15px", position:"absolute", top:"0", right:"-1px", display:bookingChatCount && bookingChatCount > 0 ?  "flex" : "none", justifyContent:"center", alignItems:"center"}}>
                        <p style={{color:"#fff", fontSize:"12px", fontWeight:"500", margin:"0"}}>{bookingChatCount}</p>
                    </div>
                </div>
            }

            <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper sx={{width:"400px", background:"rgb(244 244 244)", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", position:"relative"}}>
                        <Typography sx={{ p: 1 }}>
                            {chattingEmptyState ? 
                                <div style={{width:"100%", height:"100%"}}>
                                    <img style={{width:"100%"}} src={require("../assets/images/No-Messages-Yet_Merchant.png")} />
                                </div>
                            :
                                <div id='allTableItemList' style={{width:"100%", height:"70vh", padding:"0", marginBottom:"50px", overflowY:"scroll" }}>
                                    {getBookingChatList && getBookingChatList.map((value) => {
                                        return (
                                            <div style={{alignItems: value.added_by != 3 ? "end" : "start", width:"100%", display:"flex", flexDirection:"column", marginBottom:"10px"}} >
                                                <div style={{background: value.added_by != 0 ? "#ee7a37" : "#008c53", maxWidth:"75%", padding:"10px 10px 0", borderRadius:"10px"}}>
                                                    <p style={{width:"100%", color:"#fff", margin:"0 0 2px", textAlign:"left", overflowWrap:"break-word", fontSize:"13px", fontWeight:"400", lineHeight:"14px"}}>{value.msg}</p>
                                                    <small style={{padding: value.added_by != 3 ? "0" : "2px 0 2.5px", color:"#fff", float:"right", fontSize:"10px", fontWeight:"400", lineHeight:"10px"}}>{value.add_date_time.slice(-8)} 
                                                        <span>
                                                            {value.added_by != 3 && value.seen != 0 ? 
                                                                <DoneAllIcon sx={{width:"12px", margin:"0 0 2px 5px",height:"auto"}}></DoneAllIcon>
                                                            : null
                                                            }
                                                        </span>
                                                    </small>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <ScrollToBottom />
                                </div>
                            }

                            <div style={{width:"100%", position:"fixed", bottom:"0", display:"flex", justifyContent:"space-between", alignItems:"center", marginLeft:"-8px", padding:"10px"}}>
                                <div onClick={handleClickOpen} style={{width:"84%", height:"5vh", display:"flex", textAlign:"left", padding:"5px 10px", alignItems:"center", borderRadius:"10px", border:"1px solid #008c53"}}>
                                    {selectedChatTempMsg && selectedChatTempMsg != null ? 
                                        <p  id='allTableItemList' style={{margin:"0", width:"100%", height:"100%", overflowY:"scroll", overflowWrap:"break-word", fontSize:"12px", fontWeight:"400", lineHeight:"13px"}}>{selectedChatTempMsg}</p>
                                    :
                                        <p id='allTableItemList' style={{margin:"0", width:"100%", height:"100%", overflowY:"scroll", overflowWrap:"break-word", fontSize:"12px", fontWeight:"400", lineHeight:"13px"}}>Type a message...</p>
                                    }
                                </div>
                                <button style={{width:"40px", aspectRatio:"1/1", border:"none", background:"none", padding:"0", margin:"0"}}>
                                    <img style={{width:"100%", height:"100%"}} onClick={() => {postCustomerBookingChat()}} src={require("../assets/images/Send-Icon_Merchant.png")} />
                                </button>
                            </div>

                            <Card style={{display:"flex", flexDirection:"column", height: openTemp ? "40vh" : "0", transition:"0.5s all", position:"absolute", bottom:"0", left:"0", width:"100%", padding:"0 10px", borderRadius:"12px 12px 5px 5px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                                <IconButton
                                    style={{padding:"0"}}
                                    edge="start"
                                    onClick={handleClose}
                                    aria-label="close">
                                    <ExpandMoreIcon />
                                </IconButton>
                                <div id='allTableItemList' style={{width:"100%", height:"100%", overflowY:"scroll", overflowX:"hidden", padding:"0", margin:"0"}}>
                                    {bookingChatTempList && bookingChatTempList.map((value, index) => {
                                        return (
                                            <>
                                                <p style={{width:"100%", overflowWrap:"break-word", fontSize:"13px", lineHeight:"13px", fontWeight:"400", margin:"0", cursor:"pointer"}} onClick={() => {setSelectedChatTempId(value.temp_id); setSelectedChatTempMsg(value.temp); handleClose();}}>{value.temp}</p>
                                                <hr style={{margin:"8px 0", padding:"0", border:"0px solid #000"}} />
                                            </>
                                        )
                                    })}
                                </div>
                            </Card>

                            {/* <Dialog PaperProps={{
                                    style: {
                                            position: 'absolute',
                                            bottom: '0',
                                            color: 'black',
                                            width: '100%',
                                            height: '50%',
                                            maxWidth: '100%',
                                            padding: "0",
                                            borderRadius: "15px 15px 0 0",
                                            margin: '0',
                                            overflow:"hidden"
                                    },
                                    }} open={openTemp} onClose={handleClose} TransitionComponent={Transition} >
                                <IconButton
                                    edge="start"
                                    onClick={handleClose}
                                    aria-label="close">
                                    <ExpandMoreIcon />
                                </IconButton>
                                <Toolbar>
                                    <div id='allTableItemList' style={{width:"100%", height:"100%", overflowY:"scroll", overflowX:"hidden", padding:"0", margin:"0"}}>
                                        {bookingChatTempList && bookingChatTempList.map((value, index) => {
                                            return (
                                                <>
                                                    <p style={{width:"100%", overflowWrap:"break-word", fontSize:"13px", lineHeight:"13px", fontWeight:"400", margin:"0"}} onClick={() => {setSelectedChatTempId(value.temp_id); setSelectedChatTempMsg(value.temp); handleClose();}}>{value.temp}</p>
                                                    <hr style={{margin:"8px 0", padding:"0"}} />
                                                </>
                                            )
                                        })}
                                    </div>
                                </Toolbar>
                            </Dialog> */}
                        </Typography>
                    </Paper>
                </Fade>
                )}
            </Popper>

            <div class="modal fade" id="cancelModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div style={{marginTop:"5px"}} >
                            <p style={{marginLeft:"18px"}} >Do you want to cancel booking?</p>
                            <div style={{display:"flex",justifyContent:"end"}} >
                                <p data-bs-dismiss="modal" style={{color:"#008C53",cursor:"pointer"}}>No</p>
                                <p style={{marginLeft:"20px",color:"#008C53",cursor:"pointer"}} onClick={()=>{setGetStatus(3)}} >Yes</p>
                            </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="modal fade" id="confirmModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div style={{marginTop:"5px"}} >
                            <p style={{marginLeft:"18px"}} >Do you want to confirm booking?</p>
                            <div style={{display:"flex",justifyContent:"end"}} >
                                <p data-bs-dismiss="modal" style={{color:"#008C53",cursor:"pointer"}}>No</p>
                                <p style={{marginLeft:"20px",color:"#008C53",cursor:"pointer"}}  onClick={() => {setGetStatus(1); }} >Yes</p>
                            </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div> */}
            <div class="modal fade" id="deliverModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div style={{marginTop:"5px"}} >
                            <p style={{marginLeft:"18px"}} >Have you delivered the order?</p>
                            <div style={{display:"flex",justifyContent:"end"}} >
                                <p data-bs-dismiss="modal" style={{color:"#008C53",cursor:"pointer"}}>No</p>
                                <p style={{marginLeft:"20px",color:"#008C53",cursor:"pointer"}} onClick={() => { setGetStatus(4); }}  >Yes</p>
                            </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="packModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div style={{marginTop:"5px"}} >
                            <p style={{marginLeft:"18px"}} >Do you want to pack your order?</p>
                            <div style={{display:"flex",justifyContent:"end"}} >
                                <p data-bs-dismiss="modal" style={{color:"#008C53",cursor:"pointer"}}>No</p>
                                <p style={{marginLeft:"20px",color:"#008C53",cursor:"pointer"}} onClick={() => { setGetStatus(2);}}  >Yes</p>
                            </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="paymentModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div style={{marginTop:"5px"}} >
                            <p style={{marginLeft:"18px"}} >Do you want to make payment?</p>
                            <div style={{display:"flex",justifyContent:"end"}} >
                                <p data-bs-dismiss="modal" style={{color:"#008C53",cursor:"pointer"}}>No</p>
                                <p data-bs-dismiss="modal" style={{marginLeft:"20px",color:"#008C53",cursor:"pointer"}} onClick={() => setDataForBookingToBilling()}   >Yes</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetail