import React from "react";
import "./OrderAddNew.css";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import  { useState, useEffect,useCallback } from "react";
import { useNavigate } from "react-router-dom";


const OrderAddNew = ({setShowAddOrderPage, grahakDetails, setShowOrderAddGrahak}) => {

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate()

  const [showOrderItemList, setShowOrderItemList] = useState(false)
  const [showOrderAddItemBtn, setShowOrderAddItemBtn] = useState(false)
  const [showOrderUnitList, setShowOrderUnitList] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)
  const [filteredItemDataOrder, setFilteredItemDataOrder] = useState(null)
  const [getOrderArrayData, setGetOrderArrayData] = useState(null)
  const [getOrderObjectData, setGetOrderObjectData] = useState(null)
  const [getOrderUnitData, setGetOrderUnitData] = useState(null)
  const [orderAmount, setOrderAmount] = useState(null)
  const [orderTaxebleAmt, setOrderTaxebleAmt] = useState(null)
  const [orderMiuIdName, setOrderMiuIdName] = useState(null)
  const [orderTableArray, setOrderTableArray] = useState([])
  const [orderTotalAmount, setOrderTotalAmount] = useState(null)
  const [orderGrahakId, setOrderGrahakId] = useState(null)
  const [orderOverAllDisc, setOrderOverAllDisc] = useState(null)
  const [ttlAmtWithOvrallDisc, setTtlAmtWithOvrallDisc] = useState(null)
  const [orderUnitValue, setOrderUnitValue] = useState(null)
  const [getSelectedMiuId, setGetSelectedMiuId] = useState(null)
  const [bookingItemList, setBookingItemList] = useState([])

  const [getOrderSearchItem, setGetOrderSearchItem] = useState({
    orderItemName: null
  })
  const [orderItemFieldValue, setOrderItemFieldValue] = useState({
    item_id: null,
    iv_id: null,
    itemName: null,
  })
  const [orderEmptyObj, setOrderEmptyObj] = useState({
    itemName: null,
    size: null,
    unit: null,
    mrp: null,
    rate: null,
    gst: null,
    discount: null,
    qty: null,
    amt: null,
    itemID:null,
    ivID:null,
    customItem:null
  })

  var ttlAmt = 0

  const getItemForSearch = (event) => {
    setGetOrderSearchItem({
      ...getOrderSearchItem,
      orderItemName: event.target.value
    })
  }

  useEffect(() => {
    if(getOrderSearchItem.orderItemName != null || getOrderSearchItem.orderItemName == "") {
      orderSearchItemFunc(getOrderSearchItem.orderItemName)
      setShowOrderAddItemBtn(true)
    }
  },[getOrderSearchItem.orderItemName])

  const orderSearchItemFunc = (itemName) => {
    const requestOptionOrder = {
      method: "GET",
      url: Helper.getWsUrl() + `/ItemSearch/?name=${itemName != null && itemName != "" ? itemName : null}&web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOptionOrder)
      .then((response) => {
        // setCookie("token", response.headers.token);        
        if (response.data.code === 200) {
            setFilteredItemDataOrder(Helper.encypt_decrypt(response.data.data));
            setShowOrderItemList(true)
        } 
        else if(response.data.code === 204){
            setShowOrderItemList(false)
            setShowOrderAddItemBtn(true)
        } 
        else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const orderAutoFillItem = (name) => {
    document.getElementById("empty").reset()
    setShowOrderUnitList(false)
    setShowOrderItemList(false)
    let itemText = name   

    let inputField = filteredItemDataOrder.filter ((val) => {
      return val.iv_id.includes(itemText)
    })

    setOrderItemFieldValue((prevState) => ({
      ...prevState,
      item_id: inputField[0].item_id,
      iv_id: inputField[0].iv_id,
      itemName: inputField[0].itemName,
    }))
  } 

  useEffect(() => {
    if(orderItemFieldValue && orderItemFieldValue.item_id != null && orderItemFieldValue.iv_id != null) {
      getOrderItemDetails(orderItemFieldValue.item_id, orderItemFieldValue.iv_id)
    }
  },[orderItemFieldValue])

  const getOrderItemDetails = (itemId,ivId) => {
    const requestOption = {
      method: "GET",
      url: Helper.getWsUrl() + `/items/get_items/${itemId}/${ivId}/?web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOption)
      .then((response) => {
        // setCookie("token", response.headers.token);        
        if (response.data.code === 200) {
            setGetOrderObjectData(Helper.encypt_decrypt(response.data.data).item_data)
            setGetOrderArrayData(Helper.encypt_decrypt(response.data.data).item_verient[0])   
            // console.log("get item s : ", Helper.encypt_decrypt(response.data.data))
        }
        else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  } 

  useEffect(() => {
    if(getOrderObjectData && getOrderObjectData.miu_id) {
      getOrderUnitValue()
    }
  }, [getOrderObjectData])

  const getOrderUnitValue = () => {
    const requestOption = {
      method: "GET",
      url: Helper.getWsUrl() + `/masters/get_itemunit_active/1/?web=1`,
      headers: { "Content-Type": "application/json", Token: cookies.token },
    };
    axios(requestOption)
      .then((response) => {
        // setCookie("token", response.headers.token);        
        if (response.data.code === 200) {
          setGetOrderUnitData(Helper.encypt_decrypt(response.data.data))
        }
        else if (response.data.code === 401) {
          removeCookie("logged_in")
          removeCookie("token")
          setTimeout(() => {
              navigate('/')
              localStorage.setItem("isAuthenticated", false)
              localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  useEffect(() => {
    let filterMiuId = null
    if(getOrderUnitData && getOrderObjectData && getOrderObjectData.miu_id){
      filterMiuId = getOrderUnitData.filter((item) => {
        if(item.miu_id == getOrderObjectData.miu_id) {
          // console.log("unit item : ",item)
          return item          
        }
      })      
      setOrderMiuIdName(filterMiuId && filterMiuId[0].name)
    }
  }, [getOrderUnitData, getOrderObjectData])

  useEffect(() => { 
    let Qty = getOrderArrayData && getOrderArrayData.min_qty
    if(getOrderArrayData && getOrderArrayData) {  
      if(Qty === 0) {
        Qty = 1
      }
      let discValue = getOrderArrayData.discount_per/100
      let discAmt = discValue * getOrderArrayData.mrp
      let amtAfterDisc = getOrderArrayData.mrp - discAmt
      let amountget = Qty * amtAfterDisc
      let gstValue = getOrderObjectData.gst_tax_per/100
      let amtWithGst = getOrderArrayData.mrp + gstValue

      setOrderAmount(amountget)
      setOrderTaxebleAmt(amtWithGst)
    }
  },[getOrderArrayData, getOrderObjectData])

  useEffect(() => {
    if((getOrderSearchItem.orderItemName || orderItemFieldValue.itemName) && orderMiuIdName && getOrderObjectData && getOrderObjectData!= null && getOrderArrayData && getOrderArrayData != null)
      {
        setOrderEmptyObj((orderEmptyObj) => ({
          ...orderEmptyObj,
          itemName: getOrderObjectData.item_name,
          size: getOrderArrayData.size,
          unit: orderMiuIdName,
          mrp: getOrderArrayData.mrp,
          rate: getOrderArrayData.selling_price,
          gst: getOrderObjectData.gst_tax_per,
          discount: getOrderArrayData.discount_per,
          qty: `${getOrderArrayData && getOrderArrayData.min_qty != null && getOrderArrayData.min_qty != 0 ? getOrderArrayData.min_qty : "1"}`,
          amt: orderAmount,
          itemID:getOrderArrayData.item_id,
          ivID:getOrderArrayData.iv_id,
          customItem: 0
        }))
      }
  },[getOrderSearchItem.orderItemName,orderItemFieldValue.itemName,getOrderArrayData && getOrderArrayData.size,orderMiuIdName && orderMiuIdName])

  useEffect(() => {
    if(orderEmptyObj.itemName) {
      if(orderTableArray.find((element) => orderEmptyObj.ivID === element.ivID)) {
        let filterTableArray = [...orderTableArray]
        let indexOfArray
        let filtrTblArrElement = filterTableArray.filter((item, index) => {
                if(item.ivID == orderEmptyObj.ivID){
                    indexOfArray = index
                    return item
                }
            })
            filtrTblArrElement[0].qty++
            filterTableArray[indexOfArray] = filtrTblArrElement[0]
            setOrderTableArray(filterTableArray)
      } else {
        setOrderTableArray(orderTableArray => [
          ...orderTableArray,orderEmptyObj
        ])
      }
    }
  },[orderEmptyObj])

  useEffect(() => {
    setOrderEmptyObj((orderEmptyObj) => ({
      ...orderEmptyObj,
          itemName: null,
          size: null,
          unit: null,
          mrp: null,
          rate: null,
          gst: null,
          discount: null,
          qty: null,
          amt: null,
          itemID:null,
          ivID:null,
          customItem:null
    }))
    setGetOrderArrayData(null)
    setGetOrderObjectData(null)
    setOrderMiuIdName(null)                          
  },[orderTableArray])

  useEffect(() => {
    if(orderTableArray && orderTableArray.length > 0) {
      {orderTableArray && orderTableArray.map ((value, index) => {
        ttlAmt += value.amt
      })}
      setOrderTotalAmount(parseFloat(ttlAmt).toFixed(2))
    }
  },[orderTableArray])

  const getOrderOverAllDiscFunc = (e) => {  
    setOrderOverAllDisc({
      ...orderOverAllDisc,
      disc: e.target.value
    })
  }

  useEffect(() => {
    if(orderTotalAmount && orderTotalAmount!=null){
        setTtlAmtWithOvrallDisc(parseFloat(orderTotalAmount).toFixed(2))
    }
  },[orderTotalAmount])

  useEffect(() => {
    if(orderOverAllDisc && orderOverAllDisc != null) {
      if(orderOverAllDisc.disc > orderTotalAmount) {
        setTtlAmtWithOvrallDisc(orderTotalAmount)
      } else {
        let amtAftrOvrallDisc = parseFloat(orderTotalAmount - orderOverAllDisc.disc)
        setTtlAmtWithOvrallDisc(parseFloat(amtAftrOvrallDisc).toFixed(2))   
      }   
    }
  },[orderOverAllDisc])

  useEffect(() => {
    if(ttlAmtWithOvrallDisc && ttlAmtWithOvrallDisc != null) {
        setTtlAmtWithOvrallDisc(ttlAmtWithOvrallDisc)
    }
  },[ttlAmtWithOvrallDisc])

  const getMiuIdFunc =(e) => {
      setGetSelectedMiuId(e.target.value)
  }

  useEffect(() => {
    let filterTableArray = [...orderTableArray]
    let indexOfArray
    let filtrTblArrElement = null
    if(getSelectedMiuId && getSelectedMiuId != null){
        filtrTblArrElement = filterTableArray.filter((item, index) => {
            if(item.itemName == getOrderSearchItem.orderItemName){
                indexOfArray = index
                return item
            }
        })
        filtrTblArrElement[0].unit = parseInt(getSelectedMiuId)
        filterTableArray[indexOfArray] = filtrTblArrElement[0]
    }
    setOrderTableArray(filterTableArray)
  },[getSelectedMiuId])

  const addItemNameInTblFunc = () => {   
    getOrderUnitValue()  
    setOrderEmptyObj((orderEmptyObj) => ({
    ...orderEmptyObj,
        itemName: getOrderSearchItem.orderItemName,
        customItem: 1
    })) 
    setShowOrderUnitList(true)
    setShowOrderItemList(false)
  }

  const bookingItemListFunc = () => {      
      if(orderTableArray && orderTableArray.length > 0) {          
        {orderTableArray && orderTableArray.map ((value, index) => {
          setBookingItemList((bookingItemList) => [
            ...bookingItemList, {
              custom_item: value.customItem,
              item_id: value.itemID,
              item_name: value.itemName,
              iv_id: value.ivID,
              quantity: value.qty != null ? value.qty : 1.0,
              unit_id: parseInt(value.unit)
            }
          ])
        })}   
        setDisableBtn(true)
      }else {
        alert("please add item")
      }
    }

    useEffect(() => {
      if(bookingItemList && bookingItemList.length > 0) {
        const requestOptions4 = {
          method: "POST",
          url: Helper.getWsUrl() + "/booking/add_booking/?web=1",
          headers: { "Content-Type": "application/json", Token: cookies.token },
          data: {
              Item_booking:{
                      booked_by:0,
                      contact_id: grahakDetails.contact_id,
                      expected_date: "2022-08-01",
                      status:1
                  },
                  Item_booking_list: bookingItemList
          }      
        };
        axios(requestOptions4)
          .then((response) => {
              // setCookie("token", response.headers.token);
              if (response.data.code === 201) {
                  window.location.reload()
                  setShowAddOrderPage(false)
                  setShowOrderAddGrahak(false)
              } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                    navigate('/')
                    localStorage.setItem("isAuthenticated", false)
                    localStorage.removeItem("isAuthenticated")
                }, 1000)
              }
          })
          .catch((error) => {
              alert(error);
          });
      }
    },[bookingItemList])     

    const edityQuantityInArrayFunc = (e, index) => {
      const re = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/
      if(e.target.value === '' || re.test(e.target.value)) {
        let filterTableArray = [...orderTableArray]
        let indexOfEditQty = index
        let editQtyVal = e.target.value
        let indexOfArray
        let filtrTblArrElement = null
        filtrTblArrElement = filterTableArray.filter((item, index) => {
          if(index == indexOfEditQty){
            indexOfArray = index
            return item
          }
        })
        filtrTblArrElement[0].qty = editQtyVal
        filtrTblArrElement[0].amt = filtrTblArrElement[0].rate * editQtyVal
        filterTableArray[indexOfArray] = filtrTblArrElement[0]
        setOrderTableArray(filterTableArray)
      }
    }

  const styles = {
    width: "100%",
    textAlign: "left",
    color: "#868D96",
    fontWeight: 400,
    fontSize: "14px"
  };
  

  
  return (
    <div className='col-10' style={{backgroundColor:"rgb(245, 245, 245)",padding:"20px", position:"relative"}}>
      <div onClick={() => {window.location.reload()}} style={{cursor:"pointer", position:"absolute", top:"0", left:"0", background:"#008c53", color:"#fff", fontWeight:"600", fontSize:"16px", zIndex:"1", padding:"10px 5px", borderRadius:"0 25px 25px 0", writingMode:"vertical-rl"}}>
          view Order
      </div>
      <div style={{background:"#fff", width:"100%", height:"100%", borderRadius:"20px", padding:"20px", position:"relative", overflow:"hidden"}}>
        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
            <h3 style={{fontSize:"18px", fontWeight:"600", lineHeight:"29px"}}>Add New Order</h3>
        </div>
        <div style={{display:"flex", justifyContent:"space-around", marginTop:"40px"}}>
          <div class="user-input-wrp">
            <br />
            <input autoComplete="off" style={{border:"1px solid #008c53"}} disabled type="text" class="inputText" required value={grahakDetails.name || grahakDetails.display_name}/>
            <span style={{top: "12px", left:"17px", opacity:"1", background:"#fff", color:"#008c53"}} class="floating-label">Grahak Name</span>
          </div>
          <div class="user-input-wrp" style={{position:"relative"}}>
            <br />
            <input autoComplete="off" style={{border:"1px solid #008c53"}} disabled type="number" class="inputText" name="mobile_number" value={grahakDetails.mobile_number}/>
              <span style={{top: "12px", left:"17px", opacity:"1", background:"#fff", color:"#008c53"}} class="floating-label">Contact No.</span>
          </div>         
          <div class="user-input-wrp">
            <br />
            <input autoComplete="off" style={{border:"1px solid #008c53"}} disabled value={grahakDetails.gst_number} type="text" class="inputText" required/>
            <span style={{top: "12px", left:"17px", opacity:"1", background:"#fff", color:"#008c53"}} class="floating-label">GST No.</span>
          </div>
          <div class="user-input-wrp" style={{ width: "35%" }}>
            <br />
            <input autoComplete="off" style={{border:"1px solid #008c53", width: "100%"}} disabled value={grahakDetails.address} type="text" class="inputText" required/>
            <span style={{top: "12px", left:"17px", opacity:"1", background:"#fff", color:"#008c53"}} class="floating-label">Address</span>
          </div>
        </div>

        <table class="table" style={{marginTop:"50px", maxHeight:"40vh"}}>
          <thead>
            <tr>
            <th style={{width:"5%"}}>S.No</th>
            <th style={{ width: "20%" }}>Item Name</th>
            <th>Size</th>
            <th>Unit</th>
            <th>MRP<br />(in ₹)</th>
            <th>Selling<br />Price/unit</th>
            <th>GST*<br />(in %)</th>
            <th>Disc<br />(in %)</th>
            <th>Qty<br />(in Units)</th>
            <th>Amount<br />(in ₹)</th>
            </tr>         
            <tr>
                <th style={{border:"none"}}></th>
                <th style={{ position: "relative"}}>
                  <div style={{border: "none", width: "100%",borderRadius: "10px",padding: "0", display:"flex", justifyContent:"space-between"}}>
                    <form id="empty">
                      <input autoComplete="off" placeholder="Add/Search Item" type="text" name="itemName" onChange={(event) => {getItemForSearch(event)}}  style={{borderRadius: "10px", padding:"3.5px 7px", border:"1px solid #7a7a7a", background:"none"}}/> 
                    </form>
                      {showOrderAddItemBtn ? 
                        <button type="button" onClick={() => {addItemNameInTblFunc();}} style={{border: "none", background: "none", padding: "0", width:"max-content", marginLeft:"3px"}}>
                          <span style={{border: "none", borderRadius: "10px", padding: "6px 9px", fontSize: "14px", fontWeight: "500", color: "#fff", background:"#008C53"}}>
                            Add Item
                          </span>
                        </button>
                      :
                        null}                    
                  </div>
                  {showOrderItemList ? (
                    <div style={{paddingTop: "10px", left:"0", minHeight:"0", maxHeight:"20vh", overflowY:"scroll", backgroundColor: "#fff",position: "absolute", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", width: "100%",borderRadius:"0 0 10px 10px"}}> 
                      {filteredItemDataOrder && filteredItemDataOrder.map((value, index) => {
                        return (
                          <li onClick={(e) => {orderAutoFillItem(value.iv_id)}} style={{listStyle: "none", border:"none", cursor:"pointer", padding:"0 15px"}}>
                            <p style={styles} key={value.item_id}>
                              {value.itemName} &nbsp;({value.size}, {value.color}, {value.model})
                            </p>
                          </li>
                        )
                      })}
                    </div> ) 
                    : 
                    null
                  }
                </th>
                <th colSpan={8}></th>
            </tr>
          </thead>
          <tbody>
              {orderTableArray && orderTableArray.map((item, index) => {
                  return(
                    <tr>
                      <th>{index+1}</th>
                      <td>
                        <input disabled key={index} value={item.itemName} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.size} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        { showOrderUnitList ?            
                            <select key={index} onChange={(e) => getMiuIdFunc(e)} class="inputText inputData" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}}>
                                {getOrderUnitData && getOrderUnitData.map((value, index) => {
                                    return(
                                        <option key={index} value={value.miu_id} className="optionData">{value.name}</option>
                                    )
                                })}
                            </select>
                          :
                          <input disabled key={index} value={item.unit} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                        }
                      </td>
                      <td>
                        <input disabled key={index} value={item.mrp} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.rate} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.gst} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={item.discount} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input onChange={(e) => {edityQuantityInArrayFunc(e, index)}} key={index} value={item.qty} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                      <td>
                        <input disabled key={index} value={parseFloat(item.amt).toFixed(2)} type="text" style={{border:"1px solid #8c8c8c",width:"100%", borderRadius:"5px",padding: "3px 7px",}} class="inputText inputData" />
                      </td>
                    </tr>
                  )
                })
              }
              <tr>
                <td colSpan={2} style={{borderRight:"none"}}>
                </td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}>Total</td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none" }}></td>
                <td style={{ border: "none", width: "7%", position: "relative" }}>
                  <input style={{border: "1px solid #bababa",borderRadius: "5px",padding: "6px 9px",fontSize: "14px",fontWeight: "500", color: "#bababa", width: "100%",}}/>
                  <span style={{ position: "absolute", top: "30%", left: "15%" }}>
                    {orderTotalAmount}
                  </span>
                </td>
            </tr>
          </tbody>
        </table>

          <div style={{  display: "flex", justifyContent: "end", marginTop: "70px" }}>
              <div style={{ textAlign: "right", marginRight: "20px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "500" }}>
                  Overall Disc (optional) &nbsp;&nbsp; -{" "}
                  </p>
                 {/* <p style={{ fontSize: "16px", fontWeight: "500" }}>
                  round Off &nbsp; &nbsp; -{" "}
                </p>   */}
                  <br></br>
                  <p style={{ fontSize: "16px", fontWeight: "500" }}>
                  Final Amount &nbsp; &nbsp; -{" "}
                  </p>
              </div>

              {/* <div style={{ textAlign: "left" }}>
                  <input onChange={(e) => getOrderOverAllDiscFunc(e)} type="text" style={{ border: "none", borderBottom: "1px solid #bababa" }} placeholder="Disc" required/>
                  <br /><br />
                  <input value={ttlAmtWithOvrallDisc} type="text" style={{ border: "none", borderBottom: "1px solid #bababa" }} placeholder="Final Amt" required/>
              </div> */}

              <div style={{ textAlign: "left" }}>
                <div style={{display:"flex", position:"relative"}}>
                  <p style={{position:"absolute", left:"-12px", top:"1px"}}>₹</p>
                  <input onChange={(e) => {getOrderOverAllDiscFunc(e)}} type="number" style={{ border: "none", borderBottom: "1px solid #bababa" }} placeholder="Disc" />
                </div> <br/>
                <input value={ttlAmtWithOvrallDisc} disabled type="text" style={{ marginTop:"4px", border: "none", borderBottom: "1px solid #bababa" }} placeholder="Final Amt" required/>
              </div>
          </div>

          <div style={{ textAlign: "right", marginTop: "60px", marginRight: "10px" }} >
              <button disabled={disableBtn ? "disable" : ""} onClick={() => {
                if(grahakDetails && grahakDetails != null){
                  bookingItemListFunc()
                }else{
                  alert("Please add grahak")
                }  
              }} style={{backgroundColor: "#008C53", color: "white", borderRadius: "10px", padding: "3px 13px", border: "none", }}>
                  Book Order
              </button>
          </div>
      </div>
    </div>
  );
};

export default OrderAddNew;
