import React, { useState } from 'react'
import CashBookDetail from './CashBookDetail'
import { PassBookDetail } from './PassBookDetail'

const GallaRight = ({getPaymentId, setShowPassBookDetail, showPassBookDetail, getDate, showCashBook}) => {

  return (
    <div className='rightBackground' style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
      {showCashBook ?
        <CashBookDetail getDate = {getDate}/>
      :
        <>
          {showPassBookDetail ? 
            <PassBookDetail setShowPassBookDetail ={setShowPassBookDetail} getPaymentId = {getPaymentId}/>
          :
            <div className='rightSideBlank' style={{width:"100%"}}>
              <img src={require("../assets/images/gallascrnimg.png")} />
              <p>Create your bills and see all your transactions here.</p>
            </div>
          }
        </>
      }
  </div>  
  )
}

export default GallaRight