
import UdhaarReceiveBill from "./UdhaarReceiveBill";
import UdhaarPaymentHistory from "./UdhaarPaymentHistory";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";
import "./ClickUdhaar.css"
import UdhaarAllPaymentHistory from "./UdhaarAllPaymentHistory";
import { useNavigate } from "react-router-dom";


const UdhaarBill = ({setPayment ,customerId,setCustomerId,setbillId}) => {

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()

    const customer_id = customerId;
    const [showBill,setShowBill]=useState(false);
    const [bill, setBill] = useState([]);


    const udhaarDetail = () => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/vouchers/shop_credit/${customer_id}?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                // setCookie("token", response.headers.token);
                if (response.data.code === 200) {
                    // console.log("detail :", Helper.encypt_decrypt(response.data.data))
                    setBill(Helper.encypt_decrypt(response.data.data))
                    setbillId(Helper.encypt_decrypt(response.data.data).sales)  
                    setShowBill(false)                 
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect((customerId) => {
        udhaarDetail();
    }, [customerId])

    return (
        <div style={{width: "60%", height: "100%", borderRadius: "20px", padding: "20px", backgroundColor: "#fff", marginLeft: "20px", display: "flex", flexDirection: "column",}}>
            <div style={{ backgroundColor: "#008C53", width:"100%", color: "white", display: "flex", textAlign: "center", padding: "10px 20px", borderRadius: "10px", justifyContent: "space-around" }}>
                <div id="left" style={{ width: "50%", textAlign:"left" }}>
                    {bill && bill.customer_name != null ? <p style={{ margin:"0", padding:"0", fontWeight: "600" }}><span style={{fontWeight:"500"}}>Customer Name : </span>{bill.customer_name}</p> : null }
                    <p style={{ margin:"0", padding:"0", fontWeight: "500" }}>Mobile Number : {bill.mobile_number} </p>
                    {bill && bill.customer_address != null ? <p style={{ margin:"0", padding:"0", fontWeight: "500" }}>Address : {bill.customer_address} </p> : null }
                </div>
                {/* <div id="right" style={{ width: "50%" }}>
                    <p style={{ margin:"0", padding:"0", fontWeight: "600" }}>Total udhaar</p>
                    <p style={{ margin:"0", padding:"0", fontWeight: "600" }}>₹ {bill.Balance}</p>
                </div>    */}
                <div style={{display:"flex", alignItems:"center", width: "50%", justifyContent: "end"}}>
                    <button className="paymenthistoryBtn" onClick={()=>setShowBill(true)} >Payment History</button>
                </div>
            </div>
            <div style={{ backgroundColor: "white", display: "flex", borderRadius: "10px", marginTop: "10px",  padding: "10px 20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px" }}>
                <div id="left" style={{ width: "50%", textAlign:"left" }}>
                    <p style={{ margin:"0", padding:"0", fontWeight: "600" }}>Total udhaar : ₹ {bill.Balance}</p>
                </div>  
                <div id="right" style={{display: bill && bill.Balance === 0.0 ? "none" : "flex", alignItems:"center", width: "50%", justifyContent: "end"}}>
                    <button onClick={()=>setPayment(true)} style={{ backgroundColor: "#008C53", color: "white", padding: "5px 15px", borderRadius: "20px", border: "1px solid #008C53", cursor:"pointer" }}>Recieve Payment</button>
                </div>
            </div>
            {showBill?
                <UdhaarPaymentHistory setShowBill={setShowBill} customerId={customerId} setbillId={setbillId}></UdhaarPaymentHistory>
            :
                <UdhaarReceiveBill setPayment={setPayment} customerId={customerId} setCustomerId={setCustomerId} setbillId={setbillId}></UdhaarReceiveBill>
            }
        </div>
    )
}

export default UdhaarBill;