import './ClickUdhaar.css';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useCookies } from "react-cookie";
import Helper from "../util/Helper";
import { useNavigate } from 'react-router-dom';

const UdhaarReceiveBill = ({ setPayment, customerId,setbillId}) => {

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate()

    const [bill, setBill] = useState([]);
    const[getitem,setItem]=useState([])
    const [getItemDetl, setGetItemDetl] = useState(null)
    
    // const customer_id = customerId;

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/vouchers/shop_credit/${customerId}?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                // setCookie("token", response.headers.token);
                if (response.data.code === 200) {
                    setBill(Helper.encypt_decrypt(response.data.data).sales)
                    setbillId(Helper.encypt_decrypt(response.data.data).sales)                   
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })
    },[customerId])

    // useEffect((customerId) => {
    //     udhaarDetail();
    //     ItemDetails();
    // }, [customerId])
    const itemDetailUdhaar = () => {
        ItemDetails(customerId)
    }

    const ItemDetails = (customerId) => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl() + `/vouchers/shop_credit_items_detail/${customerId}?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                // setCookie("token", response.headers.token);
                if (response.data.code === 200) {
                    setGetItemDetl(Helper.encypt_decrypt(response.data.data))
                    setItem(Helper.encypt_decrypt(response.data.data).item_details)
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                    }, 1000)
                  }
            })
            .catch((err) => {
                console.log(err)
            })
    }
 
    let total=0;

    getitem.forEach((value)=>{
        total += value.amount
    }) 

    return (
        <>
            <div class="modal fade " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div style={{marginBottom:"-5%"}}>
                                {getItemDetl && getItemDetl.customer_detail.name != null ? <p>Customer Name : {getItemDetl && getItemDetl.customer_detail.name}</p> : <p>Customer Name : --NA--</p> }
                                <p>Mobile Number : {getItemDetl && getItemDetl.customer_detail.mobile_number}</p>
                            </div>
                            <img style={{cursor:"pointer"}} class="close modalCross" data-dismiss="modal" aria-label="Close" data-bs-dismiss="modal" src={require("../assets/images/cancel.png")} className="img-fluid "></img>
                        </div>
                        <div class="modal-body">
                            <table class="table table-striped" id='tab'>
                                <thead>
                                    <tr>
                                        <th scope="col" style={{textAlign:"center", width:"10%"}}>S.No.</th>
                                        <th scope="col" style={{textAlign:"center", width:"25%"}}>Item Name</th>
                                        <th scope="col" style={{textAlign:"center", width:"10%"}}>QTy</th>
                                        <th scope="col" style={{textAlign:"center", width:"10%"}}>Price</th>
                                        <th scope="col" style={{textAlign:"center", width:"10%"}}>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { getitem.map((value,index)=>{
                                        return(
                                            <tr>
                                                <th style={{textAlign:"center"}} scope="row">{index+1}.</th>
                                                <td style={{textAlign:"center"}}>{value.item_name}</td>
                                                <td style={{textAlign:"center"}}>{value.quantity}</td>
                                                <td style={{textAlign:"center"}}>{value.total_price}</td>
                                                <td style={{textAlign:"center"}}>{value.amount}</td>
                                            </tr>
                                        )
                                    })} 
                                </tbody>                               
                            </table>
                        </div>
                        <div class="modal-footer" style={{display:"flex",justifyContent:"space-between"}}>                            
                            <div style={{display:"flex",margin:"2%",width:"37%",justifyContent:"space-between" }}>
                              <div>
                                <p style={{fontWeight:"600"}}>Total Amount :&nbsp;&nbsp;₹ {total}</p>
                              </div>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>

            {/* <div style={{ height: "52vh", marginTop:"10px" }}> */}
                <div style={{  backgroundColor: "#fff", marginTop:"20px", display: "flex", textAlign: "center", borderRadius: "10px", padding:"10px", border:"none", justifyContent: "center", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px", }}>
                    <table class="table" style={{ border: "none" }}>
                        <thead >
                            <tr style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                <th scope="col" style={{ border: "none", textAlign:"center", width:"10%"}} className="num322"></th>
                                <th scope="col" style={{ border: "none", textAlign:"center", width:"25%"}} className="num322">Bill No.</th>
                                <th scope="col" style={{ border: "none", textAlign:"center", width:"10%"}} className="num322">Qty</th>
                                {/* <th scope="col" style={{ border: "none", textAlign:"center", width:"10%"}}>Total Amt</th> */}
                                <th scope="col" style={{ border: "none", textAlign:"center", width:"10%"}} className="num322">Balance</th>
                                <th scope="col" style={{ border: "none", textAlign:"center", width:"25%"}} className="num322">Expected Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bill.map((value, index) => {
                                return (
                                    <tr style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                        <td style={{ border: "none" }}>
                                            <img onClick={itemDetailUdhaar} data-bs-toggle="modal" data-bs-target="#exampleModal" src={require("../assets/images/eyes.png")} style={{ height: "20px" }} />
                                        </td>
                                        <td style={{ textAlign:"center", border: "none" }} className="num32">{value.invoice_number}</td>
                                        <td style={{ textAlign:"center", border: "none" }} className="num32">{value.quantity}</td>
                                        {/* <td style={{ textAlign:"center", border: "none" }}>{value.amount}</td> */}
                                        <td style={{ color: "#BA1C09", textAlign:"center", border: "none" }}  className="num32">{parseFloat(value.amount).toFixed(2)}</td>
                                        <td style={{ textAlign:"center", border: "none" }} className="num32">{value.expected_date}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            {/* </div> */}
        </>
    )
}

export default UdhaarReceiveBill;